


import React, { useState, useEffect, useCallback, Fragment } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Box, Grid, Collapse, Typography, IconButton, Avatar, CircularProgress, TextField, Zoom, Badge, TablePagination } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Lightbox } from "react-modal-image";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { DropzoneArea } from 'material-ui-dropzone'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'; 
import ShowChartIcon from '@material-ui/icons/GraphicEq';
import LaboratoryGraph from './LaboratoryGraph';

import VisibilityIcon from '@material-ui/icons/Visibility';
import LaboratoryOrderDetails from './LaboratoryOrderDetails';

const imageLocation = process.env.REACT_APP_API_IMAGE; 

const LaboratoryNew = () => {
 
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [token] = useState(localStorage.getItem('token'))
    const [username] = useState(localStorage.getItem('username'))
    const [management_id] = useState(localStorage.getItem('management_id'))

    const [laboratory, setLaboratory] = useState([])
    const [laboratoryReady, setLaboratoryReady] = useState(false)

    const [selectedCollapseId, setselectedCollapseId] = useState(null)

    const [uploadProcess, setuploadProcess] = useState(false)

    const [sharedImages, setSharedImages] = useState([])

    const [sharedImagesReady, setsharedImagesReady] = useState(false)

    const [sharedDate, setSharedDate] = useState([])

    const [open, setOpen] = useState(false)
    const [files, setFiles] = useState([])

    const [category, setCategory] = useState('');

    const [openLightbox, setopenLightbox] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null) 

    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [page, setPage] = useState(0)

    const [sharedRowsPerPage, setSharedRowsPerPage] = useState(3)
    const [sharedPage, setSharedPage] = useState(0)

    const [showGraph, setShowGraph] = useState(false)

    // const [orderPrint, setOrderPrint] = useState({
    //     open: false,
    //     doctors_id: null,
    //     patient_id: null,
    //     order: null,
    //     order_date: null
    // })

    const [orderDetails, setOrderDetails] = useState({
        openOrderDetails: false,
        doctors_id: null,
        patient_id: null, 
        order_id: null,
        order_date: null,
        formheader: null
    })

    const bodyparts = [
        { id: 1, value: 'Arm' },
        { id: 2, value: 'Body' },
        { id: 3, value: 'Foot' },
        { id: 4, value: 'Head' },
        { id: 5, value: 'Legs' }
    ]

    const getLaboratoryList = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('management_id', management_id);
        formdata.set('user_id', user_id);

        // Axios.post('laboratory/patient/patient-laboratory', formdata ) //
        Axios.post('laboratory/patient/patient-laboratory-test', formdata)
            .then((response) => {
                const data = response.data; 
                setLaboratory(data)
                setLaboratoryReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, management_id, user_id]);

    const getSharedImagesAll = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('type', 'laboratory');

        Axios.post('patient/shared/images/shared-list', formdata)
            .then((response) => {
                const data = response.data;
                setSharedImages(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id]);

    const getSharedDateInGroupBy = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('type', 'laboratory');

        Axios.post('patient/shared/images/shared-groupdate', formdata)
            .then((response) => {
                const data = response.data;
                setSharedDate(data)
                setsharedImagesReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id]);

    const handleUpload = (e) => {
        e.persist()
        e.preventDefault()
        var formdata = new FormData(e.target);
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.append('share_image', files[0]);
        formdata.set('type', 'laboratory');
        // for (let i = 0; i < files.length; i++) {
        //     formdata.append('share_image[]', files[i]);    
        // }
        var error = [];

        if (formdata.get('category').length === 0 || formdata.get('category').trim() === '') {
            error = 'error';
            Notify.fieldRequired('category');
        }
        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            error = 'error';
            Notify.fieldRequired('password');
        }
        if (error.length > 0) {
            console.warn("form has error.")
        }
        else {
            setuploadProcess(true)
            Axios.post('patient/shared/images/share-new', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        Notify.successRequest('upload')
                        getSharedImagesAll()
                        getSharedDateInGroupBy()
                        setOpen(false)
                    }
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => {
                    setuploadProcess(false)
                });
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (files) => {
        //Saving files to state for further use and closing Modal.  
        setFiles(files)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleOpenlightbox = (e) => {
        setSelectedImage(e.currentTarget.getAttribute('image'))
        setopenLightbox(true)
    }

    const handleCloselightbox = (e) => {
        setSelectedImage(null)
        setopenLightbox(false)
    }

    useEffect(() => {
        getLaboratoryList()
        getSharedImagesAll()
        getSharedDateInGroupBy()
    }, [getLaboratoryList, getSharedImagesAll, getSharedDateInGroupBy])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    };

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage)
    };

    const handleSharedChangeRowsPerPage = (event) => {
        setSharedRowsPerPage(parseInt(event.target.value, 10))
        setSharedPage(0)
    };

    const handleSharedChangePage = (event, newPage) => {
        event.persist()
        setSharedPage(newPage)
    };

    const handleUpdateUnread = (order_id) => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', order_id)
        formdata.set('connection', 'online')

        Axios.post('patient/notification/update/unread', formdata)
            .then((response) => {
                const data = response.data;
                if (data === 'success') {
                    getLaboratoryList();
                }
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const paginateLaboratory = laboratory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const paginateSharedDate = sharedDate.slice(sharedPage * sharedRowsPerPage, sharedPage * sharedRowsPerPage + sharedRowsPerPage);


    return (
        <Box mt={2}>

            <Grid container spacing={2}>
                <Grid item sm={8} xs={12}>
                    <Box mb={1} display="flex">
                        <Box flexGrow={1}>
                            <Typography variant="subtitle2" color="primary" className={`gtc-uppercase`}> Medical Laboratory </Typography>
                        </Box>

                        <Button
                            variant="outlined"
                            color="default"
                            startIcon={<ShowChartIcon size="large" color="secondary" />}
                            onClick={() => setShowGraph(true)}
                        > Graph  </Button>
                    </Box>

                    {
                        laboratoryReady ?
                            laboratory.length > 0 ?
                                paginateLaboratory.map((data, index) =>
                                    <Box
                                        key={index}
                                        p={2}
                                        border={1}
                                        mb={2}
                                        borderRadius={4}
                                        borderColor="grey.300"
                                        bgcolor="white"
                                    >
                                        <Box
                                            className="clearfix"
                                        >
                                            <Box
                                                className="float-left"
                                            >
                                                <Typography>
                                                    {parseFloat(data.unreadCount) > 0 && (
                                                        <Badge hidden={data.unreadCount > 0 ? false : true} variant="dot" color="secondary">
                                                            {data.order_no}
                                                        </Badge>
                                                    )}
                                                    {parseFloat(data.unreadCount) === 0 && (
                                                        <Fragment>
                                                            {parseFloat(data.count_chem) > 0 && (<strong>Clinical Chemistry, </strong>)}
                                                            {parseFloat(data.count_micro) > 0 && (<strong>Clinical Microscopy, </strong>)}
                                                            {parseFloat(data.count_feca) > 0 && (<strong>Fecal Analysis, </strong>)}
                                                            {parseFloat(data.count_hema) > 0 && (<strong>Hemothology, </strong>)}
                                                            {parseFloat(data.count_sero) > 0 && (<strong>Serology </strong>)}
                                                        </Fragment>
                                                    )}
                                                </Typography>
                                                <Typography>
                                                    <small>
                                                        {Notify.dateTimeConvert(data.created_at)}
                                                    </small>
                                                </Typography>
                                            </Box>
                                            <Box
                                                className="float-right"
                                            >
                                                {/* <IconButton
                                                    variant="outlined"
                                                    color={'primary'}
                                                    onClick={() => setOrderPrint({
                                                        open: true,
                                                        doctors_id: data.doctors_id,
                                                        patient_id: data.patient_id,
                                                        order: data.laboratory_orders,
                                                        order_date: data.created_at
                                                    })}
                                                >
                                                    <PrintIcon />
                                                </IconButton> */}

                                                <IconButton
                                                    variant="outlined"
                                                    color={'primary'}
                                                    onClick={() => setOrderDetails({
                                                        openOrderDetails: true,
                                                        doctors_id: data.doctors_id,
                                                        patient_id: data.patient_id,
                                                        formheader: data,
                                                        order_id: data.order_id,
                                                        order_date: data.created_at
                                                    })}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>

                                                <IconButton
                                                    variant="outlined"
                                                    color={'primary'}
                                                    onClick={() => {
                                                        parseFloat(data.unreadCount) > 0 && (
                                                            handleUpdateUnread(data.order_id)
                                                        )
                                                        setselectedCollapseId(selectedCollapseId === data.id ? null : data.id);
                                                    }}
                                                >
                                                    {selectedCollapseId === data.id ? <RemoveCircleOutlineIcon /> : <ControlPointIcon />}
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Collapse in={selectedCollapseId === data.id ? true : false}>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    sm={7}
                                                    md={9}
                                                >
                                                    {parseFloat(data.count_chem) > 0 && (
                                                        <Box mb={1}>
                                                            <span className="gtc-formlabel"> Clinical Chemistry: </span>
                                                            <Typography align="justify">
                                                                {data.glucose && (
                                                                    'Glucose'
                                                                )}
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {data.creatinine && (
                                                                    ' Creatinine'
                                                                )}
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {data.uric_acid && (
                                                                    ' Uric Acid'
                                                                )}
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {data.cholesterol && (
                                                                    ' Cholesterol'
                                                                )}
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {data.triglyceride && (
                                                                    ' Triglyceride'
                                                                )}
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {data.hdl_cholesterol && (
                                                                    ' Hdl Cholesterol'
                                                                )}
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {data.ldl_cholesterol && (
                                                                    ' Ldl Cholesterol'
                                                                )}
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {data.sgot && (
                                                                    ' SGOT'
                                                                )}
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {data.sgpt && (
                                                                    ' SGPT'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {parseFloat(data.count_micro) > 0 && (
                                                        <Box mb={1}>
                                                            <span className="gtc-formlabel"> Clinical Microscopy: </span>
                                                            <Typography align="justify">
                                                                {parseFloat(data.chemical_test) > 0 ?
                                                                    'Chemical Test'
                                                                    : null}
                                                                {/* {data.chemical_test && (
                                                                    'Chemical Test'
                                                                )} */}
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {parseFloat(data.microscopic_test) > 0 ?
                                                                    'Microscopic Test'
                                                                    : null}
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {parseFloat(data.pregnancy_test_hcg) > 0 ?
                                                                    'Pregnancy Test Hcg'
                                                                    : null}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {parseFloat(data.count_feca) > 0 && (
                                                        <Box mb={1}>
                                                            <span className="gtc-formlabel"> Fecal Analysis: </span>
                                                            <Typography>
                                                                {data.fecal_analysis && (
                                                                    'Fecal Analysis Test'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {parseFloat(data.count_hema) > 0 && (
                                                        <Box mb={1}>
                                                            <span className="gtc-formlabel"> Hemathology: </span>
                                                            <Typography>
                                                                {data.hemoglobin && (
                                                                    'HEMOGLOBIN'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.hematocrit && (
                                                                    'HEMATOCRIT'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.rbc && (
                                                                    'RBC'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.wbc && (
                                                                    'WBC'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.platelet_count && (
                                                                    'PLATELET COUNT'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.differential_count && (
                                                                    'DIFFERENTIAL COUNT'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.neutrophil && (
                                                                    'NEUTROPHIL'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.lymphocyte && (
                                                                    'LYMPHOCYTE'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.monocyte && (
                                                                    'MONOCYTE'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.eosinophil && (
                                                                    'EOSINOPHIL'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.basophil && (
                                                                    'BASOPHIL'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.bands && (
                                                                    'BANDS'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.abo_blood_type_and_rh_type && (
                                                                    'ABO BLOOD TYPE AND RH TYPE'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.bleeding_time && (
                                                                    'BLEEDING TIME'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.clotting_time && (
                                                                    'CLOTTING TIME'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    {parseFloat(data.count_sero) > 0 && (
                                                        <Box mb={1}>
                                                            <span className="gtc-formlabel"> Serology: </span>
                                                            <Typography>
                                                                {data.hbsag && (
                                                                    'HGSAG'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.hav && (
                                                                    'HAV'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.hcv && (
                                                                    'HCV'
                                                                )}
                                                            </Typography>
                                                            <Typography>
                                                                {data.vdrl_rpr && (
                                                                    'VDRL RPR'
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    )} 
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </Box>
                                )
                                : Notify.noRecord()
                            : Notify.loading()
                    }

                    <TablePagination
                        labelRowsPerPage="List"
                        rowsPerPageOptions={[5, 20, 50, 100, { label: 'All', value: -1 }]}
                        component="div"
                        count={laboratory.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>

                <Grid item sm={4}>
                    <Box mb={1} className="clearfix">
                        <Box className="float-left" mt={1}>
                            <Typography variant="h6" color="textSecondary"> Shared Images </Typography>
                        </Box>
                        <Box className="float-right">
                            <IconButton color="primary" onClick={handleOpen}>
                                <AddAPhotoIcon />
                            </IconButton>

                        </Box>
                    </Box>
                    {
                        sharedImagesReady ?
                            sharedDate.length > 0 ?
                                paginateSharedDate.map((d, dindex) => {
                                    return (
                                        <Box key={dindex} mb={2}>
                                            <Typography> {Notify.createdAt(d.shared_date)} </Typography>
                                            {/* check if match the date */}
                                            <Box
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                {
                                                    sharedImages.length > 0 ?
                                                        sharedImages.map((data, index) => {
                                                            return (
                                                                <Box
                                                                    p={1}
                                                                    key={index}
                                                                    className="pointer"
                                                                    hidden={Notify.createdAt(d.shared_date) === Notify.createdAt(data.created_at) ? false : true}
                                                                >
                                                                    <Badge
                                                                        overlap="rectangle"
                                                                        anchorOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        color="error"
                                                                        badgeContent={data.category}
                                                                    >
                                                                        <Avatar
                                                                            onClick={handleOpenlightbox}
                                                                            style={{
                                                                                width: '100px',
                                                                                height: '100px'
                                                                            }}
                                                                            variant="rounded"
                                                                            image={data.image}
                                                                            alt={data.category}
                                                                            title={data.category}
                                                                            src={imageLocation + 'imaging/sharedimages/' + data.image}
                                                                        />
                                                                    </Badge>
                                                                </Box>
                                                            )
                                                        })
                                                        : Notify.noRecord()
                                                }
                                            </Box>
                                        </Box>
                                    )
                                })
                                : Notify.noRecord()
                            : Notify.loading()
                    }

                    <TablePagination
                        labelRowsPerPage="List"
                        rowsPerPageOptions={[3, 20, 50, 100, { label: 'All', value: -1 }]}
                        component="div"
                        count={sharedDate.length}
                        rowsPerPage={sharedRowsPerPage}
                        page={sharedPage}
                        onChangePage={handleSharedChangePage}
                        onChangeRowsPerPage={handleSharedChangeRowsPerPage}
                    />
                </Grid>
            </Grid>

            {/* lightbox images */}

            {
                openLightbox && (
                    <Lightbox
                        large={imageLocation + 'imaging/sharedimages/' + selectedImage}
                        alt={selectedImage}
                        onClose={handleCloselightbox}
                        showRotate={true}
                    />
                )
            }
            {/* upload dialogs */}
            <Dialog
                TransitionComponent={Zoom}
                open={open}
                onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
                transitionDuration={6000}
            >
                <form onSubmit={handleUpload} encType="multipart/form-data">
                    <DialogTitle id="alert-dialog-title"> Share Image </DialogTitle>
                    <DialogContent dividers>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                        >
                            <InputLabel id="demo-simple-select-label"> Category </InputLabel>
                            <Select
                                label="Category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                name="category"
                            >
                                {
                                    bodyparts.map((data) => (
                                        <MenuItem value={data.value} key={data.id}> { data.value} </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <Box my={2}>
                            <DropzoneArea
                                filesLimit={1}
                                onChange={handleChange}
                                acceptedFiles={['image/jpeg', 'image/png']}
                            />
                        </Box>

                        <TextField
                            fullWidth
                            label="Password"
                            variant="outlined"
                            margin="dense"
                            name="password"
                            type="password"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setOpen(false)}
                            variant="contained"
                            color="inherit"
                            startIcon={
                                <CancelIcon />
                            }
                        >
                            Close
                            </Button>
                        <Button
                            type="submit"
                            startIcon={
                                uploadProcess ? <CircularProgress size={15} color="inherit" /> : <CloudUploadIcon />
                            }
                            variant="contained"
                            color="primary"
                            autoFocus
                            disabled={uploadProcess}
                        >
                            Upload
                            </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* laboratory Graph */}
            <LaboratoryGraph
                open={showGraph}
                close={() => setShowGraph(false)}
            />

            {/* print laboratory order */}
            {/* <LaboratoryPrint
                orderPrint={orderPrint}
                close={() => setOrderPrint({
                    open: false,
                    doctors_id: null,
                    patient_id: null,
                    order: null,
                    order_date: null
                })
                } /> */}

            {/* NEW print laboratory order */}

            <LaboratoryOrderDetails
                orderDetails={orderDetails}
                close={() => setOrderDetails({
                    openOrderDetails: false,
                    doctors_id: null,
                    patient_id: null,
                    formheader: null,
                    order_id: null,
                    order_date: null
                })
            }/>
        </Box>
    )
}

export default LaboratoryNew; 