import React, { Fragment, useEffect, useState } from 'react'
import {Tabs, Tab, Box, Grid, Badge, Typography, Paper} from '@material-ui/core'; 
import TabPanel from '../utils/TabPanel';
import Notify from '../notification/Notify';
import Axios from 'axios'; 
import ImagingOrderNew from './ImagingOrderNew';
import NewIcon from '@material-ui/icons/AddCircleOutline';
import ProcessIcon from '@material-ui/icons/OfflinePin';
import PendingIcon from '@material-ui/icons/ErrorOutline';
import RecordIcon from '@material-ui/icons/Subject';
import ImagingOrderPending from './ImagingOrderPending';
import ImagingOrderProcessing from './ImagingOrderProcessing';
import ImagingRecords from './ImagingRecords';

const Imaging = () =>{
 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [category, setCategory] = useState(0)

    let interval = null; 
    
    const [count, setCount] = useState({
        new: 0,
        pending: 0,
        processing: 0
    })

    const [ newfindings, setNewFindings] = useState(0)

    const handleChange = (event, new_category) => {
        event.persist()
        setCategory(new_category);
    }; 

    const getCounts = () =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        
        Axios.post('imaging/order/ordernew-count', formdata )
        .then((response) => {  
            const data = response.data;     
            if(data.length > 0){
                setCount({
                    new: data[0].newCount,
                    pending: data[0].pendingCount,
                    processing: data[0].processingCount
                }) 
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    }
 

    const getTeleradNewFindings = async () =>{ 
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)
            formdata.set('connection', 'local')
        const response = await Axios.post('imaging/order/newfindings', formdata)
        
        setNewFindings(response.data.length)
    }

    const initializeInterval = () =>{
        interval = setInterval(() => {
            getCounts()
            getTeleradNewFindings()
        }, 10000);
    }

    useEffect(() =>{ 

        getCounts() 

        initializeInterval()

        return(() => clearInterval(interval))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <Fragment>
            <Box m={2}>
                <Grid container spacing={3}> 
                    <Grid item xs={4} sm={3} md={2}>  
                        <Box
                            component={Paper}
                            variant="outlined"  
                            py={2} 
                        >
                            <Box ml={2} align="center">
                                <Typography variant="caption" color="textSecondary">ORDER CONTENTS </Typography>
                            </Box>
                            <Tabs   
                                orientation="vertical" 
                                value={ category }
                                onChange={ handleChange }
                                indicatorColor="primary"
                                textColor="primary"    
                            >  
                                <Tab 
                                    wrapped 
                                    icon={  <Badge color="error"  badgeContent={ count.new }> <NewIcon fontSize="small" />  </Badge> } 
                                    label={ `New` } />
                                <Tab 
                                    wrapped 
                                    icon={ <Badge color="error" badgeContent={ count.processing }> <ProcessIcon fontSize="small" /> </Badge>  } 
                                    label={ `Processing` } />
                                <Tab 
                                    wrapped 
                                    icon={ <Badge color="error" badgeContent={ count.pending }> <PendingIcon fontSize="small" /> </Badge> } 
                                    label={ `Pending` } />
                                <Tab 
                                    wrapped 
                                    icon={ <RecordIcon fontSize="small" /> } 
                                    label={
                                        <Badge color="secondary" invisible={ ! Boolean(parseInt(newfindings)) } badgeContent="new">
                                            Record
                                        </Badge>
                                    } />
                            </Tabs>
                        </Box>
                    </Grid>

                    <Grid item xs={8} sm={9} md={10}>   
                        <TabPanel value={ category } index={0}>
                            <ImagingOrderNew getCounts = { getCounts } />
                        </TabPanel>

                        <TabPanel value={ category } index={1}>
                            <ImagingOrderProcessing getCounts = { getCounts } />
                        </TabPanel>

                        <TabPanel value={ category } index={2}>
                            <ImagingOrderPending getCounts = { getCounts } />
                        </TabPanel>

                        <TabPanel value={ category } index={3}>
                            <ImagingRecords getCounts = { getCounts } />
                        </TabPanel> 
                    </Grid>  
                </Grid>
            </Box>
        </Fragment>
    )
}

export default Imaging;