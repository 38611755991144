import React, { Fragment, useState, useEffect } from "react"
import { Alert } from "react-bootstrap";
import { BrowserRouter, NavLink, Redirect, Route, Switch } from 'react-router-dom';
import Logout from "../welcome/Logout";
import Patients from "../patients/Patients";
import EnterRoom from "../patients/onlinecheckup/EnterRoom";
import PageNotFound from '../PageNotFound';
import DoctorsInformation from "../patients/appointment/DoctorsInformation";
import Axios from "axios";
import Notify from "../notification/Notify";
import {
    AppBar, Toolbar, Dialog, DialogContent, DialogTitle, Zoom, TextField, Box, Select, MenuItem, InputLabel, FormControl, Button, CircularProgress, Grid, Typography, IconButton, Menu, Hidden, ListItemIcon, List, Badge, ListItem, ListItemText, ListSubheader
} from "@material-ui/core";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MoreVertIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CardMembership from '@material-ui/icons/CardMembership';
import GTCValidateLS from "../notification/GTCValidateLS";
import StreamError from "../StreamError";
import SearchAppointment from "../patients/onlinecheckup/SeachAppointment";
import ClinicDetails from "../patients/onlinecheckup/ClinicDetails";
import { PackageContext } from "../PackageContext";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EnterRoomOld from "src/patients/onlinecheckup/EnterRoomOld";
import { PatientCategoryContext } from "src/ContextAPI";
import GTCMap from "src/patients/Map/GTCMap";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ForPatientsNotif } from 'src/ContextAPI';

const RoutePatients = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const management_id = localStorage.getItem('management_id')
    const username = localStorage.getItem('username')
    const [unViewedNotification, setUnViewedNotification] = useState([])
    const [unViewedNotificationRdy, setUnViewedNotificationRdy] = useState(false)
    const [notifAnchorEl, setNotifAnchorEl] = useState(null)
    const [notifMenu, setNotifMenu] = useState(false)
    const [_issubscribe_status, set_issubscribe_status] = useState(false)
    const [_selected_package, set_selected_package] = useState([])
    const [_issubscribe_month, set_issubscribe_month] = useState(1)
    const [_available_month, 
        // set_available_month
    ] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40])
    const [_subscriptcount, set_subscriptcount] = useState(0)
    const [_amount_topay, set_amount_topay] = useState(1)
    const [_amount_topay_subscription, set_amount_topay_subscription] = useState(0)
    const [_is_process, set_is_process] = useState(false)
    // const [_packages, set_packages] = useState('')
    const [_choosenpackage, set_choosenpackage] = useState('')
    const [_currentSubscription, set_currentSubscription] = useState(null)
    const [_anchorEl, set_anchorEl] = useState(null)
    const [_open_moretab, set_open_moretab] = useState(false)
    const [category, setcategory] = useState('profile')
    const [patientNotifBadge, setPatientNotifBadge] = useState(0);

    const checkaccountConfirmation = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('management_id', management_id);
        formdata.set('user_id', user_id);

        Axios.post('patient/information/account/checksubscription', formdata)
            .then((response) => {
                const data = response.data;
                if (data.length > 0) {
                    set_issubscribe_status(data[0].issubscribe_status === 'approved' ? true : false)
                    set_selected_package(data)
                    set_amount_topay(data[0].package_selected === 'standard' ? 100 : data[0].package_selected === 'premium' ? 200 : data[0].package_selected === 'elite' ? 10000 : 0)
                    set_subscriptcount(data[0].issubscribe_status !== 'approved' ? data[0].issubscribe : 0)
                    set_choosenpackage(data[0].package_selected === null ? '' : data[0].package_selected)
                }
            }).catch(error => {
                rerunChecker()
                Notify.requestError(error);
            });
    }

    const rerunChecker = () => {
        setTimeout(() => {
            checkaccountConfirmation()
        }, 5000);
    }

    const packagesAmount = (pkge) => {
        return pkge === 'standard' ? 100 :
            pkge === 'premium' ? 200 :
                pkge === 'elite' ? 10000 : 0
    }

    const handleChangeMonth = (e) => {
        if (e.target.value > 1) {
            set_issubscribe_month(e.target.value)
            set_amount_topay_subscription(parseFloat(e.target.value) * packagesAmount(_choosenpackage))
        } else {
            set_issubscribe_month(1)
            set_amount_topay_subscription(200)
        }
    }

    const saveSubscription = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('username', username)
        formdata.set('user_id', user_id)
        formdata.set('token', token)

        var error = [];

        if (formdata.get('package').length === 0 || formdata.get('package').trim() === '') {
            error = 'error';
            Notify.fieldRequired('package');
        }

        if (formdata.get('month_length').length === 0 || formdata.get('month_length').trim() === '') {
            error = 'error';
            Notify.fieldRequired('subscription length');
        }

        if (formdata.get('amount').length === 0 || formdata.get('amount').trim() === '') {
            error = 'error';
            Notify.fieldRequired('subscription amount');
        }

        if (formdata.get('password').length === 0 || formdata.get('password').trim() === '') {
            error = 'error';
            Notify.fieldRequired('password');
        }

        if (error.length > 0) {
            console.warn("form has an error. unable to process")
        } else {
            set_is_process(true)
            Axios.post('/patient/subscription/save-subscription', formdata)
                .then((response) => {
                    const data = response.data
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'success') {
                        checkaccountConfirmation()
                        Notify.successRequest('subscription')
                    }
                })
                .catch((error) => {
                    Notify.requestError(error);
                }).finally(() => {
                    set_is_process(false)
                })
        }
    }

    const handlePaymentLink = (e) => {
        window.open(e.currentTarget.getAttribute('paylink'), '_blank');
    }

    const getCurrentSubscription = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        Axios.post('user/account/subscription/validate/8724g03lfs59839rts4245', formdata)
            .then((res) => {
                const data = res.data
                set_currentSubscription(data)
            }).catch((error) => {
                Notify.requestError(error)
            })
    }

    const handleMenu = (event) => {
        set_anchorEl(event.currentTarget)
        set_open_moretab(true)
    };

    const handleClose = () => {
        set_anchorEl(null)
        set_open_moretab(false)
    };

    const updateCategory = (cat) => {
        setcategory(cat)
    }

    //new
    const handleCloseAnchor = () => {
        setNotifAnchorEl(null)
        setNotifMenu(false)
    }

    const updatePatientNotif = (data) => {
        setPatientNotifBadge(data)
    }

    //new
    const getLocalNotifUnread = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('connection', 'online');

        Axios.post('patient/unview/notification-unview', formdata)
            .then((response) => {
                const data = response.data;
                setUnViewedNotification(data)
                setUnViewedNotificationRdy(true)
            }).catch(error => {
                rerunChecker()
                Notify.requestError(error);
            });
    }

    const handleClearAllNotification = () =>{
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        Axios.post('patient/notification/clear-all', formdata)
            .then((res) => {
                const data = res.data
                if(data === 'success'){
                    getLocalNotifUnread();
                    Notify.successRequest('clear all')
                }
            }).catch((error) => {
                Notify.requestError(error)
            })
    }

    useEffect(() => {
        GTCValidateLS.verifyLocalStorage()
        getCurrentSubscription()
        checkaccountConfirmation()

        setInterval(() => {
            getLocalNotifUnread()
        }, 10000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <ForPatientsNotif.Provider
                value={{
                    patientNotif: patientNotifBadge,
                    updatePatientNotif: updatePatientNotif,
                    unViewedNotification: unViewedNotification,
                }}
            >
                <PatientCategoryContext.Provider
                    value={{
                        _category: category,
                        _updateCategory: updateCategory
                    }}
                >
                    <BrowserRouter>
                        <AppBar
                            position="static"
                            // color={ themeContext.gtcThemeDark ? 'default' : 'primary' } 
                            className={`gtc-appbar`}
                        >
                            <Hidden xsDown>
                                <Toolbar>
                                    {/* desktop navigation bar */}
                                    <Box
                                        display="flex"
                                        width={'100%'}
                                        className={`appbarLink`}
                                    >
                                        <Box flexGrow={1}>
                                            <Box display="flex">
                                                <Box m={2}>
                                                    <Typography noWrap className={`text-white`}>
                                                        GTC PATIENT
                                        </Typography>
                                                </Box>
                                                <Box m={2}>
                                                    <NavLink exact to="/app/patient" activeClassName="text-white">
                                                        <Typography> <HomeIcon /> Home </Typography>
                                                    </NavLink>
                                                </Box>

                                                <Box m={2}>
                                                    <NavLink exact to="/app/patient/appointment" activeClassName="text-white">
                                                        <Typography> <EventNoteIcon /> Appointment </Typography>
                                                    </NavLink>
                                                </Box>
                                                <Box m={2}>
                                                    <Box
                                                        className={`pointer`}
                                                        onClick={(e) => {
                                                            setNotifAnchorEl(e.currentTarget)
                                                            setNotifMenu(true)
                                                        }}
                                                    >
                                                        <Badge
                                                            badgeContent={unViewedNotificationRdy ? parseInt(unViewedNotification.length) : ('...')}
                                                            showZero
                                                            color="secondary"
                                                        >
                                                            <FontAwesomeIcon size="lg" icon={faBell} />
                                                        </Badge>
                                                    </Box>

                                                    <Menu
                                                        component={List}
                                                        anchorEl={notifAnchorEl}
                                                        getContentAnchorEl={null}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                        PaperProps={{  
                                                            style: {  
                                                              width: 300,  
                                                            }
                                                        }}
                                                        open={notifMenu}
                                                        onClose={handleCloseAnchor}
                                                    >
                                                        <ListSubheader>
                                                            <Box display="flex">
                                                                <Box flexGrow={1}>
                                                                    <Typography variant="caption">
                                                                        NOTIFICATIONS
                                                                    </Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography variant="caption" color="secondary" onClick={handleClearAllNotification}>
                                                                        Clear All
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            
                                                        </ListSubheader>
                                                        <PerfectScrollbar>
                                                            <Box maxHeight={350}>
                                                                {unViewedNotificationRdy ? <RenderUnreadNotif updateCategory={updateCategory} unViewedNotification={unViewedNotification} /> :
                                                                    <Box align="center">
                                                                        <Typography color="primary" variant={'caption'}> please wait...</Typography>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </PerfectScrollbar>
                                                    </Menu>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Box display="flex" width={130}>
                                            {/* <Box m={2} className={`pointer`}> 
                                    <Typography onClick={()=>{ themeContext.updategtcTheme(themeContext.gtcThemeDark ? 'light' : 'dark') } }> { themeContext.gtcThemeDark ? <DarkIcon /> : <LightIcon /> } </Typography>
                                </Box> */}

                                            <Box m={2}>
                                                <NavLink to="/app/logout" exact style={{ color: '#c5c5c5', textDecoration: 'none' }}>
                                                    <ExitToAppIcon /> Logout
                                            </NavLink>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Toolbar>
                            </Hidden>

                            <Hidden smUp>
                                <Toolbar>
                                    {/* mobile navigation */}
                                    <Box
                                        display="flex"
                                        width={'100%'}
                                        className={`appbarLink`}
                                    >
                                        <Box flexGrow={1}>
                                            <Box display="flex">
                                                <Box m={2} flexGrow={1}>
                                                    <Typography noWrap className={`text-white`}>
                                                        GTC PATIENT
                                                </Typography>
                                                </Box>

                                                <Box>
                                                    <IconButton
                                                        aria-label="account of current user"
                                                        aria-controls="menu-appbar"
                                                        aria-haspopup="true"
                                                        onClick={handleMenu}
                                                        color="inherit"
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="menu-appbar"
                                                        anchorEl={_anchorEl}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        keepMounted
                                                        open={_open_moretab}
                                                        onClose={handleClose}
                                                    >
                                                        <MenuItem
                                                            component={NavLink}
                                                            to="/app/patient"
                                                        >
                                                            <ListItemIcon>
                                                                <HomeIcon />
                                                            </ListItemIcon>
                                                            <Typography color="primary">
                                                                Home
                                                        </Typography>
                                                        </MenuItem>
                                                        <MenuItem
                                                            component={NavLink}
                                                            to="/app/patient/appointment"
                                                        >
                                                            <ListItemIcon>
                                                                <EventNoteIcon />
                                                            </ListItemIcon>
                                                            <Typography noWrap color="primary">
                                                                Appointment
                                                        </Typography>
                                                        </MenuItem>
                                                        <MenuItem
                                                            component={NavLink}
                                                            to="/app/logout"
                                                        >
                                                            <ListItemIcon>
                                                                <ExitToAppIcon />
                                                            </ListItemIcon>
                                                            <Typography color="error">
                                                                Logout
                                                        </Typography>
                                                        </MenuItem>
                                                    </Menu>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Toolbar>
                            </Hidden>
                        </AppBar>
                        <PackageContext.Provider
                            value={{
                                __package: _currentSubscription
                            }}
                        >
                            <Switch>
                                <Route exact path="/" component={() => <Redirect to="/app/patient" />} />
                                <Route exact path="/app" component={() => <Redirect to="/app/patient" />} />
                                <Route exact path="/app/patient" component={Patients} />
                                {/* <Route exact path="/app/patient/appointment" component={DoctorsList}/> */}
                                <Route exact path="/app/patient/appointment" component={SearchAppointment} />
                                <Route exact path="/app/patient/appointment/clinic/:clinic_id" component={ClinicDetails} />
                                <Route exact path="/app/patient/appointment/doctor/:doctor_id" component={DoctorsInformation} />
                                {/* <Route exact path="/virtual/create/room/v-call/:patient_id/:appointment_id/:room_id" component={VirtualCall} />  */}

                                <Route exact path="/app/patient/map" component={GTCMap} />
                                <Route exact path="/app/logout" component={Logout} />
                                <Route exact path="/streamerror" component={StreamError} />
                                <Route exact path="/app/patient/online/checkup/enter-room/:doctors_id/:room_number" component={EnterRoom} />
                                {/* <Route exact path="/app/patient/online/checkup/enter-room/:room_id" component={EnterRoom} /> */}
                                <Route exact path="/app/patient/appointment/room/:ref_number/:room_number" component={EnterRoomOld} />

                                {/* <Route exact path="/app/patient/twopeer" component={TwoPeer} /> */}
                                <Route render={
                                    () => <PageNotFound title="Page not found in patient routes." />
                                } />
                            </Switch>
                        </PackageContext.Provider>
                    </BrowserRouter>
                </PatientCategoryContext.Provider>
            </ForPatientsNotif.Provider>
            {
                _selected_package.length > 0 ?
                    <Dialog
                        fullWidth={true}
                        maxWidth={'sm'}
                        TransitionComponent={Zoom}
                        open={!_issubscribe_status}
                        keepMounted
                        disableBackdropClick={true}
                        transitionDuration={1000}
                    >
                        <DialogTitle>
                            Subscription
                        </DialogTitle>
                        <DialogContent dividers>
                            <Box p={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        {
                                            _subscriptcount > 0 ?
                                                <Fragment>
                                                    {
                                                        _selected_package.length > 0 ?
                                                            _selected_package[0].issubscribe_status === 'pending' ?
                                                                <Fragment>
                                                                    <Alert variant="danger">
                                                                        <span className="header-label text-uppercase small"> <strong> Your subscription is pending! </strong> </span> <br />
                                                                        <Button onClick={() => window.location.reload()} fullWidth variant="text" color="primary">
                                                                            Reload
                                                                    </Button>
                                                                    </Alert>

                                                                    <p className="m-0 my-2">
                                                                        Please wait while your subscription is being process and the payment link being prepare.
                                                                </p>
                                                                    <p className="m-0  text-muted header-label">
                                                                        If you have any question please dont hesitate to contact tru the information given.
                                                                </p>
                                                                </Fragment>
                                                                : _selected_package[0].issubscribe_status === 'forpayment' ?
                                                                    <Fragment>
                                                                        <Box my={2}>
                                                                            <Typography color="textSecondary">
                                                                                Please click the clink to pay your subscription and start using your account.
                                                                    </Typography>
                                                                        </Box>
                                                                        <Button fullWidth paylink={_selected_package[0].payment_link} onClick={handlePaymentLink} variant="contained" color="primary"> Pay Subscription </Button>
                                                                        <Box mt={2}>
                                                                            <Button onClick={() => window.location.reload()} fullWidth variant="text" color="secondary">
                                                                                Reload
                                                                    </Button>
                                                                        </Box>
                                                                    </Fragment>
                                                                    : null
                                                            : null
                                                    }
                                                </Fragment>
                                                :
                                                <form onSubmit={saveSubscription}>

                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                        margin="dense"
                                                    >
                                                        <InputLabel> Subscription Package </InputLabel>
                                                        <Select
                                                            name="package"
                                                            label="Subscription Package"
                                                            value={_choosenpackage}
                                                            onChange={(e) => {
                                                                set_choosenpackage(e.target.value)
                                                                set_amount_topay(packagesAmount(e.target.value))
                                                                set_amount_topay_subscription(packagesAmount(e.target.value))
                                                                set_issubscribe_month(1)
                                                            }}
                                                        >
                                                            <MenuItem value={'standard'}>Standard</MenuItem>
                                                            <MenuItem value={'premium'}>Premium</MenuItem>
                                                            <MenuItem value={'elite'} disabled>Elite</MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                    <Box my={1}>
                                                        <TextField
                                                            inputProps={{ readOnly: true }}
                                                            name="amount_monthly"
                                                            fullWidth
                                                            type="number"
                                                            variant="outlined"
                                                            margin="dense"
                                                            label="Subscription Monthly "
                                                            value={_amount_topay}
                                                        />
                                                    </Box>

                                                    <Box my={1}>
                                                        <FormControl variant="outlined" margin="dense" fullWidth>
                                                            <InputLabel > Subscription Length in Month </InputLabel>
                                                            <Select
                                                                name="month_length"
                                                                label="Subscription Length in Month"
                                                                value={_issubscribe_month}
                                                                onChange={handleChangeMonth}
                                                            >
                                                                <MenuItem value="" disabled>Months</MenuItem>
                                                                {
                                                                    _available_month.map((data, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={data}> {data} </MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Box>

                                                    <Box my={1}>
                                                        <TextField
                                                            inputProps={{ readOnly: true }}
                                                            name="amount"
                                                            fullWidth
                                                            type="number"
                                                            variant="outlined"
                                                            margin="dense"
                                                            label="Subscription Amount in PHP"
                                                            value={_amount_topay_subscription === 0 ? _amount_topay : _amount_topay_subscription}
                                                        />
                                                    </Box>

                                                    <Box my={1}>
                                                        <TextField name="password" fullWidth type="password" variant="outlined" margin="dense" label="Enter your password" />
                                                    </Box>

                                                    <Button
                                                        startIcon={_is_process ? <CircularProgress color="inherit" size={15} /> : <CardMembership />}
                                                        disabled={_is_process} color="primary" variant="contained" type="submit">
                                                        Subscribe
                                            </Button>
                                                </form>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box px={2}>
                                            <p className="text-dark header-label small"> For subscription assistance please contact: </p>
                                            <p className="m-0 my-1">
                                                <span className="header-label text-uppercase small"> MOBILE:  </span> <br /> <strong> 09-sample-5668 </strong>
                                            </p>
                                            <p className="m-0 my-2">
                                                <span className="header-label text-uppercase small"> Telephone:  </span> <br /> <strong> 255-test-1232 </strong>
                                            </p>
                                            <p className="m-0">
                                                <span className="header-label text-uppercase small"> Gmail:  </span> <br /> <strong> sampletest@gmail.com </strong>
                                            </p>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                    </Dialog>
                    : null
            }
        </Fragment>
    )
}

export default RoutePatients;

const RenderUnreadNotif = ({ unViewedNotification, updateCategory }) => {
    const handlePush = (e) => {
        updateCategory(e.currentTarget.id)
    }

    return (
        <>
            { unViewedNotification.length > 0 && (
                unViewedNotification.map((data, index) => (
                    <ListItem
                        key={index}
                        onClick={handlePush}
                        id={data.category}
                        button
                    >
                        <ListItemIcon>
                            <PeopleAltIcon />
                        </ListItemIcon>
                        <ListItemText
                            className={`gtc-capitalize`}
                            primary={data.message}
                            secondaryTypographyProps={{ noWrap: true }}
                        />
                    </ListItem>
                ))
            )}
            { unViewedNotification.length === 0 && (
                <Box align="center">
                    <Typography color="secondary" variant={'caption'}>No notification found. </Typography>
                </Box>
            )}
        </>
    )
}