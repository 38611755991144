import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TablePagination, Paper, Badge, IconButton, TextField, Dialog, DialogTitle, DialogContent } from '@material-ui/core';  
import Notify from '../notification/Notify';
import Axios from 'axios'; 
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import CancelIcon from '@material-ui/icons/Cancel';
import Label from 'src/utils/Label';

const imageLocation = process.env.REACT_APP_API_IMAGE; 

const ImagingRecords = () =>{
 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')  

    
    const [page, setPage] = React.useState(0); 
    const [rowsPerPage, setRowsPerPage] = React.useState(10); 
    
    const [records, setRecords] = useState({
        data: [],
        ready: false
    })  

    const [selectedOder, setSelectedOrder] = useState({ data: null, dialog: false })

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };   

    const getRecords = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        
        Axios.post('imaging/order/allrecords', formdata )
        .then((response) => {  
            const data = response.data;   
            setRecords({
                data: data,
                ready: true
            })
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id]); 

    
    useEffect(() =>{
        
        getRecords()

    }, [getRecords])

    return(
        <Fragment>
            <Paper component={Box} p={2} variant="outlined">
                <TableContainer>
                    <Box ml={2} mt={2}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                        >
                            NEW ORDER
                        </Typography>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> ID  </TableCell>
                                <TableCell> DateTime  </TableCell>
                                <TableCell> Patient  </TableCell>
                                <TableCell> Order  </TableCell> 
                                <TableCell> Process Stared  </TableCell>
                                <TableCell> Process Ended  </TableCell>
                                <TableCell> Doctor  </TableCell> 
                                <TableCell> Status  </TableCell> 
                                <TableCell> Action  </TableCell> 
                            </TableRow>
                        </TableHead>
                        <TableBody> 
                            {
                                records.data.length > 0 ? 
                                    (rowsPerPage > 0 ? 
                                        records.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : records.data
                                    ).map((data,index)=>(
                                        <TableRow key={index} hover>
                                            <TableCell> 
                                                <Badge color="secondary" invisible={ data.is_viewed !== 3 } variant="dot"> 
                                                    { data.imaging_center_id.replace('imaging-','') } 
                                                </Badge>
                                            </TableCell>
                                            <TableCell> { Notify.dateTimeConvert(data.created_at) } </TableCell>
                                            <TableCell> { data.patient_name } </TableCell>
                                            <TableCell> { data.imaging_order } </TableCell> 
                                            <TableCell> { data.start_time === null ? 'none' : Notify.dateTimeConvert(data.start_time) } </TableCell>
                                            <TableCell> { data.end_time === null ? 'none' : Notify.dateTimeConvert(data.end_time) } </TableCell>
                                            <TableCell> { data.doctors_name } </TableCell> 
                                            <TableCell>
                                                <Label color={ 
                                                    Boolean(parseInt(data.is_pending)) ? 'error' : 
                                                    data.imaging_result_attachment !== null && data.imaging_result === null ? 'warning' :
                                                    data.imaging_result_attachment !== null && data.imaging_result !== null ? 'success' : 'primary' 
                                                }>
                                                    { 
                                                        Boolean(parseInt(data.is_pending)) ? 'pending' : 
                                                        data.imaging_result_attachment !== null && data.imaging_result === null ? 'ongoing' :
                                                        data.imaging_result_attachment !== null && data.imaging_result !== null ? 'processed' : 'new'
                                                    } 
                                                </Label>
                                            </TableCell> 
                                            <TableCell> 
                                                <IconButton
                                                    color="primary"
                                                    onClick={ () =>{
                                                        setSelectedOrder({ data: data, dialog: true })
                                                    }}
                                                >
                                                    <MenuOpenIcon />
                                                </IconButton>
                                            </TableCell> 
                                        </TableRow>
                                    ))
                                : 
                                <TableRow>
                                    <TableCell colSpan={9}> No record found.  </TableCell> 
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage = "List"
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        component="div"
                        count={records.data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    
                </TableContainer> 
            </Paper>  

            {/* imaging details dialog */}
            { selectedOder.data && selectedOder.dialog && ( 
                <ImagingRecordsDetails 
                    details = { selectedOder.data } 
                    open ={ selectedOder.dialog} 
                    close = { () => { 
                        setSelectedOrder({ data: null, dialog: false })  
                    }} 
                /> 
            )}
        </Fragment>
    )
}

export default ImagingRecords;



const ImagingRecordsDetails = ({ details, open, close }) =>{
    
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const setTeleradFindingsRead = async () => {
        try {
            var formdata = new FormData();
                formdata.append('token' , token)
                formdata.append('user_id' , user_id)
                formdata.set('connection', 'local')
                formdata.set('imaging_center_id', details.imaging_center_id)

            const request = await Axios.post('imaging/order/set-findings-asread', formdata)
                console.log(request.data)
        } catch (error) {
            Notify.requestError(error)
        }
    }

    useEffect(() =>{

        setTeleradFindingsRead()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
           <Dialog
                open={ open }
                onClose = { close }
                fullWidth
                maxWidth="sm"
           >
               <Box display="flex">
                   <Box flexGrow={1}>
                        <DialogTitle> Order Details </DialogTitle>
                    </Box>
                    <Box mt={1} mr={1}>
                        <IconButton
                            color="secondary"
                            onClick = { close }
                        >
                            <CancelIcon />
                        </IconButton>
                    </Box>
               </Box>

               <DialogContent dividers> 

                    <Box mb={2}>
                        <TextField 
                            label="Order"
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ details.imaging_order } 
                            multiline
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField 
                            label="Order Remarks "
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ details.imaging_remarks } 
                            multiline
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField 
                            label="Order On "
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ Notify.createdAt(details.created_at) } 
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField
                            label="Result"
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ details.imaging_result === null ? 'None' : details.imaging_result }
                            multiline
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField 
                            label="Result Remarks"
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ details.imaging_results_remarks === null ? 'None' : details.imaging_results_remarks }
                            multiline
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField 
                            label="Result Added On"
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ details.end_time === null ? 'None' : Notify.createdAt(details.end_time) }
                        />
                    </Box>

                    <Box> 
                        <Box>
                            <Typography color="primary" variant="subtitle2" >
                                RESULT ATTACHMENT
                            </Typography>
                        </Box>

                        { 
                            details.imaging_result_attachment === null ? 
                                <p className="m-0 text-danger"> No attachment </p> 
                            : 
                            <div className="row">
                                {
                                    details.imaging_result_attachment.split(',').map( (data, index) => {
                                        return( 
                                            <img  
                                                key={index}  
                                                src={`${imageLocation}imaging/${data}`} 
                                                alt="" 
                                                className="col-sm-4 col-xs-6 pointer" 
                                                title="Enlarge" 
                                            />  
                                        )
                                    })
                                }
                            </div> 
                        } 
                    </Box>

               </DialogContent>
           </Dialog>
        </>
    )
}