import React, {  useContext, useEffect, useState } from 'react';  
import Notify from '../../../notification/Notify';
import Axios from 'axios';
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row' 
import ImagingProcessedDetails from './ImagingProcessedDetails';
import ImagingProcessedAttachment from './ImagingProcessedAttachment';
import Badge from '@material-ui/core/Badge'
import { PatientsUnRead } from 'src/ContextAPI';
import { Dialog, DialogTitle, DialogContent, Box, IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const ImagingOngoing = ({ patient_id, imagingType }) =>{

    const user_id =  localStorage.getItem('user_id')
    const token =  localStorage.getItem('token') 

    const [imaging, setImaging] = useState({ data: [], ready: false }) 

    const [selectedOrder, setSelectedOrder] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [selectedDialog, setSelectedDialog] = useState('details') 

    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( category, department, order_id ) =>{

        
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual;
        let count = 0;

        if(imagingType === 'local-imaging'){
            for (let i = 0; i < xx.length; i++) {
                if( patient_id === xx[i].patient_id && category === xx[i].category && department === xx[i].department && order_id === xx[i].order_id){
                    count += 1;
                }
            }
        }else{
            for (let i = 0; i < yy.length; i++) {
                if( patient_id === yy[i].patient_id && category === yy[i].category && department === yy[i].department && order_id === yy[i].order_id){
                    count += 1;
                }
            }
        }
        
        return count;
    }

    const getOngoingProcess = () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('patient_id', patient_id);    
        formdata.set('connection', imagingType === 'virtual-imaging' ? 'online' : 'local')
        
        Axios.post('imaging/doctor/patient/imaging-ongoingorder', formdata )
        .then( (response) => { 
            const data = response.data;      
            setImaging({ data: data, ready: true })
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    } 

    const closeDialog = () =>{
        setSelectedOrder(null)
        setDialogOpen(false)  
    }

    useEffect(() =>{
        
        getOngoingProcess()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[patient_id, imagingType])

    return(
        <> 
            <Row>
                <Col sm={12} className="mt-3">
                    {
                        imaging.ready ? 
                            imaging.data.length > 0 ?
                                imaging.data.map((data, index) =>{
                                    return(
                                        <Card 
                                            key={index} 
                                            className="bg-white mb-2" 
                                            style={{borderLeft: '.2em solid #ffc107'}}
                                        >  
                                            <Card.Body className="pb-0">
                                                <div className="text-capitalize gtc-text-purple font-weight-bold"> 
                                                    <Badge 
                                                        color="secondary"
                                                        badgeContent={
                                                            Boolean(parseInt(checkPatientUnreadNotif('imaging', 'ongoing', data.imaging_center_id))) ? 'new' : 0
                                                        }
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right'
                                                        }} 
                                                    >
                                                        { data.imaging_order } 
                                                    </Badge>
                                                </div>

                                                <div> 
                                                    { data.imaging_result } 
                                                </div> 
                                                
                                                <div className="gtc-small py-2 text-uppercase">
                                                    <span  
                                                        className="text-primary pointer"  
                                                        imagingid={data.imaging_center_id} 
                                                        onClick={ ()=> {
                                                            setSelectedOrder(data)
                                                            setSelectedDialog('details')
                                                            setDialogOpen(true)  
                                                        }}
                                                    >
                                                        details 
                                                    </span>

                                                    { 
                                                        data.imaging_result_attachment === null ? (
                                                            <span className="ml-3 text-secondary" > attachment </span>
                                                        ):(
                                                            <span 
                                                                className="ml-3 text-primary pointer" 
                                                                onClick={() =>{
                                                                    setSelectedOrder(data.imaging_result_attachment )
                                                                    setSelectedDialog('attachment')
                                                                    setDialogOpen(true)  
                                                                }}
                                                            > 
                                                                attachment
                                                            </span>
                                                        )
                                                    }  

                                                    <span className="ml-2 text-muted" > 
                                                        processed on { data.start_time === null ? 'not-set' : Notify.createdAt(data.start_time) } 
                                                    </span>
                                                </div>

                                            </Card.Body> 
                                        </Card>
                                    )
                                })
                            :   Notify.noRecord()
                        :   Notify.loading()
                    }
                </Col>
            </Row>

            {/* Details Dialog */}  
            <Dialog 
                open={ dialogOpen } 
                onClose={ closeDialog }
                fullWidth
                maxWidth="sm"
            >
                <Box display="flex">
                    <Box flexGrow={1}> 
                        <DialogTitle> 
                            ORDER INFORMATION 
                        </DialogTitle>
                    </Box>
                    <Box mr={2} mt={1}>
                        <IconButton 
                            onClick={ closeDialog }
                            color="secondary"
                        >
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContent dividers>
                    {  selectedOrder !==null && selectedDialog === 'details' && ( <ImagingProcessedDetails imagingType ={ imagingType } details = { selectedOrder } /> ) }
                    {  selectedOrder !==null && selectedDialog === 'attachment' && ( <ImagingProcessedAttachment imagingType ={ imagingType }  details = { selectedOrder } />   ) } 
                </DialogContent>
            </Dialog> 
        </>
    )
}

export default ImagingOngoing; 