import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';

function About (){
    return(
        <Fragment>
            <Col sm={12} className="mt-4">
                <Row>
                    <Col sm={12} md={6} xl={8}>
                        <div className="textside">
                            <h3 className="text-center mb-4">Welcome to <span className="text-primary">Global Telemedicine Corp </span></h3>
                            <ul className="pl-4">
                                <h5 className="about-text" style={{fontWeight: 'bolder'}}>Mission</h5>
                                    <p className="text-justify" style={{textIndent: '0.5cm', lineHeight: 2.5}}> 
                                        To develop a healthcare system and services by using state of the art technologies that would be able to integrate healthcare professionals, providers and patients
                                        around the world - 24/7, to ultimately improve the management of patients.
                                    </p>
                                <h5 className="about-text" style={{fontWeight: 'bolder'}}>Vission</h5>
                                <p className="text-justify" style={{textIndent: '0.5cm', lineHeight: 2.5}}> 
                                    To provide a healthcare system that is easy to use, accurate and secure.
                                </p>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6} xl={4} className="text-center mx-auto order-sm-1">
                        <div className=" d-none d-md-block">
                            <img src="/about.jpg" className=" img-fluid d-flex mx-auto rounded  col-sm-12" alt=""/> 
                        </div>
                    </Col>
                </Row>
            </Col>
        </Fragment>
    )
}

export default About ;