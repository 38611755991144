import { Box, Typography, makeStyles, Accordion, AccordionSummary, AccordionDetails, Card } from '@material-ui/core';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import ClinicList from './ClinicList';
import DoctorsList from './DoctorsList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchAppointmentDocList from './SearchAppointmentDocList';
import { grey } from '@material-ui/core/colors'
import Axios from 'axios';
import Notify from '../../notification/Notify';;

const SearchAppointment = () => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(null);
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [docSpecial, setDocSpecial] = useState([]);

    const getAllDocSpecialization = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        Axios.post('patient/appointment/get-all-doc-special', formdata)
            .then((response) => {
                const data = response.data;
                setDocSpecial(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id])

    useEffect(() => {
        getAllDocSpecialization();
    }, [getAllDocSpecialization])

    return (
        <Fragment>
            <Box display="flex" m={2} mb={1} mt={1}>
                <Box flexGrow={1}>
                    <Typography style={{ marginTop: 30 }} variant={'subtitle2'} color="primary">
                        GTC DOCTORS LIST
                    </Typography>
                </Box>
                <Box>
                    <Card
                        elevation={0}
                        component={Box}
                        border={1}
                        borderColor={grey[300]}
                        display="flex"
                        alignItems='center'
                        width={300}
                    >
                        <SearchAppointmentDocList />
                    </Card>
                </Box>
            </Box>
            <Box m={2} mt={0}>
                {docSpecial.length > 0 ?
                    docSpecial.map((data, index) => {
                        return (
                            <Accordion key={index} expanded={expanded === data.specialization} onChange={() => setExpanded(expanded === data.specialization ? null : data.specialization)} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color="primary" />}
                                >
                                    <Typography
                                        className={classes.heading}
                                    > {data.specialization} </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {expanded === data.specialization ? <DoctorsList specialization={data.specialization} /> : null}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                    : null}
            </Box>

            <Box m={2}>
                <Box mb={1}>
                    <Typography variant={'subtitle2'} color="primary">
                        GTC CLINIC LIST
                    </Typography>
                </Box>
                <Box>
                    <ClinicList />
                </Box>
            </Box>
        </Fragment >
    )
}

export default SearchAppointment;

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));