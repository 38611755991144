
import React, { Component, Fragment } from 'react';  

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Paper, Typography } from '@material-ui/core';

export default class PersonalAllergies extends Component{
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
            _patient: this.props.patient,
            _enableedit: false,   
            _allergies: this.props.patient[0].allergies === null ? 'None' : this.props.patient[0].allergies,
            isprocess: false
        }  
    } 

	render(){ 
		return(
			<Fragment>	   
                <Box
                    p={2}
                    component={Paper} 
                    variant="outlined"
                >
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Box>
                                <Typography variant="subtitle2" color="primary">
                                    ALLERGIES MONITORING
                                </Typography>
                                <Typography color="textSecondary" variant="caption">
                                    Only your appointed doctor can add allergies.
                                </Typography>
                            </Box> 
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Box mt={2} dangerouslySetInnerHTML={{__html: this.state._allergies }}/> 
                        </Grid>
                    </Grid>
                </Box>
			</Fragment>
		)
	}
}
 
 