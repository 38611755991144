import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Typography, TextField, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination, InputAdornment } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';

function LogActivity() {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [log, setLog] = useState([]);
    const [logReady, setLogReady] = useState(false);
    const [search, setSearch] = useState('')

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchLogActivity = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);

        Axios.post('malita/pharmacy/get-log-list', formdata)
            .then((response) => {
                const data = response.data;
                setLog(data);
                setLogReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, management_id])

    useEffect(() => {

        fetchLogActivity()

    }, [fetchLogActivity])

    const searchable = log.filter((data) => {
        return data.product.toLowerCase().indexOf(search.trim()) !== -1
    })

    return (
        <Fragment>
            <TableContainer component={Box} borderColor="grey.200" bgcolor="white">
                <Box display="flex">
                    <Box flexGrow={1} />
                    <Box mb={1}>
                        <TextField
                            label="Search brand"
                            variant="outlined"
                            margin="dense"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"> Date </TableCell>
                            <TableCell align="center"> Added By </TableCell>
                            <TableCell align="center"> Brand </TableCell>
                            <TableCell align="center"> Generic </TableCell>
                            <TableCell align="center"> Qty </TableCell>
                            <TableCell align="center"> Unit </TableCell>
                            <TableCell align="center"> Type </TableCell>
                            <TableCell align="center"> Supplier </TableCell>
                            <TableCell align="center"> Invoice/DR </TableCell>
                            <TableCell align="center"> Remarks </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            logReady ?
                                log.length > 0 ?
                                    searchable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((data, index) => (
                                            <TableRow key={index}>
                                                <TableCell bgcolor="white" align="right"> {Notify.dateTimeConvert(data.created_at)} </TableCell>
                                                <TableCell bgcolor="white">{data.pharmName}</TableCell>
                                                <TableCell bgcolor="white">{data.product}</TableCell>
                                                <TableCell bgcolor="white">{data.description}</TableCell>
                                                <TableCell bgcolor="white" align="right">{data.quantity}</TableCell>
                                                <TableCell bgcolor="white">{data.unit}</TableCell>
                                                <TableCell bgcolor="white" align="center">{data.request_type}</TableCell>
                                                <TableCell bgcolor="white">{data.supplier}</TableCell>
                                                <TableCell bgcolor="white" align="right">{data.dr_no}</TableCell>
                                                <TableCell bgcolor="white">{data.remarks}</TableCell>
                                            </TableRow>
                                        ))
                                    : <TableRow>
                                        <TableCell colSpan={10}>
                                            <Typography variant="subtitle2" color="secondary"> No logs added </Typography>
                                        </TableCell>
                                    </TableRow>
                                : <TableRow>
                                    <TableCell colSpan={10}>
                                        <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>

                <TablePagination
                    component={"div"}
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={log.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Fragment>
    )

}

export default LogActivity;