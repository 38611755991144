import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid' 
import Alert from '@material-ui/lab/Alert'  
import Typography from '@material-ui/core/Typography';  
import BellIcon from '@material-ui/icons/NotificationsNone';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'; 
import Avatar from '@material-ui/core/Avatar';  
import Notify from '../notification/Notify';
import Axios from 'axios';  
import AppointmentReport from './appointment-local/AppointmentReport';
import AppointmentDetails from './appointment-local/AppointmentDetails';
import { blue } from '@material-ui/core/colors';
import { PatientsUnRead } from 'src/ContextAPI';
import { Badge, Paper } from '@material-ui/core';

const imageLocation = process.env.REACT_APP_API_IMAGE;  

function LocalAppointment() {   
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id')) 
    const [unapproveapp, setunapproveapp] = useState([])  
    const [unapproveappready, setunapproveappready] = useState(false) 
 
    const [selected_appid, setselected_appid] = useState(null);
    const [detailsview, setdetailsview] = useState(false) 

    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( category, patient_id ) =>{ 

        let xx = unread.unviewNotif; 
        let count = 0; 

        for (let i = 0; i < xx.length; i++) {
            if(category === xx[i].category && patient_id === xx[i].patient_id){
                count += 1;
            }
        }  
        
        return count;
    }


    const getIncompleteLocalApp = useCallback(() =>{   
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);     
        
        Axios.post('doctors/appointment/local/incomplete-list', formdata )
        .then( (response) =>{ 
            const data = response.data;    
            setunapproveapp(data)
            setunapproveappready(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id]); 

    const handleSelectedLocalApp = (e) =>{ 
        setselected_appid(e.currentTarget.getAttribute('appid')) 
        setdetailsview(true)
    }

    const resetdisplay = () =>{
        setdetailsview(false)
        getIncompleteLocalApp()
    }

    useEffect(() =>{ 
  
        getIncompleteLocalApp()
 
    }, [getIncompleteLocalApp])
    

    return (
        <Fragment>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}> 
                        <Box>
                            {
                                unapproveapp.length > 0 && (
                                    <Box
                                        border={1}
                                        borderRadius={4} 
                                        borderColor={blue[100]}
                                    > 
                                        <Alert 
                                            icon={<BellIcon style={{ fontSize: '2.2em'}} /> }   
                                            severity="info"  
                                        >  
                                            <Typography variant="subtitle2"> NEXT APPOINTMENT </Typography>
                                            <Typography variant="body2"> { Notify.dateTimeConvert(unapproveapp[0].app_date) }  </Typography>
                                        </Alert>
                                    </Box>
                                )
                            }
                            <Box mt={2} p={2} component={Paper}> 
                                <Typography color="primary" variant="subtitle2" >
                                    LOCAL APPOINTMENT
                                </Typography>

                                <List component="nav" disablePadding={true}>
                                    {
                                        unapproveappready ? 
                                            unapproveapp.length > 0 ?
                                                unapproveapp.map((data, index)=>{
                                                    return(  
                                                        <ListItem 
                                                            button 
                                                            key={index}
                                                            appid={data.appointment_id} 
                                                            patient_id={data.patients_id} 
                                                            className={data.appointment_id === selected_appid ? "text-danger" : "pointer"} 
                                                            onClick={ handleSelectedLocalApp }   
                                                        >  
                                                            <ListItemIcon> 
                                                                <Badge
                                                                    color="secondary"
                                                                    badgeContent={`new`}
                                                                    invisible={ ! Boolean(parseInt(checkPatientUnreadNotif('appointment', data.patients_id))) }
                                                                >
                                                                    <Avatar className= {data.appointment_id === selected_appid ? "bg-danger" : ""}> 
                                                                        { 
                                                                            data.patients_image === null ?  data.patients_name.charAt().toUpperCase()  : 
                                                                            <img src={imageLocation+'patients/'+data.patients_image} alt="" className="img-fluid"/>
                                                                        }
                                                                    </Avatar> 
                                                                </Badge> 
                                                            </ListItemIcon>
                                                            
                                                            <ListItemText 
                                                                primary={ <Typography noWrap className={`gtc-capitalize`}> {   data.patients_name  } </Typography>}
                                                                secondary={
                                                                    Boolean(parseInt(data.is_reschedule)) ? 
                                                                    <Typography noWrap > 
                                                                        <small className="text-muted"> 
                                                                            <b className="text-danger"> resched </b> on 
                                                                            { Notify.dateTimeConvert(data.is_reschedule_date) }  
                                                                        </small>
                                                                    </Typography> : 
                                                                    <Typography noWrap > 
                                                                        <small className="text-muted"> on { Notify.dateTimeConvert(data.app_date)}  </small></Typography> 
                                                                } 
                                                            />  
                                                        </ListItem>   
                                                    )
                                                })
                                            : Notify.noRecord()
                                        : Notify.loading()
                                    } 
                                </List>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}> 
                        { detailsview ? <AppointmentDetails resetdisplay = { () => resetdisplay() } appid={selected_appid} /> : <AppointmentReport />} 
                    </Grid>
                </Grid>
            </Box>
            {/* action di */}
        </Fragment>
    )
}

export default LocalAppointment;
