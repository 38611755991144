import { Box, CardMedia, Typography } from '@material-ui/core';
import React from 'react';

const imageLocation = process.env.REACT_APP_API_IMAGE;


const Footer = ({ formheader }) =>{
    
    return(
        <> 
            <Box display="flex" my={3}>
                <Box flexGrow={1}>
                    <Box align="center" width={250}>
                        <Box>
                            { formheader.pathologist_signature ? (
                                <CardMedia 
                                    component="img"
                                    src={`${imageLocation}laboratory/pathologist/${formheader.pathologist_signature}`}
                                    style={{
                                        width: 100,
                                        top: 20,
                                        position: 'relative'
                                    }}
                                />
                            ) : <Box mt={5}/> } 
                        </Box>
                        <Typography className={`gtc-uppercase`} variant="subtitle2">  { formheader && (formheader.pathologist)} </Typography>
                        <Box borderTop={1} > 
                            <b> Pathologist (LIC No. { formheader && (formheader.pathologist_lcn) }) </b>
                        </Box> 
                    </Box> 
                </Box>

                <Box>
                    <Box align="center"  width={250}>
                        <Box mt={5}>
                            <Typography className={`gtc-uppercase`} variant="subtitle2"> { formheader && (formheader.medtech) } </Typography>
                            <Box borderTop={1}>
                                <b> Medical Technologist  </b>
                            </Box> 
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default Footer;