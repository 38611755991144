
import React, { useEffect } from 'react'; 

const RTCMultiConnection = require('rtcmulticonnection');

const GTCMvcall = () => {  
    
    var connection = new RTCMultiConnection();
    connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';
    connection.enableLogs = false;
    connection.session = {
        audio : true,
        video : true
    } 

    connection.sdpConstraints.mandatory = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true,
    }   
  
    const room_id = 'givenIds';
    

    const handleCreateRoom = () =>{
        connection.open('givenIds') 
    } 

    const handleJoinBtn = () =>{   
        connection.checkPresence(room_id, function(isRoomExist, roomid) {
            if (isRoomExist === true) {
                connection.join(roomid);
            } else {
                // connection.open(roomid);
                console.log('room not found.')
            }

            console.log(isRoomExist, roomid)
        });
    } 

    const generateRoomId = () =>{
        var id = `u-12318273912u-234234234${new Date().getTime()}`;
            id = id.replace(/-/g, '');
            // Notify.randomizeString(id)
            console.log(id)
    }  

    useEffect(() =>{ 

        connection.onleave = function(event) {
            var remoteUserId = event.userid;
            console.log(remoteUserId, 'is leaving.')
        };  
        
        generateRoomId()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleDisconnectBtn = () =>{
        console.log('disconnect not working:', connection.userid)
        connection.getAllParticipants().forEach(function(participantId) {
            connection.disconnectWith( participantId );
            console.log('leaving:, ', participantId)
        });
    }

    console.log('userid', connection.userid)

    return(

        <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <button id="btn-open-room" onClick= { handleCreateRoom }>Open Room</button> 
            <button id="btn-join-room" onClick= { handleJoinBtn }>joinRoom</button> 
            <button id="btn-join-room" onClick= { handleDisconnectBtn }> disconnect </button> 

            
        </>
    )
}

export default GTCMvcall;