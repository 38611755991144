
import React, { Component, Fragment } from 'react';   
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Col, Row } from 'react-bootstrap';
import Box from '@material-ui/core/Box'
import { Badge, Paper, TextField } from '@material-ui/core';
import Label from 'src/utils/Label';

export default class PersonalHistory extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'), 

            _history: [],
            _history_ready: false,
		}
	}

    componentDidMount(){
        this._mounted = true;
        this.getPatientHistory();
    }

    componentWillUnmount(){
        this._mounted = false; 
    }

    getPatientHistory(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('management_id', this.state._management_id);   
        formdata.set('user_id', this.state._user_id);    
        
        Axios.post('patient/information/personal-history', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _history: data,
                    _history_ready: true,
                })
            }
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

	render(){ 
		return(
			<Fragment>	   
                <Box
                    component={Paper}
                    variant="outlined"
                    p={2} 
                >
                    {
                        this.state._history_ready ? 
                            this.state._history.length > 0 ? 
                                this.state._history.map( (data, index) => {
                                    return(
                                        <Row key={index} className="py-2 gtc-textfield-noborder">
                                            <Col sm={12}> 

                                                <Box mb={2} display="flex" justifyContent="flex-start">
                                                    <Badge color="error" badgeContent={index + 1} > <Label color="primary"> visit count </Label> </Badge>
                                                </Box>

                                                <Row className="mb-3">
                                                    <Col sm={6}> 
                                                        <TextField
                                                            fullWidth
                                                            label="Weight (kg)"
                                                            variant="outlined"
                                                            value={data.weight === null ? 'None' : data.weight }
                                                            InputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Col>

                                                    <Col sm={6}> 
                                                        <TextField
                                                            fullWidth
                                                            label="Height (ft)"
                                                            variant="outlined"
                                                            value={data.height === null ? 'None' : data.height }
                                                            InputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col sm={6}> 
                                                        <TextField
                                                            fullWidth
                                                            label="Occupation"
                                                            variant="outlined"
                                                            value={data.occupation === null ? 'None' : data.occupation }
                                                            InputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Col>

                                                    <Col sm={6}> 
                                                        <TextField
                                                            fullWidth
                                                            label="Zip"
                                                            variant="outlined"
                                                            value={data.zip === null ? 'None' : data.zip }
                                                            InputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className="mb-3">
                                                    <Col sm={12}> 
                                                        <TextField
                                                            fullWidth
                                                            label="Addresss"
                                                            variant="outlined"
                                                            value={data.city === null ? 'None' :` ${data.street}  ${data.barangay} ${data.city}` }
                                                            InputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Col> 
                                                </Row>
                                            </Col> 
                                        </Row>
                                    )
                                })
                            :
                            Notify.noRecord()
                        :
                        Notify.loading()
                    }
                </Box>
			</Fragment>
		)
	}
}
