import React, { Fragment, useState } from 'react';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import { Box, Grid, Button, TextField, FormHelperText } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

function DeleteQtySpecificBatch({ selectedDataArray, fetchInventoryList, getBatchesByProductID, backDefaultView }) {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [username] = useState(localStorage.getItem('username'))
    const [pharmacy_id] = useState(localStorage.getItem('pharmacy_id'))

    const getFormData = (object) => {
        const formData = new FormData();

        formData.append('product', selectedDataArray.product)
        formData.append('product_id', selectedDataArray.product_id)
        formData.append('description', selectedDataArray.description)
        formData.append('supplier', selectedDataArray.supplier)
        formData.append('quantity', selectedDataArray.quantity)

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    return (
        <Fragment>
            <Formik
                initialValues={{
                    user_id: user_id,
                    token: token,
                    username: username,
                    management_id: management_id,
                    pharmacy_id: pharmacy_id,
                    manufacture: selectedDataArray.manufacture_date,
                    batch_no: selectedDataArray.batch_no,
                    expiry: selectedDataArray.expiry_date,
                    unit: selectedDataArray.unit,
                    reason: '',
                    password: '',
                }}
                validationSchema={Yup.object().shape({
                    reason: Yup.string().required(),
                    password: Yup.string().required()
                })}
                onSubmit={async (values, {
                    setErrors,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        const request = await Axios.post('malita/pharmacy/del-qty-by-specific', getFormData(values))
                        if (request.data === 'pass-invalid') {
                            setErrors({ password: "Password doesn't matched" });
                            Notify.customToast("Invalid Password", "Password doesn't matched")
                        }
                        if (request.data === 'success') {
                            Notify.successRequest('delete batch')
                            fetchInventoryList();
                            getBatchesByProductID();
                            resetForm();
                            setSubmitting(true);
                            backDefaultView();
                        }
                    } catch (error) {
                        const message = error.message || 'Something went wrong';
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    resetForm
                }) => (
                    <form
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Box flexGrow={1}>
                            <Grid container spacing={2}>
                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            required
                                            fullWidth
                                            label="Manufacture Date"
                                            error={Boolean(touched.manufacture && errors.manufacture)}
                                            helperText={touched.manufacture && errors.manufacture}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.manufacture}
                                            name="manufacture"
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            error={Boolean(touched.batch_no && errors.batch_no)}
                                            helperText={touched.batch_no && errors.batch_no}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.batch_no}
                                            fullWidth
                                            required
                                            multiline
                                            name="batch_no"
                                            label="Batch Number"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>
                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            required
                                            fullWidth
                                            label="Expiry Date"
                                            error={Boolean(touched.expiry && errors.expiry)}
                                            helperText={touched.expiry && errors.expiry}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.expiry}
                                            name="expiry"
                                            variant="outlined"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={6} item>
                                    <Box mb={1}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            error={Boolean(touched.unit && errors.unit)}
                                            helperText={touched.unit && errors.unit}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.unit}
                                            fullWidth
                                            required
                                            multiline
                                            name="unit"
                                            label="Unit"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={12} item>
                                    <Box mb={1}>
                                        <TextField
                                            error={Boolean(touched.reason && errors.reason)}
                                            helperText={touched.reason && errors.reason}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.reason}
                                            fullWidth
                                            required
                                            multiline
                                            name="reason"
                                            label="Reason"
                                            variant="outlined"
                                        />
                                    </Box>
                                </Grid>

                                <Grid xs={12} item>
                                    <Box mb={1}>
                                        <TextField
                                            fullWidth
                                            required
                                            error={Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.password}
                                            name="password"
                                            label="Enter your password"
                                            variant="outlined"
                                            type="password"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        {errors.submit && (
                            <Box mt={2} >
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}

                        <Box mt={2} mb={2} display="flex">
                            <Box flexGrow={1} />

                            <Button
                                variant="contained"
                                color="default"
                                onClick={() => backDefaultView()}
                                startIcon={<ArrowBackIcon />}
                            >
                                Back
                                    </Button>

                            <Box ml={2}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CheckIcon />}
                                    disabled={isSubmitting}
                                >
                                    Save
                                        </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </Fragment>
    )

}

export default DeleteQtySpecificBatch;