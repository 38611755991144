import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Typography, TextField, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, Divider, Paper, Button, Avatar, Dialog, Zoom, DialogTitle, DialogContent } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import VirtualProcess from './VirtualModal/VirtualProcess';
import VirtualPrintRx from './VirtualModal/VirtualPrintRx';
import CropFreeIcon from '@material-ui/icons/CropFree';
import VirtualQR from './VirtualModal/VirtualQR';
import { HighlightOffOutlined } from '@material-ui/icons';
import Label from 'src/utils/Label';

const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;

function VirtualDetails({ selectedOrder, closeDetails }) {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [clickEdit, setClickEdit] = useState(false);
    const [details, setDetails] = useState([]);
    const [detailsReady, setDetailsReady] = useState(false);
    const [processDialog, setProcessDialog] = useState(false);
    const [rxDialog, setRxDialog] = useState(false);
    const [selectedPId, setSelectedPId] = useState(null);
    const [selectedDId, setSelectedDId] = useState(null);
    const [selectedClaimId, setSelectedClaimId] = useState(null);
    const [selectedPharmaID, setSelectedPharmaID] = useState(null);
    const [qRDialog, setQRDialog] = useState(false);

    const handleUpdateQuantity = (e, id) => {
        details.forEach((item, index) => {
            if (item.id === id)
                item.quantity = e;
        })
    }

    const fetchDetail = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);
        formdata.set('order_id', selectedOrder)
        formdata.set('connection', 'online')

        Axios.post('malita/pharmacy/get-prescription-details', formdata)
            .then((response) => {
                const data = response.data;
                setDetails(data);
                setDetailsReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, selectedOrder, management_id])


    const updateQty = () => {
        var formdata = new FormData();
        formdata.append('token', token);
        formdata.append('order_id', selectedOrder);
        formdata.append('connection', 'online')

        var error = [];

        Object.keys(details).forEach(key => {
            if (parseInt(details[key].quantity) > parseInt(details[key].maxQtyProd)) {
                error = 'error'
                Notify.customToast(`${details[key].product} Invalid Qty`, 'Check your qty inputed.')
            }
            formdata.append(`id[]`, details[key].id)
            formdata.append(`quantity[]`, details[key].quantity)
        });

        if (error.length > 0) {
            console.log('oyyyyyyyyyyyyyyyyyy malika.')
        } else {
            Axios.post('malita/pharmacy/get-prescription-update-qty', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        Notify.successRequest('update qty')
                        setClickEdit(false);
                        fetchDetail();
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
    }

    const handleBroadcast = () => {
        var formdata = new FormData();
        formdata.append('token', token);
        formdata.append('order_id', selectedOrder);
        formdata.append('connection', 'online')

        Axios.post('malita/pharmacy/broadcast-order', formdata)
            .then((response) => {
                const data = response.data;
                if (data === 'success') {
                    Notify.successRequest('broadcast');
                    fetchDetail();
                }
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchDetail()
    }, [fetchDetail])

    return (
        <Fragment>
            {detailsReady ?
                <Box component={Paper} variant="outlined" p={2} mb={2}>
                    {
                        detailsReady ?
                            details.length > 0 ?
                                <>
                                    <Box display="flex" mb={1}>
                                        <Box flexGrow={1} />
                                        <Box>
                                            <Label color={parseFloat(details[0].delivery) === 1 ? "primary" : "success"}>
                                                <Typography > For
                                                    <strong> {parseFloat(details[0].delivery) === 1 ? "Delivery" : "Pick up"} </strong>
                                                </Typography>
                                            </Label>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <div className="clearfix mt-2 mb-2">
                                        <div className="float-left">
                                            <Avatar
                                                style={{
                                                    width: '120px',
                                                    height: '120px'
                                                }}
                                                src={details[0].patientImage !== null ? imageLocation + 'patients/' + details[0].patientImage : ''}
                                            >
                                                {details[0].patientImage === null ? 'No Image' : ''}
                                            </Avatar>
                                        </div>
                                        <div className="ml-sm-4 float-left">
                                            <p className="h5 header-label text-capitalize m-0 my-2 font-weight-bold" >
                                                {details[0].patientLName}, {details[0].patientFName}  {details[0].patientMName}
                                            </p>
                                            <p className="m-0 my-2">
                                                <span className="text-muted gtc-small text-uppercase"> Age: </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {Notify.calculateAge(details[0].patientBDay)}
                                            </p>
                                            <p className="m-0 my-2">
                                                <span className="text-muted gtc-small text-uppercase"> Gender: </span> &nbsp; {details[0].patientGender}
                                            </p>
                                            <p className="m-0 my-2">
                                                <span className="text-muted gtc-small text-uppercase"> Order #: </span> &nbsp; {details[0].order_no}
                                            </p>
                                        </div>
                                        <div className="ml-sm-4 float-right">
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    setRxDialog(true);
                                                    setSelectedPId(details[0].patient_id);
                                                    setSelectedDId(details[0].doctor_ID)
                                                    setSelectedClaimId(details[0].rxNumber)
                                                }}
                                                startIcon={<VisibilityIcon />}
                                            >
                                                Rx
                                            </Button><br />
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    setQRDialog(true);
                                                    setSelectedPharmaID(details[0].order_toID);
                                                }}
                                                startIcon={<CropFreeIcon />}
                                            >
                                                QR
                                            </Button>
                                        </div>
                                    </div>
                                </>
                                :
                                Notify.noRecord()
                            :
                            Notify.loading()
                    }
                    <Divider />
                    <TableContainer component={Box} mt={2}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"> PRODUCT </TableCell>
                                    <TableCell align="center"> QTY </TableCell>
                                    <TableCell align="center"> PRICE </TableCell>
                                    <TableCell align="center"> {clickEdit ? 'ACTION' : 'AMOUNT'}  </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    clickEdit ?
                                        details.length > 0 ?
                                            details.map((data, index) => (
                                                <TableRow key={index}>
                                                    <TableCell bgcolor="white" > {data.product} </TableCell>
                                                    <TableCell bgcolor="white" align="center">
                                                        <Box>
                                                            <TextField
                                                                defaultValue={data.quantity}
                                                                margin="none"
                                                                width={80}
                                                                required
                                                                name="creatinine"
                                                                type="number"
                                                                label=""
                                                                onChange={(e) => handleUpdateQuantity(e.target.value, data.id)}
                                                                InputProps={{
                                                                    inputProps: {
                                                                        max: data.maxEntryEdit,
                                                                        min: 0,
                                                                        step: 1
                                                                    }
                                                                }}
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell bgcolor="white" align="right">
                                                        {Notify.numberFormat(data.price)}
                                                    </TableCell>
                                                    <TableCell bgcolor="white" align="right">
                                                        {Notify.numberFormat(parseFloat(data.price) * parseFloat(data.quantity))}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            : <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography variant="subtitle2" color="secondary"> No purchase added </Typography>
                                                </TableCell>
                                            </TableRow>
                                        :
                                        details.length > 0 ?
                                            details.map((data, index) => (
                                                <TableRow key={index}>
                                                    <TableCell bgcolor="white" > {data.product} </TableCell>
                                                    <TableCell bgcolor="white" align="right">{data.quantity}</TableCell>
                                                    <TableCell bgcolor="white" align="right">
                                                        {Notify.numberFormat(data.price)}
                                                    </TableCell>
                                                    <TableCell bgcolor="white" align="right">
                                                        {
                                                            parseFloat(data.quantity) === 0 || data.quantity === null ?
                                                                '0.00'
                                                                : Notify.numberFormat(parseFloat(data.price) * parseFloat(data.quantity))
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            : <TableRow>
                                                <TableCell colSpan={5}>
                                                    <Typography variant="subtitle2" color="secondary"> No purchase added </Typography>
                                                </TableCell>
                                            </TableRow>
                                }

                                {
                                    parseFloat(details.length) > 0 && (
                                        <Fragment>
                                            <TableRow>
                                                <TableCell colSpan={3} align="right">
                                                    Total Amount
                                                </TableCell>

                                                <TableCell align="right">
                                                    {Notify.numberFormat(details[0].totalOverallRX)}
                                                </TableCell>
                                            </TableRow>
                                            {
                                                parseFloat(details[0].delivery) !== 1 && (
                                                    parseFloat(details[0].TotalUnpaid) > 0 && (
                                                        <Fragment>
                                                            <TableRow>
                                                                <TableCell colSpan={3} align="right"> Unpaid Consultation </TableCell>
                                                                <TableCell align="right">
                                                                    {Notify.numberFormat(details[0].TotalUnpaid)}
                                                                </TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell colSpan={3} align="right"> Total Cost </TableCell>
                                                                <TableCell align="right">
                                                                    {Notify.numberFormat(parseFloat(details[0].totalOverallRX) + parseFloat(details[0].TotalUnpaid))}
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    )
                                                )
                                            }
                                        </Fragment>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box display="flex" mt={2}>
                        <Box flexGrow={1}>
                            {
                                parseFloat(details.length) > 0 && (
                                    parseFloat(details[0].delivery) === 1 && (
                                        <Box>
                                            <Button
                                                disabled={parseFloat(details[0].countBroadcastStatus) > 0}
                                                className={'d-print-none'}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleBroadcast()}
                                            >
                                                {
                                                    parseFloat(details[0].countBroadcastStatus) > 0 ?
                                                        'Broadcasting'
                                                        : 'Broadcast'
                                                }
                                            </Button>
                                        </Box>
                                    )
                                )
                            }
                        </Box>
                        <Box>
                            <Button className={'d-print-none'} variant="contained" color="secondary" onClick={() => closeDetails()} startIcon={<HighlightOffOutlined />}>
                                Close
                            </Button>
                        </Box>
                        {
                            clickEdit ?
                                <Fragment>
                                    <Box ml={2}>
                                        <Button className={'d-print-none'} variant="contained" color="default" onClick={() => { setClickEdit(!clickEdit); fetchDetail(); }} startIcon={<KeyboardBackspaceIcon />}>
                                            Back
                                        </Button>
                                    </Box>
                                    <Box ml={2}>
                                        <Button className={'d-print-none'} variant="contained" color="primary" onClick={() => updateQty()} startIcon={<CheckCircleIcon />} >
                                            Save
                                        </Button>
                                    </Box>
                                </Fragment>
                                :
                                <Fragment>
                                    <Box ml={2}>
                                        <Button className={'d-print-none'} variant="contained" color="default" onClick={() => setClickEdit(!clickEdit)} startIcon={<EditIcon />}>
                                            Edit
                                        </Button>
                                    </Box>
                                    <Box ml={2}>
                                        <Button disabled={parseFloat(details[0].countBroadcastStatus) === 0 && parseFloat(details[0].delivery) > 0} className={'d-print-none'} variant="contained" color="primary" onClick={() => setProcessDialog(true)} startIcon={<CheckCircleIcon />} >
                                            Process
                                        </Button>
                                    </Box>
                                </Fragment>
                        }
                    </Box>

                    <Dialog
                        open={processDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        TransitionComponent={Zoom}
                        transitionDuration={1000}
                        disableBackdropClick={true}
                        maxWidth="sm"
                        fullWidth
                    >
                        <DialogTitle id="alert-dialog-title">{"Payment Form"}</DialogTitle>
                        <DialogContent dividers>
                            <VirtualProcess details={details} closeModal={() => setProcessDialog(false)} />
                        </DialogContent>
                    </Dialog>


                    <Dialog
                        disableBackdropClick
                        open={rxDialog}
                        TransitionComponent={Zoom}
                        transitionDuration={600}
                        onClose={() => setRxDialog(false)}
                        fullScreen
                    >
                        <VirtualPrintRx open={rxDialog} close={() => setRxDialog(false)} claim_id={selectedClaimId} patient_id={selectedPId} doctors_id={selectedDId} />
                    </Dialog>

                    <Dialog
                        disableBackdropClick
                        open={qRDialog}
                        TransitionComponent={Zoom}
                        transitionDuration={600}
                        onClose={() => setQRDialog(false)}
                        fullWidth
                        maxWidth="xs"
                    >
                        <VirtualQR open={qRDialog} close={() => setQRDialog(false)} selectedPharmaID={selectedPharmaID} />
                    </Dialog>

                </Box>
                : null}
        </Fragment>
    )
}

export default VirtualDetails;