
import React, { useContext } from 'react'
import { Badge, IconButton, Snackbar, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import LockIcon from '@material-ui/icons/Lock';  
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { PatientCategoryContext } from 'src/ContextAPI';

const SnackbarRequestPermission = ({ req_permission }) =>{
    const [open, setOpen] = React.useState(true)
     
    const categoryContext = useContext(PatientCategoryContext) 

    return (
        <>
            { req_permission.length > 0 && (
                req_permission.map((data, index) =>( 
                    <Snackbar
                        key={ index }
                        open={ open } 
                        anchorOrigin={{  vertical: 'bottom', horizontal: 'right'  }} 
                    > 
                        <Alert 
                            severity="info" 
                            variant="filled"
                            icon={ <Badge color="secondary" badgeContent={index + 1}> <LockIcon /> </Badge>  } 
                            action={
                                <IconButton
                                    onClick = { () =>{
                                        setOpen(false)
                                        categoryContext._updateCategory('permission'); 
                                    }} 
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            }
                        >
                            <AlertTitle
                                className={`gtc-uppercase`}
                            > 
                                <Typography variant="caption">
                                    { data.order_status } 
                                </Typography>
                            </AlertTitle>
                            Doctor request permission to view your profile.
                        </Alert>     
                    </Snackbar> 
                ))
            )}
        </>
    )
}

export default SnackbarRequestPermission;