
import { Box, Typography, Paper, Collapse, IconButton } from '@material-ui/core';
import Axios from 'axios';
import React, { useState, useCallback, useEffect, Fragment } from 'react';
import Notify from 'src/notification/Notify'; 
import ClinicalChemistryOrderDetails from './details/ClinicalChemistryOrderDetails';
 
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ListAltIcon from '@material-ui/icons/ListAlt';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import WarningIcon from '@material-ui/icons/Warning'; 
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { grey } from '@material-ui/core/colors';


const ChemistryOrder = ({ patient_id }) => {

    const token  = localStorage.getItem('token')
    const user_id  = localStorage.getItem('user_id')

    const [order, setOrder] = useState({
        data: [],
        ready: false
    })

    const [selectedOrder, setSelectedOrder] = useState(null)    

    const getLabChemistryOrder = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('patient_id', patient_id);    
        
        Axios.post('laboratory/order/ordernew-clinicalchemistry', formdata )
        .then((response) => {  
            const data = response.data;      
            setOrder({
                data: data,
                ready: true
            })
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id, patient_id]);
    
    const resetDisplay = () =>{
        setSelectedOrder(null) 
    }

    useEffect(() =>{

        getLabChemistryOrder() 

    },[getLabChemistryOrder])

    return(
        <> 
            <Box my={1}>
                <Typography className={`gtc-uppercase`} variant="subtitle2">
                    <b> CLINICAL CHEMISTRY ORDER </b>
                </Typography>
            </Box>
            
            {
                order.ready ?
                    order.data.length > 0 ?
                        order.data.map((data, index) =>{
                            return(
                                <Fragment key={index}>
                                     <Paper component={Box} my={2} p={2}>
                                        <Box display="flex"> 
                                            <Box flexGrow={1}>
                                                <Box display="flex">
                                                    <Box mt={1} mr={2}> 
                                                        { 
                                                                Boolean(parseInt(data.is_processed)) ? (<QueryBuilderIcon style={{ fontSize: '2rem' }} color="primary"/>) 
                                                            :    Boolean(parseInt(data.is_pending)) ?  (<WarningIcon style={{ fontSize: '2rem' }} color="secondary" />) 
                                                            :   (<ListAltIcon style={{ fontSize: '2rem', color: grey[500] }} />)
                                                        }
                                                    </Box>

                                                    <Box>
                                                        <Box>
                                                            <Typography 
                                                                variant="subtitle1" 
                                                                className={`gtc-uppercase`}
                                                            > { Notify.dateTimeConvert(data.date_ordered) } </Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography 
                                                                variant="caption" 
                                                                color="textSecondary" 
                                                                className={`gtc-uppercase`}
                                                            > { data.order_id } </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <IconButton
                                                    color="primary" 
                                                    onClick={
                                                        () => setSelectedOrder(selectedOrder && selectedOrder.order_id === data.order_id ? null : data)
                                                    }
                                                >
                                                    {selectedOrder && selectedOrder.order_id === data.order_id ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                                                </IconButton>
                                            </Box>
                                        </Box>

                                        <> {/* order result form */}
                                            {
                                                selectedOrder && (
                                                    <Collapse in={selectedOrder.order_id === data.order_id}>
                                                        <Box mt={2}>
                                                            <ClinicalChemistryOrderDetails 
                                                                order = { selectedOrder }
                                                                getLabCliniclChemistryOrder = { getLabChemistryOrder }
                                                                resetDisplay = { resetDisplay }
                                                            />
                                                        </Box>
                                                    </Collapse> 
                                                )
                                            }
                                        </> 
                                    </Paper> 
                                </Fragment>
                            )
                        })
                    :   <Box>
                            { Notify.noRecord() }
                        </Box>
                :   <Box>
                        { Notify.loading() }
                    </Box>
            } 
        </>
    )
}

export default ChemistryOrder;