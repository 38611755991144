import React, { useState, useEffect, useCallback, useContext } from 'react';   

import Grid from '@material-ui/core/Grid'; 
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab'; 
import Box from '@material-ui/core/Box'; 
import makeStyles from '@material-ui/core/styles/makeStyles'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTablets, faDiagnoses, faHistory, faAllergies, faHeartbeat, faSyncAlt, faUtensils } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import PersonalBasic from './PersonalBasic'; 
import PersonalVitals from './PersonalVitals'; 
import PersonalAllergies from './PersonalAllergies'; 
import PersonalMedication from './PersonalMedication'; 
import PersonalDiagnosis from './PersonalDiagnosis'; 
import PersonalHistory from './PersonalHistory';    
import PersonalHistoryFamily from './PersonalHistoryFamily'; 
import PersonalDiet from './PersonalDiet';
import TabPanel from '../../utils/TabPanel';
import { Paper } from '@material-ui/core';
import { ThemeContext } from 'src/ContextAPI';
 
const useStyle = makeStyles({
    indicatorLeft: {
      left: "0px"
    },
    indicator: {
        backgroundColor: 'white',
        left: "0px"
    },
});
 
const Personal = () => {   
    const classes = useStyle();
    const [value, setValue] = useState(0);

    const [token] = useState(localStorage.getItem('token'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [user_id] = useState(localStorage.getItem('user_id'))
 
    const themContext = useContext(ThemeContext)

    const [patient, setPatient] = useState([])
    const [patientReady, setPatientReady] = useState(false)

    const handleChange = (event, newValue) => {
        event.persist()
        setValue(newValue);
    }; 

    const getPersonalInformation =  useCallback(() => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('management_id', management_id);   
        formdata.set('user_id', user_id);    
        
        Axios.post('patient/information/personal-info', formdata )
        .then((response) => { 
            const data = response.data;     
                setPatient(data)
                setPatientReady(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }, [token, management_id, user_id]);

    useEffect(()=>{

        getPersonalInformation()

    }, [getPersonalInformation])
     
    return(      
        <Grid container spacing={2}>
            <Grid 
                item 
                xs={9}
                md={10}
                xl={11}  
            >
                <TabPanel value={value} index={0}> 
                    {
                        patientReady ? 
                            patient.length > 0 ?
                                <PersonalBasic 
                                    getPersonalInformation = { () => getPersonalInformation() } 
                                    patient={ patient } 
                                />
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {
                        patientReady ? 
                            patient.length > 0 ?
                                <PersonalVitals 
                                    getPersonalInformation = { () => getPersonalInformation() } 
                                    patient={ patient } 
                                />
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {
                        patientReady ? 
                            patient.length > 0 ?
                                <PersonalAllergies 
                                    getPersonalInformation = { () => getPersonalInformation() } 
                                    patient={ patient } 
                                />
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </TabPanel>
                <TabPanel value={value} index={3}>
                    {
                        patientReady ? 
                            patient.length > 0 ?
                                <PersonalDiagnosis
                                    getPersonalInformation = { () => getPersonalInformation() } 
                                    patient={ patient } 
                                />
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </TabPanel>
                <TabPanel value={value} index={4}>
                    {
                        patientReady ? 
                            patient.length > 0 ?
                                <PersonalMedication
                                    getPersonalInformation = { () => getPersonalInformation() } 
                                    patient={ patient } 
                                />
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </TabPanel>  
                <TabPanel value={value} index={5}>
                    {
                        patientReady ? 
                            patient.length > 0 ?
                                <PersonalDiet
                                    getPersonalInformation = { () => getPersonalInformation() } 
                                    patient={ patient } 
                                /> 
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </TabPanel>  
                <TabPanel value={value} index={6}>
                    {
                        patientReady ? 
                            patient.length > 0 ?
                                <PersonalHistoryFamily 
                                    getPersonalInformation = { () => getPersonalInformation() } 
                                    patient={ patient } 
                                /> 
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </TabPanel>  
                <TabPanel value={value} index={7}>
                    {
                        patientReady ? 
                            patient.length > 0 ?
                                <PersonalHistory
                                    getPersonalInformation = { () => getPersonalInformation() } 
                                    patient={ patient } 
                                />
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </TabPanel>  
            </Grid>
            
            <Grid 
                item 
                xs={3}
                md={2}
                xl={1}  
            >
                <Box component={Paper} variant="outlined">
                    <Tabs 
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"  
                        textColor={themContext.gtcThemeDark ? "inherit" : 'primary'}
                        indicatorColor="primary"
                        classes={{
                            indicator: themContext.gtcThemeDark  ? classes.indicator : classes.indicatorLeft
                        }} 
                    >
                        <Tab 
                            hidden
                            wrapped
                            fullWidth
                            label="Profile"
                            icon={ <FontAwesomeIcon style={{ fontSize: '1.5em'}} icon={faHeartbeat} />  }
                        />
                        <Tab 
                            wrapped
                            fullWidth
                            label="Vitals"
                            icon={ <FontAwesomeIcon style={{ fontSize: '1.5em'}} icon={faHeartbeat} />  }
                        />
                        <Tab 
                            wrapped
                            fullWidth
                            label="Allergies"
                            icon={ <FontAwesomeIcon style={{ fontSize: '1.5em'}} icon={faAllergies} /> }
                        />
                        <Tab 
                            wrapped
                            fullWidth
                            label="Diagnosis"
                            icon={ <FontAwesomeIcon style={{ fontSize: '1.5em'}} icon={faDiagnoses} /> }
                        />
                        <Tab 
                            wrapped
                            fullWidth
                            label="Medication"
                            icon={ <FontAwesomeIcon style={{ fontSize: '1.5em'}} icon={faTablets} /> }
                        />

                        <Tab 
                            wrapped
                            fullWidth
                            label="Diet"
                            icon={ <FontAwesomeIcon style={{ fontSize: '1.5em'}} icon={faUtensils} /> }
                        />  

                        <Tab 
                            wrapped
                            fullWidth
                            label="FamilyHistory"
                            icon={ <FontAwesomeIcon style={{ fontSize: '1.5em'}} icon={faSyncAlt} /> }
                        /> 

                        <Tab 
                            wrapped
                            fullWidth
                            label="History"
                            icon={ <FontAwesomeIcon style={{ fontSize: '1.5em'}} icon={faHistory} /> }
                        /> 

                    </Tabs> 
                </Box>
            </Grid> 
        </Grid> 
    ) 
}

export default  Personal;