 
import Axios from "axios";


class CheckerInternet {
    online = async () => {   
        return await Axios.get('//ipv4.icanhazip.com');
   }
}

const CheckInternet = new CheckerInternet();
export default CheckInternet;