import { TableContainer, Table, TableHead, TableCell, TableRow, Typography, Box, TableBody, CardContent } from '@material-ui/core';
import React from 'react'
import HemathologyRef from '../../../laboratory/newlaboratory/references/Ref_Hemathology';

const PatientHemaOrder = ({ hemaOrderDetails }) => {

    return (
        <>
            <CardContent>
                <Box>
                    <Typography variant="h6" align="center">
                        <b> HEMATOLOGY </b>
                    </Typography>
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell align="center"> <b> RESULT </b> </TableCell>
                                <TableCell align="center"> <b> REFERENCE VALUES </b> </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {hemaOrderDetails.data.hemoglobin &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            hemoglobin
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.hemoglobin}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.hemoglobin()}
                                    </TableCell>
                                </TableRow>
                            }

                            {hemaOrderDetails.data.hematocrit &&
                                <TableRow>
                                    <TableCell >
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            hematocrit
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.hematocrit}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.hematocrit()}
                                    </TableCell>
                                </TableRow>
                            }

                            {hemaOrderDetails.data.rbc &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            RBC
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.rbc}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.rbc()}
                                    </TableCell>
                                </TableRow>
                            }

                            {hemaOrderDetails.data.wbc &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            wbc
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.wbc}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.wbc()}
                                    </TableCell>
                                </TableRow>
                            }

                            {hemaOrderDetails.data.platelet_count &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            Platelet count
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.platelet_count}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.plateletCount()}
                                    </TableCell>
                                </TableRow>
                            }

                            {hemaOrderDetails.data.differential_count &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            differential count
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.differential_count}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" />   {/*  No refenrece values */}
                                </TableRow>
                            }

                            {hemaOrderDetails.data.neutrophil &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            neutrophil
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.neutrophil}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.neutrophil()}
                                    </TableCell>
                                </TableRow>
                            }

                            {hemaOrderDetails.data.lymphocyte &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            lymphocyte
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.lymphocyte}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.lymphocyte()}
                                    </TableCell>
                                </TableRow>
                            }

                            {hemaOrderDetails.data.monocyte &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            monocyte
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.monocyte}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.monocyte()}
                                    </TableCell>
                                </TableRow>
                            }
                            {hemaOrderDetails.data.eosinophil &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            eosinophil
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.eosinophil}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.eosinophil()}
                                    </TableCell>
                                </TableRow>
                            }
                            {hemaOrderDetails.data.basophil &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            basophil
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.basophil}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.basophil()}
                                    </TableCell>
                                </TableRow>
                            }
                            {hemaOrderDetails.data.bands &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            bands
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.bands}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.bands()}
                                    </TableCell>
                                </TableRow>
                            }{hemaOrderDetails.data.abo_blood_type_and_rh_type &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            ABO blood type / rh type
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.abo_blood_type_and_rh_type}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center" /> {/* no refernece values */}
                                </TableRow>
                            }{hemaOrderDetails.data.bleeding_time &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            bleeding time
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.bleeding_time}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.bleedingTime()}
                                    </TableCell>
                                </TableRow>
                            }{hemaOrderDetails.data.clotting_time &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            clotting time
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {hemaOrderDetails.data.clotting_time}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HemathologyRef.clottingTime()}
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </>
    )
}

export default PatientHemaOrder;