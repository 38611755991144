import React, { Fragment, useRef } from "react"   
import { Col, Row } from "react-bootstrap";
import Login from "./Login";
import GTCLogo from './GTCLogo';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import { Box, IconButton } from "@material-ui/core"; 

const Homepage = () =>{
    const homeRef = useRef(null)  

    const backtoTop = () => { 
        homeRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    return(
        <Fragment> 
            <Box
                ref ={ homeRef } 
                style={{
                    scrollBehavior: 'smooth'
                }}
            >
                <div className="login-body"> 
                    <Col xs={8} className="mx-auto login-container">
                        <Row style={{ marginTop: '10vh' }}>
                            <Col md={7} lg={8} className="log" >
                                <GTCLogo />
                            </Col>
                            <Col md={5} lg={4} className="login p-3 mt-5 mt-md-0"> 
                                <Login /> 
                            </Col>
                        </Row>
                    </Col> 
                </div>
                <div className="about-body">
                    <Col xs={8} className="mx-auto about-container">
                        <About/>
                    </Col>
                </div>
                <div className="contact-body">
                    <Col xs={12} className="contact-container">
                        <Contact/>
                    </Col>
                </div>
                <Footer />
            </Box>

            <IconButton
                color="secondary"  
                onClick ={ backtoTop }
                style={{
                    color: '#007bff',
                    border: '1px solid #007bff',
                    backgroundColor: '#007bff3d',
                    position: 'fixed',
                    right: 20,
                    bottom: 20
                }}
            >
                <VerticalAlignTopIcon fontSize="small" />
            </IconButton>
        </Fragment> 
    )
}
export default Homepage;
 