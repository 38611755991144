
import React, { useEffect, useState } from 'react'  
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'; 
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar'; 
import { Box, Grid, Snackbar, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors'; 
import RequestMessagesForm from './RequestMessagesForm';
import Notify from '../notification/Notify';
import Axios from 'axios'; 
import PerfectScrollbar from 'react-perfect-scrollbar'; 
import Alert from '@material-ui/lab/Alert';
import DoctorsList from './DoctorsList';
import ClinicInformation from './ClinicInformation';

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ClinicSecretary = () =>{
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 
    
    const [list, setList] = useState([])
    const [listReady, setListReady] = useState(false)

    const [openNotification,setOpenNotification] = useState(false)

    const [selectedPatient, setSelectedPatient] = useState(null)
    const [selectedPatientName, setSelectedPatientName] = useState(null) 

    var newInquiriesInterval = null;

    const getInquiryMessage =() => {
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)  
        Axios
        .post('clinic/secretary/inquiryby-patient', formdata)
        .then((response) =>{
            const data = response.data
            setList(data)
            setListReady(true)
            getNewInquiries( data.length > 0 ? data[0].lastmessage_id : 0 ) 
        }).catch(error => { Notify.requestError(error) }) 
    }

    const getNewInquiries = (lastmsgId) =>{ 
        newInquiriesInterval = setInterval(() => {
            var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)  
            formdata.set('lastmessage_id', lastmsgId)  
            Axios
            .post('clinic/secretary/inquiry/new-inquiries', formdata)
            .then((response) =>{
                const data = response.data 
                if(data.length > 0){     
                    if(data[0].send_by === 'Patient'){
                        setOpenNotification(data[0].send_by === 'Patient' ? true : false)
                    } 
                    getInquiryMessage()
                    clearInterval(newInquiriesInterval)
                }
            }).catch(error => { Notify.requestError(error) }) 
        }, 10000);
    } 

    useEffect(() =>{ 

        getInquiryMessage() 
        return () =>{ clearInterval(newInquiriesInterval) }
 
        // eslint-disable-next-line
    },[])
 
    return( 
        <>
            {/* show notification for new messages */}
            <Snackbar 
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom'
                }}
                open={openNotification }
                 autoHideDuration={10000} 
                 onClose={() => setOpenNotification(false)}>
                <Alert severity="info" variant="filled">
                    You have new message.
                </Alert>
            </Snackbar>

            <Box m={2}>
                <Grid container spacing={2}>
                    {/* inquiry list */}
                    <Grid 
                        item 
                        xs={3} 
                        sm={4} 
                        md={3}
                    > 
                        <Box
                            border={1} 
                            bgcolor="white" 
                            borderColor={grey[300]} 
                            pt={1}
                            pl={1}
                        >
                            <Box p={1}>
                                <Typography 
                                    noWrap 
                                    color="primary" 
                                    variant="subtitle2"
                                > INQUIRIES </Typography> 
                            </Box>  
                            <List  
                                maxHeight={'calc(100vh - 125px)'}
                                overflow="auto"  
                                component={Box}  
                            >  
                                <PerfectScrollbar>  
                                    {
                                        listReady ? 
                                            list.length > 0 ?
                                                list.map((data, index) =>(
                                                    <ListItem
                                                        key={index}
                                                        style={{ cursor: 'pointer' }} 
                                                        onClick={ () => {
                                                            setSelectedPatientName(data.patient_name);
                                                            setSelectedPatient(data.patient_id)
                                                        }}
                                                        selected={ data.patient_id === selectedPatient }
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar 
                                                                alt={ data.patient_name } 
                                                                src={data.patient_image !== null ? `${imageLocation}patients/${data.patient_images}` : '' }
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText  
                                                            primary={
                                                                <Typography 
                                                                    variant="subtitle2" 
                                                                    noWrap 
                                                                    style={{ fontWeight: parseInt(data.last_message_status) === 0 ?  'bold':'normal', textTransform: 'uppercase' }}
                                                                    color={parseInt(data.last_message_status) === 0 ? "textPrimary" :"textSecondary"}
                                                                > 
                                                                    { data.patient_name } 
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <Typography 
                                                                    variant="body2" 
                                                                    noWrap 
                                                                    color={parseInt(data.last_message_status) === 0 ? "textPrimary" :"textSecondary"}
                                                                >
                                                                    { data.last_message_sender === 'Clinic' ? 'You:' : null} { data.last_message }
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem> 
                                                ))
                                            : <Box align="center" my={2}> { Notify.noRecord() } </Box>
                                        : <Box align="center" my={2}> { Notify.loading() } </Box>
                                    }
                                </PerfectScrollbar>
                            </List>
                        </Box>
                    </Grid>

                    <Grid 
                        item 
                        xs={9} 
                        sm={8} 
                        md={6}
                    > 
                        {selectedPatient && selectedPatientName && ( <RequestMessagesForm sender={ selectedPatientName }  patient_id ={ selectedPatient }/> )}

                        <Box
                            hidden={ selectedPatient !== null }  
                        >
                            <ClinicInformation />
                        </Box>
                    </Grid> 

                    <Grid 
                        item
                        xs={12} 
                        sm={12} 
                        md={3}
                    >
                        <DoctorsList />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default ClinicSecretary; 