import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Grid, Paper, Box, Typography, TextField, Button, CardMedia, ButtonGroup, Card, Dialog, IconButton, DialogTitle, DialogContent, Tooltip, Divider, Hidden } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';  
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"; 
import PerfectScrollbar from 'react-perfect-scrollbar' 
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DraggableDialog from 'src/utils/DraggableDialog';
import ReviewPatientFindings from './ReviewPatientFindings';

const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;

const ReviewPatient = ({ imagingOrderId, getPatientsReview, resetDisplay }) =>{

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 

    const [details, setDetails] = useState({ data: [], ready: false })    

    const getImagingDetails = useCallback( async() =>{
        try { 
            var formdata = new FormData()
                formdata.append('token', token)
                formdata.append('user_id', user_id)
                formdata.append('imaging_order_id', imagingOrderId)

            const request = await Axios.post('telerad/order/order-details', formdata)
            setDetails({
                data: request.data,
                ready: true 
            })

        } catch (error) {
            Notify.requestError(error)
        }
    },[imagingOrderId, token, user_id])

 
    useEffect( () =>{

        getImagingDetails()

    },[getImagingDetails]) 

    return( 
        <>
            <Paper
                variant="outlined"
                component={Box}
                className={`gtc-textfield-noborder`}
                p={2}
            >
                <Box 
                    mb={2}
                >
                    <Typography
                        color="textPrimary"
                        className={`gtc-uppercase`} 
                        variant="subtitle2"
                    >
                        <b>
                            Order Details
                        </b>
                    </Typography>
                    
                </Box> 

                {
                    details.ready ? 
                        details.data.length > 0 ?
                            <RenderDetails 
                                details={ details.data }  
                                getPatientsReview = { getPatientsReview }
                                resetDisplay = { resetDisplay }
                                imagingOrderId = { imagingOrderId }
                            />
                        : Notify.noRecord()
                    : Notify.loading()
                } 
            </Paper>  
        </>
    )
}

export default ReviewPatient;

const RenderDetails = ({ details, getPatientsReview, resetDisplay, imagingOrderId }) =>(
    <>
        <Box 
            mb={2}
        >
            <TextField 
                label="Name" 
                variant="outlined"
                fullWidth
                multiline
                value={details[0].patient_name}
                InputProps={{
                    readOnly: true
                }}
            />
        </Box>

        <Box 
            mb={2}
        >
            <TextField 
                label="Gender" 
                variant="outlined"
                fullWidth
                value={details[0].gender === null ? 'None' : details[0].gender}
                multiline
                InputProps={{
                    readOnly: true
                }}
            />
        </Box>

        <Box 
            mb={2}
        >
            <TextField 
                label="Birthday" 
                variant="outlined"
                value={details[0].birthday === null ? 'None' : Notify.birthday(details[0].birthday)}
                fullWidth
                multiline
                InputProps={{
                    readOnly: true
                }}
            />
        </Box>

        <Box 
            mb={2}
            className={`gtc-capitalize`}
        >
            <TextField 
                label="Order" 
                variant="outlined"
                fullWidth
                multiline
                value={details[0].imaging_order === null ? 'None' : details[0].imaging_order}
                InputProps={{
                    readOnly: true
                }}
            />
        </Box>
        
        <Box 
            mb={2}
            className={`gtc-capitalize`}
        >
            <TextField 
                label="Order Remarks" 
                variant="outlined"
                fullWidth
                multiline
                value={details[0].imaging_remarks === null ? 'None' : details[0].imaging_remarks}
                InputProps={{
                    readOnly: true
                }}
            />
        </Box>

        <Box 
            mb={2}
            className={`gtc-capitalize`}
        >
            <TextField 
                label="Charge Slip" 
                variant="outlined"
                fullWidth
                multiline
                value={details[0].charge_slip === null ? 'None' : details[0].charge_slip}
                InputProps={{
                    readOnly: true
                }}
            />
        </Box>

        <Box 
            mb={2}
        >
            { details[0].imaging_result_attachment  === null &&( <Typography color="error"> No attachment found. </Typography>)}
            { details[0].imaging_result_attachment !== null &&(
                <RenderAttachment 
                    attachment = { details[0].imaging_result_attachment}  
                    getPatientsReview = { getPatientsReview }
                    resetDisplay  = { resetDisplay }
                    imagingOrderId = { imagingOrderId }
                />
            )}
            
        </Box>
    </>
)

const RenderAttachment = ({attachment, getPatientsReview, resetDisplay, imagingOrderId }) =>{ 

    const [selectedImage, setSelectedImage] = useState(null) 

    const [compareFstImg, setCompareFstImg] = useState(null)
    const [compareSndImg, setCompareSndImg] = useState(null)


    const [resultDialog, setResultDialog] = useState(false) 
    

    const handlCompare = (e) =>{ 
        var img = e.currentTarget.getAttribute('image');
        if(compareFstImg === null){
            setCompareFstImg(img)
            Notify.customToast('First Image', 'First image is ready')
        }else{
            Notify.customToast('Compare Image', 'Compare image is ready.')
            setCompareSndImg(img)
        }
    }

    return(
        <>
            <Box mb={1}>
                <Typography 
                    className={`gtc-uppercase`}     
                    variant="subtitle2" 
                    color="textPrimary"
                > Attachment </Typography>
            </Box>

            <Grid container spacing={2}>
                {
                    attachment.split(',').map((image, index) => {
                        return(
                            <Grid key={index} item xs={6} sm={6} md={4} lg={4}>
                                <Card>
                                    <Box>
                                        <CardMedia  
                                            component="img"
                                            height={'150px'}
                                            src={`${imageLocation}imaging/${image}`}
                                            alt={image}
                                        />
                                    </Box>
                                    <Box align="center" mt={1}>
                                        <ButtonGroup 
                                            fullWidth  
                                        >
                                            <Button 
                                                variant="contained" 
                                                color="primary"  
                                                onClick={ () => setSelectedImage(image) }
                                            > View </Button>
                                            <Button 
                                                variant="contained" 
                                                color="secondary"   
                                                image={image}
                                                onClick={ handlCompare }
                                            > compare </Button>
                                        </ButtonGroup>
                                    </Box>
                                </Card>
                            </Grid> 
                        )
                    })
                }
            </Grid>

            {/* dialog view */}  
            <Dialog
                open={ selectedImage !== null }
                onClose = { () => setSelectedImage(null)}
                fullScreen
                disableEscapeKeyDown
                disableBackdropClick
            >   
                <Box className="gtc-viewimage" >
                    <TransformWrapper
                        defaultScale={1} 
                    > 
                        {({ zoomIn, zoomOut }) => (
                            <>  
                                <Box display="flex">  
                                    <Box flexGrow={1}>  
                                        <DialogTitle disableTypography >
                                            <Typography 
                                                color="primary" 
                                                variant="subtitle2" 
                                                className={`gtc-uppercase`}
                                            > { `Imaging Order Attachment` } </Typography>
                                        </DialogTitle>
                                    </Box>   
                                    <Box>     
                                        <Hidden smUp>
                                            <Tooltip title="Add Finding" arrow>
                                                <IconButton  
                                                    color="primary"   
                                                    onClick={ () => setResultDialog(true) }
                                                >
                                                    <PostAddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Hidden>

                                        <IconButton 
                                            color="primary"  
                                            onClick={(zoomIn)}
                                        >
                                            <ZoomInIcon />
                                        </IconButton>
                                        <IconButton 
                                            color="primary"  
                                            onClick={zoomOut}
                                        >
                                            <ZoomOutIcon />
                                        </IconButton> 

                                        <IconButton 
                                            color="secondary"  
                                            onClick = { () => setSelectedImage(null)}
                                        >
                                            <HighlightOffIcon />
                                        </IconButton> 
                                    </Box> 
                                </Box>    

                                <Divider />

                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={8} md={9}> 
                                            <Box>
                                                <TransformComponent>    
                                                    <img 
                                                        src={`${imageLocation}imaging/${selectedImage}`}  
                                                        alt=""  
                                                    /> 
                                                </TransformComponent>   
                                            </Box>
                                        </Grid>

                                        <Hidden xsDown>
                                            <Grid item sm={4} md={3}>
                                                <Box m={2}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textPrimary"
                                                    > 
                                                        ADD FINDINGS
                                                    </Typography>
                                                </Box>
                                                <ReviewPatientFindings 
                                                    imagingOrderId = { imagingOrderId }
                                                    getPatientsReview = { getPatientsReview }
                                                    resetDisplay = { resetDisplay }
                                                    close ={ () => console.log('close form ') }
                                                /> 
                                            </Grid>
                                        </Hidden>
                                    </Grid> 
                                </DialogContent>
                            </> 
                        )}
                    </TransformWrapper>     
                </Box> 
            </Dialog>

            {/* dialog compare test */}
            { compareFstImg && compareSndImg && (
                <Dialog
                    open={ compareFstImg && compareSndImg ? true : false }
                    onClose = { () => { setCompareFstImg(null); setCompareSndImg(null) }}
                    fullScreen
                >   
                    <PerfectScrollbar>
                        <Box 
                            className="gtc-viewimage"  
                        > 
                            <Grid container spacing={2}>
                                <Grid item xs={6} >  
                                    <TransformWrapper> 
                                        {({ zoomIn, zoomOut }) => (
                                            <Fragment>   
                                                <div className="clearfix pl-1">  
                                                    <div className="float-left m-2 mt-3">  
                                                        <Typography color="primary" variant="subtitle2"> FIRST IMAGE </Typography>
                                                    </div>   
                                                    <div className="float-right">     
                                                        <IconButton 
                                                            color="primary" 
                                                            component="span"
                                                            onClick={zoomIn}
                                                        >
                                                            <ZoomInIcon />
                                                        </IconButton>
                                                        <IconButton 
                                                            color="primary" 
                                                            component="span"
                                                            onClick={zoomOut}
                                                        >
                                                            <ZoomOutIcon />
                                                        </IconButton> 
                                                    </div>
                                                </div>
                                                <TransformComponent>   
                                                    <Box>
                                                        <img  
                                                            src={imageLocation+'imaging/'+compareFstImg} 
                                                            alt={compareFstImg}  
                                                        />
                                                    </Box>
                                                </TransformComponent>  
                                            </Fragment> 
                                        )}
                                    </TransformWrapper>   
                                </Grid>
                                <Grid item xs={6}>   
                                    <TransformWrapper> 
                                        {({ zoomIn, zoomOut }) => (
                                            <Fragment>   
                                                <div className="clearfix pl-1">
                                                    <div className="float-left m-2 mt-3">  
                                                        <Typography color="primary" variant="subtitle2"> SECOND IMAGE  </Typography>
                                                    </div>  
                                                    <div className="float-right">       

                                                    <Tooltip title="Add Finding" arrow>
                                                        <IconButton  
                                                            color="primary"   
                                                            onClick={ () => setResultDialog(true) }
                                                        >
                                                            <PostAddIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    
                                                        <IconButton 
                                                            color="primary"  
                                                            onClick={zoomIn}
                                                        >
                                                            <ZoomInIcon />   
                                                        </IconButton>
                                                        <IconButton 
                                                            color="primary"  
                                                            onClick={zoomOut}
                                                        >
                                                            <ZoomOutIcon />
                                                        </IconButton>
                                                        <IconButton 
                                                            color="secondary"  
                                                            onClick={ () => { 
                                                                setCompareFstImg(null);
                                                                setCompareSndImg(null);
                                                            }}
                                                        >
                                                            <HighlightOffIcon /> 
                                                        </IconButton>  
                                                    </div>
                                                </div>
                                                <TransformComponent>   
                                                    <Box>
                                                        <img 
                                                            src={imageLocation+'imaging/'+compareSndImg} 
                                                            alt={compareSndImg}   
                                                        />
                                                    </Box>
                                                </TransformComponent>  
                                            </Fragment> 
                                        )}
                                    </TransformWrapper>    
                                </Grid>
                            </Grid> 
                        </Box>  
                    </PerfectScrollbar>
                </Dialog>
            )}  


            {/* dialog for add result telerad mobile */}
            <Dialog
                open={ resultDialog }
                onClose={ () => setResultDialog(false) }
                disableEscapeKeyDown
                disableBackdropClick
                PaperComponent={ DraggableDialog }
            > 
                <DialogTitle id="draggable-handle">
                    ADD FINDINGS
                </DialogTitle>
                <DialogContent>
                    <ReviewPatientFindings 
                        imagingOrderId = { imagingOrderId }
                        getPatientsReview = { getPatientsReview }
                        resetDisplay = { resetDisplay }
                        close={ () => setResultDialog(false) }
                    /> 
                </DialogContent>
            </Dialog>
        </> 
    )
}

