export default function ImagingExam(){
    var exams = [  
            {
                label : 'Computed tomography (CT) Scan',    
                value : 'Computed tomography (CT) Scan'
            },
            {
                label : 'Magnetic Resonance Imaging (MRI) Scan',    
                value : 'Magnetic Resonance Imaging (MRI) Scan'
            },
            {
                label : 'Breast MRI',    
                value : 'Breast MRI'
            },
            {
                label : 'X-rays',    
                value : 'X-rays'
            },
            {
                label : 'Mammography',    
                value : 'Mammography'
            },
            {
                label : 'Fluoroscopy',    
                value : 'Fluoroscopy'
            },
            {
                label : 'Ultrasound',    
                value : 'Ultrasound'
            },
            {
                label : 'Bone Scans',    
                value : 'Bone Scans'
            },
            {
                label : 'Positron Emission Tomography (PET) Scans',    
                value : 'Positron Emission Tomography (PET) Scans'
            },
            {
                label : 'PET/CT Scans',    
                value : 'PET/CT Scans'
            },
            {
                label : 'Thyroid Scans',    
                value : 'Thyroid Scans'
            },
            {
                label : 'Multigated Acquisition (MUGA) Scans',    
                value : 'Multigated Acquisition (MUGA) Scans'
            },
            {
                label : 'Gallium Scans',    
                value : 'Gallium Scans'
            }
        ] ;

    return exams;
}