import React, { Component, Fragment } from 'react';  
import Notify from '../../../notification/Notify';
import Axios from 'axios';
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export default class ImagingUnprocess extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _patient_id: this.props.patient_id,
            _imagingType: this.props.imagingType,

            _imaging: [],
            _imaging_ready: false,
        }
    } 
    
    componentDidMount(){
        this._mounted = true;
        this.getImagingOngoing();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    getImagingOngoing(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);    
        formdata.set('patient_id', this.props.patient_id);    
        formdata.set('connection', this.props.imagingType === 'virtual-imaging' ? 'online' : 'local')
        
        Axios.post('doctor/patient/imaging/imaging-unprocess', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _imaging: data,
                    _imaging_ready: true,
                }) 
            }
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    componentDidUpdate(){
        if(this.state._patient_id !== this.props.patient_id){
            this.setState({
                _patient_id: this.props.patient_id,
                _imagingType: this.props.imagingType,
                _imaging_ready: false,
            })
            this.componentDidMount()
        }
    }

	render(){ 
		return(
            <Fragment>
                <Row>
                    <Col sm={12} className="mt-3">
                        {
                            this.state._imaging_ready ? 
                                this.state._imaging.length > 0 ?
                                    this.state._imaging.map((data, index) =>{
                                        return(
                                            <Card key={index} className="bg-white mb-2" style={{borderLeft: '.2em solid #17a2b8'}}>
                                                <Card.Body className="pb-0">
                                                    <div className="text-capitalize gtc-text-purple font-weight-bold"> { data.imaging_order } </div>
                                                    <div> { data.imaging_remarks } </div>
                                                    <div className="gtc-small py-2 text-uppercase">
                                                        <span className="text-muted"> order on { Notify.createdAt(data.created_at) } </span>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        )
                                    })
                                : <Card className="bg-white"> <Card.Body> { Notify.noRecord() } </Card.Body> </Card>
                            : Notify.loading()
                        }
                    </Col>
                </Row>
            </Fragment>
        )
	}
}
