import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography, Button, TextField, CardMedia } from '@material-ui/core';  
import MessageIcon from '@material-ui/icons/MessageRounded'; 
import { useParams } from 'react-router-dom';
// import OnlineBadge from '../../utils/OnlineBadge'; 
// import PermIdentityIcon from '@material-ui/icons/PermIdentity';  

import L from 'leaflet'; 

import { Map, TileLayer, Marker } from "react-leaflet";   

import FullscreenControl from 'react-leaflet-fullscreen';
import { grey } from '@material-ui/core/colors';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import ClinicDetailsInquiry from './ClinicDetailsInquiry';
import ClinicDoctorsList from './ClinicDoctorsList';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
}); 

const ClinicDetails = () =>{  
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const { clinic_id } = useParams()

    const [opendialog, setOpenDialog] = useState(false) 

    const [clinicInfo, setClinicInfo] = useState(null)
    const [clinicInfoRd, setClinicInfoRd] = useState(false)

    const [doctorsList, setDoctorsList] = useState(null)
    const [doctorsListRd, setDoctorsListRd] = useState(false)

    const getClinicDetails = useCallback(() =>{
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)
            formdata.set('clinic_id', clinic_id)

        Axios
        .post('patient/appointment/search/clinic/clinic-details', formdata)
        .then((response) =>{
            const data = response.data 
            setClinicInfo(data)
            setClinicInfoRd(true)
        })
        .catch(error => { Notify.requestError(error) })
    },[token, user_id, clinic_id]); 

    const getClinicDoctorsList = useCallback(() =>{
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)
            formdata.set('clinic_id', clinic_id)

        Axios
        .post('patient/appointment/search/clinic/clinicdoctors-list', formdata)
        .then((response) =>{
            const data = response.data  
            setDoctorsList(data)
            setDoctorsListRd(true)
        })
        .catch(error => { Notify.requestError(error) })
    },[token, user_id, clinic_id]); 

    useEffect(() =>{

        getClinicDetails()
        getClinicDoctorsList()

    },[getClinicDetails, getClinicDoctorsList])
    return(
        <Fragment> 
            <Box m={2}> 
                <Grid container spacing={2}>
                    {
                        clinicInfoRd ?
                            Object.keys(clinicInfo).length > 0 ?
                                <Fragment>
                                    <Grid item xs={12} sm={12}>
                                        <Card
                                            component={Box}
                                            border={1}
                                            borderColor={grey[300]} 
                                            elevation={0}
                                        >
                                            <CardContent>
                                                <Box mb={1}>
                                                    <Typography variant={'subtitle2'} color="primary">
                                                        CLINIC INFORMATION
                                                    </Typography>
                                                </Box>

                                                <Grid container spacing={3}>
                                                    <Grid item xs={6} sm={3} md={4} lg={5}> 
                                                        <Box
                                                            border={1}
                                                            borderColor={grey[200]} 
                                                        >   
                                                            { 
                                                                clinicInfo.latitude && clinicInfo.longitude ?
                                                                    <Map  
                                                                        attributionControl={ false }
                                                                        center={[clinicInfo.latitude, clinicInfo.longitude]}    
                                                                        zoom={15} 
                                                                        style={{height: 'calc(100vh - 180px)'}}  
                                                                    >  
                                                                        <TileLayer
                                                                            url={process.env.REACT_APP_MAP_URL} 
                                                                            attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                                                                            maxZoom={19}
                                                                            minZoom={6}
                                                                            crossOrigin={true}
                                                                        />
                                                                        <Marker 
                                                                            position={[clinicInfo.latitude, clinicInfo.longitude]}  
                                                                        /> 
                                                                        <FullscreenControl position="topleft" />
                                                                    </Map>
                                                                :   <Box
                                                                        height={'calc(100vh - 180px)'} 
                                                                        display="flex"  
                                                                        alignItems="center" 
                                                                        style={{ 
                                                                            backgroundColor: '#abdafb', 
                                                                        }}
                                                                    > 
                                                                        <CardMedia component="img" src="/icons/no-map.png"/>  
                                                                    </Box>
                                                            } 
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} sm={9} md={8} lg={7}>
                                                        <Box  className="gtc-textfield-noborder">
                                                            <Box mb={2}> 
                                                                <Typography   
                                                                    variant="h6"
                                                                    color="primary"
                                                                    noWrap
                                                                >
                                                                    { clinicInfo.clinic }
                                                                </Typography> 
                                                            </Box>
                                                            <Box mb={3}>  
                                                                <TextField 
                                                                    multiline
                                                                    fullWidth
                                                                    label="ADDRESS"
                                                                    variant="outlined"
                                                                    value={ clinicInfo.address } 
                                                                    inputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />
                                                            </Box> 

                                                            <Box my={3}>  
                                                                <TextField 
                                                                    multiline
                                                                    fullWidth
                                                                    label="DAYS OPEN"
                                                                    variant="outlined"
                                                                    value={ clinicInfo.days_open } 
                                                                    inputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                /> 
                                                            </Box>

                                                            <Box my={3}> 
                                                                <TextField 
                                                                    multiline
                                                                    fullWidth
                                                                    label="TIME OPEN"
                                                                    variant="outlined"
                                                                    value={ clinicInfo.time_open } 
                                                                    inputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />  
                                                            </Box> 
                                                            
                                                            <Box my={3}> 
                                                                <TextField 
                                                                    multiline
                                                                    fullWidth
                                                                    label="CONTACTS"
                                                                    variant="outlined"
                                                                    value={ clinicInfo.contact_no } 
                                                                    inputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                />   
                                                            </Box>

                                                            <Box my={3}> 
                                                                <TextField 
                                                                    multiline
                                                                    fullWidth
                                                                    label="CONTACTS"
                                                                    variant="outlined"
                                                                    value={ clinicInfo.remarks } 
                                                                    inputProps={{
                                                                        readOnly: true
                                                                    }} 
                                                                />    
                                                            </Box>  

                                                            <Box my={3}> 
                                                                <TextField 
                                                                    multiline
                                                                    fullWidth
                                                                    label="DOCTORS"
                                                                    variant="outlined"
                                                                    value={`has 10 doctors accredited`} 
                                                                    inputProps={{
                                                                        readOnly: true
                                                                    }}
                                                                /> 
                                                            </Box> 

                                                            {/* <Box 
                                                                my={3}
                                                                p={1}
                                                                border={1}
                                                                borderColor={green[300]}
                                                                borderRadius={3}
                                                                bgcolor={green[100]}
                                                            >  
                                                                <Typography variant="caption">
                                                                    INQUIRY
                                                                </Typography>
                                                                <Typography  
                                                                    variant="subtitle1"
                                                                    color="textSecondary"
                                                                    noWrap
                                                                >
                                                                    <OnlineBadge
                                                                        overlap="circle"
                                                                        anchorOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        variant="dot"
                                                                    >
                                                                            <PermIdentityIcon fontSize="large" /> 
                                                                    </OnlineBadge> Secretary is online
                                                                </Typography>
                                                            </Box> */}

                                                            <Box display="flex">
                                                                <Box flexGrow={1} /> 
                                                                    <Box>
                                                                        <Button  
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => setOpenDialog(true)}
                                                                            startIcon={
                                                                                <MessageIcon />
                                                                            }
                                                                        >
                                                                            Message Clinic
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Box> 
                                                            {/* send dialog */}
                                                            { opendialog && (
                                                                <ClinicDetailsInquiry 
                                                                    open={ opendialog } 
                                                                    close = { () => setOpenDialog(false) } 
                                                                    clinic_id = { clinic_id }
                                                                    user_id = { user_id }
                                                                />
                                                            )}
                                                            
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Fragment>
                            : <Grid item xs={12}>
                                <Box justifyContent="center" >
                                    { Notify.noRecord() }
                                </Box>
                            </Grid>
                        : 
                        <Grid item xs={12}>
                            <Box justifyContent="center" >
                                { Notify.loading() }
                            </Box>
                        </Grid> 
                    } 
                    
                    <Grid item xs={12}> 
                        {
                            doctorsListRd ?
                                doctorsList.length > 0 && (
                                    <ClinicDoctorsList doctorsList ={ doctorsList } />
                                )
                            : Notify.loading()
                        } 
                    </Grid>
                </Grid>
            </Box>   
        </Fragment>
    )
}

export default ClinicDetails;