import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Tabs, Tab, Paper, Grid, Divider, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import TabPanel from '../../utils/TabPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faUserCircle, faUnlockAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import PharmacyInfo from './PharmacyInfo';
import PharmacyUsername from './PharmacyUsername';
import PharmacyPassword from './PharmacyPassword';
import PharmacyUsers from './PharmacyUsers';
import CropFree from '@material-ui/icons/CropFree';
import QRCode from 'qrcode.react';

function Account() {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [pharmacy_id] = useState(localStorage.getItem('pharmacy_id'))
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [logs, setLogs] = useState([]);
    const [logsReady, setLogsReady] = useState(false);
    const [category, setCategory] = useState('info')
    const [info, setInfo] = useState([])
    const [infoReady, setInfoReady] = useState(false)

    const fetInfo = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);

        Axios.post('malita/pharmacy/get-info', formdata)
            .then((response) => {
                const data = response.data;
                setInfo(data);
                setInfoReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, management_id])

    const fetchLogs = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);

        Axios.post('malita/pharmacy/get-logs-list', formdata)
            .then((response) => {
                const data = response.data;
                setLogs(data);
                setLogsReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, management_id])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChange = (event, newcategory) => {
        event.persist()
        setCategory(newcategory);
    };

    useEffect(() => {

        fetchLogs()
        fetInfo()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchLogs, fetInfo])

    return (
        <Fragment>
            <Box m={2}>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} sm={4} className={'d-print-none'}>
                                <Paper elevation={3} variant="outlined">
                                    <Box>
                                        <Box p={2} borderRadius={4}>
                                            <Box display="flex" justifyContent="center" >
                                                <Box flexGrow={1} >
                                                    <Typography color="primary" >
                                                        Logs
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Box m={1}>
                                            <TableContainer component={Box} borderColor="grey.200" bgcolor="white">
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center"> Logs </TableCell>
                                                            <TableCell align="center"> Date </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            logsReady ?
                                                                logs.length > 0 ?
                                                                    logs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                        .map((data, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell bgcolor="white"> {data.logs} </TableCell>
                                                                                <TableCell bgcolor="white" align="right">
                                                                                    {Notify.dateTimeConvert(data.created_at)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    : <TableRow>
                                                                        <TableCell colSpan={2}>
                                                                            <Typography variant="subtitle2" color="secondary"> No logs found </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                : <TableRow>
                                                                    <TableCell colSpan={2}>
                                                                        <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>

                                                <TablePagination
                                                    className={'d-print-none'}
                                                    component={"div"}
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    colSpan={3}
                                                    count={logs.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={8} sm={8} className={'d-print-none'}>
                                <Box
                                    component={Paper}
                                    variant="outlined"
                                    mb={2}
                                >
                                    <Tabs
                                        variant="scrollable"
                                        value={category}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        indicatorColor={'primary'}
                                        textColor={"primary"}
                                        scrollButtons="on"
                                    >
                                        <Tab
                                            wrapped
                                            fullWidth
                                            label="Pharmacy Info"
                                            value="info"
                                            icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faInfoCircle} />}
                                        />

                                        <Tab
                                            wrapped
                                            fullWidth
                                            label="Username"
                                            value="username"
                                            icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faUserShield} />}
                                        />

                                        <Tab
                                            wrapped
                                            fullWidth
                                            label="Password"
                                            value="password"
                                            icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faUnlockAlt} />}
                                        />

                                        <Tab
                                            wrapped
                                            fullWidth
                                            label="User"
                                            value="adduser"
                                            icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faUserCircle} />}
                                        />

                                        <Tab
                                            wrapped
                                            fullWidth
                                            label="QR Code"
                                            value="qrcode"
                                            icon={<CropFree />}
                                        />

                                    </Tabs>
                                </Box>

                                <Box>
                                    <TabPanel value={category} index={'info'}>
                                        <PharmacyInfo info={info} infoReady={infoReady} renderAllFetch={() => { fetchLogs(); fetInfo(); }} />
                                    </TabPanel>

                                    <TabPanel value={category} index={'username'}>
                                        <PharmacyUsername renderAllFetch={() => { fetchLogs(); fetInfo(); }} />
                                    </TabPanel>

                                    <TabPanel value={category} index={'password'}>
                                        <PharmacyPassword renderAllFetch={() => { fetchLogs(); fetInfo(); }} />
                                    </TabPanel>

                                    <TabPanel value={category} index={'adduser'}>
                                        <PharmacyUsers />
                                    </TabPanel>

                                    <TabPanel value={category} index={'qrcode'}>
                                        <Paper elevation={3} variant="outlined">
                                            <Box>
                                                <Box m={3}>
                                                    <Box display="flex" justifyContent="center" alignItems="center">
                                                        <QRCode value={pharmacy_id} level="H" size={300} />
                                                    </Box>
                                                    <Box display="flex" justifyContent="center" alignItems="center" m={3}>
                                                        <Typography variant="h6" color="primary">
                                                            For delivery purposes only.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </TabPanel>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Fragment >
    )

}

export default Account;