
import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Hidden, TablePagination } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Notify from 'src/notification/Notify';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Axios from 'axios';
import Label from 'src/utils/Label';

const BodyPain = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const maincanvasRefFront = useRef(null)

    const [ctx, setCTX] = useState(null);

    const [isedit, setEdit] = useState(false);

    const [facing, setFacing] = useState('front')

    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });

    const [openSaveDialog, setOpenSaveDialog] = useState(false)
    const [isprocess, setIsProcess] = useState(false)
    const [bodyPain, setBodyPain] = useState([])

    let image = new Image();
    let pain = new Image();
    var painsize = 20;
    var height = 600;
    var width = 250;

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(7);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const applyPagination = (doctors, page, limit) => {
        return doctors.slice(page * limit, page * limit + limit);
    }

    const initializeCanvasFront = (data) => {
        image.src = data[0].gender === 'Female' ?
            facing === 'front' ?
                '/female_skeleton_front.png'
                : '/female_skeleton_back.png'
            : facing === 'front' ?
                '/male_skeleton_front.png'
                : '/male_skeleton_back.png';
        // facing === 'front' ? '/skeleton_front.png' : '/skeleton_back.png';
        pain.src = '/skeleton-pain.png'
        maincanvasRefFront.current.height = height
        maincanvasRefFront.current.width = width

        image.onload = () => {
            ctx.drawImage(image, 0, 0, width, height)
            setTimeout(() => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].facing === facing) {
                        ctx.drawImage(pain, parseFloat(data[i].pain_position_x), parseFloat(data[i].pain_position_y), painsize, painsize)
                        ctx.fillStyle = '#fff'
                        ctx.fillText(data[i].pain_level, parseFloat(data[i].pain_position_x) + 5, parseFloat(data[i].pain_position_y) + 13);
                    }
                }
            }, 1000);
        }
    }

    const getbodyPainList = async () => {
        try {
            var formdata = new FormData()
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.append('facing', 'front')
            const request = await Axios.post('patient/vitals/graph/pain/monitoring/getpain-list', formdata)
            const data = request.data;
            initializeCanvasFront(data)
            setBodyPain(data)
        } catch (error) { Notify.requestError(error) }
    }

    useEffect(() => {

        setCTX(maincanvasRefFront.current.getContext('2d'))

        if (ctx) {
            getbodyPainList()
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctx, facing]) 

    const handleEdit = () => {
        setEdit(true)
        maincanvasRefFront.current.style.cursor = 'crosshair'
    }

    const handleEditClose = () => {
        setEdit(false)
        setPosition({ x: 0, y: 0 })
        setOpenSaveDialog(false)
        maincanvasRefFront.current.style.cursor = 'default'
    }

    const getclickPosition = (e) => {
        if (position.x && position.y) {
            Notify.customToast('Selection Error', 'Click reselect to select another.')
        } else {
            var rect = maincanvasRefFront.current.getBoundingClientRect();

            var positionX = (e.clientX - rect.left);
            var positionY = (e.clientY - rect.top);

            setPosition({ x: positionX - 10, y: positionY - 10 })
            ctx.beginPath();
            ctx.arc(positionX, positionY, 10, 0, 50);
            ctx.fillStyle = "red";
            ctx.fill();
        }
    }

    const resetSelection = () => {
        ctx.clearRect(0, 0, maincanvasRefFront.current.width, maincanvasRefFront.current.height);
        initializeCanvasFront(bodyPain)
        setPosition({ x: 0, y: 0 })
    }

    const handleSavePain = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.append('token', token)
        formdata.append('user_id', user_id)
        formdata.append('username', username)
        formdata.append('position_x', position.x)
        formdata.append('position_y', position.y)
        formdata.append('facing', facing)

        var error = [];

        if (formdata.get('pain_level').trim() === '' || formdata.get('pain_level').length === 0) {
            error = 'error'
            Notify.fieldRequired('pain_level')
        }

        if (formdata.get('description').trim() === '' || formdata.get('description').length === 0) {
            error = 'error'
            Notify.fieldRequired('description')
        }

        if (formdata.get('password').trim() === '' || formdata.get('password').length === 0) {
            error = 'error'
            Notify.fieldRequired('password')
        }

        if (error.length > 0) {
            console.warn("form has an error.")
        } else {
            setIsProcess(true)
            Axios.post('patient/vitals/graph/pain/monitoring/pain-save', formdata)
                .then((response) => {
                    const data = response.data
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'success') {
                        handleEditClose()
                        getbodyPainList()
                        Notify.successRequest('pain save')
                    }
                })
                .catch((error) => Notify.requestError(error))
                .finally(() => setIsProcess(false))
        }

    }


    const paginatePain = applyPagination(bodyPain, page, limit);

    return (
        <>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant="subtitle2" color="primary" className={`gtc-uppercase`}>
                        Pain Monitoring
                    </Typography>
                </Box>

                <Button
                    variant="text"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleEdit}
                    hidden={isedit}
                >
                    Update
                </Button>
            </Box>

            <Grid container spacing={2}>
                <Hidden smDown>
                    <Grid item xs={12} sm={12} md={7}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>  Date </TableCell>
                                        <TableCell align="center"> Description </TableCell>
                                        <TableCell align="center"> Pain Level </TableCell>
                                        <TableCell align="center"> Type </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        bodyPain.length > 0 ?
                                            paginatePain.map((data, index) => (
                                                <TableRow
                                                    key={index}
                                                >
                                                    <TableCell>  {Notify.dateTimeConvert(data.created_at)} </TableCell>
                                                    <TableCell align="center">  {data.description} </TableCell>
                                                    <TableCell align="right">  {data.pain_level} </TableCell>
                                                    <TableCell align="right">
                                                        <Label color={data.facing === 'front' ? "primary" : "success"}>
                                                            {data.facing}
                                                        </Label>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell>
                                                    <Typography color="error">
                                                        No record found.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component={Box}
                            count={bodyPain.length}
                            labelRowsPerPage="div"
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleLimitChange}
                            page={page}
                            rowsPerPage={limit}
                            rowsPerPageOptions={[7, 10, 25]}
                        />
                    </Grid>
                </Hidden>

                <Grid item xs={12} sm={12} md={5}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        overflow="auto"
                    >
                        <canvas ref={maincanvasRefFront} onClick={isedit ? getclickPosition : null} />
                    </Box>
                    <Box justifyContent="center" display="flex">
                        <Box mr={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setFacing('back')}
                                disabled={facing === 'back'}
                                startIcon={
                                    <NavigateBeforeIcon />
                                }
                            > Back </Button>
                        </Box>

                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setFacing('front')}
                                disabled={facing === 'front'}
                                endIcon={
                                    <NavigateNextIcon />
                                }
                            > Front </Button>
                        </Box>
                    </Box>
                    <Box align="center" hidden={!isedit}>
                        <Typography variant="caption" color="textSecondary">
                            Select location of your pain, please hit the image, Do not select the white space.
                        </Typography>
                    </Box>
                    <Box
                        mt={2}
                        display="flex"
                        justifyContent="center"
                        hidden={!isedit}
                    >
                        <Box mr={2}>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={resetSelection}
                            >
                                Reselect
                            </Button>
                        </Box>

                        <Box mr={2}>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={handleEditClose}
                                disabled={position.x !== 0 && position.y !== 0}
                            >
                                Close
                            </Button>
                        </Box>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenSaveDialog(true)}
                            disabled={!position.x && !position.y}
                        >
                            Save
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {/* dialog save pain */}
            <Dialog
                open={openSaveDialog}
                onClose={handleEditClose}
                disableBackdropClick
            >
                <DialogTitle>
                    Save sample
                </DialogTitle>
                <form onSubmit={handleSavePain}>
                    <DialogContent dividers>
                        <Box mb={2}>
                            <TextField
                                label="Pain Level"
                                variant="outlined"
                                name="pain_level"
                                select
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: true }}
                                fullWidth
                            >
                                <option value={''}> Select </option>
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, index) => {
                                        return (
                                            <option key={index} value={data}> {data} </option>
                                        )
                                    })
                                }
                            </TextField>
                        </Box>
                        <Box mb={2}>
                            <TextField
                                label="Description"
                                variant="outlined"
                                name="description"
                                fullWidth
                                multiline
                            />
                        </Box>

                        <Box>
                            <TextField
                                label="Password"
                                variant="outlined"
                                name="password"
                                type="password"
                                fullWidth
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={handleEditClose}
                        >
                            Close
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isprocess}
                            type="submit"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default BodyPain;