import React, { Fragment, useState, useEffect } from 'react';  
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';  
import TablePagination from '@material-ui/core/TablePagination';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import MoreVert from '@material-ui/icons/MoreVert';  
// import Details from '@material-ui/icons/DetailsTwoTone'; 
// import IconButton from '@material-ui/core/IconButton'; 
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Box, Card, CardHeader, Typography, CardContent } from '@material-ui/core';
import Label from 'src/utils/Label';



function AppointmentReport({ connection }) {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))

    const [vappreport, setvappreport] = useState([])   
    const [rowsPerPage, setRowsPerPage] = React.useState(10); 
    const [page, setPage] = React.useState(0); 

    const getappointmentReport = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('connection', connection)  
        
        Axios.post('appointment/doctors/appointment/appointmentreport-virtual', formdata)
        .then( (response) => { 
            const data = response.data;    
            setvappreport(data)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    useEffect(() => {

        getappointmentReport()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);  

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };   
    
    return (
        <Fragment>
            <Card variant="outlined" component={Box}>
                <CardHeader 
                    title={
                        <Typography className={`gtc-uppercase`} color="primary" variant="subtitle2"> ONLINE APPOINTMENT LIST </Typography>
                    }
                />
                <CardContent>
                    <TableContainer> 
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> Date </TableCell>
                                    <TableCell> Patient </TableCell>
                                    <TableCell> Service </TableCell> 
                                    <TableCell> Status </TableCell>
                                    <TableCell> Token </TableCell> 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? vappreport.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : vappreport
                                ).map((data, index) => (
                                <TableRow hover key={index}>
                                    <TableCell component="th" scope="row">
                                        { Notify.dateTimeConvert(data.appointment_date) }
                                    </TableCell>
                                    <TableCell>{data.patients}</TableCell>
                                    <TableCell>{data.services}</TableCell>
                                    <TableCell>
                                        <Label
                                            color={ data.appointment_status  === 'new'  ? "primary" : "success" }
                                        > { data.appointment_status } </Label> 
                                    </TableCell>
                                    <TableCell align="right">{ data.token }</TableCell>
                                    {/* <TableCell>
                                        <IconButton aria-label="delete" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                            <MoreVert fontSize="small"/>
                                        </IconButton> 
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={ handleClose }
                                        >
                                            <MenuItem> <Details/> Details </MenuItem> 
                                        </Menu>
                                    </TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody> 
                        </Table> 

                        <TablePagination
                            labelRowsPerPage = "List"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            component="div"
                            count={vappreport.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                        
                    </TableContainer> 
                    
                </CardContent>
            </Card>
        </Fragment>
    )
}

export default AppointmentReport;
