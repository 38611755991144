import React, { Fragment, useContext, useState } from 'react';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import NewPatient from './NewPatient';
import PatientHeader from './PatientHeader';

import Box from '@material-ui/core/Box';
import TablePagination from '@material-ui/core/TablePagination';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import IconButton from '@material-ui/core/IconButton';
import { InputAdornment, TextField, Badge, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { PatientsUnRead } from 'src/ContextAPI';
import { useParams } from 'react-router-dom'; 


const imageLocation = process.env.REACT_APP_API_IMAGE;

const Patients = () =>{

    const user_id =  localStorage.getItem('user_id')
    const token =  localStorage.getItem('token') 
    
    const { parampatient_id } = useParams()  

    const [patients, setPatients] = useState({ data: [] , ready: false })

    const [search , setSearch] = useState('')

    const [isSelected, setIsSelected] = useState(parampatient_id === 'all' ? false : true)

    const [selectedPatientId, setSelectedPatientId] = useState(parampatient_id === 'all' ? null : parampatient_id)

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)

    const [collapseList, setCollapseList] = useState(false)

    const unread = useContext(PatientsUnRead);

    const getPatients = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);

        Axios.post('doctor/patient/patients-list', formdata)
        .then((response) => {
            const data = response.data;
            setPatients({ data: data, ready: true }) 
        }).catch(error => {
            Notify.requestError(error);
        });
    }   

    const checkPatientUnreadNotif = ( patient_id, index ) =>{
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual;

        let count = 0;

        for (let i = 0; i < xx.length; i++) {
            if( patient_id === xx[i].patient_id){
                count += 1;
            }
        }  

        for (let i = 0; i < yy.length; i++) {
            if( patient_id === yy[i].patient_id){
                count += 1;
            }
        }   

        return count; 
    }

    const handleSelectedPatient = (e) => {
        setIsSelected(true)
        setCollapseList(true)
        setSelectedPatientId(e.currentTarget.id) 
    } 

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    };

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage)
    };  
    
    const matchParamAndState = React.useCallback(() =>{
        setIsSelected(parampatient_id === 'all' ? false : true)
        setSelectedPatientId(parampatient_id === 'all' ? null : parampatient_id)
    }, [parampatient_id])

    
    React.useEffect(() =>{
 
        getPatients()   

        matchParamAndState()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[matchParamAndState])

    

    var searchable = patients.data.filter( (data) => {
        return data.lastname.toLowerCase().indexOf(search.toLowerCase()) !== -1 || data.firstname.toLowerCase().indexOf(search.toLowerCase()) !== -1;;
    }); 
 
    return (
        <>
            <Fragment>
                <Box m={2}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={collapseList ? 6 : 6}
                            sm={collapseList ? 3 : 4}
                            md={collapseList ? 2 : 3}
                            xl={collapseList ? 1 : 2}
                        >
                            <Box component={Paper} variant="outlined" p={2}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <Box
                                        flexGrow={collapseList ? 0 : 1}
                                        mt={1}
                                    >
                                        <Typography
                                            variant="h6"
                                            noWrap
                                            color="textSecondary"
                                            hidden={collapseList}
                                        >
                                            Patients
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            color="primary"
                                            onClick={() => setCollapseList(! collapseList) }
                                        >
                                            <MenuOpenIcon />
                                        </IconButton>
                                    </Box>
                                </Box>

                                <Box>
                                    <TextField
                                        label="Search"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={search}
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment>
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>    

                                <List
                                    component="nav"
                                    disablePadding={false}
                                >
                                    <ListItem
                                        button
                                        onClick={() =>{
                                            setIsSelected(false);
                                            setSelectedPatientId(null)
                                        }}
                                        className="mb-2"
                                    >
                                        <ListItemIcon>
                                            <Avatar
                                                className={ selectedPatientId === null ? "bg-danger" : ""}
                                            >
                                                <AddCircleIcon />
                                            </Avatar>
                                        </ListItemIcon>
                                        <ListItemText primary={
                                            <Typography
                                                noWrap
                                                color={ selectedPatientId === null ? "secondary" : "inherit"}
                                            > New Patient </Typography>
                                        } />
                                    </ListItem>
                                    {
                                        patients.ready ?
                                            (rowsPerPage > 0
                                                ? searchable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : searchable
                                            ).map((data, index) => {
                                                return (
                                                    <ListItem
                                                        button
                                                        key={index}
                                                        id={data.patient_id}
                                                        className="mb-2"
                                                        onClick={handleSelectedPatient}
                                                    >
                                                        <ListItemIcon> 
                                                            <Badge 
                                                                invisible={ ! Boolean(checkPatientUnreadNotif(data.patient_id)) }
                                                                color="primary"
                                                                badgeContent={ checkPatientUnreadNotif(data.patient_id) }
                                                                anchorOrigin={{
                                                                    horizontal: 'left',
                                                                    vertical: 'top'
                                                                }}
                                                            >
                                                                <Avatar className={data.patient_id ===  selectedPatientId ? "bg-danger" : ""}> 
                                                                    {
                                                                        data.image === null ?
                                                                            data.lastname.charAt().toUpperCase() :
                                                                            <img src={imageLocation + 'patients/' + data.image} alt="" className="img-fluid" />
                                                                    }
                                                                </Avatar>
                                                            </Badge>  
                                                        </ListItemIcon>
                                                        <ListItemText 
                                                            primary={`${data.lastname}, ${data.firstname}`}
                                                            primaryTypographyProps={{
                                                                color : selectedPatientId === data.patient_id ? "secondary" : "inherit",
                                                                noWrap : true,
                                                                className : "gtc-capitalize"
                                                            }} 
                                                        />
                                                    </ListItem>
                                                )
                                            })
                                        : Notify.loading()
                                    }
                                </List> 
                                <TablePagination
                                    component="div"
                                    count={searchable.length}
                                    rowsPerPageOptions={[10, 50, 100]}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    labelRowsPerPage='List'
                                    labelDisplayedRows={({ from, to, count }) => ``}
                                />
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={collapseList ? 6 : 6}
                            sm={collapseList ? 9 : 8}
                            md={collapseList ? 10 : 9}
                            xl={collapseList ? 11 : 10}
                        >
                            {
                                isSelected ?
                                    <PatientHeader patient_id={ selectedPatientId} connection="local" patientDetails={ patients.data } getPatientsList = { () => getPatients() } />
                                    :
                                    <NewPatient getPatientsList = { () => getPatients() } />
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Fragment>
        </>
    )
}

export default Patients;  
     