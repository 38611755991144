
import React, { useEffect, useRef, useState } from 'react';
import { Box, CardMedia, CircularProgress, Dialog, DialogContent, Drawer, Typography,
    Grid,
    Tooltip,
    IconButton,
    Button,
    DialogActions,
    Badge
} from "@material-ui/core";     
import Notify from 'src/notification/Notify';
import { useHistory, useParams } from 'react-router-dom';
import { blue, deepOrange, green, red } from '@material-ui/core/colors';
import { Alert, AlertTitle } from '@material-ui/lab';
import OnlinechecupChat from 'src/oncheckupchat/OnlinechecupChat'; 
import ChatIcon from '@material-ui/icons/QuestionAnswer';   
import Axios from 'axios';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CheckIcon from '@material-ui/icons/CheckCircleOutline' 
import ClearIcon from '@material-ui/icons/HighlightOff'; 


const RTCMultiConnection = require('rtcmulticonnection');
const EnterRoomOld = () => { 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username') 

    const myvideoRef = useRef(null)  
    const clientvideoRef = useRef(null) 
    const [callTimeMinutes, setCallTimeMinutes] = useState(0)
    const [callTimeSeconds, setCallTimeSeconds] = useState(0)

    const [ validreq, setValidReq] = useState(false) 
    const [ prepared, setPrepared] = useState(false) 
    const [drawer, setDrawer] = useState('') 
    const [ roomnotFound , setRoomNotFound ] = useState(false) 
    const { ref_number } = useParams()
    const [ redialBtnDisabled, setRedialBtnDisabled] = useState(true) 
    const [refDetails, setRefDetails] = useState(null);
    const [con, setCon] = useState(null)
    const [ remoteId, setRemoteId] = useState(null)
    const [exitDialog, setExitDialog] = useState(false)
    const history = useHistory() 
    const [unreadMsg, setUnreadMsg] = useState(0) 

    var timeInterval = null; 
    var msgInterval = null;
    var connection = null;

    const checkMicandVideo  =  (details) => {
        navigator.mediaDevices.getUserMedia({video:true, audio: true})
        .then(() => {
            setValidReq(true)
            setPrepared(true)
            initializeRtc(details)
        })
        .catch(() =>{ 
            setValidReq(false)
            setPrepared(true)
            Notify.customToast('Camera/Mic Not Found.', 'Enable your camera to continue.')
        }) 
    } 

    const initializeRtc = (details) =>{
        connection = new RTCMultiConnection(); 
        connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';
        connection.enableLogs = false;
        connection.userid = username;
        connection.autoCreateMediaElement = false;
        connection.maxParticipantsAllowed = 3;
        connection.session = {
            audio : true,
            video : true
        }  

        connection.iceServers = [];

        connection.iceServers.push({
            urls: 'stun:global.stun.twilio.com:3478?transport=udp', 
            url: 'stun:global.stun.twilio.com:3478?transport=udp', 
        });

        connection.iceServers.push({
            urls: 'turn:global.turn.twilio.com:3478?transport=udp',
            credential: 'Z+l8d6zA8FfuBj4nZXw1D1RIJQtpKlztDUuGsLd20XM=',
            username: '7655d2535654422e5c043aa6266f07b10c10a5efca11802386959bc729d57831'
        });   
        
        connection.sdpConstraints.mandatory = {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true,
        }     

        setCon(connection)
        initializeAnswer(connection, details)
    }

    const initializeAnswer = (rtc, details) =>{    
        rtc.onstream = (event) => {    
            if (event.type === 'local') { 
                var video = myvideoRef.current;  
                video.srcObject = event.stream;
                video.play()  
            }
        
            if (event.type === 'remote') { 
                startCallTimer()  
                setRemoteId(event.userid)
                var videox = clientvideoRef.current;
                videox.srcObject = event.stream;
                videox.play()
            }
        };

        rtc.onleave = function(event) {
            var remoteUserId = event.userid;   
            Notify.customToast(`${remoteUserId} is disconnected.`)
            clearInterval(timeInterval)
            setRedialBtnDisabled(false)
        };   

        setTimeout(() => {
            rtc.checkPresence(details.room_number, function(isRoomExist, roomid, error) {
                if (isRoomExist === true) { 
                    Notify.customToast('Connected', 'You are now connected.')
                } else { 
                    console.log(isRoomExist, roomid, error)
                    setRedialBtnDisabled(false)
                    Notify.customToast('Room Closed', 'Room is close by doctor.')
                } 
            });
        }, 3000);

        rtc.join(details.room_number); 

    }
    const getVcallRoom = () =>{
        var formdata = new FormData();   
        formdata.append('ref_number' , ref_number)
        formdata.append('token', token)
        formdata.append('user_id', user_id)
        formdata.set('connection', 'online');    
        
        Axios.post('patient/online/appointment/getrefinfo', formdata )
        .then( (response) => { 
            const data = response.data;    
            if(Object.keys(data).length > 0){ 
                checkMicandVideo(data) 
                setRefDetails(data)
                intializeGetUnreadMsgInterval(data.doctors_userid) 
            }else{ 
                Notify.requestError('Room Not Found') 
                setRoomNotFound(true) 
            }
        }).catch(error=>{  
            Notify.requestError(error);
        });
    }   
 

    const getLatestMessageFromDoctor = (doctorsId) => {
        var formdata = new FormData();
        formdata.append('token', token)
        formdata.append('user_id', user_id)
        formdata.append('senders_id', doctorsId)
        formdata.set('connection', 'online');

        Axios.post('patient/get/all-unread-msg', formdata)
        .then((response) => {
            const data = response.data;
            setUnreadMsg(parseInt(data.length))
        }).catch(error => {
            Notify.requestError(error);
        });
    }

    const intializeGetUnreadMsgInterval = (doctorsId) =>{ 
        msgInterval = setInterval(() => {  
            getLatestMessageFromDoctor(doctorsId)
        }, 5000)
    }

    useEffect(() =>{        

        
        getVcallRoom() 
 
        return(() => {  
            if(con){ endCall() }
            clearInterval(timeInterval)
            clearInterval(msgInterval)
        }) 

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])  
    
    const handleReenterRoom = () =>{  
        setRedialBtnDisabled(true)
        getVcallRoom()
    }  

    const endCall = () =>{ 
        con.deletePeer(remoteId);
        con.closeSocket();
        con.getAllParticipants().forEach(function(participantId) {
            con.disconnectWith( participantId );
        });  
    } 

    const removeRoom = () => {   
        var formdata = new FormData();   
            formdata.set('token', token);  
            formdata.set('user_id', user_id);    
            formdata.set('app_ref_number', refDetails.ref_number);    
            formdata.set('connection', 'online');    
            
        Axios.post('patient/online/appointment/delete-room', formdata )
        .then( (response) => { 
            const data = response.data;   
            if(data === 'success'){  
                Notify.customToast('Room Deleted', 'Room Successfully Destroy.')
                if(con){ endCall() } 
                setTimeout(() => { 
                    history.push('/app/patient')
                }, 3000);
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        });  
    }

    const calculateTime = () =>{
        var x = parseInt(callTimeMinutes) / 60;
        var min = x < 10 ? `0${Math.floor(x)}` : Math.floor(x); 
        return min;
    }

    const startCallTimer = () => {   
        timeInterval = setInterval(() => {    
            setCallTimeSeconds((callTimeSeconds) => callTimeSeconds >= 59 ? 0 : callTimeSeconds + 1)   
            setCallTimeMinutes((callTimeMinutes) => callTimeMinutes + 1)   
        }, 1000); 
    }  

    return( 
        <>  
            <Dialog
                open = { true } 
                disableBackdropClick 
                disableEscapeKeyDown
                fullScreen
                
            >
                <DialogContent style={{
                    backgroundColor: '#000'
                }}>    
                    
                    {
                        roomnotFound ? (
                            <Box
                                display="flex"
                                justifyContent="center" 
                                alignItems="center"
                                height={'calc(100vh - 50px)'}
                                border={1}
                            >    
                                <Box alignItems="center" align="center"> 
                                    <Typography color="primary" align="center"> ROOM NOT FOUND </Typography>
                                    <Box mt={1}>
                                        <Button size="small" color="primary" variant="contained" onClick={ () => history.go(0) }> Reload </Button>
                                        <Button component={Box} ml={2} size="small" color="default" variant="contained" onClick={ () => history.push(`/app/patient`) }> Home </Button>
                                    </Box> 
                                </Box>     
                            </Box>
                        ):
                        (
                             prepared ? (
                                <>
                                    {  validreq && (
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6} lg={5}> 
                                                { drawer === 'drawer-chat' && (
                                                    <Box>
                                                        <Drawer variant="persistent" anchor={'left'} open={drawer === 'drawer-chat'} onClose={ () => setDrawer('')}>
                                                            <Box width={ window.innerWidth > 900 ? window.innerWidth - 350 : window.innerWidth }>
                                                                <OnlinechecupChat client_id = {refDetails && refDetails.doctors_userid } close={ () => setDrawer('')} />
                                                            </Box>
                                                        </Drawer> 
                                                    </Box> 
                                                )}
                                                
                                            </Grid>
    
                                            <Grid item xs={12} sm={12}>
                                                <Box 
                                                    display="flex"
                                                    justifyContent="center" 
                                                    alignItems="center"
                                                    height={'calc(100vh - 50px)'}  
                                                > 
                                                        <>
                                                            <video   // myvideo tag
                                                                playsInline
                                                                style={
                                                                    window.innerWidth < 600 || drawer !== '' ? { 
                                                                        position: 'absolute',
                                                                        maxWidth: '100px',
                                                                        top: '320px',
                                                                        right:'24px', 
                                                                        border: `2px solid ${blue[500]}`,
                                                                        borderRadius: '10px',
                                                                        zIndex: 1
                                                                    }: {    
                                                                        position: 'absolute',
                                                                        maxWidth: '200px',
                                                                        bottom: '30px',
                                                                        right:'30px', 
                                                                        border: `2px solid ${blue[500]}`,
                                                                        borderRadius: '10px',
                                                                        zIndex: 1
                                                                    }
                                                                } 
                                                                ref={ myvideoRef } 
                                                                muted 
                                                            />  
    
                                                            {/* client video */} 
                                                            <Box >    
                                                                <Box>
                                                                    <CardMedia
                                                                        component="div"
                                                                    >
                                                                        <video  
                                                                            playsInline 
                                                                            style={
                                                                                window.innerWidth < 600 || drawer !== '' ? {    
                                                                                    position: 'absolute',
                                                                                    maxWidth: '290px',
                                                                                    top: '170px',
                                                                                    right:'30px', 
                                                                                    border: `2px solid ${blue[500]}`,
                                                                                    borderRadius: '10px',
                                                                                }:{             
                                                                                    maxWidth: window.innerWidth > 600 ? '500px' : window.innerWidth , 
                                                                                    objectFit: 'cover',
                                                                                    border: `2px solid ${blue[500]}`,
                                                                                    borderRadius: '3px',
                                                                                    bottom: '200px',
                                                                                    right:'30px',   
                                                                                }
                                                                            }
                                                                            className={'img-fluid'}
                                                                            ref={ clientvideoRef } 
                                                                        /> 
                                                                    </CardMedia>
                                                                </Box>     
    
                                                                <Box 
                                                                    display="flex" 
                                                                    my={2}   
                                                                    style={ 
                                                                        window.innerWidth < 600 || drawer !== '' ? { 
                                                                            position: "absolute",
                                                                            top: 400,
                                                                            right: 10, 
                                                                            justifyContent:"center"
                                                                        } : { 
                                                                            justifyContent:"center" ,
                                                                        }
                                                                    }
                                                                >
                                                                    <Box m={1}>
                                                                        <Tooltip title="Timer" arrow>
                                                                            <Typography color="primary" variant="h5"> 
                                                                                { calculateTime() } : {callTimeSeconds > 10 ? callTimeSeconds : `0${callTimeSeconds}`} 
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    </Box> 
    
                                                                    <Box m={1}>
                                                                        <Tooltip title="Chat" arrow>
                                                                            <IconButton 
                                                                                style={{ 
                                                                                    backgroundColor: deepOrange[500], 
                                                                                    color: '#fff' 
                                                                                }}
                                                                                onClick={ () => setDrawer('drawer-chat') }
                                                                            >
                                                                                <Badge badgeContent={unreadMsg} color="secondary">
                                                                                    <ChatIcon />
                                                                                </Badge>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </Box> 
                                                                    <Box m={1}>
                                                                        <Tooltip title="Re-enter" arrow>
                                                                            <Box>
                                                                                <IconButton 
                                                                                    style={{ backgroundColor: green[redialBtnDisabled ? 200: 500], color: '#fff' }}
                                                                                    disabled={ redialBtnDisabled }
                                                                                    onClick={ handleReenterRoom }
                                                                                >
                                                                                    <MeetingRoomIcon />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Tooltip>
                                                                    </Box>  

                                                                    <Box m={1}>
                                                                        <Tooltip title={`Leave & Delete Room`} arrow>
                                                                            <Box>
                                                                                <IconButton 
                                                                                    style={{ backgroundColor: red[500], color: '#fff' }}
                                                                                    disabled={ refDetails === null }
                                                                                    onClick={ () =>{
                                                                                        endCall()
                                                                                        setExitDialog(true)
                                                                                        history.push('/app/patient')
                                                                                    }}
                                                                                >
                                                                                    <ExitToAppIcon />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Tooltip>
                                                                    </Box>  
                                                                </Box>
                                                            </Box> 
                                                        </> 
                                                </Box>
                                            </Grid>
                                        </Grid> 
                                    )}  
    
                                    {  ! validreq && ( 
                                        <Box
                                            display="flex"
                                            justifyContent="center" 
                                            alignItems="center"
                                            height={'calc(100vh - 50px)'}
                                            border={1}
                                        >    
                                            <Box>
                                                <Alert severity="info"> 
                                                    <AlertTitle>
                                                        Camera Or Microphone Error !
                                                    </AlertTitle>
                                                    Enable your camera and microphone to continue.
                                                </Alert>
                                            </Box>     
                                        </Box>
                                    )}
                                </>
                            ) : (
                                //  preparing requirements 
                                <Box
                                    display="flex"
                                    justifyContent="center" 
                                    alignItems="center"
                                    height={'calc(100vh - 50px)'}
                                    border={1}
                                >    
                                    <Box alignItems="center" align="center">
                                        <CircularProgress size={30} color="primary" />
                                        <Typography color="primary" align="center"> preparing... </Typography>
                                    </Box>     
                                </Box>
                            ) 
                        )
                    }
                </DialogContent> 
            </Dialog>
 
            {/* delete and exit room dialog */}
            <Dialog
                open = { exitDialog } 
                onClose = { () => setExitDialog(false) }
                disableBackdropClick 
                disableEscapeKeyDown
                maxWidth="xs"
                fullWidth
            >
                <DialogContent>
                    <Box>
                        <Typography>
                            Are you sure to exit and destroy this room?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="default" 
                        startIcon={ <ClearIcon /> } 
                        onClick={ () => setExitDialog(false)  }
                    >
                        No
                    </Button>  

                    <Button 
                        variant="contained"
                        color="primary"
                        startIcon={ <CheckIcon /> } 
                        onClick={() => { 
                            removeRoom();
                            setExitDialog(false) ;
                        }}
                    >
                        {`Yes`}
                    </Button>  
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EnterRoomOld; 

