import React, { Component, Fragment } from 'react';   
import Notify from '../../../notification/Notify';
import Axios from 'axios';  
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Card, TextField, CardContent, IconButton, Button, CircularProgress } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export default class UnsavePrescription extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
            _patient_id: this.props.patient_id, 
            _prescType: this.props.prescType, 

            _unsave: [],
            _unsave_ready: false,

            _remove_selected_id: null,
            _remove_dialog_open: false,
            _is_process: false,

            _save_dialog_open: false,
            _issaving: false,
        }
    }

    componentDidMount(){
        this._mounted = true;
        this.unsavePrescription();   
    }

    componentWillUnmount(){
        this._mounted = false;
    }
    
    componentDidUpdate(){
        if(this.state._patient_id !== this.props.patient_id || this.state._prescType !== this.props.prescType){
            this.setState({
                _patient_id: this.props.patient_id,
                _prescType: this.props.prescType,
                _unsave_ready: false,
            })
            this.componentDidMount();
        }
    }

    unsavePrescription = () =>{
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);    
        formdata.set('patient_id', this.props.patient_id);    
        formdata.set('connection', this.props.prescType === 'virtual-prescription' ? 'online' : 'local')
        
        Axios.post('prescription/local/product-unsave', formdata )
        .then((response)=>{ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({ _unsave: data, _unsave_ready: true });
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    closeRemovedialog = () =>{
        this.setState({
            _remove_dialog_open: false,
            _remove_selected_id: null,
        })
    }

    handleSelectedProd = (e) =>{
        this.setState({
            _remove_dialog_open: true,
            _remove_selected_id: e.currentTarget.id,
        })
    }

    handleRemove = (e) =>{
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);    
        formdata.set('username', this.state._username);    
        formdata.set('remove_id', this.state._remove_selected_id);   
        formdata.set('connection', this.props.prescType === 'virtual-prescription' ? 'online' : 'local')
        
        var error = [];
        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }
        if(error.length > 0){
            Notify.consoleLog('remove presc')
        }else{
            this.setState({ _is_process: true })
            Axios.post('prescription/local/product-unsave-remove', formdata )
            .then((response) => { 
                const data = response.data;   
                if(this._mounted){  
                    if(data ==='pass-invalid'){
                        Notify.fieldInvalid('password')
                    }
                    else if(data ==='success'){
                        this.setState({
                            _remove_dialog_open: false
                        })
                        this.props.getUnsavePrescription()
                        this.unsavePrescription();
                        Notify.successRequest('remove presc')
                    }else{
                        Notify.warnRequest('remove presc')
                    }
                } 
            }).catch(error=>{  
                Notify.requestError(error);
            }).finally(() =>{
                this.setState({ _is_process: false })
            })
        }
    }

    handleSave = (e) => {
        e.preventDefault();
        e.persist();
        
        var formdata = new FormData(e.target)
            formdata.set('user_id', this.state._user_id)
            formdata.set('token', this.state._token)
            formdata.set('username', this.state._username)
            formdata.set('patient_id', this.props.patient_id)
            formdata.set('connection', this.props.prescType === 'virtual-prescription' ? 'online' : 'local')
            
        var error = [];
        if(error.length > 0){
            console.warn("Unable to process sample, Form has an error.")
        }else{
            this.setState({ _issaving: true })
            Axios.post('prescription/doctor/local/prescriptionsaveallUnsave', formdata )
            .then((response) => { 
                const data = response.data;   
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'success'){
                    this.setState({ _save_dialog_open: false })
                    this.unsavePrescription()
                    this.props.getUnsavePrescription()
                    Notify.successRequest('prescription save')
                }
            }).catch(error=>{  
                Notify.requestError(error);
            }).finally(() =>{
                this.setState({ _issaving: false })
            })
        }
    }
	render(){ 
		return(
            <Fragment>
                <Box mb={1} hidden={ this.props.connection === 'online'}>
                    <Typography variant="subtitle2" color="primary">  UNSAVE PRESCRIPTION </Typography>
                </Box>

                {
                    this.state._unsave_ready ? 
                        this.state._unsave.length > 0 ? 
                            this.state._unsave.map((data, index)=>{
                                return(
                                    <Card 
                                        key={index}  
                                        elevation={0}
                                    >
                                        <CardContent className="p-3">
                                            <Box display="flex">
                                                <Box flexGrow={1} mt={1}>
                                                    <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                        { data.product_name === null ? data.prescription : data.product_name }
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <IconButton
                                                        id={ data.id }
                                                        onClick={this.handleSelectedProd}
                                                    >
                                                        <TrashIcon />
                                                    </IconButton>
                                                </Box>
                                            </Box>
  
                                            <Box>
                                                <Typography variant="subtitle2">
                                                    {`${ data.quantity } pc(s) of ${ data.dosage }`}
                                                </Typography> 
                                            </Box> 

                                            <Box>
                                                <Typography variant="caption" color="textSecondary">
                                                    Sig. { data.remarks }
                                                </Typography> 
                                            </Box>
                                        </CardContent>  
                                    </Card>
                                )
                            })
                        :  Notify.noRecord() 
                    : Notify.loading()
                }

                {/* save prescription dialog */}
                { this.state._unsave.length > 0 ? 
                    <Button 
                        variant='contained'
                        color="primary"
                        onClick={()=> this.setState({ _save_dialog_open: true })}
                    > Save Prescription 
                    </Button>
                : true }

                {/* remove prescription dialog */}  
                <Dialog open={ this.state._remove_dialog_open } onClose={this.closeRemovedialog}>
                    <DialogTitle> Remove Prescription </DialogTitle>
                    <form onSubmit={this.handleRemove}>
                        <DialogContent dividers> 
                            <Box>
                                <TextField 
                                    variant="outlined"
                                    label="Enter your password"
                                    name="password"
                                    type="password"
                                    fullWidth 
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions> 
                            <Button 
                                variant="contained" 
                                color="default"
                                onClick={this.closeRemovedialog}
                                startIcon={ <HighlightOffIcon /> }
                            > 
                                 No 
                            </Button>
                            <Button 
                                variant="contained" 
                                color="secondary"
                                type="submit"
                                disabled = { this.state._is_process }
                                startIcon={ 
                                    this.state._is_process  ? <CircularProgress size={20} color="inherit" /> : <CheckCircleOutlineIcon /> 
                                }
                            > 
                                Remove 
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog 
                    open = {this.state._save_dialog_open}
                    onClose={()=> this.setState({ _save_dialog_open: false })}
                >
                    <DialogTitle> Save Prescription</DialogTitle> 
                    <form onSubmit={ this.handleSave }>
                        <DialogContent dividers>
                            <Box>
                                <TextField 
                                    variant="outlined"
                                    label="Enter your password"
                                    name="password"
                                    type="password"
                                    fullWidth 
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions> 
                            <Button 
                                variant="contained" 
                                color="default"
                                onClick={()=> this.setState({ _save_dialog_open: false })}
                                startIcon={ <HighlightOffIcon /> }
                            > 
                                 No 
                            </Button>
                            <Button 
                                variant="contained" 
                                color="primary"
                                type="submit"
                                disabled = { this.state._issaving }
                                startIcon={ 
                                    this.state._issaving  ? <CircularProgress size={20} color="inherit" /> : <CheckCircleOutlineIcon /> 
                                }
                            > 
                                 Save 
                            </Button> 
                        </DialogActions>
                    </form>
                </Dialog> 
            </Fragment>
        )
	}
}
