
import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react"; 
import Notify from "../../notification/Notify"; 
import Box from "@material-ui/core/Box"; 
import GTCChart from "../../GoogleChart/GTCChart";

const Cholesterol = () => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [chartData, setChartData] = useState([])  
    const [ready, setReady] = useState(false)

    const getCholesterol = useCallback(() => {
        var formData = new FormData();
			formData.set('user_id', user_id);
			formData.set('token', token); 

        Axios.post('patient/vitals/graph/get-cholesterol',  formData)
		.then( (response) => { 
            const data = response.data;   
            pushtoChart(data)  
            setReady(true)
		})
		.catch(error => { 
			Notify.requestError(error);
		}) 
    }, [user_id, token]);
    
    const pushtoChart = (data) =>{ 
        if(data.length > 0){
            var newData = [['x', 'Cholesterol']]
            for (let i = 0; i < data.length; i++) {
                newData.push([Notify.dateTimeConvert(data[i].created_at), parseFloat(data[i].cholesterol)])  
            }    
            setChartData(newData)
        }
        else{
            setChartData([['x', 'Cholesterol'], ['No Record', 0]])
        }
    }

    useEffect(() =>{
        getCholesterol()
    }, [getCholesterol]) 

    return ( 
        <Box 
            px={2}
        >
            {
                ready ? 
                    <GTCChart
                        chartData= { chartData }
                        chartLabel = {'Cholesterol'} 
                    /> 
                : Notify.loading()
            } 
        </Box>  
    );
};
export default Cholesterol;