
import React, { Fragment, useContext, useEffect, useState } from "react" 
import { BrowserRouter, NavLink, Redirect, Route, Switch } from 'react-router-dom';  
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound"; 
import { ThemeContext } from "../ContextAPI";
import DarkIcon from '@material-ui/icons/Brightness4';
import LightIcon from '@material-ui/icons/Brightness7';
import { AppBar, Badge, Box, Hidden, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core"; 
import ExitToAppIcon from '@material-ui/icons/ExitToApp';   
import MoreVertIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home'; 
import GTCValidateLS from "src/notification/GTCValidateLS"; 
import ImagingSentToTelerad from "src/imaging/ImagingSentToTelerad";
import Imaging from "src/imaging/Imaging";
import Axios from "axios";

const RouteImaging = () =>{
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const themeContext = useContext(ThemeContext)    
    const [anchorEl, setAnchorEl] = useState(null)
    const [menu, setMenu] = useState(false)
    const [newcount, setNewCount] = useState(0)

    var interval;

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
        setMenu(true) 
    }; 

    const getTeleradNewFindings = async () =>{
        
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)
            formdata.set('connection', 'online')
        const response = await Axios.post('imaging/order/newfindings', formdata)
        
        setNewCount(response.data.length)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setMenu(false) 
    }; 

    const initializeInterval = () =>{
        interval = setInterval(() => {
            getTeleradNewFindings()
        }, 10000);
    } 

    useEffect(() => {

        initializeInterval()
        GTCValidateLS.verifyLocalStorage()

        return(() => { clearInterval(interval)})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <Fragment>  
            <BrowserRouter>
                <AppBar 
                    position="static" 
                    color={ themeContext.gtcThemeDark ? 'default' : 'primary' } 
                    className={`gtc-appbar`}
                >
                    <Hidden xsDown>
                        <Toolbar> 
                            {/* desktop navigation bar */} 
                            <Box 
                                display="flex" 
                                width={'100%'}
                                className={`appbarLink`}
                            >  
                                <Box flexGrow={1}> 
                                    <Box display="flex">
                                        <Box m={2}>
                                            <Typography className={`text-white`}>
                                                GTC IMAGING
                                            </Typography>
                                        </Box> 
                                        <Box m={2}> 
                                            <NavLink to="/app/imaging" exact activeClassName="text-white"> 
                                                Home 
                                            </NavLink> 
                                        </Box>    

                                        <Box m={2}> 
                                            <NavLink to="/app/imaging/sentto/telerad/all" exact activeClassName="text-white"> 
                                                <Badge invisible={ ! Boolean(newcount) } badgeContent={'new'} color="secondary">
                                                    Telerad
                                                </Badge> 
                                            </NavLink> 
                                        </Box>    
                                    </Box> 
                                </Box> 
                            </Box>       

                            <Box>
                                <Box display="flex" width={180}>
                                    <Box m={2} className={`pointer`}> 
                                        <Typography onClick={()=>{ themeContext.updategtcTheme(themeContext.gtcThemeDark ? 'light' : 'dark') } }> { themeContext.gtcThemeDark ? <DarkIcon /> : <LightIcon /> } </Typography>
                                    </Box>

                                    <Box m={2}>  
                                        <NavLink to="/app/logout" exact style={{ color: '#c5c5c5', textDecoration: 'none' }}> 
                                            Logout  <ExitToAppIcon />
                                        </NavLink>    
                                    </Box>
                                </Box>
                            </Box>
                        </Toolbar>
                    </Hidden>

                    <Hidden smUp>
                        <Toolbar>
                            {/* mobile navigation */}
                            <Box 
                                display="flex" 
                                width={'100%'}
                                className={`appbarLink`}
                            >  
                                <Box flexGrow={1}> 
                                    <Box display="flex">
                                        <Box m={2} flexGrow={1}>
                                            <Typography noWrap className={`text-white`}>
                                                GTC IMAGING
                                            </Typography>
                                        </Box>   

                                        <Box m={2} className={`pointer`}> 
                                            <Typography onClick={()=>{ themeContext.updategtcTheme(themeContext.gtcThemeDark ? 'light' : 'dark') } }> { themeContext.gtcThemeDark ? <DarkIcon /> : <LightIcon /> } </Typography>
                                        </Box>
                                        
                                        <Box>
                                            <IconButton
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                onClick={ handleMenu }
                                                color="inherit"
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="menu-appbar"
                                                anchorEl={ anchorEl }
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                keepMounted  
                                                open={ menu }
                                                onClose={ handleClose }
                                            >
                                                <MenuItem
                                                    component={NavLink}
                                                    to="/app/imaging"
                                                >
                                                    <ListItemIcon>
                                                        <HomeIcon />
                                                    </ListItemIcon> 
                                                    <Typography color="primary"> 
                                                        Home 
                                                    </Typography>  
                                                </MenuItem> 

                                                <MenuItem
                                                    component={NavLink}
                                                    to="/app/imaging/sentto/telerad/all"
                                                >
                                                    <ListItemIcon>
                                                        <HomeIcon />
                                                    </ListItemIcon> 
                                                    <Typography color="primary"> 
                                                        Telerad 
                                                    </Typography>  
                                                </MenuItem> 

                                                <MenuItem
                                                    component={NavLink}
                                                    to="/app/logout"
                                                >
                                                    <ListItemIcon>
                                                        <ExitToAppIcon />
                                                    </ListItemIcon>
                                                    <Typography color="error">
                                                        Logout 
                                                    </Typography>  
                                                </MenuItem> 
                                            </Menu>
                                        </Box>
                                    </Box> 
                                </Box> 
                            </Box>  
                        </Toolbar>
                    </Hidden>
                </AppBar>
                <Switch>  
                    <Route exact path="/" component={() => <Redirect to="/app/imaging" /> } />   
                    <Route exact path="/app" component={() => <Redirect to="/app/imaging" /> } />   
                    <Route exact path="/app/imaging" component={Imaging}/>  
                    <Route exact path="/app/imaging/sentto/telerad/:orderId" component={ImagingSentToTelerad}/>  
                    <Route exact path="/app/logout" component={Logout} />
                    <Route render={
                        () => <PageNotFound />
                    }/> 
                </Switch>
            </BrowserRouter>  
        </Fragment>
    )
}

export default RouteImaging;
 