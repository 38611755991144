
import React, { useState, useCallback, useEffect } from 'react'
import { Box, List, Typography, ListItem, Avatar, ListItemText, ListItemAvatar } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar'; 
import { grey } from '@material-ui/core/colors';
import Axios from 'axios';
import Notify from '../notification/Notify'; 
import DoctorsListDetails from './DoctorsListDetails';


const imageLocation = process.env.REACT_APP_API_IMAGE;

const DoctorsList = () =>{ 

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 
    const [doctorsList, setDoctorsList] = useState([])
    const [doctorsListReady, setDoctorsListReady] = useState(false)

    const [openDetails, setOpenDetails] = useState(false)
    const [selectedDoctorId, setSelectedDoctorId] = useState(null)
     
    const getDoctorsList = useCallback(() => {
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)  
        Axios
        .post('clinic/secretary/accredited-doctors', formdata)
        .then((response) =>{
            const data = response.data    
            setDoctorsList(data)
            setDoctorsListReady(true)
        }).catch(error => { Notify.requestError(error) }) 
    },[token, user_id])

    const handleSelectedDoctor = (e) =>{ 
        setOpenDetails(true)
        setSelectedDoctorId(e.currentTarget.id)
    }

    useEffect(() =>{

        getDoctorsList()

    },[getDoctorsList]) 

    return(
        <>
            <Box  
                border={1}
                borderColor={grey[300]}
                bgcolor="white"
            >
                <Box
                    px={2} 
                    mt={2}  
                >
                    <Typography
                        noWrap 
                        color="primary" 
                        variant="subtitle2"
                    > DOCTORS LIST  </Typography>     
                </Box>  
                <List
                    maxHeight={'calc(100vh - 125px)'}
                    overflow="auto"  
                    component={Box}  
                >  
                    <PerfectScrollbar> 
                        {
                            doctorsListReady ?  
                                doctorsList.length > 0 ?
                                    doctorsList.map((data, index) =>(
                                        <ListItem 
                                            style={{ cursor: 'pointer' }}   
                                            key={index}
                                            id={ data.doctor_userid }
                                            onClick={ handleSelectedDoctor }
                                            selected={ data.doctor_userid === selectedDoctorId  }
                                        >
                                            <ListItemAvatar> 
                                                <Avatar 
                                                    alt={ data.doctors_name } 
                                                    src={data.doctors_image !== null ? `${imageLocation}doctors/${data.doctors_image}` : '' }
                                                />
                                            </ListItemAvatar>
                                            <ListItemText  
                                                primary={
                                                    <Typography 
                                                        variant="subtitle2" 
                                                        noWrap  
                                                    > 
                                                        DR. { data.doctors_name }
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography 
                                                        variant="body2" 
                                                        color="textSecondary"
                                                        noWrap  
                                                    >
                                                        { data.doctors_spicialty }
                                                    </Typography>
                                                }
                                            />
                                        </ListItem> 
                                    ))
                                :   <Box 
                                        align="center" 
                                        my={2}
                                    >  {Notify.noRecord()} </Box>
                            :   <Box 
                                    align="center" 
                                    my={2}
                                > { Notify.loading()} </Box>
                        } 
                    </PerfectScrollbar>
                </List>
            </Box> 

            {
                openDetails && ( 
                    <DoctorsListDetails 
                        open={ openDetails } 
                        close ={ () => setOpenDetails(false) } 
                        doctor_userid = { selectedDoctorId } 
                    />
                ) 
            }
        </>
    )
}

export default DoctorsList;