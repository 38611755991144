import React, { Fragment,useEffect, useState } from 'react';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import PersonalInfoHeader from '../patients/PersonalInfoHeader'; 
import { Box, ButtonGroup, Button, Dialog, Zoom,FormControlLabel, Checkbox, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress, Typography, Drawer, Paper, IconButton  } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Cancel from '@material-ui/icons/Cancel'
import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns'; 
// import AppointmentApprovedDetailsReadmore from '../appointment/AppointmentApprovedDetailsReadmore';
// import { grey } from '@material-ui/core/colors';
import PatientHeader from 'src/doctor/patients/PatientHeader'

function AppointmentDetails(props) {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [username] = useState(localStorage.getItem('username'))

    const [localappdetails, setlocalappdetails] = useState([])
    const [localappdetailsrd, setlocalappdetailsrd] = useState(false)

    const [completedialog, setcompletedialog] = useState(false)
    const [iscompleteprocess, setiscompleteprocess] = useState(false)

    const [rescheduleDialog, setrescheduleDialog] = useState(false)

    const [appendDate, setappendDate] = useState(new Date());
    const [reschedappdate, setreschedappdate] = useState(new Date()); 
    const [isreschedprocess, setisreschedprocess] = useState(false)

    const [ispatientinform, setispatientinform] = useState(false)

    const [contactinfodialog, setcontactinfodialog] = useState(false) 

    const [ updateAppointment, setUpdateAppointment] = useState({
        data: null,
        dialog: false
    })

    const [changeIsProcess, setChangeIsProcess] = useState(false)

    const [details, setDetails] = useState(false) 

    const handleAppendDate = (date) => {
        setappendDate(date);
    };

    const handleReschedappDate = (date) => {
        setreschedappdate(date);
    }; 

    const getAppointmentDetails = () =>{
        var formdata = new FormData();
        formdata.set('token', token); 
        formdata.set('user_id', user_id);     
        formdata.set('app_id', props.appid);     
        
        Axios.post('doctor/appointment/local/applocal-details', formdata )
        .then( (response) =>{ 
            const data = response.data;    
            setlocalappdetails(data)
            setlocalappdetailsrd(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const handleCompleteDialog = () =>{
        setcompletedialog(!completedialog)
    }

    const handlerescheduleDialog = () =>{
        setrescheduleDialog(!rescheduleDialog)
    }

    const handleContactInfo = () =>{
        setcontactinfodialog(!contactinfodialog)
    }
    
    const completeAppSave =  (e) =>{
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);  
        formdata.set('token', token); 
        formdata.set('username', username);     
        formdata.set('user_id', user_id);     
        formdata.set('appid', props.appid);     

        var error = []; 

        if(formdata.get('append_date').length === 0 || formdata.get('append_date').trim()===''){  
            Notify.fieldRequired('date')
            error = 'error';
        }
        if(formdata.get('append_time').length === 0 || formdata.get('append_time').trim()===''){  
            Notify.fieldRequired('time')
            error = 'error';
        } 

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){  
            Notify.fieldRequired('password')
            error = 'error';
        }

        if(error.length > 0){
            console.warn("form has an error. watch out.")
        }else{
            setiscompleteprocess(true)
            Axios.post('doctors/appointment/local/set-complete', formdata )
            .then( (response) =>{ 
                const data = response.data;    
                if(data === 'success'){
                    props.resetdisplay()
                    handleCompleteDialog()
                    Notify.successRequest('set complete')
                }
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'db-error'){
                    Notify.warnRequest('set complete')
                }
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(()=>{
                setiscompleteprocess(false)
            })
        } 
    }

    const rescheduleAppSave = (e) => {

        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);  
        formdata.set('token', token); 
        formdata.set('username', username);     
        formdata.set('user_id', user_id);     
        formdata.set('appid', props.appid);       

        var error = []; 

        if(formdata.get('resched_date').length === 0 || formdata.get('resched_date').trim()===''){  
            Notify.fieldRequired('date')
            error = 'error';
        }
        if(formdata.get('resched_time').length === 0 || formdata.get('resched_time').trim()===''){  
            Notify.fieldRequired('time')
            error = 'error';
        } 

        if(formdata.get('resched_reason').length === 0 || formdata.get('resched_reason').trim()===''){  
            Notify.fieldRequired('reason')
            error = 'error';
        }  

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){  
            Notify.fieldRequired('password')
            error = 'error';
        }  

        if(!ispatientinform){  
            Notify.fieldRequired('patient_informed')
            error = 'error';
        } 
        
        if(error.length > 0){
            console.warn("form has an error. watch out.")
        }else{
            setisreschedprocess(true)
            Axios.post('doctors/appointment/local/set-reschedule', formdata )
            .then( (response) =>{ 
                const data = response.data;    
                if(data === 'success'){
                    props.resetdisplay()
                    handlerescheduleDialog()
                    Notify.successRequest('set reschedule')
                }
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'db-error'){
                    Notify.warnRequest('set reschedule')
                }
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(()=>{
                setisreschedprocess(false)
            })
        }
    }

    const updateServiceType = (e) =>{
        e.preventDefault()
        e.persist()
        var formdata = new FormData(e.target)
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.append('username', username)
        var error = [];


        if(formdata.get('service').length === 0 || formdata.get('service').trim()===''){  
            Notify.fieldRequired('service')
            error = 'error';
        }  

        if(formdata.get('amount').length === 0 || formdata.get('amount').trim()===''){  
            Notify.fieldRequired('amount')
            error = 'error';
        }  
        
        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){  
            Notify.fieldRequired('password')
            error = 'error';
        }  

        if(error.length > 0){
            console.warn("form has an error. watch out.")
        }else{
            setChangeIsProcess(true)
            Axios.post('doctors/appointment/local/change-appointmenttype', formdata )
            .then( (response) =>{ 
                const data = response.data;    
                if(data === 'success'){
                    getAppointmentDetails()
                    Notify.successRequest('appointment change')
                    setUpdateAppointment({ data: null, dialog: false })
                }
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'db-error'){
                    Notify.warnRequest('appointment change')
                }
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(()=>{
                setChangeIsProcess(false)
            })
        }
    }

    useEffect(() => {
        getAppointmentDetails()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.appid]); 


    return (
        <Fragment> 
            {
                localappdetailsrd ? 
                    localappdetails.length > 0 ?
                        <Fragment>
                            {/* persoanl details */}
                            <Box>
                                <PersonalInfoHeader patient_id =  { localappdetails[0].patients_id } />
                            </Box> 

                            {/* action list */}
                            <Box>
                                <ButtonGroup fullWidth size={'large'} color="primary" component={Paper} variant="outlined" elevation={0}> 
                                    <Button onClick={ () =>{
                                        setDetails(!details)
                                    }}> Patient Details </Button>  
                                    <Button onClick={ handleCompleteDialog }>Set Complete </Button>
                                    <Button onClick={ handlerescheduleDialog }> Re-schedule </Button> 
                                    <Button onClick={ handleContactInfo }> Contact Info </Button>  
                                </ButtonGroup>
                            </Box>

                            {/* appointment details */} 
                                <Box my={2} component={Paper} p={2} className={`gtc-textfield-noborder`}>  
                                    <Box mb={1} display="flex">   
                                        <Box flexGrow={1}>
                                            <Typography variant="subtitle2" className="gtc-uppercase"> 
                                               Appointment Details
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Button
                                                color="primary"
                                                onClick ={ () => setUpdateAppointment({ data: localappdetails[0], dialog: true }) }
                                            >
                                                Change Service
                                            </Button>
                                        </Box>
                                    </Box>

                                    <Box mb={2}> 
                                        <TextField
                                            label="Appointment Reschedule"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            variant="outlined"
                                            multiline
                                            value={` ${ Boolean(parseInt(localappdetails[0].is_reschedule)) ? 'Yes'  : ' No ' } `}
                                        />
                                    </Box>

                                    { Boolean(parseInt(localappdetails[0].is_reschedule)) &&(
                                        <Box mb={2}> 
                                            <TextField
                                                label="Appointment Reschedule"
                                                fullWidth
                                                InputProps={{ readOnly: true }}
                                                variant="outlined"
                                                multiline
                                                value={` ${localappdetails[0].is_reschedule_reason === null ? 'None' : localappdetails[0].is_reschedule_reason}`}
                                            />
                                        </Box>
                                    )}
                                    

                                    <Box mb={2}> 
                                        <TextField
                                            label="Appointment Date"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            variant="outlined"
                                            multiline
                                            value={` ${ Boolean(parseInt(localappdetails[0].is_reschedule)) ?  Notify.dateTimeConvert(localappdetails[0].is_reschedule_date) :  Notify.dateTimeConvert(localappdetails[0].app_date) } `}
                                        />
                                    </Box>

                                    <Box mb={2}> 
                                        <TextField
                                            label="Appointment Reason"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            variant="outlined"
                                            multiline
                                            value={` ${ localappdetails[0].app_reason === null ? 'None' : localappdetails[0].app_reason } `}
                                        />
                                    </Box>

                                    <Box mb={2}> 
                                        <TextField
                                            label="Appointment Type"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            variant="outlined"
                                            multiline
                                            value={` ${ localappdetails[0].services === null ? 'None' : localappdetails[0].services } `}
                                        />
                                    </Box> 

                                    <Box mb={2}> 
                                        <TextField
                                            label="Appointment Rate"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            variant="outlined"
                                            multiline
                                            value={` ${ localappdetails[0].amount === null ? 'None' : localappdetails[0].amount } `}
                                        />
                                    </Box> 
                                     
                                    <Box mb={2}> 
                                        <TextField
                                            label="Appointment Rate"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            variant="outlined"
                                            multiline
                                            value={` ${ localappdetails[0].amount === null ? 'None' : localappdetails[0].amount } `}
                                        />
                                    </Box>  

                                    <Box mb={2}> 
                                        <TextField
                                            label="Created On"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            variant="outlined"
                                            multiline
                                            value={` ${ localappdetails[0].created_at === null ? 'None' : Notify.dateTimeConvert(localappdetails[0].created_at) } `}
                                        />
                                    </Box>   
                                </Box>  

                            {/* patient information more */}
                            <Drawer anchor={'right'} open={details} onClose={() => setDetails(!details)}>
                                <Box maxWidth={window.innerWidth > 800 ? '70vw' : window.innerWidth } p={2}> 
                                    <Box 
                                        display="flex"
                                    >
                                        <Box mt={1} flexGrow={1}>
                                            <Typography
                                                variant="subtitle2"
                                                color="primary"
                                                className={`gtc-uppercase`}
                                            >
                                                Patient Information
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => setDetails(!details)}
                                            >
                                                <Cancel />
                                            </IconButton>
                                        </Box>
                                    </Box> 

                                    <PatientHeader patient_id= { localappdetails[0].patients_id } connection ="local" />
                                </Box>
                            </Drawer>

                            {/* complete appointment dialog */}
                            <Dialog
                                disableBackdropClick
                                open={completedialog}
                                TransitionComponent={Zoom}
                                transitionDuration={500} 
                                onClose={ handleCompleteDialog }  
                            >
                                <DialogTitle>
                                    Set complete
                                </DialogTitle>

                                <form onSubmit={ completeAppSave }>
                                    <DialogContent dividers>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>   
                                            <Box mb={1} mt={1}>
                                                <DatePicker 
                                                    // disablePast
                                                    fullWidth 
                                                    margin="normal" 
                                                    label="Appointment Date End "
                                                    format="MM/dd/yyyy"
                                                    value={appendDate}
                                                    onChange={ handleAppendDate } 
                                                    inputVariant="outlined"
                                                    name="append_date"
                                                />  
                                            </Box>

                                            <Box mb={1}>
                                                <TimePicker
                                                    fullWidth 
                                                    margin="normal" 
                                                    label="Appointment Time End"
                                                    value={appendDate}
                                                    inputVariant="outlined"
                                                    onChange={ handleAppendDate } 
                                                    name="append_time"
                                                />
                                            </Box>
                                            <TextField 
                                                fullWidth
                                                label="Enter password" 
                                                name="password" 
                                                type="password" 
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </DialogContent>
                                    <DialogActions> 
                                        <Button 
                                            startIcon={<HighlightOffIcon /> } 
                                            variant="contained" color="default" 
                                            onClick={handleCompleteDialog}> No </Button>
                                        <Button 
                                            type="submit" 
                                            disabled={ iscompleteprocess ? true : false } 
                                            startIcon={ iscompleteprocess ? <CircularProgress size={15} color="inherit" /> : <CheckCircleOutlineIcon />} 
                                            variant="contained" color="primary" > Save </Button>
                                        
                                    </DialogActions>
                                </form>
                            </Dialog>

                            {/* resched dialog */}
                            <Dialog
                                disableBackdropClick
                                open={rescheduleDialog}
                                TransitionComponent={Zoom}
                                transitionDuration={500} 
                                onClose={ handlerescheduleDialog }  
                            >
                                <form onSubmit={ rescheduleAppSave }>
                                    <DialogTitle >
                                        Re-schedule Appointment
                                    </DialogTitle>
                                    <DialogContent dividers>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>    
                                            <Box mb={1} mt={1}>
                                                <DatePicker 
                                                    // disablePast
                                                    fullWidth 
                                                    margin="normal" 
                                                    label="Re-shedule Appointment Date"
                                                    format="MM/dd/yyyy"
                                                    value={reschedappdate}
                                                    onChange={ handleReschedappDate } 
                                                    inputVariant="outlined"
                                                    name="resched_date"
                                                />  
                                            </Box>

                                            <Box mb={1}>
                                                <TimePicker
                                                    fullWidth 
                                                    margin="normal" 
                                                    label="Re-shedule Time"
                                                    value={reschedappdate}
                                                    inputVariant="outlined"
                                                    onChange={ handleReschedappDate } 
                                                    name="resched_time"
                                                />
                                            </Box>

                                            <Box mb={1}>
                                                <TextField 
                                                    fullWidth
                                                    label="Reason of Re-shedule" 
                                                    name="resched_reason"  
                                                    margin="normal"
                                                    variant="outlined"
                                                    multiline
                                                />
                                            </Box>

                                            <TextField 
                                                fullWidth
                                                label="Enter password" 
                                                name="password" 
                                                type="password" 
                                                margin="normal"
                                                variant="outlined"
                                            />  
                                        </MuiPickersUtilsProvider>
                                        <Box my={1}>
                                            <FormControlLabel onChange={ () => setispatientinform(!ispatientinform)} control={<Checkbox name="patient_informed" />} label={
                                                <span className="text-muted"> Please inform patient that this appointment is reschedule </span>
                                            } />
                                        </Box>
                                    </DialogContent>
                                    <DialogActions> 
                                        <Button 
                                            startIcon={<HighlightOffIcon /> } 
                                            variant="contained" color="default" 
                                            onClick={handlerescheduleDialog}> No </Button>
                                        <Button 
                                            type="submit" 
                                            disabled={ isreschedprocess } 
                                            startIcon={ isreschedprocess ? <CircularProgress size={15} color="inherit" /> : <CheckCircleOutlineIcon />} 
                                            variant="contained" color="primary" > Save </Button>
                                        
                                    </DialogActions> 
                                </form>
                            </Dialog>
                        
                            {/* contactinfo dialog */}
                            <Dialog 
                                disableBackdropClick
                                open={contactinfodialog}
                                TransitionComponent={Zoom}
                                transitionDuration={500} 
                                onClose={ handleContactInfo }  
                            > 
                                <DialogTitle>
                                    Contact Information
                                </DialogTitle>
                                <DialogContent 
                                    dividers
                                    className="gtc-textfield-noborder"
                                >
                                    {
                                        localappdetails.length > 0 ?
                                            <Fragment>
                                                <Box >
                                                    <TextField 
                                                        margin="normal"
                                                        label="Email"
                                                        variant="outlined"
                                                        value={ localappdetails[0].email  === null ? 'None' : localappdetails[0].email }
                                                        InputProps={{
                                                            inputProps:{
                                                                readOnly: true 
                                                            }
                                                        }}
                                                    />
                                                </Box> 

                                                <Box mt={2}> 
                                                    <TextField 
                                                        margin="normal"
                                                        label="Mobile"
                                                        variant="outlined"
                                                        value={ localappdetails[0].mobile  === null ? 'None' : localappdetails[0].mobile }
                                                        InputProps={{
                                                            inputProps:{
                                                                readOnly: true 
                                                            }
                                                        }}
                                                    />
                                                </Box> 

                                                <Box mt={2}> 
                                                    <TextField 
                                                        margin="normal"
                                                        label="Mobile"
                                                        variant="outlined"
                                                        value={ localappdetails[0].telephone  === null ? 'None' : localappdetails[0].telephone }
                                                        InputProps={{
                                                            inputProps:{
                                                                readOnly: true 
                                                            }
                                                        }}
                                                    />
                                                </Box> 
                                            </Fragment>
                                        : 'No contact found.'
                                    }
                                </DialogContent>
                                <DialogActions>
                                    <Button 
                                        variant="contained" 
                                        color="default" 
                                        onClick={ handleContactInfo }
                                        startIcon={
                                            <Cancel />
                                        }
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Fragment>
                    : Notify.noRecord()
                : Notify.loading()
            }

            {/* update appointment type */}
            <Dialog 
                open={ updateAppointment.dialog }
                onClose ={ () => setUpdateAppointment({ data: null, dialog: false }) }
                disableEscapeKeyDown
                disableBackdropClick
            >
                { updateAppointment.data && updateAppointment.dialog && (
                    <>
                        <DialogTitle>
                            Change Service Type
                        </DialogTitle>
                        <form onSubmit={ updateServiceType }>
                            <DialogContent dividers>

                                <Box mb={2}>
                                    <TextField 
                                        fullWidth
                                        label="Appointment Id"
                                        variant="outlined"
                                        name="appointment_id"
                                        defaultValue={ updateAppointment.data.appointment_id}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Box>

                                <Box mb={2}>
                                    <TextField
                                        fullWidth 
                                        label="Appointment Type"
                                        variant="outlined"
                                        name="service"
                                        defaultValue={ updateAppointment.data.services}
                                    />
                                </Box>

                                <Box mb={2}>
                                    <TextField 
                                        fullWidth
                                        label="Appointment Rate"
                                        variant="outlined"
                                        name="amount"
                                        type="number"
                                        InputProps={{
                                            inputProps:{
                                                min: 1,
                                                max: 10000,
                                                step: 0.01
                                            }
                                        }}
                                        defaultValue={ updateAppointment.data.amount}
                                    />
                                </Box>

                                <Box>
                                    <TextField 
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        name="password"
                                        variant="outlined"
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    variant="contained" 
                                    color="default"    
                                    onClick ={ () => setUpdateAppointment({ data: null, dialog: false }) }
                                >
                                    Cancel
                                </Button>

                                <Button 
                                    variant="contained" 
                                    color="primary"  
                                    disabled ={ changeIsProcess }
                                    startIcon={
                                        changeIsProcess && ( <CircularProgress size={20} color="inherit" /> )
                                    }
                                    type="submit"
                                >
                                    Change
                                </Button>
                            </DialogActions>
                        </form>
                    </>
                )}
                
            </Dialog>
        </Fragment>
    )
}

export default AppointmentDetails;
