import { Grid , Typography, Box, Card , CardHeader, CardContent, CardMedia } from '@material-ui/core';
import React  from 'react'
// import HemathologyRef from '../references/Ref_Hemathology';
import Header from './Header'
import Footer from './Footer'

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ClinicalMicroscopyOrder = ({ formheader,  orderDetails }) => { 

    return(
        <>
            <Card>  
                
                <Box display="flex" justifyContent="center">
                    <Box mt={2}>
                        {
                            formheader && 
                            <CardMedia
                                style={{ width : 70 }}
                                component={'img'}
                                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                            /> 
                        } 
                    </Box>
                    <Box >
                        <CardHeader
                            component={Box}
                            align="center"
                            title={ formheader && (formheader.name) }
                            subheader={ formheader && (formheader.address) }
                        /> 
                    </Box>
                </Box> 
                
                
                <CardContent>
                    <Header details={orderDetails} />
                </CardContent> 
                
                <CardContent> 
                    {/* order details */}
                    <Box>
                        <Typography variant="h6" align="center">
                            <b> CLINICAL MICROSCOPY </b>
                        </Typography>
                    </Box> 

                    <Box>  
                        <>
                            <Box mb={1}>
                                <Typography variant="caption"> <b> SPECIMEN: </b> </Typography> <span className="gtc-uppercase"> { orderDetails.data.spicemen } </span>
                            </Box>

                            <Box mb={2}>
                                {/* clinical microscopy */}
                                { Boolean(parseInt(orderDetails.data.chemical_test)) && 
                                    <>
                                        <Box>
                                            <Typography variant="subtitle1"> <b> CHEMICAL TEST </b> </Typography> 
                                        </Box>
                                        <Box ml={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4} sm={4}>
                                                    <Box mt={1} display="flex">
                                                        <Typography variant="caption"> <b> COLOR: </b>  </Typography> 
                                                        <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                            { orderDetails.data.chemical_test_color } 
                                                        </Box>
                                                    </Box>  
                                                    
                                                    <Box mt={1} display="flex">
                                                        <Typography variant="caption"> <b> TRANSPARENCY: </b>  </Typography> 
                                                        <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                            { orderDetails.data.chemical_test_transparency } 
                                                        </Box>
                                                    </Box> 
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <Box mt={1} display="flex">
                                                        <Typography variant="caption"> <b> PH: </b>  </Typography> 
                                                        <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                            { orderDetails.data.chemical_test_ph } 
                                                        </Box>
                                                    </Box>
                                                    <Box mt={1} display="flex">
                                                        <Typography variant="caption"> <b> SPECIFIC GRAVITY: </b>  </Typography> 
                                                        <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                            { orderDetails.data.chemical_test_spicific_gravity } 
                                                        </Box>
                                                    </Box> 
                                                </Grid>
                                                <Grid item xs={4} sm={4}>
                                                    <Box mt={1} display="flex">
                                                        <Typography variant="caption"> <b> GLUCOSE: </b>  </Typography> 
                                                        <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                            { orderDetails.data.chemical_test_glucose } 
                                                        </Box>
                                                    </Box>
                                                    <Box mt={1} display="flex">
                                                        <Typography variant="caption"> <b> ALBUMIN: </b>  </Typography> 
                                                        <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                            { orderDetails.data.chemical_test_albumin } 
                                                        </Box>
                                                    </Box>  
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                }
                            </Box>

                            <Box mb={2}>
                                {/* clinical microscopy */}
                                { Boolean(parseInt(orderDetails.data.microscopic_test)) && 
                                    <>
                                        <Box>
                                            <Typography variant="subtitle1"> <b> MICROSCOPIC TEST </b> </Typography> 
                                        </Box>
                                        <Box ml={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sm={6}>
                                                    <>
                                                        <Box mt={1}>
                                                            <Typography variant="subtitle2" color="textSecondary"> CELLS </Typography>
                                                        </Box> 
                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Squamous Cells </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_squamous} 
                                                            </Box>
                                                        </Box> 

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Pus Cells </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_pus} 
                                                            </Box> /HPF
                                                        </Box> 

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Red Blood Cells </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_redblood} 
                                                            </Box> /HPF
                                                        </Box>  
                                                    </> 

                                                    <> 
                                                        <Box mt={1}>
                                                            <Typography variant="subtitle2" color="textSecondary"> CASTS </Typography>
                                                        </Box> 
                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Hyaline Cast </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_hyaline} 
                                                            </Box>
                                                        </Box>

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> WBC Cast </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_wbc} 
                                                            </Box>
                                                        </Box>

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> RBC Cast </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_rbc} 
                                                            </Box>
                                                        </Box>

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Fine Granualar Cast </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_fine_granular} 
                                                            </Box>
                                                        </Box>

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Coarse Granualar Cast </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_coarse_granular} 
                                                            </Box>
                                                        </Box> 
                                                    </>
                                                </Grid>

                                                <Grid item xs={6} sm={6}>
                                                    <>
                                                        <Box mt={1}>
                                                            <Typography variant="subtitle2" color="textSecondary"> CRYSTALS </Typography>
                                                        </Box> 
                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Calcium Oxalate </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_calcium_oxalate} 
                                                            </Box>
                                                        </Box> 

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Triple Phosphate </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_triple_phospahte} 
                                                            </Box>
                                                        </Box>

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Leucine/Tyrosine </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_leucine_tyrosine} 
                                                            </Box>
                                                        </Box>  

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Ammonium Biurate </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_ammonium_biurate} 
                                                            </Box>
                                                        </Box>

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Amorphous Urates </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_amorphous_urates} 
                                                            </Box>
                                                        </Box>  

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Amorphous Phosphates </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_amorphous_phosphates} 
                                                            </Box>
                                                        </Box> 

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Uric Acid </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_uricacid} 
                                                            </Box>
                                                        </Box> 
                                                    </>  
                                                    <>
                                                        <Box mt={1}>
                                                            <Typography variant="subtitle2" color="textSecondary"> OTHERS </Typography>
                                                        </Box> 

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Mucus Thread </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_mucus_thread} 
                                                            </Box>
                                                        </Box> 

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Bacteria </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_bacteria} 
                                                            </Box>
                                                        </Box>

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption"> <b> Yeast </b>  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                {orderDetails.data.microscopic_test_yeast} 
                                                            </Box>
                                                        </Box>  
                                                    </>
                                                </Grid>  
                                            </Grid>
                                        </Box>
                                    </>
                                }
                            </Box>

                            <Box mb={2}>
                                {/* clinical microscopy */}
                                { Boolean(parseInt(orderDetails.data.pregnancy_test_hcg)) && 
                                    <> 
                                        <Box mt={1} display="flex">
                                            <Typography variant="subtitle1"> <b> PREGNANCY TEST (HCG): </b>  </Typography> 
                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                {orderDetails.data.pregnancy_test_hcg_result} 
                                            </Box>
                                        </Box>   
                                    </>
                                }
                            </Box>

                            <Box mb={2}>
                                {/* clinical microscopy */} 
                                <>
                                    <Box mt={1} display="flex">
                                        <Typography variant="subtitle1"> <b> REMARKS: </b>  </Typography> 
                                        <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                            {orderDetails.data.result_remarks} 
                                        </Box>
                                    </Box> 
                                </> 
                            </Box>
                        </> 
                    </Box> 

                    <Box>
                        <Footer formheader={ formheader } />
                    </Box>
                </CardContent>  
            </Card>  
        </>
    )
}

export default ClinicalMicroscopyOrder;