 
import { Box, Grid, Paper, TextField, Typography, CircularProgress, FormHelperText, Button, IconButton } from '@material-ui/core';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';   
import Notify from 'src/notification/Notify';
import * as Yup from 'yup'; 
import { Formik } from 'formik'; 
import ClearIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/CheckCircleOutline'; 
import EditIcon from '@material-ui/icons/Edit'; 

const PatientDetailsProfile = ({ patient_id }) => {  
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const [edit, setEdit] = useState(false)

    const [ patientInfo, setPatientInfo ] = useState({
        data: null,
        ready: false
    })
    
    const  getFormData = (object) => {
        const formData = new FormData(); 
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    } 

    const getPatientDetails = useCallback( () => { 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);     
        formdata.set('patient_id', patient_id);     
        
        Axios.post('encoder/patients/getpatient-info', formdata )
        .then( (response) =>{ 
            const data = response.data;     
            setPatientInfo({
                data: data, ready: true
            }) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, user_id, patient_id]) 

    useEffect(() =>{

        getPatientDetails()

    },[getPatientDetails])
    return(
        <Box
            component={Paper}
            variant="outlined"
            mt={2}
            p={2}
        > 
            <Box className={ edit ? '' : `gtc-textfield-noborder`}>  
                {
                    patientInfo.ready ?
                        patientInfo.data ? 
                        <Formik   
                            enableReinitialize
                            initialValues={{
                                username: username,
                                user_id: user_id, 
                                token: token,  
                                password: '',
                                patient_id: patientInfo.data.patient_id,
                                lastname: patientInfo.data.lastname, 
                                firstname: patientInfo.data.firstname, 
                                middlename: patientInfo.data.middle === null ? "" : patientInfo.data.middle, 
                                gender: patientInfo.data.gender === null ? "" : patientInfo.data.gender,
                                birthday: patientInfo.data.birthday === null ? "" : patientInfo.data.birthday,
                                birthplace: patientInfo.data.birthplace === null ? "" : patientInfo.data.birthplace,
                                street: patientInfo.data.street === null ? "" : patientInfo.data.street,
                                barangay: patientInfo.data.barangay === null ? "" : patientInfo.data.barangay,
                                city: patientInfo.data.city === null ? "" : patientInfo.data.city,
                                occupation: patientInfo.data.birthplace === null ? "" : patientInfo.data.birthplace,
                                civil_status: patientInfo.data.civil_status === null ? "" : patientInfo.data.civil_status,
                                religion: patientInfo.data.religion === null ? "" : patientInfo.data.religion,
                                mobile: patientInfo.data.mobile === null ? "" : patientInfo.data.mobile,
                                telephone: patientInfo.data.telephone === null ? "" : patientInfo.data.telephone,
                                email: patientInfo.data.email === null ? "" : patientInfo.data.email,

                                // health
                                height: patientInfo.data.height === null ? "" : patientInfo.data.height,
                                weight: patientInfo.data.weight === null ? "" : patientInfo.data.weight,
                                pulse: patientInfo.data.pulse === null ? "" : patientInfo.data.pulse,
                                temp: patientInfo.data.temperature === null ? "" : patientInfo.data.temperature,
                                bp_systolic: patientInfo.data.blood_systolic === null ? "" : patientInfo.data.blood_systolic,
                                bp_diastolic:patientInfo.data.blood_diastolic === null ? "" : patientInfo.data.blood_diastolic,
                                blood_type: patientInfo.data.blood_type === null ? "" : patientInfo.data.blood_type,
                            }}
                            validationSchema={Yup.object().shape({
                                password: Yup.string().trim().required(),
                                lastname: Yup.string().trim().required(), 
                                firstname: Yup.string().trim().required(), 
                                gender: Yup.string().trim().required(), 
                                birthday: Yup.date().required(),
                                birthplace: Yup.string().trim().required(),  
                                street: Yup.string().trim().required(),
                                barangay: Yup.string().trim().required(),
                                city: Yup.string().trim().required(),
                                occupation: Yup.string().trim().required(),
                                civil_status: Yup.string().trim().required(),
                                religion: Yup.string().trim().required(),
                                mobile: Yup.string().trim().required(),
                                email: Yup.string().trim().email().required(),
                            })}
                            onSubmit={async (values, {
                                setErrors, 
                                setSubmitting,
                                resetForm
                            }) => {  
                                try { 
                                    const request = await Axios.post('encoder/patients/edit-patient', getFormData(values)) 
                                    if(request.data === 'pass-invalid'){ 
                                        setErrors({ password: 'Password is invalid.' });
                                        Notify.fieldInvalid('password')
                                    }  
                                    if(request.data === 'success'){
                                        getPatientDetails()
                                        Notify.successRequest('update patient')
                                        resetForm(); 
                                        setSubmitting(true); 
                                        setEdit(false)
                                    }
                                } catch (error) {
                                    const message = error.message || 'Something went wrong';  
                                    setErrors({ submit: message });
                                    setSubmitting(false);
                                }
                            }}
                            >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit, 
                                isSubmitting,
                                touched,
                                values
                            }) => (
                                <form
                                    noValidate 
                                    onSubmit={handleSubmit}  
                                > 
                                    <Box mb={2} display="flex">
                                        <Box flexGrow={1}>
                                            <Typography
                                                color="primary"
                                                variant="subtitle2"
                                            >
                                                PATIENT INFORMATION
                                            </Typography>
                                        </Box>
                                        <Box hidden={ edit }>
                                            <IconButton
                                                onClick= { () => setEdit(true) }
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Box> 
                                    </Box> 
        
                                    <Box my={3}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.lastname && errors.lastname)}
                                                        helperText={touched.lastname && errors.lastname}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.lastname}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }}
                                                        required
                                                        name="lastname"
                                                        label="Lastname"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
        
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.firstname && errors.firstname)}
                                                        helperText={touched.firstname && errors.firstname}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.firstname}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }} 
                                                        required
                                                        name="firstname"
                                                        label="Firstname"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
        
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.middlename && errors.middlename)}
                                                        helperText={touched.middlename && errors.middlename}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.middlename}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }} 
                                                        name="middlename"
                                                        label="Middle Name"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
        
        
                                    <Box my={3}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.gender && errors.gender)}
                                                        helperText={touched.gender && errors.gender}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.gender}
                                                        fullWidth 
                                                        required
                                                        name="gender"
                                                        label="Gender"
                                                        variant="outlined" 
                                                        select
                                                        SelectProps={{
                                                            native: true,
                                                            disabled: ! edit
                                                        }} 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    > 
                                                        <option value={''}>Select</option>
                                                        <option value={'Male'}>Male</option>
                                                        <option value={'Female'}>Female</option> 
                                                    </TextField>
                                                </Box>
                                            </Grid>
        
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.birthday && errors.birthday)}
                                                        helperText={touched.birthday && errors.birthday}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.birthday}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }}
                                                        required
                                                        name="birthday"
                                                        label="Birthday"
                                                        variant="outlined" 
                                                        type="date" 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
        
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.birthplace && errors.birthplace)}
                                                        helperText={touched.birthplace && errors.birthplace}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.birthplace}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }} 
                                                        required
                                                        name="birthplace"
                                                        label="Birth Place"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
        
                                    <Box my={3}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.street && errors.street)}
                                                        helperText={touched.street && errors.street}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.street}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }}
                                                        required
                                                        name="street"
                                                        label="Street"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
        
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.barangay && errors.barangay)}
                                                        helperText={touched.barangay && errors.barangay}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.barangay}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }} 
                                                        required
                                                        name="barangay"
                                                        label="Barangay"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
        
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.city && errors.city)}
                                                        helperText={touched.city && errors.city}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.city}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }} 
                                                        required
                                                        name="city"
                                                        label="City"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
        
                                    <Box my={3}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.occupation && errors.occupation)}
                                                        helperText={touched.occupation && errors.occupation}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.occupation}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }}
                                                        required
                                                        name="occupation"
                                                        label="Occupation"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
        
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.civil_status && errors.civil_status)}
                                                        helperText={touched.civil_status && errors.civil_status}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.civil_status}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }}
                                                        required
                                                        name="civil_status"
                                                        label="Civil Status"
                                                        variant="outlined" 
                                                        select
                                                        SelectProps={{
                                                            native: true,
                                                            disabled: ! edit
                                                        }} 
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    > 
                                                        <option value={''}>Select</option>
                                                        <option value={'Single'}>Single</option>
                                                        <option value={'Married'}>Married</option> 
                                                        <option value={'Divorced'}>Divorced</option> 
                                                    </TextField>
                                                </Box>
                                            </Grid>
        
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.religion && errors.religion)}
                                                        helperText={touched.religion && errors.religion}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.religion}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }} 
                                                        required
                                                        name="religion"
                                                        label="Religion"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
        
                                    <Box my={3}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.mobile && errors.mobile)}
                                                        helperText={touched.mobile && errors.mobile}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.mobile}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }}
                                                        required 
                                                        name="mobile"
                                                        label="Mobile"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
        
                                            <Grid item xs={12} sm={4}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.telephone && errors.telephone)}
                                                        helperText={touched.telephone && errors.telephone}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.telephone}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: ! edit
                                                        }}  
                                                        name="telephone"
                                                        label="Telephone"
                                                        variant="outlined" 
                                                    />
                                                </Box>
                                            </Grid>
        
                                            <Grid item xs={12} sm={4}> 
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.email && errors.email)}
                                                        helperText={touched.email && errors.email}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.email}
                                                        fullWidth
                                                        InputProps={{
                                                            readOnly: true
                                                        }} 
                                                        required
                                                        name="email"
                                                        label="Email"
                                                        variant="outlined" 
                                                    />
                                                    <FormHelperText error> Email address not editable </FormHelperText>
                                                </Box> 
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box my={3}>
                                        <Box mb={2}>
                                            <Typography color="textSecondary"> Health Records </Typography>
                                        </Box>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.height && errors.height)}
                                                        helperText={touched.height && errors.height}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.height}
                                                        fullWidth 
                                                        required
                                                        name="height"
                                                        label="Height(ft)"
                                                        variant="outlined" 
                                                        type="number"
                                                        InputProps={{
                                                            inputProps:{
                                                                min:1,
                                                                max:10000,
                                                                step:0.01
                                                            },
                                                            readOnly: ! edit
                                                        }} 
                                                    />   
                                                </Box>  
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.weight && errors.weight)}
                                                        helperText={touched.weight && errors.weight}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.weight}
                                                        fullWidth 
                                                        required
                                                        name="weight"
                                                        label="Weight(kg)"
                                                        variant="outlined" 
                                                        type="number"
                                                        InputProps={{
                                                            inputProps:{
                                                                min:1,
                                                                max:10000,
                                                                step:0.01
                                                            },
                                                            readOnly: ! edit
                                                        }}
                                                    />   
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.pulse && errors.pulse)}
                                                        helperText={touched.pulse && errors.pulse}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.pulse}
                                                        fullWidth 
                                                        required
                                                        name="pulse"
                                                        label="Pulse"
                                                        variant="outlined" 
                                                        type="number"
                                                        InputProps={{
                                                            inputProps:{
                                                                min:1,
                                                                max:10000,
                                                                step:0.01
                                                            },
                                                            readOnly: ! edit
                                                        }}
                                                    />   
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.temp && errors.temp)}
                                                        helperText={touched.temp && errors.temp}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.temp}
                                                        fullWidth 
                                                        required
                                                        name="temp"
                                                        label="Temperature"
                                                        variant="outlined" 
                                                        type="number"
                                                        InputProps={{
                                                            inputProps:{
                                                                min:1,
                                                                max:10000,
                                                                step:0.01
                                                            },
                                                            readOnly: ! edit
                                                        }}
                                                    />   
                                                </Box>
                                            </Grid>
                                        </Grid>  

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.bp_systolic && errors.bp_systolic)}
                                                        helperText={touched.bp_systolic && errors.bp_systolic}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.bp_systolic}
                                                        fullWidth 
                                                        required
                                                        name="bp_systolic"
                                                        label="BP Systolic"
                                                        variant="outlined" 
                                                        type="number"
                                                        InputProps={{
                                                            inputProps:{
                                                                min:1,
                                                                max:10000,
                                                                step:0.01
                                                            },
                                                            readOnly: ! edit
                                                        }}
                                                    />   
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Box>
                                                    <TextField 
                                                        error={Boolean(touched.bp_diastolic && errors.bp_diastolic)}
                                                        helperText={touched.bp_diastolic && errors.bp_diastolic}  
                                                        onBlur={handleBlur}
                                                        onChange={handleChange} 
                                                        value={values.bp_diastolic}
                                                        fullWidth 
                                                        required
                                                        name="bp_diastolic"
                                                        label="BP Diastolic"
                                                        variant="outlined" 
                                                        type="number"
                                                        InputProps={{
                                                            inputProps:{
                                                                min:1,
                                                                max:10000,
                                                                step:0.01
                                                            },
                                                            readOnly: ! edit
                                                        }}
                                                    />   
                                                </Box>
                                            </Grid>
                                        </Grid> 

                                        <Box mt={3}>
                                            <TextField 
                                                error={Boolean(touched.blood_type && errors.blood_type)}
                                                helperText={touched.blood_type && errors.blood_type}  
                                                onBlur={handleBlur}
                                                onChange={handleChange} 
                                                value={values.blood_type}
                                                fullWidth 
                                                required
                                                name="blood_type"
                                                label="Blood Type"
                                                variant="outlined" 
                                                select
                                                SelectProps={{ native: true, disabled: ! edit }}
                                                InputLabelProps={{
                                                    shrink: true 
                                                }}
                                            >
                                                <option value=""> Select </option>
                                                <option value="A"> A </option>
                                                <option value="B"> B </option>
                                                <option value="AB"> AB </option>
                                                <option value="O"> O </option> 
                                                <option value="A-negative"> A-Negative </option>
                                                <option value="B-positive"> B-Positive </option>
                                                <option value="B-negative"> B-Negative </option>
                                                <option value="O-positive"> O-Positive </option>
                                                <option value="O-negative"> O-Negative </option>
                                                <option value="AB-positive">AB-Positive </option>
                                                <option value="AB-negative">AB-Negative </option>
                                            </TextField>   
                                        </Box>
                                    </Box>
        
                                    <Box hidden = { ! edit }>
                                        <Box my={3}>
                                            <TextField 
                                                error={Boolean(touched.password && errors.password)}
                                                helperText={touched.password && errors.password}  
                                                onBlur={handleBlur}
                                                onChange={handleChange} 
                                                value={values.password}
                                                fullWidth
                                                InputProps={{
                                                    readOnly: ! edit
                                                }}
                                                required
                                                name="password"
                                                type="password"
                                                label="Password"
                                                variant="outlined" 
                                            />
                                        </Box>
        
                                        {errors.submit && (
                                            <Box mt={3}>
                                                <FormHelperText error>
                                                {errors.submit}
                                                </FormHelperText>
                                            </Box>
                                        )}
        
                                        <Box mb={2} display="flex"> 
                                            <Box flexGrow={1} />
        
                                            <Button
                                                variant="contained"
                                                color="default"
                                                onClick={ () => setEdit(false) }
                                                startIcon={ <ClearIcon /> }
                                            >
                                                Cancel
                                            </Button> 
                                            <Box ml={2}>   
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={ isSubmitting ? <CircularProgress size={20} color="inherit" /> : <CheckIcon /> }
                                                    disabled={isSubmitting}  
                                                >
                                                    Save
                                                </Button> 
                                            </Box>
                                        </Box> 
                                    </Box>
                                </form>
                            )}
                        </Formik>
                        : Notify.noRecord()
                    : Notify.loading()
                }
            </Box>
        </Box>
    )
}

export default PatientDetailsProfile;