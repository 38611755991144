import React, { Component } from 'react';  
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import './pharmacy/Pharmacy.css';
import './patients/Patients.css';
import './doctor/Doctor.css';
import 'react-quill/dist/quill.snow.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'leaflet/dist/leaflet.css'; 
import 'react-leaflet-fullscreen/dist/styles.css'  
import 'mapbox-gl/dist/mapbox-gl.css'
import NavbarHome from './routes/NavbarHome';
import { ToastContainer, Zoom } from 'react-toastify'; 
import axios from 'axios';
import LoginChecker from './welcome/LoginChecker';
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'; 

import { UnreadContext, ThemeContext } from './ContextAPI'; 

import Cookies from 'universal-cookie';
import { 
	// deepPurple
	blue, 
	// grey,
	// red, 
	// pink,
	// purple, 
	// orange,  
	// green 
} from '@material-ui/core/colors';
// import SyncData from './SycnDonascoData';
import Box from '@material-ui/core/Box';

const cookies = new Cookies(); 
 
const lightTheme = createMuiTheme({
	palette: {
		type:'light',
		background: {
			default: "#edf0f385"
		},
		primary:{
			light: blue[400],
			main: blue[700],
			dark: blue[900],
		},  
	},
	typography:{
		fontFamily: [
			'Montserrat', 
		].join(',')
	}
  });
  
  const darkTheme = createMuiTheme({ 
	palette: {
		type:'dark',
		background: {
			default: "#212121"
		},
		primary:{ 
			main: blue[500], 
		},
		text:{
			primary: '#fff', 
		}
	},
	typography:{
		fontFamily: [
			'Montserrat', 
		].join(',')
	}
  });
 

require('dotenv').config();

axios.defaults.baseURL = process.env.REACT_APP_API;     

export default class App extends Component{
	constructor(props){
        super(props); 
        this.state={
			_is_login: localStorage.getItem('is_login'), 
			_type: localStorage.getItem('type'), 
			_registration: false,

			unreadInquiery: [],
			darkmode: cookies.get('gtcTheme') === 'dark' ? true : false, 
		}
		
		this.sampleref = null;
		this.msamp = 0;
	}

	componentDidMount(){ 
		console.log('app status:', process.env.NODE_ENV);
		if(cookies.get('gtcTheme') === undefined){
			cookies.set('gtcTheme', 'light')
		}else{
			this.setState({ darkmode: cookies.get('gtcTheme')  === 'dark' ? true : false })
		}
	}

	updateUnreadInquiery = (data) =>{
		this.setState({
			unreadInquiery: data
		})
	} 

	updateGTCTheme = (data) =>{
			cookies.remove('gtcTheme');
			if(this.state.darkmode){  
				cookies.set('gtcTheme', data);
				this.setState({ darkmode: false })
			}else{  
				cookies.set('gtcTheme', data);
				this.setState({ darkmode: true })
			}
	} 

	handleTouchmove = () =>{   
		if(window.scrollY > 0 && parseInt(window.scrollY) === parseInt(Math.abs(document.body.getBoundingClientRect().top))){   
			var xx = this.msamp += 100
			this.sampleref.style.marginBottom = `${xx}px`;   
		}  
	}

	handleTouchRelease = () =>{      
		this.msamp = 0;
		this.sampleref.style.marginBottom = 0;   
	}

	render(){ 
		return(
			<>
				<Box  
					onTouchEnd={ this.handleTouchRelease } 
					onTouchMove={ this.handleTouchmove } 
					ref={ (el) => this.sampleref = el}
				>
					<ThemeContext.Provider
						value={{ 
							gtcThemeDark: this.state.darkmode, updategtcTheme: this.updateGTCTheme
						}}
					>
						<UnreadContext.Provider 
							value={{
								unreadInq: this.state.unreadInquiery , updateUnreadInq: this.updateUnreadInquiery
							}}
						>	     
							<ThemeProvider theme={this.state.darkmode ? darkTheme : lightTheme}>
								<CssBaseline />
								{ this.state._is_login ? LoginChecker(this.state._type) : <NavbarHome /> } 	
								{/* toastr container */}
								<ToastContainer
									position="bottom-center"
									autoClose={2000}
									hideProgressBar 
									closeOnClick
									rtl={false} 
									draggable
									pauseOnHover={false} 
									transition={Zoom}
								/> 
							</ThemeProvider> 
						</UnreadContext.Provider>
					</ThemeContext.Provider>
				</Box> 
				{/* enable syncronize */}
				{/* <SyncData /> */}
			</>
		)
	}
}
