import React, { useState, Fragment } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, TextField, FormHelperText, Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DropzoneArea } from 'material-ui-dropzone'

const PharmacyInfo = ({ info, infoReady, renderAllFetch }) => {
    const [token] = useState(localStorage.getItem('token'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [username] = useState(localStorage.getItem('username'))
    const [image, setImage] = useState([])

    const handleImageChange = (files) => {
        setImage(files)
    }

    const getFormData = (object) => {
        const formData = new FormData();
        formData.set('image', image.length > 0 ? image[0] : '')
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    return (
        <Fragment>
            {infoReady ?
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                    >
                        <Formik
                            initialValues={{
                                user_id: user_id,
                                token: token,
                                username: username,
                                management_id: management_id,
                                name: info.company_name,
                                contact: info.contact,
                                tin: info.tin_number,
                                email: info.email,
                                address: info.address,
                                password: '',
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required(),
                                contact: Yup.string().required(),
                                tin: Yup.string().required(),
                                email: Yup.string().required(),
                                address: Yup.string().required(),
                                password: Yup.string().required()
                            })}
                            onSubmit={async (values, {
                                setErrors,
                                setSubmitting,
                                resetForm
                            }) => {
                                try {
                                    const request = await Axios.post('malita/pharmacy/update-pharmacy-info', getFormData(values))
                                    if (request.data === 'pass-invalid') {
                                        setErrors({ password: "Password doesn't matched" });
                                        Notify.customToast("Invalid Password", "Password doesn't matched")
                                    }
                                    if (request.data === 'success') {
                                        Notify.successRequest('update pharmacy info')
                                        resetForm();
                                        setSubmitting(true);
                                        renderAllFetch();
                                    }
                                } catch (error) {
                                    const message = error.message || 'Something went wrong';
                                    setErrors({ submit: message });
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                                resetForm
                            }) => (
                                <form
                                    noValidate
                                    onSubmit={handleSubmit}
                                >
                                    <Box flexGrow={1}>
                                        <Grid container spacing={2}>
                                            <Grid xs={6} item>
                                                <Box mb={1}>
                                                    <TextField
                                                        error={Boolean(touched.name && errors.name)}
                                                        helperText={touched.name && errors.name}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.name}
                                                        fullWidth
                                                        required
                                                        multiline
                                                        name="name"
                                                        label="Pharmacy Name"
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid xs={6} item>
                                                <Box mb={1}>
                                                    <TextField
                                                        error={Boolean(touched.contact && errors.contact)}
                                                        helperText={touched.contact && errors.contact}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        defaultValue={values.contact}
                                                        fullWidth
                                                        required
                                                        multiline
                                                        name="contact"
                                                        label="Contact Number"
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid xs={12} item>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Box mb={1}>
                                                            <DropzoneArea
                                                                filesLimit={1}
                                                                onChange={handleImageChange}
                                                                acceptedFiles={['image/jpeg', 'image/png']}
                                                                dropzoneText="Pharmacy Logo (Optional)"
                                                                maxFileSize={300000}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Box mb={1}>
                                                                    <TextField
                                                                        error={Boolean(touched.tin && errors.tin)}
                                                                        helperText={touched.tin && errors.tin}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        defaultValue={values.tin}
                                                                        fullWidth
                                                                        required
                                                                        multiline
                                                                        name="tin"
                                                                        label="TIN Number"
                                                                        variant="outlined"
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box mb={1}>
                                                                    <TextField
                                                                        error={Boolean(touched.email && errors.email)}
                                                                        helperText={touched.email && errors.email}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        defaultValue={values.email}
                                                                        fullWidth
                                                                        required
                                                                        multiline
                                                                        name="email"
                                                                        label="Email"
                                                                        variant="outlined"
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box mb={1}>
                                                                    <TextField
                                                                        error={Boolean(touched.address && errors.address)}
                                                                        helperText={touched.address && errors.address}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        defaultValue={values.address}
                                                                        fullWidth
                                                                        required
                                                                        multiline
                                                                        name="address"
                                                                        label="Address"
                                                                        variant="outlined"
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box mb={1}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required
                                                                        error={Boolean(touched.password && errors.password)}
                                                                        helperText={touched.password && errors.password}
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        value={values.password}
                                                                        name="password"
                                                                        label="Enter your password"
                                                                        variant="outlined"
                                                                        type="password"
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {errors.submit && (
                                        <Box mt={2} >
                                            <FormHelperText error>
                                                {errors.submit}
                                            </FormHelperText>
                                        </Box>
                                    )}

                                    <Box mt={2} mb={2} display="flex">
                                        <Box flexGrow={1} />

                                        <Button
                                            variant="contained"
                                            color="default"
                                            onClick={() => resetForm()}
                                            startIcon={<HighlightOffIcon />}
                                        >
                                            Reset
                                        </Button>

                                        <Box ml={2}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<CheckCircleIcon />}
                                                disabled={isSubmitting}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
                : null}
        </Fragment >
    )
}

export default PharmacyInfo;