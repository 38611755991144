import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Grid, Box, Typography, Card, CardContent, Button, CardMedia } from '@material-ui/core'
import Icon from '@material-ui/icons/ArrowRightAlt'
import Axios from 'axios'
import Notify from '../../notification/Notify'  
import { useHistory } from 'react-router-dom'
import { grey } from '@material-ui/core/colors' 

const imageLocation = process.env.REACT_APP_API_IMAGE;

const ClinicList = () =>{
    const history = useHistory()
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [clinics, setClinics] = useState({
        data:[],
        ready: false 
    })

    const getClinicList = useCallback(() =>{
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)
        Axios
        .post('patient/appointment/search/gtc220920201053', formdata)
        .then((response) =>{
            const data = response.data
            setClinics({ data, ready: true })
        })
        .catch(error => { Notify.requestError(error) })
    },[token, user_id]);

    useEffect(() =>{
        
        getClinicList()

    }, [getClinicList])

    return(
        <Fragment> 
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={4}> 
                    <Card
                        elevation={0}
                        component={Box}
                        border={1}
                        borderColor={grey[300]}
                    >
                        <CardContent>
                            {
                                clinics.ready ?
                                    clinics.data.length > 0 ?
                                        clinics.data.map((data, index)=>(
                                            <Grid key={index} container spacing={2}>
                                                <Grid 
                                                    item 
                                                    xs={6} 
                                                    sm={3} 
                                                    md={4}  
                                                > 
                                                     <Box mt={2}>
                                                        <CardMedia 
                                                            component="img"
                                                            image={ data.clinic === null ? `${imageLocation}clinic/${data.clinic_image}` : "/icons/no-clinic.png"}
                                                            title={data.clinic} 
                                                        />
                                                    </Box> 
                                                </Grid>
                                                
                                                <Grid 
                                                    item  
                                                    xs={6} 
                                                    sm={9} 
                                                    md={8}  
                                                >
                                                    <Box>
                                                        <Box mb={1}>  
                                                            <Typography     
                                                                variant="subtitle1"
                                                                color="primary"
                                                                noWrap  
                                                            >
                                                                { data.clinic.toUpperCase() }
                                                            </Typography> 
                                                        </Box>
                                                        <Box mb={1}> 
                                                            <Typography 
                                                                variant="caption" 
                                                                color="textSecondary"
                                                            >
                                                                ADDRESS
                                                            </Typography>
                                                            <Typography  
                                                                variant="subtitle1" 
                                                                noWrap
                                                            >
                                                                { data.address }
                                                            </Typography>
                                                        </Box> 

                                                        <Box mb={1}> 
                                                            <Typography 
                                                                variant="caption"
                                                                color="textSecondary"
                                                            >
                                                                DAYS OPEN
                                                            </Typography>
                                                            <Typography  
                                                                variant="subtitle1" 
                                                                noWrap
                                                            >
                                                                { data.days_open }
                                                            </Typography>  
                                                        </Box>

                                                        <Box mb={1}> 
                                                            <Typography 
                                                                variant="caption"
                                                                color="textSecondary"
                                                            >
                                                                TIME OPEN
                                                            </Typography>
                                                            <Typography  
                                                                variant="subtitle1" 
                                                                noWrap
                                                            >
                                                                { data.time_open }
                                                            </Typography>  
                                                        </Box>  
                                                    </Box>

                                                    <Box display="flex" justifyContent="flex-end"> 
                                                        <Button   
                                                            variant="text"
                                                            color="primary"
                                                            endIcon={
                                                                <Icon />
                                                            }
                                                            onClick={ () => history.push('/app/patient/appointment/clinic/'+data.clinic_id)}
                                                        >
                                                            Details
                                                        </Button> 
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        ))
                                    : Notify.noRecord()
                                : Notify.loading()
                            } 
                        </CardContent> 
                    </Card>
                </Grid> 
            </Grid>
        </Fragment>
    )
}

export default ClinicList;