import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import Axios from 'axios';
import Notify from '../../../notification/Notify';
 
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; 
import TablePagination from '@material-ui/core/TablePagination'; 
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton'; 
import Collapse from '@material-ui/core/Collapse'; 
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'; 
import Print from '@material-ui/icons/Print'; 
import Rx from '../../../utils/Rx'; 

function PrescriptionList({ patient_id, prescType }) {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id')) 
    const [rowsPerPage, setRowsPerPage] = useState(5);  

    const [prescription, setPrescription] = useState({ data: [] , ready : false });  
    const [prescriptionDetails, setPrescriptionDetails] = useState({ data: [] , ready : false });  
    const [page, setPage] = useState(0);  
    const[selectedid, setselectedid] = useState(null)

    // printableRx
    const [printRx, setPrintRx] = useState(false)
    const [claimIdForRx, setclaimIdForRx] = useState(null)
    const [patientIdForRx, setpatientIdForRx] = useState(null)
    const [doctorsIdForRx, setdoctorsIdForRx] = useState(null)
    
    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };   

    const getPrescription = useCallback(() =>{ 
        var formdata = new FormData();
            formdata.set('token', token)
            formdata.set('user_id', user_id)
            formdata.set('patient_id', patient_id) 
            formdata.set('connection', prescType === 'virtual-prescription' ? 'online' : 'local')

            Axios.post('prescription/doctor/local/prescriptionlist', formdata)
            .then( (response) => { 
                const data = response.data;     
                setPrescription({ data, ready: true })
            }).catch(error=>{ 
                Notify.requestError(error);
            }); 
    },[token, user_id, patient_id, prescType]);

    useEffect(()=>{

        getPrescription()
 
    },[getPrescription])

    const handleSelectedPresc = (e) =>{ 
        var claim_id = e.currentTarget.id;
        var doctors_id = e.currentTarget.getAttribute('doctors_id');

        if(claim_id === selectedid){
            setselectedid(null)
        }else{
            var formdata = new FormData();
            formdata.set('token', token)
            formdata.set('user_id', doctors_id)
            formdata.set('patient_id', patient_id)
            formdata.set('claim_id', claim_id)
            formdata.set('connection', prescType === 'virtual-prescription' ? 'online' : 'local')


            Axios.post('prescription/doctor/local/prescriptiondetailslist', formdata)
            .then( (response) => { 
                const data = response.data;    
                setselectedid(claim_id) 
                setPrescriptionDetails({ data, ready: true })
            }).catch(error=>{ 
                Notify.requestError(error);
            }); 
        }
    }
    

    const handlePrintableRx = (e) =>{
        setclaimIdForRx(e.currentTarget.getAttribute('claimid'))
        setpatientIdForRx(e.currentTarget.getAttribute('patientid'))
        setdoctorsIdForRx(e.currentTarget.getAttribute('doctorsid'))
        setPrintRx(true)
    }

    return (
        <Fragment>
            <Box> 
                <Typography variant="subtitle2" color="primary"> PRESCRIPTION LIST </Typography>
                <TableContainer> 
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell> <b> Date </b> </TableCell>
                                <TableCell> <b> Claim ID </b> </TableCell> 
                                <TableCell> <b> Type </b> </TableCell>  
                                <TableCell> <b> Action </b> </TableCell>  
                            </TableRow> 
                        </TableHead>

                        <TableBody>
                            {
                                prescription.ready ? 
                                    prescription.data.length > 0 ? 
                                        (rowsPerPage > 0
                                            ? prescription.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : prescription.data
                                        ).map((data, index) => (
                                            <Fragment key={index}>
                                                <TableRow className= {selectedid === data.claim_id ? "gtc-bg-light" : ""} key={index}> 
                                                    <TableCell>
                                                        <IconButton 
                                                            color="primary" 
                                                            size="small"
                                                             doctors_id ={data.doctors_id} 
                                                             id={data.claim_id} 
                                                             onClick={ handleSelectedPresc } 
                                                            disabled={selectedid === data.claim_id}
                                                        >
                                                            { selectedid === data.claim_id ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }
                                                        </IconButton>
                                                    </TableCell> 
                                                    <TableCell>  { Notify.dateTimeConvert(data.created_at) } </TableCell>
                                                    <TableCell>{ data.claim_id.replace('claim-', '') }</TableCell> 
                                                    <TableCell> 
                                                        { data.prescription_type }
                                                    </TableCell> 
                                                    <TableCell> 
                                                        <IconButton
                                                            claimid ={data.claim_id}
                                                            doctorsid ={data.doctors_id}
                                                            patientid ={data.patients_id}
                                                            onClick={ handlePrintableRx }
                                                        >
                                                            <Print fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>  
                                                </TableRow>

                                                {/* collapse */}
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={selectedid === data.claim_id} timeout="auto" unmountOnExit>
                                                            <Box m={1}>  
                                                                <Typography variant="caption" color="primary">
                                                                    PRESCRIPTION DETAILS
                                                                </Typography> 
                                                                <Table size="small" >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell> <b> Date</b> </TableCell>
                                                                            <TableCell> <b> Prescription</b> </TableCell>
                                                                            <TableCell> <b> Type</b> </TableCell>
                                                                            <TableCell> <b> Dosage</b>  </TableCell> 
                                                                            <TableCell> <b> Sig</b>  </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody> 
                                                                        {
                                                                            prescriptionDetails.ready ? 
                                                                                prescriptionDetails.data.length > 0 ? 
                                                                                    prescriptionDetails.data.map((presc, vindex) =>{
                                                                                        return(
                                                                                            <TableRow key={vindex}>
                                                                                                <TableCell>
                                                                                                    {Notify.dateTimeConvert(presc.created_at)}
                                                                                                </TableCell>
                                                                                                <TableCell> { presc.product_name }</TableCell>
                                                                                                <TableCell> { presc.type } </TableCell>
                                                                                                <TableCell> { presc.dosage } </TableCell> 
                                                                                                <TableCell>
                                                                                                    { presc.remarks }
                                                                                                </TableCell>
                                                                                            </TableRow> 
                                                                                        )
                                                                                    })
                                                                                :   <TableRow>
                                                                                        <TableCell colSpan={5}> 
                                                                                            <Typography color="secondary" align="center">
                                                                                                No record found.
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                            :   <TableRow>
                                                                                    <TableCell colSpan={5}> 
                                                                                        <Typography color="primary" align="center">
                                                                                            please wait...
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                        }
                                                                    </TableBody>
                                                                </Table> 
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </Fragment>
                                        ))
                                    :
                                        <TableRow>
                                            <TableCell colSpan={6}> 
                                                <Typography color="secondary" align="center">
                                                    No prescription record. 
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                :
                                <TableRow>
                                    <TableCell colSpan={6}> 
                                        <Typography color="primary" align="center">
                                            please wait...
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            } 
                        </TableBody>
                    </Table> 

                    <TablePagination
                        labelRowsPerPage = "List"
                        rowsPerPageOptions={[5, 20, 50, 100]}
                        component="div"
                        count={prescription.data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    
                </TableContainer> 
            </Box>

            {/* printable rx */}
            { printRx ? 
                <Rx
                    open={ printRx }
                    close = { () => setPrintRx(false) }
                    claim_id = { claimIdForRx }
                    patient_id = { patientIdForRx }
                    doctors_id = { doctorsIdForRx }  
                />
            : null }
        </Fragment>
    )
}

export default PrescriptionList;
