import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Alert, Button, ButtonGroup, Modal, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faTimesCircle, faCircleNotch, faTrashAlt } from '@fortawesome/free-solid-svg-icons'; 

function AppointmentCommentsForApproval(props) {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [username] = useState(localStorage.getItem('username'))

    const [forappcomments, setforappcomments] = useState([])

    const [approveddialog, setapproveddialog] = useState(false)

    const [isappprocess, setisappprocess] = useState(false)

    const [selectedid, setselectedid] = useState(null)

    const [removedialog, setremovedialog] = useState(false)


    const getForApprovalComments = useCallback(() =>{
        
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);     
        formdata.set('connection', props.connection);     
        
        Axios.post('doctor/comments/forapproval-list', formdata )
        .then((response) => { 
            const data = response.data;   
            setforappcomments(data)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, user_id, props.connection]);

    const handleApprovedAction = (e) =>{
        setapproveddialog(true)
        setselectedid(e.currentTarget.id)
    }

    const handleRemoveAction = (e) =>{
        setremovedialog(true)
        setselectedid(e.currentTarget.id)
    }

    const handleApproveComment = (e) =>{
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);
            formdata.set('user_id', user_id);
            formdata.set('token', token);
            formdata.set('username', username); 
            formdata.set('connection', props.connection);  
        var error = [];

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }
        if(error.length > 0){
            console.warn("form has an error. observe.")
        }else{
            setisappprocess(true)
            Axios.post('doctor/comments/approvedcomment-save', formdata)
            .then((response)=>{ 
                const data = response.data
                if(data ==='pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data ==='success'){
                    getForApprovalComments();
                    setapproveddialog(false)
                    setselectedid(null)
                    Notify.successRequest('approved comment')
                }
                if(data ==='db-error'){
                    Notify.warnRequest('approved comment')
                }
            })
            .catch((error)=>{ 
                Notify.requestError(error)
            }).finally(()=>{
                setisappprocess(false)
            })
        }
    }

    const handleRemoveComment = (e) =>{
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);
            formdata.set('user_id', user_id);
            formdata.set('token', token);
            formdata.set('username', username); 
            formdata.set('connection', props.connection);  
        var error = [];

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0){
            console.warn("form has an error. observe.")
        }else{
            setisappprocess(true)
            Axios.post('doctor/comments/forapprovedcomment-delete', formdata)
            .then((response)=>{ 
                const data = response.data
                if(data ==='pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data ==='success'){
                    getForApprovalComments();
                    setremovedialog(false)
                    setselectedid(null)
                    Notify.successRequest('remove comment')
                }
                if(data ==='db-error'){
                    Notify.warnRequest('remove comment')
                }
            })
            .catch((error)=>{ 
                Notify.requestError(error)
            }).finally(()=>{
                setisappprocess(false)
            })
        }
    }

    useEffect(() => {
        getForApprovalComments()
 
    }, [getForApprovalComments]);
    return (
        <Fragment>
            {
                forappcomments.length > 0 ?
                    forappcomments.map((data, index) =>{
                        return (
                            <Alert key={index} variant="mb-2 bg-white border">
                                <p className="text-justify m-0"> <b className="text-capitalize"> {data.patient_name} </b> requesting for comment to approve on  <b> {Notify.dateTimeConvert(data.created_at)} </b>.
                                    <p className="m-0 text-muted ml-2"> { data.comment } </p>
                                    Would you like to have an appointment with this person? 
                                </p> 
                                <ButtonGroup>
                                    <Button
                                        variant="success"
                                        id = {data.id}
                                        appaction = "approved" 
                                        onClick={ handleApprovedAction }
                                    > <FontAwesomeIcon icon={faThumbsUp} /> Approve </Button>
                                    <Button
                                        id = {data.id}
                                        appaction = "disapproved" 
                                        variant="danger ml-2"
                                        onClick={ handleRemoveAction }
                                    > <FontAwesomeIcon icon={faThumbsDown} /> Disapprove </Button> 
                                </ButtonGroup>
                            </Alert>
                        )
                    })
                : null // no comment for approval
            }
            {/* approved appointment */}
            <Modal size="sm" centered backdrop="static" show={approveddialog} onHide={() => setapproveddialog(false)}> 
                <Modal.Header className="bg-success text-white p-3" closeButton>
                    Approved Comment
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={ handleApproveComment }>
                        <FormControl readOnly hidden name="comment_id" defaultValue={ selectedid } />

                        <span className="text-muted gtc-small text-uppercase"> Enter your password </span>
                        <FormControl type="password" name="password" /> 

                        <p className="text-muted m-0 mb-2"> Are you sure to approve this comment? </p>

                        <Button variant="success" type="submit" disabled={isappprocess ? true : false }>
                            <FontAwesomeIcon icon={ isappprocess ? faCircleNotch : faThumbsUp} spin={isappprocess ? true : false } /> Approved
                        </Button>

                        <Button variant="secondary ml-2" onClick = { () => setapproveddialog(false) }>
                            <FontAwesomeIcon icon={faTimesCircle} /> No
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>


            {/* disapproved appointment */}
            <Modal size="sm" centered backdrop="static" show={removedialog} onHide={() => setremovedialog(false)}> 
                <Modal.Header className="bg-danger text-white p-3" closeButton>
                    Remove Comment
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={ handleRemoveComment }>
                        <FormControl readOnly hidden name="comment_id" defaultValue={ selectedid } />

                        <span className="text-muted gtc-small text-uppercase"> Enter your password </span>
                        <FormControl type="password" name="password" /> 

                        <p className="text-muted m-0 mb-2"> Are you sure to remove this comment? </p>

                        <Button variant="danger" type="submit" disabled={isappprocess ? true : false }>
                            <FontAwesomeIcon icon={ isappprocess ? faCircleNotch : faTrashAlt} spin={isappprocess ? true : false } /> Approved
                        </Button>

                        <Button variant="secondary ml-2" onClick = { () => setremovedialog(false) }>
                            <FontAwesomeIcon icon={faTimesCircle} /> No
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>
 
        </Fragment>
    )
}

export default AppointmentCommentsForApproval;
