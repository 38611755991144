import React, { Component, Fragment } from 'react';  
import { Alert, Col, Row, FormControl, Button, Spinner, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import Notify from '../../notification/Notify';
import axios from 'axios';
import Peer from 'simple-peer';
import MediaHandler from '../../MediaHandler';
import { toast } from 'react-toastify';
import OnlinechecupChat from '../../oncheckupchat/OnlinechecupChat';
import { Link } from 'react-router-dom';
      
export default class EnterRoom extends Component{
    _mounted = false;
    _peer = null;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'), 
 
            _mywebrct_id: null, 
 
            _enable_enter_room: true,
            _enter_room_processed: false,
            _show_vcontainer: true,
            _is_enter: false,

            _is_peer_null: false,
            _peer_status: null,
            _no_room_found: false,

            _current_room_number: null,

            _doctors_id: this.props.match.params.doctors_id,
            _room_number: this.props.match.params.room_number,

            _room_info: [],
            _room_info_ready: false,
        }   

        this.webidDoctor = null;
        this.checkConnectionInterval = null;

        // react ref
        this.mediaHandler = new MediaHandler(); 
        this.mywebrtcid = React.createRef();
        this.clientwebrtcid = React.createRef();
        this.myvideocontainer = React.createRef(null);
        this.clientvideocontainer = React.createRef(null);
        this.enterRoomBtn = React.createRef();
        this.windowHeight = window.innerHeigh;

    } 


    getRoomDetails(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);   
        formdata.set('room_number', this.state._room_number);   
        formdata.set('doctors_id', this.state._doctors_id);   
        
        axios.post('patient/online/checkup/room-details', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                if(data.length > 0){
                    this.initializeWebRtc();
                }
                this.setState({
                    _room_info: data,
                    _room_info_ready: true,
                })
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }
    
    componentDidMount(){
        this._mounted = true;  
        this.getRoomDetails();
    } 

    initializeWebRtc(){
        this.getDoctorsWebrtcId();
        this.mediaHandler.getPermissions()
        .then((stream) => {  
            this._peer = new Peer({ 
                initiator: false,
                trickle: false,  
                stream: stream,   
            }); 

            this._peer.on('signal', (data) => { 
                this.mywebrtcid.value = JSON.stringify(data)
            }) 

            this._peer.on('stream',(stream) => { 

                if ('srcObject' in this.clientvideocontainer) {
                    this.clientvideocontainer.srcObject = stream
                } else {
                    this.clientvideocontainer.src = window.URL.createObjectURL(stream) // for older browsers
                } 

                this.clientvideocontainer.play();    
            }) 

            if(this.myvideocontainer){
                if ('srcObject' in this.myvideocontainer) {
                    this.myvideocontainer.srcObject = stream
                } else {
                    this.myvideocontainer.src = window.URL.createObjectURL(stream) // for older browsers
                } 
                this.myvideocontainer.play();     
            }else{
                Notify.customToast('Video Error', 'Unable to load video container.')
            }
            
        })

        setTimeout(() => {
            this.checkConnectionRTC();
        }, 2000);
    }

    checkConnectionRTC(){
        this.checkConnectionInterval = setInterval(()=>{ 
            if(this._peer !== null ){ 
                if(this._peer._pc !== null ){
                    this.setState({
                        _peer_status: this._peer._pc.connectionState
                    })
                    if(this._peer._pc.connectionState === 'disconnected'){
                        this._peer = null;
                        toast.error('Call ended, Connection has been terminated.');
                    }  
                }else{
                    this._peer = null; 
                    this.setState({
                        _peer_status: 'doctor-disconnected',
                    })
                    toast.error('Unable to enter in the room.'); 
                } 
            }
        }, 1000) 
    }

    componentWillUnmount(){
        if(this._peer !== null){
            this._peer._pc.close();  
            this._peer = null;
        } 
        this._mounted = false; 
        clearInterval(this.webidDoctor) 
        clearInterval(this.checkConnectionInterval)  
    }

    handleConnect(){ 
        try{
            this._peer.signal(JSON.parse(this.clientwebrtcid.value));  
            setTimeout( () => {
                this.enterRoomed();     
                this.setState({
                    _is_enter: true
                })
            }, 2000)
        }catch{
            toast.error('Unable to connect your client, try to reload the page.','Error');
        }
    }

    getDoctorsWebrtcId(){ 
        // this.state._patient_webid_ready ? 
        this.webidDoctor = setInterval(() => {
            var formdata = new FormData();  
                formdata.set('token', this.state._token); 
                formdata.set('management_id', this.state._management_id);   
                formdata.set('user_id', this.state._user_id);   
                formdata.set('username', this.state._username);    
                formdata.set('doctors_id', this.state._doctors_id);   
                formdata.set('room_number', this.state._room_number);   
                
                axios.post('patient/online/checkup/doctors-webrtc-id', formdata )
                .then(function(response){ 
                    const data = response.data;   
                    if(this._mounted){ 
                        if(data.length > 0){ 
                            this.clientwebrtcid.value = data[0].doctors_webrtc_id;
                            // this.handleConnect();
                            this.setState({
                                _current_room_number: data[0].room_number,
                                _enable_enter_room: false,
                                _no_room_found: false
                            }) 
                            return clearInterval(this.webidDoctor);
                        }else{
                            this.setState({
                                _no_room_found: true,
                            })
                            toast.error('Unable ro get room details, try to reload the page or chat your doctor to create room.')
                        }
                    } 
                }.bind(this)).catch(error=>{ 
                    Notify.requestError(error);
                });
        }, 1000);  
    }

    enterRoomed(){ 
        var formdata = new FormData();  
            formdata.set('token', this.state._token); 
            formdata.set('management_id', this.state._management_id);   
            formdata.set('user_id', this.state._user_id);   
            formdata.set('username', this.state._username);      
            formdata.set('doctors_id', this.state._doctors_id);   
            formdata.set('_your_web_rtc_id', this.mywebrtcid.value);   
        
        axios.post('patient/online/checkup/entered-room', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){  
                if(data === 'success'){ 
                    this.setState({ 
                        _enter_room_processed: true,
                        _show_vcontainer: false
                    })
                    toast.info('Please wait for the doctor to allow you to enter the room.')
                    Notify.successRequest('enter room');   
                }
                if(data === 'db-error'){
                    Notify.warnRequest('enter room')
                }
            } 
        }.bind(this)).catch(error=>{  
            Notify.requestError(error);
        });
    }   

	render(){  
		return(
			<Fragment>
                {
                    this.state._room_info_ready ? 
                        this.state._room_info.length > 0 ?
                            <Fragment> 
                                <Alert variant="danger text-center p-1 rounded-0 pb-0 mb-0">
                                    <strong> Please do not reload the page while preparing and entering the room.</strong><br/>
                                    <small> If page is reload, the room number will be change and your patient cannot access or join in the room. </small>
                                </Alert>   
                
                                {
                                    this.state._peer_status === 'connecting' ? 
                                        <Alert className="bg-cyan text-center p-1 rounded-0 pb-0 mb-0 small text-white"> 
                                            <b className="text-uppercase">Room Number :  { this.state._current_room_number } </b> <br />
                                            Entering the room, Please wait while doctor is allowing you to enter.
                                        </Alert>
                                    :
                                    this.state._peer_status === 'connected' ? 
                                        <Alert className="bg-green text-center p-1 rounded-0 pb-0 mb-0 small text-white"> 
                                            <b className="text-uppercase">Room Number :  { this.state._current_room_number } </b> <br />
                                            Room Entered, You are now connected to each other.
                                        </Alert>
                                    :
                                    this.state._peer_status === 'disconnected' ? 
                                        <Alert className="bg-red text-center p-1 rounded-0 pb-0 mb-0 small text-white"> 
                                            Room disconnected, Your connection of your room is lost, It either doctor is ending the call or doctor <Button size="sm" onClick={()=>window.location.reload()}>reloading</Button> his/her page. Try to contact your doctor via chat or direct.
                                        </Alert>
                                    :  
                                    this.state._peer_status === 'doctor-disconnected' ? 
                                        <Alert className="bg-red text-center p-1 rounded-0 pb-0 mb-0 small text-white"> 
                                            Room not found, Doctors room not found, try to contact in chat and match the room number.
                                        </Alert>
                                    : 
                                    null
                                }
                                {
                                    this.state._no_room_found ? 
                                    <Alert variant="warning text-center p-1 rounded-0 pb-0 mb-0 small"> Unable to get room details, Please contact your doctor or reload the page. </Alert>
                                    :null
                                }
                                
                                <Row className="m-0 p-0"
                                    style={{ 
                                        background: '#000', 
                                        height: this.windowHeight,
                                    }}
                                >
                                    <Col sm={9}  
                                        className="m-0 p-0" 
                                        style={{
                                            borderWidth: '.7rem',   
                                            borderStyle: 'double',
                                            borderColor: ' #007bff',
                                        }}
                                    >
                                        {/* my id */}  
                                        <FormControl hidden as="textarea" defaultValue={this.state._mywebrct_id}  ref={ el => this.mywebrtcid = el} /> 
                                        
                                        {/* client id */}  
                                        <FormControl hidden as="textarea" ref={ el => this.clientwebrtcid = el} /> 
                                        
                                        <div hidden={this.state._enter_room_processed ? true : false } className="text-center" style={{
                                            top: '40%',
                                            left: '25%',
                                            position: 'absolute', 
                                            zIndex: '1',
                                        }}>
                                            {
                                                this.state._enable_enter_room ?
                                                    <div className="text-white">
                                                        <Spinner animation="border" />
                                                        <p>
                                                            Please wait while room details is processing...
                                                        </p>
                                                    </div>
                                                :
                                                <Fragment>
                                                    <p className="text-primary mb-2 m-0">
                                                        Room is ready please click the button to enter.
                                                    </p>
                                                    <Button 
                                                        disabled = {this.state._enable_enter_room ? true : false}
                                                        hidden = {this.state._enter_room_processed ? true : false}
                                                        ref={ el => this.enterRoomBtn = el}
                                                        type="button"
                                                        onClick={this.handleConnect.bind(this)}
                                                    >
                                                        <FontAwesomeIcon icon={ this.state._is_enter ? faSpinner : faDoorOpen } spin={ this.state._is_enter ? true : false } /> Enter Room { this.state._current_room_number}
                                                    </Button>
                                                </Fragment>
                                            } 
                                        </div> 
                                        <div className="m-0 p-0">
                                            {/* my video */}
                                            <video   
                                                playsInline
                                                style={{    
                                                    position: 'absolute',
                                                    maxHeight: '10vw',
                                                    top: '10px',
                                                    left:'10px', 
                                                    border: '3px solid #007bff',
                                                }} 
                                                ref={ el => this.myvideocontainer = el} 
                                                muted
                                                className={'img-fluid '}
                                            /> 
                
                                            {/* client video */}
                                            <video 
                                                playsInline
                                                style={{      
                                                    maxHeight: '80vh',  
                                                    bottom: 0,  
                                                    backgroundColor: '#000',
                                                    objectFit: 'cover',
                                                }} className = "img-fluid d-flex w-100 mx-auto mt-0 mt-sm-5" ref={ el => this.clientvideocontainer = el} 
                                            /> 
                                        </div>
                                    </Col>
                                    <Col sm={3} className="m-0 p-0">
                                        <OnlinechecupChat client_id = {this.state._doctors_id}/> 
                                    </Col> 
                                </Row> 
                            </Fragment>
                        : <Modal centered size="sm" show={true} backdrop="static" keyboard={false}> 
                            <Modal.Header className="bg-danger text-white"> Room not existed </Modal.Header>
                            <Modal.Body>
                                Room not existed in the server, It is either the doctor is leaving or the doctor is changing the room number.
                                Try to reloadingi the main page to check for the new room number.
                                <p className="text-center mt-3">
                                    <Link to="/app/patient" > Back to dashboard </Link>
                                </p>
                            </Modal.Body>
                        </Modal>
                    : <div className="mt-5">
                        { Notify.loading() }
                    </div>

                }
            </Fragment>
		)
	}
}
