 
 import React  from 'react'
import { Box, TextField, Button, FormHelperText,  Card, CircularProgress, CardContent} from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios'; 
import CheckIcon from '@material-ui/icons/CheckCircleOutline' 
import ClearIcon from '@material-ui/icons/HighlightOff';  
import { Formik } from 'formik';
import * as Yup from 'yup';  


const ReviewPatientFindings = ({ imagingOrderId, getPatientsReview, resetDisplay, close }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')  

    const  formdata = (object) => {
        const formData = new FormData(); 
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }  

    return(
        <>
            <Formik 
                initialValues={{ 
                    user_id: user_id, 
                    token: token,   
                    findings: 'Normal',
                    impressions: 'Normal',
                    tuberculosis: '',
                    imaging_id: imagingOrderId,
                }}
                validationSchema={Yup.object().shape({ 
                    findings: Yup.string().required()
                })}
                onSubmit={async (values, {
                    setErrors, 
                    setSubmitting,
                    resetForm
                }) => {  
                    try { 
                        const request = await Axios.post('telerad/order/findings/save-findings', formdata(values))  
                        if(request.data === 'success'){
                            Notify.successRequest('finding added.') 
                            resetForm(); 
                            getPatientsReview()
                            resetDisplay()
                        }
                    } catch (error) {
                        const message = error.message || 'Something went wrong';   
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    resetForm
                }) => (
                    <form
                        noValidate 
                        onSubmit={handleSubmit}  
                    >    
                        <Card elevation={0}> 
                            <CardContent>
                                <Box mb={2}>
                                    <TextField 
                                        error={Boolean(touched.findings && errors.findings)}
                                        helperText={touched.findings && errors.findings}  
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        value={values.findings}
                                        multiline
                                        fullWidth
                                        required
                                        name="findings"
                                        label="Findings"
                                        variant="outlined"  
                                        rows={3}
                                    />
                                </Box>  

                                <Box mb={2}>
                                    <TextField 
                                        error={Boolean(touched.impressions && errors.impressions)}
                                        helperText={touched.impressions && errors.impressions}  
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        value={values.impressions}
                                        multiline
                                        fullWidth 
                                        name="impressions"
                                        label="Impressions"
                                        variant="outlined"  
                                        rows={3}
                                    />
                                </Box>   

                                <Box mb={2}>
                                    <TextField
                                        select
                                        SelectProps={{ native: true }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        required
                                        fullWidth
                                        error={Boolean(touched.tuberculosis && errors.tuberculosis)}
                                        helperText={touched.tuberculosis && errors.tuberculosis}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.tuberculosis}
                                        name="tuberculosis"
                                        label="Tuberculosis"
                                        variant="outlined"
                                    >
                                        <option value={''}> Select </option>
                                        <option value="negative"> Negative </option>
                                        <option value="positive"> Positive </option>
                                    </TextField>
                                </Box>

                                {errors.submit && (
                                    <Box mt={3}>
                                        <FormHelperText error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}  

                                <Box display="flex" mt={2}>
                                    <Box flexGrow={1} />
                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            onClick={ () => {
                                                resetForm()
                                                close()
                                            }}
                                            startIcon={ <ClearIcon /> }
                                            component={Box}
                                            mr={2}
                                        >
                                            Clear
                                        </Button> 

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary" 
                                            disabled={isSubmitting}  
                                            startIcon={ isSubmitting ? <CircularProgress size={20} color="inherit" /> : <CheckIcon /> }
                                        >
                                            Save
                                        </Button>   
                                    </Box>
                                </Box> 
                            </CardContent>  
                        </Card>
                    </form>
                )}
            </Formik> 
        </>
    )
}

export default ReviewPatientFindings;