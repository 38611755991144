import React, { Fragment, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'; 
import Box from '@material-ui/core/Box'; 
import Notify from '../../notification/Notify'; 
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios';
import { InputAdornment } from '@material-ui/core';

function RequestCredit() {
    
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))

    const [isprocess, setisprocess] = useState(false)

    const [creditrequest,setcreditrequest] = useState([])
    const getRequest = () =>{

        var formdata = new FormData()  
        formdata.set('token', token);  
        formdata.set('user_id', user_id);     
        

        Axios.post('patient/credits/credits-requestlist', formdata )
        .then((response) => { 
            const data = response.data;   
            setcreditrequest(data)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const handleRequestAccount = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)  
        formdata.set('token', token);  
        formdata.set('user_id', user_id);     
        
        var error = [];
        if(formdata.get('credit').length === 0 || formdata.get('credit').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('credit'); 
        }else{
            if(parseFloat(formdata.get('credit')) < 100){
                error = 'error';
                Notify.fieldInvalid('Credit must be 100 and up');  
            }
        }
        if(error.length > 0){
            console.warn("form has an error, request failed")
        }else{
            setisprocess(true)
            Axios.post('patient/credits/credits-requestsave', formdata )
            .then((response) => { 
                const data = response.data;   
                if(data === 'success'){
                    getRequest()
                    Notify.successRequest('request')
                }
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(() =>{
                setisprocess(false)
            })
        }
    }

    const handleCreditPayment = (e) =>{
        window.open(e.currentTarget.id, '_blank');
    }

    useEffect(()=>{
        getRequest()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <Typography align="center" color="textSecondary"> 
                You can only sent request once and wait until your request is approved so you can request again.
                <b> Request Token has a minimum of 100. </b>
            </Typography>

            {
                creditrequest.length > 0 ?
                    <Box mt={4}>
                        <Typography align="center"> 
                            <b> Your request is being process please wait for the payment link to display. </b> 
                        </Typography>
                        <p className="mt-3 text-center">
                            { creditrequest[0].payment_link === null ? "YOUR LINK WILL APPEND HERE" : 
                                <Button id={creditrequest[0].payment_link} color="primary" variant="contained" onClick = { handleCreditPayment }>
                                    Pay Request Credit
                                </Button>
                            }
                        </p>
                    </Box>
                :
                    <form onSubmit={ handleRequestAccount }>
                        <Box mt={2}> 
                            <TextField  
                                type="number" 
                                fullWidth  
                                variant="outlined" 
                                name="credit" 
                                label="Credit Amount" 
                                autoComplete="off" 
                                defaultValue={100} 
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                           <Button  
                                            variant="contained" 
                                            color="primary" 
                                            type="submit" 
                                            disabled={ isprocess ? true : false } 
                                            startIcon={ isprocess ? <CircularProgress color="inherit" size={15} /> : <AddCircleOutline />}
                                        > Request </Button>
                                      </InputAdornment>
                                    ),
                                }}
                            /> 
                        </Box>
                    </form> 
            }
            
        </Fragment>
    )
}

export default RequestCredit;
