
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import ReactQuill from 'react-quill';
import { Box, Typography, CircularProgress, Button, TextField } from '@material-ui/core'; 
import AddCircle from '@material-ui/icons/AddCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Notify from '../../notification/Notify';
import Axios from 'axios';

const HealthAllergies = (props) => {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [username] = useState(localStorage.getItem('username'))

    const [allergies, setAllergies] = useState('') 
    
    const [isProcess, setIsProcess] = useState(false)
    const [editEnable, seteditEnable] = useState(false)

    const getAllergies = useCallback(() =>{ 
        var formdata = new FormData()
            formdata.set('user_id', user_id)
            formdata.set('token', token)
            formdata.set('patient_id', props.patient_id)
            formdata.set('connection', props.connection)

        Axios.post('doctors/patient/information/getpatient-information', formdata)
        .then((response) =>{
            const data = response.data
            if(data.length >  0){
                setAllergies(data[0].allergies)
            } 
        }).catch((error)=>{
            Notify.requestError(error)
        })
    },[user_id, token, props.patient_id, props.connection])

    const handleAllergies = (e) =>{
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
            formdata.set('user_id', user_id)
            formdata.set('token', token)
            formdata.set('username', username)
            formdata.set('allergies', allergies.replace('<p><br></p>', ''))
            formdata.set('patient_id', props.patient_id)
            formdata.set('connection', props.connection)
            
        var error = []; 

        if(formdata.get('allergies').length === 0 || formdata.get('allergies').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('allergies'); 
        }
        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }
        if(error.length > 0 ){
            console.warn("Form has an error.")
        }else{
            setIsProcess(true)
            Axios.post('doctor/patient/patient-new-allergies', formdata)
            .then((response) =>{
                const data = response.data
                if(data === 'success'){
                    e.target.reset()
                    seteditEnable(false)
                    Notify.successRequest('new allergies')
                }
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
            }).catch((error)=>{
                Notify.requestError(error)
            }).finally(() =>{
                setIsProcess(false) 
            })
        }   
    }

    useEffect(()=>{
        getAllergies()

    },[getAllergies])

    return (
        <Fragment> 
            <form onSubmit={ handleAllergies }> 
                <Box 
                    bgcolor="white" 
                    p={2} 
                    border={1} 
                    borderColor={"grey.200"}
                > 
                    <Box p={1} display="flex">
                        <Box flexGrow={1}>
                            <Typography variant="h6" color="textSecondary"> Allergies </Typography>   
                        </Box>
                        <Box>
                            <Button
                                startIcon={ <AddCircle /> } 
                                color="primary" 
                                onClick={ () => seteditEnable(true) } 
                                disabled={ isProcess }
                                hidden={ editEnable }
                            >
                                Update
                            </Button>
                        </Box>
                    </Box>
                    <Box my={1}>  
                        <ReactQuill
                            name="sample"
                            theme="snow"  
                            value={ allergies }
                            onChange ={(e) => setAllergies(e)}
                            readOnly={ ! editEnable }
                            className={editEnable ? '' : 'bg-light'}
                        />   
                    </Box> 

                    <Box  hidden={ ! editEnable }>  
                        <Box mb={2}>
                            <TextField 
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                label="Password"
                                name="password"
                                type="password"
                            /> 
                        </Box>

                        <Box display="flex">
                            <Button
                                type="submit"
                                variant="contained"
                                startIcon={ isProcess ? <CircularProgress color="primary" size={15} />: <CheckCircleIcon  /> } 
                                color="primary"  
                                disabled={ isProcess }
                            >
                                Save
                            </Button>
                            <Box ml={2}> 
                                <Button
                                    variant="contained"
                                    startIcon={ <CancelIcon /> } 
                                    color="inherit"   
                                    onClick={ () => seteditEnable(false) }
                                >
                                    No
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </form>
        </Fragment>
    )
}

export default HealthAllergies;
