import React, { useCallback, useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography, Button, Zoom } from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'
import Print from '@material-ui/icons/Print'
import Axios from 'axios'
import Notify from '../../notification/Notify'

const imageLocation = process.env.REACT_APP_API_IMAGE;
const ImagingPrint = ({ orderPrint, close }) =>{

    const token = localStorage.getItem('token')
    // open: false,
    // doctors_id: null,
    // patient_id: null,
    // lab_id: null, 

    const [doctorsInfo, setDoctorsInfo] = useState({
        data: [],
        ready: false
    }) 

    const [patientInfo, setPatientInfo] = useState({
        data: [],
        ready: false
    }) 

    const getDoctorsInfo = useCallback(() => {
        var formdata  = new FormData();
            formdata.set('doctors_id', orderPrint.doctors_id)
            formdata.set('token', token)

        Axios.post('patients/rx/printable/doctorsdetails', formdata)
        .then((response) =>{
            const data = response.data
            setDoctorsInfo({ data, ready : true })
        }).catch((error) =>{
            Notify.requestError(error)
        })
    } , [token, orderPrint.doctors_id] );

    const getPatientInfo = useCallback(() => {
        var formdata  = new FormData();
            formdata.set('token', token) 
            formdata.set('patient_id', orderPrint.patient_id) 

        Axios.post('doctor/patient/patient-information', formdata)
        .then((response) =>{
            const data = response.data 
            setPatientInfo({ data, ready : true }) 
        }).catch((error) =>{
            Notify.requestError(error)
        })
    }, [token, orderPrint.patient_id] );

    useEffect(() =>{
        getPatientInfo()
        getDoctorsInfo()
    }, [getPatientInfo, getDoctorsInfo])

    return(
        <Dialog
            TransitionComponent={ Zoom }
            transitionDuration={600}
            open={ orderPrint.open }
            fullScreen
        >
            <DialogTitle disableTypography>
                <Typography variant="h5" align="center">
                    Imaging Requisition Form
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Box p={2} border={1} borderColor="grey.400" mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                {
                                    patientInfo.ready?
                                        patientInfo.data.length > 0 ?
                                            <>
                                                <Box mb={1}> <b> Patient Information </b> </Box>  
                                                <Box mb={1}>
                                                    <Typography>
                                                    <Typography variant="caption"  color="textSecondary"> NAME: 
                                                    </Typography>
                                                        <span className="text-capitalize"> { patientInfo.data[0].firstname } { patientInfo.data[0].middle } { patientInfo.data[0].lastname }  </span>
                                                    </Typography>
                                                </Box>

                                                <Box mb={1}>
                                                    <Typography>
                                                    <Typography variant="caption"  color="textSecondary"> ADDRESS: 
                                                    </Typography>
                                                        <span className="text-capitalize"> { patientInfo.data[0].street  }, { patientInfo.data[0].barangay  },  { patientInfo.data[0].city  }</span>
                                                    </Typography>
                                                </Box>

                                                <Box mb={1}>
                                                    <Typography>
                                                    <Typography variant="caption"  color="textSecondary"> DATE OF BIRTH: 
                                                    </Typography>
                                                        { patientInfo.data[0].birthday !== null ? Notify.createdAt( patientInfo.data[0].birthday) : null }
                                                    </Typography>
                                                </Box>

                                                <Box mb={1}>
                                                    <Typography>
                                                    <Typography variant="caption"  color="textSecondary"> CONTACTS:
                                                    </Typography>
                                                        { patientInfo.data[0].mobile !== null ? patientInfo.data[0].mobile : null } | { patientInfo.data[0].telephone !== null ? patientInfo.data[0].telephone : null }
                                                    </Typography>
                                                </Box> 
                                            </>
                                        : Notify.noRecord()
                                    : Notify.loading()
                                }
                            </Grid>

                            <Grid item xs={6}>
                                {
                                    doctorsInfo.ready ?
                                        doctorsInfo.data.length > 0 ?
                                            <>
                                                <Box mb={1}> <b> Doctor Information </b> </Box> 

                                                <Box mb={1}>
                                                    <Typography>
                                                    <Typography variant="caption"  color="textSecondary"> NAME: 
                                                    </Typography>
                                                        <span className="text-capitalize"> { doctorsInfo.data[0].name } </span>
                                                    </Typography>
                                                </Box>

                                                <Box mb={1}>
                                                    <Typography>
                                                    <Typography variant="caption"  color="textSecondary"> ADDRESS: 
                                                    </Typography>
                                                        <span className="text-capitalize"> { doctorsInfo.data[0].address } </span>
                                                    </Typography>
                                                </Box>

                                                <Box mb={1}>
                                                    <Typography>
                                                    <Typography variant="caption"  color="textSecondary"> DATE OF BIRTH: 
                                                    </Typography>
                                                        { doctorsInfo.data[0].birthday !== null ? Notify.createdAt(doctorsInfo.data[0].birthday) : null }
                                                    </Typography>
                                                </Box>

                                                <Box mb={1}>
                                                    <Typography>
                                                    <Typography variant="caption"  color="textSecondary"> LINC. NUMBER: 
                                                    </Typography>
                                                        { doctorsInfo.data[0].cil_umn === null ? 'Not Available' : doctorsInfo.data[0].cil_umn }
                                                    </Typography>
                                                </Box>
                                            </>
                                        : Notify.noRecord()
                                    : Notify.loading()
                                }
                            </Grid>
                        </Grid>
                    </Box>

                    <Box p={2}  borderColor="grey.400">
                        
                        <Box mb={1}> <b> Imaging Infomartion </b> </Box> 

                        <Box mb={1}>
                            <Typography>
                                &bull; { orderPrint.order } 
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                               order created on { orderPrint.order_date !== null ? Notify.dateTimeConvert(orderPrint.order_date) : null }
                            </Typography>
                        </Box>   
                    </Box>

                    <Box display="flex" mt={5}>
                        <Box flexGrow={1} >  
                            <Box maxWidth={250} align="center">
                                <Box >
                                    <Typography variant="body1" className="text-uppercase">  { patientInfo.data.length > 0 ? patientInfo.data[0].firstname+ ' ' + patientInfo.data[0].lastname : null }  </Typography>
                                </Box>
                                <Box borderTop={1} borderColor="grey.400">
                                    <Typography variant="caption" color="textSecondary"> 
                                        PATIENT PRINTED NAME OVER SIG.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box >
                            <Box align="left"> 
                                {/* <img src="/sample-sign.png" alt="" width="120" style={{position: 'relative', top: 140}}/> */}
                                {
                                    doctorsInfo.data.length > 0 && doctorsInfo.data[0].image_signature !== null ?  
                                        <img 
                                            src={imageLocation+"doctors/doctors_signatures/"+doctorsInfo.data[0].image_signature } 
                                            alt="" 
                                            width="120" 
                                            style={{position: 'relative', top: 30}}
                                        />
                                    : null 
                                }
                            </Box> 
                            <Box borderBottom={1} borderColor="grey.400">
                                <Typography variant="body1" className="text-uppercase text-center">  { doctorsInfo.data.length > 0 ? doctorsInfo.data[0].name : null }  </Typography>
                            </Box>
                            <Typography variant="caption" color="textSecondary"> 
                                DOCTORS PRINTED NAME OVER SIG.
                            </Typography>
                        </Box>
                    </Box>

                    <Box display="flex" mt={5} className={'d-print-none'}>
                        <Box flexGrow={1} />  
 
                        <Button
                            variant="contained"
                            color="inherit"
                            onClick = { close }
                            startIcon={
                                <Cancel />
                            }
                        >
                            Cancel
                        </Button>

                        <Box ml={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={ () => window.print() }
                                startIcon={
                                    <Print />
                                }
                            >
                                Print
                            </Button>
                        </Box> 
                    </Box>
                </Box> 
            </DialogContent>
        </Dialog>
    )
}

export default ImagingPrint;