import React, { Fragment, useEffect, useState, useContext } from 'react';  
import { Col, Row } from 'react-bootstrap';
import Notify from '../notification/Notify';
import Axios from 'axios';
import AppointmentRequest from './appointment/AppointmentRequest'; 
import AppointmentApprovedDetails from './appointment/AppointmentApprovedDetails';
import AppointmentCommentsForApproval from './appointment/AppointmentCommentsForApproval';
import Box from '@material-ui/core/Box';  
import { Alert, AlertTitle } from '@material-ui/lab';
import BellIcon from '@material-ui/icons/NotificationsNone';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'; 
import { Badge, Divider, Drawer, IconButton, Paper } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Notifications from './notifications/Notifications';
import { blue } from '@material-ui/core/colors'; 
import CheckInternet from 'src/utils/CheckInternet';
import NoInternet from 'src/NoInternet';
import CheckingConnection from 'src/CheckInternet';
import { PatientsUnRead } from 'src/ContextAPI';

const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL;  

const VirtualAppointment = (props) => {
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token'); 

    const [_approve_app, set_approve_app] = useState([]) 
    const [_approve_app_ready, set_approve_app_ready] = useState(false)  
    const [_selected_app, set_selected_app] = useState() 
    const [_selected_appid, set_selected_appid] = useState() 
    const [_selected_patientid, set_selected_patientid] = useState()  
    const [_notificationdrawer, set_notificationdrawer] = useState(false) 
    const [_online, set_online] = useState('checking')

    var interval = null;  

    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( category, patient_id ) =>{ 

        let yy = unread.unviewNotifVirtual; 
        let count = 0;  

        for (let i = 0; i < yy.length; i++) {
            if(category === yy[i].category && patient_id === yy[i].patient_id){
                count += 1;
            }
        }  
         
        return count;
    }


    const getApproveAppointment = () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('connection', props.connection);    
        
        Axios.post('appointment/doctors/approve-appointment', formdata )
        .then( (response) => { 
            const data = response.data;   
            set_approve_app(data)
            set_approve_app_ready(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const deleteOnlineVcallRoomAll = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('connection', props.connection);     
        
        Axios.post('appointment/doctors/appointment/vcall/deleteall-room', formdata )
        .then( (response) => { 
            const data = response.data;   
            if(data==='success'){ console.log('all appointment room deleted.') }
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    } 

    const handleSelectedApp = (e) =>{ 
        set_selected_app(true)
        set_selected_appid(e.currentTarget.getAttribute('appid'))
        set_selected_patientid(e.currentTarget.getAttribute('patient_id'))
    }

    const resetDisplay = () =>{
        set_selected_app(false)
        set_selected_appid(null) 
    }

    const checkinternet = () => {
        CheckInternet.online().then(() => set_online('connected') ).catch(() => set_online('disconnected') )
    }

    const initializeInterval = () =>{
        interval = setInterval(() => {
            getApproveAppointment();
        }, 5000); 
    }

    useEffect(() =>{ 
        checkinternet()
        if(_online === 'connected'){
            getApproveAppointment();
            deleteOnlineVcallRoomAll(); 
            initializeInterval()
        }

        return (() => clearInterval(interval))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[_online])

    return(
        <Fragment>    
            { _online === 'checking' && <CheckingConnection />  }
            { _online === 'disconnected' && <NoInternet />  } 
            { _online === 'connected' && (
                <>
                    <Row>
                        <Col sm={12} md={3}>
                            {/* next appointment here */}
                            {
                                _approve_app.length > 0 && (
                                    <Box
                                        border={1}
                                        borderRadius={4} 
                                        borderColor={blue[100]}
                                    >
                                        <Alert 
                                            icon={<BellIcon style={{ fontSize: '2.2em'}} /> }   
                                            severity="info"  
                                        >  
                                            <Typography variant="subtitle2"> NEXT APPOINTMENT </Typography>
                                            <Typography variant="body2"> { Notify.dateTimeConvert(_approve_app[0].appointment_date) } </Typography>
                                        </Alert>  
                                    </Box>
                            )}    
                            <Box 
                                mt={2}  
                                border={1} 
                                borderRadius={4} 
                                borderColor={blue[100]}
                                color="white" 
                                className="pointer" 
                                onClick={() => set_notificationdrawer(true) }
                            >
                                <Alert 
                                    severity="info"
                                    icon={
                                        <Badge
                                            className="gtc-float-right" 
                                            badgeContent={ _approve_app.length > 0 ? _approve_app[0].unreadNotification : 0} 
                                            color="secondary"
                                        > <MailIcon /> </Badge> 
                                    }
                                >
                                    <AlertTitle>
                                        <Typography noWrap>
                                            Notification
                                        </Typography>
                                    </AlertTitle> 
                                </Alert> 
                            </Box>

                            <Box 
                                my={2} 
                                p={2} 
                                component={Paper}
                                variant="outlined"
                            >
                                <Typography color="primary" variant="subtitle2" >
                                    VIRTUAL APPOINTMENT
                                </Typography>

                                <List component="div" disablePadding={true}>
                                    {
                                        _approve_app_ready ? 
                                            _approve_app.length > 0 ?
                                                _approve_app.map((data, index)=>{
                                                    return(  
                                                        <ListItem 
                                                            button 
                                                            key={index}
                                                            appid={data.appointment_id} 
                                                            patient_id={data.patient_id} 
                                                            className={data.appointment_id === _selected_appid ? "text-danger" : "pointer"} 
                                                            onClick={ handleSelectedApp }   
                                                        > 
                                                            <ListItemIcon>
                                                                <Badge
                                                                    color="secondary"
                                                                    badgeContent={`new`}
                                                                    invisible={ ! Boolean(parseInt(checkPatientUnreadNotif('appointment', data.patientid_old))) }
                                                                >
                                                                    <Avatar  
                                                                        className= {data.appointment_id === _selected_appid ? "bg-danger" : ""}
                                                                        alt={''}
                                                                        src={ data.patient_image === null ? '/no-image.png' : imageLocation+'patients/'+data.patient_image}
                                                                    />
                                                                </Badge> 
                                                            </ListItemIcon>
                                                            
                                                            <ListItemText 
                                                                className={`gtc-capitalize`}
                                                                primary={ data.patient_name }  
                                                                secondary={
                                                                    <> 
                                                                        on { Boolean(parseInt(data.is_reschedule)) ? 
                                                                            Notify.dateTimeConvert(data.is_reschedule_date)  : Notify.dateTimeConvert(data.appointment_date) } 
                                                                    </>
                                                                } 
                                                            />  
                                                        </ListItem>   
                                                    )
                                                })
                                            : Notify.noRecord()
                                        : Notify.loading()
                                    }  
                                </List>
                            </Box>
                        </Col>
                        <Col sm={12} md={9}>
                            {/* notification list */} 
                            {
                                _selected_app ? 
                                    <AppointmentApprovedDetails 
                                        patient_id = {_selected_patientid} 
                                        appid={_selected_appid} 
                                        resetDisplay = { () => resetDisplay() } 
                                        connection = { props.connection }
                                    />
                                :
                                    <Fragment>
                                        <AppointmentCommentsForApproval connection = { props.connection } />
                                        
                                        <AppointmentRequest 
                                            getApproveAppointment = {getApproveAppointment.bind(this)} 
                                            connection = { props.connection }
                                        />
                                    </Fragment>
                            }
                        </Col>  
                    </Row> 

                    {/* drawer */}
                    <Drawer open={_notificationdrawer} onClose={ () => set_notificationdrawer(false) } anchor="right">
                        <Box m={2} width={ window.innerWidth > 600 ? 600 : window.innerWidth }>
                            <Box mb={2} display="flex">
                                <Box flexGrow={1} mt={2}>
                                    <span className="text-muted text-uppercase header-label h6"> Notifications List </span> 
                                </Box>
                                <Box>
                                    <IconButton color="secondary" onClick={ () => set_notificationdrawer(false) }> <HighlightOffIcon /> </IconButton>
                                </Box> 
                            </Box>

                            <Divider />
                            
                            <Notifications connection = { props.connection } /> 
                        </Box> 
                    </Drawer>
                </>  
            )} 
        </Fragment>
    )
}

export default VirtualAppointment; 