import { TableContainer, Table, TableHead, TableCell, TableRow, Typography, Box, TableBody, Card, CardHeader, CardContent, CardMedia } from '@material-ui/core';
import React from 'react'
import Header from './Header'
import Footer from './Footer'
import ChemistryRef from '../references/Ref_Chemistry';


const imageLocation = process.env.REACT_APP_API_IMAGE;

const ChemistryOrder = ({formheader, chemistryOrderDetails }) => {

    return (
        <>
            <Card>
                
                <Box display="flex" justifyContent="center">
                    <Box mt={2}>
                        {
                            formheader && 
                            <CardMedia
                                style={{ width : 70 }}
                                component={'img'}
                                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                            /> 
                        } 
                    </Box>
                    <Box >
                        <CardHeader
                            component={Box}
                            align="center"
                            title={ formheader && (formheader.name) }
                            subheader={ formheader && (formheader.address) }
                        /> 
                    </Box>
                </Box>  

                <CardContent>
                    <Header details={chemistryOrderDetails} />
                </CardContent>

                <CardContent>
                    <Box>
                        <Typography variant="h6" align="center">
                            <b> CLINICAL CHEMISTRY REPORT </b>
                        </Typography>
                    </Box>
                    <Box mb={1}>
                        <Typography variant="caption"> <b> SPECIMEN: </b> </Typography> <span className="gtc-uppercase"> {chemistryOrderDetails.data.spicemen} </span>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"> <b> TEST REQUEST </b> </TableCell>
                                    <TableCell align="center"> <b> RESULT </b> </TableCell>
                                    <TableCell align="center"> <b> REFERENCE VALUES </b> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    chemistryOrderDetails.data.glucose &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                GLUCOSE
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {chemistryOrderDetails.data.glucose}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {ChemistryRef.glucose()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    chemistryOrderDetails.data.creatinine &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                CREATININE
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {chemistryOrderDetails.data.creatinine}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {ChemistryRef.creatinine()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    chemistryOrderDetails.data.uric_acid &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                URIC ACID
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {chemistryOrderDetails.data.uric_acid}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {ChemistryRef.uric()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    chemistryOrderDetails.data.cholesterol &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                CHOLESTEROL
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {chemistryOrderDetails.data.cholesterol}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {ChemistryRef.cholesterol()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    chemistryOrderDetails.data.triglyceride &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                TRIGLYCERIDE
                                                                </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {chemistryOrderDetails.data.triglyceride}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {ChemistryRef.triglyceride()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    chemistryOrderDetails.data.hdl_cholesterol &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                HDL CHOLESTEROL
                                                                </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {chemistryOrderDetails.data.hdl_cholesterol}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {ChemistryRef.hdlcholesterol()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    chemistryOrderDetails.data.ldl_cholesterol &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                LDL CHOLESTEROL
                                                                </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {chemistryOrderDetails.data.ldl_cholesterol}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {ChemistryRef.ldlcholesterol()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    chemistryOrderDetails.data.sgot &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                SGOT
                                                                </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {chemistryOrderDetails.data.sgot}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {ChemistryRef.sgot()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    chemistryOrderDetails.data.sgpt &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                SGPT
                                                                </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {chemistryOrderDetails.data.sgpt}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {ChemistryRef.sgpt()}
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box>
                        <Footer formheader = { formheader } />
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default ChemistryOrder;