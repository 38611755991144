import React, { useState } from 'react'
import { Dialog, Box, DialogActions, DialogContent, TextField, DialogTitle, Zoom, Button, CircularProgress, Typography } from '@material-ui/core';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/PausePresentation';

const SetPending = ({ imaging_center_id, open, close, reload, getCounts }) =>{

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 
    const username = localStorage.getItem('username') 

    const [submitting, setSubmitting] = useState(false)

    const handleSetAsPending = (e) =>{
        e.preventDefault();
        e.persist();
    
        var formdata = new FormData(e.target);  
        var error = [];
        formdata.set('token', token); 
        formdata.set('user_id', user_id); 
        formdata.set('username', username);     

        if(formdata.get('pending_reason').length === 0 || formdata.get('pending_reason').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('pending reason'); 
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0){
            Notify.consoleLog('set process')
        }else{
            setSubmitting(true)
            Axios.post('imaging/order/set-pending', formdata )
            .then((response) => { 
                const data = response.data;    
                if(data==='pass-inv'){
                Notify.fieldInvalid('password')
                }
                if(data==='db-error'){
                    Notify.warnRequest('set process') 
                }
                if(data==='success'){
                    reload()
                    close()
                    getCounts()
                    Notify.successRequest('set process') 
                } 
            }).catch(error=>{  
                Notify.requestError(error);
            }).finally(() =>{
                setSubmitting(false)
            });
        }
    }  

    return( 
        <Dialog
            TransitionComponent={Zoom}
            transitionDuration={600}
            disableBackdropClick
            open={ open }
            onClose={ close }
        >
            <DialogTitle disableTypography> 
                <Typography variant="subtitle2" className={`gtc-uppercase`}> Set Pending </Typography> 
            </DialogTitle>
            <form onSubmit = { handleSetAsPending }>
                <DialogContent dividers>  
                    <Box mb={2}>
                        <TextField 
                            fullWidth
                            name="imaging_center_id"
                            variant="outlined"
                            label="Selected Order"
                            defaultValue={ imaging_center_id }
                            InputProps={{
                                inputProps:{
                                    readOnly: true
                                }
                            }}
                        /> 
                    </Box>

                    <Box mb={2}>
                        <TextField 
                            fullWidth
                            name="pending_reason"
                            variant="outlined"
                            label="Pending Reason" 
                            multiline 
                            autoFocus
                        /> 
                    </Box> 

                    <Box>
                        <TextField 
                            fullWidth
                            name="password"
                            variant="outlined"
                            label="Enter Password"
                            type="password" 
                        /> 
                    </Box>
                </DialogContent> 
                <DialogActions> 
                    <Button
                        variant="contained"
                        color="inherit"
                        startIcon={<Cancel />}
                        onClick={ close }
                    >
                        No
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        startIcon={ submitting ? <CircularProgress color="inherit" size={15} /> : <CheckCircleIcon />}
                        disabled={ submitting }
                    >
                        pending
                    </Button> 
                </DialogActions>
            </form>
        </Dialog> 
    )
}

export default SetPending;