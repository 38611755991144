import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Collapse, Paper } from '@material-ui/core';  
import Notify from '../notification/Notify';
import Axios from 'axios';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import SetProcesss from './Dialogs/SetProcess';

 
const ImagingOrderPending = ({ getCounts }) =>{
 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')  

    const [pendingOrder, setPendingOrder] = useState({ data: [], ready: false }) 

    const [ process, setProcess] = useState({ imagingId: null, open: false })  

    const getPendingOrder = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        
        Axios.post('imaging/order/order-pending', formdata )
        .then((response) => {  
            const data = response.data;   
            setPendingOrder({ data, ready: true })
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id]); 

    
    useEffect(() =>{
        getPendingOrder()
    }, [getPendingOrder])

    return(
        <Fragment>
            <Collapse in={pendingOrder.ready} timeout={600}>
                <Paper component={Box} p={2} variant="outlined"> 
                    <TableContainer >
                        <Box ml={2} mt={2}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                            >
                                PENDING ORDER
                            </Typography>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> ID </TableCell>
                                    <TableCell> PendingOn </TableCell>
                                    <TableCell> Patient </TableCell>
                                    <TableCell> Order </TableCell>
                                    <TableCell> Reason </TableCell> 
                                    <TableCell> Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody> 
                                {
                                    pendingOrder.ready ?
                                        pendingOrder.data.length > 0 ?
                                            pendingOrder.data.map((data,index)=>(
                                                <TableRow key={index} hover>
                                                    <TableCell> { data.imaging_center_id.replace('imaging-','') } </TableCell>
                                                    <TableCell> { Notify.dateTimeConvert(data.pending_date) } </TableCell>
                                                    <TableCell> { data.patient_name } </TableCell>
                                                    <TableCell> { data.laboratory_orders } </TableCell>
                                                    <TableCell> { data.pending_reason } </TableCell> 
                                                    <TableCell> 
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            color="primary"
                                                            endIcon={ <DoneAllIcon /> }
                                                            onClick={()=>{
                                                                setProcess({ imagingId: data.imaging_center_id, open: true })
                                                            }}
                                                        > process </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <Typography variant="subtitle2" color="secondary"> No new order </Typography> 
                                            </TableCell> 
                                        </TableRow>
                                    : 
                                    <TableRow>
                                        <TableCell colSpan={6}> 
                                            <Typography variant="subtitle2" color="primary"> please wait </Typography>
                                        </TableCell> 
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Collapse> 
            {/* setis process dialog */}
            <SetProcesss 
                open = { process.open }
                imaging_center_id = { process.imagingId }
                close={ () => setProcess({ imagingId: null , open: false }) }
                reload = { getPendingOrder }
                getCounts = { getCounts }
            />
            
        </Fragment>
    )
}

export default ImagingOrderPending;