import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import RadiologistPatientForReview from './RadiologistPatientForReview';
import RadiologistPatientDetails from './RadiologistPatientDetails';
import RadiologistPatientReviewed from './RadiologistPatientReviewed';

const Radiologist = () => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [patients , setPatients] = useState({
        data: [],
        ready: false
    })

    const [records , setRecords] = useState({
        data: [],
        ready: false
    })

    var interval = null;

    const [selectedPatient, setSelectedPatient] = useState(null)

    const getPatientForReview = useCallback( async() =>{
        try {
            var formdata = new FormData();
                formdata.append('token', token)
                formdata.append('user_id', user_id)
            const request =  await Axios.post('radiologist/patients/getpatient-forreview', formdata)
                setPatients({
                    data: request.data,
                    ready: true
                })
        } catch (error) {
            Notify.requestError(error)
        }
    },[token, user_id])

    const getPatientRead = useCallback( async() =>{
        try {
            var formdata = new FormData();
                formdata.append('token', token)
                formdata.append('user_id', user_id)
            const request =  await Axios.post('radiologist/patients/getpatients-read', formdata)
                setRecords({
                    data: request.data,
                    ready: true
                })
        } catch (error) {
            Notify.requestError(error)
        }
    },[token, user_id])

    const initializeInterval = () =>{
        interval = setInterval( async() => {
            getPatientForReview()
        }, 15000);
    }

    const updateSelectedPatient = (data) =>{   
        setSelectedPatient(data)
    }

    useEffect(() =>{
        
        getPatientForReview()

        initializeInterval()

        getPatientRead()

        return( ()=>{ clearInterval(interval) })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getPatientForReview, getPatientRead])

    return(
        <Box m={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <Box>
                        <RadiologistPatientForReview 
                            patients = { patients } 
                            selectedPatient = { selectedPatient }
                            updateSelected = { updateSelectedPatient } 
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={8} md={9}>
                    <Box> 
                        { selectedPatient === null && (<RadiologistPatientReviewed records={ records } /> )}
                        { selectedPatient && ( 
                            <RadiologistPatientDetails 
                                selectedPatient = { selectedPatient } 
                                resetDisplay = { () =>{ 
                                    setSelectedPatient(null) 
                                    getPatientForReview()
                                    getPatientRead()
                                }} 
                            /> 
                        )} 
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Radiologist;