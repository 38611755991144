import React, { useState } from 'react'
import { Dialog, DialogTitle, IconButton, Snackbar, Typography, Badge } from '@material-ui/core';
import { Alert } from '@material-ui/lab'; 
import Notify from 'src/notification/Notify';
import AppointmentNotificationMsgDetails from '../appointment/AppointmentNotificationMsgDetails';
import MessageIcon from '@material-ui/icons/Message';
import ReplyIcon from '@material-ui/icons/Reply';

const SnackbarUnreadNotif = ({ notifUnread, getUnreadNotifMsg }) =>{

    const [selectedNotfiId, setSelectedNotifId] = useState(null)
    const [ notifDialog, setNotifDialog] = useState(false)

    const handleNotif = (e) =>{
        setSelectedNotifId(e.currentTarget.getAttribute('notifid'))
        setNotifDialog(true) 
    }

    const closeNotifDialog = () =>{ 
        setSelectedNotifId(null)
        setNotifDialog(false) 
    }

    return(
        <>
            {notifUnread.length > 0 && (
                notifUnread.map((data, index)=>{
                    return( 
                        <Snackbar
                            key={index} 
                            open={ true } 
                            anchorOrigin={{  vertical: 'bottom', horizontal: 'right'  }}  
                        > 
                            <Alert  
                                severity="info" 
                                variant="filled"
                                icon={ <Badge badgeContent={index + 1} color="error"> <MessageIcon /> </Badge> } 
                                action={
                                    <IconButton
                                        notifid={data.notif_id} 
                                        color="inherit"
                                        onClick={ handleNotif }
                                    > <ReplyIcon />  </IconButton>
                                }
                            > 
                                <Typography variant="subtitle2">
                                    { data.notification_msg }
                                </Typography>   
                                <Typography variant="caption">
                                    { data.doctors_name }  message on {Notify.dateTimeConvert(data.created_at)}
                                </Typography>
                            </Alert> 
                        </Snackbar>  
                    )
                })
            )}

            {/* notification dialog */} 
            <Dialog
                disableBackdropClick
                open={ notifDialog } 
                onClose={ closeNotifDialog }
            >
                <DialogTitle>
                    Notification
                </DialogTitle>
                <AppointmentNotificationMsgDetails
                    notifid = { selectedNotfiId } 
                    closeNotifDialog = { closeNotifDialog }
                    getNotificationMsg = { getUnreadNotifMsg }
                />
            </Dialog> 
        </>
    )
}

export default SnackbarUnreadNotif;