
import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react"; 
import Notify from "../../notification/Notify";
import Box from "@material-ui/core/Box";  
import GTCChart from "../../GoogleChart/GTCChart";
 
const BloodPressure = () => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [chartData, setChartData] = useState([])   
    const [ready, setReady] = useState(false)

    const getBloodPressure = useCallback(() => {
        var formData = new FormData();
			formData.set('user_id', user_id);
			formData.set('token', token); 

        Axios.post('patient/vitals/graph/get-bloodpressure',  formData)
		.then( (response) => { 
            const data = response.data;   
            pushtoChart(data)  
            setReady(true)
		})
		.catch(error => { 
			Notify.requestError(error);
		})
    }, [user_id, token]);
    
    const pushtoChart = (data) =>{ 
        if(data.length > 0){
            var newData = [['x', 'Systolic', 'Diastolic']]
            for (let i = 0; i < data.length; i++) {
                newData.push([Notify.dateTimeConvert(data[i].created_at), parseFloat(data[i].systolic), parseFloat(data[i].diastolic)])  
            }    
            setChartData(newData)
        }
        else{
            setChartData([['x', 'Systolic', 'Diastolic'], ['No Record', 0, 0]])
        }
    }

    useEffect(() =>{
        getBloodPressure()
    }, [getBloodPressure]) 

    return ( 
            <Box 
                px={2}
            >
                {
                    ready ? 
                        <GTCChart
                            chartData= { chartData }
                            chartLabel = {'Blood Pressure'} 
                        /> 
                    : Notify.loading()
                } 
            </Box>  
        );
    };
export default BloodPressure;