
import { Box, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Button, DialogTitle, DialogContent, DialogActions, Tooltip, CircularProgress } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import Notify from 'src/notification/Notify'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import DraggableDialog from 'src/utils/DraggableDialog'
import { useHistory } from 'react-router-dom'
import FormvalidationClinicChemistry from './validation/FormvalidationClinicChemistry';
import { Fragment } from 'react';
import ChemistryRef from '../references/Ref_Chemistry';

const ClinicalChemistryOrderDetails = ({ order, getLabCliniclChemistryOrder, resetDisplay }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')
    const history = useHistory()
    const [orderDetails, setOrderDetails] = useState({
        data: null,
        ready: false
    })
    const [pendingDialog, setPendingDialog] = useState(false)
    const [processDialog, setProcessDialog] = useState(false)
    const [pendingSubmitting, setPendingSubmitting] = useState(false)
    const [processSubmitting, setProcessSubmitting] = useState(false)
    const [resultSubmitting, setResultSubmitting] = useState(false)

    const getLabCChemistryOrderDetails = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', order.order_id);
        Axios.post('laboratory/order/ordernew-clinicalchemistry/details', formdata)
            .then((response) => {
                const data = response.data;
                setOrderDetails({
                    data: data,
                    ready: true
                })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {
        getLabCChemistryOrderDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    const handleSaveResult = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', order.order_id);
        formdata.set('patient_id', orderDetails.data.patient_id)
        formdata.set('doctor_id', orderDetails.data.doctor_id)

        var error = [];

        if (orderDetails.data) {
            error = FormvalidationClinicChemistry(orderDetails.data, formdata);
        }

        if (error.length > 0) {
            console.log("Form has an error.")
        } else {
            setResultSubmitting(true)
            Axios.post('laboratory/order/ordernew-clinicalchemistry/save-process-result', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        Notify.successRequest('order result added.')
                        setTimeout(() => {
                            history.push(`/app/laboratory/record/print/order/${order.order_id}`)
                        }, 5000);
                    }
                }).catch(error => {
                    Notify.requestError(error);
                });
        }
    }

    const handlePendingOrder = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.set('order_id', order.order_id);

        var error = [];

        if (error.length > 0) {
            console.log("Form has an error.")
        } else {
            setPendingSubmitting(true)
            Axios.post('laboratory/order/ordernew-clinicalchemistry/save-setpending', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'success') {
                        getLabCliniclChemistryOrder()
                        resetDisplay()
                        setPendingDialog(false)
                        Notify.successRequest('order pending')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => setPendingSubmitting(false));
        }
    }

    const handleProcessOrder = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.set('order_id', order.order_id);

        var error = [];

        if (error.length > 0) {
            console.log("Form has an error.")
        } else {

            setProcessSubmitting(true)
            Axios.post('laboratory/order/ordernew-clinicalchemistry/save-setprocessing', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'success') {
                        getLabCliniclChemistryOrder()
                        setProcessDialog(false)
                        resetDisplay()
                        Notify.successRequest('order pending')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => setProcessSubmitting(false));
        }
    }
    return (
        <>
            <form onSubmit={handleSaveResult}>
                <Card elevation={0}>
                    <CardHeader
                        component={Box}
                        align="center"
                        title="DONASCO DIAGNOSTIC LABORATORY"
                        subheader="2f. Planlaque Bldg., Papaya St. General Santos City"
                    />
                    <CardContent>
                        {/* paitent information */}
                        <Box display="flex">
                            <Box flexGrow={1} mb={2}>
                                <Box mb={2}>
                                    <Typography>
                                        <Typography variant="caption" className="font-weight-bold">
                                            NAME:
                                        </Typography>  {`${order.firstname} ${order.lastname}`}
                                    </Typography>
                                </Box>

                                <Box display="flex" mb={2}>
                                    <Box>
                                        <Typography>
                                            <Typography variant="caption" className="font-weight-bold">
                                                AGE:
                                            </Typography>  {order.birthday === null ? 'none' : Notify.calculateAge(order.birthday)}
                                        </Typography>
                                    </Box>
                                    <Box ml={5}>
                                        <Typography>
                                            <Typography variant="caption" className="font-weight-bold">
                                                SEX:
                                            </Typography>  {order.gender === null ? 'none' : order.gender}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography>
                                        <Typography variant="caption" className="font-weight-bold">
                                            ADDRESS:
                                        </Typography>  {`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box>
                                <Box mb={2}>
                                    <Typography>
                                        <Typography variant="caption" className="font-weight-bold">
                                            DATE:
                                        </Typography>  {Notify.dateTimeConvert(new Date().toLocaleString())}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>

                    <CardContent>
                        {/* order details */}
                        <Box>
                            <Typography variant="h6" align="center">
                                <b> CLINICAL CHEMISTRY REPORT </b>
                            </Typography>
                        </Box>
                        {
                            orderDetails.ready ?
                                Object.keys(orderDetails.data).length > 0 ?
                                    <Fragment>
                                        <Box mb={1}>
                                            <Typography variant="caption"> <b> SPECIMEN: </b> </Typography> <span className="gtc-uppercase"> {orderDetails.data.spicemen} </span>
                                        </Box>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center"> <b> TEST REQUEST </b> </TableCell>
                                                        <TableCell align="center"> <b> RESULT </b> </TableCell>
                                                        <TableCell align="center"> <b> REFERENCE VALUES </b> </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        orderDetails.data.glucose &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    GLUCOSE
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="glucose"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {ChemistryRef.glucose()}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {
                                                        orderDetails.data.creatinine &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    CREATININE
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="creatinine"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {ChemistryRef.creatinine()}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {
                                                        orderDetails.data.uric_acid &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    URIC ACID
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="uric_acid"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {ChemistryRef.uric()}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {
                                                        orderDetails.data.cholesterol &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    CHOLESTEROL
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="cholesterol"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {ChemistryRef.cholesterol()}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {
                                                        orderDetails.data.triglyceride &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    TRIGLYCERIDE
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="triglyceride"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {ChemistryRef.triglyceride()}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {
                                                        orderDetails.data.hdl_cholesterol &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    HDL CHOLESTEROL
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="hdl_cholesterol"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {ChemistryRef.hdlcholesterol()}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {
                                                        orderDetails.data.ldl_cholesterol &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    LDL CHOLESTEROL
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="ldl_cholesterol"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {ChemistryRef.ldlcholesterol()}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {
                                                        orderDetails.data.sgot &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    SGOT
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="sgot"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {ChemistryRef.sgot()}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {
                                                        orderDetails.data.sgpt &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    SGPT
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="sgpt"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {ChemistryRef.sgpt()}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Fragment>
                                    :
                                    <Typography color="secondary" variant="subtitle2">
                                        No record found.
                                    </Typography>
                                :
                                <Typography color="primary" variant="subtitle2">
                                    please wait...
                                </Typography>
                        }
                    </CardContent>
                    <Box display="flex" m={1}>
                        <Box flexGrow={1} />
                        <Box>
                            <CardActions>
                                <Tooltip title={`${order.is_pending_reason}`} open={Boolean(parseInt(order.is_pending))} arrow>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        onClick={() => setPendingDialog(true)}
                                        disabled={Boolean(parseInt(order.is_pending))}
                                    >
                                        Set as Pending
                                    </Button>
                                </Tooltip>

                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    disabled={Boolean(parseInt(order.is_processed))}
                                    onClick={() => setProcessDialog(true)}
                                >
                                    Set As Process
                                </Button>

                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    type="submit"
                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                    startIcon={resultSubmitting && <CircularProgress size={20} color="inherit" />}
                                >
                                    Save Result
                                </Button>
                            </CardActions>
                        </Box>
                    </Box>
                </Card>
            </form>

            {/* set as pending dialog */}
            <Dialog
                open={pendingDialog}
                onClose={() => setPendingDialog(false)}
                disableBackdropClick
                PaperComponent={DraggableDialog}
            >
                <DialogTitle id="draggable-handle">
                    Set as pending
                </DialogTitle>
                <form
                    onSubmit={handlePendingOrder}
                >
                    <DialogContent dividers>
                        <Box mb={2}>
                            <TextField
                                rows={5}
                                fullWidth
                                name="reason"
                                label={`Pending Reason`}
                                variant="outlined"
                                multiline
                            />
                        </Box>

                        <Box>
                            <TextField
                                fullWidth
                                name="password"
                                label={`Password`}
                                variant="outlined"
                                type="password"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="default" onClick={() => setPendingDialog(false)} startIcon={<HighlightOffIcon />}>
                            cancel
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" disabled={pendingSubmitting} startIcon={pendingSubmitting ? <CircularProgress size={20} color="inherit" /> : <ErrorOutlineIcon />}>
                            pending
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* set as process dialog */}
            <Dialog
                open={processDialog}
                onClose={() => setProcessDialog(false)}
                disableBackdropClick
                PaperComponent={DraggableDialog}
            >
                <DialogTitle id="draggable-handle">
                    Set as processing
                </DialogTitle>
                <form
                    onSubmit={handleProcessOrder}
                >
                    <DialogContent dividers>
                        <Box>
                            <TextField
                                fullWidth
                                name="password"
                                label={`Password`}
                                variant="outlined"
                                type="password"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="default" onClick={() => setProcessDialog(false)} startIcon={<HighlightOffIcon />}>
                            cancel
                        </Button>
                        <Button variant="contained" color="primary" type="submit" disabled={processSubmitting} startIcon={processSubmitting ? <CircularProgress size={20} color="inherit" /> : <CheckCircleIcon />}>
                            process
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default ClinicalChemistryOrderDetails;
