import React, { Component, Fragment } from 'react';  
import Notify from '../../notification/Notify';
import Axios from 'axios'; 
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';   
import { Paper } from '@material-ui/core';

const imageLocation = process.env.REACT_APP_API_IMAGE;   
const imageLocationOnline = process.env.REACT_APP_API_IMAGE_VIRTUAL;   

export default class PersonalInfoHeader extends Component{
    _mounted = false;
 
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
            _patient_id: this.props.patient_id,
            _info: [],
            _info_ready: false,
        }
    } 
    
    getPatientInformation(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);    
        formdata.set('management_id', this.state._management_id);    
        formdata.set('patient_id', this.props.patient_id);    
        formdata.set('connection', this.props.connection);    
        
        Axios.post('doctor/patient/patient-information', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                if(data.length > 0){
                    this.setState({
                        _info: data,
                        _info_ready: true,
                    })
                }else{
                    this.setState({
                        _info: [],
                        _info_ready: true,
                    })
                }
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    componentDidMount(){
        this._mounted = true;
        this.getPatientInformation();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    componentDidUpdate(){
        if(this.state._patient_id !== this.props.patient_id){
            this.setState({
                _patient_id: this.props.patient_id,
                _info_ready: false,
            })
            this.componentDidMount();
        }
    } 

	render(){  
		return(
            <Fragment> 
                <Box component={Paper} variant="outlined"  p={2} mb={2}> 
                    {
                        this.state._info_ready ? 
                            this.state._info.length > 0 ?
                                <div className="clearfix">
                                    <div className="float-left">
                                        <Avatar 
                                            style={{
                                                width: '120px',
                                                height: '120px'
                                            }} 
                                            src={
                                                this.props.connection === 'online' ?
                                                    this.state._info[0].image !== null ? imageLocationOnline+'patients/'+this.state._info[0].image : ''
                                                :
                                                    this.state._info[0].image !== null ? imageLocation+'patients/'+this.state._info[0].image : ''
                                            }
                                        />
                                    </div> 
                                    <div className="ml-sm-4 float-left">
                                        <p className="h5 header-label text-capitalize m-0 my-2 font-weight-bold" > 
                                        { this.state._info[0].lastname }, { this.state._info[0].firstname }  { this.state._info[0].middle }  
                                        </p> 
                                        <p className="m-0 my-2"> 
                                            <span className="text-muted gtc-small text-uppercase"> Age: </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; { Notify.calculateAge(this.state._info[0].birthday) } 
                                        </p>
                                        <p className="m-0 my-2"> 
                                            <span className="text-muted gtc-small text-uppercase"> Gender: </span> &nbsp; { this.state._info[0].gender } 
                                        </p>
                                        <p className="m-0 my-2"> 
                                            <span className="text-muted gtc-small text-uppercase"> Birthday: </span> { Notify.birthday(this.state._info[0].birthday) } 
                                        </p>
                                    </div>
                                </div>
                            :
                            Notify.noRecord()
                        :
                        Notify.loading()
                    }  
                </Box>
            </Fragment>
        )
	}
}
  