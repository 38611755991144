import React, { Fragment, useContext, useState } from "react" 
import { BrowserRouter, NavLink, Redirect, Route, Switch } from 'react-router-dom'; 
import Dashboard from "../telerad/Dashboard";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound"; 
import { ThemeContext } from "../ContextAPI";
import DarkIcon from '@material-ui/icons/Brightness4';
import LightIcon from '@material-ui/icons/Brightness7';
import { AppBar, Badge, Box, Hidden, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core"; 
import ExitToAppIcon from '@material-ui/icons/ExitToApp'; 
import Drawer from "src/telerad/messages/Drawer"; 
import MoreVertIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Axios from 'axios'
import GTCValidateLS from "src/notification/GTCValidateLS";

const RouteTelerad = () =>{
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const themeContext = useContext(ThemeContext)   
    const [open , setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null)
    const [menu, setMenu] = useState(false)

    const [count, setCount]= useState(0)
    
    var interval = null;

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
        setMenu(true) 
    }; 

    const handleClose = () => {
        setAnchorEl(null)
        setMenu(false) 
    };  
    
    const getUnreadMessage = async () =>{
        try {
            var formdata = new FormData()
                formdata.append('token', token)
                formdata.append('user_id', user_id)
            const request   = await Axios.post('telerad/message/get-unreadcount', formdata)
            setCount(request.data) 
        } catch (error) {
            console.log('unable to fetch unread', error.message)
        }
    } 

    const initializeInterval = () =>{
        interval = setInterval(() => {
            getUnreadMessage()
        }, 10000);
    }  


    React.useEffect(() => {

        initializeInterval()

        GTCValidateLS.verifyLocalStorage()

        return( () =>{ clearInterval(interval) }) 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <Fragment>  
            <BrowserRouter>
                <AppBar 
                    position="static" 
                    color={ themeContext.gtcThemeDark ? 'default' : 'primary' } 
                    className={`gtc-appbar`}
                >  
                    <Hidden xsDown>
                        <Toolbar> 
                            {/* desktop navigation bar */} 
                            <Box 
                                display="flex" 
                                width={'100%'}
                                className={`appbarLink`}
                            >  
                                <Box flexGrow={1}> 
                                    <Box display="flex">
                                        <Box m={2}>
                                            <Typography className={`text-white`}>
                                                GTC TELERAD
                                            </Typography>
                                        </Box> 
                                        <Box m={2}> 
                                            <NavLink to="/app/telerad" exact activeClassName="text-white"> 
                                                Home 
                                            </NavLink> 
                                        </Box>   
                                        <Box m={2}> 
                                            <Typography
                                                component="a"
                                                onClick = { () => setOpen(true) }
                                                className={`pointer`}
                                            > 
                                                <Badge
                                                    color="error" 
                                                    badgeContent={
                                                        count
                                                    }
                                                >
                                                    Messages
                                                </Badge> 
                                            </Typography> 
                                        </Box>  
                                    </Box> 
                                </Box> 
                            </Box>       

                            <Box>
                                <Box display="flex" width={180}>
                                    <Box m={2} className={`pointer`}> 
                                        <Typography onClick={()=>{ themeContext.updategtcTheme(themeContext.gtcThemeDark ? 'light' : 'dark') } }> { themeContext.gtcThemeDark ? <DarkIcon /> : <LightIcon /> } </Typography>
                                    </Box>

                                    <Box m={2}>  
                                        <NavLink to="/app/logout" exact style={{ color: '#c5c5c5', textDecoration: 'none' }}> 
                                            Logout  <ExitToAppIcon />
                                        </NavLink>    
                                    </Box>
                                </Box>
                            </Box>
                        </Toolbar>
                    </Hidden>

                    <Hidden smUp>
                        <Toolbar>
                            {/* mobile navigation */}
                            <Box 
                                display="flex" 
                                width={'100%'}
                                className={`appbarLink`}
                            >  
                                <Box flexGrow={1}> 
                                    <Box display="flex">
                                        <Box m={2} flexGrow={1}>
                                            <Typography noWrap className={`text-white`}>
                                                GTC TELERAD
                                            </Typography>
                                        </Box>   

                                        <Box m={2} className={`pointer`}> 
                                            <Typography onClick={()=>{ themeContext.updategtcTheme(themeContext.gtcThemeDark ? 'light' : 'dark') } }> { themeContext.gtcThemeDark ? <DarkIcon /> : <LightIcon /> } </Typography>
                                        </Box>
                                        
                                        <Box>
                                            <IconButton
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                onClick={ handleMenu }
                                                color="inherit"
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                id="menu-appbar"
                                                anchorEl={ anchorEl }
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                keepMounted  
                                                open={ menu }
                                                onClose={ handleClose }
                                            >
                                                <MenuItem
                                                    component={NavLink}
                                                    to="/app/telerad"
                                                >
                                                    <ListItemIcon>
                                                        <HomeIcon />
                                                    </ListItemIcon> 
                                                    <Typography color="primary"> 
                                                        Home 
                                                    </Typography>  
                                                </MenuItem>
                                                <MenuItem> 
                                                    <ListItemIcon>
                                                        <Badge
                                                            color="error" 
                                                            badgeContent={
                                                                count
                                                            }
                                                        >
                                                            <MailOutlineIcon />
                                                        </Badge> 
                                                    </ListItemIcon>

                                                    <Typography
                                                        component="a"
                                                        onClick = { () => setOpen(true) }
                                                        className={`pointer`}
                                                    >  
                                                        Messages 
                                                    </Typography> 
                                                </MenuItem>
                                                <MenuItem
                                                    component={NavLink}
                                                    to="/app/logout"
                                                >
                                                    <ListItemIcon>
                                                        <ExitToAppIcon />
                                                    </ListItemIcon>
                                                    <Typography color="error">
                                                        Logout 
                                                    </Typography>  
                                                </MenuItem> 
                                            </Menu>
                                        </Box>
                                    </Box> 
                                </Box> 
                            </Box>  
                        </Toolbar>
                    </Hidden>
                </AppBar>
                <Switch>
                    <Route exact path="/" component={() => <Redirect to="/app/telerad" /> } />    
                    <Route exact path="/app" component={() => <Redirect to="/app/telerad" /> } /> 
                    <Route exact path="/app/telerad" component={Dashboard}/>    
                    <Route exact path="/app/logout" component={Logout} />
                    <Route render={
                        () => <PageNotFound />
                    }/> 
                </Switch>
            </BrowserRouter> 

            {/* messages drawer */} 
            { open && ( <Drawer open={ open } close={() => setOpen(false) } /> ) }
        </Fragment>
    )
}

export default RouteTelerad;
 