
import React  from 'react';   
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock, faExclamationTriangle, faTimesCircle, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'; 
import HashLoader from "react-spinners/HashLoader"; 
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import { Typography } from '@material-ui/core';
 

class Notifies {
     customToast = (title, msg) => {
        toast( 
            <div className="row "> 
                <div className="col-sm-2 my-auto">
                    <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="" />
                </div>
                <div className="col-sm-10">
                    <b className="header-label text-uppercase gtc-small"> {title} </b><br />
                    <p className="m-0 header-label">{ msg }</p>
                </div> 
            </div>  
        );
    }

     fieldRequired = (field) => {
        toast( 
            <div className="row "> 
                <div className="col-sm-2 my-auto">
                    <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="" />
                </div>
                <div className="col-sm-10">
                    <b className="header-label text-uppercase gtc-small"> {field} </b><br />
                    <p className="m-0 header-label"> Field is required.</p>
                </div> 
            </div>  
        );
    } 
    
     fieldInvalid = (field) => {
        toast( 
            <div className="row"> 
                <div className="col-sm-2 my-auto">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x"  className=""/>
                </div>
                <div className="col-sm-10">
                    <b className="header-label text-uppercase gtc-small"> {field} </b><br />
                    <p className="m-0 header-label"> Field is invalid.</p>
                </div> 
            </div>  
        );
    }
    
     consoleLog = (alt) =>  {
        console.log('Unable to process '+ alt +' request, Form has an error.');
    }

     requestError = (error) => { 
        var toastId  = 1;

        if(error.message ==='Request failed with status code 500'){
            if (!toast.isActive(toastId)) {
                toast( 
                    <div className="row"> 
                        <div className="col-sm-3 my-auto  mx-auto "> 
                            <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="" />
                        </div>
                        <div className="col-sm-9">
                            <b className="header-label text-uppercase gtc-small"> Connection Error </b><br />
                            <p className="m-0 header-label"> Unable to connect to server.</p>
                        </div> 
                    </div>,{ toastId: 1 } 
                );
            }
        }
        
        if(error.message ==='Request failed with status code 404'){
            if (!toast.isActive(toastId)) {
                toast( 
                    <div className="row"> 
                        <div className="col-sm-3 my-auto">
                            <FontAwesomeIcon icon={faTimesCircle} size="2x" className="mx-auto" />
                        </div>
                        <div className="col-sm-9">
                            <b className="header-label text-uppercase gtc-small"> Page Not Found </b><br />
                            <p className="m-0 header-label"> Unable to connect to server.</p>
                        </div> 
                    </div>, {toastId: 1, autoClose: false, closeButton: false}
                );
            }
        }

        if(error.message ==='Request failed with status code 401'){
            if (!toast.isActive(toastId)) {
                toastId = toast( 
                    <div className="row">
                        <div className="col-sm-3 my-auto">
                            <FontAwesomeIcon icon={faUserLock} size="2x" className="" />
                        </div>
                        <div className="col-sm-9">
                            <b className="header-label text-uppercase gtc-small"> Unauthorized </b><br />
                            <p className="m-0 header-label"> Login session expired. Try to relogin</p>
                        </div> 
                    </div>  , {toastId: 1, autoClose: false, closeButton: false, closeOnClick: false }
                ); 
            }
            setTimeout(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('is_login')
                window.location.href="/"
            }, 3000);
        } 
    } 

     successRequest = (alt) => {
        toast.success(
            <div className="row"> 
                <div className="col-sm-3 my-auto">
                    <FontAwesomeIcon icon={faCheckCircle} size="2x" className="" />
                </div>
                <div className="col-sm-9">
                    <b className="header-label text-uppercase gtc-small"> Success </b><br />
                    <p className="m-0 header-label"><span className="text-capitalize">{alt}</span>  request successfully process.</p>
                </div> 
            </div>  
        );
    }

     warnRequest = (alt) => {
        toast.warning(
            <div className="row"> 
                <div className="col-sm-3 my-auto">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="" />
                </div>
                <div className="col-sm-9">
                    <b className="text-capitalize"> Warning </b><br />
                    <p className="m-0 header-label"><span className="text-capitalize">{alt}</span>  request cannont be process. Database connection refuse.</p>
                </div> 
            </div>  
        );
    }

     loading = () => {
        return(
            <div className="text-primary text-center">
                <div className="d-flex mx-auto mb-2" style={{width: '35px'}}>
                    <HashLoader
                        loading={true}
                        size={30}
                        color={"#007bff"}
                    />
                </div>
                <b>Loading</b>
            </div>
        )
    }

     noRecord = () => {
        return(
            <div className="text-center text-danger">
                {/* <FontAwesomeIcon icon={faInbox} size="2x" className="mx-auto" /> <br /> */}
                <InboxOutlinedIcon fontSize="large" />
                <Typography>
                    No record found.
                </Typography>
            </div>
        )
    } 

     numberFormat = (amount) => {
        var _amount = parseFloat(amount);
        return _amount.toLocaleString('en-IN', {minimumFractionDigits: 2, maximumFractionDigits: 2})
    }

     calculateAge = (birthday) => {
        return (
            new Date().toLocaleString('en-US',{ year:'numeric'}) - new Date(birthday.replace(/-/g, '/')).toLocaleString('en-US',{ year:'numeric'}) > 0 ? 
            new Date().toLocaleString('en-US',{ year:'numeric'}) - new Date(birthday.replace(/-/g, '/')).toLocaleString('en-US',{ year:'numeric'}) + ' years old'
            :
            
            new Date().toLocaleString('en-US',{ month:'numeric'}) - new Date(birthday.replace(/-/g, '/')).toLocaleString('en-US',{ month:'numeric'}) > 0 ? 
            new Date().toLocaleString('en-US',{ month:'numeric'}) - new Date(birthday.replace(/-/g, '/')).toLocaleString('en-US',{ month:'numeric'}) + ' month old'

            :
            <span > Days old </span> 
        )
    }

     birthday = (birthday) => {
        var d = birthday.replace(/-/g, '/'); // "mm/dd/yyyy hh:mm:ss" 
        return new Date(d).toLocaleString('en-US',{ year:'numeric',month:'long',day:'2-digit'});
    }

     createdAt = (date) => {
        var d = date.replace(/-/g, '/'); // "mm/dd/yyyy hh:mm:ss" 
       return new Date(d).toLocaleString('en-US',{ year:'numeric',month:'short',day:'2-digit'})
    }

     createdTime = (date) => {
        var d = date.replace(/-/g, '/'); // "mm/dd/yyyy hh:mm:ss" 
        return new Date(d).toLocaleString('en-US',{ hour:'numeric',minute:'numeric'})
     }

     dateTimeConvert = (date) => {  
        var d = date.replace(/-/g, '/'); // "mm/dd/yyyy hh:mm:ss"  
        return new Date(d).toLocaleString('en-US',{ year:'numeric',month:'short',day:'2-digit', hour:'numeric', minute: 'numeric'}); 
     }

      isvalidEmail = (email) => {
        //eslint-disable-next-line
        return /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,5}$/.test( email ); 
     }

      convertToNumber = (amount) => {
        return new Intl.NumberFormat('US',{style:'decimal',minimumFractionDigits:'2',maximumFractionDigits:'2'}).format(amount) 
     }

     isValidUrl = (txt) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(txt);
    }

    randomizeString = (string) =>{ 
        var shuffle = ''; 
        var letter = `abcdefghigklmnopqrstvuwwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789${new Date().getTime()}`;
        var mixed = `${letter}${string}`
        var randomize = mixed.split('');
        while (randomize.length > 0) {
            shuffle +=  randomize.splice(randomize.length * Math.random() << 0, 1);
        }
        return shuffle; 
    }

    clearLocalStorage = () => {
        localStorage.removeItem('token');  
        localStorage.removeItem('is_login') 
    }
}

const Notify = new Notifies();
export default Notify;