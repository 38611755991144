import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import { Box, Grid, Typography, Button, TextField, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination, FormHelperText } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddQtySpecificBatch from '../Add/AddQtySpecificBatch';

function AddProductBatches({ fetchInventoryList, selectedProdID, selectedProductName, selectedUnit, selectedDescription, selectedSupplier }) {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [username] = useState(localStorage.getItem('username'))
    const [pharmacy_id] = useState(localStorage.getItem('pharmacy_id'))
    const [batches, setBatches] = useState([]);
    const [batchesReady, setBatchesReady] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [defaultView, setDefaultView] = useState('table-view-batches');
    const [selectedDataArray, setSelectedDataArray] = useState([]);

    const getFormData = (object) => {
        const formData = new FormData();
        formData.append('product', selectedProductName)
        formData.append('product_id', selectedProdID)
        formData.append('unit', selectedUnit)
        formData.append('description', selectedDescription)
        formData.append('supplier', selectedSupplier)

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    const getBatchesByProductID = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);
        formdata.set('product_id', selectedProdID)

        Axios.post('malita/pharmacy/get-batches-by-product-id', formdata)
            .then((response) => {
                const data = response.data;
                setBatches(data);
                setBatchesReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, selectedProdID, management_id])

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const showAddProductDialog = (e) => {
        setDefaultView('add-stock-id')
    }

    const handleAddQtySpecId = (data) => {
        setSelectedDataArray(data);
        setDefaultView('add-qty-spec-id');
    }

    useEffect(() => {
        getBatchesByProductID()
    }, [getBatchesByProductID])

    return (
        <Fragment>
            {
                defaultView === 'table-view-batches' ?
                    <TableContainer component={Box} >
                        <Box display="flex">
                            <Box mb={1}>
                                <Typography variant="h5" color="primary">
                                    {selectedProductName} - {selectedDescription}
                                </Typography>
                            </Box>
                            <Box flexGrow={1} />
                            <Box mb={1}>
                                <Button
                                    // hidden={props.connection === 'online'}
                                    color="primary"
                                    onClick={showAddProductDialog}
                                    startIcon={<AddCircleOutline />}
                                >
                                    Stock
                                </Button>
                            </Box>
                        </Box>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"> Manufacture Date </TableCell>
                                    <TableCell align="center"> Batch Number </TableCell>
                                    <TableCell align="center"> Expiry Date </TableCell>
                                    <TableCell align="center"> Quantity </TableCell>
                                    <TableCell align="center"> Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    batchesReady ?
                                        batches.length > 0 ?
                                            batches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((data, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell > {data.manufacture_date} </TableCell>
                                                        <TableCell >{data.batch_no}</TableCell>
                                                        <TableCell >{data.expiry_date}</TableCell>
                                                        <TableCell >{data.quantity}</TableCell>
                                                        <TableCell align="center">
                                                            <AddCircleOutline
                                                                color="primary"
                                                                onClick={() => handleAddQtySpecId(data)}
                                                                className="pointer"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            : <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Typography variant="subtitle2" color="secondary"> No batch added </Typography>
                                                </TableCell>
                                            </TableRow>
                                        : <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                        <TablePagination
                            component={"div"}
                            rowsPerPageOptions={[5, 10, 25]}
                            colSpan={3}
                            count={batches.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                    : defaultView === 'add-stock-id' ?
                        <Fragment>
                            <Box mb={2}>
                                <Typography variant="h5" color="primary">
                                    {selectedProductName} - {selectedDescription}
                                </Typography>
                            </Box>
                            <Formik
                                initialValues={{
                                    user_id: user_id,
                                    token: token,
                                    username: username,
                                    management_id: management_id,
                                    pharmacy_id: pharmacy_id,
                                    manufacture: '',
                                    batch_no: '',
                                    expiry: '',
                                    invoice: '',
                                    qty: '',
                                    unit: selectedUnit,
                                    remarks: '',
                                    password: '',
                                }}
                                validationSchema={Yup.object().shape({
                                    batch_no: Yup.string().required(),
                                    expiry: Yup.string().required(),
                                    invoice: Yup.string().required(),
                                    qty: Yup.number().required(),
                                    remarks: Yup.string().required(),
                                    password: Yup.string().required()
                                })}
                                onSubmit={async (values, {
                                    setErrors,
                                    setSubmitting,
                                    resetForm
                                }) => {
                                    try {
                                        const request = await Axios.post('malita/pharmacy/add-batch-by-id', getFormData(values))
                                        if (request.data === 'pass-invalid') {
                                            setErrors({ password: "Password doesn't matched" });
                                            Notify.customToast("Invalid Password", "Password doesn't matched")
                                        }
                                        if (request.data === 'invoice-exist') {
                                            setErrors({ invoice: 'Invoice is already recorded' });
                                            Notify.customToast('Invoice Exist', 'Invoice is already recorded')
                                        }
                                        if (request.data === 'success') {
                                            Notify.successRequest('new product')
                                            fetchInventoryList();
                                            getBatchesByProductID();
                                            resetForm();
                                            setSubmitting(true);
                                            setDefaultView('table-view-batches');
                                        }
                                    } catch (error) {
                                        const message = error.message || 'Something went wrong';
                                        setErrors({ submit: message });
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                    resetForm
                                }) => (
                                    <form
                                        noValidate
                                        onSubmit={handleSubmit}
                                    >
                                        <Box flexGrow={1}>
                                            <Grid container spacing={2}>
                                                <Grid xs={4} item>
                                                    <Box mb={1}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="Manufacture Date"
                                                            error={Boolean(touched.manufacture && errors.manufacture)}
                                                            helperText={touched.manufacture && errors.manufacture}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.manufacture}
                                                            name="manufacture"
                                                            variant="outlined"
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid xs={4} item>
                                                    <Box mb={1}>
                                                        <TextField
                                                            error={Boolean(touched.batch_no && errors.batch_no)}
                                                            helperText={touched.batch_no && errors.batch_no}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            defaultValue={values.batch_no}
                                                            fullWidth
                                                            required
                                                            multiline
                                                            name="batch_no"
                                                            label="Batch Number"
                                                            variant="outlined"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid xs={4} item>
                                                    <Box mb={1}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="Expiry Date"
                                                            error={Boolean(touched.expiry && errors.expiry)}
                                                            helperText={touched.expiry && errors.expiry}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.expiry}
                                                            name="expiry"
                                                            variant="outlined"
                                                            type="date"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid xs={4} item>
                                                    <Box mb={1}>
                                                        <TextField
                                                            error={Boolean(touched.invoice && errors.invoice)}
                                                            helperText={touched.invoice && errors.invoice}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            defaultValue={values.invoice}
                                                            fullWidth
                                                            required
                                                            name="invoice"
                                                            label="Invoice Number"
                                                            variant="outlined"
                                                        />
                                                    </Box>
                                                </Grid>

                                                <Grid xs={4} item>
                                                    <Box mb={1}>
                                                        <TextField
                                                            error={Boolean(touched.qty && errors.qty)}
                                                            helperText={touched.qty && errors.qty}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            defaultValue={values.qty}
                                                            fullWidth
                                                            required
                                                            name="qty"
                                                            type="number"
                                                            label="Quantity"
                                                            variant="outlined"
                                                        />
                                                    </Box>
                                                </Grid>

                                                <Grid xs={4} item>
                                                    <Box mb={1}>
                                                        <TextField
                                                            InputProps={{ readOnly: true }}
                                                            error={Boolean(touched.unit && errors.unit)}
                                                            helperText={touched.unit && errors.unit}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            defaultValue={values.unit}
                                                            fullWidth
                                                            required
                                                            multiline
                                                            name="unit"
                                                            label="Unit"
                                                            variant="outlined"
                                                        />
                                                    </Box>
                                                </Grid>

                                                <Grid xs={12} item>
                                                    <Box mb={1}>
                                                        <TextField
                                                            error={Boolean(touched.remarks && errors.remarks)}
                                                            helperText={touched.remarks && errors.remarks}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            defaultValue={values.remarks}
                                                            fullWidth
                                                            required
                                                            multiline
                                                            name="remarks"
                                                            label="Remarks"
                                                            variant="outlined"
                                                        />
                                                    </Box>
                                                </Grid>

                                                <Grid xs={12} item>
                                                    <Box mb={1}>
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            error={Boolean(touched.password && errors.password)}
                                                            helperText={touched.password && errors.password}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.password}
                                                            name="password"
                                                            label="Enter your password"
                                                            variant="outlined"
                                                            type="password"
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {errors.submit && (
                                            <Box mt={2} >
                                                <FormHelperText error>
                                                    {errors.submit}
                                                </FormHelperText>
                                            </Box>
                                        )}

                                        <Box mt={2} mb={2} display="flex">
                                            <Box flexGrow={1} />

                                            <Button
                                                variant="contained"
                                                color="default"
                                                onClick={() => setDefaultView('table-view-batches')}
                                                startIcon={<ArrowBackIcon />}
                                            >
                                                Back
                                    </Button>

                                            <Box ml={2}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<CheckIcon />}
                                                    disabled={isSubmitting}
                                                >
                                                    Save
                                        </Button>
                                            </Box>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Fragment>
                        :
                        <Fragment>
                            <Box mb={2}>
                                <Typography variant="h5" color="primary">
                                    {selectedProductName} - {selectedDescription}
                                </Typography>
                            </Box>
                            <AddQtySpecificBatch selectedDataArray={selectedDataArray} fetchInventoryList={() => fetchInventoryList()} getBatchesByProductID={() => getBatchesByProductID()} backDefaultView={() => setDefaultView('table-view-batches')} />
                        </Fragment>
            }
        </Fragment>
    )

}

export default AddProductBatches;