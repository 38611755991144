import React, { Fragment, useState } from 'react';   
import BloodPressure from './BloodPressure';
import Temperature from './Temperature';
import Glucose from './Glucose';
import Pulse from './Pulse';
import Respiratory from './Respiratory';
import Uricacid from './Uricacid';
import Cholesterol from './Cholesterol';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFillDrip, faBurn, faFireAlt, faLungs, faHeartbeat, faTint, faTintSlash, faWeight } from '@fortawesome/free-solid-svg-icons';
import Weight from './Weight'; 

import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box' 
import TabPanel from '../../utils/TabPanel';
import makeStyles from '@material-ui/styles/makeStyles'
import Calcium from './Calcium'; 
import Chloride from './Chloride';
import Creatinine from './Creatinine';
import HDL from './HDL';
import LDL from './LDL';
import Lithium from './Lithium';
import Magnesium from './Magnesium';
import Potasium from './Potasium';
import Protein from './Protein';
import Sodium from './Sodium';
import BodyPain from './BodyPain';
import { Paper } from '@material-ui/core';

const useStyle = makeStyles((theme) =>({
    indicatorLeft: {
      left: "0px"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));
 

const Vitals = () =>{

    const classes = useStyle() 
    const [category, setCategory] = useState('pain'); 

    const handleChange = (event, newValue) => {
        event.persist()
        setCategory(newValue);
    };

    return (
        <Fragment>	  
            <Grid container spacing={2}>
                <Grid  
                    item
                    xs={9}
                    md={10}
                    xl={11}  
                >
                    <Box
                        component={Paper}
                        variant="outlined"
                        p={2}
                    >
                        <TabPanel value={ category } index={'pain'}> 
                            <BodyPain />
                        </TabPanel>  

                        <TabPanel value={ category } index={'temperature'}> 
                            <Temperature />
                        </TabPanel>

                        <TabPanel value={ category } index={'bloodpressure'}> 
                            <BloodPressure />
                        </TabPanel>

                        <TabPanel value={ category } index={'pulse'}> 
                            <Pulse />
                        </TabPanel>

                        <TabPanel value={ category } index={'respiratory'}> 
                            <Respiratory />
                        </TabPanel>

                        <TabPanel value={ category } index={'weight'}> 
                            <Weight />
                        </TabPanel>

                        <TabPanel value={ category } index={'glucose'}> 
                            <Glucose />
                        </TabPanel>

                        <TabPanel value={ category } index={'cholesterol'}> 
                            <Cholesterol />
                        </TabPanel>

                        <TabPanel value={ category } index={'uricacid'}> 
                            <Uricacid />
                        </TabPanel> 

                        <TabPanel value={ category } index={'calcium'}> 
                            <Calcium />
                        </TabPanel> 

                        <TabPanel value={ category } index={'chloride'}> 
                            <Chloride />
                        </TabPanel>

                        <TabPanel value={ category } index={'creatinine'}> 
                            <Creatinine />
                        </TabPanel>

                        <TabPanel value={ category } index={'hdl'}> 
                            <HDL />
                        </TabPanel>

                        <TabPanel value={ category } index={'ldl'}> 
                            <LDL />
                        </TabPanel>

                        <TabPanel value={ category } index={'lithuim'}> 
                            <Lithium />
                        </TabPanel>

                        <TabPanel value={ category } index={'magnesium'}> 
                            <Magnesium />
                        </TabPanel>

                        <TabPanel value={ category } index={'potasium'}> 
                            <Potasium />
                        </TabPanel>

                        <TabPanel value={ category } index={'protein'}> 
                            <Protein />
                        </TabPanel>

                        <TabPanel value={ category } index={'sodium'}> 
                            <Sodium />
                        </TabPanel>   
                    </Box>
                </Grid>

                <Grid 
                    item
                    xs={3}
                    md={2}
                    xl={1}  
                >
                    <Box
                        border={1}
                        borderColor="grey.200"
                        bgcolor="white" 
                    >
                        <Tabs  
                            orientation="vertical"
                            variant="scrollable"
                            value={ category }
                            onChange={ handleChange }
                            aria-label="Vertical tabs example" 
                            indicatorColor="primary"
                            textColor="primary"   
                            scrollButtons="on" 
                            classes = {{ indicator: classes.indicatorLeft }}
                            style={{ height: 'calc(90vh - 180px)' }}
                        >
                            <Tab 
                                wrapped
                                fullWidth
                                label="Pain" 
                                value="pain"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Temperature" 
                                value="temperature"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Blood Pressure" 
                                value="bloodpressure"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Pulse" 
                                value="pulse"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Respiratory" 
                                value="respiratory"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Weight" 
                                value="weight"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Glucose" 
                                value="glucose"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Cholesterol" 
                                value="cholesterol"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Uric Acid" 
                                value="uricacid"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Calcium" 
                                value="calcium"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Chloride" 
                                value="chloride"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Creatinine" 
                                value="creatinine"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="HDL" 
                                value="hdl"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="LDL" 
                                value="ldl"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Lithium" 
                                value="lithuim"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Magnesium" 
                                value="magnesium"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Potasium" 
                                value="potasium"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Protein" 
                                value="protein"
                            />

                            <Tab 
                                wrapped
                                fullWidth
                                label="Sodium" 
                                value="sodium"
                            />
                        </Tabs>
                    </Box>
                </Grid>
            </Grid> 
        </Fragment>
    )
}

export default Vitals; 