import React, { useEffect, useState } from 'react';
import TabPanel from 'src/utils/TabPanel';

import { Box, Tabs, Tab, Badge, Paper } from '@material-ui/core';
import Patients from './Patients'; 
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ComputerIcon from '@material-ui/icons/Computer';
import PublicIcon from '@material-ui/icons/Public';
import LocalAppointment from './local-appointment/'
import VirtualAppointment from './virtual-appointment/'
import Notify from 'src/notification/Notify';
import axios from 'axios';
import OnlineAppRequest from './snackbar/OnlineAppRequest';
import { EncoderContext } from 'src/ContextAPI';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import LabOrder from './billing/Billing';
import CheckInternet from 'src/utils/CheckInternet';
import NoInternet from 'src/NoInternet';

const Dashboard = () =>{ 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [category, setCategory] = React.useState('patient');

    const [onlineappReq, setOnlineappReq] = useState({
        data: [],
        ready: false
    })

    const [onlineCount, setonlineCount] = useState(0)
    const [localCount, setlocalCount] = useState(0)
    const [unpaidbill, setunpaidBill] = useState(0)
    const [unreadNotifCount, setUnreadNotfiCount] = useState(0)
    const [_online, set_online] = useState('checking')

    var interval = null;
    var countinterval = null;

    const updateContext = (tab) => {
        setCategory(tab);
    };

    const handleChange = (event, newcategory) => {
        setCategory(newcategory);
    }; 
    
    const getOnlineAppRequest = () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);         
        
        axios.post('encoder/appointment/online/getrequest-new', formdata )
        .then( (response) => { 
            const data = response.data;     
            setOnlineappReq({
                data: data,
                ready: true
            })  
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const getUnreadCountnotif  = () =>{
        var formdata = new FormData();
        formdata.set('token', token); 
        formdata.set('user_id', user_id);             
        
        axios.post('encoder/appointment/online/notification-unreadcount', formdata )
        .then( (response) =>{ 
            const data = response.data;     
            setUnreadNotfiCount(data.length) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const getOnlineAppointmentCount = () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);         
        
        axios.post('encoder/appointment/online/appcount', formdata )
        .then( (response) => { 
            const data = response.data;     
            setonlineCount(data.length)  
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const getLocalAppointmentCount = () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);         
        
        axios.post('encoder/appointment/local/appcount', formdata )
        .then( (response) => { 
            const data = response.data;     
            setlocalCount(data.length)  
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const getUnpaidBillCount = () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);         
        
        axios.post('encoder/billing/unpaid-count', formdata )
        .then( (response) => { 
            const data = response.data;     
            setunpaidBill(data.length)  
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const CheckConnection = () =>{
        
        countinterval = setInterval(() => {
            getLocalAppointmentCount()
            getUnpaidBillCount()
        }, 20000);

        if(navigator.onLine){
            CheckInternet.online().then(() =>{
                getOnlineAppRequest()
                getOnlineAppointmentCount()
                getUnreadCountnotif()
                interval = setInterval(() => {
                    getOnlineAppRequest()
                    getOnlineAppointmentCount()
                    getUnreadCountnotif()
                }, 20000);
            }).catch(() => {
                Notify.customToast('Virtual Not Available','No internet connection.')
                set_online('disconnected')
            })
            
        }else{ 
            clearInterval(countinterval)
            clearInterval(interval)
        }
        
    }
 

    useEffect(() =>{
        CheckConnection()

        return(() =>{ 
            clearInterval(countinterval)
            clearInterval(interval) 
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[_online])

    return(
        <EncoderContext.Provider
            value={{
                _category: category,
                _updateCategory: updateContext
            }}
        >
            <Box m={2}> 
                {/* snackbar */}
                { onlineappReq.ready && <OnlineAppRequest apprequest={ onlineappReq } getOnlineAppRequest = { getOnlineAppRequest } />} 

                <Box
                    component={Paper}
                    variant="outlined"
                >
                    <Tabs
                        value={category}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary" 
                        variant="scrollable" 
                        scrollButtons="on"
                    >
                        <Tab 
                            wrapped
                            value="patient" 
                            label={`Patients`} 
                            icon={ <PeopleAltIcon /> }
                        />
                        <Tab 
                            wrapped
                            value="local" 
                            label={`Local Appointment`} 
                            icon={ <Badge badgeContent={ localCount } color="error" > <ComputerIcon />  </Badge> }
                        />
                        <Tab  
                            wrapped
                            value="virtual" 
                            label={ `Virtual Appointment` } 
                            icon={ <Badge badgeContent={onlineCount + unreadNotifCount} color="error"> <PublicIcon />  </Badge> }
                        /> 

                        <Tab 
                            wrapped
                            value="billing" 
                            label={`Billing`} 
                            icon={<Badge badgeContent={ unpaidbill } color="error" >  <VerticalSplitIcon />  </Badge> }
                        /> 
                    </Tabs>
                </Box> 

                <Box mt={2}>
                    <TabPanel value={ category } index='patient'>
                        <Patients />
                    </TabPanel> 
                    <TabPanel value={ category } index='local'>
                        <LocalAppointment />
                    </TabPanel> 
                    <TabPanel value={ category } index='virtual'>
                        { _online === 'disconnected' ? ( <NoInternet /> ) : (  <VirtualAppointment /> )} 
                    </TabPanel>

                    <TabPanel value={ category } index='billing'>
                        <LabOrder />
                    </TabPanel>
                </Box>
            </Box>
        </EncoderContext.Provider>
    )
}

export default Dashboard;