import React, { Fragment , useEffect, useState} from 'react'; 
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Box, Typography, IconButton } from '@material-ui/core';
import Notify from '../../notification/Notify';
import Axios from 'axios';

function NotificationsDetails(props) {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id')) 

    const [notification, setnotification] = useState([])
    const [notificationrd, setnotificationrd] = useState(false)

    const getNotificationDetails = () =>{
        var formdata = new FormData();
        formdata.set('token', token); 
        formdata.set('user_id', user_id);        
        formdata.set('isread', props.notif_status);        
        formdata.set('notif_id', props.notif_id);    
        formdata.set('connection', props.connection);       
        
        Axios.post('doctor/appointment/virtual/notification-msg', formdata )
        .then( (response) =>{ 
            const data = response.data;     
            setnotification(data)
            setnotificationrd(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    useEffect(() => {
        getNotificationDetails()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.notif_id]);

    return (
        <Fragment>  
            <Box >    
                <Typography variant="body2" color="textSecondary">
                    <IconButton color="primary" onClick ={ props.resetNotificationDisplay}>
                        <ArrowBackIcon fontSize="small" />
                    </IconButton> 
                    { props.notif_patient }    
                </Typography>    
            </Box>

            {
                 notificationrd ? 
                    notification.length > 0 ?
                        <Box>
                            <span className="text-dark">
                                { notification[0].notification_msg }
                            </span>   
                            <br />
                            <br />
                            <span className="text-muted small float-right"> { Notify.dateTimeConvert(notification[0].created_at) }  </span> 
                        </Box>
                    : Notify.noRecord()
                 : Notify.loading()
            }

        </Fragment>
    )
}

export default NotificationsDetails;
