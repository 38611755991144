import { Typography, Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Notify from 'src/notification/Notify';
import axios from 'axios'; 
import Label from 'src/utils/Label'; 
import { grey } from '@material-ui/core/colors'; 

const FecalOrder = ({ order_id , patient_id }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [details, setDetails] = useState(null)

    const getpaidLaboratoryOrderDetails = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id); 
        formdata.set('patient_id', patient_id);    
        formdata.set('order_id', order_id);     
        formdata.set('table', 'laboratory_fecal_analysis');     
        formdata.set('connection', 'local');
        
        axios.post('doctor/patient/laboratory/order/paid-detailsbytable', formdata )
        .then((res) =>{
            const data = res.data
            setDetails(data)
        }).catch((er) => Notify.requestError(er))
    }
    
    const checkResult = (order) => {
        return order === null ? <Label color="error"> no result </Label> : <Label color="success"> {order} </Label>;
    }

    useEffect(() =>{

        getpaidLaboratoryOrderDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order_id, patient_id]) 

    return(
        <>  
            { details && Object.keys(details).length > 0 && (
                
                <Box border={1} borderColor={grey[300]} borderRadius={4} my={2} p={2}> 
                    { Boolean(parseInt(details.fecal_analysis)) && (
                        parseInt(details.fecal_analysis) === 2 ? 
                            <Box display={'flex'} mt={1}>
                                <Box>
                                    <Label color="primary"> FECAL ANALALYSIS ORDER   </Label>
                                </Box>
                                <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                    <Label color="warning"> refund </Label>
                                </Box>
                            </Box>
                        :
                        <>
                            <Box>
                                <Label color="primary"> FECAL ANALALYSIS ORDER   </Label>
                            </Box>
                            
                            <Box>    
                                <Box mt={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6}>
                                            <>
                                                <Box>
                                                    <Typography variant="caption"> <b> CELLULAR ELEMENTS </b> </Typography>
                                                </Box>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}> 

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption">  COLOR:  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                { checkResult(details.cellular_elements_color) } 
                                                            </Box>
                                                        </Box>  

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption">  CONSISTENCY:  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                { checkResult(details.cellular_elements_consistency) } 
                                                            </Box>
                                                        </Box>   
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption">  PUS:  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                { checkResult(details.cellular_elements_pus) } 
                                                            </Box>
                                                            <Typography variant="caption"> /HPF  </Typography>  
                                                        </Box>   
                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption">  RBC:  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                { checkResult(details.cellular_elements_rbc) }
                                                            </Box>
                                                            <Typography variant="caption"> /HPF  </Typography>  
                                                        </Box>    
                                                    </Grid>
                                                </Grid>
                                            </> 
                                        </Grid>
                                        <Grid item  xs={6} sm={6}>
                                            <Grid container >
                                                <Grid item xs={3} sm={3} />
                                                <Grid item xs={9} sm={9}>
                                                    <Box>
                                                        <Box>
                                                            <Typography variant="caption"> <b> OTHERS </b> </Typography>
                                                        </Box>

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption">  FAT GLOBULES:  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                { checkResult(details.cellular_elements_fat_globules) } 
                                                            </Box>
                                                        </Box>  

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption">  OCCULT BLOOD::  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                { checkResult(details.cellular_elements_occultblood) } 
                                                            </Box>
                                                        </Box>  

                                                        <Box mt={1} display="flex">
                                                            <Typography variant="caption">  BACTERIA:  </Typography> 
                                                            <Box mx={2} align="center" minWidth={100} borderBottom={1}>
                                                                { checkResult(details.cellular_elements_bacteria) } 
                                                            </Box>
                                                        </Box>    
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Box mt={1}>
                                        <Typography variant="caption">  <b> RESULT:  </b> </Typography> 
                                        <Box mx={2} minWidth={100}>
                                            <Typography variant="subtitle2"> { checkResult(details.cellular_elements_result) } </Typography>
                                        </Box>
                                    </Box>        
                                </Box>   
                            </Box>
                        </> 
                    )}
                </Box>
            )}
        </>
    )
}

export default FecalOrder;