import React, { Component, Fragment } from 'react';  
import axios from 'axios';    
import Notify from '../../notification/Notify'; 
import { Col, FormGroup, Row, Form, FormControl, InputGroup } from 'react-bootstrap';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-sidebar-v2/js/leaflet-sidebar.min.js';
import 'leaflet-sidebar-v2/css/leaflet-sidebar.min.css';
import 'react-leaflet-sidebarv2';
import 'leaflet-sidebar-v2';
import 'font-awesome/css/font-awesome.min.css';
import './GTCMap.css';
import { Link } from "react-router-dom";
import Sarangani from './coordinate/Sarangani';
import SantaMaria from './coordinate/SantaMaria';
import Malita from './coordinate/Malita';
import JoseAbadSantos from './coordinate/JoseAbadSantos';
import DonMarcelino from './coordinate/DonMarcelino';

export default class GTCMap extends Component{ 
    _isMounted = false;
    prevLayerClicked = null; 
    constructor(props) {
        super(props);

		this.state = {
			_user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            //response data
            _municipal: [], 
            _barangay: [],
            _speficbarangay: [],

            //disable click on select
            defaultCity: false,
            defaultMunicipal: false,
            defaultBarangay: false,
            display_barangay: false,

            //use for onchange
            city: 'Davao Occidental',
            municipality: 0,
            barangay: 0,
            illness:0,

            //map state
            map : '',

            //storage of red, blue, yellow dots on the map
            layer: [],
            feature: [],

            //radius
            defaultradius: 500,

            //monitoring count
            total_male_infancy: 0,
            total_female_infancy: 0,
            total_male_child: 0,
            total_female_child: 0,
            total_male_legal: 0,
            total_female_legal: 0,
            total_male_middle: 0,
            total_female_middle: 0,
            total_city_pop: 0,

            total_covid19_Positive: 0,
            total_covid19_PUM: 0,
            total_covid19_PUI: 0,

            // another storage of red, blue, yellow dots on the map
            _features: [],
            _result: [],

            //display convid count if the illness selected
            getIllnessGetClicked: false,
        };
        this.setMaponEachFeatureDvoOcci = this.setMaponEachFeatureDvoOcci.bind(this);
    }

    //okay
    getMunicipal(){
        var formData = new FormData();
            formData.append('user_id', this.state._user_id);
            formData.append('token', this.state._token);
            formData.append('city', this.state.city);

		axios.post('gtcmap/municipality/list-municipality', formData)
		.then(function (response){       
            if(this._isMounted){
				if(response.data.length > 0){
					if(response.data==='user-not-allowed'){
						Notify.usernotallowed('Display Barangay');
                    }
                    else{
                        this.setState({
                            _municipal: response.data,
                            defaultBarangay: true,
                            defaultIllness: true,
                            defaultMunicipal: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop,
                        });
                    }
				}
			}
        }.bind(this))
        .catch(error => {
            console.log(error)
            Notify.requestError(error);
        })
    }
    //okay
    getBarangay(e){
        e.preventDefault();
        e.persist();

        var formData = new FormData();
            formData.append('user_id', this.state.user_id);
            formData.append('token', this.state.token);
            formData.append('city', this.state.city);
            formData.append('municipality', e.target.value)

        this.setState({
            barangay: 0,
            illness: 0,
            municipality: e.target.value,
            getIllnessGetClicked: false,
        });

		axios.post('gtcmap/barangay/list-barangay', formData)
		.then(function (response){       
            if(this._isMounted){
                this.setState({
                    _barangay: [],
                    total_male_infancy: 0,
                    total_female_infancy: 0,
                    total_male_child: 0,
                    total_female_child: 0,
                    total_male_legal: 0,
                    total_female_legal: 0,
                    total_male_middle: 0,
                    total_female_middle: 0,
                    total_city_pop: 0
                });
				if(response.data.length > 0){
                    this.clearMap();
                    var thatthis = this; 
					if(response.data==='user-not-allowed'){
                        Notify.usernotallowed('Display Barangay');
                    }
                    if(this.state.municipality === 'Don Marcelino'){
                        this.setState({
                            display_barangay: true,
                            _barangay: response.data,
                            defaultBarangay: true,
                            defaultIllness: true,
                            defaultMunicipal: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop
                        });
                        document.getElementById('illness').selectedIndex = 0;
                        document.getElementById('barangayList').selectedIndex = 0;

                        function onEachFeatureDvoOcci(feature, layer){
                            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
                        }

                        new L.GeoJSON(DonMarcelino.dvoOccidentalDonMarcelino(),{
                            style: thatthis.setMapStyle(5),
                            onEachFeature: onEachFeatureDvoOcci
                        }).setZIndex(1).addTo(thatthis.state.map);

                        thatthis.state.map.setView(new L.LatLng(6.1456, 125.6314), 11);
                    }
                    if(this.state.municipality === 'Jose Abad Santos'){
                        this.setState({
                            display_barangay: true,
                            _barangay: response.data,
                            defaultBarangay: true,
                            defaultIllness: true,
                            defaultMunicipal: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop
                        });
                        document.getElementById('illness').selectedIndex = 0;
                        document.getElementById('barangayList').selectedIndex = 0;

                        function onEachFeatureDvoOcci(feature, layer){
                            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
                        }

                        new L.GeoJSON(JoseAbadSantos.dvoOccidentalJoseAbadSantos(),{
                            style: thatthis.setMapStyle(4),
                            onEachFeature: onEachFeatureDvoOcci
                        }).setZIndex(1).addTo(thatthis.state.map);

                        thatthis.state.map.setView(new L.LatLng(5.8341, 125.5439), 10);
                    }
                    if(this.state.municipality === 'Malita'){
                        this.setState({
                            display_barangay: true,
                            _barangay: response.data,
                            defaultBarangay: true,
                            defaultIllness: true,
                            defaultMunicipal: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop
                        });
                        document.getElementById('illness').selectedIndex = 0;
                        document.getElementById('barangayList').selectedIndex = 0;

                        function onEachFeatureDvoOcci(feature, layer){
                            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
                        }

                        new L.GeoJSON(Malita.dvoOccidentalMalita(),{
                            style: thatthis.setMapStyle(3),
                            onEachFeature: onEachFeatureDvoOcci
                        }).setZIndex(1).addTo(thatthis.state.map);

                        thatthis.state.map.setView(new L.LatLng(6.3843, 125.5816), 10);
                    }
                    if(this.state.municipality === 'Santa Maria'){
                        this.setState({
                            display_barangay: true,
                            _barangay: response.data,
                            defaultBarangay: true,
                            defaultIllness: true,
                            defaultMunicipal: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop
                        });
                        document.getElementById('illness').selectedIndex = 0;
                        document.getElementById('barangayList').selectedIndex = 0;

                        function onEachFeatureDvoOcci(feature, layer){
                            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
                        }

                        new L.GeoJSON(SantaMaria.dvoOccidentalSantaMaria(),{
                            style: thatthis.setMapStyle(2),
                            onEachFeature: onEachFeatureDvoOcci
                        }).setZIndex(1).addTo(thatthis.state.map);

                        thatthis.state.map.setView(new L.LatLng(6.5152, 125.4563), 11);
                    }
                    if(this.state.municipality === 'Sarangani'){
                        this.setState({
                            display_barangay: true,
                            _barangay: response.data,
                            defaultBarangay: true,
                            defaultIllness: true,
                            defaultMunicipal: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop
                        });
                        document.getElementById('illness').selectedIndex = 0;
                        document.getElementById('barangayList').selectedIndex = 0;

                        function onEachFeatureDvoOcci(feature, layer){
                            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
                        }

                        new L.GeoJSON(Sarangani.dvoOccidentalSarangani(),{
                            style: thatthis.setMapStyle(2),
                            onEachFeature: onEachFeatureDvoOcci
                        }).setZIndex(1).addTo(thatthis.state.map);

                        thatthis.state.map.setView(new L.LatLng(5.4188, 125.4563), 12);
                    }
				}
			}
        }.bind(this))
        .catch(error => {
            console.log(error)
            Notify.requestError('Display Barangay');
        })
    }
    //okay
    getSpecBarangay(e){
        e.preventDefault();
        e.persist();

        var formData = new FormData();
            formData.append('user_id', this.state.user_id);
            formData.append('token', this.state.token);
            formData.append('city', this.state.city);
            formData.append('municipality', this.state.municipality);
            formData.append('barangay', e.target.value);

        this.setState({
            illness: 0,
            barangay: e.target.value,
            getIllnessGetClicked: false,
        });

		axios.post('gtcmap/barangay/specific-barangay', formData)
		.then(function (response){       
            if(this._isMounted){
                this.setState({
                    _speficbarangay: [],
                    total_male_infancy: 0,
                    total_female_infancy: 0,
                    total_male_child: 0,
                    total_female_child: 0,
                    total_male_legal: 0,
                    total_female_legal: 0,
                    total_male_middle: 0,
                    total_female_middle: 0,
                    total_city_pop: 0
                });
				if(response.data.length > 0){
                    this.clearMap();
                    var thatthis = this; 
					if(response.data==='user-not-allowed'){
						Notify.usernotallowed('Display Specific Barangay');
                    }
                    if(this.state.municipality === 'Don Marcelino'){
                        this.setState({
                            _speficbarangay: response.data,
                            defaultIllness: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop,
                        })
                        document.getElementById('illness').selectedIndex = 0;

                        function onEachFeatureDvoOcci(feature, layer){
                            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
                        }

                        new L.GeoJSON(DonMarcelino.dvoOccidentalDonMarcelino(),{
                            filter: function(feature, layer){
                                if(feature.properties.barangay === thatthis.state.barangay){
                                    return feature.id;
                                }
                            },
                            style: thatthis.setMapStyle(5),
                            onEachFeature: onEachFeatureDvoOcci
                        }).setZIndex(1).addTo(thatthis.state.map);

                        thatthis.state.map.setView(new L.LatLng(6.1456, 125.6314), 11);
                    }
                    if(this.state.municipality === 'Jose Abad Santos'){
                        this.setState({
                            _speficbarangay: response.data,
                            defaultIllness: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop,
                        });
                        document.getElementById('illness').selectedIndex = 0;

                        function onEachFeatureDvoOcci(feature, layer){
                            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
                        }

                        new L.GeoJSON(JoseAbadSantos.dvoOccidentalJoseAbadSantos(),{
                            filter: function(feature, layer){
                                if(feature.properties.barangay === thatthis.state.barangay){
                                    return feature.id;
                                }
                            },
                            style: thatthis.setMapStyle(4),
                            onEachFeature: onEachFeatureDvoOcci
                        }).setZIndex(1).addTo(thatthis.state.map);

                        thatthis.state.map.setView(new L.LatLng(5.8341, 125.5439), 10);
                    }
                    if(this.state.municipality === 'Malita'){
                        this.setState({
                            _speficbarangay: response.data,
                            defaultIllness: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop,
                        })
                        document.getElementById('illness').selectedIndex = 0;

                        function onEachFeatureDvoOcci(feature, layer){
                            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
                        }

                        new L.GeoJSON(Malita.dvoOccidentalMalita(),{
                            filter: function(feature, layer){
                                if(feature.properties.barangay === thatthis.state.barangay){
                                    return feature.id;
                                }
                            },
                            style: thatthis.setMapStyle(3),
                            onEachFeature: onEachFeatureDvoOcci
                        }).setZIndex(1).addTo(thatthis.state.map);

                        thatthis.state.map.setView(new L.LatLng(6.3843, 125.5816), 10);
                    }
                    if(this.state.municipality === 'Santa Maria'){
                        this.setState({
                            _speficbarangay: response.data,
                            defaultIllness: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop,
                        })
                        document.getElementById('illness').selectedIndex = 0;

                        function onEachFeatureDvoOcci(feature, layer){
                            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
                        }

                        new L.GeoJSON(SantaMaria.dvoOccidentalSantaMaria(),{
                            filter: function(feature, layer){
                                if(feature.properties.barangay === thatthis.state.barangay){
                                    return feature.id;
                                }
                            },
                            style: thatthis.setMapStyle(2),
                            onEachFeature: onEachFeatureDvoOcci
                        }).setZIndex(1).addTo(thatthis.state.map);

                        thatthis.state.map.setView(new L.LatLng(6.5152, 125.4563), 11);
                    }
                    if(this.state.municipality === 'Sarangani'){
                        this.setState({
                            _speficbarangay: response.data,
                            defaultIllness: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop,
                        })
                        document.getElementById('illness').selectedIndex = 0;

                        function onEachFeatureDvoOcci(feature, layer){
                            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
                        }

                        new L.GeoJSON(Sarangani.dvoOccidentalSarangani(),{
                            filter: function(feature, layer){
                                if(feature.properties.barangay === thatthis.state.barangay){
                                    return feature.id;
                                }
                            },
                            style: thatthis.setMapStyle(2),
                            onEachFeature: onEachFeatureDvoOcci
                        }).setZIndex(1).addTo(thatthis.state.map);

                        thatthis.state.map.setView(new L.LatLng(5.4188, 125.4563), 12);
                    }
                    else{
                        this.setState({
                            _speficbarangay: response.data,
                            defaultIllness: true,
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop,
                        })
                        document.getElementById('illness').selectedIndex = 0;
                    }
				}
			}
        }.bind(this))
        .catch(error => {
            console.log(error)
            Notify.requestError('Display Specific Barangay');
        })
    }
    //okay
    getIllness(e){
        e.preventDefault();
        e.persist();
        this.setState({
            total_male_infancy: 0,
            total_female_infancy: 0,
            total_male_child: 0,
            total_female_child: 0,
            total_male_legal: 0,
            total_female_legal: 0,
            total_male_middle: 0,
            total_female_middle: 0,
            total_city_pop: 0,
            illness: e.target.value,

            total_covid19_Positive: 0,
            total_covid19_PUI: 0,
            total_covid19_PUM: 0,            
        })
        var formData = new FormData();
            formData.append('user_id', this.state.user_id);
            formData.append('token', this.state.token);
            formData.append('city', this.state.city);
            formData.append('municipality', this.state.municipality);
            formData.append('barangay', this.state.barangay);
            formData.append('illness', e.target.value);

		axios.post('gtcmap/illness/illed-patient', formData)
		.then(function (response){
            if(this._isMounted){
				if(response.data.length > 0){
					if(response.data==='user-not-allowed'){
						Notify.usernotallowed('Display Specific Illness');
                    }
                    else{
                        // this.setMarkerOnPositivePatientsDemo(response.data, this.state.defaultradius);
                        this.setState({
                            total_male_infancy: response.data[0].total_male_infancy,
                            total_female_infancy: response.data[0].total_female_infancy,
                            total_male_child: response.data[0].total_male_child,
                            total_female_child: response.data[0].total_female_child,
                            total_male_legal: response.data[0].total_male_legal,
                            total_female_legal: response.data[0].total_female_legal,
                            total_male_middle: response.data[0].total_male_middle,
                            total_female_middle: response.data[0].total_female_middle,
                            total_city_pop: response.data[0].total_city_pop,
                            total_covid19_Positive: response.data[0].total_covid19_Positive,
                            total_covid19_PUI: response.data[0].total_covid19_PUI,
                            total_covid19_PUM: response.data[0].total_covid19_PUM,
                            getIllnessGetClicked: true,
                        })   
                    }
				}
			}
        }.bind(this))
        .catch(error => {
            console.log(error)
            Notify.requestError('Display Specific Illness');
        })
    }


    changeRadius(e){
        this.setState({
            defaultradius: e.target.value
        });
        this.clearMap();

        this.state.illness === 'COVID-19' ? 
            this.setMarkerOnPositivePatientsDemo(this.state._result, e.target.value)

        : this.state.illness === 'COVID-19-PUM' ?
            this.setMarkerOnPUMPatientsDemo(this.state._result, e.target.value)
            
        : this.setMarkerOnPUIPatientsDemo(this.state._result, e.target.value)
    }

    getColor(population) {
        return  population > 79  ? '#bd0026' :
                population > 59  ? '#f03b20' :
                population > 39  ? '#fd8d3c' :
                population > 19  ? '#fed976' :			
                                   '#ffffb2' ; 
    }
    getColor2(e) {
        return  e > 80  ? '#696969' :
                e > 60  ? '#808080' :
                e > 40  ? '#A9A9A9' :
                e > 20  ? '#C0C0C0' :			
                          '#DCDCDC' ; 
    }
    getColor3(color) {
        return color === 0 ? '#fff' :
               color === 1 ? 'pink' :
               color === 2 ? '#c3e4ed' :
               color === 3 ? '#98fb98' :
               color === 4 ? '#ffff99' :
               color === 5 ? '#dda0dd' :
                             '#000' ; 
    }


    //L.control
    setLControlIcons(){
        var thatthis = this; 

        var sidebar = L.control.sidebar({
            closeButton: true,
            position: 'left',
            autopan: false
        });
        thatthis.state.map.addControl(sidebar);

        //Zoom In Sidebar
        sidebar.addPanel({
            id: 'zoomIn',
            tab: '<i class="fa fa-plus"></i>',
            title: 'Zoom In',
            button: function() { thatthis.state.map.setZoom(thatthis.state.map.getZoom() + 1)},
        });

        //Zoom Out Sidebar
        sidebar.addPanel({
            id: 'zoomOut',
            tab: '<i class="fa fa-minus"></i>',
            title: 'Zoom Out',
            button: function() { thatthis.state.map.setZoom(thatthis.state.map.getZoom() - 1) }
        });

        //Menu Sidebar
        var panelContent = {
            id: 'menu',
            tab: '<i class="fa fa-bars"></i>',
            pane: document.getElementById('menu_tab'),
            title: 'Menu',
            // position: 'bottom'
        };
        sidebar.addPanel(panelContent);
    }
    //L.legend Monitoring
    setLegendMonitoring(){
        var thatthis = this; 
        var legend = L.control({ position: "bottomright" });
        legend.onAdd = function(map) {
            var div = L.DomUtil.create("div", "info legend"),
                grades = [0, 20, 40, 60, 80],
                labels = ['<b>Monitoring</b>'],
                from,
                to;
            for (var i = 0; i < grades.length; i++) {
                from = grades[i];
                to = grades[i + 1];
                labels.push(
                    '<i style="background:' + thatthis.getColor(from + 1) + '"></i>' + from + '%' + (to ? "&ndash;" + (to - 1) + '%' : "–100%")
                );
        }
        div.innerHTML = labels.join("<br>");
        return div;
        };
        legend.addTo(this.state.map);
    }
    //Style
    setMapStyle(color){
        var that = this;
        return {
            weight: 2,
            opacity: 1,
            color: "#A9A9A9",
            dashArray: "3",
            fillOpacity: 0.5,
            fillColor: that.getColor3(color)
        };
    }
    //onEachFeature
    setMaponEachFeatureDvoOcci(feature, layer){
        var that = this;
        var popupOptions = {maxWidth: 170};
        layer.bindPopup("<b>Barangay: </b>"+feature.properties.barangay+"<br><b>Municipality: </b>"+feature.properties.municipality+"<br><b>Province: </b>"+feature.properties.city, popupOptions)
        layer.on({ 
            click: function(e){
                if (that.prevLayerClicked !== null) {
                    //========> Reset style <========
                    that.prevLayerClicked.setStyle({
                        weight: 1,
                        color: "#A9A9A9",
                        dashArray: "",
                        fillOpacity: 0.5
                    });
                }
                that.state.map.fitBounds(e.target.getBounds());
                console.log(e.target);
                var layer = e.target;
                layer.setStyle({
                    weight: 4,
                    color: '#666',
                    dashArray: '',
                    fillOpacity: 1
                });
                if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                    layer.bringToFront();
                }
                that.prevLayerClicked = layer;
            }
        });
    }
    setMarkerOnPositivePatientsDemo(result, radiuspositivevalue){
        var that = this;
        var popupOptions = {width: 170}; 

        this.setState({
            _result: result
        }); 
    
        for(let x = 0; x < result.length ; x ++){    
            var patientRadius = new L.Circle([result[x].latitude, result[x].longitude], {
                color: 'red',
                fillColor: 'red',
                fillOpacity: 0.1,
                radius: radiuspositivevalue
            }).addTo(that.state.map);
            patientRadius.bringToBack()

            var patientInfo = new L.Circle([result[x].latitude, result[x].longitude], {
                color: 'red',
                fillColor: '#f03',
                fillOpacity: 1,
                radius: 1,
                className: 'frame-circle',
                zIndexOffset: 99999999
            }).addTo(that.state.map);
            patientInfo.bringToFront()
                
            patientInfo.bindPopup("<b>Patient: </b>" + result[x].firstname + ' ' + result[x].lastname + "<br/>" + result[x].street + "<br/><b>Barangay: </b>" + result[x].barangay + "<br/><b>City: </b>" + result[x].city, popupOptions);
            
            patientInfo.on('click', function(){
                this.openPopup();
            }) 
        }
    }
    setMarkerOnPUMPatientsDemo(result, radiuspumvalue){
        var that = this;
        var popupOptions = {width: 170}; 

        this.setState({
            _result: result
        }); 
    
        for(let x = 0; x < result.length ; x ++){    
            var patientRadius = new L.Circle([result[x].latitude, result[x].longitude], {
                color: 'blue',
                fillColor: 'blue',
                fillOpacity: 0.1,
                radius: radiuspumvalue
            }).bindTooltip("test", {
                sticky: true, className: 'markerPUM', direction: 'top'
            }).addTo(that.state.map);
            patientRadius.bringToBack()

            var patientInfo = new L.Circle([result[x].latitude, result[x].longitude], {
                color: 'blue',
                fillColor: '#f03',
                fillOpacity: 1,
                radius: 1,
                className: 'frame-circle',
                zIndexOffset: 99999999
            }).bindTooltip("test", {
                sticky: true, className: 'markerPUM', direction: 'top'
            }).addTo(that.state.map);
            patientInfo.bringToFront()
                
            patientInfo.bindPopup("<b>Patient: </b>" + result[x].firstname + ' ' + result[x].lastname + "<br/>" + result[x].street + "<br/><b>Barangay: </b>" + result[x].barangay + "<br/><b>City: </b>" + result[x].city, popupOptions);
            
            patientInfo.on('click', function(){
                this.openPopup();
            }) 
        }
    }
    setMarkerOnPUIPatientsDemo(result, radiuspumvalue){
        var that = this;
        var popupOptions = {width: 170}; 

        this.setState({
            _result: result
        }); 
    
        for(let x = 0; x < result.length ; x ++){    
            var patientRadius = new L.Circle([result[x].latitude, result[x].longitude], {
                color: 'yellow',
                fillColor: 'yellow',
                fillOpacity: 0.1,
                radius: radiuspumvalue
            }).addTo(that.state.map);
            patientRadius.bringToBack()

            var patientInfo = new L.Circle([result[x].latitude, result[x].longitude], {
                color: 'yellow',
                fillColor: '#f03',
                fillOpacity: 1,
                radius: 1,
                className: 'frame-circle',
                zIndexOffset: 99999999
            }).addTo(that.state.map);
            patientInfo.bringToFront()
                
            patientInfo.bindPopup("<b>Patient: </b>" + result[x].firstname + ' ' + result[x].lastname + "<br/>" + result[x].street + "<br/><b>Barangay: </b>" + result[x].barangay + "<br/><b>City: </b>" + result[x].city, popupOptions);
        
            patientInfo.on('click', function(){
                this.openPopup();
            }) 
        }
    }


    clearMap() {
        var that = this;
        for(let i in that.state.map._layers) {
            if(that.state.map._layers[i]._path !== undefined) {
                try {
                    that.state.map.removeLayer(that.state.map._layers[i]);
                }
                catch(e) {
                    console.log("problem with " + e + that.state.map._layers[i]);
                }
            }
        }
    }
    renderMap(){  
        var thatthis = this; 
        // L.tileLayer("https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiamhvbWJhbHoiLCJhIjoiY2tmZHBoMGp6MWtyMTM2bzU4am03eWp6cSJ9.J_8Q-Qci62ZOMc2BBVKxPQ", {
        //     maxZoom: 18,
        //     minZoom: 6,
        //     crossOrigin: true,
        //     id: 'mapbox.streets'
        // }).addTo(thatthis.state.map);

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
            tileSize: 512,
            maxZoom: 18,
            zoomOffset: -1,
            id: 'mapbox/streets-v11',
            accessToken: 'pk.eyJ1IjoiamhvbWJhbHoiLCJhIjoiY2tmZHBoMGp6MWtyMTM2bzU4am03eWp6cSJ9.J_8Q-Qci62ZOMc2BBVKxPQ'
        }).addTo(thatthis.state.map);

        if( ! navigator.onLine ){
            new L.ImageOverlay('/occidental.jpeg', [[6.6386612, 125.168218], [5.537291, 125.759770]]).addTo(thatthis.state.map);
            new L.ImageOverlay('/sarangani.jpeg', [[5.528482, 125.317883], [5.354823, 125.512490]]).addTo(thatthis.state.map);
        } 
 
        function onEachFeatureDvoOcci(feature, layer){
            thatthis.setMaponEachFeatureDvoOcci(feature, layer);
        }

        new L.GeoJSON(Sarangani.dvoOccidentalSarangani(),{
            style: this.setMapStyle(1),
            onEachFeature: onEachFeatureDvoOcci
        }).setZIndex(1).addTo(this.state.map);

        new L.GeoJSON(SantaMaria.dvoOccidentalSantaMaria(),{
            style: this.setMapStyle(2),
            onEachFeature: onEachFeatureDvoOcci
        }).setZIndex(1).addTo(this.state.map);

        new L.GeoJSON(Malita.dvoOccidentalMalita(),{
            style: this.setMapStyle(3),
            onEachFeature: onEachFeatureDvoOcci
        }).setZIndex(1).addTo(this.state.map);

        new L.GeoJSON(JoseAbadSantos.dvoOccidentalJoseAbadSantos(),{
            style: this.setMapStyle(4),
            onEachFeature: onEachFeatureDvoOcci
        }).setZIndex(1).addTo(this.state.map);

        new L.GeoJSON(DonMarcelino.dvoOccidentalDonMarcelino(),{
            style: this.setMapStyle(5),
            onEachFeature: onEachFeatureDvoOcci
        }).setZIndex(1).addTo(this.state.map);

        // new L.GeoJSON(DavaoOccidental.dvoOccidental(),{
        //     style: this.setMapStyle(0),
        //     onEachFeature: onEachFeatureDvoOcci
        // }).setZIndex(1).addTo(this.state.map);

        // this.setSamplePatientsRedDot(GraceparkSubdivision.graceparkCoordinate())
    }


    componentDidMount(){
        this._isMounted = true;
        this.setState({
            map: L.map('mis-map', {zoomControl:false}).setView([6.0941, 125.6095], 10),
        });
        this.getMunicipal();
        setTimeout(() => {
            this.renderMap();
            this.setLControlIcons();
            // this.setLegendMonitoring();
        }, 1000);
    }
	componentWillUnmount(){
		this._isMounted = false;
    }
    render(){
        return(
			<div className="col-sm-12">
                <div className="row" style={{ flexWrap: 'nowrap'}}>
                    <div id="mis-map"></div>
                </div>

                <div className="leaflet-sidebar-content">
                    <div className="leaflet-sidebar-pane leaflet-sidebar-scroll" id="menu_tab">
                        <h1 className="leaflet-sidebar-header">
                            <div className="leaflet-sidebar-close"><i className="fa fa-caret-left"></i></div>
                            <span className="ml-3"> Menu </span>
                        </h1>
                        <Form id="sidebarDisplay" className="pr-2" >
                            <div id="city" className="mt-1">
                                <span className="text-muted text-uppercase gtc-small"> Province </span>
                                <FormControl name="cityList" id="cityList" defaultValue={this.state.city} readOnly/>

                                <span className="text-muted text-uppercase gtc-small"> Municipality </span>
                                <select className="form-control mb-1" id="municipalList" name="municipalList" onChange={this.getBarangay.bind(this)}>
                                    <option value='' disabled={this.state.defaultMunicipal === true ? true : false}>SELECT MUNICIPAL</option>
                                {
                                    this.state._municipal.length > 0 ?
                                        this.state._municipal.map(function(data, index){
                                            return (
                                                <option key={index} value={data.municipality}>
                                                    {data.municipality}
                                                </option>
                                            )
                                        })
                                    : <option value=''>NO MUNICIPAL FOUND</option>
                                }
                                </select>
                                <span className="text-muted text-uppercase gtc-small"> Barangay </span>
                                <select className="form-control mb-1" id="barangayList" defaultValue={this.state.defaultBarangay} name="barangayList" onChange={this.getSpecBarangay.bind(this)}>
                                    <option value='' disabled={this.state.defaultBarangay === true ? true : false}>SELECT BARANGAY</option>
                                {
                                    this.state._barangay.length > 0 ?
                                        this.state._barangay.map(function(data, index){
                                            return (
                                                <option key={index} value={data.barangay}>
                                                    {data.barangay}
                                                </option>
                                            )
                                        })
                                    : <option value=''>SELECT MUNICIPAL FIRST</option>
                                }
                                </select>

                                <div className="barangayANdResultContainer">
                                    <span className="text-muted text-uppercase gtc-small"> Illness </span>
                                    <select className="form-control mb-1" name="illness" id="illness" defaultValue={this.state.defaultIllness} onChange={this.getIllness.bind(this)}>
                                        <option value=""  disabled={this.state.defaultIllness === true ? true : false}>SELECT ILLNESS</option>
                                        {/* <option value="Dengue">Dengue</option> */}
                                        <option value="COVID-19">COVID-19</option>
                                    </select>

                                    <div id="cityBarangayInfo">
                                        <span className="text-muted text-uppercase gtc-small"> 0-9 years old (male) </span>
                                        <input className="form-control mb-1" id="0_9male" value={this.state.total_male_infancy} readOnly/>
                                        <span className="text-muted text-uppercase gtc-small"> 0-9 years old (female) </span>
                                        <input className="form-control mb-1" id="0_9female" value={this.state.total_female_infancy} readOnly/>

                                        <span className="text-muted text-uppercase gtc-small"> 10-17 years old (male) </span>
                                        <input className="form-control mb-1" id="10_17male" value={this.state.total_male_child} readOnly/>
                                        <span className="text-muted text-uppercase gtc-small"> 10-17 years old (female) </span>
                                        <input className="form-control mb-1" id="10_17female" value={this.state.total_female_child} readOnly/>

                                        <span className="text-muted text-uppercase gtc-small"> 18-45 years old (male) </span>
                                        <input className="form-control mb-1" id="18_45male" value={this.state.total_male_legal} readOnly/>
                                        <span className="text-muted text-uppercase gtc-small"> 18-45 years old (female) </span>
                                        <input className="form-control mb-1" id="18_45female" value={this.state.total_female_legal} readOnly/>

                                        <span className="text-muted text-uppercase gtc-small"> 46+ years old (male) </span>
                                        <input className="form-control mb-1" id="46male" value={this.state.total_male_middle} readOnly/>
                                        <span className="text-muted text-uppercase gtc-small"> 46+ years old (female) </span>
                                        <input className="form-control mb-2" id="46female" value={this.state.total_female_middle} readOnly/>

                                        <div id="totalpatientsdiv">
                                            <span className="text-primary"> Total Patients </span>
                                            <input className="form-control mb-2" id="_totalPatients" value={this.state.total_city_pop} readOnly/>
                                        </div>
                                        {
                                            this.state.getIllnessGetClicked ?
                                                <Fragment>
                                                    <span className="text-danger"> Total Positive </span>
                                                    <input className="form-control mb-1" id="total_positive" value={this.state.total_covid19_Positive} readOnly/>
                                                    
                                                    <span className="text-danger"> Total PUM </span>
                                                    <input className="form-control mb-1" id="total_pum" value={this.state.total_covid19_PUM} readOnly/>

                                                    <span className="text-danger"> Total PUI </span>
                                                    <input className="form-control mb-1" id="total_pui" value={this.state.total_covid19_PUI} readOnly/>
                                                </Fragment>
                                            : null
                                        }
                                        {/* 
                                            <button type="button" className="btn btn-outline-primary" style={{width: '100%'}} >
                                                View More Details
                                            </button> 
                                        */}
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="leaflet-sidebar-pane" id="adjust_radius_tab">
                        <h1 className="leaflet-sidebar-header">
                            Adjust Radius
                            <div className="leaflet-sidebar-close"><i className="fa fa-caret-left"></i></div>
                        </h1>
                        <Form className="mt-1">
                            <span className="text-muted text-uppercase gtc-small"> Radius </span>
                            <InputGroup className="mb-2">
                                <FormControl 
                                    type="number" 
                                    name="radiuschanger" 
                                    defaultValue={this.state.defaultradius} 
                                    onKeyUp={this.changeRadius.bind(this)} 
                                />

                                <InputGroup.Append>
                                    <InputGroup.Text id="basic-addon1">meters</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form>
                    </div>

                    <div className="leaflet-sidebar-pane" id="print_list_tab">
                        <h1 className="leaflet-sidebar-header">                                                                                                                                   
                            Print Patients
                            <div className="leaflet-sidebar-close"><i className="fa fa-caret-left"></i></div>
                        </h1>
                        <div>
                            <Col sm={12} className="mt-1">
                                <Row>
                                    <Col sm={3}>
                                        <Row>
                                            <span className="text-muted text-uppercase gtc-small"> Province/City : </span>
                                        </Row>
                                    </Col>
                                    <Col sm={9}>
                                        <Row>
                                            {this.state.city === 0 ? 'Select province/city' : this.state.city}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <Row>
                                            <span className="text-muted text-uppercase gtc-small"> Municipality : </span>
                                        </Row>
                                    </Col>
                                    <Col sm={9}>
                                        <Row>
                                            {this.state.municipality === 0 ? 'Select municipality' : this.state.municipality}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <Row>
                                            <span className="text-muted text-uppercase gtc-small"> Barangay : </span>
                                        </Row>
                                    </Col>
                                    <Col sm={9}>
                                        <Row>
                                            {this.state.barangay === 0 ? 'Select barangay' : this.state.barangay}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={3}>
                                        <Row>
                                            <span className="text-muted text-uppercase gtc-small"> Illness : </span>
                                        </Row>
                                    </Col>
                                    <Col sm={9}>
                                        <Row>
                                            {this.state.illness === 0 ? 'None' : this.state.illness}
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        
                            <FormGroup>
                                <Link style={{width: '100%'}} className='text-center btn btn-outline-primary rounded-0 pointer' 
                                to={ 
                                    this.state.city !== 0 ?
                                        '/management/print/filtered-patient/'+this.state.city+'/'+this.state.municipality+'/'+this.state.barangay+'/'+this.state.illness
                                    :   '/management/mapping'
                                    } 
                                target={this.state.city !== 0 ? "_blank" : ''}>
                                    Print Details
                                </Link>
                            </FormGroup>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}