
import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Grid, List, ListItem, ListItemIcon, ListItemText, Box, Paper, Typography, IconButton, TablePagination, TextField, InputAdornment } from '@material-ui/core'
import Axios from 'axios'
import Notify from 'src/notification/Notify'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NewPatient from './NewPatient';
import PatientDetails from './PatientDetails'; 
import SearchIcon from '@material-ui/icons/Search';

const Patients = () => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    
    const [page, setPage] = useState(0)
    const [ rowsPerPage, setRowsPerPage] = useState(10)
    const [selectedPatient, setSelectedPatient] = useState('new-patient')

    const [patient, setPatient] = useState({
        data: [],
        ready: false
    })

    const [search , setSearch] = useState('')

    const getDoctorsPatient = useCallback(() => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);     
        
        Axios.post('encoder/patients/getpatient-list', formdata )
        .then( (response) =>{ 
            const data = response.data;     
            setPatient({
                data: data, ready: true
            })
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, user_id])

    const handleChangeRowsPerPage = (event) => {  
        setRowsPerPage(parseInt(event.target.value))
        setPage(0)
    };   

    const handleChangePage = (event, newPage) => {
        event.persist() 
        setPage(newPage)
    };

    useEffect(() =>{

        getDoctorsPatient()

    },[getDoctorsPatient])
 
    const searchable =  patient.data.filter( (data) => { 
        return data.firstname.toLowerCase().indexOf(search.trim()) !== -1 || data.lastname.toLowerCase().indexOf(search.trim()) !== -1
    })

    return( 
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} lg={3}>
                    <Box
                        component={Paper}
                        variant="outlined" 
                        borderRadius={4}
                        px={1}
                    >
                        <Box display="flex" >
                            <Box flexGrow={1} mt={2} ml={2}  >
                                <Typography variant="subtitle2" className={`gtc-uppercase`} color="primary"> Patient List </Typography>
                            </Box>

                            <Box hidden={ selectedPatient === 'new-patient'} >
                                <IconButton color="primary" onClick={ () => setSelectedPatient('new-patient') }> 
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Box> 
                        </Box> 

                        <List component="div">
                            <Box mx={3} mb={2}>
                                <TextField 
                                    label="Search"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    value={ search }
                                    onChange={ (e) => setSearch(e.target.value) }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            {
                                patient.ready ?  
                                    patient.data.length > 0 ?
                                        searchable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((data, index) =>(
                                            <ListItem
                                                key={index}
                                                button 
                                                className="mb-2"
                                                onClick={ () => setSelectedPatient(data.patient_id) }
                                                selected={ selectedPatient === data.patient_id }
                                            >
                                                <ListItemIcon> 
                                                    <Avatar> 
                                                        { data.firstname !== null ? data.firstname.charAt().toUpperCase() : 'N'}
                                                    </Avatar> 
                                                </ListItemIcon>
                                                <ListItemText className={`gtc-capitalize`} primary={`${data.lastname}, ${data.firstname} ${data.middle} `} />
                                            </ListItem> 
                                        ))
                                    : Notify.noRecord() 
                                : Notify.loading()
                            }
                        </List>
                        <TablePagination
                            component="div"
                            count={patient.data.length} 
                            rowsPerPageOptions={[10, 50, 100, { label: 'All', value: -1 }]}
                            page={page}
                            onChangePage={ handleChangePage }
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={ handleChangeRowsPerPage }
                            labelRowsPerPage='List'     
                        /> 
                    </Box>
                </Grid>
                
                <Grid item xs={12} sm={8} lg={9}>
                    { selectedPatient === 'new-patient' && ( <NewPatient  getDoctorsPatient = { getDoctorsPatient } /> ) }
                    { selectedPatient !== 'new-patient' && ( <PatientDetails patient_id = { selectedPatient } /> )}
                </Grid>
            </Grid>
        </>
    )
}

export default Patients;