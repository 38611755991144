import React, { Component, Fragment } from 'react';   
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';


const fileLocation = process.env.REACT_APP_API_IMAGE; 

export default class AppointmentDetails extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _details: [],
            _details_ready: false,
        }

    } 

    componentDidMount(){
        this._mounted = true;
        this.getAppointmentDetails();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    getAppointmentDetails(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token);  
        formdata.set('user_id', this.state._user_id);    
        formdata.set('appointment_id', this.props.appointment_id);    
        
        Axios.post('appointment/patient/appointment-detail', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _details: data,
                    _details_ready: true,
                })
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

	render(){ 
		return(
            <Fragment>
                {
                    this.state._details_ready ? 
                        this.state._details.length > 0 ?
                            <Box className={`gtc-textfield-noborder`}> 
                                <Box mb={2}> 
                                    <TextField
                                        fullWidth
                                        label="Appointment Reason"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true
                                        }} 
                                        multiline
                                        defaultValue={ this.state._details[0].appointment_reason }
                                    />
                                </Box> 
 
                                <Box mb={2}> 
                                    <TextField
                                        fullWidth
                                        label="Appointment Attachment"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment:(
                                                <InputAdornment>
                                                    <IconButton
                                                        color="primary"
                                                        component={'a'}
                                                        rel="noopener noreferrer" className="pointer float-right" target="_blank" href={fileLocation+'appointment/'+this.state._details[0].attachment}download
                                                    > 
                                                        <GetAppIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }} 
                                        multiline
                                        defaultValue={ this.state._details[0].attachment === null ? 'No attachment' : this.state._details[0].attachment }
                                            
                                    />
                                </Box>  

                                <Box mb={2}> 
                                    <TextField
                                        fullWidth
                                        label="Appointment Status"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true
                                        }} 
                                        multiline
                                        defaultValue={ this.state._details[0].appointment_status }
                                    />
                                </Box> 

                                { 
                                    this.state._details[0].appointment_status === 'successful' ? 
                                        <Fragment> 
                                            <Box mb={2}> 
                                                <TextField
                                                    fullWidth
                                                    label="Appointment Time Consumed"
                                                    variant="outlined"
                                                    InputProps={{
                                                        readOnly: true
                                                    }} 
                                                    multiline
                                                    defaultValue={ this.state._details[0].consumed_time }
                                                />
                                            </Box>  

                                            <Box mb={2}> 
                                                <TextField
                                                    fullWidth
                                                    label="Appointment Done On"
                                                    variant="outlined"
                                                    InputProps={{
                                                        readOnly: true
                                                    }} 
                                                    multiline
                                                    defaultValue={ Notify.dateTimeConvert(this.state._details[0].appointment_done_on) } 
                                                />
                                            </Box> 
                                        </Fragment>
                                    :null
                                }

                                { this.state._details[0].appointment_status === 'approved' ? 
                                    <Box mb={2}> 
                                        <TextField
                                            fullWidth
                                            label="Appointment Approve Message"
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: true
                                            }} 
                                            multiline
                                            defaultValue={ this.state._details[0].process_message } 
                                        />
                                    </Box> 
                                :null }  

                                <Box mb={2}> 
                                    <TextField
                                        fullWidth
                                        label="Appointment Created On"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true
                                        }} 
                                        multiline
                                        defaultValue={ this.state._details[0].created_at } 
                                    />
                                </Box> 
                            </Box>
                        : Notify.noRecord()
                    : Notify.loading()
                }
            </Fragment>
        )
	}
}
