import React, { Fragment, useState } from 'react';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Typography, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination, Button, Zoom, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PaymentForm from './DashboardModal/Payment/PaymentForm';

function PurchaseList({ purchaseReady, purchase, fetchPuchaseList, fetchInventoryList }) {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [paymentModal, setPaymentModal] = useState(false);

    const handleDeltePurchase = (id, prod_id, batch, expiry) => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);
        formdata.set('id', id);
        formdata.set('prod_id', prod_id);
        formdata.set('batch', batch);
        formdata.set('expiry', expiry);

        Axios.post('malita/pharmacy/delete-purchase-id', formdata)
            .then(() => {
                Notify.successRequest('delete purchase');
                fetchPuchaseList();
                fetchInventoryList();
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Fragment>
            <Box display="flex" p={1}>
                <Box flexGrow={1} />
                <Box>
                    <Button hidden={purchase.length <= 0} variant="contained" color="primary" onClick={() => setPaymentModal(true)} startIcon={<ReceiptIcon />}>
                        Payment
                    </Button>
                </Box>
            </Box>
            <Box p={1}>
                <TableContainer component={Box} borderColor="grey.200" bgcolor="white">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"> PRODUCT </TableCell>
                                <TableCell align="center"> QTY </TableCell>
                                <TableCell align="center"> PRICE </TableCell>
                                <TableCell align="center"> AMOUNT </TableCell>
                                <TableCell align="center"> ACTION </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                purchaseReady ?
                                    purchase.length > 0 ?
                                        purchase.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((data, index) => (
                                                <TableRow key={index}>
                                                    <TableCell > {data.product} </TableCell>
                                                    <TableCell align="right">{data.purchase_quantity}</TableCell>
                                                    <TableCell align="right">{data.price}</TableCell>
                                                    <TableCell align="right">{data.total}</TableCell>
                                                    <TableCell align="center">
                                                        <DeleteIcon
                                                            color="secondary"
                                                            onClick={() => handleDeltePurchase(data.id, data.product_id, data.batch_no, data.expiry_date)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        : <TableRow>
                                            <TableCell colSpan={5}>
                                                <Typography variant="subtitle2" color="secondary"> No purchase added </Typography>
                                            </TableCell>
                                        </TableRow>
                                    : <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                        component={"div"}
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={3}
                        count={purchase.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>

            <Dialog
                open={paymentModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Zoom}
                transitionDuration={1000}
                disableBackdropClick={true}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{"Payment Form"}</DialogTitle>
                <DialogContent dividers>
                    <PaymentForm purchase={purchase} purchaseReady={purchaseReady} fetchPuchaseList={() => fetchPuchaseList()} closeModal={() => setPaymentModal(false)} />
                </DialogContent>
            </Dialog>
        </Fragment>
    )

}

export default PurchaseList;