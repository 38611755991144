import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export default class Others extends Component {
    _mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _others: [],
            _others_ready: false,

            _total_others: 0,
        }

    }

    componentDidMount() {
        this._mounted = true;
        this.getOthers();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    getOthers = () => {
        var formdata = new FormData();
        formdata.set('token', this.state._token);
        formdata.set('management_id', this.state._management_id);
        formdata.set('user_id', this.state._user_id);

        Axios.post('patient/credits/credits-others', formdata)
            .then(function (response) {
                const data = response.data;
                if (this._mounted) {
                    this.setState({
                        _others: data,
                        _others_ready: true,
                    })
                    this.totalLoadout(data)
                }
            }.bind(this)).catch(error => {
                Notify.requestError(error);
            });
    }

    totalLoadout = (data) => {
        var total = 0;
        for (let index = 0; index < data.length; index++) {
            total += parseFloat(data[index].unpaid_consultation);
        }
        this.setState({
            _total_others: total
        })
    }

    render() {
        return (
            <Fragment>
                <Table striped responsive>
                    <thead className="text-uppercase header-label gtc-bg-purple text-white text-center">
                        <tr>
                            <th> Date </th>
                            <th> Order # </th>
                            <th> Payment </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state._others_ready ?
                                this.state._others.length > 0 ?
                                    this.state._others.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td> {Notify.createdAt(data.created_at)} </td>
                                                <td className="text-right"> {data.order_no} </td>
                                                <td className="text-right"> {data.unpaid_consultation} </td>
                                            </tr>
                                        )
                                    })
                                    : <tr>
                                        <td colSpan="6" className="text-danger"> No transaction yet. </td>
                                    </tr>
                                : <tr>
                                    <td colSpan="6" className="gtc-text-purple"> <FontAwesomeIcon icon={faCircleNotch} spin={true} />  loading </td>
                                </tr>
                        }
                    </tbody>
                </Table>
                <div className="float-right header-label text-uppercase">
                    <strong> Total Paid Consultation Tru Delivery: </strong> <span className="h5">{this.state._total_others}</span>
                </div>
            </Fragment>
        )
    }
}
