import React, { Fragment } from 'react';
import Card from 'react-bootstrap/Card'

function GTCContactInfo() {
    return (
        <Fragment>
            <Card>
                <Card.Header className="gtc-darkblue"> <strong> Looking for help? </strong> </Card.Header>
                <Card.Body>
                    <p className="gtc-darkblue"> Dont hesitate to contact us directly , we here to listen, help and assist your concern. </p>
                    <div>
                        <span className="text-muted text-uppercase gtc-small"> Mobile: </span>
                        <p className="font-weight-bold"> +6390-378-3234 </p> 
                    </div>
                    <div>
                        <span className="text-muted text-uppercase gtc-small"> telephone: </span>
                        <p className="font-weight-bold"> 083-234-222 </p> 
                    </div>
                    <div>
                        <span className="text-muted text-uppercase gtc-small"> email: </span>
                        <p className="font-weight-bold"> sample@gmail.com </p> 
                    </div>
                </Card.Body>
            </Card> 
        </Fragment>
    )
}

export default GTCContactInfo;
