import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TablePagination } from '@material-ui/core';
import React from 'react';
import Notify from 'src/notification/Notify';

const RadiologistPatientReviewed = ({ records }) => {


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return(
        <Paper
            variant="outlined"
            component={Box}
            p={2}
        >
            <Box
                variant="subtitle2"
                color="textPrimary"
                className={`gtc-uppercase`}
            >
                Patient Reviewed
            </Box>
            
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow> 
                            <TableCell> Patient </TableCell> 
                            <TableCell> Order </TableCell>
                            <TableCell> Date </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            records.ready ? 
                                records.data.length > 0 ?
                                    (rowsPerPage > 0 ? records.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : records.data)
                                    .map((data, index) => (
                                        <TableRow
                                            key={ index }
                                            hover
                                        > 
                                            <TableCell>
                                                { data.patient_name }
                                            </TableCell> 
                                            <TableCell>
                                                { data.imaging_order }
                                            </TableCell>
                                            <TableCell> 
                                                { Notify.dateTimeConvert(data.created_at) }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                :   <TableRow>
                                        <TableCell colSpan={3}>
                                            <Typography color="error">
                                                No record found.
                                            </Typography>
                                        </TableCell> 
                                    </TableRow>
                            :   <TableRow>
                                    <TableCell colSpan={3}>
                                        <Typography color="textPrimary">
                                            please wait...
                                        </Typography>
                                    </TableCell> 
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                labelRowsPerPage="list"
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={records.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    )
}

export default RadiologistPatientReviewed;