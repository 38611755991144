import React, { useEffect, useState } from 'react'
import { Box, Drawer, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, makeStyles, CircularProgress  } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ClinicDetailsInquiryMessages from './ClinicDetailsInquiryMessages';
import Axios from 'axios';
import Notify from '../../notification/Notify';

const drawerWidth = 440;
const innerwidth = window.innerWidth; 

const useStyles = makeStyles({  
    drawerPaper: {
        width: innerwidth > 600 ? drawerWidth : '100%'
    },
});


const ClinicDetailsInquiry = ({ open , close , clinic_id, user_id }) =>{

    const classes = useStyles()
    const token = localStorage.getItem('token')  
    const [process, setProcess] = useState(false)
    const [messages, setMessages] = useState({
        data: [],
        ready: false
    }) 

    var newInquiryInterval = null;

    const handleInquiry = (e) =>{
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
            formdata.set('token', token)
            formdata.set('patient_id', user_id)
            formdata.set('clinic_id', clinic_id)
            formdata.set('send_by', 'Patient')
        var error = [];

        if(formdata.get('message').length === 0 || formdata.get('message').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('message'); 
        }

        if(error.length > 0){
            console.warn("Form has an error.")
        }else{
            setProcess(true)
            Axios
            .post('patient/appointment/clinic/details/inquiry-send', formdata)
            .then((response) =>{
                const data = response.data
                if(data === 'success'){
                    e.target.reset()
                    getInquiryMessage()
                    Notify.successRequest('Inquiry sent!')
                }
            }).catch(error => { 
                Notify.requestError(error) 
            }).finally(() =>{
                setProcess(false)
            })
        }
    }

    const getInquiryMessage = () => {
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('patient_id', user_id)
            formdata.set('clinic_id', clinic_id) 

        Axios
        .post('patient/appointment/clinic/details/inquiry-getmessages', formdata)
        .then((response) =>{
            const data = response.data
            setMessages({ data: data, ready: true }) 
            getNewInquiryResponse(data.length > 0 ? data[data.length - 1].id : 0)
        }).catch(error => { 
            Notify.requestError(error) 
        }) 
    }

    const getNewInquiryResponse = (lastMsgId) =>{
        newInquiryInterval = setInterval(() => {
            var formdata = new FormData()
                formdata.set('token', token)
                formdata.set('patient_id', user_id)
                formdata.set('clinic_id', clinic_id) 
                formdata.set('lastmessage_id', lastMsgId)

                Axios
                .post('patient/appointment/clinic/details/inquiry-getnewinquiry', formdata)
                .then((response) =>{
                    const data = response.data
                    if(data.length > 0){
                        getInquiryMessage()
                        clearInterval(newInquiryInterval)
                    }
                }).catch(error => { 
                    Notify.requestError(error) 
                })
        }, 10000);
    }

    useEffect(() =>{

        getInquiryMessage()  

        return(() =>{
            clearInterval(newInquiryInterval)
        })
        // eslint-disable-next-line
    },[])

    return(
        <>
            <Drawer 
                anchor={'right'} 
                open={open} 
                onClose={close}
                variant="temporary" 
                classes={{
                    paper: classes.drawerPaper,
                }}
            >

                <ClinicDetailsInquiryMessages messages = { messages }/>

                <Box m={1}>
                    <form
                        onSubmit={ handleInquiry }
                    >  
                        <FormControl
                            variant="outlined"
                            fullWidth 
                        >
                            <InputLabel htmlFor="message"> Message</InputLabel>
                            <OutlinedInput
                                id="message"  
                                name="message"
                                autoComplete="off"
                                required
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility" 
                                            edge="end"
                                            color="primary"
                                            type="submit"
                                            disabled={ process }
                                        >
                                            { process ? <CircularProgress size={20} /> : <SendIcon/>  }
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </form>
                </Box>
            </Drawer>
        </>
    )
}

export default ClinicDetailsInquiry;