
 
import React from 'react'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Box, Typography, IconButton, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const ZoomableImage = ({ open , close, title, image }) => {

    return(
        <Dialog
            open={ open }
            onClose={ close }
            fullScreen
            disableEscapeKeyDown
            disableBackdropClick
        >  
            <Box>
                <TransformWrapper
                    defaultScale={1} 
                > 
                    {({ zoomIn, zoomOut }) => (
                        <>  
                            <Box display="flex">  
                                <Box flexGrow={1}>  
                                    <DialogTitle disableTypography >
                                        <Typography 
                                            color="primary" 
                                            variant="subtitle2" 
                                            className={`gtc-uppercase`}
                                        > { title } </Typography>
                                    </DialogTitle>
                                </Box>   
                                <Box>     
                                    <IconButton 
                                        color="primary"  
                                        onClick={zoomIn}
                                    >
                                        <ZoomInIcon />
                                    </IconButton>
                                    <IconButton 
                                        color="primary"  
                                        onClick={zoomOut}
                                    >
                                        <ZoomOutIcon />
                                    </IconButton> 

                                    <IconButton 
                                        color="secondary"  
                                        onClick={ close }
                                    >
                                        <HighlightOffIcon />
                                    </IconButton> 
                                </Box>
                            </Box>   

                            <DialogContent dividers>
                                <Box>
                                    <TransformComponent>    
                                        <img 
                                            src={image} 
                                            alt=""  
                                        /> 
                                    </TransformComponent>   
                                </Box>
                            </DialogContent>
                        </> 
                    )}
                </TransformWrapper>    
            </Box>
        </Dialog>
    )
}

export default ZoomableImage;