import React, { useCallback, useEffect, useState } from 'react' 
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Tooltip, Typography } from '@material-ui/core'
import Notify from '../notification/Notify'
import Axios from 'axios'
import FileCopyIcon from '@material-ui/icons/FileCopy';

const DoctorsListDetails = ({ open, close, doctor_userid }) =>{

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 
    const [doctorsInfo, setDoctorsInfo] = useState([])
    const [doctorsInfoReady, setDoctorsInfoReady] = useState(false)
    const [title, setTitle] = useState('Click to copy')

    const getInquiryMessage = useCallback(() => {
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)  
            formdata.set('doctor_userid', doctor_userid)
        Axios
        .post('clinic/secretary/doctors/doctor-information', formdata)
        .then((response) =>{
            const data = response.data
            setDoctorsInfo(data)
            setDoctorsInfoReady(true)
        }).catch(error => { Notify.requestError(error) }) 
    },[token, user_id, doctor_userid])

    useEffect(() =>{
        getInquiryMessage()
    },[getInquiryMessage])

    return(
        <>
            <Dialog
                open = { open }
                onClose = { close }  
                disableBackdropClick  
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle
                    disableTypography
                >
                    <Typography variant="subtitle2" color="primary">
                        DOCTOR INFORMATION
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    {
                        doctorsInfoReady ?
                            doctorsInfo.length > 0 ?
                                doctorsInfo.map((data, index) =>( 
                                    <Box 
                                        className="gtc-textfield-noborder"
                                        key={index}
                                    > 
                                        <Box mb={3}>  
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Name"
                                                variant="outlined"
                                                value={data.name} 
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Box> 

                                        <Box mb={3}>  
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Address"
                                                variant="outlined"
                                                value={data.address} 
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Box> 

                                        <Box mb={3}>  
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Birthday"
                                                variant="outlined"
                                                value={Notify.birthday(data.birthday)} 
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Box> 

                                        <Box mb={3}>  
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Specialization"
                                                variant="outlined"
                                                value={data.specialization} 
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                            />
                                        </Box> 

                                        <Box mb={3}>    
                                            <FormControl
                                                variant="outlined"
                                                fullWidth 
                                            >
                                                <InputLabel htmlFor="message"> Patient Link </InputLabel>
                                                <OutlinedInput
                                                    id="message"  
                                                    name="message"
                                                    autoComplete="off" 
                                                    labelWidth={70}
                                                    multiline
                                                    inputProps={{
                                                        readOnly: true, 
                                                        value: `${window.location.href}patient/appointment/doctor/${doctor_userid}`
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <Tooltip title={ title }> 
                                                                <IconButton
                                                                    aria-label="toggle password visibility" 
                                                                    color="primary"
                                                                    edge="end"
                                                                    onClick={() =>{
                                                                        setTitle('Copied')
                                                                        navigator.clipboard.writeText(`patient/appointment/doctor/${doctor_userid}`)
                                                                    }}
                                                                >
                                                                    <FileCopyIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Box> 
                                    </Box> 
                                ))
                            : Notify.noRecord()
                        : Notify.loading()
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="inherit"
                        onClick={ close }
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DoctorsListDetails;