import React, { Component, Fragment } from 'react';   
import Axios from 'axios';
import Notify from '../../notification/Notify'; 
import { TextField, Box, Button, CircularProgress, DialogContent, DialogActions } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export default class AppointmentApprovedDetailsMsgForm extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
            
            _is_process: false,
		}
	} 

    handleNotificationMsg = (e) =>{
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);
            formdata.set('user_id', this.state._user_id);
            formdata.set('token', this.state._token);
            formdata.set('username', this.state._username);
            formdata.set('patient_id', this.props.patient_id);
            formdata.set('appid' , this.props.appid);
            formdata.set('connection' , this.props.connection);

        var error = [];

        if(formdata.get('message').length === 0 || formdata.get('message').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('message'); 
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }
        if(error.length > 0){
            Notify.consoleLog('notification')
        }else{
            this.setState({
                _is_process: true
            })

            Axios.post('appointment/patient/send-notification', formdata)
                 .then((response)=>{
                    this.setState({
                        _is_process: false
                    })
                        const data = response.data
                    if(data ==='pass-inv'){
                        Notify.fieldInvalid('password')
                    }
                    if(data ==='success'){
                        this.props.closeDialog();
                        Notify.successRequest('notification sent')
                    }
                    if(data ==='db-error'){
                        Notify.warnRequest('notification sent')
                    }
                    })
                    .catch((error)=>{
                    this.setState({
                        _is_process: false
                    })
                        Notify.requestError(error)
                })
        }
    }

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

	render(){ 
		return(
            <Fragment>
                <form onSubmit={this.handleNotificationMsg}> 
                    <DialogContent dividers> 
                        <Box>
                            <TextField fullWidth name="message" label="Messsage" margin="normal" variant="outlined" multiline />
                        </Box>
                        <Box>
                            <TextField fullWidth name="password" label="Password" margin="normal" variant="outlined" type="password" autoComplete="off" />
                        </Box>  
                    </DialogContent>
                    <DialogActions> 
                        <Button 
                            className="ml-2"
                            startIcon={<HighlightOffIcon /> } 
                            variant="contained" color="default" 
                            onClick={this.props.closeDialog}> No </Button>

                        <Button 
                            type="submit" 
                            disabled={ this.state._is_process } 
                            startIcon={ this.state._is_process ? <CircularProgress size={15} color="inherit" /> : <CheckCircleOutlineIcon />} 
                            variant="contained" color="primary" > Send </Button>
                    </DialogActions>
                </form>
            </Fragment>
		)
	}
}
