import React, { useCallback, useEffect, useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TableFooter, TablePagination } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';

const ReviewedPatient = () => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [list, setList] = useState([])
    const [listRd, setListRd] = useState(false)

    let totalPF = 0;

    // const [orderCount , serOrderCount ] = useState()

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getReviewedList = useCallback(async () => {
        try {
            var formdata = new FormData()
            formdata.append('token', token)
            formdata.append('user_id', user_id)

            const request = await Axios.post('telerad/patients/getreviewed-list', formdata)
            setList(request.data)
            setListRd(true)
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id])

    useEffect(() => {

        getReviewedList()

    }, [getReviewedList])

    
    const calculatePF = (amount) => {
        totalPF += amount;

        return Notify.convertToNumber(amount);
    }

    return (
        <Paper
            variant="outlined"
            component={Box}
            p={2}
        >
            <Box mb={2}>
                <Typography
                    variant="subtitle2"
                    color="textPrimary"
                >
                    <b>
                        REVIEWED PATIENTS REPORT
                    </b>
                </Typography>
            </Box>

            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> <b> Date </b> </TableCell>
                                <TableCell> <b> Patient </b> </TableCell>
                                <TableCell> <b> Order </b>  </TableCell>
                                <TableCell> <b> Shots </b>  </TableCell>
                                <TableCell> <b> PF </b>  </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                listRd ?
                                    list.length > 0 ?
                                        (rowsPerPage > 0 ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : list)
                                            .map((data, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                    >
                                                        <TableCell> {Notify.dateTimeConvert(data.created_at)} </TableCell>
                                                        <TableCell> {data.patient_name} </TableCell>
                                                        <TableCell> {data.imaging_order} </TableCell>
                                                        <TableCell align="right"> {data.number_shots} </TableCell>
                                                        <TableCell align="right"> 
                                                            { calculatePF(parseFloat(data.number_shots) * 50) }
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        : <TableRow>
                                            <TableCell colSpan={5} > <Typography color="error"> No patient recorded. </Typography> </TableCell>
                                        </TableRow>
                                    : <TableRow>
                                        <TableCell colSpan={5} > <Typography color="primary"> please wait... </Typography> </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell> Total </TableCell>
                                <TableCell align="right"> {totalPF} </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="List"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={list.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Box>
        </Paper>
    )
}

export default ReviewedPatient;