import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@material-ui/core";
import Notify from 'src/notification/Notify';
import Axios from 'axios';  
import L from 'leaflet';  
import { Map, TileLayer, Marker } from "react-leaflet";    
import FullscreenControl from 'react-leaflet-fullscreen';
import CancelIcon from '@material-ui/icons/CancelOutlined'
import MapIcon from '@material-ui/icons/MapOutlined';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const Location = ({ mapHeight }) =>{
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const [ location, setLocation] = useState([])
    const [ opendialog, setOpenDialog] = useState(false)
    const [ locationProcess, setLocationProcess] = useState(false)
    const [marker, setmarker] = useState({lat: null,  lng: null});  
    var refmarker = useRef(null);

    const getCurrentLocation = useCallback( async () =>{
        try {
            var formdata = new FormData()
                formdata.append('token', token)
                formdata.append('user_id', user_id) 
            const request = await Axios.post('patient/information/location/getpatient-location', formdata)
            setLocation(request.data)
        } catch (error) {
            Notify.requestError(error)
        }
    },[token, user_id])

    const handleDragMarker = (e) =>{    
        setmarker(refmarker.leafletElement.getLatLng()) 
    }

    const getNavLocation = useCallback(() =>{
        navigator.geolocation.getCurrentPosition(function(location){  
            var current_location = [{
                lat: location.coords.latitude,
                lng: location.coords.longitude
            }]; 
            setmarker(current_location[0]) 
        })
    },[]);

    const handleUpdateLocation = (e) => { 
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
            formdata.append('token', token)
            formdata.append('user_id', user_id) 
            formdata.append('username', username)
        var error = []
        if(formdata.get('password').trim('') === '' || formdata.get('password').length  === 0){
            error = 'error'
            Notify.fieldRequired('password')
        }
        if(error.length > 0){
            console.warn('form has an error.')
         }
        else{
            setLocationProcess(true)
            Axios.post('patient/information/location/updatepatient-location', formdata)
            .then((response)=>{
                if(response.data === 'success'){
                    setOpenDialog(false)
                    getCurrentLocation()
                    Notify.successRequest('location update')
                }
                if(response.data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
            }).catch((error) =>{
                Notify.requestError(error)
            }).finally(() =>{
                setLocationProcess(false)
            })
        }
    }
 
    useEffect(() =>{ 
        
        getCurrentLocation()
        getNavLocation() 

    },[getCurrentLocation, getNavLocation])

    

    return(
        <Box>
            {
                location.length > 0 ? (
                    <Box>
                        <Map 
                            center={[location[0].latitude, location[0].longitude]} 
                            zoom={10}  
                            style={{height: `${mapHeight}px`}}    
                            attributionControl={false}
                        >  
                            <TileLayer
                                url={process.env.REACT_APP_MAP_URL} 
                                attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                                maxZoom={19}
                                minZoom={6}
                                crossOrigin={true}
                            />
                            <Marker 
                                position={[location[0].latitude, location[0].longitude]}   
                            />  
                            <FullscreenControl position="topright" />
                        </Map>
                    </Box>
                ) 
                    : 
                (
                    <Box 
                        align="center"
                    >
                        <Typography
                            color="textSecondary"
                            variant="subtitle2"
                        > 
                            Set your location to enable delivery method in cart.
                        </Typography> 
                        
                        <Box mt={3}>
                            <Button
                                color="default"
                                variant="contained"
                                onClick = { () => setOpenDialog(true) }
                            >
                                Set location
                            </Button>
                        </Box>
                    </Box>
                )
            }

            {/* set location dialog */}
            <Dialog
                open = { opendialog }
                onClose= { () => {
                    setOpenDialog(false);  
                }} 
                maxWidth="sm"
                fullWidth
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle
                    disableTypography
                >
                    <Typography subtitle="subtitle2" className={`gtc-uppercase`}> 
                        Set Location
                    </Typography> 
                </DialogTitle>
                <form onSubmit={ handleUpdateLocation }>
                    <DialogContent dividers>
                        <Box className={`gtc-textfield-noborder`}>
                            <Map 
                                center={[12.8797, 121.7740]} 
                                zoom={5} 
                                style={{height: '300px'}}  
                                locate={{
                                    watch: true,
                                    enableHighAccuracy: true
                                }}
                            >  
                                <TileLayer
                                    url={process.env.REACT_APP_MAP_URL} 
                                    attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                                    maxZoom={19}
                                    minZoom={6}
                                    crossOrigin={true}
                                />
                                <Marker 
                                    position={marker} 
                                    onDrag={ handleDragMarker }
                                    draggable={true}
                                    onDragend={ handleDragMarker }
                                    ref={ (ref) => refmarker = ref }
                                /> 
                                <FullscreenControl position="topright" />
                            </Map>
                            <Box my={3}>
                                <TextField 
                                    name="longitude"
                                    fullWidth
                                    label="Longitude"
                                    variant="outlined"
                                    value={ marker.lng }
                                    readOnly
                                />
                            </Box>
                            <Box mb={3}>
                                <TextField 
                                    name="latitude"
                                    fullWidth
                                    label="Latitude"
                                    variant="outlined"
                                    value={ marker.lat }
                                    readOnly
                                />
                            </Box> 
                            <Box>
                                <TextField 
                                    required
                                    fullWidth
                                    label="Password"
                                    variant="outlined" 
                                    type="password" 
                                    name="password"
                                />
                            </Box> 
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            primary="default"
                            onClick = { () => {
                                setOpenDialog(false) 
                            }}
                            startIcon={
                                <CancelIcon />
                            }
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            startIcon={ locationProcess ? <CircularProgress size={20} color="inherit" /> : <MapIcon /> }
                            disabled={ locationProcess }
                        >
                            SET Location
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    )
}

export default Location;
