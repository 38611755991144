import React, { Fragment, useContext, useEffect, useState } from "react"
import { BrowserRouter, NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { AppBar, Box, Drawer, Hidden, IconButton, List, Toolbar, Typography, ListItem, ListItemIcon, ListItemText, Badge } from "@material-ui/core";
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import Home from '../malitapharmacy/Home';
import Receipt from '../malitapharmacy/Receipt/Receipt';
import ReceiptPrint from '../malitapharmacy/Receipt/ReceiptPrint';
import Stock from "src/malitapharmacy/Stock/Stock";
import Sales from "src/malitapharmacy/Sales/Sales";
import { ThemeContext, VirtualPharmacyContext } from "../ContextAPI";
import DarkIcon from '@material-ui/icons/Brightness4';
import LightIcon from '@material-ui/icons/Brightness7';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Account from "src/malitapharmacy/Account/Account";
import Virtual from "src/malitapharmacy/Virtual/Virtual";
import Notify from './../notification/Notify';
import Axios from 'axios';
import CheckInternet from 'src/utils/CheckInternet';

var interval = null;

const RouteMalitaPharmacy = () => {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [pharmacy_id] = useState(localStorage.getItem('pharmacy_id'))
    const role = localStorage.getItem('role')
    const themeContext = useContext(ThemeContext)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [badgeData, setBadgeData] = useState([])
    const [online, setOnline] = useState('checking')

    const fetchBadgeCount = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);
        formdata.set('pharmacy_id', pharmacy_id)
        formdata.set('connection', 'online')

        Axios.post('malita/pharmacy/get-prescription-list', formdata)
            .then((response) => {
                const data = response.data;
                setBadgeData(data);
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const checkinternet = () => {
        CheckInternet.online()
            .then(() => {
                setOnline('connected');
                interval = setInterval(() => {
                    fetchBadgeCount();
                }, 5000);
            })
            .catch(() => {
                setOnline('disconnected');
                clearInterval(interval);
            })
    }

    useEffect(() => {

        checkinternet()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [online])

    return (
        <Fragment>
            <VirtualPharmacyContext.Provider
                value={{
                    badgeCount: badgeData
                }}
            >
                <BrowserRouter>
                    <AppBar
                        position="static"
                        color={themeContext.gtcThemeDark ? 'default' : 'primary'}
                        className={`gtc-appbar d-print-none`}
                    >
                        <Toolbar>
                            <Hidden xsDown>
                                <Box
                                    display="flex"
                                    width={'100%'}
                                    className={`appbarLink`}
                                >
                                    <Box flexGrow={1}>
                                        <Box display="flex">
                                            <Box m={2}>
                                                <Typography className={`text-white`}>
                                                    GTC PHARMACY
                                            </Typography>
                                            </Box>

                                            <Box m={2}>
                                                <NavLink to="/app/malitapharmacy" exact activeClassName="text-white">
                                                    {
                                                        badgeData.length > 0 ?
                                                            <Badge variant="dot" color="secondary">Home</Badge>
                                                            : 'Home'
                                                    }
                                                </NavLink>
                                            </Box>

                                            <Box m={2}>
                                                <NavLink to="/app/malitapharmacy/receipt" exact activeClassName="text-white">
                                                    Receipt
                                            </NavLink>
                                            </Box>

                                            <Box m={2}>
                                                <NavLink to="/app/malitapharmacy/stock" exact activeClassName="text-white">
                                                    Stock
                                            </NavLink>
                                            </Box>

                                            <Box m={2}>
                                                <NavLink to="/app/malitapharmacy/sales" exact activeClassName="text-white">
                                                    Sales
                                            </NavLink>
                                            </Box>
                                            {
                                                role === 'admin' && (
                                                    <Box m={2}>
                                                        <NavLink to="/app/malitapharmacy/account" exact activeClassName="text-white">
                                                            Accounts
                                                        </NavLink>
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Hidden>
                            <Hidden smUp>
                                <IconButton edge="start" color="inherit" aria-label="menu"
                                    onClick={() => setOpenDrawer(true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Box
                                    display="flex"
                                    width={'100%'}
                                >
                                    <Box flexGrow={1}>
                                        <Drawer anchor={`left`} open={openDrawer} onClose={() => setOpenDrawer(false)}>
                                            <Box p={2}>
                                                <Typography>
                                                    GTC PHARMACY
                                            </Typography>
                                                <List
                                                    className={`appbarLink`}
                                                >
                                                    <ListItem component={NavLink} exact to="/app/malitapharmacy" activeClassName={themeContext.gtcThemeDark ? 'text-white' : 'gtc-text-purple'}>
                                                        <ListItemIcon> <HomeIcon /> </ListItemIcon>
                                                        <ListItemText primary={'Home'} />
                                                    </ListItem>

                                                    <ListItem component={NavLink} exact to="/app/malitapharmacy/receipt" activeClassName={themeContext.gtcThemeDark ? 'text-white' : 'gtc-text-purple'}>
                                                        <ListItemIcon> <ReceiptIcon /> </ListItemIcon>
                                                        <ListItemText primary={'Receipt'} />
                                                    </ListItem>

                                                    <ListItem component={NavLink} exact to="/app/malitapharmacy/stock" activeClassName={themeContext.gtcThemeDark ? 'text-white' : 'gtc-text-purple'}>
                                                        <ListItemIcon> <PostAddIcon /> </ListItemIcon>
                                                        <ListItemText primary={'Stock'} />
                                                    </ListItem>

                                                    <ListItem component={NavLink} exact to="/app/malitapharmacy/sales" activeClassName={themeContext.gtcThemeDark ? 'text-white' : 'gtc-text-purple'}>
                                                        <ListItemIcon> <MonetizationOnIcon /> </ListItemIcon>
                                                        <ListItemText primary={'Sales'} />
                                                    </ListItem>

                                                    {
                                                        role === 'admin' && (
                                                            <ListItem component={NavLink} exact to="/app/malitapharmacy/account" activeClassName={themeContext.gtcThemeDark ? 'text-white' : 'gtc-text-purple'}>
                                                                <ListItemIcon> <AccountCircleIcon /> </ListItemIcon>
                                                                <ListItemText primary={'Accounts'} />
                                                            </ListItem>
                                                        )
                                                    }
                                                </List>
                                            </Box>
                                        </Drawer>
                                    </Box>
                                </Box>
                            </Hidden>

                            <Box>
                                <Box display="flex" width={180}>
                                    <Box m={2} className={`pointer`}>
                                        <Typography onClick={() => { themeContext.updategtcTheme(themeContext.gtcThemeDark ? 'light' : 'dark') }}> {themeContext.gtcThemeDark ? <DarkIcon /> : <LightIcon />} </Typography>
                                    </Box>
                                    <Box m={2}>
                                        <NavLink exact to="/app/logout" style={{ color: '#c5c5c5', textDecoration: 'none' }}>
                                            Logout  <ExitToAppIcon />
                                        </NavLink>
                                    </Box>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Switch>
                        <Route exact path="/" component={() => <Redirect to="/app/malitapharmacy" />} />
                        <Route exact path="/app" component={() => <Redirect to="/app/malitapharmacy" />} />
                        <Route exact path="/app/malitapharmacy" component={Home} />
                        <Route exact path="/app/malitapharmacy/receipt" component={Receipt} />
                        <Route exact path="/app/malitapharmacy/receipt/:receipt_number" component={ReceiptPrint} />
                        <Route exact path="/app/malitapharmacy/stock" component={Stock} />
                        <Route exact path="/app/malitapharmacy/sales" component={Sales} />
                        <Route exact path="/app/malitapharmacy/account" component={Account} />
                        <Route exact path="/app/malitapharmacy/virtual" component={Virtual} />
                        <Route exact path="/app/logout" component={Logout} />
                        <Route render={
                            () => <PageNotFound />
                        } />
                    </Switch>

                </BrowserRouter>
            </VirtualPharmacyContext.Provider>
        </Fragment>
    )
}

export default RouteMalitaPharmacy;
