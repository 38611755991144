import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Collapse, Paper } from '@material-ui/core';  
import SetProcess from './Dialogs/SetProcess';
import Notify from '../notification/Notify';
import Axios from 'axios';
import DoneAllIcon from '@material-ui/icons/DoneAll'; 

 
const ImagingOrderNew = ({ getCounts }) =>{
 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')  

    const [newOrder, setNewOrder] = useState({
        data: [],
        ready: false
    }) 

    const [ process, setProcess] = useState({
        imagingId: null,
        open: false
    })

    const getNewOrder = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        
        Axios.post('imaging/order/order-new', formdata )
        .then((response) => {  
            const data = response.data;   
            setNewOrder({
                data: data,
                ready: true
            })
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id]); 

    
    useEffect(() =>{
        getNewOrder()
    }, [getNewOrder])

    return(
        <Fragment> 
            <Collapse in={newOrder.ready} timeout={600}>
                <Paper component={Box} p={2} variant="outlined"> 
                    <TableContainer >
                        <Box ml={2} mt={2}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                            >
                                NEW ORDER
                            </Typography>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> ID </TableCell>
                                    <TableCell> Date/Time </TableCell>
                                    <TableCell> Patient </TableCell>
                                    <TableCell> Order </TableCell>
                                    <TableCell> Remarks </TableCell>
                                    <TableCell> Doctor </TableCell>
                                    <TableCell> Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>  
                                {
                                    newOrder.data.length > 0 ?
                                        newOrder.data.map((data,index)=>(
                                            <TableRow key={index} hover>
                                                <TableCell> { data.imaging_center_id.replace('imaging-','') } </TableCell>
                                                <TableCell> { Notify.dateTimeConvert(data.created_at) } </TableCell>
                                                <TableCell> { data.patient_name } </TableCell>
                                                <TableCell> { data.imaging_order } </TableCell>
                                                <TableCell> { data.imaging_remarks } </TableCell>
                                                <TableCell> { data.doctors_name } </TableCell>
                                                <TableCell> 
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        endIcon={ <DoneAllIcon /> }
                                                        onClick={()=>{
                                                            setProcess({ imagingId: data.imaging_center_id, open: true })
                                                        }}
                                                    > process </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    :
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            <Typography variant="subtitle2" color="secondary"> No new order </Typography> 
                                        </TableCell> 
                                    </TableRow> 
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Collapse>
            
            {/* setis process dialog */}
            <SetProcess
                open = { process.open }
                imaging_center_id = { process.imagingId }
                close={ () => setProcess({ imagingId: null , open: false }) }
                reload = { getNewOrder }
                getCounts = { getCounts }
            />
            
        </Fragment>
    )
}

export default ImagingOrderNew;