import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination, InputAdornment, Divider, Paper, Button, Dialog, DialogTitle, DialogContent, Zoom, FormHelperText, IconButton } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import ClearIcon from '@material-ui/icons/HighlightOff';
import { Formik } from 'formik';
import * as Yup from 'yup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SalesReportPrint from './SalesModal/SalesReportPrint';

const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

function Sales() {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [pharmacy_id] = useState(localStorage.getItem('pharmacy_id'))
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sales, setSales] = useState([]);
    const [salesReady, setSalesReady] = useState(false);
    const [search, setSearch] = useState('')
    const [filterDialog, setFilterDialog] = useState(false)
    const [printSalesDiag, setPrintSalesDialog] = useState(false)

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchSales = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);
        formdata.set('pharmacy_id', pharmacy_id);

        Axios.post('malita/pharmacy/get-sales-list', formdata)
            .then((response) => {
                const data = response.data;
                setSales(data);
                setSalesReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, management_id, pharmacy_id])

    useEffect(() => {

        fetchSales()

    }, [fetchSales])

    const searchable = sales.filter((data) => {
        return data.product.toLowerCase().indexOf(search.trim()) !== -1
    })

    return (
        <Fragment>
            <Box m={2}>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={2} sm={2} className={'d-print-none'}>
                                <Paper elevation={3} variant="outlined">
                                    <Box>
                                        <Box p={2} borderRadius={4}>
                                            <Box display="flex" justifyContent="center" >
                                                <Box flexGrow={1} >
                                                    <Typography color="primary" >
                                                        Overall Sales
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Box p={2}>
                                            <Box flexGrow={1} align="center">
                                                <Typography color="inherit" variant="h5" >
                                                    {
                                                        sales.length > 0 ?
                                                            Notify.numberFormat(sales[0].sum_all_total_sales)
                                                            : '0.00'
                                                    }
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Paper component={Box}>
                                    <Box>
                                        <Box p={2} borderRadius={4}>
                                            <Box display="flex" justifyContent="center" >
                                                <Box flexGrow={1} >
                                                    <Typography noWrap color="primary" >
                                                        Sales Report
                                                    </Typography>
                                                </Box>
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => setPrintSalesDialog(true)}
                                                >
                                                    <PrintIcon />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Box m={1}>
                                            <TableContainer component={Box} borderColor="grey.200" bgcolor="white">
                                                <Box display="flex">
                                                    <Box flexGrow={1}>
                                                        <IconButton
                                                            size="small"
                                                            className={'d-print-none'}
                                                            color="primary"
                                                            onClick={() => setFilterDialog(true)}
                                                        >
                                                            <FilterListIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <Box mb={1}>
                                                        <TextField
                                                            className={'d-print-none'}
                                                            label="Search brand"
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={search}
                                                            onChange={(e) => setSearch(e.target.value)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment>
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>

                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center"> Brand </TableCell>
                                                            <TableCell align="center"> Generic </TableCell>
                                                            <TableCell align="center"> Date </TableCell>
                                                            <TableCell align="center"> Unit </TableCell>
                                                            <TableCell align="center"> Qty </TableCell>
                                                            <TableCell align="center"> Amount </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            salesReady ?
                                                                sales.length > 0 ?
                                                                    searchable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                        .map((data, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell bgcolor="white"> {data.product} </TableCell>
                                                                                <TableCell bgcolor="white">{data.description}</TableCell>
                                                                                <TableCell bgcolor="white" align="right">
                                                                                    {Notify.dateTimeConvert(data.created_at)}
                                                                                </TableCell>
                                                                                <TableCell bgcolor="white">{data.unit}</TableCell>
                                                                                <TableCell bgcolor="white" align="right">{data.sum_all_total_quantity}</TableCell>
                                                                                <TableCell bgcolor="white" align="right">
                                                                                    {Notify.numberFormat(data.sum_spec_total_quantity)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    : <TableRow>
                                                                        <TableCell colSpan={6}>
                                                                            <Typography variant="subtitle2" color="secondary"> No sales found </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                : <TableRow>
                                                                    <TableCell colSpan={6}>
                                                                        <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>

                                                <TablePagination
                                                    className={'d-print-none'}
                                                    component={"div"}
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    colSpan={3}
                                                    count={sales.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={filterDialog}
                TransitionComponent={Zoom}
                transitionDuration={1000}
                disableBackdropClick
            >
                <DialogTitle>
                    <Typography color="primary">
                        Filter By Date
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={{
                        user_id: user_id,
                        token: token,
                        management_id: management_id,
                        date_from: '',
                        date_to: '',
                    }}
                    validationSchema={Yup.object().shape({
                        date_from: Yup.string().required(),
                        date_to: Yup.string().required(),
                    })}
                    onSubmit={async (values, {
                        setErrors,
                        setSubmitting,
                        resetForm
                    }) => {
                        try {
                            const request = await Axios.post('malita/pharmacy/get-filter-by-date', getFormData(values))
                            const data = request.data;
                            setSales(data);
                            setSalesReady(true);
                            Notify.successRequest('filter')
                            resetForm();
                        } catch (error) {
                            const message = error.message || 'Something went wrong';
                            setErrors({ submit: message });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                        <form
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <DialogContent dividers>
                                <Box mb={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Box mb={1}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label="Date From"
                                                    error={Boolean(touched.date_from && errors.date_from)}
                                                    helperText={touched.date_from && errors.date_from}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.date_from}
                                                    name="date_from"
                                                    variant="outlined"
                                                    type="date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Box mb={1}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label="Date To"
                                                    error={Boolean(touched.date_to && errors.date_to)}
                                                    helperText={touched.date_to && errors.date_to}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.date_to}
                                                    name="date_to"
                                                    variant="outlined"
                                                    type="date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {errors.submit && (
                                    <Box mt={3}>
                                        <FormHelperText error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}

                                <Box mb={1} display="flex">
                                    <Box flexGrow={1} />
                                    <Button
                                        variant="contained"
                                        color="default"
                                        startIcon={<ClearIcon />}
                                        onClick={() => setFilterDialog(false)}
                                    >
                                        Close
                                    </Button>
                                    <Box ml={2}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CheckIcon />}
                                        >
                                            Go
                                        </Button>
                                    </Box>
                                </Box>

                            </DialogContent>
                        </form>
                    )}
                </Formik>
            </Dialog>

            <Dialog
                open={printSalesDiag}
                TransitionComponent={Zoom}
                transitionDuration={500}
                fullScreen
            >
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <Typography component={Box} fontWeight="bold" align="center" variant="h6" >
                                        Sales Report
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        className={'d-print-none'}
                                        color="secondary"
                                        onClick={() => setPrintSalesDialog(false)}
                                    >
                                        <HighlightOffIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <SalesReportPrint />
                    </Grid>
                </DialogContent>
            </Dialog>
        </Fragment>
    )

}

export default Sales;