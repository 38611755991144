import React, { Component, Fragment } from 'react';  
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppointmentApprovedDetailsMsgForm from './AppointmentApprovedDetailsMsgForm';
import IconButton from '@material-ui/core/IconButton';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { Dialog, Zoom, DialogTitle, Avatar, Box, Grid, Card, CardContent, TextField, Typography, CardHeader, ButtonGroup, Button, InputAdornment } from '@material-ui/core';
 
import VideocamIcon from '@material-ui/icons/Videocam';
import AppointmentApprovedDetailsReadmore from './AppointmentApprovedDetailsReadmore';
import AppointmentActionReschedule from './AppointmentActionReschedule';
import CallPatient from '../onlinecheckup/CallPatient';
import { green } from '@material-ui/core/colors';

const imageLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL; 
 

export default class AppointmentApprovedDetails extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _appid: this.props.appid,

            _appdetails: [],
            _appdetails_ready: false,

            _patient: [],
            _patient_ready: false,

            _notif_dialog: false,
            _selected_patient_id: null,

            _is_process: false,
            _is_readmore: false,

            _reshedappdialog : false,
            _appactionid: null,
            _appactiondate: null,
            
            _vcall_dialog: false,
            _vcall_appid: null, 
        }  
	} 

    getAppDetails(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);    
        formdata.set('appointment_id', this.props.appid) 
        formdata.set('connection', this.props.connection) 
        
        Axios.post('appointment/patient/appointment-detail', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _appdetails: data,
                    _appdetails_ready: true,
                })
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }
    
    patientInformation(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('management_id', this.state._management_id);   
        formdata.set('user_id', this.props.patient_id);    
        formdata.set('connection', this.props.connection); 
        
        Axios.post('patient/information/personal-info', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){  
                this.setState({
                    _patient: data,
                    _patient_ready: true,
                }) 
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    componentDidUpdate(){
        if(this.state._appid !== this.props.appid){
            this.setState({
                _appid: this.props.appid,
                _patient_ready: false,
            })
            this.componentDidMount();
        }
    }

    componentDidMount(){
        this._mounted = true;
        this.getAppDetails();
        this.patientInformation(); 
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    closeDialog = () =>{
        this.setState({
            _notif_dialog: false,
            _selected_patient_id: null
        })
    }  

    handleAppReschedule = (e) => {
        this.setState({
            _appactionid: e.currentTarget.getAttribute('appid'),
            _appactiondate: e.currentTarget.getAttribute('appdate'),
            _reshedappdialog : true
        })
    }

	render(){ 
		return(
			<Fragment>  
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        {/* patient personal information */}
                        <Card component={Box} mb={2} variant="outlined">
                            <Box display={'flex'}>
                                <Box flexGrow={1}>
                                    <CardHeader
                                        title={
                                            <Typography variant="subtitle2" color="primary">
                                                PERSONAL INFORMATION
                                            </Typography>
                                        }
                                    />
                                </Box>
                                <Box>
                                    <IconButton color="secondary" onClick={ () => this.props.resetDisplay() }> 
                                        <HighlightOff />
                                    </IconButton>
                                </Box>
                            </Box>

                            <CardContent>
                                {
                                    this.state._patient_ready ? 
                                        this.state._patient.length > 0 ?
                                            <>  
                                                <Box mb={2}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={12} md={3}>
                                                            <Box align="center">
                                                                <Avatar 
                                                                    style={{
                                                                        width: '150px',
                                                                        height: '150px'
                                                                    }} 
                                                                    src={this.state._patient[0].image !== null ? imageLocation+'patients/'+this.state._patient[0].image : ''}
                                                                /> 
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} md={9}>
                                                            <Box className={`gtc-textfield-noborder`} mb={2}>
                                                                <Box mb={2}>
                                                                    <TextField
                                                                        label="Name"
                                                                        fullWidth
                                                                        InputProps={{ readOnly: true }}
                                                                        variant="outlined"
                                                                        multiline
                                                                        value={` ${ this.state._patient[0].firstname }  ${ this.state._patient[0].middle }  ${ this.state._patient[0].lastname } `}
                                                                    />
                                                                </Box>

                                                                <Box mb={2}>
                                                                    <TextField
                                                                        label="Birthday"
                                                                        fullWidth
                                                                        InputProps={{ readOnly: true }}
                                                                        variant="outlined"
                                                                        multiline
                                                                        value={` ${ this.state._patient[0].birthday === null ? 'None' : Notify.birthday(this.state._patient[0].birthday) }`}
                                                                    />
                                                                </Box>

                                                                <Box mb={2}>
                                                                    <TextField
                                                                        label="Birthday"
                                                                        fullWidth
                                                                        InputProps={{ readOnly: true }}
                                                                        variant="outlined"
                                                                        multiline
                                                                        value={` ${ this.state._patient[0].birthday === null ? 'None' : Notify.calculateAge(this.state._patient[0].birthday) }`}
                                                                    />
                                                                </Box>
                                                                    
                                                                <Box mb={2}>
                                                                    <TextField
                                                                        label="Birthday"
                                                                        fullWidth
                                                                        InputProps={{ readOnly: true }}
                                                                        variant="outlined"
                                                                        multiline
                                                                        value={` ${ this.state._patient[0].gender === null ? 'None' : this.state._patient[0].gender }`}
                                                                    />
                                                                </Box> 

                                                            </Box>

                                                            <Box align="center">
                                                                <ButtonGroup color="primary" fullWidth> 
                                                                    <Button
                                                                        onClick={ () => this.setState({ _is_readmore : !this.state._is_readmore })}
                                                                    >
                                                                        { this.state._is_readmore ? `details` : `read more...` }  
                                                                    </Button>

                                                                    <Button
                                                                        onClick = {() => 
                                                                            this.setState({ 
                                                                                _notif_dialog: true ,
                                                                                _selected_patient_id: this.state._patient[0].patient_id ,
                                                                            })
                                                                        }
                                                                        startIcon={ <FontAwesomeIcon icon={faEnvelope} />  }
                                                                    >
                                                                        Message
                                                                    </Button>

                                                                    { this.state._appdetails.length > 0 &&( 
                                                                        <Button
                                                                            appid = { this.state._appdetails[0].appointment_id }
                                                                            appdate = { this.state._appdetails[0].appointment_date }
                                                                            onClick = { this.handleAppReschedule }
                                                                            startIcon={ <FontAwesomeIcon icon={faCalendarAlt} />  }
                                                                        >
                                                                            Reschedule
                                                                        </Button> 
                                                                    )}

                                                                    { this.state._appdetails.length > 0 &&(
                                                                        <Button 
                                                                            appid = { this.state._appdetails[0].appointment_id }
                                                                            appdate = { this.state._appdetails[0].appointment_date }
                                                                            onClick = { this.handleAppReschedule }
                                                                            endIcon={ <VideocamIcon />  }
                                                                            component={Link} 
                                                                            style={{ backgroundColor: green[600], color: '#fff' }}
                                                                            to={`/app/doctor/appointment/room/${this.state._appdetails[0].reference_no}/${Notify.randomizeString(this.state._user_id)}`}
                                                                        >
                                                                            Video Call
                                                                        </Button>
                                                                    )} 

                                                                </ButtonGroup>
                                                            </Box> 
                                                        </Grid>
                                                    </Grid> 
                                                </Box>   
                                            </>
                                        :  Notify.noRecord()
                                    : Notify.loading()
                                } 
                            </CardContent>  
                        </Card>
                    </Grid>
                </Grid>

                <Row> 
                    {/* patient appointment information */}
                    <Col sm={12}>
                        <Box hidden={ this.state._is_readmore }>
                            <Card component={Box} mb={2} py={2} variant="outlined">
                                <CardContent>
                                    {
                                        this.state._appdetails_ready ? 
                                            this.state._appdetails.length > 0 ?
                                                <Fragment>
                                                    <Row className="mb-3">
                                                        <Col sm={6}> 
                                                            <Box >
                                                                <TextField
                                                                    label="Reference Number"
                                                                    fullWidth
                                                                    InputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                    multiline
                                                                    value={` ${ this.state._appdetails[0].reference_no === null ? 'None' : this.state._appdetails[0].reference_no }`}
                                                                />
                                                            </Box> 
                                                        </Col>
                                                        <Col sm={6}>
                                                            <Box >
                                                                <TextField
                                                                    label="Appointment Date"
                                                                    fullWidth
                                                                    InputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                    multiline
                                                                    value={` ${ Boolean(parseInt(this.state._appdetails[0].is_reschedule))  ? Notify.dateTimeConvert(this.state._appdetails[0].is_reschedule_date)  : Notify.dateTimeConvert(this.state._appdetails[0].appointment_date) }`}
                                                                />
                                                            </Box>  
                                                        </Col>
                                                    </Row> 

                                                    <Row className="mb-3">
                                                        <Col sm={6}>
                                                            <Box >
                                                                <TextField
                                                                    label="Appointment Type"
                                                                    fullWidth
                                                                    InputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                    multiline
                                                                    value={` ${ this.state._appdetails[0].services === null ? 'None' : this.state._appdetails[0].services }`}
                                                                />
                                                            </Box>  
                                                        </Col>

                                                        <Col sm={6}>
                                                            <Box >
                                                                <TextField
                                                                    label="Appointment Credit"
                                                                    fullWidth
                                                                    InputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                    multiline
                                                                    value={` ${ this.state._appdetails[0].credit_fee }`}
                                                                />
                                                            </Box>  
                                                        </Col>
                                                    </Row>  
                                                    
                                                    <Box mb={2}>
                                                        <TextField
                                                            label="Appointment Reason"
                                                            fullWidth
                                                            InputProps={{ readOnly: true }}
                                                            variant="outlined"
                                                            multiline
                                                            value={` ${ this.state._appdetails[0].appointment_reason === null ? 'None' : this.state._appdetails[0].appointment_reason }`} 
                                                        />
                                                    </Box> 
 
                                                    <Box >
                                                        <TextField
                                                            label="Appointment Reason"
                                                            fullWidth
                                                            InputProps={{ readOnly: true, endAdornment: (
                                                                <InputAdornment position="start">
                                                                    <a rel="noopener noreferrer" className="pointer float-right" target="_blank" href={imageLocation+'appointment/'+this.state._appdetails[0].attachment} download> download </a> 
                                                                </InputAdornment>
                                                            )}}
                                                            variant="outlined"
                                                            multiline
                                                            value={` ${ this.state._appdetails[0].attachment === null ? 'None' : this.state._appdetails[0].attachment }`}  
                                                            
                                                        />
                                                    </Box>   
                                                </Fragment>
                                            : Notify.noRecord()
                                        : Notify.loading()
                                    }
                                </CardContent>
                            </Card>

                            <Card component={Box} mb={2} py={2} variant="outlined">
                                <CardContent>
                                    {
                                        this.state._appdetails_ready ? 
                                            this.state._appdetails.length > 0 ?
                                                <Fragment>
                                                    <Box mb={2}>
                                                        <TextField
                                                            label="Appointment Status"
                                                            fullWidth
                                                            InputProps={{ readOnly: true }}
                                                            variant="outlined"
                                                            multiline
                                                            value={` ${ this.state._appdetails[0].appointment_status === null ? 'None' : this.state._appdetails[0].appointment_status }`}
                                                        />
                                                    </Box> 

                                                    <Box mb={2}>
                                                        <TextField
                                                            label="Process Message "
                                                            fullWidth
                                                            InputProps={{ readOnly: true }}
                                                            variant="outlined"
                                                            multiline
                                                            value={` ${ this.state._appdetails[0].process_message === null ? 'None' : this.state._appdetails[0].process_message }`}
                                                        />
                                                    </Box> 

                                                    <Box mb={2}>
                                                        <TextField
                                                            label="Appointment Reschedule"
                                                            fullWidth
                                                            InputProps={{ readOnly: true }}
                                                            variant="outlined"
                                                            multiline
                                                            value={` ${ Boolean(parseInt(this.state._appdetails[0].is_reschedule)) ? 'Yes' : 'No'}`}
                                                        />
                                                    </Box>  

                                                    <Box mb={2}>
                                                        <TextField
                                                            label="Appointment Reschedule Reason"
                                                            fullWidth
                                                            InputProps={{ readOnly: true }}
                                                            variant="outlined"
                                                            multiline
                                                            value={` ${ Boolean(parseInt(this.state._appdetails[0].is_reschedule)) ? this.state._appdetails[0].is_reschedule_reason : 'None'}`}
                                                        />
                                                    </Box>  
                                                    
                                                </Fragment>
                                            : Notify.noRecord()
                                        : Notify.loading()
                                    }
                                </CardContent>
                            </Card>
                        </Box>

                        <Box hidden={ !this.state._is_readmore }>
                            { this.state._patient.length > 0 ? 
                                <AppointmentApprovedDetailsReadmore connection={this.props.connection} patient_id ={ this.state._patient[0].patient_id } /> : null
                            }
                        </Box>
                    </Col>
                </Row> 

                {/* send notification message dialog */}  
                    <Dialog 
                        open={this.state._notif_dialog}
                        TransitionComponent={Zoom}
                        transitionDuration={1000} 
                        onClose={ this.closeDialog }  
                    >   
                        <DialogTitle>
                            Message
                        </DialogTitle>
                        <AppointmentApprovedDetailsMsgForm
                            patient_id = {this.state._selected_patient_id}
                            appid = {this.props.appid}
                            closeDialog = {this.closeDialog}
                            connection={this.props.connection}
                        />
                    </Dialog>
                {/* appointment reshedule */}
                <Dialog 
                    open={this.state._reshedappdialog}
                    TransitionComponent={Zoom}
                    transitionDuration={600} 
                    onClose={ () => this.setState({ _reshedappdialog : false }) }  
                > 
                    <DialogTitle>
                         Reschedule Appointment
                    </DialogTitle> 
                    <AppointmentActionReschedule
                        appid = {this.state._appactionid}
                        appdate = { this.state._appactiondate }
                        closeDialog = { () => this.setState({ _reshedappdialog : false })}
                        getRequestapp = { this.getAppDetails.bind(this) }
                        connection={this.props.connection}
                    /> 
                </Dialog>
			
                {/* videocall patient */}
                { this.state._vcall_dialog  && (
                    <CallPatient 
                        open = { this.state._vcall_dialog }
                        close = { () => this.setState({ _vcall_dialog: false }) }
                        appdetails = { this.state._vcall_appid }
                        connection={this.props.connection}
                    />
                )} 
            </Fragment>
		)
	}
}
