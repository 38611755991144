import React, { useState, Fragment, useCallback, useEffect } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Box, Grid, Button, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, TablePagination, Paper, Typography, Menu, IconButton, MenuItem, Zoom, Dialog, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MoreVert from '@material-ui/icons/MoreVert';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import EditIcon from '@material-ui/icons/Edit';
import AccountEdit from './AccountModal/AccountEdit';
import AccountDeActivate from './AccountModal/AccountDeActivate';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AccountActivate from './AccountModal/AccountActivate';
import PersonAddSharpIcon from '@material-ui/icons/PersonAddSharp';
import AccountAddUser from './AccountModal/AccountAddUser';

const PharmacyUsers = () => {
    const [token] = useState(localStorage.getItem('token'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [users, setUsers] = useState([]);
    const [usersReady, setUsersReady] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedUserId, setSelectedUserID] = useState(null)
    const [selectedName, setSelectedName] = useState(null)
    const [selectedUsername, setSelectedUsername] = useState(null)
    const [editDialog, setEditDialog] = useState(false)
    const [deActiveDialog, setDeActiveDialog] = useState(false)
    const [activateDialog, setActivateDialog] = useState(false)
    const [addUserDialog, setAddUserDialog] = useState(false)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setSelectedUserID(event.currentTarget.id)
        setSelectedUsername(event.currentTarget.getAttribute('uusername'))
        setSelectedName(event.currentTarget.getAttribute('fullname'))
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchUsers = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);

        Axios.post('malita/pharmacy/get-users-list', formdata)
            .then((response) => {
                const data = response.data;
                setUsers(data);
                setUsersReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, management_id])

    useEffect(() => {
        
        fetchUsers();

    }, [fetchUsers])


    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                >
                    <Paper elevation={3} variant="outlined">
                        <Box>
                            <Box m={1}>
                                <Box display="flex">
                                    <Box flexGrow={1} />
                                    <Box mt={1} mb={1}>
                                        <Button
                                            // hidden={props.connection === 'online'}
                                            color="primary"
                                            onClick={() => setAddUserDialog(true)}
                                            startIcon={<PersonAddSharpIcon />}
                                        >
                                            User
                                        </Button>
                                    </Box>
                                </Box>
                                <Divider />
                                <TableContainer component={Box} borderColor="grey.200" bgcolor="white">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center"> Full Name </TableCell>
                                                <TableCell align="center"> Account Type </TableCell>
                                                <TableCell align="center"> Status </TableCell>
                                                <TableCell align="center"> Action </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                usersReady ?
                                                    users.length > 0 ?
                                                        users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            .map((data, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell bgcolor="white"> {data.name} </TableCell>
                                                                    <TableCell bgcolor="white"> {data.role} </TableCell>
                                                                    <TableCell style={{ color: data.status === 1 ? 'green' : 'red' }} bgcolor="white" align="center"> {data.status === 1 ? 'active' : 'deactive'} </TableCell>
                                                                    <TableCell bgcolor="white" align="center">
                                                                        <IconButton aria-label="delete" aria-controls="simple-menu" aria-haspopup="true" id={data.user_id} fullname={data.name} uusername={data.username} onClick={handleClick} >
                                                                            <MoreVert fontSize="small" />
                                                                        </IconButton>
                                                                        <Menu
                                                                            id="simple-menu"
                                                                            anchorEl={anchorEl}
                                                                            keepMounted
                                                                            open={Boolean(anchorEl)}
                                                                            onClose={handleClose}
                                                                        >
                                                                            <MenuItem onClick={() => setEditDialog(true)}> <EditIcon color="primary" /> Edit </MenuItem>
                                                                            {parseFloat(data.status) === 1 && (<MenuItem onClick={() => setDeActiveDialog(true)}> <PersonAddDisabledIcon color="secondary" /> Deactivate </MenuItem>)}
                                                                            {parseFloat(data.status) === 0 && (<MenuItem onClick={() => setActivateDialog(true)}> <VerifiedUserIcon color="primary" /> Activate </MenuItem>)}
                                                                        </Menu>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        : <TableRow>
                                                            <TableCell colSpan={4}>
                                                                <Typography variant="subtitle2" color="secondary"> No users found </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    : <TableRow>
                                                        <TableCell colSpan={4}>
                                                            <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>

                                    <TablePagination
                                        className={'d-print-none'}
                                        component={"div"}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={3}
                                        count={users.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TableContainer>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Dialog
                open={activateDialog}
                TransitionComponent={Zoom}
                transitionDuration={1000}
            >
                <AccountActivate selectedUserId={selectedUserId} closeModal={() => setActivateDialog(false)} reRenderModal={() => { fetchUsers(); setEditDialog(false); setSelectedUserID(null); setSelectedName(null); setSelectedUsername(null) }} />
            </Dialog>

            <Dialog
                open={deActiveDialog}
                TransitionComponent={Zoom}
                transitionDuration={1000}
            >
                <AccountDeActivate selectedUserId={selectedUserId} closeModal={() => setDeActiveDialog(false)} reRenderModal={() => { fetchUsers(); setEditDialog(false); setSelectedUserID(null); setSelectedName(null); setSelectedUsername(null) }} />
            </Dialog>

            <Dialog
                open={editDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Zoom}
                transitionDuration={1000}
                disableBackdropClick={true}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" justifyContent="center" >
                        <Box flexGrow={1}>
                            <Typography variant="h5">
                                Edit User
                            </Typography>
                        </Box>
                        <IconButton
                            className={'d-print-none'}
                            color="secondary"
                            onClick={() => setEditDialog(false)}
                        >
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <AccountEdit closeModal={() => { fetchUsers(); setEditDialog(false); setSelectedUserID(null); setSelectedName(null); setSelectedUsername(null) }} selectedUserId={selectedUserId} selectedName={selectedName} selectedUsername={selectedUsername} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={addUserDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Zoom}
                transitionDuration={1000}
                disableBackdropClick={true}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display="flex" justifyContent="center" >
                        <Box flexGrow={1}>
                            <Typography variant="h5">
                                Add User
                            </Typography>
                        </Box>
                        <IconButton
                            className={'d-print-none'}
                            color="secondary"
                            onClick={() => setAddUserDialog(false)}
                        >
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <AccountAddUser closeModal={() => { fetchUsers(); setAddUserDialog(false); }} />
                </DialogContent>
            </Dialog>
        </Fragment >
    )
}

export default PharmacyUsers;