import React from 'react'
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

const DraggableDialog = (props) => {
    return (
      <Draggable handle="#draggable-handle" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

export default DraggableDialog;