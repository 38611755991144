import React from 'react'
import { Box, Paper, Typography, Grid } from '@material-ui/core';
import QRCode from 'qrcode.react';

const MyQRCode = () => {
    const user_id = localStorage.getItem('user_id');

    return (
        <>
            <Paper variant="outlined">
                <Box m={4} />
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Grid container justify="center" alignContent="center">
                            <QRCode value={user_id} level="H" size={200} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="center" alignContent="center">
                            <Box m={4}>
                                <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                >
                                    This is for delivery purposes only.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default MyQRCode;