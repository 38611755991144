
import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import LaboratoryReport from './laboratoryreport/LaboratoryReport';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { grey } from '@material-ui/core/colors';
import LocalPrescriptionReport from './prescriptionreport/LocalPrescriptionReport';
import VirtualPrescriptionReport from './prescriptionreport/VirtualPrescriptionReport';
import ListAltIcon from '@material-ui/icons/ListAlt';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const Report = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [expand, setExpand] = useState(false);
    const [displayDefault, setDisplayDefault] = useState('laboratory');
    const [innerWidth] = useState(window.innerWidth);

    const checkingWidth = () => {
        if (innerWidth < 745) {
            setExpand(true);
        }
        else {
            setExpand(false);
        }
    }

    useEffect(() => {
        checkingWidth()
        //eslint-disable-next-line
    }, [])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Box m={2} variant="outlined" >
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Box width={expand ? 60 : 200} border={1} borderColor={grey[500]} borderRadius={6}>
                                    <List>
                                        <ListItem button onClick={() => setExpand(!expand)}>
                                            {expand ? <MenuIcon /> : null}
                                            <ListItemText primary={expand ? '' : 'Menu'} />
                                            {expand ? null : <MenuOpenIcon />}
                                        </ListItem>

                                        <Divider />

                                        <ListItem button onClick={() => { setOpen(false); setDisplayDefault('laboratory') }}>
                                            <ListItemIcon>
                                                <ApartmentIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={expand ? '' : 'Laboratory'} />
                                        </ListItem>

                                        <Divider />

                                        <ListItem button onClick={() => setOpen(!open)}>
                                            <ListItemIcon>
                                                <ListAltIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={expand ? '' : 'Prescription'} />
                                        </ListItem>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItem button className={expand ? '' : classes.nested} onClick={() => setDisplayDefault('prescription-local')}>
                                                    <ListItemIcon>
                                                        <WifiOffIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={expand ? '' : 'Local'} />
                                                </ListItem>

                                                <ListItem button className={expand ? '' : classes.nested} onClick={() => setDisplayDefault('prescription-virtual')}>
                                                    <ListItemIcon>
                                                        <WifiIcon />
                                                    </ListItemIcon>
                                                    <ListItemText variant="primary" primary={expand ? '' : 'Virtual'} />
                                                </ListItem>
                                            </List>
                                        </Collapse>
                                    </List>
                                </Box>
                            </Box>
                            <Box width={"100%"} >
                                <Grid container>
                                    <Grid item sm={12} xs={12}>
                                        <Box border={1} borderColor={grey[500]} borderRadius={6} ml={2}>
                                            {displayDefault === 'laboratory' && (
                                                <LaboratoryReport />
                                            )}
                                            {displayDefault === 'prescription-local' && (
                                                <LocalPrescriptionReport />
                                            )}
                                            {displayDefault === 'prescription-virtual' && (
                                                <VirtualPrescriptionReport />
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Report;
