import { Box, Typography } from '@material-ui/core';
import React from 'react';
import Notify from 'src/notification/Notify';

const Header = ({ details }) =>{
    
    return(
        <>
            {/* paitent information */}
            <Box display="flex">
                <Box flexGrow={1} mb={2}>
                    <Box mb={2}> 
                        <Typography> 
                            <Typography variant="caption" className="font-weight-bold">
                                NAME:
                            </Typography> <span className="text-capitalize"> {`${details.data.firstname } ${details.data.lastname }`} </span> 
                        </Typography>
                    </Box>

                    <Box display="flex" mb={2}>
                        <Box>
                            <Typography> 
                                <Typography variant="caption" className="font-weight-bold">
                                    AGE:
                                </Typography>  { details.data.birthday === null ? "None" : Notify.calculateAge(details.data.birthday) }
                            </Typography>
                        </Box>
                        <Box ml={5}>
                            <Typography> 
                                <Typography variant="caption" className="font-weight-bold">
                                    SEX:
                                </Typography>  { details.data.gender === null ? "None" : details.data.gender  } 
                            </Typography>
                        </Box>
                    </Box>
                    
                    <Box>
                        <Typography>  
                            <Typography variant="caption" className="font-weight-bold">
                                ADDRESS:
                            </Typography> <span className="text-capitalize"> {`${details.data.street} ${details.data.barangay} ${details.data.city} `} </span> 
                        </Typography>
                    </Box>
                </Box>  

                <Box>
                    <Box mb={2}>  
                        <Typography> 
                            <Typography variant="caption" className="font-weight-bold">
                                DATE:
                            </Typography>  { new Date().toLocaleString() }
                        </Typography> 
                    </Box>  
                </Box>
            </Box>
        </>
    )
}
export default Header;