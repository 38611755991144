import React, { useContext, useState } from 'react';   
import {  faCircleNotch, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  
import Notify from '../../notification/Notify';
import Axios from 'axios';
import Box from '@material-ui/core/Box' 
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { PackageContext } from '../../PackageContext';
import { Grid, Typography, Button, Paper, TextField, Divider } from '@material-ui/core';
 
const  PersonalVitals  = ({ getPersonalInformation, patient }) => {

    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')
    // const management_id = localStorage.getItem('management_id')
    // const type = localStorage.getItem('type')
    const username = localStorage.getItem('username')
    
    const [isProcess, setIsProcess] = useState(false)
    const [enableEdit, setEnableEdit] = useState(false)

    const contextData = useContext(PackageContext)
    
    const [temp, settemp] = useState(''); 
    const [systolic, setsystolic] = useState('')
    const [diastolic, setdiastolic] = useState('')
    const [pulse, setpulse] = useState('')
    const [weight, setweight] = useState('')
    const [resp, setresp] = useState('')
    const [gluc, setgluc] = useState('')
    const [uric, seturic] = useState('')
    const [choles, setcholes] = useState('') 
    
    const checker = (value) =>{ 
        if(value !== null && parseInt(value.length) > 0){
            return value;
        }else{
            return 'None'
        }
    }

    const resetForm = () =>{
        settemp('')
        setsystolic('')
        setdiastolic('')
        setpulse('')
        setweight('')
        setresp('')
        setgluc('')
        seturic('')
        setcholes('') 
    }

    const handleNewHealth = (e) =>{
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        var error = [];
        formdata.set('token', token); 
        formdata.set('user_id', user_id); 
        formdata.set('username', username);  
        formdata.set('patient_id', patient[0].patient_id);  
        
        if(
            formdata.get('temperature').trim()==='' && 
            formdata.get('diastolic').trim()==='' && 
            formdata.get('systolic').trim()==='' && 
            formdata.get('pulse').trim()==='' && 
            formdata.get('weight').trim()==='' && 
            formdata.get('respiratory').trim()==='' && 
            formdata.get('glucose').trim()==='' && 
            formdata.get('uricacid').trim()==='' && 
            formdata.get('cholesterol').trim()===''  
        ){
            Notify.customToast('No Field', 'Select field to update.')
            error = 'error';
        }

        if(formdata.get('systolic').trim() !=='' || formdata.get('diastolic').trim() !==''){
            if(formdata.get('systolic').trim() === ''){
                Notify.fieldRequired('Systolic')
                error = 'error';
            }
            if(formdata.get('diastolic').trim() === ''){
                Notify.fieldRequired('diastolic')
                error = 'error';
            }
        }
        
        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){
            Notify.fieldInvalid('password')
            error = 'error';
        }

        if(error.length > 0){
            console.warn("Form has an error. ")
        }else{ 
            setIsProcess(true)
            Axios.post('patient/information/personal-health', formdata)
            .then((response) =>{
                const data = response.data
                if(data === 'success'){ 
                    getPersonalInformation()
                    setEnableEdit(false)
                    resetForm()
                    Notify.successRequest("health update")
                } 
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'db-error'){
                    Notify.warnRequest('health update')
                }
            }).catch((error) =>{
                Notify.requestError(error)
            }).finally(() =>{
                setIsProcess(false)
            })
        }
    }  

    return( 
        <>  
            <Box
                component={Paper}
                variant="outlined"
                p={3} 
            > 
                <form onSubmit={ handleNewHealth }>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    <Typography variant="subtitle2" color="primary">
                                        HEALTH MONITORING
                                    </Typography>
                                </Box>

                                <Box>
                                    <Button  
                                        color="primary"
                                        hidden = { contextData.__package === null ? true : contextData.__package.subscription !== 'standard' ? enableEdit ? true : false : true }  
                                        onClick ={ () => setEnableEdit(true)}
                                        startIcon={ <AddCircleOutlineIcon />}
                                    >
                                        Update
                                    </Button>
                                </Box>
                            </Box>

                            <Box
                                hidden={ contextData.__package === null ? true : contextData.__package.subscription !== 'standard' ? true : false }
                            >
                                <Typography variant="caption" color="secondary">
                                    Your current subscription is not allowed to update your health monitoring.
                                </Typography>
                            </Box> 
                        </Grid>

                        <Grid item xs={12} sm={12} className={ enableEdit ? "" : "gtc-textfield-noborder"}> 
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Grid container spacing={3}>
                                        <Grid item sm={12} xs={12}>
                                            <TextField 
                                                label="Temperature (&#8451;)"
                                                variant="outlined"
                                                fullWidth
                                                name="temperature"
                                                value={ ! enableEdit ? checker(patient[0].temperature) : temp }
                                                onChange = {(e) => settemp(e.target.value)} 
                                                type="number"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 1000,
                                                        step: '0.01',
                                                        readOnly: ! enableEdit
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <Box display="flex">
                                                <Box flexGrow={1} mr={1}>
                                                    <TextField 
                                                        label="BP (systolic)"
                                                        variant="outlined"
                                                        fullWidth
                                                        name="systolic" 
                                                        value={ ! enableEdit ? checker(patient[0].blood_systolic) : systolic }
                                                        onChange = {(e) => setsystolic(e.target.value)} 
                                                        type="number"
                                                        InputProps={{
                                                            inputProps:{
                                                                min: 1,
                                                                max: 1000,
                                                                step: '0.01',
                                                                readOnly: ! enableEdit
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <TextField 
                                                        label="BP (diastolic)"
                                                        variant="outlined"
                                                        fullWidth
                                                        name="diastolic"
                                                        value={ ! enableEdit ? checker(patient[0].blood_diastolic) : diastolic }
                                                        onChange = {(e) => setdiastolic(e.target.value)} 
                                                        type="number"
                                                        InputProps={{
                                                            inputProps:{
                                                                min: 1,
                                                                max: 1000,
                                                                step: '0.01',
                                                                readOnly: ! enableEdit
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextField 
                                                label="Pulse (bpm)"
                                                variant="outlined"
                                                fullWidth
                                                name="pulse" 
                                                value={ ! enableEdit ? checker(patient[0].pulse) : pulse }
                                                onChange = {(e) => setpulse(e.target.value)} 
                                                type="number"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 1000,
                                                        step: '0.01',
                                                        readOnly: ! enableEdit
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextField 
                                                label="Weight (kg)"
                                                variant="outlined"
                                                fullWidth
                                                name="weight" 
                                                value={ ! enableEdit ? checker(patient[0].weight) : weight }
                                                onChange = {(e) => setweight(e.target.value)} 
                                                type="number"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 1000,
                                                        step: '0.01',
                                                        readOnly: ! enableEdit
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>

                                <Grid item xs={12} sm={12} md={4}>
                                    <Grid container spacing={3}>
                                        <Grid item sm={12} xs={12}>
                                            <TextField 
                                                label="Respiratory (bpm)"
                                                variant="outlined"
                                                fullWidth
                                                name="respiratory" 
                                                value={ ! enableEdit ? checker(patient[0].rispiratory) : resp }
                                                onChange = {(e) => setresp(e.target.value)}  
                                                type="number"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 1000,
                                                        step: '0.01',
                                                        readOnly: ! enableEdit
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextField 
                                                label="Glucose (mg/dL)"
                                                variant="outlined"
                                                fullWidth
                                                name="glucose"
                                                value={ ! enableEdit ? checker(patient[0].glucose) : gluc }
                                                onChange = {(e) => setgluc(e.target.value)}  
                                                type="number"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 1000,
                                                        step: '0.01',
                                                        readOnly: ! enableEdit
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextField 
                                                label="Uric Acid  (mg/dL)"
                                                variant="outlined"
                                                fullWidth
                                                name="uricacid" 
                                                value={ ! enableEdit ? checker(patient[0].uric_acid) : uric }
                                                onChange = {(e) => seturic(e.target.value)}  
                                                type="number"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 1000,
                                                        step: '0.01',
                                                        readOnly: ! enableEdit
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item sm={12} xs={12}>
                                            <TextField 
                                                label="Cholesterol  (mg/dL)"
                                                variant="outlined"
                                                fullWidth
                                                name="cholesterol"  
                                                value={ ! enableEdit ? checker(patient[0].cholesterol) : choles }
                                                onChange = {(e) => setcholes(e.target.value)}  
                                                type="number"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 1000,
                                                        step: '0.01',
                                                        readOnly: ! enableEdit
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={4}>
                                    <Grid container spacing={3}>
                                        <Grid item sm={12} xs={12}> 
                                            <TextField 
                                                label="Hepatitis"
                                                variant="outlined"
                                                fullWidth 
                                                defaultValue={patient[0].hepatitis}
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                hidden={ patient[0].hepatitis === null }
                                            />
                                        </Grid> 

                                        <Grid item sm={12} xs={12}> 
                                            <TextField 
                                                label="Dengue"
                                                variant="outlined"
                                                fullWidth 
                                                InputProps={{
                                                    readOnly: true
                                                }}
                                                defaultValue={patient[0].dengue}
                                                hidden={ patient[0].dengue === null }
                                            />
                                        </Grid> 

                                        <Grid item sm={12} xs={12}> 
                                            <Box>
                                                <TextField 
                                                    label="Tuberculosis"
                                                    variant="outlined"
                                                    fullWidth 
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                    defaultValue={patient[0].tuberculosis}
                                                    hidden={ patient[0].tuberculosis === null }
                                                />
                                            </Box>
                                        </Grid> 
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sm={12} hidden={ ! enableEdit }>  
                                <Box my={2}>
                                    <TextField 
                                        label="Enter your password"
                                        variant="outlined"
                                        fullWidth 
                                        name="password"
                                        type="password"  
                                    />
                                </Box>

                                <Divider />

                                <Box mt={2} display="flex">
                                    <Box mr={2}>
                                        <Button 
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={isProcess }
                                            startIcon={
                                                <FontAwesomeIcon 
                                                    icon={isProcess ? faCircleNotch : faCheckCircle } 
                                                    spin={isProcess} 
                                                /> 
                                            }
                                        >  
                                            Save 
                                        </Button>
                                    </Box>
                                    <Button variant="contained" color="default" onClick={()=> setEnableEdit(false)} startIcon={ <FontAwesomeIcon icon={faTimesCircle} /> }>  No </Button>
                                </Box> 
                            </Grid> 
                        </Grid>
                    </Grid> 
                </form>
            </Box>
        </> 
    )
}
export default PersonalVitals; 