import React, { Component, Fragment } from 'react';  
import Axios from 'axios';
import Notify from '../../notification/Notify'; 
import { Box, Button, DialogActions, DialogContent } from '@material-ui/core';
const fileLocation = process.env.REACT_APP_API_IMAGE_VIRTUAL; 

export default class AppointmentDetails extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _appid: this.props.appid,
            _details: [],
            _details_ready: false,
		}
	} 

    getAppDetails(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);    
        formdata.set('appointment_id', this.props.appid)
        formdata.set('connection', this.props.connection)
        
        Axios.post('appointment/patient/appointment-detail', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _details: data,
                    _details_ready: true,
                })
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }
    
    componentDidUpdate(){
        if(this.state._appid !== this.props.appid){
            this.setState({
                _appid: this.props.appid
            })
            this.componentDidMount();
        }
    }

    componentDidMount(){
        this._mounted = true;
        this.getAppDetails();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

	render(){ 
		return(
			<Fragment>
                <DialogContent dividers>
                    {
                        this.state._details_ready ? 
                            this.state._details.length > 0 ?
                                <Box>
                                    <Box className="mb-3">
                                        <span className="text-muted text-uppercase gtc-small"> Appointment Date </span>
                                        <p className={"m-0"}> 
                                            <span className={ this.state._details[0].is_reschedule ? "m-0 gtc-striketext mr-2" : "m-0"}> { Notify.dateTimeConvert(this.state._details[0].appointment_date) }  </span>
                                            { this.state._details[0].is_reschedule ? <span> <b> resched </b> on  {Notify.dateTimeConvert(this.state._details[0].is_reschedule_date) }</span> : null}
                                        </p> 
                                    </Box>

                                    <Box className="mb-3">
                                        <span className="text-muted text-uppercase gtc-small"> Appointment Reason </span>
                                        <p className="m-0"> { this.state._details[0].appointment_reason } </p> 
                                    </Box>

                                    {
                                        this.state._details[0].is_reschedule ?
                                            <Box className="mb-3">
                                                <span className="text-muted text-uppercase gtc-small"> Appointment Reason </span>
                                                <p className="m-0"> { this.state._details[0].is_reschedule_reason } </p> 
                                            </Box>
                                        :null
                                    }

                                    <Box className="mb-3">
                                        <span className="text-muted text-uppercase gtc-small"> Shared File/Attachment </span>
                                        <p className="m-0"> 
                                            { this.state._details[0].attachment } 
                                            <a rel="noopener noreferrer" className="pointer" target="_blank" href={fileLocation+'appointment/'+this.state._details[0].attachment} download> view </a>
                                        </p>
                                    </Box>

                                    <Box>
                                        <span className="text-muted text-uppercase gtc-small"> Requested On </span>
                                        <p className="m-0"> { Notify.dateTimeConvert(this.state._details[0].created_at) } </p> 
                                    </Box>
                                </Box>
                            : Notify.noRecord()
                        : Notify.loading()
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="contained" 
                        color="inherit"
                        onClick={ () => this.props.close() }
                    > Ok </Button>
                </DialogActions>
			</Fragment>
		)
	}
}
