import React, { useState } from 'react' 
import { Box, Button, CircularProgress, FormHelperText, Grid, Paper, TextField, Typography } from '@material-ui/core' 
import CheckIcon from '@material-ui/icons/CheckCircleOutline' 
import ClearIcon from '@material-ui/icons/HighlightOff'; 
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import * as Yup from 'yup'; 
import { Formik } from 'formik'; 
import {
    MuiPickersUtilsProvider,
    TimePicker,
    DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; 

const NewPatient = ({ getDoctorsPatient }) =>{

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')
    const [selectedDate, setSelectedDate] = useState(new Date());   

    const  getFormData = (object) => {
        const formData = new FormData(); 
        formData.append('app_date', selectedDate.toLocaleString())
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    } 

    return(
        <Paper
            component={Box}
            p={2}
        >
            {/* sample formik */}
            <Formik 
                initialValues={{
                    username: username,
                    user_id: user_id, 
                    token: token,  
                    password: '',
                    lastname: '', 
                    firstname: '', 
                    middlename: '', 
                    gender: '',
                    birthday: '',
                    birthplace: '',
                    street: '',
                    barangay: '',
                    city: '',
                    occupation: '',
                    civil_status: '',
                    religion: '',
                    mobile: '',
                    telephone: '',
                    email: '',
                    //health 
                    height: '',
                    weight: '',
                    pulse: '',
                    temp: '',
                    bp_systolic: '',
                    bp_diastolic:'',
                    blood_type: '',
                    // create appointment
                    has_appointment: '', 
                    app_reason: '',
                    app_service:'',
                    app_amount: '',
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string().trim().required(),
                    lastname: Yup.string().trim().required(), 
                    firstname: Yup.string().trim().required(), 
                    gender: Yup.string().trim().required(), 
                    birthday: Yup.date().required(),
                    birthplace: Yup.string().trim().required(),  
                    street: Yup.string().trim().required(),
                    barangay: Yup.string().trim().required(),
                    city: Yup.string().trim().required(),
                    occupation: Yup.string().trim().required(),
                    civil_status: Yup.string().trim().required(),
                    religion: Yup.string().trim().required(),
                    mobile: Yup.string().trim().required(),
                    email: Yup.string().trim().email().required(),
                    // appointment
                    has_appointment: Yup.string().required('Create appointment is required.'),    
                })}
                onSubmit={async (values, {
                    setErrors, 
                    setSubmitting,
                    resetForm
                }) => {  
                    
                    var errored = [];  

                    if(values.has_appointment === 'Yes'){  
                        if(values.app_reason.length === 0){
                            Notify.fieldRequired('appointment reason')
                            setErrors({ app_reason: 'Appointment reason is required.' });
                            errored = 'error' 
                            return;
                        }

                        if(values.app_service.length === 0){
                            Notify.fieldRequired('appointment type')
                            setErrors({ app_service: 'Appointment type is required.' });
                            errored = 'error' 
                            return;
                        }

                        if(values.app_amount.length === 0){
                            Notify.fieldRequired('appointment rate')
                            setErrors({ app_amount: 'Appointment rate is required.' });
                            errored = 'error' 
                            return;
                        }
 
                    }

                    if(errored.length > 0){  
                        console.log('form error.')
                    }else{
                        try { 
                            
                            const request = await Axios.post('encoder/patients/newpatient-save', getFormData(values)) 
                            
                            if(request.data === 'pass-invalid'){ 
                                setErrors({ password: 'Password is invalid.' });
                                Notify.fieldInvalid('password')
                            } 
                            if(request.data === 'email-exist'){ 
                                setErrors({ email: 'Email is not available.' });
                                Notify.fieldInvalid('email')
                            } 
                            if(request.data === 'success'){
                                Notify.successRequest('new doctor')
                                resetForm(); 
                                setSubmitting(true);
                                getDoctorsPatient()
                            }
                        } catch (error) {
                            const message = error.message || 'Something went wrong';  
                            setErrors({ submit: message });
                            setSubmitting(false);
                        }
                    } 
                }}
                >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    resetForm,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                        noValidate 
                        onSubmit={handleSubmit}  
                    > 
                        <Box mb={2}>
                            <Typography
                                color="primary"
                                variant="subtitle2"
                            >
                                NEW PATIENT
                            </Typography>
                        </Box> 

                        <Box my={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.lastname && errors.lastname)}
                                            helperText={touched.lastname && errors.lastname}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.lastname}
                                            fullWidth
                                            required
                                            name="lastname"
                                            label="Lastname"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.firstname && errors.firstname)}
                                            helperText={touched.firstname && errors.firstname}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.firstname}
                                            fullWidth 
                                            required
                                            name="firstname"
                                            label="Firstname"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.middlename && errors.middlename)}
                                            helperText={touched.middlename && errors.middlename}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.middlename}
                                            fullWidth 
                                            name="middlename"
                                            label="Middle Name"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>


                        <Box my={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.gender && errors.gender)}
                                            helperText={touched.gender && errors.gender}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.gender}
                                            fullWidth
                                            required
                                            name="gender"
                                            label="Gender"
                                            variant="outlined" 
                                            select
                                            SelectProps={{
                                                native: true,
                                            }} 
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        > 
                                            <option value={''}>Select</option>
                                            <option value={'Male'}>Male</option>
                                            <option value={'Female'}>Female</option> 
                                        </TextField>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.birthday && errors.birthday)}
                                            helperText={touched.birthday && errors.birthday}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.birthday}
                                            fullWidth
                                            required
                                            name="birthday"
                                            label="Birthday"
                                            variant="outlined" 
                                            type="date" 
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.birthplace && errors.birthplace)}
                                            helperText={touched.birthplace && errors.birthplace}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.birthplace}
                                            fullWidth 
                                            required
                                            name="birthplace"
                                            label="Birth Place"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box my={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.street && errors.street)}
                                            helperText={touched.street && errors.street}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.street}
                                            fullWidth
                                            required
                                            name="street"
                                            label="Street"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.barangay && errors.barangay)}
                                            helperText={touched.barangay && errors.barangay}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.barangay}
                                            fullWidth 
                                            required
                                            name="barangay"
                                            label="Barangay"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.city && errors.city)}
                                            helperText={touched.city && errors.city}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.city}
                                            fullWidth 
                                            required
                                            name="city"
                                            label="City"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box my={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.occupation && errors.occupation)}
                                            helperText={touched.occupation && errors.occupation}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.occupation}
                                            fullWidth
                                            required
                                            name="occupation"
                                            label="Occupation"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.civil_status && errors.civil_status)}
                                            helperText={touched.civil_status && errors.civil_status}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.civil_status}
                                            fullWidth
                                            required
                                            name="civil_status"
                                            label="Civil Status"
                                            variant="outlined" 
                                            select
                                            SelectProps={{
                                                native: true,
                                            }} 
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        > 
                                            <option value={''}>Select</option>
                                            <option value={'Single'}>Single</option>
                                            <option value={'Married'}>Married</option> 
                                            <option value={'Divorced'}>Divorced</option> 
                                        </TextField>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.religion && errors.religion)}
                                            helperText={touched.religion && errors.religion}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.religion}
                                            fullWidth 
                                            required
                                            name="religion"
                                            label="Religion"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box my={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.mobile && errors.mobile)}
                                            helperText={touched.mobile && errors.mobile}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.mobile}
                                            fullWidth
                                            required 
                                            name="mobile"
                                            label="Mobile"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.telephone && errors.telephone)}
                                            helperText={touched.telephone && errors.telephone}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.telephone}
                                            fullWidth  
                                            name="telephone"
                                            label="Telephone"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.email}
                                            fullWidth 
                                            required
                                            name="email"
                                            label="Email"
                                            variant="outlined" 
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        
                        {/* health records */}
                        <Box my={3}>
                            <Box mb={2}>
                                <Typography color="textSecondary"> Health Records </Typography>
                            </Box>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.height && errors.height)}
                                            helperText={touched.height && errors.height}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.height}
                                            fullWidth 
                                            required
                                            name="height"
                                            label="Height(ft)"
                                            variant="outlined" 
                                            type="number"
                                            InputProps={{
                                                inputProps:{
                                                    min:1,
                                                    max:10000,
                                                    step:0.01
                                                }
                                            }}
                                        />   
                                    </Box>  
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.weight && errors.weight)}
                                            helperText={touched.weight && errors.weight}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.weight}
                                            fullWidth 
                                            required
                                            name="weight"
                                            label="Weight(kg)"
                                            variant="outlined" 
                                            type="number"
                                            InputProps={{
                                                inputProps:{
                                                    min:1,
                                                    max:10000,
                                                    step:0.01
                                                }
                                            }}
                                        />   
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.pulse && errors.pulse)}
                                            helperText={touched.pulse && errors.pulse}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.pulse}
                                            fullWidth 
                                            required
                                            name="pulse"
                                            label="Pulse"
                                            variant="outlined" 
                                            type="number"
                                            InputProps={{
                                                inputProps:{
                                                    min:1,
                                                    max:10000,
                                                    step:0.01
                                                }
                                            }}
                                        />   
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.temp && errors.temp)}
                                            helperText={touched.temp && errors.temp}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.temp}
                                            fullWidth 
                                            required
                                            name="temp"
                                            label="Temperature"
                                            variant="outlined" 
                                            type="number"
                                            InputProps={{
                                                inputProps:{
                                                    min:1,
                                                    max:10000,
                                                    step:0.01
                                                }
                                            }}
                                        />   
                                    </Box>
                                </Grid>
                            </Grid>  

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.bp_systolic && errors.bp_systolic)}
                                            helperText={touched.bp_systolic && errors.bp_systolic}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.bp_systolic}
                                            fullWidth 
                                            required
                                            name="bp_systolic"
                                            label="BP Systolic"
                                            variant="outlined" 
                                            type="number"
                                            InputProps={{
                                                inputProps:{
                                                    min:1,
                                                    max:10000,
                                                    step:0.01
                                                }
                                            }}
                                        />   
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box>
                                        <TextField 
                                            error={Boolean(touched.bp_diastolic && errors.bp_diastolic)}
                                            helperText={touched.bp_diastolic && errors.bp_diastolic}  
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            value={values.bp_diastolic}
                                            fullWidth 
                                            required
                                            name="bp_diastolic"
                                            label="BP Diastolic"
                                            variant="outlined" 
                                            type="number"
                                            InputProps={{
                                                inputProps:{
                                                    min:1,
                                                    max:10000,
                                                    step:0.01
                                                }
                                            }}
                                        />   
                                    </Box>
                                </Grid>
                            </Grid> 

                            <Box mt={3}>
                                <TextField 
                                    error={Boolean(touched.blood_type && errors.blood_type)}
                                    helperText={touched.blood_type && errors.blood_type}  
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values.blood_type}
                                    fullWidth 
                                    required
                                    name="blood_type"
                                    label="Blood Type"
                                    variant="outlined" 
                                    select
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{
                                        shrink: true 
                                    }}
                                >
                                    <option value=""> Select </option>
                                    <option value="A"> A </option>
                                    <option value="B"> B </option>
                                    <option value="AB"> AB </option>
                                    <option value="O"> O </option> 
                                    <option value="A-negative"> A-Negative </option>
                                    <option value="B-positive"> B-Positive </option>
                                    <option value="B-negative"> B-Negative </option>
                                    <option value="O-positive"> O-Positive </option>
                                    <option value="O-negative"> O-Negative </option>
                                    <option value="AB-positive">AB-Positive </option>
                                    <option value="AB-negative">AB-Negative </option>
                                </TextField>   
                            </Box>
                        </Box>
                        
                        {/* appointment records */}
                        <Box my={3}>
                            <Box mb={2}>
                                <Typography color="textSecondary"> Appointment Details </Typography>
                            </Box>
                            
                            <Box mb={2}> 
                                <TextField 
                                    error={Boolean(touched.has_appointment && errors.has_appointment)}
                                    helperText={touched.has_appointment && errors.has_appointment}  
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values.has_appointment}
                                    fullWidth
                                    required
                                    name="has_appointment"
                                    label="Create Appointment"
                                    variant="outlined" 
                                    select
                                    SelectProps={{
                                        native: true,
                                    }} 
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                > 
                                    <option value={''}>Select</option>
                                    <option value={'Yes'}> Yes </option>
                                    <option value={'No'}>  No </option> 
                                </TextField>
                            </Box>
                            
                            { 
                                values.has_appointment === 'Yes' &&(
                                    <>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>    
                                            <Box mb={1} mt={1}>  
                                                <DatePicker
                                                    fullWidth 
                                                    disablePast
                                                    margin="normal"
                                                    id="date-picker"
                                                    label="Date Picker"
                                                    value={selectedDate}
                                                    inputVariant="outlined"
                                                    onChange={(date) => setSelectedDate(date)} 
                                                    name="app_date"
                                                />
                                            </Box>

                                            <Box mb={1}>
                                                <TimePicker
                                                    fullWidth 
                                                    margin="normal"
                                                    id="time-picker"
                                                    label="Time Picker"
                                                    value={selectedDate}
                                                    inputVariant="outlined"
                                                    onChange={(date) => setSelectedDate(date)}  
                                                    name="app_time"
                                                />
                                            </Box>
                                        </MuiPickersUtilsProvider>

                                        <Box mb={2}>
                                            <TextField 
                                                error={Boolean(touched.app_reason && errors.app_reason)}
                                                helperText={touched.app_reason && errors.app_reason}  
                                                onBlur={handleBlur}
                                                onChange={handleChange} 
                                                value={values.app_reason}
                                                fullWidth 
                                                required
                                                name="app_reason"
                                                multiline
                                                label="Appointment Reason"
                                                variant="outlined" 
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <TextField 
                                                error={Boolean(touched.app_service && errors.app_service)}
                                                helperText={touched.app_service && errors.app_service}  
                                                onBlur={handleBlur}
                                                onChange={handleChange} 
                                                value={values.app_service}
                                                fullWidth 
                                                required
                                                name="app_service"
                                                label="Appointment Type"
                                                variant="outlined" 
                                            />
                                        </Box>

                                        <Box mb={2}>
                                            <TextField 
                                                error={Boolean(touched.app_amount && errors.app_amount)}
                                                helperText={touched.app_amount && errors.app_amount}  
                                                onBlur={handleBlur}
                                                onChange={handleChange} 
                                                value={values.app_amount}
                                                fullWidth 
                                                required
                                                name="app_amount"
                                                label="Appointment Rate"
                                                variant="outlined" 
                                                type="number"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        step: 0.01,
                                                        max: 10000
                                                    }
                                                }}
                                            />
                                        </Box>

                                        
                                    </>
                                )
                            }
                            
                        </Box>

                        <Box my={3}>
                            <TextField 
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}  
                                onBlur={handleBlur}
                                onChange={handleChange} 
                                value={values.password}
                                fullWidth
                                required
                                name="password"
                                type="password"
                                label="Password"
                                variant="outlined" 
                            />
                        </Box>

                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>
                                {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}

                        <Box mb={2} display="flex"> 
                            <Box flexGrow={1} />

                            <Button
                                variant="contained"
                                color="default"
                                onClick={ resetForm }
                                startIcon={ <ClearIcon /> }
                            >
                                Clear
                            </Button> 
                            <Box ml={2}>   
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={ isSubmitting ? <CircularProgress size={20} color="inherit" /> : <CheckIcon /> }
                                    disabled={isSubmitting}  
                                >
                                    Save
                                </Button> 
                            </Box>
                        </Box> 
                    </form>
                )}
                </Formik>
            {/* sample formit */} 
        </Paper>
    )
}   

export default NewPatient; 