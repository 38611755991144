 
import React, { useState } from "react";
import { Chart } from "react-google-charts"; 
import { Typography, Box, Checkbox, FormControlLabel, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert'; 
import Notify from "../notification/Notify";

const GTCChart = ({ chartData, chartLabel }) => {
 
    const [fullscreen, setFullscreen] = useState(true) 

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [chartType, setChartType] = useState("LineChart") 
    const [curveLine, setCurveline] = useState('function')

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
   
    return ( 
        <Box 
            px={2}
        >
            <Box my={1} display="flex"> 
                <Box flexGrow={1}>
                    <Typography variant="h6" color="textSecondary"> {chartLabel} Monitoring </Typography>
                </Box>
                <Box>
                    <IconButton
                        color="primary"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    
                    <Menu 
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose} 
                    > 
                        <MenuItem >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={ fullscreen } 
                                        color="primary"
                                        onChange={ () => setFullscreen(!fullscreen) }
                                    />
                                }
                                label="Fullscreen"
                            />   
                        </MenuItem> 
                        <MenuItem
                            hidden={ chartType === "ColumnChart"}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={ curveLine === 'function' } 
                                        color="primary"
                                        onChange={ () => setCurveline(curveLine === 'function' ? null : 'function')}
                                    />
                                }
                                label="Curved"
                            />
                        </MenuItem>
                        <MenuItem>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={ chartType === 'ColumnChart' } 
                                        color="primary"
                                        onChange={ () => setChartType(chartType === 'ColumnChart' ? 'LineChart' : 'ColumnChart')}
                                    />
                                } 
                                label="ColumnChart"
                            /> 
                        </MenuItem>
                    </Menu>  
                </Box>
            </Box>
            <Chart
                style={ style.chart } 
                chartType={ chartType === 'ColumnChart' ? "ColumnChart" : "LineChart"}
                loader={ Notify.loading() }
                data={ chartData } 
                options={{       
                    legend: "none", 
                    theme: fullscreen ? 'maximized' : null ,
                    lineWidth: 1,
                    pointSize: 4,  
                    curveType: curveLine === 'function' ? 'function' : null,
                    colors: ['#3f51b5', '#dc3545'],  
                    animation: {
                        startup: true,
                        easing: 'linear',
                        duration: 700,
                    },  
                }} 
                rootProps={{ 'data-testid': '1' }}
            />
        </Box>  
    );
};
export default GTCChart;

const style = {
    chart:{
        width :  '100%',
        height :  'calc(96vh - 270px)'
    }
}