import React, { useState, Fragment, useCallback, useEffect } from 'react';   
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { useHistory } from 'react-router-dom'; 
import Alert from '@material-ui/lab/Alert';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { Box, Button, Typography } from '@material-ui/core';  

const AppointmentRoom = () =>{ 
    const history = useHistory(); 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [room, setRoom] = useState({
        data: [],
        ready: false,
        open: false,
    }) 

    var interval = null;

    const createdRoom = useCallback(() => {
        var formdata = new FormData();  
        formdata.set('token', token);  
        formdata.set('user_id', user_id);    
        
        // Axios.post('appointment/patient/doctor/created-room', formdata )
        Axios.post('appointment/patient/online/created-roombydoctor', formdata )
        .then( (response) => { 
            const data = response.data; 
            setRoom({
                data: data,
                ready: true,
                open: true
            }) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, user_id])

    const initializeInterval  =() =>{
        interval = setInterval(() => {
            createdRoom()
        }, 5000);
    }

    useEffect(()=>{
        createdRoom()
 
        initializeInterval()

        return(() => clearInterval(interval))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdRoom])
    
    return(
        <Fragment>   
            {
                room.ready ? 
                    room.data.length > 0 ?
                        room.data.map((data, index)=>{
                            return(
                                <Box key={index} mb={2}>    
                                    <Alert  
                                        severity="error" 
                                        variant="filled" 
                                        icon={
                                            <MeetingRoomIcon />
                                        }
                                        action={ 
                                            <>
                                                <Button 
                                                    variant="contained"
                                                    color="default"
                                                    size={'small'} 
                                                    onClick={ ()=> 
                                                        history.push(`/app/patient/appointment/room/${data.ref_number}/${Notify.randomizeString(data.ref_number)}`) 
                                                    }
                                                > Enter Room </Button> 
                                                
                                            </>
                                        } 
                                    >
                                        <Typography className={`gtc-uppercase`} variant="subtitle2"> {data.doctors_name} making room </Typography> 
                                    </Alert>      
                                </Box>
                            )
                        })
                    : null
                : <p className="text-center text-muted small"> checking for created room... </p>
            }
        </Fragment>
    )
}

export default AppointmentRoom;