import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { Badge, Dialog, DialogContent, DialogTitle, Box, Grid, Typography, Button, TextField, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination, InputAdornment, Divider, Paper, Zoom } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import OverTheCounter from './Dashboard/OverTheCounter';
import PurchaseList from './Dashboard/PurchaseList';
import AddProductModal from './Dashboard/DashboardModal/Add/AddProductModal';
import AddProductBatches from './Dashboard/DashboardModal/Add/AddProductBatches';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteProductBatches from './Dashboard/DashboardModal/Delete/DeleteProductBatches';
import Alert from '@material-ui/lab/Alert';
import { blue, red } from '@material-ui/core/colors';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { useHistory } from 'react-router-dom';
import { VirtualPharmacyContext } from './../ContextAPI';
import CheckInternet from 'src/utils/CheckInternet';
import CheckingConnection from 'src/CheckInternet';
// import NewAddProductModal from './Dashboard/DashboardModal/Add/NewAddProductModal';

function Home() {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [addProductdialog, setaddProductdialog] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [invetory, setInvetory] = useState([]);
    const [inventoryReady, setInventoryReady] = useState(false);
    const [search, setSearch] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [purchase, setPurchase] = useState([]);
    const [purchaseReady, setPurchaseReady] = useState(false);
    const [deleteStockDialog, setDeleteStockDialog] = useState(false);
    const [addStockDialog, setAddStockDialog] = useState(false);
    const [selectedProdID, setSelectedProdID] = useState(null);
    const [selectedProductName, setSelectedProductName] = useState(null)
    const [selectedUnit, setSelectedUnit] = useState(null)
    const [selectedDescription, setSelectedDescription] = useState(null)
    const [selectedSupplier, setSelectedSupplier] = useState(null)
    const contextCount = useContext(VirtualPharmacyContext);
    const history = useHistory();
    const [role, setRole] = useState(null);
    const [roleReady, setRoleReady] = useState(false);
    const [online, setOnline] = useState('checking')


    const [newAddProductDialog, setNewAddProductDialog] = useState(false);


    const showAddProductDialog = (e) => {
        setaddProductdialog(true)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchRole = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);

        Axios.post('malita/pharmacy/get-spec-role', formdata)
            .then((response) => {
                const data = response.data;
                setRole(data);
                setRoleReady(true);
                localStorage.setItem('role', data.role)
                localStorage.setItem('pharmacy_id', data.pharmacy_id)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, management_id])

    const fetchInventoryList = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);

        Axios.post('malita/pharmacy/get-product-list', formdata)
            .then((response) => {
                const data = response.data;
                setInvetory(data);
                setInventoryReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, management_id])

    const fetchPuchaseList = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);

        Axios.post('malita/pharmacy/get-purchase-list', formdata)
            .then((response) => {
                const data = response.data;
                setPurchase(data);
                setPurchaseReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, management_id])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setSelectedProdID(event.currentTarget.id)
        setSelectedProductName(event.currentTarget.getAttribute('pname'))
        setSelectedUnit(event.currentTarget.getAttribute('unit'))
        setSelectedDescription(event.currentTarget.getAttribute('description'))
        setSelectedSupplier(event.currentTarget.getAttribute('supplier'))
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const checkinternet = () => {
        CheckInternet.online().then(() => setOnline('connected')).catch(() => setOnline('disconnected'))
    }

    useEffect(() => {
        checkinternet();

        fetchRole();
        fetchInventoryList()
        fetchPuchaseList()
    }, [fetchInventoryList, fetchPuchaseList, fetchRole])

    const searchable = invetory.filter((data) => {
        return data.product.toLowerCase().indexOf(search.trim()) !== -1 || data.description.toLowerCase().indexOf(search.trim()) !== -1
    })

    return (
        <Fragment>
            <Box m={2}>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                            <Grid item sm={4} xs={4}>
                                <Grid container>
                                    <Grid item sm={12} xs={12}>
                                        {online === 'checking' && <CheckingConnection />}
                                        {online === 'disconnected' && (
                                            <Fragment>
                                                <Box
                                                    border={1}
                                                    borderRadius={4}
                                                    borderColor={red[100]}
                                                >
                                                    <Alert
                                                        icon={<WifiOffIcon style={{ fontSize: '2.2em' }} />}
                                                        severity="error"
                                                    >
                                                        <Typography variant="subtitle2"> Offline. </Typography>
                                                        <Typography variant="body2"> The system cannot receive orders from virtual doctors. </Typography>
                                                    </Alert>
                                                </Box>
                                            </Fragment>
                                        )}
                                        {online === 'connected' && (
                                            <Box
                                                display="flex"
                                                border={1}
                                                borderRadius={4}
                                                borderColor={blue[100]}
                                                className="pointer"
                                                onClick={() => history.push('/app/malitapharmacy/virtual')}
                                            >
                                                <Box flexGrow={1}>
                                                    <Alert
                                                        icon={
                                                            <WifiIcon style={{ fontSize: '2.2em' }} />
                                                        }
                                                        severity="info"
                                                    >
                                                        <Typography variant="subtitle1"> Online </Typography>
                                                        <Typography variant="subtitle2"> The system can receive orders from virtual doctors. </Typography>
                                                    </Alert>
                                                </Box>
                                                <Badge badgeContent={contextCount.badgeCount.length} color="secondary" />
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Paper component={Box} mt={1}>
                                            <Box>
                                                <Box p={2} borderRadius={4}>
                                                    <Box display="flex" justifyContent="center" >
                                                        <Box flexGrow={1} >
                                                            <Typography noWrap color="primary">
                                                                OVER THE COUNTER
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                                <Box m={1}>
                                                    <OverTheCounter fetchPuchaseList={() => fetchPuchaseList()} fetchInventoryList={() => fetchInventoryList()} />
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Paper component={Box} mt={1}>
                                            <Box>
                                                <Box p={2} borderRadius={4}>
                                                    <Box display="flex" justifyContent="center" >
                                                        <Box flexGrow={1} >
                                                            <Typography noWrap color="primary">
                                                                PURCHASE LIST
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                                <Box m={1}>
                                                    <PurchaseList purchaseReady={purchaseReady} purchase={purchase} fetchPuchaseList={() => fetchPuchaseList()} fetchInventoryList={() => fetchInventoryList()} />
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={8} xs={8}>
                                <Paper>
                                    <Box>
                                        <Box p={2} borderRadius={4}>
                                            <Box display="flex" justifyContent="center" >
                                                <Box flexGrow={1} >
                                                    <Typography color="primary" >
                                                        PHARMACY INVENTORY
                                                    </Typography>
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => setNewAddProductDialog(true)}
                                                        startIcon={<AddCircleOutline />}
                                                    >
                                                        Product
                                                    </Button>
                                                </Box>
                                                {
                                                    roleReady && (
                                                        <Box>
                                                            <Button
                                                                hidden={role.role !== 'admin'}
                                                                color="primary"
                                                                onClick={showAddProductDialog}
                                                                startIcon={<AddCircleOutline />}
                                                            >
                                                                Product
                                                            </Button>
                                                        </Box>
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                        <Divider />
                                        <Box m={1}>
                                            <TableContainer component={Box} borderColor="grey.200" bgcolor="white">
                                                <Box display="flex">
                                                    <Box flexGrow={1} />
                                                    <Box mb={1}>
                                                        <TextField
                                                            label="Search brand/generic"
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={search}
                                                            onChange={(e) => setSearch(e.target.value)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment>
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>

                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center"> BRAND </TableCell>
                                                            <TableCell align="center"> GENERIC </TableCell>
                                                            <TableCell align="center"> MSRP </TableCell>
                                                            <TableCell align="center"> SRP </TableCell>
                                                            <TableCell align="center"> RATE </TableCell>
                                                            <TableCell align="center"> AVAILABLE </TableCell>
                                                            <TableCell align="center"> ACTION </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            inventoryReady ?
                                                                invetory.length > 0 ?
                                                                    searchable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                        .map((data, index) => (
                                                                            <TableRow key={index}>
                                                                                <TableCell > {data.product} </TableCell>
                                                                                <TableCell >{data.description}</TableCell>
                                                                                <TableCell align="right">{data.unit_price}</TableCell>
                                                                                <TableCell align="right">{data.srp}</TableCell>
                                                                                <TableCell align="right">
                                                                                    {(100 - ((parseFloat(data.unit_price) / parseFloat(data.srp)) * 100)).toFixed(2)}%
                                                                                </TableCell>
                                                                                <TableCell align="right">{data.sum_all_quantity}</TableCell>
                                                                                <TableCell align="center">
                                                                                    <IconButton aria-label="delete" aria-controls="simple-menu" aria-haspopup="true" supplier={data.supplier} description={data.description} unit={data.unit} pname={data.product} id={data.product_id} onClick={handleClick} >
                                                                                        <MoreVert fontSize="small" />
                                                                                    </IconButton>
                                                                                    <Menu
                                                                                        id="simple-menu"
                                                                                        anchorEl={anchorEl}
                                                                                        keepMounted
                                                                                        open={Boolean(anchorEl)}
                                                                                        onClose={handleClose}
                                                                                    >
                                                                                        <MenuItem onClick={() => setAddStockDialog(true)}> <PostAddIcon color="primary" /> Add </MenuItem>
                                                                                        <MenuItem onClick={() => setDeleteStockDialog(true)}> <DeleteIcon color="secondary" /> Delete </MenuItem>
                                                                                    </Menu>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    : <TableRow>
                                                                        <TableCell colSpan={7}>
                                                                            <Typography variant="subtitle2" color="secondary"> No product added </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                : <TableRow>
                                                                    <TableCell colSpan={7}>
                                                                        <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                                <TablePagination
                                                    component={"div"}
                                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                    colSpan={3}
                                                    count={invetory.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* add product */}
                <Dialog
                    open={addProductdialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Zoom}
                    transitionDuration={1000}
                    disableBackdropClick={true}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">{"Add New Product"}</DialogTitle>
                    <DialogContent dividers>
                        <AddProductModal fetchInventoryList={() => fetchInventoryList()} closeModal={() => setaddProductdialog(false)} />
                    </DialogContent>
                </Dialog>

                {/* add batches */}
                <Dialog
                    open={addStockDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Zoom}
                    transitionDuration={1000}
                    disableBackdropClick={true}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box display="flex" justifyContent="center" >
                            <Box flexGrow={1}>
                                <Typography variant="h5">
                                    Add Product Batches
                                </Typography>
                            </Box>
                            <IconButton
                                className={'d-print-none'}
                                color="secondary"
                                onClick={() => setAddStockDialog(false)}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent dividers>
                        <AddProductBatches fetchInventoryList={() => fetchInventoryList()} closeModal={() => setAddStockDialog(false)} selectedProdID={selectedProdID} selectedProductName={selectedProductName} selectedUnit={selectedUnit} selectedDescription={selectedDescription} selectedSupplier={selectedSupplier} />
                    </DialogContent>
                </Dialog>

                {/* delete batches */}
                <Dialog
                    open={deleteStockDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Zoom}
                    transitionDuration={1000}
                    disableBackdropClick={true}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box display="flex" justifyContent="center" >
                            <Box flexGrow={1}>
                                <Typography variant="h5">
                                    Delete Product Batches
                                </Typography>
                            </Box>
                            <IconButton
                                className={'d-print-none'}
                                color="secondary"
                                onClick={() => setDeleteStockDialog(false)}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent dividers>
                        <DeleteProductBatches fetchInventoryList={() => fetchInventoryList()} closeModal={() => setDeleteStockDialog(false)} selectedProdID={selectedProdID} selectedProductName={selectedProductName} selectedUnit={selectedUnit} selectedDescription={selectedDescription} selectedSupplier={selectedSupplier} />
                    </DialogContent>
                </Dialog>


                <Dialog
                    open={newAddProductDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Zoom}
                    transitionDuration={400}
                    disableBackdropClick={true}
                    fullScreen
                >
                    {/* <NewAddProductModal closeModal={() => setNewAddProductDialog(false)} fetchInventoryList={() => fetchInventoryList()} /> */}
                </Dialog>

            </Box>
        </Fragment >
    )

}

export default Home;