import React, { useEffect, useState } from 'react'; 
import { Avatar, Box, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, makeStyles, Badge, Divider } from "@material-ui/core"; 
import { deepOrange } from '@material-ui/core/colors';
import ReviewedPatient from './ReviewedPatient';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import ReviewPatient from './ReviewPatient';

const useStyles = makeStyles((theme) => ({ 
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500]
    }
}));
  
const Dashboard = () =>{ 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [selectedPatient, setSelectedPatient] = useState(null) 

    const [patients, setPatients] = useState([])
    const [patientsRd, setPatientsRd] = useState(false) 
    
    const [lastId, setLastId] = useState(0)
    let interval = null;

    const updateSelectedPatient = (data) =>{
        setSelectedPatient(data)
    }

    const getPatientsReview = async() =>{
        try {
            var formdata = new FormData()
                formdata.append('token', token) 
                formdata.append('user_id', user_id)
            const request = await Axios.post('telerad/patients/getpatient-toview', formdata)
            setPatients(request.data)
            setPatientsRd(true) 
            setLastId(request.data.length > 0 ? request.data[0].id  : 0)
        } catch (error) {
            Notify.requestError(error)
        }
    }

    const getNewPAtietnForReading = async () =>{
        try {
            var formdata = new FormData()
                formdata.append('token', token) 
                formdata.append('user_id', user_id)
                formdata.append('lastid', lastId)
            const request = await Axios.post('telerad/patients/latest-newpatient', formdata)
            if(request.data.length > 0){ 
                getPatientsReview() 
            }
        } catch (error) {
            Notify.requestError(error)
        }
    }

    const initializeInterval = () =>{
        interval = setInterval( async() => {
            getNewPAtietnForReading()
        }, 15000);
    }

    useEffect(() =>{ 

        initializeInterval()
        getPatientsReview()

        return(() => {
            clearInterval(interval)
        })
        // eslint-disable-next-line
    }, [lastId])

    return(
        <Box m={2}>
            <Grid
                container
                spacing={2}
            >   
                <Grid 
                    item
                    xs={12}
                    sm={4}
                    md={3}
                >
                    <Paper
                        variant="outlined"
                        component={Box}
                        p={2}
                    >
                        <Box mb={2} display="flex">
                            <Box flexGrow={1}>
                                <Badge color="secondary" badgeContent={ patients.length } >
                                    <Typography
                                        variant="subtitle2"
                                        color="textPrimary"
                                    >
                                        <b>
                                            PATIENT TO REVIEW
                                        </b>
                                    </Typography>
                                </Badge>
                            </Box> 
                        </Box>
                        
                        <Divider />

                        <Box>  
                            <List 
                                component="div"
                            > 
                                {
                                    patientsRd ?
                                        <RenderList 
                                            selectedPatient = { selectedPatient } 
                                            updateSelectedPatient = { updateSelectedPatient }
                                            patients = { patients } 
                                        />
                                    : <Box p={2} > { Notify.loading() } </Box>
                                }
                            </List>
                        </Box>
                    </Paper>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={9}
                >
                    { selectedPatient === null && ( <ReviewedPatient /> ) }
                    { selectedPatient && ( <ReviewPatient resetDisplay = { () =>{ setSelectedPatient(null) }} getPatientsReview = { getPatientsReview } imagingOrderId = { selectedPatient } /> ) }
                </Grid>
            </Grid>
        </Box>
    )
}

export default Dashboard;

const RenderList = ({ selectedPatient, updateSelectedPatient, patients }) => { 
    const classes = useStyles(); 
    return(
        <>
            { 
                patients.length > 0 ?
                    patients.map((data, index) => (
                        <ListItem
                            key = { index }
                            button 
                            onClick = { () => updateSelectedPatient(data.imaging_center_id) }
                            selected = { selectedPatient === data.imaging_center_id }
                        >
                            <ListItemIcon>
                                <Avatar className={selectedPatient === data.imaging_center_id ? classes.orange : ''} sizes="large">
                                    {data.patients_name.charAt().toUpperCase()}
                                </Avatar>
                            </ListItemIcon>
                            <ListItemText 
                                primaryTypographyProps={{
                                    className: 'gtc-capitalize'
                                }}
                                primary={ data.patients_name }
                                secondary={ Notify.dateTimeConvert(data.created_at)}
                            />
                        </ListItem>
                    ))
                : <Box p={2} > { Notify.noRecord() } </Box> 
            } 
        </>
    )
}