
import React, {
    useState, useEffect, Fragment,
    // useContext
} from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import PrintIcon from '@material-ui/icons/Print';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';

import Rx from 'src/utils/Rx';
import { Button, Paper, Tooltip } from '@material-ui/core';
import PersonalMedicationForm from './PersonalMedicationForm';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import AddToCart from 'src/patients/cart/AddToCart';
// import { OrderContext } from 'src/ContextAPI';

const PersonalMedication = (props) => {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))

    const [medication, setMedication] = useState([])
    const [medicationReady, setMedicationReady] = useState(false)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);

    const [selectedid, setselectedid] = useState(null)

    const [medicationDetails, setMedicationDetails] = useState([])

    const [printRx, setPrintRx] = useState(false)

    const [patientIdForRx, setPatientIdForRx] = useState(null)
    const [doctorsIdForRx, setDoctorsIdForRx] = useState(null)
    const [claimIdForRx, setClaimIdForRx] = useState(null)

    const [newmedication, setNewMedication] = useState(false)

    const [personalMedication, setPersonalMedication] = useState([])

    const [personalMedicationByDate, setPersonalMedicationByDate] = useState([])
    const [personalMedicationByDateReady, setPersonalMedicationByDateReady] = useState(false)

    const [pmedId, setPmedId] = useState(null)

    const [pageMedication, setPageMedication] = useState(0);
    const [rowsPerPageMedication, setRowsPerPageMedication] = useState(7);

    // const orderContext = useContext(OrderContext)

    const [cartData, setCartData] = useState({
        data: null,
        dialog: false
    })

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getMedication = () => {
        var formdata = new FormData();

        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('patient_id', props.patient[0].patient_id);

        Axios.post('doctor/patient/medication/getmedication-list', formdata)
            .then((response) => {
                const data = response.data;
                setMedication(data)
                setMedicationReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const handleSelectedPrescription = (e) => {
        if (e === 'match') { return null }
        var prescription_date = e.currentTarget.getAttribute('date');
        var prescription_type = e.currentTarget.getAttribute('type');

        var formdata = new FormData();
        formdata.set('token', token)
        formdata.set('user_id', user_id)
        formdata.set('patient_id', props.patient[0].patient_id)
        formdata.set('prescription_date', prescription_date)
        formdata.set('prescription_type', prescription_type)

        Axios.post('doctor/patient/medication/getmedication-details', formdata)
            .then((response) => {
                const data = response.data;
                setMedicationDetails(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const handlePrintRx = (e) => {
        setPatientIdForRx(e.currentTarget.id)
        setDoctorsIdForRx(e.currentTarget.getAttribute('doctors_id'))
        setClaimIdForRx(e.currentTarget.getAttribute('claim_id'))
        setPrintRx(true)
    }


    const getPersonalMedicationByDate = () => {

        var formdata = new FormData();

        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('patient_id', props.patient[0].patient_id);

        Axios.post('patient/medication/monitoring/list-medicationbydate', formdata)
            .then((response) => {
                const data = response.data;
                setPersonalMedicationByDate(data)
                setPersonalMedicationByDateReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const handleSelectedMedication = (e) => {
        if (e === 'match') { return null }
        var date = e.currentTarget.getAttribute('date');
        var id = e.currentTarget.id;
        var formdata = new FormData();

        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('patient_id', props.patient[0].patient_id);
        formdata.set('id', id);
        formdata.set('created_at', date);
        Axios.post('patient/medication/monitoring/list-medication', formdata)
            .then((response) => {
                const data = response.data;
                setPersonalMedication(data)
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const handleChangePageMedication = (event, newPage) => {
        event.persist()
        setPageMedication(newPage);
    };

    const handleChangeRowsPerPageMedication = (event) => {
        setRowsPerPageMedication(parseInt(event.target.value, 10));
        setPageMedication(0);
    };

    useEffect(() => {

        getMedication()
        getPersonalMedicationByDate()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.patient_id])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                    <Box
                        component={Paper}
                        p={2}
                        variant="outlined"
                    >
                        <Box>
                            <Typography variant="subtitle2" color="primary" className={`gtc-uppercase`}>
                                Doctors Prescription
                            </Typography>
                        </Box>

                        <TableContainer component={Box} mt={2}>
                            {
                                medicationReady ?
                                    <Table >
                                        <TableBody>
                                            {
                                                medication.length > 0 ?
                                                    medication.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((data, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <TableRow
                                                                        className={selectedid === data.prescription_id ? "gtc-bg-light" : ""}
                                                                        key={index}
                                                                    >
                                                                        <TableCell>
                                                                            <IconButton
                                                                                color="primary"
                                                                                id={data.prescription_id}
                                                                                date={data.created_at}
                                                                                type={data.prescription_type}
                                                                                onClick={(e) => {
                                                                                    setselectedid(selectedid === data.prescription_id ? null : data.prescription_id)
                                                                                    setMedicationDetails([])
                                                                                    handleSelectedPrescription(selectedid === data.prescription_id ? 'match' : e)
                                                                                }}
                                                                            >
                                                                                {selectedid === data.prescription_id ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
                                                                            </IconButton>
                                                                        </TableCell>
                                                                        <TableCell>  {Notify.dateTimeConvert(data.created_at)} </TableCell>
                                                                        <TableCell>{data.prescription_type}</TableCell>
                                                                        <TableCell>
                                                                            <Tooltip title="Add to cart">
                                                                                <IconButton
                                                                                    color={cartData.claimId === data.claim_id ? 'secondary' : 'default'}
                                                                                    id={data.patient_id}
                                                                                    doctors_id={data.doctors_id}
                                                                                    claim_id={data.claim_id}
                                                                                    onClick={() => setCartData({
                                                                                        data: data,
                                                                                        dialog: true
                                                                                    })}
                                                                                >
                                                                                    <AddShoppingCartIcon />
                                                                                </IconButton>
                                                                            </Tooltip>

                                                                            <IconButton
                                                                                id={data.patient_id}
                                                                                doctors_id={data.doctors_id}
                                                                                claim_id={data.claim_id}
                                                                                onClick={handlePrintRx}
                                                                            >
                                                                                {/* temporary hide */}
                                                                                {/* <PrintIcon /> */}
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>

                                                                    {/* collapse */}
                                                                    <TableRow>
                                                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                            <Collapse in={selectedid === data.prescription_id ? true : false} timeout="auto" unmountOnExit>
                                                                                <Box margin={1}>
                                                                                    <Typography color="primary" variant="caption">
                                                                                        <b> 
                                                                                            MEDICINES 
                                                                                        </b>
                                                                                    </Typography>
                                                                                    <Table size="small">
                                                                                        <TableHead>
                                                                                            <TableRow>
                                                                                                <TableCell> <strong> Item </strong> </TableCell>
                                                                                                <TableCell> <strong> Type </strong> </TableCell>
                                                                                                <TableCell> <strong> Dosage </strong> </TableCell>
                                                                                                <TableCell> <strong> Every </strong> </TableCell>
                                                                                                <TableCell> <strong> Times </strong> </TableCell>
                                                                                                <TableCell> <strong> Quantity </strong> </TableCell>
                                                                                                <TableCell> <strong> Remarks </strong> </TableCell>
                                                                                            </TableRow>
                                                                                        </TableHead>
                                                                                        <TableBody>
                                                                                            {
                                                                                                medicationDetails.length > 0 ?
                                                                                                    medicationDetails.map((meds, vindex) => {
                                                                                                        return (
                                                                                                            <TableRow key={vindex}>
                                                                                                                <TableCell> {meds.product_name}</TableCell>
                                                                                                                <TableCell> {meds.type} </TableCell>
                                                                                                                <TableCell> {meds.dosage} </TableCell>
                                                                                                                <TableCell className="text-right"> {meds.per_day} </TableCell>
                                                                                                                <TableCell className="text-right"> {meds.per_take} </TableCell>
                                                                                                                <TableCell className="text-right"> {meds.quantity} </TableCell>
                                                                                                                <TableCell>
                                                                                                                    <Box maxWidth={400}>
                                                                                                                        <Typography>
                                                                                                                            {meds.remarks}
                                                                                                                        </Typography>
                                                                                                                    </Box>
                                                                                                                </TableCell>
                                                                                                            </TableRow>
                                                                                                        )
                                                                                                    })
                                                                                                    : <TableRow>
                                                                                                        <TableCell colSpan={7}>
                                                                                                            {Notify.loading()}
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                            }
                                                                                        </TableBody>
                                                                                    </Table>
                                                                                </Box>
                                                                            </Collapse>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </Fragment>
                                                            )
                                                        })
                                                    :
                                                    <TableRow>
                                                        <TableCell colSpan={6}>
                                                            <Typography color="error" align="center">
                                                                No medication record.
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                    : Notify.loading()
                            }

                            <TablePagination
                                labelRowsPerPage="List"
                                rowsPerPageOptions={[7, 20, 50, 100, { value: -1, label: 'All' }]}
                                component="div"
                                count={medication.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                    <Box
                        component={Paper}
                        p={2}
                        variant="outlined"
                    >
                        <Box display="flex">
                            <Box flexGrow={1}>
                                <Typography variant="subtitle2" color="primary" className={`gtc-uppercase`}>
                                    Personal Medication
                                </Typography>
                            </Box>
                            <Button
                                color="primary"
                                onClick={() => setNewMedication(true)}
                                startIcon={<AddCircleIcon />}
                            >
                                Update
                            </Button>
                        </Box>
                        <Box pt={4}>
                            {
                                personalMedicationByDateReady ?
                                    personalMedicationByDate.length > 0 ?
                                        personalMedicationByDate.slice(pageMedication * rowsPerPageMedication, pageMedication * rowsPerPageMedication + rowsPerPageMedication)
                                            .map((d, d_index) => (
                                                <Box key={d_index}>
                                                    <Box display="flex" >
                                                        <Box>
                                                            <IconButton
                                                                color="primary"
                                                                date={d.created_at}
                                                                id={d.id}
                                                                onClick={(e) => {
                                                                    setPmedId(pmedId === d.id ? null : d.id);
                                                                    setPersonalMedication([]);
                                                                    handleSelectedMedication(pmedId === d.id ? 'match' : e)
                                                                }}
                                                            >
                                                                {pmedId === d.id ? (<KeyboardArrowDownIcon />) : (<KeyboardArrowRight />)}
                                                            </IconButton>
                                                        </Box>
                                                        <Box mt={2} ml={3}>
                                                            <Typography variant="subtitle2">
                                                                {Notify.createdAt(d.created_at)}
                                                            </Typography>
                                                        </Box>
                                                    </Box>

                                                    <Collapse in={pmedId === d.id ? true : false}>
                                                        <Box ml={3}>
                                                            {
                                                                personalMedication.length > 0 ?
                                                                    personalMedication.map((data, index) => (
                                                                        <Box
                                                                            key={index}
                                                                            hidden={Notify.createdAt(data.created_at) === Notify.createdAt(d.created_at) ? false : true}
                                                                        >
                                                                            <Box>
                                                                                <Typography color="primary" variant="caption" className="gtc-uppercase">
                                                                                    <b>
                                                                                        {data.meals} on {Notify.createdTime(data.created_at)}
                                                                                    </b>
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box p={1}>
                                                                                <Box dangerouslySetInnerHTML={{ __html: data.description }} />
                                                                            </Box>
                                                                        </Box>
                                                                    ))
                                                                    : Notify.loading()
                                                            }
                                                        </Box>
                                                    </Collapse>
                                                </Box>
                                            ))
                                        : Notify.noRecord()
                                    : Notify.loading()
                            }

                            <TablePagination
                                labelRowsPerPage="List"
                                rowsPerPageOptions={[7, 20, 50, 100, { value: -1, label: 'All' }]}
                                component="div"
                                count={personalMedicationByDate.length}
                                rowsPerPage={rowsPerPageMedication}
                                page={pageMedication}
                                onChangePage={handleChangePageMedication}
                                onChangeRowsPerPage={handleChangeRowsPerPageMedication}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            {/* Rx */}
            { printRx ?
                <Rx
                    open={printRx}
                    close={() => setPrintRx(false)}
                    claim_id={claimIdForRx}
                    patient_id={patientIdForRx}
                    doctors_id={doctorsIdForRx}
                />
                : null}

            {/* new personal medication form */}
            <PersonalMedicationForm
                open={newmedication}
                close={() => setNewMedication(false)}
                getPersonalMedicationByDate={getPersonalMedicationByDate}
            />

            {/* process dialog */}
            { cartData.data !== null && (
                <AddToCart
                    open={cartData.dialog}
                    close={() =>
                        setCartData({
                            data: null,
                            dialog: false
                        })
                    }
                    cartData={cartData}
                    type='Rx'
                />
            )}
        </Fragment>
    )
}

export default PersonalMedication; 