import React, { Component } from 'react';
import { Col, Row, Alert, ButtonGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faShareAlt, faTruck } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import Transaction from './Transaction';
import Loadout from './Loadout';
import RequestCredit from './RequestCredit';
import GTCContactInfo from '../../GTCContactInfo';
import Others from './Others';

export default class Credits extends Component {
    _mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _credit_balance: 0,
            _unpaidConsultation: 0,
            _unpaidConsuCount: 0,
            _credit_category: 'transaction',
        }

    }

    componentDidMount() {
        this._mounted = true;
        this.getCreditBalance();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    getCreditBalance = () => {
        var formdata = new FormData();
        formdata.set('token', this.state._token);
        formdata.set('management_id', this.state._management_id);
        formdata.set('user_id', this.state._user_id);

        Axios.post('patient/credits/credits-balance', formdata)
            .then(function (response) {
                const data = response.data;
                if (this._mounted) {
                    if (data.length > 0) {
                        this.setState({
                            _credit_balance: parseFloat(data[0].credit_total) - (parseFloat(data[0].credit_expense) + parseFloat(data[0].unpaid_not_enough_creditsss)),
                            _unpaidConsultation: data[0].unpaid_not_enough_credit,
                            _unpaidConsuCount: data[0].countUnpaid
                        })
                    }
                }
            }.bind(this)).catch(error => {
                Notify.requestError(error);
            });
    }

    handleCreditType = (e) => {
        this.setState({
            _credit_category: e.currentTarget.id
        })
    }

    render() {
        return (
            <Row>
                <Col sm={12} className="mt-3">
                    <Row>
                        <Col sm={8} md={9} className="mb-3">
                            <Row>
                                <ButtonGroup className="col-sm-12 col-md-9">
                                    <Button
                                        id="transaction"
                                        onClick={this.handleCreditType}
                                        variant={this.state._credit_category === 'transaction' ? "m-0 text-muted p-credit-active-btn" : "m-0 text-muted p-credit-btn"}
                                    > <FontAwesomeIcon icon={faArrowDown} /> Transaction </Button>
                                    <Button
                                        id="loadout"
                                        onClick={this.handleCreditType}
                                        variant={this.state._credit_category === 'loadout' ? "m-0 text-muted p-credit-active-btn" : "m-0 text-muted p-credit-btn"}
                                    > <FontAwesomeIcon icon={faArrowUp} /> LoadIn </Button>
                                    <Button
                                        id="request"
                                        onClick={this.handleCreditType}
                                        variant={this.state._credit_category === 'request' ? "m-0 text-muted p-credit-active-btn" : "m-0 text-muted p-credit-btn"}
                                    > <FontAwesomeIcon icon={faShareAlt} /> Request </Button>


                                    {/* naay g change */}
                                    <Button
                                        id="others"
                                        onClick={this.handleCreditType}
                                        variant={this.state._credit_category === 'others' ? "m-0 text-muted p-credit-active-btn" : "m-0 text-muted p-credit-btn"}
                                    > <FontAwesomeIcon icon={faTruck} /> Others </Button>   

                                </ButtonGroup>

                                {
                                    this.state._credit_category === 'transaction' ?
                                        <Col sm={12} className="mt-3">
                                            <Transaction />
                                        </Col>
                                        : this.state._credit_category === 'loadout' ?
                                            <Col sm={12} className="mt-3">
                                                <Loadout />
                                            </Col>
                                            : this.state._credit_category === 'request' ?
                                                <Col sm={12} className="mt-3">
                                                    <RequestCredit />
                                                </Col>
                                                : this.state._credit_category === 'others' ?
                                                    <Col sm={12} className="mt-3">
                                                        <Others />
                                                    </Col>
                                                    : null
                                }
                            </Row>
                        </Col>
                        <Col sm={4} md={3}>
                            <Alert variant="success text-center border border-success">
                                <strong> Credit Balance </strong>
                                <p className="h4 font-weight-bold"> {Notify.convertToNumber(this.state._credit_balance)} </p>
                            </Alert>
                            {
                                this.state._unpaidConsuCount > 0 ?
                                    <Alert variant="danger text-center border border-danger">
                                        <strong> {this.state._unpaidConsuCount} Unpaid Consultation </strong>
                                        <p className="h4 font-weight-bold"> {Notify.convertToNumber(this.state._unpaidConsultation)} </p>
                                    </Alert>
                                    : null
                            }
                            <GTCContactInfo />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}
