import React, { Component, Fragment } from 'react';  
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, DialogActions, DialogContent, TextField, Button } from '@material-ui/core';

export default class AppointmentAction extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
 
            _appid: this.props.appid,
            _appaction: this.props.appaction,

            _is_process: false,
		}
	} 

    handleAppaction = (e) =>{
        e.preventDefault();
        e.persist();
        var formdata = new FormData(e.target);  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);    
        formdata.set('username', this.state._username);    
        formdata.set('appointment_id', this.props.appid);  
        formdata.set('reference_no', this.props.reference_no);  
        formdata.set('connection', this.props.connection);  

        var error = [];
        if(formdata.get('appaction').length === 0 || formdata.get('appaction').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('action'); 
        }

        if(formdata.get('appmessage').length === 0 || formdata.get('appmessage').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('message'); 
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0){
            Notify.consoleLog('appointment action')
        }else{
            this.setState({
                _is_process: true
            })

            Axios.post('appointment/doctors/appointment-action', formdata )
            .then(function(response){ 
                const data = response.data;   
                if(this._mounted){ 
                    this.setState({
                        _is_process: false
                    })
                    if(data ==='pass-inv'){
                        Notify.fieldInvalid('password')
                    }
                    if(data ==='success'){
                        this.props.getApproveAppointment();
                        this.props.closeDialog();
                        this.props.getRequestapp();
                        Notify.successRequest('appointment action')
                    }
                    if(data ==='db-error'){
                        Notify.warnRequest('appointment action')
                    }
                } 
            }.bind(this)).catch(error=>{ 
                this.setState({
                    _is_process: false
                })
                Notify.requestError(error);
            });
        }
    }
    
    componentDidMount(){
        this._mounted = true;
    }

    componentDidUpdate(){
        if(this.state._appid !== this.props.appid || this.state._appaction !== this.props.appaction){
            this.setState({
                _appid: this.props.appid,
                _appaction: this.props.appaction
            })
            this.componentDidMount();
        }
    }

    componentWillUnmount(){
        this._mounted = false;
    }

	render(){ 
		return(
			<Fragment>
                <Form onSubmit={this.handleAppaction}> 
                    <DialogContent dividers>
                        <Box mb={2}>   
                            <TextField
                                fullWidth
                                label={`Appointment ${this.props.appaction}`}
                                defaultValue={this.props.appaction}
                                variant="outlined"
                                name="appaction"
                                InputProps={{
                                    inputProps:{
                                        readOnly: true
                                    }
                                }}
                            />
                        </Box>

                        <Box mb={2}>   
                            <TextField
                                fullWidth
                                label="Message" 
                                variant="outlined"
                                name="appmessage" 
                                multiline
                            />
                        </Box>

                        <Box> 
                            <TextField
                                fullWidth
                                label="Password" 
                                variant="outlined"
                                name="password" 
                                type="password"
                            />
                        </Box> 
                    </DialogContent>
                    <DialogActions> 
                        <Button 
                            variant="contained" 
                            color="inherit" 
                            onClick={() => this.props.closeDialog()}
                            startIcon={
                                <FontAwesomeIcon icon={faTimesCircle} />
                            }
                        >
                             No </Button>
                        
                        <Button 
                            type="submit"  
                            variant="contained"
                            color="primary"
                            disabled = {this.state._is_process}
                            startIcon = {
                                <FontAwesomeIcon
                                    icon = {this.state._is_process ? faCircleNotch : faCheckCircle }
                                    spin = {this.state._is_process }
                                />
                            }
                        >
                             Save </Button>

                    </DialogActions>
                </Form>
			</Fragment>
		)
	}
}
