
import { Box, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Button, DialogTitle, DialogContent, DialogActions, Tooltip, CircularProgress } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import Notify from 'src/notification/Notify'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import DraggableDialog from 'src/utils/DraggableDialog'
import { useHistory } from 'react-router-dom'
import SorologyRef from '../references/Ref_Sorology'
import FormvalidationSorology from './validation/FormvalidationSorology'


const SorologyOrderDetails = ({ order, getLabSorologyOrder, resetDisplay }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const history = useHistory()
    const [orderDetails, setOrderDetails] = useState({
        data: null,
        ready: false
    })

    const [pendingDialog, setPendingDialog] = useState(false)
    const [processDialog, setProcessDialog] = useState(false)

    const [pendingSubmitting, setPendingSubmitting] = useState(false)
    const [processSubmitting, setProcessSubmitting] = useState(false)
    const [resultSubmitting, setResultSubmitting] = useState(false)

    const getLabSoroOrderDetails = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', order.order_id);
        Axios.post('laboratory/order/ordernew-sorology/details', formdata)
            .then((response) => {
                const data = response.data;
                setOrderDetails({
                    data: data,
                    ready: true
                })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {

        getLabSoroOrderDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [order])

    const handleSaveResult = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', order.order_id);
        formdata.set('patient_id', orderDetails.data.patient_id)
        formdata.set('doctor_id', orderDetails.data.doctor_id)

        var error = [];

        if (orderDetails.data) {
            error = FormvalidationSorology(orderDetails.data, formdata);
        }

        if (error.length > 0) {
            console.log("Form has an error.")
        } else {
            setResultSubmitting(true)
            Axios.post('laboratory/order/ordernew-sorology/save-process-result', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        Notify.successRequest('order result added.')
                        setTimeout(() => {
                            history.push(`/app/laboratory/record/print/order/${order.order_id}`)
                        }, 5000);
                    }
                }).catch(error => {
                    Notify.requestError(error);
                });
        }
    }

    const handlePendingOrder = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.set('order_id', order.order_id);

        var error = [];

        if (error.length > 0) {
            console.log("Form has an error.")
        } else {
            setPendingSubmitting(true)
            Axios.post('laboratory/order/ordernew-sorology/save-setpending', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'success') {
                        getLabSorologyOrder()
                        resetDisplay()
                        setPendingDialog(false)
                        Notify.successRequest('order pending')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => setPendingSubmitting(false));
        }
    }

    const handleProcessOrder = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.set('order_id', order.order_id);

        var error = [];

        if (error.length > 0) {
            console.log("Form has an error.")
        } else {

            setProcessSubmitting(true)
            Axios.post('laboratory/order/ordernew-sorology/save-setprocessing', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'success') {
                        getLabSorologyOrder()
                        setProcessDialog(false)
                        resetDisplay()
                        Notify.successRequest('order pending')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => setProcessSubmitting(false));
        }
    }

    return (
        <>
            <form onSubmit={handleSaveResult}>
                <Card elevation={0}>
                    <CardHeader
                        component={Box}
                        align="center"
                        title="DONASCO DIAGNOSTIC LABORATORY"
                        subheader="2f. Planlaque Bldg., Papaya St. General Santos City"
                    />
                    <CardContent>
                        {/* paitent information */}
                        <Box display="flex">
                            <Box flexGrow={1} mb={2}>
                                <Box mb={2}>
                                    <Typography>
                                        <Typography variant="caption" className="font-weight-bold">
                                            NAME:
                                        </Typography>  {`${order.firstname} ${order.lastname}`}
                                    </Typography>
                                </Box>

                                <Box display="flex" mb={2}>
                                    <Box>
                                        <Typography>
                                            <Typography variant="caption" className="font-weight-bold">
                                                AGE:
                                            </Typography>  {order.birthday === null ? 'none' : Notify.calculateAge(order.birthday)}
                                        </Typography>
                                    </Box>
                                    <Box ml={5}>
                                        <Typography>
                                            <Typography variant="caption" className="font-weight-bold">
                                                SEX:
                                            </Typography>  {order.gender === null ? 'none' : order.gender}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography>
                                        <Typography variant="caption" className="font-weight-bold">
                                            ADDRESS:
                                        </Typography>  {`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box>
                                <Box mb={2}>
                                    <Typography>
                                        <Typography variant="caption" className="font-weight-bold">
                                            DATE:
                                        </Typography>  {Notify.dateTimeConvert(new Date().toLocaleString())}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>

                    <CardContent>
                        {/* order details */}
                        <Box>
                            <Typography variant="h6" align="center">
                                <b> SEROLOGY </b>
                            </Typography>
                        </Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell > <b> EXAMINATION REQUESTED </b> </TableCell>
                                        <TableCell align="center"> <b> NORMAL </b> </TableCell>
                                        <TableCell align="center"> <b> TEST RESULT </b> </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        orderDetails.ready ?
                                            Object.keys(orderDetails.data).length > 0 ?
                                                <>
                                                    {orderDetails.data.hbsag &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    Hepatitis B surface Antigen (HBsAg)
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {SorologyRef.normal()}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="hbsag"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {orderDetails.data.hav &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    HAV (Hepatitis A Virus) lgG/lgM
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {SorologyRef.normal()}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="hav"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    } 
                                                    {orderDetails.data.hcv &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    HCV (Hepatitis C Virus)
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {SorologyRef.normal()}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="hcv"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    } 
                                                    {orderDetails.data.vdrl_rpr &&
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                                    VDRL/RPR
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {SorologyRef.normal()}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Box>
                                                                    <TextField
                                                                        margin="dense"
                                                                        fullWidth
                                                                        required
                                                                        name="vdrl_rpr"
                                                                        label="Result"
                                                                        disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                    />
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </>
                                                :
                                                <TableRow>
                                                    <TableCell align="center" colSpan={3}>
                                                        <Typography color="secondary" variant="subtitle2">
                                                            No record found.
                                                    </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            :
                                            <TableRow>
                                                <TableCell align="center" colSpan={3}>
                                                    <Typography color="primary" variant="subtitle2">
                                                        please wait...
                                                </Typography>
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                    <Box display="flex" m={1}>
                        <Box flexGrow={1} />
                        <Box>
                            <CardActions>
                                <Tooltip title={`${order.is_pending_reason}`} open={Boolean(parseInt(order.is_pending))} arrow>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        onClick={() => setPendingDialog(true)}
                                        disabled={Boolean(parseInt(order.is_pending))}
                                    >
                                        Set as Pending
                                    </Button>
                                </Tooltip>

                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    disabled={Boolean(parseInt(order.is_processed))}
                                    onClick={() => setProcessDialog(true)}
                                >
                                    Set As PRocess
                                </Button>

                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    type="submit"
                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                    startIcon={resultSubmitting && <CircularProgress size={20} color="inherit" />}
                                >
                                    Save Result
                                </Button>
                            </CardActions>
                        </Box>
                    </Box>
                </Card>
            </form>

            {/* set as pending dialog */}
            <Dialog
                open={pendingDialog}
                onClose={() => setPendingDialog(false)}
                disableBackdropClick
                PaperComponent={DraggableDialog}
            >
                <DialogTitle id="draggable-handle">
                    Set as pending
                </DialogTitle>
                <form
                    onSubmit={handlePendingOrder}
                >
                    <DialogContent dividers>
                        <Box mb={2}>
                            <TextField
                                rows={5}
                                fullWidth
                                name="reason"
                                label={`Pending Reason`}
                                variant="outlined"
                                multiline
                            />
                        </Box>

                        <Box>
                            <TextField
                                fullWidth
                                name="password"
                                label={`Password`}
                                variant="outlined"
                                type="password"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="default" onClick={() => setPendingDialog(false)} startIcon={<HighlightOffIcon />}>
                            cancel
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" disabled={pendingSubmitting} startIcon={pendingSubmitting ? <CircularProgress size={20} color="inherit" /> : <ErrorOutlineIcon />}>
                            pending
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* set as process dialog */}
            <Dialog
                open={processDialog}
                onClose={() => setProcessDialog(false)}
                disableBackdropClick
                PaperComponent={DraggableDialog}
            >
                <DialogTitle id="draggable-handle">
                    Set as processing
                </DialogTitle>
                <form
                    onSubmit={handleProcessOrder}
                >
                    <DialogContent dividers>
                        <Box>
                            <TextField
                                fullWidth
                                name="password"
                                label={`Password`}
                                variant="outlined"
                                type="password"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="default" onClick={() => setProcessDialog(false)} startIcon={<HighlightOffIcon />}>
                            cancel
                        </Button>
                        <Button variant="contained" color="primary" type="submit" disabled={processSubmitting} startIcon={processSubmitting ? <CircularProgress size={20} color="inherit" /> : <CheckCircleIcon />}>
                            process
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default SorologyOrderDetails;
