import React, { Fragment, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; 
import Menu from '@material-ui/core/Menu'; 
import MenuItem from '@material-ui/core/MenuItem';  
import IconButton from '@material-ui/core/IconButton'; 
import Typography from '@material-ui/core/Typography';  
import Slide from '@material-ui/core/Slide';
import TablePagination from '@material-ui/core/TablePagination'; 
import Dialog from '@material-ui/core/Dialog'; 
import MoreVert from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/AddCircleOutline'; 

import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';

import Axios from 'axios';
import Notify from '../../../notification/Notify';
import Box from '@material-ui/core/Box'; 
import TextField from '@material-ui/core/TextField'; 
import Grid from '@material-ui/core/Grid';
import TreatmentPlanEdit from './TreatmentPlanEdit';
import TreatmentPlanDelete from './TreatmentPlanDelete';
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'; 
import { DialogTitle, Button, DialogContent, DialogActions, CircularProgress, Paper, CardMedia, Card, CardHeader } from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';   
import { Lightbox } from "react-modal-image";
import TreatmentPlanCanvas from './TreatmentPlanCanvas';

const imageLocation = process.env.REACT_APP_API_IMAGE;

function TreatmentPlan(props) {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))  
    const [username] = useState(localStorage.getItem('username'))  
    const [management_id] = useState(localStorage.getItem('management_id'))  

    const [planDate, setPlanDate] = useState(new Date());

    const [opendialogdetails, setopendialogdetails] = useState(false);  
    const [anchorEl, setAnchorEl] = useState(null); 
    const [viewtplanid, setviewtplanid] = useState(null); 
    const [newtreatmentplan, setnewtreatmentplan] = useState(false);  

    const [treatment, settreatment] = useState([])  
    const [treatmentid, settreatmentid] = useState(null) 
    const [treatmentplan, settreatmentplan] = useState(null) 

    const [opendialogremove, setopendialogremove] = useState(false);  

    // pagination
    const [page, setPage] = React.useState(0); 
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // pagination
    const [pagefile, setPageFile] = React.useState(0); 
    const [rowsPerPageFile, setRowsPerPageFile] = React.useState(3);

    const [openCanvasDialog, setOpenCanvasDialog] = useState(false) 
 
    const [anchorUpdate, setAnchorUpdate] = React.useState(null);

    const [isProcess, setIsProcess] = React.useState(false)

    const [treatmentFile, settreatmentFile] = useState([])

    const [imagebox, setImageBox] = useState({
        image: null,
        open: false,
    })

    const handleClick = (event) => {
        setAnchorUpdate(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorUpdate(null);
    };


    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePageFile = (event, newPage) => {
        event.persist()
        setPageFile(newPage);
    };

    const handleChangeRowsPerPageFile = (event) => {
        setRowsPerPageFile(parseInt(event.target.value, 10));
        setPageFile(0);
    };
    
    const handleActionmenu = (event) => { 
        setAnchorEl(event.currentTarget);  
        settreatmentid(event.currentTarget.id)
        settreatmentplan(event.currentTarget.getAttribute('treatmentplan'))
    };

    const handleCloseActionmenu = () => {
        setAnchorEl(null); 
        settreatmentid(null)
        settreatmentplan(null)
    }; 

    const handleOpenDialogEdit = (e) => {
        e.persist() 
        setopendialogdetails(true);  
    };
    
    const handleCloseDialogEdit = () => {
        setopendialogdetails(false); 
        handleCloseActionmenu()
    };

    const handleOpenDialogDelete = () =>{
        setopendialogremove(true)
    }

    const handleCloseDialogDelete = () =>{
        setopendialogremove(false); 
        handleCloseActionmenu()
    }

    const getTreatmentPlanText = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);     
        formdata.set('patient_id', props.patient_id);     
        formdata.set('type', 'text');     
        
        Axios.post('doctor/patient/patient-treatmentplan', formdata )
        .then( (response) => { 
            const data = response.data;    
            settreatment(data) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    } 

    const getTreatmentPlanFile = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);     
        formdata.set('patient_id', props.patient_id);     
        formdata.set('type', 'file');     
        
        Axios.post('doctor/patient/patient-treatmentplan', formdata )
        .then( (response) => { 
            const data = response.data;    
            settreatmentFile(data) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    } 

    const handlenewTplan = (e) =>{
        e.preventDefault()
        e.persist()
        
        var formdata = new FormData(e.target)
            formdata.set('token', token)
            formdata.set('user_id', user_id)
            formdata.set('username', username)
            formdata.set('management_id', management_id) 
            formdata.set('patient_id', props.patient_id) 

        var error = [];
        if(formdata.get('treatmentplan').length === 0 || formdata.get('treatmentplan').trim()===''){  
            Notify.fieldRequired('treatment plan')
            error = 'error';
        }

        if(formdata.get('plan_date').length === 0 || formdata.get('plan_date').trim()===''){  
            Notify.fieldRequired('date plan')
            error = 'error';
        }

        if(error.length > 0 ){
            console.warn('Unable to process , form has an error.')
        }else{
            setIsProcess(true)
            Axios.post('doctor/patient/patient-treatmentplansave', formdata )
            .then( (response) => { 
                const data = response.data;    
                if(data ==='success'){ 
                    e.target.reset(); 
                    Notify.successRequest('treatment plan')
                    setnewtreatmentplan(false)
                    getTreatmentPlanText()
                }
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(() => setIsProcess(false));
        }
    }

    useEffect(() =>{

        getTreatmentPlanText()  
        getTreatmentPlanFile()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.patient_id])
 

    return (
        <Fragment>     

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={7} lg={8}> 
                        <Paper
                            variant="outlined"
                        >
                            <Box m={2} mb={0}>
                                <Box display="flex">
                                    <Box flexGrow={1}>
                                        <Typography variant="h6">
                                            Treatment Plan  
                                        </Typography> 
                                    </Box>
                                    <Button
                                        onClick={handleClick}
                                        color="primary"
                                        startIcon={ <AddIcon /> }
                                    >
                                        update
                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorUpdate}
                                        keepMounted
                                        open={Boolean(anchorUpdate)}
                                        onClose={handleClose} 
                                    >
                                        <MenuItem onClick = { () => setnewtreatmentplan(true) } > Treatment Plan </MenuItem>
                                        <MenuItem onClick = { () => setOpenCanvasDialog(true) } > Treatment Canvas </MenuItem> 
                                    </Menu> 
                                </Box> 
                            </Box> 
                            <TableContainer >
                                <Table>
                                    <TableHead>
                                        <TableRow>  
                                            <TableCell> Schedule </TableCell>
                                            <TableCell> Treatment Plan </TableCell>
                                            <TableCell> Action </TableCell> 
                                        </TableRow>
                                    </TableHead>
                                    <TableBody> 
                                        { 
                                            treatment.length > 0 ?
                                                (rowsPerPage > 0
                                                    ? treatment.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : treatment
                                                ).map((data, index) =>{
                                                    return(
                                                        <TableRow key={index}> 
                                                            <TableCell> { data.date === null ? Notify.dateTimeConvert(data.created_at) : Notify.dateTimeConvert(data.date)  }</TableCell>
                                                            <TableCell > 
                                                                <Typography className="pointer" style={{width: '20vw'}}  noWrap={ viewtplanid === data.id ? false : true} onClick ={ ()=> setviewtplanid(viewtplanid === data.id ? null : data.id )}>
                                                                    { data.treatment_plan }
                                                                </Typography> 
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton treatmentplan = {data.treatment_plan} id={data.id} aria-controls={'menutest_'+data.id} aria-haspopup="true" onClick={handleActionmenu}>
                                                                    <MoreVert fontSize="small" />
                                                                </IconButton>
                                                                <Menu  
                                                                    id={'menutest_'+data.id}
                                                                    anchorEl={anchorEl} 
                                                                    open={Boolean(anchorEl)}
                                                                    onClose={handleCloseActionmenu} 
                                                                > 
                                                                    <MenuItem className="gtc-text-primary" onClick={handleOpenDialogEdit}><EditOutlined />  Edit  </MenuItem>
                                                                    <MenuItem className="gtc-text-danger" onClick={handleOpenDialogDelete}><DeleteOutlined /> Delete  </MenuItem>
                                                                </Menu>
                                                            </TableCell> 
                                                        </TableRow>
                                                    )
                                                })
                                            :
                                            <TableRow>
                                                <TableCell colSpan={3} className="text-danger text-center">
                                                    <Typography color="error">
                                                        No treatment plan created.
                                                    </Typography>
                                                </TableCell>
                                            </TableRow> 
                                        } 
                                    </TableBody>
                                </Table>   
                                <TablePagination
                                    rowsPerPageOptions={[5, 20, 50, 100]}
                                    component="div"
                                    count={treatment.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                            
                        </Paper>
                    </Grid>

                    <Grid item  xs={12} sm={12} md={5} lg={4}> 
                        <Box>
                            {
                                treatmentFile.length > 0 ?
                                    (rowsPerPageFile > 0
                                        ? treatmentFile.slice(pagefile * rowsPerPageFile, pagefile * rowsPerPageFile + rowsPerPageFile)
                                        : treatmentFile
                                    ).map((data, index) =>{
                                        return(
                                            <Card key={index} component={Box} mb={2}> 
                                                <CardHeader 
                                                    disableTypography
                                                    title={ Notify.dateTimeConvert(data.date) }
                                                /> 
                                                <CardMedia 
                                                    component="img"
                                                    src={`${imageLocation}doctors/treatmentplan/${data.treatment_plan}`}
                                                    alt=""
                                                    style={{ maxHeight: 100 }}
                                                    onClick={ () =>{
                                                        setImageBox({
                                                            image: data.treatment_plan,
                                                            open:  true
                                                        })
                                                    }}
                                                />
                                            </Card>
                                        )
                                    })
                                : <Box align="center" mt={3}> 
                                    { Notify.noRecord() }
                                </Box>
                            } 
                        </Box>
                        <TablePagination
                            rowsPerPageOptions={[3, 20, 50, 100]}
                            component="div"
                            count={treatmentFile.length}
                            rowsPerPage={rowsPerPageFile}
                            page={pagefile}
                            onChangePage={handleChangePageFile}
                            onChangeRowsPerPage={handleChangeRowsPerPageFile}
                            labelRowsPerPage="List"
                        />
                        {/* openimage larger */}
                        {
                            imagebox.open && imagebox.image && (
                                <Lightbox
                                    large={imageLocation+'doctors/treatmentplan/'+imagebox.image}
                                    alt=""  
                                    onClose = { () =>{
                                        setImageBox({
                                            image: null,
                                            open:  false
                                        })
                                    }}
                                />
                            )
                        }
                    </Grid> 
                </Grid>

            {/* dialog details */}  
            <Dialog
                open={opendialogdetails} 
                onClose={handleCloseDialogEdit} 
                disableBackdropClick={true} 
                onExited={handleCloseDialogEdit}  
                TransitionComponent={Slide}
                transitionDuration={600} 
            >    
                <DialogTitle>
                    Edit Treatment Plan
                </DialogTitle> 
                <TreatmentPlanEdit treatment_id = {treatmentid} tplan={ treatmentplan }  getTreatmentPlanText = { getTreatmentPlanText } closedialog={handleCloseDialogEdit} />  
            </Dialog>

            {/* dialog remove */}  
            <Dialog
                open={opendialogremove} 
                onClose={handleCloseDialogDelete} 
                disableBackdropClick={true} 
                onExited={handleCloseDialogDelete}  
                TransitionComponent={Slide}
                transitionDuration={600} 
            >   
                <DialogTitle>
                    Delete Treatment Plan
                </DialogTitle> 
                <TreatmentPlanDelete treatment_id = {treatmentid}  getTreatmentPlanText = { getTreatmentPlanText } closedialog={handleCloseDialogDelete} />   
            </Dialog>

            {/* dialog canvas treatment plan */}
            <Dialog
                open={ openCanvasDialog }
                onClose= { () => setOpenCanvasDialog(false) }
                disableBackdropClick
                disableEscapeKeyDown
                fullScreen
            > 
                <DialogTitle
                    disableTypography
                >
                    <Typography variant="subtitle2" className={`gtc-uppercase`}>
                        Draw Treatment
                    </Typography>
                </DialogTitle> 

                <TreatmentPlanCanvas getTreatmentPlanFile = { getTreatmentPlanFile } close= { () => setOpenCanvasDialog(false) } patient_id= { props.patient_id } /> 
            </Dialog>

            {/* dialog treatment */}
            <Dialog
                open = { newtreatmentplan }
                onClose ={ () => setnewtreatmentplan(false) }
            >
                <DialogTitle
                    disableTypography
                >
                    <Typography variant="subtitle2" className={`gtc-uppercase`}>
                        Treatment Plan
                    </Typography>
                </DialogTitle> 
                <form onSubmit={ handlenewTplan }> 
                    <DialogContent dividers>
                        <Box mb={2}>
                            <TextField  
                                fullWidth 
                                name="treatmentplan" 
                                label="New Treatment Plan" 
                                autoComplete="off"  
                                variant="outlined" 
                                multiline 
                            />
                        </Box>

                        <Box>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>  
                                <DateTimePicker
                                    fullWidth
                                    label="Date Time Picker"
                                    inputVariant="outlined" 
                                    value={ planDate }
                                    onChange={ (e) => setPlanDate(e) }
                                    format="yyyy/MM/dd hh:mm a"
                                    name="plan_date"
                                />
                            </MuiPickersUtilsProvider> 
                        </Box> 
                    </DialogContent> 
                    <DialogActions>  
                        <Button 
                            variant="contained"
                            color="default" 
                            onClick ={ () => setnewtreatmentplan(false)}
                            startIcon={ <CancelIcon /> }
                        >
                            Close
                        </Button>  
                        <Button 
                            variant="contained"
                            color="primary" 
                            type="submit"
                            startIcon={ isProcess ? <CircularProgress size={20} color="inherit" /> : <CheckCircleIcon /> }
                            disabled={ isProcess }
                        >
                            Save
                        </Button> 
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    )
}

export default TreatmentPlan;
