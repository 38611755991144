import React, { Fragment, useState, useCallback, useEffect, Suspense } from 'react'; 

import {  Box, Tabs, Tab, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core'; 
import CloseIcon from '@material-ui/icons/Cancel';
 
import Cookies from 'universal-cookie';
import TabPanel from '../utils/TabPanel';
import Notify from '../notification/Notify';
import Axios from 'axios'; 

const PatientHeader = React.lazy(() => import('./patients/PatientHeader'));

const cookie = new Cookies();

function DashboardAlternative() { 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const management_id = localStorage.getItem('management_id')
 

    const [activeTab, setactiveTab] = useState(0)

    const [tabx, setTabx] = useState( cookie.get('tabs') === undefined ?  [ { id: 0, value: 'Patient List' } ] : cookie.get('tabs') )

    const [patients, setPatients] = useState([])
    const [patientsRd, setPatientsRd] = useState(false)

    
    const handleChange = (e, newvalue) =>{ 
        setactiveTab(newvalue)
    }

    const updateTabx = (e) =>{ 
        console.log(e.currentTarget.id)
        var id = e.currentTarget.id ;
        tabx.push({ id: id, value: e.currentTarget.title }) 
        setactiveTab(id)   
        cookie.set('tabs', JSON.stringify(tabx)) // save tabs in cookies
    }  
 
    const handleCloseTabx = useCallback(
        (event, tabToDelete) => {
            event.stopPropagation();
        
            const tabToDeleteIndex = tabx.findIndex(
                tab => tab.id === tabToDelete
            );
            const updatedTabs = tabx.filter((tab, index) => {
                return index !== tabToDeleteIndex;
            });
            const previousTab =
                tabx[tabToDeleteIndex - 1] ||
                tabx[tabToDeleteIndex + 1] ||
                {};

                cookie.set('tabs', JSON.stringify(updatedTabs)) // update tabs in cookies
                setTabx(updatedTabs);
                setactiveTab(previousTab.id);
            },
        [tabx]
    ); 


    const getPatients = useCallback( async () =>{
        try {
            var formdata = new FormData() 
            formdata.set('token', token); 
            formdata.set('management_id', management_id);   
            formdata.set('user_id', user_id);     

            const request = await Axios.post('doctor/patients/all/patient-list', formdata)  
            setPatients(request.data)
            setPatientsRd(true)
        } catch (error) {
            Notify.requestError(error);
        } 
    },[token, user_id, management_id]) 

    useEffect(() =>{

        getPatients()

    },[getPatients])

    return (
        <Fragment>   
            <Paper>   
                <Tabs 
                    component={Box}
                    py={1}
                    value={ activeTab } 
                    onChange={ handleChange } 
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {
                        tabx.map((data, index) => (
                            <Tab  
                                wrapped
                                key={ index }
                                value={ data.id }   
                                label={ 
                                    <Box>
                                        {data.value }
                                        { data.id !== 0 && data.id === activeTab && (  
                                            <CloseIcon
                                                id={ data.id }
                                                size="small"
                                                color="secondary" 
                                                onClick={event => handleCloseTabx(event, data.id)}
                                                style={{
                                                    position: "absolute",
                                                    top: -2,
                                                    right: 2
                                                }}
                                                fontSize="small"
                                            /> 
                                        )}
                                    </Box>
                                }
                            />
                        ))
                    } 
                </Tabs>  
                
                <TabPanel
                    value = { activeTab } 
                    index = { activeTab }
                > 
                    {
                        activeTab === 0 ? 
                            patientsRd ? 
                                patients.length > 0 && (  
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell> Name </TableCell>
                                                    <TableCell> Added By </TableCell>
                                                    <TableCell> Appointment </TableCell>
                                                    <TableCell> New Update </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    patientsRd ? 
                                                        patients.length > 0 ?
                                                            patients.map((data, index) => (
                                                                <TableRow 
                                                                    key={ index }
                                                                    id={ data.patient_id }
                                                                    title={ data.patients_name }
                                                                    onClick = { updateTabx } 
                                                                    hover
                                                                    className={`pointer`}
                                                                >
                                                                    <TableCell> { data.patients_name } </TableCell>
                                                                    <TableCell> { data.added_by }</TableCell>
                                                                    <TableCell> Appointment 1 </TableCell>
                                                                    <TableCell> New Update 1 </TableCell>
                                                                </TableRow>
                                                            ))
                                                        :
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="subtitle2" color="secondary"> No patient </Typography>
                                                            </TableCell> 
                                                        </TableRow>
                                                    :
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                                        </TableCell> 
                                                    </TableRow>
                                                } 
                                            </TableBody>
                                        </Table>
                                    </TableContainer> 
                                )
                            : Notify.loading()
                        :   <Suspense fallback={Notify.loading()}>
                                <PatientHeader patient_id ={ activeTab }  />
                            </Suspense> 
                    } 
                </TabPanel> 
                    
            </Paper>
        </Fragment>
    )
}

export default DashboardAlternative; 
  