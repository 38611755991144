import { TableHead, Table, TableCell, TableRow, TableBody, TableContainer, Typography, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Notify from 'src/notification/Notify';
import axios from 'axios'; 
import Label from 'src/utils/Label'; 
import { grey } from '@material-ui/core/colors';
import ChemistryRef from 'src/laboratory/newlaboratory/references/Ref_Chemistry';

const ChemistryOrder = ({ order_id , patient_id }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [details, setDetails] = useState(null)

    const getpaidLaboratoryOrderDetails = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id); 
        formdata.set('patient_id', patient_id);    
        formdata.set('order_id', order_id);     
        formdata.set('table', 'laboratory_chemistry');     
        formdata.set('connection', 'local');
        
        axios.post('doctor/patient/laboratory/order/paid-detailsbytable', formdata )
        .then((res) =>{
            const data = res.data
            setDetails(data)
        }).catch((er) => Notify.requestError(er))
    }
    
    const checkResult = (order) => {
        return  order === 'new-order' ? 
                <Label color="error"> no result </Label> 
            :   order === 'refund' ? 
                <Label color="warning"> {order} </Label>
            :   <Label color="success"> {order} </Label>
    }

    useEffect(() =>{

        getpaidLaboratoryOrderDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order_id, patient_id]) 

    return(
        <>  
            { details && Object.keys(details).length > 0 && (
                <Box border={1} borderColor={grey[300]} borderRadius={4} my={2} p={2}>
                    <Box>
                        <Label color="primary"> CLINICAL CHEMISTRY ORDER   </Label>
                    </Box>
                    
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"> <b> Test Request </b> </TableCell>
                                    <TableCell align="center"> <b> Result </b> </TableCell>
                                    <TableCell align="center"> <b> Reference Values </b> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    details.glucose &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                GLUCOSE
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { checkResult(details.glucose) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {ChemistryRef.glucose()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    details.creatinine &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                CREATININE
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {checkResult(details.creatinine) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {ChemistryRef.creatinine()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    details.uric_acid &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                URIC ACID
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {checkResult(details.uric_acid) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {ChemistryRef.uric()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    details.cholesterol &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                CHOLESTEROL
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {checkResult(details.cholesterol) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {ChemistryRef.cholesterol()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    details.triglyceride &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                TRIGLYCERIDE
                                                                </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {checkResult(details.triglyceride) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {ChemistryRef.triglyceride()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    details.hdl_cholesterol &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                HDL CHOLESTEROL
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {checkResult(details.hdl_cholesterol) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {ChemistryRef.hdlcholesterol()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    details.ldl_cholesterol &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                LDL CHOLESTEROL
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { checkResult(details.ldl_cholesterol) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {ChemistryRef.ldlcholesterol()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    details.sgot &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                SGOT
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { checkResult(details.sgot) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {ChemistryRef.sgot()}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    details.sgpt &&
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                SGPT
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { checkResult(details.sgpt) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {ChemistryRef.sgpt()}
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </>
    )
}

export default ChemistryOrder;