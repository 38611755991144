import React, { Fragment, Suspense, useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import DoctorsInformationComment from './DoctorsInformationComment';
import { Box, CardContent, CardMedia, Grid, Card, Typography, TableContainer, TableRow, TableCell, Table, TableHead, TableBody, Tabs, Tab, makeStyles, CircularProgress, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { blue, grey, red } from '@material-ui/core/colors';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CommentIcon from '@material-ui/icons/Comment';
import TabPanel from '../../utils/TabPanel';
import { useParams } from 'react-router-dom';


// import RequestAppointment from './RequestAppointment'; 
const RequestAppointment = React.lazy(() => import('./RequestAppointment'));

const useStyle = makeStyles({
    noIndicator: {
        backgroundColor: "transparent"
    }
});

const imageLocation = process.env.REACT_APP_API_IMAGE;

const DoctorsInformation = () => {
    const classes = useStyle();
    const user_id = localStorage.getItem('user_id');
    const token = localStorage.getItem('token');
    const management_id = localStorage.getItem('management_id');

    const { doctor_id } = useParams()

    const [doctorInfo, setDoctorInfo] = useState([])
    const [doctorInfoReady, setDoctorInfoReady] = useState(false)

    const [doctorService, setDoctorService] = useState([])
    const [doctorServiceReady, setDoctorServiceReady] = useState(false)
    const [credit, setCredit] = useState(0)
    const [category, setCategory] = useState('request')

    const [unpaidConsultation, setUnpaidCosultation] = useState(0)
    const [unpaidConsuCount, setUnpaidConsuCount] = useState(0)


    const getDoctorsInformation = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('management_id', management_id);
        formdata.set('user_id', user_id);
        formdata.set('doctors_id', doctor_id);

        Axios.post('appointment/doctors/doctors-information', formdata)
            .then(function (response) {
                const data = response.data;
                setDoctorInfo(data)
                setDoctorInfoReady(true)

            }).catch(error => {
                Notify.requestError(error);
            });
    }, [user_id, token, management_id, doctor_id])


    const getServices = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('doctors_id', doctor_id);

        Axios.post('appointment/doctors/doctors-services', formdata)
            .then(function (response) {
                const data = response.data;
                setDoctorService(data)
                setDoctorServiceReady(true)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [user_id, token, doctor_id])

    const getCreditBalance = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('management_id', management_id);
        formdata.set('user_id', user_id);
        formdata.set('doctors_id', doctor_id);

        Axios.post('patient/credits/credits-balance', formdata)
            .then(function (response) {
                const data = response.data; 
                if (data.length > 0) { 
                    setCredit(parseFloat(data[0].credit_total) - (parseFloat(data[0].credit_expense) + parseFloat(data[0].unpaid_not_enough_creditsss)))
                    setUnpaidCosultation(data[0].unpaid_not_enough_credit);
                    setUnpaidConsuCount(data[0].countUnpaid)
                }
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [user_id, token, management_id, doctor_id])

    const handleChange = (e, newCategory) => {
        e.persist()
        setCategory(newCategory)
    }

    useEffect(() => {
        getDoctorsInformation()
        getServices()
        getCreditBalance()
    }, [getDoctorsInformation, getServices, getCreditBalance])

    return (
        <Fragment>
            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={9}>
                        <Box mb={1}>
                            <Typography variant={'subtitle2'} color="primary">
                                DOCTORS INFORMATION
                            </Typography>
                        </Box>

                        <Box>
                            {
                                doctorInfoReady ?
                                    doctorInfo.length > 0 ?
                                        <Box
                                            border={1}
                                            borderColor={grey[200]}
                                            bgcolor="white"
                                            p={2}
                                            className="gtc-textfield-noborder"
                                        >
                                            <Grid container spacing={3}>
                                                <Grid item xs={4} sm={4} md={3}>
                                                    <Card
                                                        elevation={0}
                                                    >
                                                        <CardMedia
                                                            component="img"
                                                            image={doctorInfo[0].image === null ? "/doctorsIcon.png" : imageLocation + 'doctors/' + doctorInfo[0].image}
                                                            title="test"
                                                            alt=""
                                                            style={{ borderRadius: '50%' }}
                                                        />
                                                        <CardContent>
                                                            <Box mt={2}>
                                                                <Typography align="center" variant={'subtitle2'} color="primary" style={style.uppercase}>
                                                                    {doctorInfo[0].name}
                                                                </Typography>
                                                                <Typography align="center" style={style.capitalize} variant="body2">
                                                                    {doctorInfo[0].specialization}
                                                                </Typography>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>

                                                <Grid item xs={8} sm={8} md={9}>
                                                    <Box mb={3}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            label="Age"
                                                            variant="outlined"
                                                            value={doctorInfo[0].birthday === null ? "None" : Notify.calculateAge(doctorInfo[0].birthday)}
                                                            inputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box mb={3}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            label="Birthday"
                                                            variant="outlined"
                                                            value={doctorInfo[0].birthday === null ? "None" : Notify.birthday(doctorInfo[0].birthday)}
                                                            inputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box mb={3}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            label="Gender"
                                                            variant="outlined"
                                                            value={doctorInfo[0].gender === null ? 'None' : doctorInfo[0].gender}
                                                            inputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box mb={3}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            label="Contact No."
                                                            variant="outlined"
                                                            value={doctorInfo[0].contact_no === null ? 'None' : doctorInfo[0].contact_no}
                                                            inputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box mb={3}>
                                                        <TextField
                                                            multiline
                                                            fullWidth
                                                            label="Address"
                                                            variant="outlined"
                                                            value={doctorInfo[0].address === null ? 'None' : doctorInfo[0].address}
                                                            inputProps={{
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        : Notify.noRecord()
                                    : Notify.loading()
                            }
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Box mb={2} border={1} borderColor={blue[100]} width={'100%'}>
                            <Alert
                                icon={
                                    <Box mt={1}>
                                        <WalletIcon fontSize="large" />
                                    </Box>
                                }
                                variant="standard"
                                severity="info"
                            >
                                <Typography variant="caption" style={style.uppercase}>
                                    Credit Balance
                                </Typography>
                                <Typography variant="h5" style={style.uppercase}>
                                    {Notify.convertToNumber(credit)}
                                </Typography>
                            </Alert>
                        </Box>
                        {
                            unpaidConsuCount > 0 ?
                                <Box mb={2} border={1} borderColor={red[100]} width={'100%'}>
                                    <Alert
                                        icon={
                                            <Box mt={1}>
                                                <WalletIcon fontSize="large" />
                                            </Box>
                                        }
                                        variant="standard"
                                        severity="error"
                                    >
                                        <Typography variant="caption" style={style.uppercase}>
                                            Unpaid Consultation
                                </Typography>
                                        <Typography variant="h5" style={style.uppercase}>
                                            {Notify.convertToNumber(unpaidConsultation)} {`(${unpaidConsuCount})`}
                                        </Typography>
                                    </Alert>
                                </Box>
                                : null
                        }
                        <TableContainer
                            component={Box}
                            border={1}
                            borderColor={grey[200]}
                            p={2}
                            bgcolor="white"
                        >
                            <Typography variant="subtitle2" style={style.uppercase} color="primary"> Services Available </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> <strong> Services </strong>  </TableCell>
                                        <TableCell align="right"> <strong> Credit </strong>  </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        doctorServiceReady ?
                                            doctorService.length > 0 ?
                                                doctorService.map((data, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell className="text-left"> {data.services.toUpperCase()}  </TableCell>
                                                            <TableCell className="text-right"> {data.amount} </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                                : <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography color="secondary"> No record </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            : <TableRow>
                                                <TableCell colSpan={2}>
                                                    <Typography color="primary"> please wait... </Typography>
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12} sm={9}>

                        <Tabs
                            component={Box}
                            border={1}
                            borderColor={grey[200]}
                            bgcolor="white"
                            value={category}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            classes={{ indicator: classes.noIndicator }}
                        >
                            <Tab value="request" wrapped icon={<DateRangeIcon />} label="Request Appointment" />
                            <Tab value="comment" wrapped icon={<CommentIcon />} label="Comments" />
                        </Tabs>

                        <Box my={2}>
                            <TabPanel value={category} index={'request'}>
                                <Suspense
                                    fallback={
                                        <> <CircularProgress size={20} /> please wait...</>
                                    }
                                >   
                                    <RequestAppointment
                                        services={doctorService}
                                        credits={credit}
                                        countUnpaid={unpaidConsuCount}
                                        doctors_id={doctor_id}
                                        getCredit={getCreditBalance}
                                    />
                                </Suspense>
                            </TabPanel>

                            <TabPanel value={category} index={'comment'}>
                                <DoctorsInformationComment doctors_id={doctor_id} />
                            </TabPanel>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </Fragment>
    )
}

export default DoctorsInformation;

const style = {
    uppercase: {
        textTransform: 'uppercase',
    },
    capitalize: {
        textTransform: 'capitalize'
    }
}