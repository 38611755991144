
import React, { useState, useEffect } from 'react'
import { Avatar, Box, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@material-ui/core';
import Report from './Report';
import Notify from 'src/notification/Notify';
import axios from 'axios';
import LocalAppDetails from './LocalAppDetails';

const LocalAppointment =  () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [incomplete, setIncomplete] = useState({
        data: [],
        ready: false
    })

    const [selectedApp, setSelectedApp] = useState(null)

    const getIncompleteList = () =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);         
        
        axios.post('encoder/appointment/local/incomplete-list', formdata )
        .then( (response) => { 
            const data = response.data;     
            setIncomplete({
                data: data, ready: true
            })  
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }  

    useEffect(() =>{

        getIncompleteList()  
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
 

    return( 
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={3}>
                <Box
                    component={Paper}
                    variant="outlined" 
                    px={1}
                >  
                    <Box ml={2} mt={2}>
                        <Typography variant="subtitle2" color="primary" className="gtc-uppercase">
                            Appointment
                        </Typography>
                    </Box>

                    <Box> 
                        <List component="div">
                            {
                                incomplete.ready ? 
                                    incomplete.data.length > 0 ? 
                                        incomplete.data.map((data, index) =>(
                                            <ListItem
                                                key={ index }
                                                onClick={ () => setSelectedApp(data) }
                                                selected={ selectedApp && selectedApp.id === data.id }
                                                button
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        {index  +1}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText 
                                                    className={`gtc-capitalize`}
                                                    primary={`${data.lname}, ${data.fname}`}
                                                    secondary={ Notify.dateTimeConvert(data.appointment_date) }
                                                />
                                            </ListItem>
                                        ))
                                    : Notify.noRecord()
                                : Notify.loading()
                            } 
                        </List>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12} sm={8} lg={9}>
                { ! selectedApp && ( <Report /> ) }
                { selectedApp && <LocalAppDetails app={ selectedApp } getIncompleteList = { getIncompleteList } /> }
            </Grid>
        </Grid> 
    )
}

export default LocalAppointment;