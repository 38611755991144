import React, { useCallback, useState, useEffect, Fragment } from 'react' 
import { Box, Typography } from '@material-ui/core';
import { grey, blue } from '@material-ui/core/colors';  
import PerfectScrollbar from 'react-perfect-scrollbar'; 
import Notify from '../notification/Notify';

const RequestMessages = ({ messages, patient_id }) =>{  

    const [scrollHeight, setScrollHeight] = useState(null);
    
    const scrollBottom = useCallback(() =>{ 
        if (scrollHeight) { scrollHeight.scrollTop = scrollHeight.scrollHeight; }
    },[scrollHeight])

    useEffect(() => {

        setTimeout(() => { scrollBottom() }, 500); 

    }, [patient_id, scrollBottom, scrollHeight, messages]);
  
    return( 
        <Box>  
            <Box   
                height={'calc(100vh - 205px)'}
                overflow="auto"    
                pt={1} 
            >
                <PerfectScrollbar 
                    id={ patient_id }
                    containerRef={ref => { 
                        setScrollHeight(ref);
                    }}
                >
                    {
                        messages.ready ?
                            messages.data.length > 0 ?
                                messages.data.map((data, index) =>(
                                    <Fragment key={index}>  
                                        {
                                            data.send_by === 'Clinic' ? 
                                                <Box 
                                                    id="sender" 
                                                    my={2} 
                                                    display={'flex'}
                                                >
                                                    <Box flexGrow={1} />
                                                    <Box  
                                                        width={'60%'}
                                                        border={1}
                                                        borderColor={blue[300]}
                                                        bgcolor={blue[100]}
                                                        p={2}
                                                        borderRadius={4}  
                                                        style={{ wordBreak: 'break-word' }}
                                                    > 
                                                        <Typography 
                                                            onClick={() => { navigator.clipboard.writeText(data.message) }}
                                                            variant="subtitle2" color="inherit"
                                                        >  
                                                            { data.message } 
                                                        </Typography> 
                                                    </Box> 
                                                </Box>  
                                            :
                                                <Box 
                                                    id="receiver" 
                                                    my={2} 
                                                    width={'60%'}
                                                    border={1}
                                                    borderColor={grey[300]}
                                                    bgcolor={grey[100]}
                                                    p={2}
                                                    borderRadius={4}  
                                                    style={{ wordBreak: 'break-word' }}
                                                > 
                                                    <Typography 
                                                        onClick={() => { navigator.clipboard.writeText(data.message) }}
                                                        variant="subtitle2" color="inherit"
                                                    > 
                                                        { data.message } 
                                                    </Typography> 
                                                </Box>  
                                        } 
                                    </Fragment>
                                ))
                            : Notify.noRecord()
                        :Notify.loading()
                    } 
                </PerfectScrollbar>
            </Box> 
        </Box>
    )
}

export default RequestMessages;