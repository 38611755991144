import React, { Component, Fragment } from 'react';  
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import AppointmentDetails from './AppointmentDetails';
import AppointmentAction from './AppointmentAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faEnvelope, faAddressBook, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; 
import AppointmentReport from './AppointmentReport';
import AppointmentApprovedDetailsMsgForm from './AppointmentApprovedDetailsMsgForm';
import { Dialog, Zoom, DialogTitle } from '@material-ui/core';
import AppointmentActionReschedule from './AppointmentActionReschedule';
import AppointmentActionContact from './AppointmentActionContact';

export default class AppointmentRequest extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _request_app: [],
            _request_app_ready: false,

            _selected_viewid: null,
            _selected_viewdialog: false,

            _appactionid: null,
            _appaction: null,
            _appactiondialog: false,
            _reference_no: null,

            _messagedialog: false,
            _appidmsg: null,
            _appmsgepatient: null,

            _reshedappdialog: false,
            _contactinfodialog: false,
            _appactiondate: null,
		}
	} 

    getRequestapp(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);     
        formdata.set('connection', this.props.connection);    
        
        Axios.post('appointment/doctors/request-appointment-list', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _request_app: data,
                    _request_app_ready: true,
                })
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }
    
    componentDidMount(){
        this._mounted = true;
        this.getRequestapp();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    handleAppDetails = (e) =>{
        this.setState({
            _selected_viewid: e.currentTarget.getAttribute('appid'),
            _selected_viewdialog: true
        })
    }

    handleAppAction = (e) =>{
        this.setState({
            _appactionid: e.currentTarget.getAttribute('appid'),
            _appaction: e.currentTarget.getAttribute('appaction'),
            _reference_no: e.currentTarget.getAttribute('reference_no'),
            _appactiondialog: true,
        })
    }

    closeDailog = () =>{
        this.setState({
            _selected_viewdialog : false,
            _selected_viewid: null,
            _appactionid: null,
            _appaction: null,
            _appactiondialog: false,
        })
    } 

    handleAppMessagePatient = e =>{
        this.setState({
            _messagedialog: true,
            _appidmsg: e.currentTarget.getAttribute('appid'),
            _appmsgepatient: e.currentTarget.getAttribute('patient_id'),
        })
    }

    handleAppReschedule = (e) => {
        this.setState({
            _appactionid: e.currentTarget.getAttribute('appid'),
            _appactiondate: e.currentTarget.getAttribute('appdate'),
            _reshedappdialog : true
        })
    }

    handleContactInfo = (e) =>{
        this.setState({
            _appmsgepatient: e.currentTarget.getAttribute('patient_id'),
            _contactinfodialog: true
        })
    }

	render(){ 
		return(
			<Fragment>
                {
                    this.state._request_app_ready ?
                        this.state._request_app.length > 0 ?
                            this.state._request_app.map((data, index) =>{
                                return (
                                    <Alert key={index} variant="mb-2 bg-white border">
                                        <p className="text-justify"> 
                                            <b className="text-capitalize"> {data.patient_name} </b> 
                                            is requesting for a <b> {data.appointment_type} </b> on  <b> { data.is_reschedule ? Notify.dateTimeConvert(data.is_reschedule_date)  : Notify.dateTimeConvert(data.appointment_date)  } </b>  .
                                            Would you like to have an appointment with this person? 
                                        </p> 
                                        <ButtonGroup>
                                            <Button
                                                variant="success"
                                                appid = {data.appointment_id}
                                                appaction = "approved"
                                                reference_no = {data.reference_no}
                                                onClick={this.handleAppAction}
                                            > <FontAwesomeIcon icon={faThumbsUp} /> Approve </Button>
                                            {/* <Button
                                                appid = {data.appointment_id}
                                                appaction = "disapproved"
                                                onClick={this.handleAppAction}
                                                variant="danger ml-2"
                                            > <FontAwesomeIcon icon={faThumbsDown} /> Disapprove </Button> */}
                                            {/* <Button 
                                                appid = {data.appointment_id} 
                                                appdate = {data.is_reschedule ? data.is_reschedule_date : data.appointment_date} 
                                                variant="primary ml-2 text-white" 
                                                onClick={this.handleAppReschedule}
                                            > <FontAwesomeIcon icon={faCalendarDay} /> Reschedule </Button> */}
                                            <Button 
                                                appid = {data.appointment_id} 
                                                patient_id = { data.patientId }
                                                variant="info ml-2" 
                                                onClick={this.handleAppMessagePatient}
                                            > <FontAwesomeIcon icon={faEnvelope} />  Message </Button>
                                            <Button 
                                                appid = {data.appointment_id} 
                                                variant="info ml-2" 
                                                onClick={this.handleAppDetails}
                                            > <FontAwesomeIcon icon={faInfoCircle} /> Details </Button>

                                            <Button 
                                                appid = {data.appointment_id} 
                                                patient_id = { data.patient_id } 
                                                variant="info ml-2" 
                                                onClick={this.handleContactInfo}
                                            > <FontAwesomeIcon icon={faAddressBook} /> Contact </Button>
                                            
                                        </ButtonGroup>
                                    </Alert>
                                )
                            })
                        : null // no appointment for apporval found.
                    : Notify.loading()
                }

                <AppointmentReport connection = { this.props.connection } /> 

                {/* app action dailog */}
                <Dialog 
                    open={this.state._selected_viewdialog}
                    TransitionComponent={Zoom}
                    transitionDuration={600} 
                    onClose={ this.closeDailog }  
                > 
                    <DialogTitle>
                        Appointment Details
                    </DialogTitle>
                    <AppointmentDetails
                        appid = {this.state._selected_viewid}
                        close={ this.closeDailog }  
                        connection = { this.props.connection }
                    />
                </Dialog> 
                
                {/* view details dailog */}
                <Dialog 
                    open={this.state._appactiondialog}
                    TransitionComponent={Zoom}
                    transitionDuration={600} 
                    onClose={ this.closeDailog }  
                > 
                    <DialogTitle>
                        Appointment { this.state._appaction }
                    </DialogTitle>
                    <AppointmentAction
                        appid = {this.state._appactionid}
                        reference_no = {this.state._reference_no}
                        appaction = {this.state._appaction}
                        getRequestapp = { this.getRequestapp.bind(this) }
                        getApproveAppointment = {this.props.getApproveAppointment}
                        closeDialog = { this.closeDailog }
                        connection = { this.props.connection }
                    />
                </Dialog> 

                {/* send notification message dialog */}  
                <Dialog 
                    open={this.state._messagedialog}
                    TransitionComponent={Zoom}
                    transitionDuration={600} 
                    onClose={ () => this.setState({ _messagedialog : false }) }  
                > 
                    <DialogTitle >
                        Message
                    </DialogTitle>
                    <AppointmentApprovedDetailsMsgForm
                        patient_id = {this.state._appmsgepatient}
                        appid = {this.state._appidmsg}
                        closeDialog = { () => this.setState({ _messagedialog : false })}
                        connection = { this.props.connection } 
                    />
                </Dialog>
			
                {/* send notification message dialog */}  
                <Dialog 
                    open={this.state._reshedappdialog}
                    TransitionComponent={Zoom}
                    transitionDuration={600} 
                    onClose={ () => this.setState({ _reshedappdialog : false }) }  
                > 
                    <DialogTitle>
                        Reschedule Appointment
                    </DialogTitle> 
                    <AppointmentActionReschedule 
                        appid = {this.state._appactionid}
                        appdate = { this.state._appactiondate }
                        closeDialog = { () => this.setState({ _reshedappdialog : false })}
                        getRequestapp = { this.getRequestapp.bind(this) }
                        connection = { this.props.connection } 
                    /> 
                </Dialog>

                {/* contactinfo dialog */}
                <Dialog 
                    open={this.state._contactinfodialog}
                    TransitionComponent={Zoom}
                    transitionDuration={600} 
                    onClose={ () => this.setState({ _contactinfodialog : false }) }  
                > 
                    <DialogTitle>
                        Contact Information
                    </DialogTitle>
                    <AppointmentActionContact
                        patient_id = { this.state._appmsgepatient }
                        close={ () => this.setState({ _contactinfodialog : false }) }  
                        connection = { this.props.connection } 
                    />
                </Dialog>
            </Fragment>
		)
	}
}
