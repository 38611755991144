import React, { useState, useCallback, useEffect } from 'react'
import { Box, Paper, Typography,TableContainer ,Table, TableHead, TableBody, TableRow, TableCell, Tooltip, IconButton, Button, TableFooter, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, TextField } from '@material-ui/core'; 
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'; 
import axios from 'axios'
import Notify from 'src/notification/Notify'
import Axios from 'axios';
import { useHistory } from 'react-router-dom';

const BillingDetails = ({ details, getUnpaidOrder }) =>{
    const token = localStorage.getItem('token') 
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')
    const management_id = localStorage.getItem('management_id')
    const history = useHistory();

    const [billingDetails, setBillingDetails] = useState({
        data: [],
        ready: false
    }) 
 
    const [ cancelOrder, setCancelOrder] = useState({
        data: null,
        dialog: false
    })

    const [isprocessCancel, setIsProcessCancel] = useState(false) 
    const [isprocessPaid, setIsProcessPaid] = useState(false) 


    const [paidDialog, setPaidDialog] = useState({
        data: null,
        open: false,
    })

    let totalorder = 0;

    const getBillingDetails = useCallback( () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);         
        formdata.set('patient_id', details.patient_id);         
        formdata.set('order_id', details.order_id);         
        
        axios.post('encoder/pateint/billing/details', formdata )
        .then( (response) => { 
            const data = response.data;     
            setBillingDetails({
                data: data,
                ready: true
            })   
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, user_id, details]) 

    useEffect(() =>{

        getBillingDetails()

    },[getBillingDetails])

    const calculateOrder = (amount) =>{
        totalorder += parseFloat(amount);
        return Notify.convertToNumber(amount);
    }
 

    const handleCancelOrder = (e) =>{
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);  
        var error = [];
        formdata.set('token', token);     
        formdata.set('patient_id', details.patient_id);      
        formdata.set('username', username);        

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0){
            Notify.consoleLog('form error.')
        }else{
            setIsProcessCancel(true)
            Axios.post('encoder/billing/cancel-bill', formdata )
            .then((response) =>{ 
                const data = response.data;    

                if(data==='pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data==='success'){
                    e.target.reset();  
                    setCancelOrder({ data: null, dialog: false  })
                    getBillingDetails()
                    Notify.successRequest('billing cancel')
                }
                if(data==='db-error'){
                    Notify.warnRequest('billing cancel')
                } 
            }).catch(error=>{  
                Notify.requestError(error);
            }).finally(() => setIsProcessCancel(false ))
        }
    } 

    const handleSetAsPaid = (e) =>{
        e.preventDefault();
        e.persist();

        var receipt_number = `receipt-${Math.floor(100000 + Math.random() * new Date().getTime())}`;
        var formdata = new FormData(e.target);  
        var error = [];
        formdata.set('token', token); 
        formdata.set('user_id', user_id);     
        formdata.set('patient_id', details.patient_id);      
        formdata.set('username', username);        
        formdata.set('receipt_number', receipt_number)
        formdata.set('management_id', management_id) 

        if(formdata.get('amountto_pay').length === 0 || formdata.get('amountto_pay').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('amountto_pay'); 
        }

        if(formdata.get('payment').length === 0 || formdata.get('payment').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('payment'); 
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0){
            Notify.consoleLog('form error.')
        }else{
            setIsProcessPaid(true)
            Axios.post('encoder/billing/setaspaid-bill', formdata )
            .then((response) =>{ 
                const data = response.data;    

                if(data==='pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data==='success'){
                    e.target.reset();  
                    setPaidDialog({ data: null, open: false  })
                    getBillingDetails()
                    getUnpaidOrder()
                    Notify.successRequest('billing cancel')
                    history.push(`/app/encoder/billing/receipt/${receipt_number}`)
                }
                if(data==='db-error'){
                    Notify.warnRequest('billing cancel')
                } 
            }).catch(error=>{  
                Notify.requestError(error);
            }).finally(() => setIsProcessPaid(false ))
        }
    }

    return(
        <Box
            component={Paper}
            variant="outlined"
            p={2}
        >
            <Box mb={2}>
                <Typography 
                    color="textPrimary" 
                    variant="subtitle2" 
                    className={`gtc-uppercase`}
                >
                    <b> {`${details.fname} ${details.lname}`} Bills </b>
                </Typography>
            </Box>
            <Box> 
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> Order </TableCell>
                                <TableCell> Order Rate </TableCell> 
                                <TableCell> Action </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody> 
                            {
                                billingDetails.ready ? 
                                    billingDetails.data.length > 0 ?
                                        billingDetails.data.map((data, index) =>(
                                            <TableRow
                                                key={index}
                                            >
                                                <TableCell> { data.bill_name } </TableCell>
                                                <TableCell align="right"> 
                                                    { calculateOrder(data.bill_amount) } 
                                                </TableCell> 
                                                <TableCell align="center"> 
                                                    <Tooltip arrow title="Cancel Order">
                                                        <IconButton 
                                                            color="secondary"  
                                                            hidden={ data.bill_from === 'appointment'} 
                                                            onClick={
                                                                ()=> setCancelOrder({ data: data, dialog: true })
                                                            }
                                                        >
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    </Tooltip> 
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    : 
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <Typography color="secondary">
                                                No unpaid order.
                                            </Typography>
                                        </TableCell> 
                                    </TableRow>
                                : 
                                <TableRow>
                                    <TableCell colSpan={4}> 
                                        <Typography color="primary">
                                            please wait... 
                                        </Typography>
                                    </TableCell> 
                                </TableRow>
                            }
                        </TableBody> 

                        <TableFooter>
                            <TableRow>
                                <TableCell /> 
                                <TableCell colSpan={2} align={'center'}> 
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={ () => setPaidDialog({ data: totalorder, open: true })}
                                    >
                                        SET AS PAID ( PHP { totalorder } )
                                    </Button>
                                </TableCell>  
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>  
                            
            {/* cancel order dialog */} 
                <Dialog
                    open={ cancelOrder.dialog }
                    onClose = { () => setCancelOrder({ data: null, dialog: false  }) }
                    disableBackdropClick
                    disableEscapeKeyDown
                >
                    {
                        cancelOrder.data && cancelOrder.dialog &&(
                            <form onSubmit={ handleCancelOrder }> 
                                <DialogTitle>
                                    Verify cancel
                                </DialogTitle>
                                <DialogContent dividers>
                                    <Box mb={2}>
                                        <TextField
                                            label={'Order Id'}
                                            variant="outlined"
                                            name="cancel_id" 
                                            defaultValue = { cancelOrder.data.epb_id }
                                            InputProps={{
                                                readOnly: true
                                            }}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            label={'Enter password'}
                                            variant="outlined"
                                            name="password"
                                            type="password"
                                            fullWidth
                                        />
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        onClick = { () => setCancelOrder({ data: null, dialog: false  }) } 
                                    >
                                        No
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={ isprocessCancel }
                                        startIcon={
                                            isprocessCancel && ( <CircularProgress size={20} color="inherit" /> )
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </form>
                        )
                    }
                </Dialog>
        
            {/* setas paid bills dialog */}

            <Dialog
                open={ paidDialog.open }
                onClose={ () => setPaidDialog({ data: null, open: false })}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <form
                    onSubmit={ handleSetAsPaid }
                >
                    <DialogTitle>
                        Set as paid Bills
                    </DialogTitle>

                    <DialogContent dividers> 

                        <Box mb={2} className={`gtc-textfield-noborder`}>
                            <TextField 
                                required
                                fullWidth
                                label="Amount to pay"
                                variant="outlined"
                                defaultValue={ paidDialog.data } 
                                InputProps={{
                                    readOnly: true
                                }}
                                name="amountto_pay"
                            />
                        </Box>

                        <Box mb={2}>
                            <TextField 
                                required
                                fullWidth
                                label="Payment"
                                variant="outlined" 
                                name="payment"
                                type="number"
                                InputProps={{
                                    inputProps:{
                                        min: paidDialog.data && parseFloat(paidDialog.data),
                                        step: 0.01
                                    }
                                }}
                            />
                        </Box>

                        <Box>
                            <TextField 
                                required
                                fullWidth
                                label="Enter password"
                                variant="outlined"
                                type="password"
                                name="password"
                            />
                        </Box>

                    </DialogContent>

                    <DialogActions>
                        <Button 
                            variant="contained"
                            color="default"
                            onClick={ () => setPaidDialog({ data: null, open: false })}
                        >
                            No
                        </Button>

                        <Button 
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={ isprocessPaid }
                            startIcon={ isprocessPaid && <CircularProgress size={20} color="inherit" /> }
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    )
}

export default BillingDetails;