import { TableHead, Table, TableCell, TableRow, TableBody, TableContainer, Typography, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Notify from 'src/notification/Notify';
import axios from 'axios'; 
import Label from 'src/utils/Label';
import SorologyRef from 'src/laboratory/newlaboratory/references/Ref_Sorology';
import { grey } from '@material-ui/core/colors';

const SerologyOrder = ({ order_id , patient_id }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [details, setDetails] = useState(null)

    const getpaidLaboratoryOrderDetails = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id); 
        formdata.set('patient_id', patient_id);    
        formdata.set('order_id', order_id);     
        formdata.set('table', 'laboratory_sorology');     
        formdata.set('connection', 'local');
        
        axios.post('doctor/patient/laboratory/order/paid-detailsbytable', formdata )
        .then((res) =>{
            const data = res.data
            setDetails(data)
        }).catch((er) => Notify.requestError(er))
    }
    
    const checkResult = (order) => {
        return  order === 'new-order' ? 
                <Label color="error"> no result </Label> 
            :   order === 'refund' ? 
                <Label color="warning"> {order} </Label>
            :   <Label color="success"> {order} </Label>
    }

    useEffect(() =>{

        getpaidLaboratoryOrderDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order_id, patient_id]) 

    return(
        <>  
            { details && Object.keys(details).length > 0 && (
                <Box border={1} borderColor={grey[300]} borderRadius={4} my={2} p={2}>
                    <Box>
                        <Label color="primary"> SEROLOGY ORDER   </Label>
                    </Box>
                    
                    <TableContainer> 
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"> <b> Examination Requested </b> </TableCell>
                                    <TableCell align="center"> <b> Normal </b> </TableCell>
                                    <TableCell align="center"> <b> Result </b> </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>   
                                { details.hbsag && 
                                    <TableRow>
                                        <TableCell> 
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                HEPATITIS B SURFACE ANTIGEN (HBSAG)
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">  
                                            { SorologyRef.normal() }
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { checkResult(details.hbsag) }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                } 
                                { details.hav && 
                                    <TableRow>
                                        <TableCell> 
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                HAV (HEPATITIS A VIRUS) LGG/LGM
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">  
                                            { SorologyRef.normal() }
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { checkResult(details.hav) }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                } 
                                { details.hcv && 
                                    <TableRow>
                                        <TableCell> 
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                HCV (HEPATITIS C VIRUS)
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">  
                                            { SorologyRef.normal() }
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { checkResult(details.hcv) }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                } 
                                { details.vdrl_rpr && 
                                    <TableRow>
                                        <TableCell> 
                                            <Typography variant="caption" className={`gtc-uppercase`}>
                                                VDRL/RPR
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">  
                                            { SorologyRef.normal() }
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { checkResult(details.vdrl_rpr) }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </>
    )
}

export default SerologyOrder;