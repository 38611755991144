import { Box, Grid, List, Paper, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Badge, Drawer, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Notify from 'src/notification/Notify';
import OnlineAppointmentDetails from './OnlineAppointmentDetails';
import Report from './Report';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';  
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Notifications from './notification/Notifications';
import Axios from 'axios';

const OnlineAppointment = ({ appointment, getOnlineAppointment }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [selectedApp, setSelectedApp ]  = useState(null)
    const [notifDrawer, setNotifDrawer ]  = useState(false)
    const [unreadNotifCount, setUnreadNotfiCount] = useState(0)

    const getUnreadCountnotif  = () =>{
        var formdata = new FormData();
        formdata.set('token', token); 
        formdata.set('user_id', user_id);      
        formdata.set('connection', 'online');         
        
        Axios.post('encoder/appointment/online/notification-unreadcount', formdata )
        .then( (response) =>{ 
            const data = response.data;     
            setUnreadNotfiCount(data.length) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    useEffect(() => {

        getUnreadCountnotif()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return(
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={3}>
                <Box
                    component={Paper}
                    variant="outlined" 
                    p={2}
                    mb={2}
                    display="flex"
                >
                    <Box flexGrow={1} mt={ 1 }>
                        <Typography>
                            <Badge badgeContent={unreadNotifCount} color="error">
                                Notification
                            </Badge> 
                        </Typography>
                    </Box>

                   <Box>
                    <IconButton 
                        onClick={ () => setNotifDrawer(true) }
                        color="primary"
                    >
                        <OpenInNewIcon />
                    </IconButton> 
                   </Box>
                </Box>
                
                <Box
                    component={Paper}
                    variant="outlined" 
                    px={1}
                >
                    <Box ml={2} mt={2}>
                        <Typography variant="subtitle2" color="primary" className="gtc-uppercase">
                            <Badge badgeContent={ appointment.data.length } color="error">
                                Appointment Online
                            </Badge>
                        </Typography>
                    </Box>

                    <List component="div">
                        {
                            appointment.ready ?
                                appointment.data.length > 0 ?
                                    appointment.data.map((data, index) =>(
                                        <ListItem 
                                            button
                                            key={ index }
                                            selected={ selectedApp && selectedApp.id === data.id }
                                            onClick={ () => setSelectedApp(data) }
                                        >
                                            <ListItemAvatar>
                                                <Avatar>
                                                    { index +1 }
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                className={`gtc-capitalize`}
                                                primary={`${ data.fname } ${ data.lname }`}
                                                secondary={ Notify.dateTimeConvert(data.appointment_dateonline) }
                                            />
                                        </ListItem>
                                    ))
                                : Notify.noRecord()
                            : Notify.loading()
                        } 
                    </List>
                </Box>
            </Grid>


            <Grid item xs={12} sm={8} lg={9}>
                { ! selectedApp && (
                    <Report />
                )}

                { selectedApp && (
                    <OnlineAppointmentDetails app ={ selectedApp } getOnlineAppointment ={ getOnlineAppointment } />
                )}
            </Grid>

            {/* drawer */}
            <Drawer open={ notifDrawer } onClose={ () => setNotifDrawer(false)} anchor="right">
                <Box m={2} width={ window.innerWidth > 600 ? 600 : window.innerWidth }>
                    <Box mb={2}>
                        <IconButton color="secondary" onClick={ () => setNotifDrawer(false)}> <HighlightOffIcon /> </IconButton>
                        <span className="text-muted text-uppercase header-label h6"> Notifications List </span>  
                    </Box>
                    
                    <Notifications />
                </Box> 
            </Drawer>
        </Grid>
    )
}

export default OnlineAppointment;