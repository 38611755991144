
import { Box, TableBody,Table , TableHead, TableCell, TableContainer, TextField, TableRow, Button, Typography, Paper, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import Axios from 'axios';
import React, { useState } from 'react'
import Notify from 'src/notification/Notify';

const LaboratoryTestList = ({ list, getLabTest }) =>{

    const username = localStorage.getItem('username')
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [edit, setEdit] = useState({
        data: null,
        open: false
    })

    const handleEditTest = (e) =>{
        e.preventDefault()
        e.persist()
 
        var formdata = new FormData(e.target) 
            formdata.append('token', token)
            formdata.append('user_id' , user_id) 
            formdata.append('username' , username) 

        var error = [];

        if(formdata.get('test').trim() === '' || formdata.get('test').length === 0){
            error = 'error'
            Notify.fieldRequired('test')
        }

        if(formdata.get('rate').trim() === '' || formdata.get('rate').length === 0){
            error = 'error'
            Notify.fieldRequired('test')
        }

        if(formdata.get('password').trim() === '' || formdata.get('password').length === 0){
            error = 'error'
            Notify.fieldRequired('password')
        }
        
        if(error.length > 0){
            console.log('form has an error')
        }else{
            setIsSubmitting(true)
            Axios.post('laboratory/test/edit-test', formdata)
            .then((response) =>{
                const data = response.data;
                if(data === 'pass-invalid'){  
                    Notify.fieldInvalid('password')
                } 
                if(data === 'success'){
                    Notify.successRequest('new doctor')  
                    getLabTest()
                    setEdit({ data: null , open : true })
                }
            }).catch((error) =>{
                Notify.requestError(error) 
            }).finally(() => setIsSubmitting(false))
        }
    }

    return(
        <Box component={Paper} variant="outlined" p={2}>
            <Box>
                <Typography
                    className={`gtc-uppercase`}
                    variant="subtitle2"
                >
                    Laboratory Test Available
                </Typography>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell> <strong> Test </strong> </TableCell>
                            <TableCell> <strong> Department </strong> </TableCell>
                            <TableCell> <strong> Rate </strong> </TableCell> 
                            <TableCell> <strong> Action </strong> </TableCell>  
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            list.ready ?
                                list.data.length > 0 ?
                                    list.data.map((data, index) =>(
                                        <TableRow
                                            key={index}
                                            hover
                                        >
                                            <TableCell> { data.laboratory_test } </TableCell>
                                            <TableCell align="right"> { data.department } </TableCell> 
                                            <TableCell align="right"> { data.laboratory_rate } </TableCell> 
                                            <TableCell align="center"> 
                                                <Tooltip arrow title="Edit Laboratory Test">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={()=>
                                                            setEdit({
                                                                data: data,
                                                                open: true
                                                            })
                                                        }
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>  
                                        </TableRow>
                                    ))
                                : <TableRow> 
                                    <TableCell colSpan={4}>  No laboratory test added. </TableCell> 
                                </TableRow>
                            : 
                            <TableRow> 
                                <TableCell colSpan={4}>  please wait... </TableCell> 
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {/* edit dialog */}
            { edit.data && edit.open && (
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={ edit.open }
                    onClose = { () => setEdit({ data: null , open : true })}
                >
                    <DialogTitle> Edit Laboratory Test </DialogTitle>
                    <form onSubmit={ handleEditTest }>
                        <DialogContent dividers>
                            <Box my={2}>
                                <TextField   
                                    fullWidth
                                    required
                                    name="id"
                                    label="Laboratory Test"
                                    variant="outlined" 
                                    hidden
                                    defaultValue={ edit.data.lt_id }
                                />
                                <TextField   
                                    fullWidth
                                    required
                                    name="test"
                                    label="Laboratory Test"
                                    variant="outlined" 
                                    disabled
                                    defaultValue={ edit.data.laboratory_test}
                                />
                            </Box> 

                            <Box my={2}>
                                <TextField   
                                    fullWidth
                                    required
                                    name="test"
                                    label="Department"
                                    variant="outlined" 
                                    disabled
                                    defaultValue={ edit.data.department}
                                />
                            </Box>

                            <Box mb={2}>
                                <TextField  
                                    fullWidth
                                    required
                                    name="rate"
                                    label="Laboratory Rate"
                                    variant="outlined" 
                                    type="number"
                                    defaultValue={ edit.data.laboratory_rate}
                                    InputProps={{
                                        inputProps:{
                                            min: 1,
                                            max: 10000,
                                            step: 0.01
                                        }
                                    }}
                                />
                            </Box> 

                            <Box mb={2}>
                                <TextField 
                                    fullWidth
                                    required 
                                    name="password"
                                    label="Enter your password"
                                    variant="outlined" 
                                    type="password"
                                />
                            </Box> 
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="default"
                                onClick = { () => setEdit({ data: null , open : true })}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={ isSubmitting }
                                startIcon={ isSubmitting && <CircularProgress size={20} color="inherit" /> }
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )} 
        </Box>
    )
}

export default LaboratoryTestList;