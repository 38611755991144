import React, { Component, Fragment } from 'react';  
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import Button from 'react-bootstrap/Button';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
 
export default class AppointmentSetDone extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
            _appid: this.props.appid,
            _patient_id: this.props.patient_id,
            _consumed: this.props.consumed_time,
		}
	} 

    componentDidMount(){
        this._mounted = true;
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    appointmentSetDone = (e) =>{
        e.preventDefault();
        e.persist();
        var formdata = new FormData(e.target);  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);    
        formdata.set('username', this.state._username);    
        formdata.set('appointment_id', this.props.appid);  
        formdata.set('reference_no', this.props.reference_no);  
        formdata.set('connection', 'online')

        var error = [];
        if(formdata.get('consumed_time').length === 0 || formdata.get('consumed_time').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('consumed time'); 
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0){
            Notify.consoleLog('appointment done')
        }else{
            this.setState({
                _is_process: true
            })

            Axios.post('appointment/doctors/appointment-done', formdata )
            .then(function(response){ 
                const data = response.data;   
                if(this._mounted){ 
                    this.setState({
                        _is_process: false
                    })
                    if(data ==='pass-inv'){
                        Notify.fieldInvalid('password')
                    }
                    if(data ==='success'){
                        this.props.closeDialog();
                        Notify.successRequest('appointment done')
                        setTimeout(() => {
                            window.location.href="/doctor"
                        }, 3000);
                    }
                    if(data ==='db-error'){
                        Notify.warnRequest('appointment done')
                    }
                } 
            }.bind(this)).catch(error=>{ 
                this.setState({
                    _is_process: false
                })
                Notify.requestError(error);
            });
        }   
    }
    
    componentDidUpdate(){
        if(this.state._appid !== this.props.appid){
            this.setState({
                _appid: this.props.appid
            })
        }
    }
    
	render(){ 
		return(
			<Fragment>
                <Form onSubmit={this.appointmentSetDone}>
                    <FormGroup>
                        <span className="text-muted text-uppercase gtc-small"> Comsumed time</span>
                        <FormControl name="consumed_time" 
                            value={this.props.consumed_time} 
                            readOnly = {this.props.consumed_time !== '' ? true : false} 
                        />
                    </FormGroup>

                    <FormGroup> 
                        <span className="text-muted text-uppercase gtc-small"> Appointment ID</span>
                        <FormControl name="appointment_id" value={this.props.appid} readOnly/>
                    </FormGroup>

                    <FormGroup> 
                        <span className="text-muted text-uppercase gtc-small"> Enter your password</span>
                        <FormControl type="password" name="password"/>
                    </FormGroup>

                    <p className="m-0 mb-2"> Are you sure to set this appointment done?</p>
                    <Button 
                        variant="success" 
                        disabled ={this.state._is_process ? true : false}
                        type="submit">
                        <FontAwesomeIcon icon={this.state._is_process ? faCircleNotch : faCheckCircle} spin ={this.state._is_process ? true : false} /> Save </Button>
                    <Button variant="secondary ml-2" onClick={()=> this.props.closeDialog()}> 
                        <FontAwesomeIcon icon={faTimesCircle} /> No </Button> 
                </Form>
			</Fragment>
		)
	}
}
