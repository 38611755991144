import React, { Component, Fragment } from 'react';  
import Notify from '../../notification/Notify';
import Axios from 'axios'; 
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'; 
import { Paper } from '@material-ui/core';
 
export default class PersonalInfo extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
            _patient_id: this.props.patient_id,
            _info: [],
            _info_ready: false,
        }
    } 
    
    getPatientInformation(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);    
        formdata.set('management_id', this.state._management_id);    
        formdata.set('patient_id', this.props.patient_id);    
        formdata.set('connection', this.props.connection);    
        
        Axios.post('doctor/patient/patient-information', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                if(data.length > 0){
                    this.setState({
                        _info: data,
                        _info_ready: true,
                    })
                }else{
                    this.setState({
                        _info: [],
                        _info_ready: true,
                    })
                }
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    componentDidMount(){
        this._mounted = true;
        this.getPatientInformation();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    componentDidUpdate(){
        if(this.state._patient_id !== this.props.patient_id){
            this.setState({
                _patient_id: this.props.patient_id,
                _info_ready: false,
            })
            this.componentDidMount();
        }
    }

	render(){ 
		return(
            <Fragment>
                <Box 
                    component={Paper}
                    variant="outlined"
                    p={2}  
                > 
                    <Typography variant="subtitle2" color="primary"> PERSONAL INFORMATION </Typography>
                    {
                        this.state._info_ready ? 
                            this.state._info.length > 0 ?
                                <Grid container>
                                    <Grid item xs={12} sm={12}> 
                                        <Box p={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <span className="text-muted gtc-small text-uppercase"> Occupation </span>
                                                    <p className="text-capitalize">
                                                        { this.state._info[0].occupation === null ? 'None' : this.state._info[0].occupation }
                                                    </p>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <span className="text-muted gtc-small text-uppercase"> Blood type </span>
                                                    <p className="text-capitalize">
                                                        { this.state._info[0].blood_type === null ? 'None' : this.state._info[0].blood_type}
                                                    </p>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid> 

                                    <Grid item xs={12} sm={12}>
                                        <Box p={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <span className="text-muted gtc-small text-uppercase"> Civil Status </span>
                                                    <p className="text-capitalize">
                                                        { this.state._info[0].civil_status === null ? 'None' : this.state._info[0].civil_status}
                                                    </p>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <span className="text-muted gtc-small text-uppercase"> Religion </span>
                                                    <p className="text-capitalize">
                                                        { this.state._info[0].religion === null ? 'None' : this.state._info[0].religion}
                                                    </p>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid> 

                                    <Grid item xs={12} sm={12}>
                                        <Box p={1}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <span className="text-muted gtc-small text-uppercase"> Mobile </span>
                                                    <p className="text-capitalize">
                                                        { this.state._info[0].mobile  === null ? 'None' : this.state._info[0].mobile}
                                                    </p>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <span className="text-muted gtc-small text-uppercase"> Telephone </span>
                                                    <p className="text-capitalize">
                                                        { this.state._info[0].telephone === null ? 'None' : this.state._info[0].telephone }
                                                    </p>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>  

                                    <Grid item xs={12} sm={12}>
                                        <Box p={1}>
                                            <span className="text-muted gtc-small text-uppercase"> Address </span>
                                            <p className="text-capitalize">
                                                { this.state._info[0].street }, { this.state._info[0].barangay }, { this.state._info[0].city } { this.state._info[0].zip }
                                            </p>
                                        </Box>
                                    </Grid> 
                                </Grid>
                            :Notify.noRecord()
                        :Notify.loading()
                    } 
                </Box>
            </Fragment>
        )
	}
}
