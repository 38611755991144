import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination, InputAdornment, Divider, Paper, Dialog, DialogContent, Zoom } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print';
import ReceipPrintModal from './ReceiptModal/ReceipPrintModal';

function Receipt() {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [receipt, setReceipt] = useState([]);
    const [receiptReady, setReceiptReady] = useState(false);
    const [search, setSearch] = useState('')
    const [openModal, setOpenModal] = useState(false);
    const [selectedReceipt, setSelectedReceipt] = useState(null);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchReceiptList = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);

        Axios.post('malita/pharmacy/get-receipt-list', formdata)
            .then((response) => {
                const data = response.data;
                setReceipt(data);
                setReceiptReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, management_id])

    useEffect(() => {
        fetchReceiptList()
    }, [fetchReceiptList])

    const searchable = receipt.filter((data) => {
        return data.name_customer.toLowerCase().indexOf(search.trim()) !== -1 || data.receipt_id.toLowerCase().indexOf(search.trim()) !== -1
    })

    return (
        <Fragment>
            <Box m={2}>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <Paper>
                            <Box>
                                <Box p={2} borderRadius={4}>
                                    <Box display="flex" justifyContent="center" >
                                        <Box flexGrow={1} >
                                            <Typography color="primary" >
                                                Receipt List
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box m={1}>
                                    <TableContainer component={Box} borderColor="grey.200" bgcolor="white">
                                        <Box display="flex">
                                            <Box flexGrow={1} />
                                            <Box mb={1}>
                                                <TextField
                                                    label="Search client or receipt"
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment>
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                        </Box>

                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center"> Client Name </TableCell>
                                                    <TableCell align="center"> Client Address </TableCell>
                                                    <TableCell align="center"> Receipt </TableCell>
                                                    <TableCell align="center"> Total Amount </TableCell>
                                                    <TableCell align="center"> Action </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    receiptReady ?
                                                        receipt.length > 0 ?
                                                            searchable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                .map((data, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell > {data.name_customer} </TableCell>
                                                                        <TableCell >{data.address_customer}</TableCell>
                                                                        <TableCell align="right">{data.receipt_id}</TableCell>
                                                                        <TableCell align="right"> {Notify.numberFormat(data.total_cost)} </TableCell>
                                                                        <TableCell align="center">
                                                                            <PrintIcon
                                                                                color={selectedReceipt === data.receipt_id ? 'secondary' : 'primary'}
                                                                                onClick={() => {
                                                                                    setSelectedReceipt(data.receipt_id);
                                                                                    setOpenModal(true)
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            : <TableRow>
                                                                <TableCell colSpan={7}>
                                                                    <Typography variant="subtitle2" color="secondary"> No receipts added </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        : <TableRow>
                                                            <TableCell colSpan={7}>
                                                                <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        </Table>

                                        <TablePagination
                                            component={"div"}
                                            rowsPerPageOptions={[5, 10, 25]}
                                            colSpan={3}
                                            count={receipt.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>
                                </Box>
                                <Dialog
                                    open={openModal}
                                    fullScreen
                                    disableBackdropClick
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    TransitionComponent={Zoom}
                                    transitionDuration={1000}
                                >
                                    <DialogContent>
                                        <ReceipPrintModal closeModal={() => setOpenModal(false)} selectedReceipt={selectedReceipt} />
                                    </DialogContent>
                                </Dialog>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    )

}

export default Receipt;