import React, { Fragment, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Axios from 'axios'
import Typography from '@material-ui/core/Typography'


function Generatehashstring() {

    const [generatedpass, setGeneratedPassword] = useState(null)

    const handleGeneratePassword = (e) => { 
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        Axios.post("welcome/generatehash-string", formdata)
        .then((response) =>{
            const data = response.data
            setGeneratedPassword(data)
        }).catch((error)=>{
            console.log(error)
        })
    }

    return (
        <Fragment>
            <Box m={20}>
                <Typography variant="h6" color="primary"> 
                    { generatedpass }
                </Typography>
                <form onSubmit={ handleGeneratePassword }> 
                    <span className="gtc-formlabel">
                        GENERATE PASSWORD
                    </span>
                    <TextField fullWidth name="generate_password" margin="dense" variant="outlined" />
                    <Button type="submit" variant="contained" color="primary">
                        Generate Password
                    </Button>
                </form>
            </Box>
        </Fragment>
    )
}

export default Generatehashstring ;
