import React, { useCallback, useEffect, useState } from 'react';  
import { Col } from 'react-bootstrap';
import PersonalHeader from './information/PersonalHeader';
import PatientsCategory from './PatientsCategory';
import Axios from 'axios';
import Notify from '../notification/Notify';  
import AppointmentRoom from './appointment/AppointmentRoom';    
import { OrderContext } from 'src/ContextAPI';  
import { useHistory } from 'react-router-dom';  
import SnackbarNextAppointment from './snackbar/SnackbarNextAppointment';
import SnackbarUnreadNotif from './snackbar/SnackbarUnreadNotif';
import SnackbarUnreadInquiries from './snackbar/SnackbarUnreadInquiries'; 
import SnackbarRequestPermission from './snackbar/SnackbarRequestPermission';

const Patient = () =>{
    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')
    const management_id = localStorage.getItem('management_id')  
    
    const [nextAppointment, setNextAppointment] = useState(null)
    const [notifUnread, setNotifUnread] = useState([])   
 

    const [cart, setCart] = useState([])
    const [ unreadInqReply, setUnreadInqReply] = useState([]) 

    const [ reqpermission , setReqPermission] = useState([])  

    const history = useHistory()

    var interval = null;

    const getNextAppointment = useCallback(() => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('management_id', management_id);   
        formdata.set('user_id', user_id);    
        
        Axios.post('appointment/patient/appointment-next', formdata )
        .then( (response) => { 
            const data = response.data;    
            if(data.length > 0){
                setNextAppointment(data[0].appointment_date) 
            } 
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, management_id, user_id])


    const getUnreadNotifMsg = useCallback(() => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('management_id', management_id);   
        formdata.set('user_id', user_id);    
        
        Axios.post('appointment/patient/notification-msg-unread', formdata )
        .then( (response) => { 
            const data = response.data;    
            setNotifUnread(data) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, management_id, user_id]) 

    const getNewInquieryReply = useCallback(() =>{
        var formdata = new FormData();  
        formdata.set('token', token);  
        formdata.set('user_id', user_id);    
        
        Axios.post('patient/clinic/inquiry/unread-msgresponse', formdata )
        .then( (response) => { 
            const data = response.data;   
            setUnreadInqReply(data)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, user_id])

    const getIncompleteCartOrder  = () =>{
        var formdata = new FormData();  
        formdata.set('token', token);  
        formdata.set('user_id', user_id);    
        formdata.set('connection', 'online');
        
        Axios.post('patient/card/order-incomplete', formdata )
        .then( (response) => { 
            const data = response.data;   
            setCart(data) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }  

    const getDoctorsPermissionRequest  = () =>{
        var formdata = new FormData();  
        formdata.set('token', token);  
        formdata.set('user_id', user_id);    
        
        Axios.post('patient/permission/getunapprove-permission', formdata )
        .then( (response) => { 
            const data = response.data;   
            setReqPermission(data) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const updateCart = (data) =>{
        setCart(data)
    }

    const initializeInterval = () =>{
        interval = setInterval(() => {
            getUnreadNotifMsg();   
            getNewInquieryReply();
            getIncompleteCartOrder()
            getDoctorsPermissionRequest()
        }, 10000);
    } 

    useEffect(() =>{
        getNextAppointment()
        getUnreadNotifMsg()
        getNewInquieryReply() 
        getIncompleteCartOrder()
        getDoctorsPermissionRequest()
        initializeInterval()

        return(() =>{
            clearInterval(interval)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[getNextAppointment, getUnreadNotifMsg, getNewInquieryReply])
 
    return( 
        <OrderContext.Provider
            value={{ 
                __cart: cart, 
                updateCart : updateCart 
            }}
        > 
            {/* next appointment */}  
            <SnackbarNextAppointment nextAppointment = { nextAppointment } close ={ () => setNextAppointment(null) } />      

            {/* unread notifications message */}
            <SnackbarUnreadNotif notifUnread ={ notifUnread } getUnreadNotifMsg ={ getUnreadNotifMsg } />
            
            {/* unread inquiery response */}
            <SnackbarUnreadInquiries unreadInqReply = { unreadInqReply } history = { history } />

            {/* cart update snackbar */}
            {/* <SnackbarCartUpdate cart = { cart } categoryContext={ categoryContext } /> */}

            {/* unapproved permission */}
            <SnackbarRequestPermission req_permission = {reqpermission} />

            <Col sm={12} className="mt-3">
                <AppointmentRoom />
            </Col>
            
            <Col sm={12} className="mt-3">
                <PersonalHeader />
            </Col>
            
            <Col sm={12} className="mb-5">
                <PatientsCategory />
            </Col>  
        </OrderContext.Provider>
    )
}

export default Patient; 