
import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react"; 
import Notify from "../../../notification/Notify";
import Box from "@material-ui/core/Box"; 
import GTCChart from "../../../GoogleChart/GTCChart";
 
const Glucose = ({ patient_id }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [chartData, setChartData] = useState([])  
    const [ready, setReady] = useState(false)

    const getGlucose = useCallback(() => {
        var formData = new FormData();
			formData.set('user_id', user_id);
			formData.set('token', token); 
			formData.set('patient_id', patient_id); 

        Axios.post('doctor/patient/vitals/graph/get-glucose',  formData)
		.then( (response) => { 
            const data = response.data;   
            pushtoChart(data)  
            setReady(true)
		})
		.catch(error => { 
			Notify.requestError(error);
		}) 
    }, [user_id, token, patient_id]);
    
    const pushtoChart = (data) =>{ 
        if(data.length > 0){
            var newData = [['x', 'Glucose']]
            for (let i = 0; i < data.length; i++) {
                newData.push([Notify.dateTimeConvert(data[i].created_at), parseFloat(data[i].glucose)])  
            }    
            setChartData(newData)
        }
        else{
            setChartData([['x', 'Glucose'], ['No Record', 0]])
        }
    }

    useEffect(() =>{
        getGlucose()
    }, [getGlucose]) 

    return ( 
        <Box 
            p={2}
        >
            {
                ready ? 
                    <GTCChart
                        chartData= { chartData }
                        chartLabel = {'Glucose'} 
                    /> 
                : Notify.loading()
            } 
        </Box>  
    );
};
export default Glucose;