import React, { useState, Fragment } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, TextField, FormHelperText, Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const PharmacyUsername = ({ renderAllFetch }) => {
    const [token] = useState(localStorage.getItem('token'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [username] = useState(localStorage.getItem('username'))

    const getFormData = (object) => {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                >
                    <Formik
                        initialValues={{
                            user_id: user_id,
                            token: token,
                            username: username,
                            management_id: management_id,
                            new_username: '',
                            password: '',
                        }}
                        validationSchema={Yup.object().shape({
                            new_username: Yup.string().required(),
                            password: Yup.string().required()
                        })}
                        onSubmit={async (values, {
                            setErrors,
                            setSubmitting,
                            resetForm
                        }) => {
                            try {
                                const request = await Axios.post('malita/pharmacy/update-username', getFormData(values))
                                if (request.data === 'pass-invalid') {
                                    setErrors({ password: "Password doesn't matched" });
                                    Notify.customToast("Invalid Password", "Password doesn't matched")
                                }
                                if (request.data === 'success') {
                                    Notify.successRequest('update username')
                                    resetForm();
                                    setSubmitting(true);
                                    renderAllFetch();
                                    localStorage.setItem('username', values.new_username)
                                }
                            } catch (error) {
                                const message = error.message || 'Something went wrong';
                                setErrors({ submit: message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            resetForm,
                            setFieldValue
                        }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <Box flexGrow={1}>
                                    <Grid container spacing={2}>
                                        <Grid xs={12} item>
                                            <Box mb={1}>
                                                <TextField
                                                    error={Boolean(touched.username && errors.username)}
                                                    helperText={touched.username && errors.username}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.username}
                                                    fullWidth
                                                    required
                                                    multiline
                                                    label="Current Username"
                                                    variant="outlined"
                                                    InputProps={{
                                                        readOnly: true
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Box mb={1}>
                                                <TextField
                                                    error={Boolean(touched.new_username && errors.new_username)}
                                                    helperText={touched.new_username && errors.new_username}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.new_username}
                                                    fullWidth
                                                    required
                                                    multiline
                                                    name="new_username"
                                                    label="New Username"
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid xs={12} item>
                                            <Box mb={1}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    error={Boolean(touched.password && errors.password)}
                                                    helperText={touched.password && errors.password}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    name="password"
                                                    label="Enter your password"
                                                    variant="outlined"
                                                    type="password"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {errors.submit && (
                                    <Box mt={2} >
                                        <FormHelperText error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}

                                <Box mt={2} mb={2} display="flex">
                                    <Box flexGrow={1} />

                                    <Button
                                        variant="contained"
                                        color="default"
                                        onClick={() => resetForm()}
                                        startIcon={<HighlightOffIcon />}
                                    >
                                        Reset
                                        </Button>

                                    <Box ml={2}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CheckCircleIcon />}
                                            disabled={isSubmitting}
                                        >
                                            Save
                                            </Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default PharmacyUsername;