
import React, { useState, useEffect, Fragment, useCallback } from 'react';   
import Axios from 'axios';
import Notify from '../../notification/Notify'; 
import { Box, Collapse, Typography, TablePagination, Paper } from '@material-ui/core'; 
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';


const PersonalDiagnosis = (props) => {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
 
    const [diagnosis, setdiagnosis] = useState([])
    const [diagnosisready, setdiagnosisready] = useState(false)

    const [selectedId, setSelectedId] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(5);    
    const [page, setPage] = useState(0);  

    const patient_id = props.patient[0].patient_id

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };   

    const getDiagnosis = useCallback(() =>{
        var formdata = new FormData(); 
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('patient_id', patient_id);

        Axios.post('doctor/patient/diagnosis/getdiagnosis-list', formdata )
        .then((response)=>{ 
            const data = response.data;  
            setdiagnosis(data)
            setdiagnosisready(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    },[token, user_id, patient_id] );
    

    useEffect(() =>{

        getDiagnosis()

    }, [getDiagnosis])

    return (
        <Fragment> 
            <Box
                p={2}
                component={Paper} 
                variant="outlined"
            >  
                    <Box>
                        <Typography variant="subtitle2" color="primary" className={`gtc-uppercase`}>
                            Diagnosis Monitoring
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                            Only your appointed doctor can add diagnosis.
                        </Typography>
                    </Box> 
                <Box> 
                    {
                        diagnosisready ? 
                            diagnosis.length > 0 ?
                                (rowsPerPage > 0
                                    ? diagnosis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : diagnosis
                                ).map((data, index) => ( 
                                    <Box 
                                        p={1} 
                                        key={index}
                                        borderRadius={4} 
                                    >
                                        <div
                                            className="clearfix pointer"
                                            onClick={ () => setSelectedId( selectedId === data.id ? null : data.id)}
                                        >
                                            <Typography 
                                                style={{ maxWidth: '90%'}} 
                                                noWrap
                                                className="float-left" 
                                            > 
                                                <strong> { data.diagnosis }  </strong>
                                                <br />
                                                <small className="text-muted"> { Notify.dateTimeConvert(data.created_at) } </small>
                                            </Typography>   
                                            <span className="float-right"> { selectedId === data.id ? <ArrowDropDown />  : <ArrowRightIcon /> } </span>
                                        </div>
                                        <Collapse in={selectedId === data.id ? true : false }> 
                                            <div dangerouslySetInnerHTML={{__html: data.remarks}} />
                                        </Collapse>  
                                    </Box>
                                ))
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </Box>
                <TablePagination
                    labelRowsPerPage = "List"
                    rowsPerPageOptions={[5, 20, 50, 100]}
                    component="div"
                    count={diagnosis.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Box> 
        </Fragment>
    )
}

export default PersonalDiagnosis; 