import React, { useContext, useEffect, useState } from 'react'  
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core' 
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/CheckCircle';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import { PatientCategoryContext } from 'src/ContextAPI';

const AddToCart = ({ open, close, type, cartData }) =>{ 

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [isprocess, setIsProcess] = useState(false)  

    const { claim_id, doctors_id, patient_id } = cartData.data;  

    const categoryContext = useContext(PatientCategoryContext)
    
    const handleAddToCart = () =>{

        var error = [];
        var formdata = new FormData();
            formdata.set('token', token)
            formdata.set('user_id', user_id) 
            formdata.set('claim_id', claim_id)  
            formdata.set('doctors_id', doctors_id)  
            formdata.set('patient_id', patient_id)  

        if(formdata.get('claim_id').length === 0 || formdata.get('claim_id').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('claim id'); 
        }

        if(formdata.get('doctors_id').length === 0 || formdata.get('doctors_id').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('doctors id'); 
        }

        if(formdata.get('patient_id').length === 0 || formdata.get('patient_id').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('patient id'); 
        }

        if(error.length > 0){
            console.warn("form has an errrrrrrrror.")
        }else{
            setIsProcess(true)
            Axios.post('patient/cart/addtocart-rx', formdata)
            .then((response) =>{
                const data = response.data
                if(data.message === 'success'){
                    close()  
                    categoryContext._updateCategory('cart')
                    Notify.customToast(`${data.count} item added to cart`, 'Rx successfully added to cart.')
                } 
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'rx-existed'){
                    Notify.customToast('Rx already added', 'Rx not process in the cart.')
                }
                if(data === 'db-error'){
                    Notify.warnRequest('diet')
                } 
            }).catch((err) =>{
                Notify.requestError(err)
            }).finally(() =>{
                setIsProcess(false)  
            })
        }

    } 

    useEffect(() =>{ },[cartData]) 

    return(
        <>
            <Dialog
                open={ open }
                onClose = { close }
                disableBackdropClick
                disableEscapeKeyDown   
            > 
                <DialogContent> 
                    <Typography>
                        Are you sure to add this <b> {type} </b> to cart?
                    </Typography>   
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="default"
                        onClick = { close }
                        startIcon = { <CancelIcon /> }
                    >
                        No
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled = { isprocess }
                        startIcon = { isprocess ? <CircularProgress size={15} /> : <CheckIcon /> }
                        onClick = { handleAddToCart }
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog> 
        </>
    )   
}

export default AddToCart;