import { TableContainer, Table, TableHead, TableCell, TableRow, Typography, Box, TableBody, CardContent } from '@material-ui/core';
import React from 'react'
import ChemistryRef from '../../../laboratory/newlaboratory/references/Ref_Chemistry';

const PatientChemOrder = ({ chemistryOrderDetails }) => {

    return (
        <>
            <CardContent>
                <Box>
                    <Typography variant="h6" align="center">
                        <b> CLINICAL CHEMISTRY REPORT </b>
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant="caption"> <b> SPICEMEN: </b> </Typography> <span className="gtc-uppercase"> {chemistryOrderDetails.data.spicemen} </span>
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"> <b> TEST REQUEST </b> </TableCell>
                                <TableCell align="center"> <b> RESULT </b> </TableCell>
                                <TableCell align="center"> <b> REFERENCE VALUES </b> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                chemistryOrderDetails.data.glucose &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            GLUCOSE
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {chemistryOrderDetails.data.glucose}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {ChemistryRef.glucose()}
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                chemistryOrderDetails.data.creatinine &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            CREATININE
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {chemistryOrderDetails.data.creatinine}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {ChemistryRef.creatinine()}
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                chemistryOrderDetails.data.uric_acid &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            URIC ACID
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {chemistryOrderDetails.data.uric_acid}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {ChemistryRef.uric()}
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                chemistryOrderDetails.data.cholesterol &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            CHOLESTEROL
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {chemistryOrderDetails.data.cholesterol}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {ChemistryRef.cholesterol()}
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                chemistryOrderDetails.data.triglyceride &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            TRIGLYCERIDE
                                                                </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {chemistryOrderDetails.data.triglyceride}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {ChemistryRef.triglyceride()}
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                chemistryOrderDetails.data.hdl_cholesterol &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            HDL CHOLESTEROL
                                                                </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {chemistryOrderDetails.data.hdl_cholesterol}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {ChemistryRef.hdlcholesterol()}
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                chemistryOrderDetails.data.ldl_cholesterol &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            LDL CHOLESTEROL
                                                                </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {chemistryOrderDetails.data.ldl_cholesterol}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {ChemistryRef.ldlcholesterol()}
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                chemistryOrderDetails.data.sgot &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            SGOT
                                                                </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {chemistryOrderDetails.data.sgot}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {ChemistryRef.sgot()}
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                chemistryOrderDetails.data.sgpt &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            SGPT
                                                                </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {chemistryOrderDetails.data.sgpt}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {ChemistryRef.sgpt()}
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </>
    )
}

export default PatientChemOrder;