 
import React from 'react';
export default class LaboratoryOrder extends React.Component{
    static list = () => {
        var orders = [
            { 
                label: "Hemoglobin", 
                value: "hemoglobin"
            },
            { 
                label: "Hematocrit", 
                value: "hematocrit"
            },
            { 
                label: "RBC", 
                value: "rbc"
            },
            { 
                label: "WBC", 
                value: "wbc"
            },
            { 
                label: "Platelet Count", 
                value: "platelet count"
            },
            { 
                label: "Differential Count", 
                value: "differential count"
            },
            { 
                label: "Neutrophil", 
                value: "neutrophil"
            },
            { 
                label: "Lymphocyte", 
                value: "lymphocyte"
            },
            { 
                label: "Monocyte", 
                value: "monocyte"
            },
            { 
                label: "Eosinophil", 
                value: "eosinophil"
            },
            { 
                label: "Hepatitis B surface Antigen (HBsAg)", 
                value: "Hepatitis B surface Antigen (HBsAg)"
            },
            { 
                label: "HAV (Hepatitis A Virus) IgG/IgM", 
                value: "HAV (Hepatitis A Virus) IgG/IgM"
            },
            { 
                label: "HCV (Hepatitis C Virus)", 
                value: "HCV (Hepatitis C Virus)"
            },
            { 
                label: "VDRL/RPR", 
                value: "VDRL/RPR"
            },
            { 
                label: "Chemical Test", 
                value: "chemical test"
            },
            { 
                label: "Microscopic Test", 
                value: "microscopic test"
            },
            { 
                label: "Pregnancy Test (HCG)", 
                value: "pregnancy test (HCG)"
            },
            { 
                label: "Fecal Analysis", 
                value: "fecal analysis"
            },
            { 
                label: "SGPT", 
                value: "SGPT"
            }, 
            { 
                label: "SGOT", 
                value: "SGOT"
            }
        ];

        return orders
    }

    static dept = () => {
        var orders = [
            { 
                label: "Hemathology", 
                value: "hemathology"
            },
            { 
                label: "Serology", 
                value: "serology"
            },
            { 
                label: "Clinical Microscopy", 
                value: "clinical-microscopy"
            },
            { 
                label: "Fecal Analysis", 
                value: "fecal-analysis"
            },
            { 
                label: "Clinical Chemistry", 
                value: "clinical-chemistry"
            }
        ];

        return orders
    }
}


