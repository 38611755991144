
import React from 'react'

const ActiveSubscription = () =>{

    return(
        <>
            this is active subscription
        </>
    )
}

export default ActiveSubscription;