
import React, { Fragment, useState, useEffect, useCallback } from 'react' 
import { Button, Dialog, DialogActions, DialogContent, Zoom, Box, Grid, Table, TableHead, TableRow, TableBody, TableCell, Typography, Divider } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import Print from '@material-ui/icons/Print';  
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrescription } from '@fortawesome/free-solid-svg-icons';
import Notify from 'src/notification/Notify'; 

const imageLocation = process.env.REACT_APP_API_IMAGE;   

const Rx = ({ open, close, claim_id , patient_id, doctors_id }) =>{ 

    const token = localStorage.getItem('token')

    const [doctorsInfo, setDoctorsInfo] = useState([])
    const [doctorsInfoRd, setDoctorsInfoRd] = useState(false)

    const [prescDetails, setPrescDetails] = useState([])
    const [prescDetailsRd, setPrescDetailsRd] = useState(false)

    const [patientInfo, setPatientInfo] = useState([])
    const [patientInfoRd, setPatientInfoRd] = useState(false)

    const getDoctorsInfo = useCallback(() => {
        var formdata  = new FormData();
            formdata.set('doctors_id', doctors_id)
            formdata.set('token', token)

        Axios.post('patients/rx/printable/doctorsdetails', formdata)
        .then((response) =>{
            const data = response.data
            setDoctorsInfo(data)
            setDoctorsInfoRd(true) 
        }).catch((error) =>{
            Notify.requestError(error)
        })
    } , [token, doctors_id] );

    const getPatientInfo = useCallback(() => {
        var formdata  = new FormData();
            formdata.set('token', token) 
            formdata.set('patient_id', patient_id) 

        Axios.post('doctor/patient/patient-information', formdata)
        .then((response) =>{
            const data = response.data
            setPatientInfo(data)
            setPatientInfoRd(true)  
        }).catch((error) =>{
            Notify.requestError(error)
        })
    }, [token, patient_id] );

    const getPrescriptionDetails = useCallback(() => {
        var formdata  = new FormData();
            formdata.set('token', token)
            formdata.set('claim_id', claim_id) 
            formdata.set('patient_id', patient_id)
            formdata.set('user_id', doctors_id)

        Axios.post('patients/rx/printable/prescriptiondetails', formdata)
        .then((response) =>{
            const data = response.data
            setPrescDetails(data)
            setPrescDetailsRd(true)  
        }).catch((error) =>{
            Notify.requestError(error)
        })
    }, [token, claim_id,patient_id, doctors_id] );

    useEffect( () =>{
        getDoctorsInfo()
        getPrescriptionDetails()
        getPatientInfo()
    }, [getDoctorsInfo, getPrescriptionDetails, getPatientInfo])
 
    
    return (
        <Fragment>
            {/* dialog for print rx */}
            <Dialog
                disableBackdropClick
                open={ open } 
                TransitionComponent={ Zoom }
                transitionDuration={ 600 } 
                onClose={ close } 
                fullScreen
            > 
                <DialogContent >  
                    <Box className="gtc-printable-landscape">
                        {/* doctors information */}
                        {
                            doctorsInfoRd ?
                                doctorsInfo.length > 0 ?
                                    <Box align="center" mb={1}>
                                        <Box>
                                            <Typography variant="h6" className={`gtc-uppercase`}>
                                                <b> {doctorsInfo[0].header} </b>
                                            </Typography> 
                                        </Box>
            
                                        <Box>
                                            <Typography variant="subtitle1"  className={`gtc-uppercase`} color="textPrimary">
                                                {doctorsInfo[0].sub_header}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {doctorsInfo[0].location}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {doctorsInfo[0].contact_no}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                {doctorsInfo[0].days_open}
                                            </Typography>
                                        </Box>
                                    </Box>
                                : <Box> 
                                    { Notify.noRecord() }
                                    <Typography>
                                        Doctors Information Not Found.
                                    </Typography>
                                 </Box>
                            : Notify.loading()
                        }
                        

                        <Divider />
                        <Divider />

                        <Box mt={1}>
                            {/* patients information */} 

                            {
                                patientInfoRd ? 
                                    patientInfo.length > 0 ?
                                        <>
                                            <Box mb={1}>
                                                <b> Name: </b> <span className="text-capitalize"> { patientInfo[0].firstname } { patientInfo[0].middle } { patientInfo[0].firstname } </span>
                                            </Box>
                                            <Grid container> 
                                                <Grid item xs={6}>
                                                    <Box>
                                                        <b> Gender: </b> { patientInfo[0].gender }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box>
                                                        <b> Age: </b> { Notify.calculateAge(patientInfo[0].birthday) }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box mt={1}>
                                                        <b> Date: </b> { prescDetails.length > 0 ? Notify.dateTimeConvert(prescDetails[0].created_at) : 'No date found.'}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </>
                                    : Notify.noRecord()
                                : Notify.loading()
                            }

                            {/* RX ICON */}
                            <Box mt={2}>
                                <FontAwesomeIcon icon={faPrescription} size="5x" />
                            </Box>

                            <Table className="gtc-table-borderless" padding="checkbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell> <b> Products </b> </TableCell>
                                        <TableCell> <b> Quantity </b> </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        prescDetailsRd ? 
                                            prescDetails.length > 0 ?
                                                prescDetails.map((data, index) => {
                                                    return(  
                                                        <Fragment key={index}>
                                                            <TableRow >
                                                                <TableCell>
                                                                    <Box>
                                                                        <Typography 
                                                                            variant="subtitle2"
                                                                            noWrap={false}
                                                                        >
                                                                            <span className="text-uppercase"> { data.product_name } { data.type } { data.dosage }  </span>
                                                                        </Typography> 
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography 
                                                                            variant="subtitle2" 
                                                                            color="textSecondary"
                                                                            noWrap={false}
                                                                        >
                                                                            Sig. { data.remarks }
                                                                        </Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Box>
                                                                        { data.quantity }
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow> 
                                                        </Fragment>
                                                    )
                                                })
                                            :  <TableRow>
                                                    <TableCell align="right">
                                                        <Typography color="secondary">
                                                            No record found.
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                        :   <TableRow>
                                                <TableCell align="right">
                                                    <Typography color="primary">
                                                        please wait...
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                    } 
                                </TableBody>
                            </Table>
                        </Box> 

                        {/* signs and licencess */}
                        {
                            doctorsInfoRd ?
                                doctorsInfo.length > 0 ?
                                    <Box display="flex">
                                        <Box flexGrow={1}/>
                                        <Box>
                                            <Box>
                                                <Box align="left"> 
                                                    {/* <img src="/sample-sign.png" alt="" width="120" style={{position: 'relative', top: 140}}/> */}
                                                    <img  
                                                        src={imageLocation+"doctors/doctors_signatures/"+doctorsInfo[0].image_signature } 
                                                        alt="" 
                                                        width="120" 
                                                        style={{position: 'relative', top: 30}}
                                                    />
                                                </Box> 

                                                <Typography variant="subtitle1">
                                                    <b className="text-capitalize"> { doctorsInfo[0].name } </b>
                                                </Typography>
                                            </Box>
                                                
                                            <Box>
                                                <Typography variant="subtitle2">
                                                    PRC No. { doctorsInfo[0].cil_umn }
                                                </Typography>
                                            </Box>
                                            <Box> 
                                                <Typography variant="subtitle2">
                                                    PTR No.
                                                </Typography>
                                            </Box>
                                            <Box> 
                                                <Typography variant="subtitle2">
                                                    TIN No.
                                                </Typography>
                                            </Box>
                                            <Box> 
                                                <Typography variant="subtitle2">
                                                    S2 No.
                                                </Typography>
                                            </Box>
                                        </Box> 
                                    </Box>
                                : Notify.noRecord()
                            : Notify.loading()
                        } 
                    </Box>
                </DialogContent>
                <DialogActions className="d-print-none">
                    <Button
                        variant="contained"
                        color="inherit"
                        startIcon={ <Cancel />}
                        onClick={ close }
                    >
                        No
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={ <Print /> }
                        onClick={ () => window.print() }
                    >
                        Print
                    </Button>
                </DialogActions>
            </Dialog> 
        </Fragment>
    )
}

export default Rx;
