import React, { Fragment, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box' 
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead' 
import TableCell from '@material-ui/core/TableCell' 
import TableRow from '@material-ui/core/TableRow'  
import TablePagination from '@material-ui/core/TablePagination' 
import Paper from '@material-ui/core/Paper'  
import Typography from '@material-ui/core/Typography';   
import Notify from '../../notification/Notify';
import Axios from 'axios';  
import Label from 'src/utils/Label';

function AppointmentReport() {   
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))

    const [localapp, setlocalapp] = useState([])  

    const [page, setPage] = useState(0);  
    const [rowsPerPage, setRowsPerPage] = useState(10); 
   
    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };    

    const getalllocalAppointment = () =>{   
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);     
        
        Axios.post('doctors/appointment/local/applocal-list', formdata )
        .then( (response) =>{ 
            const data = response.data;    
            setlocalapp(data)
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    } 

    useEffect(() =>{

        getalllocalAppointment() 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    return (
        <Fragment> 
            <Box component={Paper} variant="outlined">
                <Box ml={2} mt={2}>
                    <Typography className={`gtc-uppercase`} color="primary" variant="body2"> Appointment List </Typography>
                </Box>
                
                <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> Date </TableCell>
                                <TableCell> Patient </TableCell>
                                <TableCell> Service </TableCell> 
                                <TableCell> Token </TableCell>  
                                <TableCell> Status </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                    ? localapp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : localapp
                                ).map((data, index) => (
                                <TableRow hover key={index}>
                                    <TableCell component="th" scope="row">
                                        { Notify.dateTimeConvert(data.app_date) }
                                    </TableCell>
                                    <TableCell>{data.patients_name}</TableCell>
                                    <TableCell>{data.services}</TableCell> 
                                    <TableCell align="right">{ data.amount }</TableCell> 
                                    <TableCell>
                                        <Label
                                            color={ Boolean(parseInt(data.is_complete)) ? "success" : "error" }
                                        > 
                                            { Boolean(parseInt(data.is_complete)) ? "complete" : "incomplete" } 
                                        </Label>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody> 
                    </Table> 

                    <TablePagination
                        labelRowsPerPage = "List"
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        component="div"
                        count={localapp.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> 
                </TableContainer> 
            </Box>
            {/* action di */}
        </Fragment>
    )
}

export default AppointmentReport;
