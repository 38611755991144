import React, { Fragment, useEffect, useState, useContext } from 'react';   
import { Box, Paper, Tabs, Tab, Typography, Badge } from '@material-ui/core';
import TabPanel from 'src/utils/TabPanel'; 
import ImagingHeader from './ImagingHeader'
import CheckInternet from 'src/utils/CheckInternet';
import CheckingConnection from 'src/CheckInternet';
import NoInternet from 'src/NoInternet';
import { PatientsUnRead } from 'src/ContextAPI';

const ImagingOrder = ({ patient_id }) =>{  
    
    const [tab, setTabs] = useState('local') 
    const [online, setOnline] = useState('checking')

    const unread = useContext(PatientsUnRead);

    const checkinternet = () => {
        CheckInternet.online().then(() => setOnline('connected')).catch(() => setOnline('disconnected'))
    }

    const checkPatientUnreadNotif = ( notifFrom, category ) =>{
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual;
        let count = 0;
        if(notifFrom === 'local'){
            for (let i = 0; i < xx.length; i++) {
                if( patient_id === xx[i].patient_id && category === xx[i].category){
                    count += 1;
                }
            }
        }else{
            for (let i = 0; i < yy.length; i++) {
                if( patient_id === yy[i].patient_id && category === yy[i].category){
                    count += 1;
                }
            }
        }
        
        return count;
    }

    useEffect(() =>{

        checkinternet()

    },[patient_id, online])

    return(
        <>
            <Fragment>        
                <Box mb={2} display="flex">
                    <Box flexGrow={1} />
                    <Box>
                        <Paper variant="outlined">
                            <Tabs 
                                value={tab} 
                                onChange={(e, tab) => setTabs(tab)}  
                                indicatorColor="primary"
                                textColor="primary" 
                                scrollButtons="on"  
                                variant="scrollable"  
                            >
                                <Tab
                                    value="local"
                                    label={
                                        Boolean(parseInt(checkPatientUnreadNotif('local', 'imaging'))) ?
                                            (<Badge color="secondary" badgeContent={parseInt(checkPatientUnreadNotif('local', 'imaging'))}> Local Order </Badge>)
                                        : 'Local Order'
                                    }
                                    wrapped
                                />
                                <Tab
                                    value="virtual"
                                    label={
                                        Boolean(parseInt(checkPatientUnreadNotif('virtual', 'imaging'))) ?
                                            (<Badge color="secondary" badgeContent={parseInt(checkPatientUnreadNotif('virtual', 'imaging'))}> Virtual Order </Badge>)
                                        : 'Virtual Order'
                                    }
                                    wrapped
                                /> 
                            </Tabs> 
                        </Paper>
                    </Box>
                </Box>  
                
                <Box>
                    <TabPanel value={ tab } index="local"> 
                        <ImagingHeader imagingType="local-imaging" patient_id = { patient_id } />
                    </TabPanel>

                    <TabPanel value={ tab } index="virtual">     
                        { online === 'connected' &&(
                            <ImagingHeader imagingType="virtual-imaging" patient_id = { patient_id } /> 
                        )} 

                        { online === 'checking' && <CheckingConnection /> }
                        { online === 'disconnected' && (
                            <>
                                <Box align="center">
                                    <Typography  color="secondary" variant="caption"> VIRTUAL IMAGING NOT AVAILABLE IN OFFLINE MODE. </Typography>
                                </Box>
                                <NoInternet />
                            </>
                        )}
                    </TabPanel>
                </Box>
            </Fragment>
        </>
    )
}

export default ImagingOrder; 