
import React, { useState, useEffect, Fragment, useCallback } from 'react';   
import Axios from 'axios';
import Notify from '../../notification/Notify'; 
import { Grid, Box, Collapse, Typography, TablePagination, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import HealthDiagnosisForm from './HealthDiagnosisForm'; 
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const HealthDiagnosis = (props) => {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))

    
 
    const [hasDiagnosis, sethasDiagnosis] = useState(false);  
    
    const [diagnosis, setdiagnosis] = useState([])
    const [diagnosisready, setdiagnosisready] = useState(false)

    const [selectedId, setSelectedId] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(5);    
    const [page, setPage] = useState(0);  

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };   

    const getDiagnosis = useCallback(() =>{
        var formdata = new FormData();

        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('patient_id', props.patient_id);
        formdata.set('connection', props.connection);

        Axios.post('doctor/patient/diagnosis/getdiagnosis-list', formdata )
        .then((response)=>{ 
            const data = response.data;  
            setdiagnosis(data)
            setdiagnosisready(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }, [token, user_id, props.patient_id, props.connection]);
    

    useEffect(() =>{

        getDiagnosis()

    }, [getDiagnosis])  
    
    return (
        <Fragment>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Box 
                        p={2} 
                        border={1} 
                        borderColor={"grey.200"} 
                        bgcolor="white"
                    >
                        <Box display="flex" p={1}>
                            <Box flexGrow={1}>
                                <Typography variant="h6" color="textSecondary"> Diagnosis </Typography> 
                            </Box>
                            <Box>
                                <Button 
                                    variant="text"
                                    color="primary" 
                                    onClick={ () => sethasDiagnosis(true)}
                                    hidden={ hasDiagnosis ? true : false }
                                    startIcon={ <AddCircle  /> }
                                >
                                    Update
                                </Button>
                            </Box>
                        </Box>
                        
                        <Collapse in={ hasDiagnosis ? true : false } timeout={1000}>
                            <HealthDiagnosisForm 
                                patient_id = { props.patient_id }
                                connection = { props.connection }
                                closeForm ={ () => sethasDiagnosis(false)}
                                getDiagnosis = { () => getDiagnosis() }
                            />
                        </Collapse>  
 
                        {/* diagnosis list */}
                        <Box hidden={ hasDiagnosis ? true : false }> 
                            {
                                diagnosisready ? 
                                    diagnosis.length > 0 ?
                                        (rowsPerPage > 0
                                            ? diagnosis.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : diagnosis
                                        ).map((data, index) => ( 
                                            <Box 
                                                p={1} 
                                                key={index}
                                                borderRadius={4} 
                                            >
                                                <Box display={'flex'}>
                                                    <Box flexGrow={1}>
                                                        <Typography  
                                                            noWrap 
                                                            variant="subtitle1"
                                                        > 
                                                            { data.diagnosis }
                                                            <br />
                                                            <small className="text-muted"> { Notify.dateTimeConvert(data.created_at) } </small>
                                                        </Typography>  
                                                    </Box> 
                                                    <Box>
                                                        <IconButton 
                                                            color="primary"
                                                            onClick={ () => setSelectedId(selectedId === data.id ? null : data.id)}
                                                        >
                                                            { selectedId === data.id ? <KeyboardArrowDownIcon />  : <KeyboardArrowRightIcon /> } 
                                                        </IconButton> 
                                                    </Box>
                                                </Box>

                                                <Collapse in={selectedId === data.id ? true : false }> 
                                                    <Box mt={1}>
                                                        <Typography variant="subtitle1" dangerouslySetInnerHTML={{__html: data.remarks}} />
                                                    </Box>
                                                </Collapse>  
                                            </Box>
                                        ))
                                    : Notify.noRecord()
                                : Notify.loading()
                            } 
                        </Box>
                        <TablePagination
                            hidden={ hasDiagnosis ? true : false }
                            labelRowsPerPage = "List"
                            rowsPerPageOptions={[5, 20, 50, 100]}
                            component="div"
                            count={diagnosis.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Box>
                </Grid>  
            </Grid>
        </Fragment>
    )
}

export default HealthDiagnosis; 