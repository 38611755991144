import React, { Fragment, useCallback, useState, useEffect, useContext } from 'react';
import {
    Grid,
    ButtonGroup,
    Hidden,
    Button, Box, Badge, makeStyles
} from '@material-ui/core';
import VirtualAppointment from './VirtualAppointment';
import LocalAppointment from './LocalAppointment';
import Computer from '@material-ui/icons/Computer';
import Public from '@material-ui/icons/Public';
import DashboardAlternative from './DashboardAlternative';
import Notify from 'src/notification/Notify';
import axios from 'axios'
import { PatientsUnRead } from './../ContextAPI';


const useStyles = makeStyles((theme) => ({
    center: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));

function Dashboard() {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 
    const classes = useStyles();
    const [appcategory, setappcategory] = useState('local-appointment') 
    const [doctorsId, setDoctorsId] = useState(null) 


    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( category, from ) =>{
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual;
        let count = 0;
        if(from === 'local'){
            for (let i = 0; i < xx.length; i++) {
                if( doctorsId === xx[i].doctor_id && category === xx[i].category && from === xx[i].notification_from){
                    count += 1;
                }
            } 
        }else{
            for (let i = 0; i < yy.length; i++) {
                if( doctorsId === yy[i].doctor_id && category === yy[i].category && from === yy[i].notification_from){
                    count += 1;
                }
            } 
        }
        
        return count;
    }

    const movePatientToList = useCallback(async () => {
        try {
            var formdata = new FormData()
            formdata.set('token', token);
            formdata.set('user_id', user_id); 
            const response = await axios.post('doctor/patients/moveto-list', formdata) 
            const data = response.data
            setDoctorsId(data)
        } catch (error) {
            Notify.requestError(error);
        }
    }, [token, user_id]) 

    useEffect(() => {

        movePatientToList()   
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [movePatientToList, doctorsId])

    return (
        <Fragment>

            <Box m={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Box mb={2} className="clearfix">
                            <Box  className={ window.innerWidth > 500 ? ''  : classes.center }
                            >
                                <ButtonGroup
                                    variant="contained"
                                >
                                    <Button
                                        id="local-appointment"
                                        onClick={(e) => setappcategory(e.currentTarget.id)}
                                        color={appcategory === 'local-appointment' ? "primary" : "default"}
                                        startIcon={<Computer />}
                                        size="large"
                                    >
                                        <Badge
                                            color="secondary"
                                            badgeContent ={'new'}
                                            invisible = { ! Boolean(parseInt(checkPatientUnreadNotif('appointment', 'local'))) }
                                        >
                                            Local <Hidden xsDown> Appointment </Hidden>
                                        </Badge>
                                    </Button>

                                    <Button
                                        id="virtual-appointment"
                                        onClick={(e) => setappcategory(e.currentTarget.id)}
                                        color={appcategory === 'virtual-appointment' ? "primary" : "default"}
                                        startIcon={<Public />}
                                        size="large"
                                    >
                                        <Badge
                                            color="secondary"
                                            badgeContent ={'new'}
                                            invisible = { ! Boolean(parseInt(checkPatientUnreadNotif('appointment', 'virtual'))) }
                                        >
                                            Virtual <Hidden xsDown> Appointment </Hidden>
                                        </Badge>
                                    </Button> 

                                    {/* <Button   
                                        id="dboard" 
                                        onClick={ (e) => setappcategory(e.currentTarget.id) }   
                                        color={appcategory === 'dboard' ? "secondary" : "primary"}
                                        startIcon={<Computer />}
                                    > Alternative Dashboard </Button>  */}
                                </ButtonGroup>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {
                    appcategory === 'local-appointment' ?
                        <LocalAppointment connection="local" />
                    : appcategory === 'virtual-appointment' ?
                        <VirtualAppointment connection="online" />
                    :   <DashboardAlternative />
                }
            </Box>
        </Fragment>
    )
}

export default Dashboard;  