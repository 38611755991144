
import Notify from 'src/notification/Notify'

const FormvalidationClinicMicroscopy = (fieldData, formField) =>{ 
    var error  = [];  
    
    if(Boolean(parseInt(fieldData.chemical_test))){
        if( formField.get('color').length === 0 && 
            formField.get('color').trim() === '' &&
            formField.get('transparency').length === 0 && 
            formField.get('transparency').trim() === '' &&
            formField.get('ph').length === 0 && 
            formField.get('ph').trim() === '' &&
            formField.get('specific_gravity').length === 0 && 
            formField.get('specific_gravity').trim() === '' &&
            formField.get('glucose').length === 0 && 
            formField.get('glucose').trim() === '' &&
            formField.get('albumin').length === 0 &&
            formField.get('albumin').trim() === '')
        {
            Notify.fieldRequired('Chemical Test Fields')
            error = 'error';
        }
    } 

    if(Boolean(parseInt(fieldData.microscopic_test))){
        if( formField.get('squamous').length === 0 && 
            formField.get('squamous').trim() === '' &&
            formField.get('pus').length === 0 && 
            formField.get('pus').trim() === '' &&
            formField.get('redblood').length === 0 && 
            formField.get('redblood').trim() === '' &&
            formField.get('hyaline').length === 0 && 
            formField.get('hyaline').trim() === '' &&
            formField.get('wbc_cast').length === 0 && 
            formField.get('wbc_cast').trim() === '' &&
            formField.get('rbc_cast').length === 0 && 
            formField.get('rbc_cast').trim() === '' &&
            formField.get('fine_granualar').length === 0 && 
            formField.get('fine_granualar').trim() === '' &&
            formField.get('coarse_granualar').length === 0 && 
            formField.get('coarse_granualar').trim() === '' &&
            formField.get('crystal_oxalate').length === 0 && 
            formField.get('crystal_oxalate').trim() === '' &&
            formField.get('triple_phosphate').length === 0 && 
            formField.get('triple_phosphate').trim() === '' &&
            formField.get('leucine_tyrocine').length === 0 && 
            formField.get('leucine_tyrocine').trim() === '' &&
            formField.get('ammoniume').length === 0 && 
            formField.get('ammoniume').trim() === '' &&
            formField.get('amorphous_urates').length === 0 && 
            formField.get('amorphous_urates').trim() === '' &&
            formField.get('amorphous_phosphate').length === 0 && 
            formField.get('amorphous_phosphate').trim() === '' &&
            formField.get('uric_acid').length === 0 && 
            formField.get('uric_acid').trim() === '' &&
            formField.get('mucus_thread').length === 0 && 
            formField.get('mucus_thread').trim() === '' &&
            formField.get('bacteria').length === 0 && 
            formField.get('bacteria').trim() === '' &&
            formField.get('yeast').length === 0 && 
            formField.get('yeast').trim() === ''
        ){
            Notify.fieldRequired('Microscopy Test Fields')
            error = 'error';
        }
    }  
 
    if(Boolean(parseInt(fieldData.pregnancy_test_hcg))){
        if(formField.get('pregnancy_test').length === 0 || formField.get('pregnancy_test').trim() === ''){
            Notify.fieldRequired('pregnancy_test')
            error = 'error';
        }
    }   
 
    if(formField.get('result_remarks').length === 0 || formField.get('result_remarks').trim() === ''){
        Notify.fieldRequired('result_remarks')
        error = 'error';
    } 

    return error;
}

export default FormvalidationClinicMicroscopy;