import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, TablePagination, InputAdornment, Divider, Paper, Button } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import VirtualDetails from './VirtualDetails';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { green } from '@material-ui/core/colors';
import { KeyboardArrowLeftOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

var interval = null;
function Virtual() {
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [pharmacy_id] = useState(localStorage.getItem('pharmacy_id'))
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [patients, setPatients] = useState([]);
    const [patientsReady, setPatientsReady] = useState(false);
    const [search, setSearch] = useState('')
    const [selectedOrder, setSelectedOrder] = useState(null);
    const history = useHistory();

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchPrescription = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);
        formdata.set('pharmacy_id', pharmacy_id)
        formdata.set('connection', 'online')

        Axios.post('malita/pharmacy/get-prescription-list', formdata)
            .then((response) => {
                const data = response.data;
                setPatients(data);
                setPatientsReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {
        fetchPrescription()

        interval = setInterval(() => {
            fetchPrescription()
        }, 7000);

        return () => {
            clearInterval(interval);
        }

        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const searchable = patients.filter((data) => {
        return data.patientFullname.toLowerCase().indexOf(search.trim()) !== -1
    })

    return (
        <Fragment>
            <Box m={1}>
                <Grid container spacing={2}>
                    <Grid item sm={12} xs={12}>
                        <Box>
                            <Button
                                onClick={() => history.push('/app')}
                                color="primary"
                                startIcon={<KeyboardArrowLeftOutlined />}
                            >
                                Home
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={4}>
                        <Paper>
                            <Box>
                                <Box p={2} borderRadius={4}>
                                    <Box display="flex" justifyContent="center" >
                                        <Box flexGrow={1} >
                                            <Typography color="primary" >
                                                Virtual Prescription List
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Divider />
                                <Box m={1}>
                                    <TableContainer component={Box} borderColor="grey.200" bgcolor="white">
                                        <Box display="flex">
                                            <Box flexGrow={1} />
                                            <Box mb={1}>
                                                <TextField
                                                    label="Search name"
                                                    variant="outlined"
                                                    margin="dense"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment>
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                        </Box>

                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center"> Service </TableCell>
                                                    <TableCell align="center"> Name </TableCell>
                                                    {/* <TableCell align="center"> Doctor </TableCell> */}
                                                    <TableCell align="center"> Order # </TableCell>
                                                    <TableCell align="center"> Action </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    patientsReady ?
                                                        patients.length > 0 ?
                                                            searchable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                .map((data, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell align="center" bgcolor="white">{parseFloat(data.delivery) === 1 ? <MotorcycleIcon color="primary" /> : <ShoppingBasketIcon style={{ color: green[500] }} />}</TableCell>
                                                                        <TableCell bgcolor="white">{data.patientFullname}</TableCell>
                                                                        {/* <TableCell bgcolor="white">{data.doctorFullName}</TableCell> */}
                                                                        <TableCell bgcolor="white"> {data.order_no} </TableCell>
                                                                        <TableCell bgcolor="white" align="center">
                                                                            <NavigateNextIcon
                                                                                color={selectedOrder === data.order_no ? 'secondary' : "primary"}
                                                                                onClick={() => setSelectedOrder(data.order_no)}
                                                                                className="pointer"
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                            : <TableRow>
                                                                <TableCell colSpan={7}>
                                                                    <Typography variant="subtitle2" color="secondary"> No prescription added </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        : <TableRow>
                                                            <TableCell colSpan={7}>
                                                                <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        </Table>

                                        <TablePagination
                                            component={"div"}
                                            rowsPerPageOptions={[5, 10, 25]}
                                            colSpan={3}
                                            count={patients.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                        <Box>
                            {selectedOrder === null && (
                                <Box component={Paper} variant="outlined" p={2} mb={2}>
                                    <Box display="flex" justifyContent="center" alignItems="center" height={100}>
                                        <Typography variant="h3" color="primary">
                                            Select Order
                                        </Typography>
                                    </Box>
                                </Box>)}
                            {selectedOrder !== null && (<VirtualDetails closeDetails={() => setSelectedOrder(null)} selectedOrder={selectedOrder} />)}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    )
}

export default Virtual;