import React, { Fragment, useEffect } from 'react';   
import ImagingOrder from './ImagingOrder';  
  

const Imaging = ({ patient_id }) =>{ 

    useEffect(() =>{  },[ patient_id ])
    return(
        <Fragment>
            <ImagingOrder 
                patient_id={patient_id} 
            />
        </Fragment>
    )
}

export default Imaging;