import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Box, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Typography,  
    IconButton,
    Menu,
    MenuItem, ListItemIcon, ListItemText, Collapse, Paper
} from '@material-ui/core';  
import Notify from '../notification/Notify';
import Axios from 'axios'; 
import More from '@material-ui/icons/MoreVert'; 
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PausePresentationIcon from '@material-ui/icons/PausePresentation';
import SetPending from './Dialogs/SetPending';
import AddResult from './Dialogs/AddResult';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'; 
import { NavLink } from 'react-router-dom';
 
const ImagingOrderProcessing = ({ getCounts }) =>{
 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')  
    const management_id = localStorage.getItem('management_id')  

    const [processing, setProcessing] = useState({ data: [], ready: false }) 

    const [addResult, setAddResult] = useState(false)

    const [ selectedImagingId, setSelectedImagingId ] = useState(null)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl); 

    const [ pending, setPending] = useState(false) 

    const [radiologist, setRadiologist] = useState([])

    const getProssingOrder = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        
        Axios.post('imaging/order/order-processing', formdata )
        .then((response) => {  
            const data = response.data;   
            setProcessing({ data, ready: true })
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id]); 
   
    const getRadiologist = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('management_id', management_id);    
        
        Axios.post('imaging/order/radiologist/get-radiologist', formdata )
        .then((response) => {  
            const data = response.data;   
            setRadiologist(data) 
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, management_id]); 

    const handleClick = (event) => {
        setSelectedImagingId(event.currentTarget.id)
        setAnchorEl(event.currentTarget);
    };
    
    const handlePending = () => {
        setAnchorEl(null)
        setPending(true);
    };
 
    const handleAddResult = () => {
        setAnchorEl(null)
        setAddResult(true);
    }; 

    useEffect(() =>{

        getProssingOrder()
        getRadiologist()
        
    }, [getProssingOrder, getRadiologist])

    return(
        <Fragment>
            <Collapse in={processing.ready} timeout={600}>
                <Paper component={Box} p={2} variant="outlined">
                    <TableContainer >
                        <Box ml={2} mt={2}>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                            >
                                PROCESSING ORDER
                            </Typography>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> ID </TableCell>  
                                    <TableCell> StartedOn </TableCell>
                                    <TableCell> Patient </TableCell>
                                    <TableCell> Order </TableCell> 
                                    <TableCell> Action </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody> 
                                {
                                    processing.ready ?
                                        processing.data.length > 0 ?
                                            processing.data.map((data,index)=>(
                                                <TableRow key={index} hover>
                                                    <TableCell> { data.imaging_center_id.replace('imaging-','') } </TableCell>  
                                                    <TableCell> { Notify.dateTimeConvert(data.start_time) } </TableCell> 
                                                    <TableCell> { data.patient_name } </TableCell>
                                                    <TableCell> { data.imaging_order } </TableCell>
                                                    <TableCell> 
                                                        <IconButton 
                                                            color="primary"
                                                            onClick={ handleClick }
                                                            id={ data.imaging_center_id }
                                                        >
                                                            <More />
                                                        </IconButton>
                                                        <Menu 
                                                            anchorEl={ anchorEl } 
                                                            open={ open }
                                                            onClose={ () => {
                                                                setAnchorEl(null);
                                                                setPending(false);
                                                                setSelectedImagingId(null)
                                                            }} 
                                                        >  
                                                            <MenuItem 
                                                                onClick={ handlePending }
                                                            >
                                                                <ListItemIcon>
                                                                    <PausePresentationIcon color="secondary" /> 
                                                                </ListItemIcon>
                                                                <ListItemText primary={ <Typography color="secondary"> Set Pending </Typography>} />
                                                            </MenuItem> 
                                                            <MenuItem
                                                                onClick={ handleAddResult }
                                                            >
                                                                <ListItemIcon>
                                                                    <AddCircleOutlineIcon color="primary" />
                                                                </ListItemIcon>
                                                                <ListItemText primary={ <Typography color="primary"> Sent To RadTech </Typography>} /> 
                                                            </MenuItem> 

                                                            <MenuItem 
                                                                component={NavLink}
                                                                to={`/app/imaging/sentto/telerad/${data.imaging_center_id}`}
                                                            >
                                                                <ListItemIcon>
                                                                    <PersonPinCircleIcon color="primary" />
                                                                </ListItemIcon> 
                                                                <ListItemText primary={ <Typography color="primary"> Send To Telerad </Typography>} />
                                                            </MenuItem> 
                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Typography variant="subtitle2" color="secondary"> No new order </Typography> 
                                            </TableCell> 
                                        </TableRow>
                                    : 
                                    <TableRow>
                                        <TableCell colSpan={5}> 
                                            <Typography variant="subtitle2" color="primary"> please wait </Typography>
                                        </TableCell> 
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Collapse>

            {/* dialog add result */}
            <AddResult  
                open={ addResult }
                imaging_center_id={ selectedImagingId }
                close={ () =>{ 
                    setAddResult(false)
                    setSelectedImagingId(null)
                }}
                reload={ getProssingOrder }
                getCounts={ getCounts } 
                radiologist = {radiologist}
            />

            {/* dialog set as pending */}
            <SetPending
                open={ pending }
                imaging_center_id={ selectedImagingId }
                close={ () =>{ 
                    setPending(false)
                    setSelectedImagingId(null)
                }}
                reload={ getProssingOrder }
                getCounts = { getCounts }
            />
 
        </Fragment>
    )
}

export default ImagingOrderProcessing;