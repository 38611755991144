
import React, { Fragment, useContext, useState, useEffect } from "react"
import { BrowserRouter, NavLink, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import { ThemeContext } from "../ContextAPI";
import { AppBar, Box, Hidden, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography, Badge, List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreVertIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import GTCValidateLS from "src/notification/GTCValidateLS";
import Patients from "src/doctor/patients/Patient";
import Dashboard from "../doctor/Dashboard";
import CreateRoom from "../doctor/onlinecheckup/CreateRoom";
import AppointmentApprovedDetailsReadmore from "../doctor/appointment/AppointmentApprovedDetailsReadmore";
import StreamError from "../StreamError";
import CreateRoomOLd from "src/doctor/onlinecheckup/CreateRoomOLd";
import { PatientsUnRead } from 'src/ContextAPI';
import Report from 'src/doctor/Income/Report';
import FeaturedPlayListIcon from '@material-ui/icons/ListAlt';
import DarkIcon from '@material-ui/icons/Brightness4';
import LightIcon from '@material-ui/icons/Brightness7';
import axios from 'axios' 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import PerfectScrollbar from 'react-perfect-scrollbar';
import CheckInternet from "src/utils/CheckInternet";


const RouteDoctor = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    
    const themeContext = useContext(ThemeContext)

    const [anchorEl, setAnchorEl] = useState(null)
    const [menu, setMenu] = useState(false)


    const [notifAnchorEl, setNotifAnchorEl] = useState(null)
    const [notifMenu, setNotifMenu] = useState(false)

    const [patientNotifBadge, setPatientNotifBadge] = useState(0);

    const [unviewNotif, setUnviewNotif] = useState([])
    const [unviewNotifVirtual, setUnviewNotifVirtual] = useState([])
    const [unviewNotifReady, setUnviewNotifReady] = useState(false)

    var interval = null;

    const updateDocNotif = (data) => {
        setPatientNotifBadge(data)
    }


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
        setMenu(true)
    }; 

    const handleClose = () => {
        setNotifAnchorEl(null)
        setNotifMenu(false)
        setAnchorEl(null)
        setMenu(false)
    };

    const getLocalNotifUnread = async () =>{     
        try {
            var formdata = new FormData();
            formdata.set('token', token);
            formdata.set('user_id', user_id);
            formdata.set('connection', 'local')

            const response = await axios.post('doctor/unview/notification-unview-orders', formdata) 
            const data = response.data; 
            setUnviewNotif(data)
            
        } catch (error) {
            console.log('doctors unread notif error.', error)
        } 
    }

    const getVirtualNotifUnread = () =>{     
        CheckInternet.online().then( async () =>{ 
            try {
                var formdata = new FormData();
                formdata.set('token', token);
                formdata.set('user_id', user_id);
                formdata.set('connection', 'online')
    
                const response = await axios.post('doctor/unview/notification-unview-orders', formdata) 
                const data = response.data; 
                setUnviewNotifVirtual(data)
                setUnviewNotifReady(true)  
            } catch (error) {
                console.log('doctors unread notif error.', error)
            } 
        }).catch(() => {
            console.log('no internet connection, notification from virtual not availbale.')
            setUnviewNotifReady(true)
        })
    } 

    const initializeInterval = () =>{ 
        interval = setInterval(() => {
            getLocalNotifUnread()
            setTimeout(() => { 
                getVirtualNotifUnread() 
            }, 3000);
        }, 10000);
    }  

    useEffect(() => {

        GTCValidateLS.verifyLocalStorage()

        initializeInterval()

        return(() => clearInterval(interval) )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <PatientsUnRead.Provider
                value={{

                    doctorNotif: patientNotifBadge,
                    updateDocNotif: updateDocNotif,

                    unviewNotif: unviewNotif, 
                    unviewNotifVirtual: unviewNotifVirtual,
                }}
            >
                <BrowserRouter>
                    <AppBar
                        position="static"
                        color={themeContext.gtcThemeDark ? 'default' : 'primary'}
                        className={`gtc-appbar`}
                    >
                        <Hidden xsDown>
                            <Toolbar>
                                {/* desktop navigation bar */}
                                <Box
                                    display="flex"
                                    width={'100%'}
                                    className={`appbarLink`}
                                >
                                    <Box flexGrow={1}>
                                        <Box display="flex">
                                            <Box m={2}>
                                                <Typography className={`text-white`}>
                                                    GTC DOCTOR
                                            </Typography>
                                            </Box>
                                            <Box m={2}>
                                                <NavLink to="/app/doctor" exact activeClassName="text-white">
                                                    <HomeIcon /> Home
                                                </NavLink>
                                            </Box>
                                            <Box m={2}>
                                                <NavLink to="/app/doctor/patient/all" exact activeClassName="text-white">
                                                    <PeopleAltIcon />  Patients 
                                                </NavLink>
                                            </Box>
                                            <Box m={2}>
                                                <NavLink to="/app/doctor/income/report" exact activeClassName="text-white">
                                                    <FeaturedPlayListIcon /> Report
                                                </NavLink>
                                            </Box> 

                                            <Box m={2}> 
                                                <Box
                                                    className={`pointer`}
                                                    onClick={(e) =>{
                                                        setNotifAnchorEl(e.currentTarget)
                                                        setNotifMenu(true)
                                                    }}
                                                >
                                                    <Badge 
                                                        badgeContent={ unviewNotifReady ? parseInt(unviewNotif.length) + parseInt(unviewNotifVirtual.length) : ('...')} 
                                                        showZero 
                                                        color="secondary"
                                                    >
                                                        <FontAwesomeIcon size="lg" icon={ faBell } />
                                                    </Badge> 
                                                </Box> 

                                                <Menu  
                                                    component={List}
                                                    anchorEl={notifAnchorEl}
                                                    getContentAnchorEl={null}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }} 
                                                    open={notifMenu}
                                                    onClose={ handleClose } 
                                                >
                                                    <ListSubheader>
                                                        NOTIFICATIONS
                                                    </ListSubheader>
                                                    <PerfectScrollbar>
                                                        <Box maxHeight={300}>
                                                            { unviewNotifReady ? <RenderUnreadNotif notif={ unviewNotif } notifVirtual={unviewNotifVirtual}  /> : 
                                                                <Box align="center"> 
                                                                    <Typography color="primary" variant={'caption'}> please wait...</Typography> 
                                                                </Box>
                                                            }
                                                        </Box>
                                                    </PerfectScrollbar>
                                                </Menu>
                                            </Box> 
                                        </Box>
                                    </Box>
                                </Box>

                                <Box>
                                    <Box display="flex" justifyContent="flex-end" width={350}>
                                        <Box m={2} className="movingtext">
                                            <span > welcome @{localStorage.getItem('username')} </span>
                                        </Box>
                                        
                                        {/* <Box m={2} className={`pointer`}> 
                                            <Typography onClick={()=>{ themeContext.updategtcTheme(themeContext.gtcThemeDark ? 'light' : 'dark') } }> { themeContext.gtcThemeDark ? <DarkIcon /> : <LightIcon /> } </Typography>
                                        </Box> */}

                                        {/* <Box m={2} className={`pointer`}> 
                                            <Typography onClick={()=>{ themeContext.updategtcTheme(themeContext.gtcThemeDark ? 'light' : 'dark') } }> { themeContext.gtcThemeDark ? <DarkIcon /> : <LightIcon /> } </Typography>
                                        </Box> */}

                                        <Box m={2}>
                                            <NavLink to="/app/logout" exact style={{ color: '#c5c5c5', textDecoration: 'none' }}>
                                                Logout  <ExitToAppIcon />
                                            </NavLink>
                                        </Box>
                                    </Box>
                                </Box>
                            </Toolbar>
                        </Hidden>

                        <Hidden smUp>
                            <Toolbar>
                                {/* mobile navigation */}
                                <Box
                                    display="flex"
                                    width={'100%'}
                                    className={`appbarLink`}
                                >
                                    <Box flexGrow={1}>
                                        <Box display="flex">
                                            <Box m={2} flexGrow={1}>
                                                <Typography noWrap className={`text-white`}>
                                                    GTC DOCTOR
                                                </Typography>
                                            </Box>

                                            <Box m={2} className={`pointer`}> 
                                                <Typography onClick={()=>{ themeContext.updategtcTheme(themeContext.gtcThemeDark ? 'light' : 'dark') } }> { themeContext.gtcThemeDark ? <DarkIcon /> : <LightIcon /> } </Typography>
                                            </Box>

                                            <Box>
                                                <IconButton
                                                    aria-label="account of current user"
                                                    aria-controls="menu-appbar"
                                                    aria-haspopup="true"
                                                    onClick={handleMenu}
                                                    color="inherit"
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="menu-appbar"
                                                    anchorEl={anchorEl}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    keepMounted
                                                    open={menu}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem
                                                        component={NavLink}
                                                        to="/app/telerad"
                                                    >
                                                        <ListItemIcon>
                                                            <HomeIcon />
                                                        </ListItemIcon>
                                                        <Typography color="primary">
                                                            Home
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem
                                                        component={NavLink}
                                                        to="/app/doctor/patient/all"
                                                    >
                                                        <ListItemIcon>
                                                            <PeopleAltIcon />
                                                        </ListItemIcon>
                                                        <Typography>
                                                            Patients
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem
                                                        component={NavLink}
                                                        to="/app/doctor/income/report"
                                                    >
                                                        <ListItemIcon>
                                                            <FeaturedPlayListIcon />
                                                        </ListItemIcon>
                                                        <Typography> 
                                                            Report
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem
                                                        component={NavLink}
                                                        to="/app/logout"
                                                    >
                                                        <ListItemIcon>
                                                            <ExitToAppIcon />
                                                        </ListItemIcon>
                                                        <Typography color="error">
                                                            Logout
                                                        </Typography>
                                                    </MenuItem>
                                                </Menu>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Toolbar>
                        </Hidden>
                    </AppBar>
                    <Switch>
                        <Route exact path="/" component={() => <Redirect to="/app/doctor" /> } />   
                        <Route exact path="/app" component={() => <Redirect to="/app/doctor" /> } />
                        <Route exact path="/app/doctor" component={Dashboard} />
                        <Route exact path="/app/doctor/patient/:parampatient_id" component={Patients} />
                        <Route exact path="/app/doctor/income/report" component={Report} />
                        <Route exact path="/app/logout" component={Logout} />
                        {/* <Route exact path="/app/doctor/create/room/v-call/:room_id" component={CreateRoom} /> */}
                        <Route exact path="/app/doctor/create/room/v-call/:appointment_id" component={CreateRoom} />
                        {/* <Route exact path="/app/virtual/create/room/v-call/:patient_id/:appointment_id/:room_id" component={VirtualCall} />  */}
                        <Route exact path="/app/doctor/appointment/patient/information/:patient_id" component={AppointmentApprovedDetailsReadmore} />
                        <Route exact path="/app/doctor/appointment/room/:ref_number/:room_number" component={CreateRoomOLd} />
                        <Route exact path="/app/streamerror" component={StreamError} />
                        <Route render={
                            () => <PageNotFound />
                        } />
                    </Switch>
                </BrowserRouter>
            </PatientsUnRead.Provider>
        </Fragment>
    )
}

export default RouteDoctor;


const RenderUnreadNotif = ({ notif, notifVirtual }) =>{

    const history = useHistory()

    const handlePush = (e) => {
        var patientid = e.currentTarget.id
        var category = e.currentTarget.getAttribute('category')

        if(category === 'appointment'){
            history.push(`/app/doctor`)
        }else{ 
            history.push(`/app/doctor/patient/${patientid}`)
        }
    }

    return(
        <>
            { notif.length > 0 && (
                notif.map((data, index) =>(
                    <ListItem  
                        key= { index }
                        onClick={ handlePush }
                        id={ data.patient_id }
                        category={ data.category }
                        button
                    >
                        <ListItemIcon>
                            <PeopleAltIcon />
                        </ListItemIcon> 
                        <ListItemText 
                            className={`gtc-capitalize`}
                            primary={ `${data.firstname} ${data.lastname}` }
                            secondary={ data.message }
                            secondaryTypographyProps={{ noWrap: true }}
                        />
                    </ListItem>
                ))
            )}
            { notifVirtual.length > 0 && (
                notifVirtual.map((data, index) =>(
                    <ListItem  
                        key= { index }
                        onClick={ handlePush }
                        id={ data.patient_id }
                        category={ data.category }
                        button
                    >
                        <ListItemIcon>
                            <PeopleAltIcon />
                        </ListItemIcon> 
                        <ListItemText 
                            className={`gtc-capitalize`}
                            primary={ `${data.firstname} ${data.lastname}` }
                            secondary={ data.message }
                            secondaryTypographyProps={{ noWrap: true }}
                        />
                    </ListItem>
                ))
            )}
            { notif.length === 0 && notifVirtual.length === 0 && (
                <Box align="center"> 
                    <Typography color="secondary" variant={'caption'}>No notification found. </Typography> 
                </Box>
            )} 
        </>
    )
}