import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row, FormGroup, FormControl, Button, Badge  } from 'react-bootstrap';
import Notify from '../../../notification/Notify';


const PrescriptionDoctor = ({ patient_id, displayUnsave, unsaveCount, getUnsavePrescription }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const management_id = localStorage.getItem('management_id')

    const [isProcess, setIsProcess] = useState(false) 

    const addToPrecription = e => {
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);
        var error = [];
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('management_id', management_id);    
        formdata.set('patient_id', patient_id);     
        formdata.set('prescription_type', 'doctor');
        formdata.set('pharmacy_id', '');
        formdata.set('prescription', '');

        if(formdata.get('product_name').length === 0 || formdata.get('product_name').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('product_name'); 
        }

        if(formdata.get('order_qty').length === 0 || formdata.get('order_qty').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('order qty'); 
        } 

        if(formdata.get('dosage').length === 0 || formdata.get('dosage').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('dosage'); 
        }

        // if(formdata.get('take_every').length === 0 || formdata.get('take_every').trim()===''){ 
        //     error = 'error';
        //     Notify.fieldRequired('every take'); 
        // }

        // if(formdata.get('take_times').length === 0 || formdata.get('take_times').trim()===''){ 
        //     error = 'error';
        //     Notify.fieldRequired('take times'); 
        // }

        if(error.length > 0){
            Notify.consoleLog('add product')
        }else{
            setIsProcess(true)
            Axios.post('prescription/local/product-add', formdata )
            .then((response) => { 
                const data = response.data;    
                if(data === 'success'){
                    e.target.reset();
                    getUnsavePrescription()
                    Notify.successRequest('add product');
                }else{
                    Notify.warnRequest('add product');
                } 
            }).catch(error=>{  
                Notify.requestError(error);
            }).finally(() =>{
                setIsProcess(false)
            });
        }
    }

    useEffect(() =>{ },[patient_id])
    

    return (
        <Fragment>
            <Box mb={1}>
                <Typography variant="subtitle2" color="primary"> CREATE DOCTOR PRESCRIPTION </Typography>
            </Box>

            <form onSubmit={ addToPrecription }>
                <Row>   
                    <Col sm={12}>
                        <FormGroup>
                            <span className="gtc-small text-uppercase text-muted"> Prescription </span>
                            <FormControl name="product_name"/>
                        </FormGroup>
                    </Col>

                    <Col sm={6}>
                        <FormGroup>
                            <span className="gtc-small text-uppercase text-muted"> Quantity </span>
                            <FormControl type="number" min="1" name="order_qty"/>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>  
                        <FormGroup>
                            <span className="gtc-small text-uppercase text-muted"> Type </span>
                            <FormControl name="type"  as="select">
                                <option> -- Select -- </option> 
                                <option value="Bottle"> Bottle </option> 
                                <option value="Capsule"> Capsule </option> 
                                <option value="Liniment"> Liniment </option> 
                                <option value="Syrup"> Syrup </option> 
                            </FormControl> 
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <span className="gtc-small text-uppercase text-muted"> dosage </span>
                            <FormControl name="dosage"/>
                        </FormGroup>
                    </Col>
                    <Col sm={4} hidden>
                        <FormGroup>
                            <span className="gtc-small text-uppercase text-muted"> Take Every </span>
                            <FormControl name="take_every"/>
                        </FormGroup>
                    </Col>
                    <Col sm={4} hidden>
                        <FormGroup>
                            <span className="gtc-small text-uppercase text-muted"> Times </span>
                            <FormControl name="take_times"/>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <span className="gtc-small text-uppercase text-muted"> Sig. </span>
                    <FormControl name="remarks"/>
                </FormGroup> 

                <p className="m-0 mb-2 text-muted"> Are you sure to continue adding this item? </p>
                <Button 
                    type="submit" 
                    variant="success"
                    disabled = { isProcess ? true : false }
                > <FontAwesomeIcon
                        icon={ isProcess ? faSpinner : faCheckCircle} 
                        spin={ isProcess ? true : false } 
                /> Add </Button>
                <Button type="reset" variant="secondary ml-2"> <FontAwesomeIcon icon={faTimesCircle} /> No </Button>
                <Button 
                    type="button" 
                    variant="primary ml-2"
                    onClick = { displayUnsave }
                > <Badge variant="danger"> { unsaveCount } </Badge> Unsave Prescription </Button>
            </form> 
        </Fragment>
    )
}

export default PrescriptionDoctor;