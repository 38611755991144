 
import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import Axios from 'axios';
import Notify from 'src/notification/Notify'; 
import { useParams } from 'react-router-dom';
import ImagingReadByImaging from './ImagingReadByImaging';
import ImagingTeleradDetails from './ImagingTeleradDetails'; 
import CheckInternet from 'src/utils/CheckInternet';
import CheckingConnection from 'src/CheckInternet';
import NoInternet from 'src/NoInternet';
 
const ImagingSentToTelerad = () =>{  

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')  
    const { orderId } = useParams()
    const [telerad, setTelerad] = useState({
        data: [],
        ready: false
    })  
    const [selectedTelerad, setSelectedTelerad] = useState({
        data: null
    })  

    const [online, setOnline] = useState('checking')

    const getTeleradList  = useCallback( async () => {
        try {
            var formdata = new FormData()
                formdata.append('token', token)
                formdata.append('user_id', user_id) 
            const request = await Axios.post('imaging/telerad/get-teleradlist', formdata)
                setTelerad({
                    data: request.data,
                    ready: true
                }) 
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id])
    
    const validateOrderId = useCallback( async () => {
        try {
            var formdata = new FormData()
                formdata.append('token', token)
                formdata.append('user_id', user_id) 
                formdata.append('orderId', orderId)  
            await Axios.post('imaging/order/validate-order', formdata) 
        } catch (error) {
            Notify.requestError(error)
        }
    },[token, user_id, orderId])
  

    const checkinternet = () => {
        CheckInternet.online().then(() => setOnline('connected') ).catch(() => setOnline('disconnected') )
    }

    useEffect(() =>{

        checkinternet()

        if(online === 'connected'){ 
            getTeleradList()
            validateOrderId()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [online])

    if(orderId === 'all'){
        return <ImagingReadByImaging />
    } 

    return( 
        <>
            { online === 'checking' && <CheckingConnection />  }
            { online === 'disconnected' && <NoInternet />  } 
            { online === 'connected' && ( 
                <Box m={2}> 
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} lg={3}>
                            <Paper
                                component={Box}
                                p={2}
                                variant="outlined"
                            >
                                <Box mb={1}>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                        className={`gtc-uppercase`}
                                    > Telerad List </Typography>
                                </Box>
                                <Box> 
                                    <List component="div">
                                        {
                                            telerad.ready ? 
                                                telerad.data.length > 0 ?
                                                    telerad.data.map((data, index) =>(
                                                        <ListItem 
                                                            selected={ data === selectedTelerad.data }
                                                            button
                                                            key={index}  
                                                            onClick = { () => setSelectedTelerad({ data: data }) }
                                                        >
                                                            <ListItemIcon>
                                                                <Avatar> 
                                                                    { data.name.charAt() } 
                                                                </Avatar>
                                                            </ListItemIcon>
                                                            <ListItemText 
                                                                primary={ data.name }
                                                                secondary={ data.address }
                                                            />
                                                        </ListItem>
                                                    ))
                                                : Notify.noRecord()
                                            : Notify.loading()
                                        } 
                                    </List>
                                </Box>
                            </Paper>
                        </Grid>
                        
                        <Grid item xs={12} sm={8} lg={9}>
                            { selectedTelerad.data === null && ( <ImagingReadByImaging /> )}
                            { selectedTelerad.data && ( 
                                <ImagingTeleradDetails 
                                    telerad  = { selectedTelerad}
                                    orderId  = { orderId }
                                />
                            )} 
                        </Grid>
                    </Grid> 
                </Box>
            )}
        </> 
    )
}

export default ImagingSentToTelerad;
 