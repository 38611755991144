import { TableContainer, Table, TableHead, TableCell, TableRow, Typography, Box, TableBody, Card , CardHeader, CardContent, CardMedia } from '@material-ui/core';
import React  from 'react' 
import Header from './Header'
import Footer from './Footer'
import SorologyRef from '../references/Ref_Sorology';

const imageLocation = process.env.REACT_APP_API_IMAGE;

const SorologyOrder = ({ formheader,  serologyOrderDetails }) => { 

    return(
        <>
            <Card>  
                
                <Box display="flex" justifyContent="center">
                    <Box mt={2}>
                        {
                            formheader && 
                            <CardMedia
                                style={{ width : 70 }}
                                component={'img'}
                                src={`${imageLocation}laboratory/logo/${formheader.logo}`}
                            /> 
                        } 
                    </Box>
                    <Box >
                        <CardHeader
                            component={Box}
                            align="center"
                            title={ formheader && (formheader.name) }
                            subheader={ formheader && (formheader.address) }
                        /> 
                    </Box>
                </Box> 

                <CardContent>
                    <Header details={serologyOrderDetails} />
                </CardContent> 
                
                <CardContent> 
                    <Box>
                        <Typography variant="h6" align="center">
                            <b> SEROLOGY </b>
                        </Typography>
                    </Box> 
                    <TableContainer> 
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"> <b> EXAMINATION REQUESTED </b> </TableCell>
                                    <TableCell align="center"> <b> NORMAL </b> </TableCell>
                                    <TableCell align="center"> <b> TEST RESULT </b> </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>   
                                { serologyOrderDetails.data.hbsag && 
                                    <TableRow>
                                        <TableCell> 
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                Hepatitis B surface Antigen (HBsAg)
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">  
                                            { SorologyRef.normal() }
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { serologyOrderDetails.data.hbsag }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                } 
                                { serologyOrderDetails.data.hav && 
                                    <TableRow>
                                        <TableCell> 
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                HAV (Hepatitis A Virus) lgG/lgM
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">  
                                            { SorologyRef.normal() }
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { serologyOrderDetails.data.hav }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                } 
                                { serologyOrderDetails.data.hcv && 
                                    <TableRow>
                                        <TableCell> 
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                HCV (Hepatitis C Virus)
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">  
                                            { SorologyRef.normal() }
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { serologyOrderDetails.data.hcv }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                } 
                                { serologyOrderDetails.data.vdrl_rpr && 
                                    <TableRow>
                                        <TableCell> 
                                            <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                                VDRL/RPR
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">  
                                            { SorologyRef.normal() }
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                { serologyOrderDetails.data.vdrl_rpr }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box>
                        <Footer formheader = { formheader } />
                    </Box>
                </CardContent>
            </Card> 
        </>
    )
}

export default SorologyOrder;