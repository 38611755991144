import React, { Component, Fragment } from "react"
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Notify from "../../notification/Notify";

export default class CreditsTransactionLogs extends Component{
    _mounted = false;
    constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _show_dropdown: false,

            _logs: [],
            _logs_ready: false
        }
    }

    componentDidMount(){
        this._mounted = true;  
        this.getTransactionLogs();
    }

    getTransactionLogs(){
        var formdata = new FormData();
        formdata.set('token', this.state._token);
        formdata.set('user_id', this.state._user_id);

        Axios.post('gtcadmin/credits/transaction-logs', formdata )
        .then((response)=>{ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _logs: data,
                    _logs_ready: true
                })
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render(){
        return(
            <Fragment>
                <Table borderless responsive hover>
                    <thead>
                        <tr className="header-label text-uppercase">
                            <th> Ref No </th>
                            <th> User Id </th>
                            <th> Name </th>
                            <th> Transaction </th>
                            <th> Cost </th>
                            <th> Status </th>
                            <th> Created On </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state._logs_ready ? 
                                this.state._logs.length > 0 ?
                                    this.state._logs.map((data, index) =>{
                                        return(
                                            <tr className="header-label" key={index}>
                                                <td> {data.reference_no} </td>
                                                <td> {data.patient_id} </td>
                                                <td className="text-capitalize"> {data.patient_name} </td>
                                                <td className="text-capitalize"> {data.transaction_type} </td>
                                                <td> {data.transaction_cost} </td>
                                                <td> {data.transaction_status} </td>
                                                <td> {Notify.createdAt(data.created_at)} </td>
                                            </tr>
                                        )
                                    })
                                :<tr>
                                    <td colSpan={7} className="text-danger">
                                        No logs recorded.
                                    </td>
                                </tr>
                            :<tr>
                                <td colSpan={7} className="text-primary">
                                    <FontAwesomeIcon icon={faCircleNotch} spin={true} /> please wait
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </Fragment>
        )
    }
}