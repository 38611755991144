import React, { useEffect, useState, Fragment, useContext } from 'react';
import { Paper, Box, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Collapse, IconButton, TablePagination, Badge } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';
import Notify from 'src/notification/Notify';
import axios from 'axios'
import ClinicalMicroscopy from '../orderform/ClinicalMicroscopy';
import Hemathology from '../orderform/Hemathology';
import SerologyOrder from '../orderform/SerologyOrder';
import ChemistryOrder from '../orderform/ChemistryOrder';
import FecalOrder from '../orderform/FecalOrder';
import { PatientsUnRead } from 'src/ContextAPI';


const PaidOrder = ({ patient_id, getPatientsList, getPermission, permission }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [paid, setPaid] = useState([]) 

    const [selectedOrderId, setSelectedOrderId] = useState(null)

    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);

    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( category, order_id ) =>{
        let xx = unread.unviewNotif;
        let count = 0;
        for (let i = 0; i < xx.length; i++) {
            if( patient_id === xx[i].patient_id && category === xx[i].category && order_id === xx[i].order_id){
                count += 1;
            }
        } 
        
        return count;
    }

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 5));
        setPage(0);
    };

    const getpaidLaboratoryOrder = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('patient_id', patient_id);
        formdata.set('connection', 'local');

        axios.post('doctor/patient/laboratory/order/paid-list', formdata)
            .then((res) => {
                const data = res.data
                setPaid(data)
            }).catch((er) => Notify.requestError(er))
    }

    const getpaidLaboratoryOrderDetails = (orderid) => { 
        setSelectedOrderId(selectedOrderId === orderid ? null : orderid)
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('patient_id', patient_id);
        formdata.set('order_id', orderid);

        axios.post('doctor/patient/doc-notif-update', formdata)
        .then((res) => {
            getPatientsList()
            getPermission()
            getpaidLaboratoryOrder()
        }).catch((er) => Notify.requestError(er))

    }

    useEffect(() => {

        getpaidLaboratoryOrder()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient_id])

    const applyPagination = (order, page, limit) => {
        return order.slice(page * limit, page * limit + limit);
    }

    const paginatedOrder = applyPagination(paid, page, rowsPerPage);

    return (
        <>
            <Paper component={Box} variant="outlined" p={2} mb={2}>
                <Box mb={2}>
                    <Typography variant="subtitle2" color="primary" className={`gtc-uppercase`}>
                        Paid Order List
                    </Typography>
                </Box>

                <Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center"> <b> DATE </b> </TableCell>
                                    <TableCell align="center"> <b> ORDER ID </b> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    paid.length > 0 ?
                                        paginatedOrder.map((data, index) => (
                                            <Fragment key={index}>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        <IconButton
                                                            onClick={() => getpaidLaboratoryOrderDetails(data.order_id)}
                                                        >
                                                            {
                                                                Boolean(parseInt(checkPatientUnreadNotif('laboratory', data.order_id))) ?
                                                                    <Badge color="secondary" badgeContent={'new'}>
                                                                        { selectedOrderId === data.order_id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                                                    </Badge>
                                                                : (selectedOrderId === data.order_id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />) 
                                                            }
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell> {Notify.dateTimeConvert(data.created_at)} </TableCell>
                                                    <TableCell align="center"> {data.order_id.replace('order-', '')} </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={selectedOrderId === data.order_id} timeout="auto" unmountOnExit>
                                                            <>
                                                                {selectedOrderId && (
                                                                    <Box>
                                                                        <Hemathology order_id={selectedOrderId} patient_id={patient_id} />
                                                                        <ClinicalMicroscopy order_id={selectedOrderId} patient_id={patient_id} />
                                                                        <SerologyOrder order_id={selectedOrderId} patient_id={patient_id} />
                                                                        <ChemistryOrder order_id={selectedOrderId} patient_id={patient_id} />
                                                                        <FecalOrder order_id={selectedOrderId} patient_id={patient_id} />
                                                                    </Box>
                                                                )}
                                                            </>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </Fragment>
                                        ))
                                        :
                                        <TableRow>
                                            <TableCell colSpan={3} align="center">
                                                <Typography color="secondary" variant="subtitle2">
                                                    No paid order
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        labelRowsPerPage="List"
                        rowsPerPageOptions={[5, 20, 50, 100]}
                        component="div"
                        count={paid.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Box>
            </Paper>
        </>
    )
}

export default PaidOrder;   