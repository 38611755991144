import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Typography, IconButton,
    Menu,
    MenuItem,
    ListItemIcon
} from "@material-ui/core";
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import Label from 'src/utils/Label';
import TrashIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab'; 
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LaunchIcon from '@material-ui/icons/Launch';
import { useHistory } from 'react-router-dom';
import PermissionRemove from './PermissionRemove';
import PermissionApproved from './PermissionApproved';

const Permissions = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [permission, setPermission] = useState([])
    const [permissionRd, setPermissionRd] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    const history = useHistory();

    const [selectedPermission, setSelectedPermission]  =  useState({
        open: false,
        permission: null,
        doctors_id: null
    })

    const [approvePermission, setApprovePermission]  = useState({
        open: false,
        permission: null,
        doctors_id: null
    })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getPermission = useCallback( async () => {
        try {
            var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)

            const request = await Axios.post('patient/permission/permission-getList', formdata)
            setPermission(request.data)
            setPermissionRd(true)
        } catch (error) {
            Notify.requestError(error)
        }
    },[token, user_id])

    useEffect(() => {

        getPermission()

    },[getPermission])

    return( 
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
                sm={8}
            >
                <Paper
                    component={Box}
                    variant="outlined"
                    p={2}
                >
                    <Box mb={2}>
                        <Typography
                            variant="subtitle2"
                            color="textPrimary"
                        >
                            MEDICAL RECORD ACCESS
                        </Typography>
                    </Box>

                    <TableContainer> 
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> 
                                        <strong> 
                                            Doctors  
                                        </strong>
                                    </TableCell>
                                    <TableCell> 
                                        <strong> 
                                            Date  
                                        </strong>
                                    </TableCell>
                                    <TableCell> 
                                        <strong> 
                                            Status  
                                        </strong>
                                    </TableCell>
                                    <TableCell align="center"> 
                                        <strong> 
                                            Action  
                                        </strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { 
                                    permissionRd ?
                                        permission.length > 0 ?
                                            permission.map((data, index) =>(
                                                <TableRow
                                                    key={ index }
                                                    hover
                                                >
                                                    <TableCell> 
                                                        <Typography
                                                            className={`gtc-capitalize`}
                                                        >
                                                            { data.doctors_name }
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>  
                                                        { Notify.dateTimeConvert(data.created_at) } 
                                                    </TableCell>
                                                    <TableCell> 
                                                        <Typography
                                                            className={`gtc-capitalize`}
                                                        >
                                                            <Label color={data.permission_status === 'approved' ? "success" : "error"}> { data.permission_status  } </Label>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                    > 
                                                        <IconButton 
                                                            color="default" 
                                                            onClick={handleClick}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton> 
                                                        <Menu
                                                            id="simple-menu"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleClose}
                                                        >
                                                            { data.permission_status === 'approved' ? (
                                                                <MenuItem 
                                                                    onClick={ () => {
                                                                        handleClose();
                                                                        setSelectedPermission({
                                                                            open: true,
                                                                            permission: data.permission_id,
                                                                            doctors_id: data.doctors_id
                                                                        });
                                                                    }}
                                                                > 
                                                                    <ListItemIcon>
                                                                        <TrashIcon />
                                                                    </ListItemIcon>
                                                                    <Typography>
                                                                        Remove
                                                                    </Typography>
                                                                </MenuItem> 
                                                            ):(
                                                                <MenuItem 
                                                                    onClick={ () => {
                                                                        handleClose();
                                                                        setApprovePermission({
                                                                            open: true,
                                                                            permission: data.permission_id,
                                                                            doctors_id: data.doctors_id
                                                                        });
                                                                    }}
                                                                > 
                                                                    <ListItemIcon>
                                                                        <TrashIcon />
                                                                    </ListItemIcon>
                                                                    <Typography>
                                                                       Approve
                                                                    </Typography>
                                                                </MenuItem> 
                                                            )}
                                                            <MenuItem 
                                                                onClick={ () => history.push(`/app/patient/appointment/doctor/${data.doctors_id}`) }
                                                            > 
                                                                <ListItemIcon>
                                                                    <LaunchIcon />
                                                                </ListItemIcon>
                                                                <Typography>
                                                                    View Doctor
                                                                </Typography>
                                                            </MenuItem> 
                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        : 
                                        <TableRow>
                                            <TableCell colSpan={4}> 
                                                <Typography color="secondary">
                                                    No doctor(s) have access to your health record.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    : 
                                    <TableRow>
                                        <TableCell colSpan={4}> 
                                            <Typography color="primary">
                                                Loading...
                                            </Typography> 
                                        </TableCell>
                                    </TableRow>
                                } 
                            </TableBody>
                        </Table>
                    </TableContainer> 
                </Paper>
            </Grid>

            <Grid
                item
                xs={12}
                sm={4}
            > 
                <Alert 
                    severity="info" 
                    icon={ false }
                > 
                    <Typography align="center" variant="h2"> {permission.length > 0 ? permission[0].hasaccess_count : 0 } </Typography> 
                    <Typography align="center" variant="subtitle2"> Doctor(s) have access to your health records. </Typography>
                </Alert>
            </Grid>  

            {/* remove dialog */}
            { selectedPermission && ( 
            <PermissionRemove 
                reload = {getPermission}
                remove = { selectedPermission } 
                close = {() => setSelectedPermission({
                    open: false,
                    permission: null,
                    doctors_id: null
                })}  
            /> )}

            {/* remove dialog */}
            { selectedPermission && ( 
            <PermissionApproved 
                reload = {getPermission}
                approved = { approvePermission } 
                close = {() => setApprovePermission({
                    open: false,
                    permission: null,
                    doctors_id: null
                })}  
            /> )}

        </Grid> 
    )
}

export default Permissions;