
import React, { useEffect, useState } from 'react' 
import { Box, Paper } from '@material-ui/core'
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import OnlineAppointment from './OnlineAppointment';
import axios from 'axios';
import Notify from 'src/notification/Notify';

const VirtualAppointment =  () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [isonline, setInOnline] = useState(true)
    const [incomplete, setIncomplete] = useState({
        data: [],
        ready: false
    })

    const getOnlineAppointment = () => {
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);         
        formdata.set('connection', 'online');         
        
        axios.post('encoder/appointment/online/incomplete-list', formdata )
        .then( (response) => { 
            const data = response.data;     
            setIncomplete({ data: data, ready: true })  
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const CheckConnection = () =>{
        if(navigator.onLine){
            
            getOnlineAppointment()

        }else{ setInOnline(false) }
    }

    useEffect( () =>{

        CheckConnection()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isonline]) 

    return(
        <>
            { isonline && (
                <>
                   <OnlineAppointment appointment={ incomplete }  getOnlineAppointment = { getOnlineAppointment } />
                </>
            )}

            { ! isonline &&(
                <NoInternet />
            )}
        </>
    )
}

export default VirtualAppointment;

const NoInternet = () => (
    <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={'calc(100vh - 190px)'} 
        component={Paper}
        variant="outlined"
    >
        <Alert
            severity="info"
            variant="filled"
        > 
            <AlertTitle>
                No Internet Connection!
            </AlertTitle>
            Check your internet and reload this page to view virtual appointment.
        </Alert>
    </Box>
)