import React, { Fragment } from "react"  
import { Col, Row } from "react-bootstrap";
import Notify from "../notification/Notify"; 
import axios from 'axios'; 
import LoginChecker from "./LoginChecker";
import './Login.css';
import { Link } from "react-router-dom";
import { TextField, Button, InputAdornment, Box, Backdrop, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import { Alert, AlertTitle } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    }
}));

const Login = () =>{
    const classes = useStyles();
 
    const [isprocess, setIsProcess] = React.useState(false)
    const [accountInvalid, setAccountInvalid] = React.useState(null) 

    const handleLogin = (e) => {
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target); 
        var error = [];  
        
        if(formdata.get('username').length === 0 || formdata.get('username').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('username')
        } 
        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password')
        }
        if(error.length > 0){
            console.log('Unable to process login, Form has an error');
        }else{
            setIsProcess(true) 
            axios({
                url: axios.defaults.baseURL+'welcome/login', 
                method: 'post',
                data: formdata, 
            })
            .then( (response) => {   
                if(response.data.msg ==='account-invalid'){ 
                    setAccountInvalid('Check your username and password')
                    Notify.customToast('Credentials Invalid','Check your username and password.');
                }else{ 
                   localStorage.setItem('user_id', response.data[0].user_id);
                   localStorage.setItem('type', response.data[0].type);
                   localStorage.setItem('username', response.data[0].username);
                   localStorage.setItem('token', response.data[0].token);
                   localStorage.setItem('management_id', response.data[0].manage_by);
                   localStorage.setItem('is_login', response.data[0].is_login);   
                   LoginChecker(response.data[0].type)    
                    window.location.href="/app/"
                }
            }) 
            .catch(error => {
                Notify.requestError(error)
            }).finally(() =>{
                setIsProcess(false)  
            })
        }
    } 

    return(
        <Fragment>  
            {/* enable loading */}
            <Backdrop className={classes.backdrop} open={isprocess}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Col sm={9} className="mx-auto mb-2 p-0 "> 
                <Row className="mb-3">
                    <Col xs={6} sm={6} className="text-right sign-in-box">
                        <h5 className="text-dark"> Sign In </h5>
                    </Col>
                    <Col xs={6} sm={6} className="text-left">
                        <Link to="/app/registration/packages" className="text-primary pointer h5">Register</Link>
                    </Col>
                </Row>  

                <form onSubmit={ handleLogin }>
                    {
                        accountInvalid && (
                            <Alert severity="error">
                                <AlertTitle>
                                    Error
                                </AlertTitle>
                                { accountInvalid } 
                            </Alert>
                        )
                    } 
                    <Box my={4}> 
                        {/* <FormControl name="username" className="login-field" autoComplete="off" placeholder="Username" /> */}
                        <TextField   
                            name="username"
                            label={
                                <Typography color="primary"> Username </Typography>
                            }  
                            autoComplete="off" 
                            variant="outlined"
                            placeholder="Enter your username"
                            fullWidth
                            InputProps={{
                                style:{ color: grey[800] },
                                startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle color={"primary"} />
                                </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box mb={4}> 
                        {/* <FormControl type="password" className="login-field" name="password" autoComplete="off" placeholder="Password" /> */}
                        <TextField 
                            name="password"
                            label={
                                <Typography color="primary"> Password </Typography>
                            } 
                            variant="outlined"
                            type="password"
                            placeholder="Enter your password"
                            fullWidth
                            InputProps={{
                                style:{ color: grey[800] },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon color={"primary"} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box mb={4}> 
                        <Button 
                            variant="contained"
                            color="primary"
                            disabled = {isprocess}
                            type="submit" 
                            size="large"
                            fullWidth 
                        >
                            Sign In
                        </Button>
                    </Box>
                </form> 
            </Col>
        </Fragment>  
    )
}

export default Login;