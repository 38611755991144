import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@material-ui/core';
import Axios from 'axios';
import React, { useEffect, useCallback, useState } from 'react';
import Notify from 'src/notification/Notify';
import SearhcIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router-dom';

const Report = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 

    const [order, setOrder] = useState([])

    const [query, setQuery] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10); 

    const getHemaOrder = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('table', 'laboratory_hematology');    
        
        Axios.post('laboratory/order/completed/report', formdata )
        .then((response) => {  
            const data = response.data;       
            setOrder(order => order.concat(data))
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id]);

    const getSeroOrder = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('table', 'laboratory_sorology');    
        
        Axios.post('laboratory/order/completed/report', formdata )
        .then((response) => {  
            const data = response.data;        
            setOrder(order => order.concat(data))
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id]);

    const getMicroOrder = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('table', 'laboratory_microscopy');    
        
        Axios.post('laboratory/order/completed/report', formdata )
        .then((response) => {  
            const data = response.data;        
            setOrder(order => order.concat(data))
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id]);

    const getFecalOrder = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('table', 'laboratory_fecal_analysis');    
        
        Axios.post('laboratory/order/completed/report', formdata )
        .then((response) => {  
            const data = response.data;        
            setOrder(order => order.concat(data))
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id]);

    const getChemOrder = useCallback(() =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('table', 'laboratory_chemistry');    
        
        Axios.post('laboratory/order/completed/report', formdata )
        .then((response) => {  
            const data = response.data;        
            setOrder(order => order.concat(data))
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    },[token, user_id]);

    useEffect(() =>{

        getHemaOrder()
        getSeroOrder()
        getChemOrder()
        getFecalOrder()
        getMicroOrder()

    },[getHemaOrder, getSeroOrder, getChemOrder, getFecalOrder, getMicroOrder]) 

    
    const handleSearch = (event) => {
        event.persist();
        setQuery(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const applyPagination = (doctors, page, limit) => {
        return doctors.slice(page * limit, page * limit + limit);
    }

    const searchOrder = (searchOrder, query) => {
        return searchOrder.filter((data) => { 
          let matches = true;
      
          if (query) { 
            let containsQuery = false;
       
            if (data.fname.toLowerCase().includes(query.toLowerCase()) || data.lname.toLowerCase().includes(query.toLowerCase())) {
                containsQuery = true;
            } 
      
            if (!containsQuery) {
              matches = false;
            }
          } 
          return matches;
        });
    } 

    const filteredOrder = searchOrder(order, query);
    const paginateOrder = applyPagination(filteredOrder, page, limit);

    return(
        <>
            <Box
                component={Paper}
                p={2}
            >    
                <TableContainer>
                    <Box my={1} display="flex">
                        <Box flexGrow={1} mt={2}> 
                            <Typography className={`gtc-uppercase`} variant="subtitle1" color="textSecondary">
                                Laboratory Record
                            </Typography> 
                        </Box>
                        <Box>
                            <TextField
                                fullWidth
                                label="Search Patient"
                                variant="outlined"
                                margin="dense"
                                onChange={handleSearch} 
                                value={query}
                            />
                        </Box>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"> <b> Date </b> </TableCell>
                                <TableCell align="center"> <b> Patient </b> </TableCell>
                                <TableCell align="center"> <b> Order Id </b> </TableCell> 
                                <TableCell align="center"> <b> Actions </b> </TableCell> 
                            </TableRow>
                        </TableHead> 
                        <TableBody>  
                            { order.length > 0 && <RenderTable data={paginateOrder} title="Display ALll" /> }
                        </TableBody>
                    </Table>
                    <TablePagination
                        component={Box}
                        count={filteredOrder.length}
                        labelRowsPerPage="List"
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 25]}
                    />
                </TableContainer>
            </Box>
        </>
    )
}

export default Report;

const RenderTable  = ({data, title}) => {

    console.log(title)
    const history = useHistory()

    return(
        <>
            {/* <TableRow >
                <TableCell colSpan={4}>
                    <Typography variant="caption" className={`gtc-uppercase`} color="textSecondary"> { title } </Typography>
                </TableCell> 
            </TableRow>  */}

            {
                data.map((data, index) =>(
                    <TableRow key={index}>
                        <TableCell> { Notify.dateTimeConvert(data.created_at) } </TableCell>
                        <TableCell align="center" className={`gtc-capitalize`}> { `${data.fname} ${data.lname}` } </TableCell>
                        <TableCell align="center"> { data.order_id.replace('order-','') } </TableCell> 
                        <TableCell align="center"> 
                            <IconButton
                                onClick={ () => history.push(`/app/laboratory/record/print/order/${data.order_id}`)}
                                color="primary"
                            >
                                <SearhcIcon />
                            </IconButton>
                        </TableCell> 
                    </TableRow> 
                ))
            }
        </>
    )
}