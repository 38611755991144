
import Notify from 'src/notification/Notify'

const FormvalidationFecalAnalysis = (fieldData, formField) =>{ 
    var error  = [];  
    
    if(Boolean(parseInt(fieldData.fecal_analysis))){
        if( formField.get('color').length === 0 && 
            formField.get('color').trim() === '' &&
            formField.get('consistency').length === 0 && 
            formField.get('consistency').trim() === '' &&
            formField.get('pus').length === 0 && 
            formField.get('pus').trim() === '' &&
            formField.get('rbc').length === 0 && 
            formField.get('rbc').trim() === '' &&
            formField.get('fat').length === 0 && 
            formField.get('fat').trim() === '' &&
            formField.get('occult').length === 0 &&
            formField.get('occult').trim() === '' &&
            formField.get('bacteria').length === 0 &&
            formField.get('bacteria').trim() === '')
        {
            Notify.fieldRequired('Chemical Test Fields')
            error = 'error';
        }
    }  
 
    if(formField.get('fecal_result').length === 0 || formField.get('fecal_result').trim() === ''){
        Notify.fieldRequired('fecal result')
        error = 'error';
    } 

    return error;
}

export default FormvalidationFecalAnalysis;