import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Paper, Typography } from '@material-ui/core';

const imageLocation = process.env.REACT_APP_API_IMAGE;
export default class PersonalHeader extends Component {
    _mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _patient: [],
            _patient_ready: false,
            _uploadprocess: false,
        }

        this.profileimage = React.createRef()
    }

    componentDidMount() {
        this._mounted = true;
        this.getPersonalInformation();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    getPersonalInformation() {
        var formdata = new FormData();
        formdata.set('token', this.state._token);
        formdata.set('management_id', this.state._management_id);
        formdata.set('user_id', this.state._user_id);

        Axios.post('patient/information/personal-info', formdata)
            .then(function (response) {
                const data = response.data;
                if (this._mounted) {
                    this.setState({
                        _patient: data,
                        _patient_ready: true,
                    })
                }
            }.bind(this)).catch(error => {
                Notify.requestError(error);
            });
    }

    handleClickupload = () => {
        this.profileimage.click()
    }

    handleUploadImage = (event) => {
        var ext = event.target.files[0].type.split('/')[1];
        var maxfile = event.target.files[0].size / 1024 / 1024;
        var extArray = ['png', 'jpeg', 'jpg']
        var resetUpload = event.target;
        if (extArray.indexOf(ext) === -1) {
            Notify.customToast('Image Not Allowed', 'Upload PNG and JPEG only.')
            resetUpload.value = '';
        } else if (maxfile.toFixed(2) > 2) {
            Notify.customToast('Image Not Allowed', 'Image size too big, Upload 2mb or less.')
            resetUpload.value = '';
        }
        else {
            var formdata = new FormData();
            formdata.set('token', this.state._token);
            formdata.set('user_id', this.state._user_id);
            formdata.set('profile', event.target.files[0]);
            formdata.set('current_profile', this.state._patient.length > 0 ? this.state._patient[0].image !== null ? this.state._patient[0].image : '' : '');

            this.setState({ _uploadprocess: true })
            Axios.post('patient/information/personal-uploadnewprofile', formdata)
                .then(function (response) {
                    const data = response.data;
                    if (data === 'success') {
                        Notify.successRequest('profile')
                        this.getPersonalInformation()
                        resetUpload.value = '';
                    }
                }.bind(this)).catch(error => {
                    Notify.requestError(error);
                }).finally(() => {
                    this.setState({ _uploadprocess: false })
                });
        }
    }

    render() {

        return (
            <Fragment>
                <Box
                    component={Paper}
                    variant="outlined"
                    p={2}
                    mb={2}
                >
                    {
                        this.state._patient_ready ?
                            this.state._patient.length > 0 ?
                                <div className="clearfix">
                                    <div hidden={this.state._uploadprocess ? false : true} className="uploadLoading">
                                        <span>
                                            <CircularProgress color="inherit" />
                                        </span>
                                    </div>
                                    <div className="float-left gtc-profile">
                                        {
                                            this.state._patient[0].image !== null ?
                                                <img src={imageLocation + 'patients/' + this.state._patient[0].image} alt="" className="img-fluid d-flex my-auto p-profile border" />
                                                :
                                                <img src={'/no-image.png'} alt="" className="img-fluid d-flex my-auto p-profile" />
                                        }
                                        <div className="p-uploadhover text-white pointer" onClick={this.handleClickupload}>
                                            <FontAwesomeIcon size="2x" style={style.picon} icon={faCamera} />
                                        </div>
                                        <input type="file" onChange={this.handleUploadImage} className="d-none" ref={(ref) => this.profileimage = ref} />
                                    </div>

                                    <div className="ml-sm-4 float-left">
                                        <Box mb={1}>
                                            <Typography color="textSecondary" variant="caption" className={`gtc-uppercase`}>
                                                {/* {this.state._user_id} */}
                                            </Typography>
                                        </Box>
                                        <Box mb={1}>
                                            <Typography color="textPrimary" variant="h5" className={`gtc-capitalize`}>
                                                <strong> {this.state._patient[0].firstname}  {this.state._patient[0].middle}  {this.state._patient[0].lastname}  </strong>
                                            </Typography>
                                        </Box>

                                        <Box mb={1}>
                                            <Typography>
                                                &nbsp;&nbsp;<Typography variant="caption" color="textSecondary" className={`gtc-uppercase`}> Birthday: </Typography> {Notify.birthday(this.state._patient[0].birthday)}
                                            </Typography>
                                        </Box>

                                        <Box mb={1}>
                                            <Typography>
                                                &nbsp;&nbsp;<Typography variant="caption" color="textSecondary" className={`gtc-uppercase`}> Age: </Typography>  {Notify.calculateAge(this.state._patient[0].birthday)}
                                            </Typography>
                                        </Box>

                                        <Box mb={1}>
                                            <Typography>
                                                &nbsp;&nbsp;<Typography variant="caption" color="textSecondary" className={`gtc-uppercase`}> Gender: </Typography>  {this.state._patient[0].gender}
                                            </Typography>
                                        </Box>

                                        {/* <Box mb={1}>
                                            <Typography>
                                                &nbsp;&nbsp;<Typography variant="caption" color="textSecondary" className={`gtc-uppercase`}> OTP NUMBER: </Typography>  {this.state._patient[0].gender}
                                            </Typography>
                                        </Box> */}

                                    </div>
                                </div>
                                :
                                Notify.noRecord()
                            :
                            Notify.loading()
                    }
                </Box>
            </Fragment>
        )
    }
}

const style = {
    picon: {
        fontSize: '18px',
    }
}