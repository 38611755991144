import { TableContainer, Table, TableHead, TableCell, TableRow, Typography, Box, TableBody, CardContent } from '@material-ui/core';
import React from 'react'
import SorologyRef from '../../../laboratory/newlaboratory/references/Ref_Sorology';

const PatientSeroOrder = ({ serologyOrderDetails }) => {

    return (
        <>
            <CardContent>
                <Box>
                    <Typography variant="h6" align="center">
                        <b> SEROLOGY </b>
                    </Typography>
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"> <b> EXAMINATION REQUESTED </b> </TableCell>
                                <TableCell align="center"> <b> NORMAL </b> </TableCell>
                                <TableCell align="center"> <b> TEST RESULT </b> </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {serologyOrderDetails.data.hbsag &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            Hepatitis B surface Antigen (HBsAg)
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {SorologyRef.normal()}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {serologyOrderDetails.data.hbsag}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            }
                            {serologyOrderDetails.data.hav &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            HAV (Hepatitis A Virus) lgG/lgM
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {SorologyRef.normal()}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {serologyOrderDetails.data.hav}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            } 
                            {serologyOrderDetails.data.hcv &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            HCV (Hepatitis C Virus)
                                            </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {SorologyRef.normal()}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {serologyOrderDetails.data.hcv}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            } 
                            {serologyOrderDetails.data.vdrl_rpr &&
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2" className={`gtc-uppercase`}>
                                            VDRL/RPR
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        {SorologyRef.normal()}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography>
                                            {serologyOrderDetails.data.vdrl_rpr}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </>
    )
}

export default PatientSeroOrder;