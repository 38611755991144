import React, { Component, Fragment } from 'react';   
import Notify from '../../notification/Notify'; 
import {   faCircleNotch, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import Cookies from 'universal-cookie'; 
import Axios from 'axios';
import { Box, Divider, Grid, Paper, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider, 
    KeyboardDatePicker,
} from '@material-ui/pickers';

const cookies = new Cookies();

export default class PersonalBasic extends Component{
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
            _patient: this.props.patient,
            _enableedit: false,

            _birthday: new Date(this.props.patient[0].birthday),
            _subscription  : cookies.get('_subspackage'),
            _is_process: false,
            _civil_status: this.props.patient[0].civil_status,
            _bloodtype: this.props.patient[0].bloodtype,
		}
    } 
    
    componentDidUpdate(){
        if(this.state._patient !== this.props.patient){
            this.setState({
                _patient: this.props.patient
            })
        }
    }

    handleEditPersonalInfo = e => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target);  
        var error = [];
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id); 
        formdata.set('username', this.state._username);  
        formdata.set('patient', this.props.patient[0].patient_id);  
        

        if(formdata.get('firstname').length === 0 || formdata.get('firstname').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('firstname'); 
        }

        if(formdata.get('lastname').length === 0 || formdata.get('lastname').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('lastname'); 
        }
        if(formdata.get('gender').length === 0 || formdata.get('gender').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('gender'); 
        }
        if(formdata.get('birthday').length === 0 || formdata.get('birthday').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('birthday'); 
        }
        if(formdata.get('street').length === 0 || formdata.get('street').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('street'); 
        }
        if(formdata.get('barangay').length === 0 || formdata.get('barangay').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('barangay'); 
        }
        if(formdata.get('city').length === 0 || formdata.get('city').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('city'); 
        }
        if(formdata.get('civil_status').length === 0 || formdata.get('civil_status').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('civil_status'); 
        }
        if(formdata.get('occupation').length === 0 || formdata.get('occupation').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('occupation'); 
        }
        if(formdata.get('religion').length === 0 || formdata.get('religion').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('religion'); 
        }
        if(formdata.get('height').length === 0 || formdata.get('height').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('height'); 
        }

        if(formdata.get('weight').length === 0 || formdata.get('weight').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('weight'); 
        }

        if(formdata.get('blood_type').length === 0 || formdata.get('blood_type').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('blood_type'); 
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0 ){
            console.warn("Form has an error, observe please")
        }else{
            this.setState({ _is_process: true })

            Axios.post('patient/information/personal-update', formdata)
            .then((response) =>{
                const data = response.data
                if(data === 'success'){
                    this.props.getPersonalInformation()
                    this.setState({ _enableedit: false}) 
                    Notify.successRequest("personal information")
                } 
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'db-error'){
                    Notify.warnRequest('personal information')
                }
            }).catch((error) =>{
                Notify.requestError(error)
            }).finally(() =>{
                this.setState({ _is_process: false })
            })
        }
        
    } 

	render(){ 
		return(
			<Fragment>	  
                <Box
                    component={Paper}
                    variant="outlined"
                    className={this.state._enableedit ? "" : "gtc-textfield-noborder"}
                    p={2}
                >
                    <form onSubmit={ this.handleEditPersonalInfo }> 
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>   
                            <Box display="flex" mb={2}>
                                <Box flexGrow={1} mb={1}>
                                    <Typography variant="subtitle2" color="primary">
                                        PERSONAL INFORMATION
                                    </Typography>
                                </Box>
                                <Box>
                                    <Button  
                                        variant="text"
                                        color="primary"
                                        hidden={this.state._enableedit ? true : false } 
                                        onClick ={ () => this.setState({ _enableedit: true})}
                                        startIcon={ <AddCircleOutlineIcon />}
                                    >
                                        Update
                                    </Button>
                                </Box>
                            </Box>

                            <Box mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Firstname"
                                                name="firstname"
                                                variant="outlined" 
                                                defaultValue={ this.state._patient[0].firstname }
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Lastname"
                                                name="lastname"
                                                variant="outlined"
                                                defaultValue={ this.state._patient[0].lastname }
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Middle Name"
                                                name="middle"
                                                variant="outlined"
                                                defaultValue={ this.state._patient[0].middle }
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Box mt={2}>
                                            <TextField
                                                select
                                                required
                                                fullWidth
                                                label="Gender"
                                                name="gender"
                                                variant="outlined"
                                                defaultValue={ this.state._patient[0].gender } 
                                                SelectProps={{ native: true }} 
                                                InputProps={{ inputProps: { disabled : !this.state._enableedit } }}
                                            >
                                                <option value="Male"> Male</option>
                                                <option value="Female"> Female</option>
                                            </TextField>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box mt={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Age"
                                                name="age"
                                                variant="outlined"
                                                defaultValue={ this.state._patient[0].birthday === null ? 'None' : Notify.calculateAge(this.state._patient[0].birthday) }
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>  
                                        <Box>
                                            <KeyboardDatePicker
                                                fullWidth
                                                margin="normal" 
                                                label="Birthday"
                                                format="MM/dd/yyyy"
                                                name="birthday"
                                                inputVariant="outlined"
                                                value={ this.state._birthday }
                                                onChange= { (date) => this.setState({ _birthday: date })}   
                                                
                                                disabled = {!this.state._enableedit}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box> 
                            
                            <Box mb={3}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Birthplace"
                                    name="birthplace"
                                    variant="outlined"
                                    defaultValue={ this.state._patient[0].birthplace }
                                    InputProps={{ readOnly: !this.state._enableedit }}
                                />
                            </Box>

                            <Box mb={2}>
                                <Grid container spacing={2}> 
                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                multiline
                                                required
                                                fullWidth
                                                label="Street"
                                                name="street"
                                                variant="outlined"
                                                defaultValue={ this.state._patient[0].street }
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Barangay"
                                                name="barangay"
                                                variant="outlined"
                                                defaultValue={ this.state._patient[0].barangay }
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="City"
                                                name="city"
                                                variant="outlined"
                                                defaultValue={ this.state._patient[0].city }
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid> 
                            </Box>
                            
                            <Box mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Civil Status"
                                                name="civil_status"
                                                variant="outlined" 
                                                defaultValue={ this.state._patient[0].civil_status } 
                                                select
                                                InputProps={{ inputProps: { disabled : !this.state._enableedit } }}
                                                SelectProps={{ native: true }} 
                                            >
                                                <option value="Single"> Single </option>
                                                <option value="Married">  Married </option>
                                            </TextField>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Occupation"
                                                name="occupation"
                                                variant="outlined" 
                                                defaultValue={ this.state._patient[0].occupation }  
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />  
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Religion"
                                                name="religion"
                                                variant="outlined" 
                                                defaultValue={ this.state._patient[0].religion }  
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            
                            <Box mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Height (ft)"
                                                name="height"
                                                variant="outlined" 
                                                defaultValue={ this.state._patient[0].height }  
                                                type="number"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 13,
                                                        step: '0.01'
                                                    },
                                                    readOnly: !this.state._enableedit 
                                                }} 
                                            />
                                        </Box>   
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Weight (kg)"
                                                name="weight"
                                                variant="outlined" 
                                                defaultValue={ this.state._patient[0].weight }  
                                                type="number"
                                                InputProps={{
                                                    inputProps:{
                                                        min: 1,
                                                        max: 200,
                                                        step: '0.01'
                                                    },
                                                    readOnly: !this.state._enableedit
                                                }} 
                                            />
                                        </Box>   
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Blood Type"
                                                name="blood_type"
                                                variant="outlined"  
                                                select
                                                defaultValue={this.state._patient[0].blood_type}
                                                SelectProps={{ native: true }}
                                                InputProps={{ inputProps: { disabled : !this.state._enableedit } }}
                                            > 
                                                <option value="A"> A </option>
                                                <option value="B"> B </option>
                                                <option value="AB"> AB </option>
                                                <option value="O"> O </option> 
                                                <option value="A-negative"> A-Negative </option>
                                                <option value="B-positive"> B-Positive </option>
                                                <option value="B-negative"> B-Negative </option>
                                                <option value="O-positive"> O-Positive </option>
                                                <option value="O-negative"> O-Negative </option>
                                                <option value="AB-positive">AB-Positive </option>
                                                <option value="AB-negative">AB-Negative </option>
                                            </TextField>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                variant="outlined" 
                                                defaultValue={ this.state._patient[0].email }  
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Mobile"
                                                name="mobile"
                                                variant="outlined" 
                                                defaultValue={ this.state._patient[0].mobile }  
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <Box>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Telephone"
                                                name="telephone"
                                                variant="outlined" 
                                                defaultValue={ this.state._patient[0].telephone }  
                                                InputProps={{ readOnly: !this.state._enableedit }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            
                            <Box>
                                {
                                    this.state._enableedit ?
                                        <Box mb={2}>
                                            <Grid container spacing={2}>    
                                                <Grid item xs={12}>
                                                    <Box>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            label="Password"
                                                            name="password"
                                                            variant="outlined" 
                                                            type="password"
                                                        />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box my={2}>
                                                        <Divider />
                                                    </Box>

                                                    <Box display="flex">
                                                        <Button 
                                                            variant="contained"
                                                            type="submit"
                                                            color="primary"
                                                            disabled={ this.state._is_process }
                                                            startIcon={ 
                                                                <FontAwesomeIcon 
                                                                    icon={ this.state._is_process ? faCircleNotch : faCheckCircle } 
                                                                    spin={ this.state._is_process ? true : false } 
                                                                />   
                                                            }
                                                        >
                                                            Save 
                                                        </Button>

                                                        <Box ml={2}>
                                                            <Button 
                                                                variant="contained" 
                                                                onClick={()=> this.setState({ _enableedit: false })}
                                                                startIcon={ <FontAwesomeIcon icon={faTimesCircle} />  }
                                                                color="default"
                                                            > 
                                                                No 
                                                            </Button>
                                                        </Box> 
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box> 
                                    : null
                                }
                            </Box>
                        </MuiPickersUtilsProvider>    
                    </form>
                </Box>
			</Fragment>
		)
	}
}
