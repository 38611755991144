
import React, { Component, Fragment } from 'react';   
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Col } from 'react-bootstrap';
import { Box, Grid, Paper } from '@material-ui/core';
 
export default class PatientHistory extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'), 
            _patient_id : this.props.patient_id,

            _history: [],
            _history_ready: false,

            // pagination setup
            _currentPage: 1,
            _historyPerPage: 1,
		}
	}

    componentDidMount(){
        this._mounted = true;
        this.getPatientHistory();
    }

    componentWillUnmount(){
        this._mounted = false; 
    }

    getPatientHistory(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('management_id', this.state._management_id);   
        formdata.set('patient_id', this.props.patient_id);    
        
        Axios.post('doctor/patient/history/patient-history', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _history: data,
                    _history_ready: true,
                })
            }
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    handlePage(event){
        this.setState({
            _historyPerPage: Number(event.currentTarget.value)
        });
    }
    
    handleClick(event) {
        this.setState({
            _currentPage: Number(event.currentTarget.id)
        });
    }


    componentDidUpdate(){
        if(this.state._patient_id !== this.props.patient_id){
            this.setState({
                _patient_id: this.props.patient_id,
                _history_ready : false
            })
            this.componentDidMount();
        }
    }

	render(){  

        const { _history, _currentPage, _historyPerPage } = this.state;
 
        const indexOfLastTodo = _currentPage * _historyPerPage;
        const indexOfFirstTodo = indexOfLastTodo - _historyPerPage;
        const current_history = _history.slice(indexOfFirstTodo, indexOfLastTodo);
        
        const pageNumbers = []; 

        for (let i = 1; i <= Math.ceil(_history.length / _historyPerPage); i++) {
          pageNumbers.push(i);
        }  

		return(
			<Fragment>	    
                <Paper component={Box} variant="outlined" p={2}> 
                    {
                        this.state._history_ready ? 
                            current_history.length > 0 ? 
                                current_history.map( (data, index) => { 
                                    return(
                                        <Box key={index}>
                                            <Box mb={2}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={6}>
                                                        <span className="text-uppercase small text-muted"> Weight </span>
                                                            <p className="m-0"> 
                                                                { data.weight === null ? 'None' : 
                                                                    <span> { data.weight } kg</span>
                                                                }
                                                            </p>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <span className="text-uppercase small text-muted"> Height </span>
                                                            <p className="m-0"> 
                                                                { data.height === null ? 'None' : 
                                                                    <span> { data.height } ft.</span> 
                                                                } 
                                                            </p>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            <Box mb={2} >
                                                <Grid container>
                                                    <Grid item xs={12} sm={6}>
                                                        <span className="text-uppercase small text-muted"> occupation </span>
                                                        <p className="m-0"> 
                                                            { data.occupation === null ? 'None' : 
                                                                <span> { data.occupation }</span>
                                                            }
                                                        </p>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <span className="text-uppercase small text-muted"> zip </span>
                                                        <p className="m-0"> 
                                                            { data.zip === null ? 'None' : 
                                                                <span> { data.zip } </span> 
                                                            } 
                                                        </p>
                                                    </Grid>  
                                                </Grid>
                                            </Box>

                                            <Box mb={2}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={6}>
                                                        <span className="text-uppercase small text-muted"> Allergies </span>
                                                        <p className="m-0"> 
                                                            { data.allergies === null ? 'None' : 
                                                                <span> { data.allergies }</span>
                                                            }
                                                        </p>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <span className="text-uppercase small text-muted"> Medication </span>
                                                        <p className="m-0"> 
                                                            { data.medication === null ? 'None' : 
                                                                <span> { data.medication } </span> 
                                                            } 
                                                        </p>
                                                    </Grid> 
                                                </Grid>
                                            </Box>

                                            <Grid item xs={12} sm={12}>
                                                <span className="text-uppercase small text-muted"> addresss </span>
                                                <p className="m-0"> 
                                                    { data.city === null ? 'None' : 
                                                        <span> { data.street } { data.barangay } { data.city }</span>
                                                    }
                                                </p>
                                            </Grid>  
                                        </Box>
                                    )
                                })
                            : Notify.noRecord()
                        : Notify.loading()
                    } 
                </Paper> 
                {/* pagination */}
                <Col sm={12} className="mt-3">
                    {
                        pageNumbers.length > 1 ? 
                            <ul className="pagination justify-content-center m-0"> 
                                {
                                    pageNumbers.map(page => {
                                        return (  
                                            <li 
                                                className={page === _currentPage ? "page-item pointer active" : "page-item pointer"} 
                                                key={page}
                                                id={page}
                                                onClick={this.handleClick.bind(this)}
                                            >
                                                <span className="page-link">
                                                    {page} 
                                                </span>
                                            </li>  
                                        );
                                    })
                                } 
                                
                            </ul>
                        :null
                    }
                </Col>
			</Fragment>
		)
	}
}
