import React, { Component, Fragment } from "react";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default class RouteDoctor extends Component{
    _isMounted = false;
    constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
        }
    }

    componentDidMount(){
        this._isMounted = true;  
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){
        return(
            <Fragment>
                <Col sm={12} className="mt-3">
                    <Row>
                        <Col sm={9}>
                            <Alert variant="primary border-primary"> Jhomar Baluca is requesting for an account registrration </Alert>
                            <Card className="bg-light mb-3">
                                <Card.Header> Credit Loadout Monitoring </Card.Header>
                                <Card.Body>
                                    <h1>  Map should display in here </h1>
                                </Card.Body>
                            </Card>

                            <Card className="bg-light">
                                <Card.Header> Users Monitoring </Card.Header>
                                <Card.Body>
                                    <h1>  Map should display in here </h1>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={3}>
                            <Card className="bg-light mb-3 border border-success">
                                <Card.Header className="bg-success text-white"> Total Credit Loaded </Card.Header>
                                <Card.Body>
                                    <h1 className="font-weight-bold text-center"> 1000 </h1>
                                </Card.Body>
                            </Card>

                            <Card className="bg-light mb-3">
                                <Card.Header> Credit Transaction </Card.Header>
                                <Card.Body>
                                    <div className="p-2 mb-2 border"> transaction number </div> 
                                    <div className="p-2 mb-2 border"> transaction number </div> 
                                    <div className="p-2 mb-2 border"> transaction number </div> 
                                    <div className="p-2 mb-2 border"> transaction number </div> 
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Fragment>
        )
    }
}