
import React, {  useEffect, useRef, useState } from 'react';
import { Box, Button, CardMedia, CircularProgress, FormHelperText, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Grid, IconButton, TextField, Tooltip, Typography, Badge } from "@material-ui/core";     
import Notify from 'src/notification/Notify';
import { useHistory, useParams } from 'react-router-dom';
import { blue, deepOrange, green, purple, red } from '@material-ui/core/colors';
import { Alert, AlertTitle } from '@material-ui/lab';
import OnlinechecupChat from 'src/oncheckupchat/OnlinechecupChat';
import PermissionRequest from './PermissionRequest';
import PatientHeader from '../patients/PatientHeader';
import ChatIcon from '@material-ui/icons/QuestionAnswer';
import ProfileIcon from '@material-ui/icons/PermContactCalendar'; 
import HighlightOff from '@material-ui/icons/HighlightOff' 
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeOutlined from '@material-ui/icons/HomeOutlined';
import axios from 'axios';
import * as Yup from 'yup'; 
import { Formik } from 'formik';
import CheckIcon from '@material-ui/icons/CheckCircleOutline' 
import ClearIcon from '@material-ui/icons/HighlightOff'; 

const RTCMultiConnection = require('rtcmulticonnection');

const CreateRoomOLd = () => { 
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')
    const history = useHistory()

    const myvideoRef = useRef(null)  
    const clientvideoRef = useRef(null) 

    const [ validreq, setValidReq] = useState(false)
    const [ prepared, setPrepared] = useState(false)
    const [ ishost , setIsHost ] = useState(false)

    const [callTimeMinutes, setCallTimeMinutes] = useState(0)
    const [callTimeSeconds, setCallTimeSeconds] = useState(0)
    const [refDetails, setRefDetails] = useState(null)
    const [drawer, setDrawer] = useState('') 

    const [hasPermit, setHasPermitted] = useState(null)
    const [doneDialog, setDoneDialog] = useState(false)
    const [buttonExitDisabled, setButtonExitDisabled] = useState(true)


    const [ exitDialog, setExitDialog] = useState(false)

    const [remoteId, setRemoteId] = useState(null)
    const [con, setCon] = useState(null)
    const { ref_number } = useParams()  

    const [unreadMsg, setUnreadMsg] = useState(0)

    const room_number = Notify.randomizeString(ref_number) 

    var timeInterval = null; 
    var msgInterval = null; 
    var connection = null;

    const getRefDetails = () =>{ 
        var formdata = new FormData();   
            formdata.append('ref_number' , ref_number)
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.set('connection', 'online');    
            
        axios.post('doctor/online/appointment/getrefdetails', formdata )
        .then( (response) => { 
            const data = response.data;    
            if(Object.keys(data).length > 0){
                createVCallRoom(data)
                setRefDetails(data)
                intializeGetUnreadMsgInterval(data.patient_id)
            }else{
                history.push('/app/pagenotfound')
            }
        }).catch(error=>{  
            Notify.requestError(error);
        });
    }

    const createVCallRoom = (details) =>{ 
        var formdata = new FormData();   
            formdata.append('ref_number' , ref_number)
            formdata.append('room_number', room_number)
            formdata.append('token', token)
            formdata.append('doctors_userid', user_id)
            formdata.append('patient_userid', details.patient_id)
            formdata.set('connection', 'online');    
            
        axios.post('doctor/online/appointment/vcall/createroom', formdata )
        .then( (response) => { 
            const data = response.data;    
            if(data === 'success'){ 
                checkMicandVideo(details)
            }else{
                history.push('/app/pagenotfound')
            }
        }).catch(error=>{  
            Notify.requestError(error);
        });
    }

    const checkMicandVideo  =  (details) => { 
        navigator.mediaDevices.getUserMedia({video:true, audio: true})
        .then(() => {
            setValidReq(true)
            setPrepared(true)
            initializeCall()
            getProfilePermission(details) 
        })
        .catch(() =>{ 
            setValidReq(false)
            setPrepared(true)
            Notify.customToast('Camera/Mic Not Found.', 'Enable your camera to continue.')
        }) 
    }   
 
    const initializeCall = () =>{
        connection = new RTCMultiConnection(); 
            connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';
            connection.enableLogs = false; 
            connection.userid = `${username}`;
            connection.autoCreateMediaElement = false;
            connection.autoCloseEntireSession = false;
            connection.maxParticipantsAllowed = 2;
            connection.session = {
                audio : true,
                video : true
            } 

            connection.iceServers = [];

            connection.iceServers.push({
                urls: 'stun:global.stun.twilio.com:3478?transport=udp', 
                url: 'stun:global.stun.twilio.com:3478?transport=udp', 
            });

            connection.iceServers.push({
                urls: 'turn:global.turn.twilio.com:3478?transport=udp',
                credential: 'Z+l8d6zA8FfuBj4nZXw1D1RIJQtpKlztDUuGsLd20XM=',
                username: '7655d2535654422e5c043aa6266f07b10c10a5efca11802386959bc729d57831'
            });  
            
            connection.sdpConstraints.mandatory = {
                OfferToReceiveAudio: true,
                OfferToReceiveVideo: true,
            }          

            connection.onstream = (event) => {  
                setIsHost(connection.isInitiator)

                if (event.type === 'local') { 
                    var video = myvideoRef.current; 

                        video.srcObject = event.stream;
                        video.play()  
                }
            
                if (event.type === 'remote') {  
                    startCallTimer()  
                    setRemoteId(event.userid)
                    setTimeout(() => {
                        setButtonExitDisabled(false)
                    }, 5000); 
                    
                    var videox = clientvideoRef.current;  
                    videox.srcObject = event.stream;
                    videox.play()  
                }
            };

            connection.onleave = function(event) {
                var remoteUserId = event.userid;  
                Notify.customToast(`${remoteUserId} is disconnected.`)
                clearInterval(timeInterval)
            }; 
            
            var alreadyAllowed = {};

            connection.onNewParticipant = function(participantId, userPreferences) { 
                if(alreadyAllowed[participantId]) {
                    connection.addParticipationRequest(participantId, userPreferences);  
                    return;
                }

                connection.acceptParticipationRequest(participantId, userPreferences); 
                console.log(participantId)
                var message = `${participantId} is joining.`;
                Notify.customToast('Joining', message)    
            };  

        connection.open(room_number);  
        setCon(connection)
    }

    const getProfilePermission = (details) =>{
        var formdata = new FormData();   
            formdata.set('token', token);  
            formdata.set('doctors_id', user_id);    
            formdata.set('patient_id', details.patient_id);    
            formdata.set('connection', 'online');    
            
        axios.post('doctor/online/checkup/check-profilepermission', formdata )
        .then( (response) => { 
            const data = response.data;   
            if(data.length > 0){ setHasPermitted(true) }
            else{ setHasPermitted(false) }
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    }

    const removeRoom = () => {   
        var formdata = new FormData();   
            formdata.set('token', token);  
            formdata.set('user_id', user_id);    
            formdata.set('app_ref_number', ref_number);    
            formdata.set('connection', 'online');    
            
        axios.post('doctor/online/appointment/delete-room', formdata )
        .then( (response) => { 
            const data = response.data;   
            if(data === 'success'){  
                Notify.customToast('Room Deleted', 'Room Successfully Destroy.')
            }
        }).catch(error=>{ 
            Notify.requestError(error);
        });  
    } 

    const getLatestMessageFromPatient = (patientidssss) => {
        var formdata = new FormData();
        formdata.append('token', token)
        formdata.append('user_id', user_id)
        formdata.append('senders_id', patientidssss)
        formdata.set('connection', 'online');

        axios.post('doctor/get/all-unread-msg', formdata)
        .then((response) => {
            const data = response.data;
            setUnreadMsg(parseInt(data.length))
        }).catch(error => {
            Notify.requestError(error);
        });
    }

    const intializeGetUnreadMsgInterval = (patientidssss) =>{ 
        msgInterval = setInterval(() => {  
            getLatestMessageFromPatient(patientidssss)
        }, 5000)
    }

    useEffect(() =>{       

        getRefDetails()   

        return(() => { 
            removeRoom()
            clearInterval(timeInterval)
            clearInterval(msgInterval)
        }) 
        
         // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])     

    const endCall = () =>{ 
        con.deletePeer(remoteId);
        con.closeSocket();
        con.getAllParticipants().forEach(function(participantId) {
            con.disconnectWith( participantId );
        });  
    } 

    const calculateTime = () =>{
        var x = parseInt(callTimeMinutes) / 60;
        var min = x < 10 ? `0${Math.floor(x)}` : Math.floor(x); 
        return min;
    }

    const startCallTimer = () => {   
        timeInterval = setInterval(() => {    
            setCallTimeSeconds((callTimeSeconds) => callTimeSeconds >= 59 ? 0 : callTimeSeconds + 1)   
            setCallTimeMinutes((callTimeMinutes) => callTimeMinutes + 1)  
        }, 1000); 
    }  

    const  getFormData = (object) => {
        const formData = new FormData(); 

        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    } 
    
    const checkProfilePermission = () =>{
        setDrawer('drawer-profile')
        if(refDetails){
            getProfilePermission(refDetails)
        }
    }

    return(

        <>     
            <Dialog
                open = { true } 
                disableBackdropClick 
                disableEscapeKeyDown
                fullScreen 
            >
                 <DialogContent style={{ backgroundColor : '#000' }}>     
                    {
                        prepared ? (
                            <>
                                {  validreq && (
                                    <Grid container> 
                                        <Grid item xs={12} sm={12} md={6}> 
                                            {drawer === 'drawer-chat' &&(
                                                <Box>
                                                    <Drawer variant="persistent" anchor={'left'} open={drawer === 'drawer-chat'} onClose={ () => setDrawer('')}>
                                                        <Box width={ window.innerWidth > 900 ? window.innerWidth - 350 : window.innerWidth }>
                                                            <OnlinechecupChat connection = "online" client_id = {refDetails && refDetails.patient_id } close={ () => setDrawer('')} />
                                                        </Box>
                                                    </Drawer> 
                                                </Box>
                                            )} 

                                            <Box>
                                                <Drawer variant="persistent" anchor={'left'} open={drawer === 'drawer-profile'} onClose={ () => setDrawer('')}>
                                                    <Box width={ window.innerWidth > 900 ? window.innerWidth - 350 : window.innerWidth }>
                                                        <Box display="flex" mb={1}>
                                                            <Box flexGrow={1} mt={2} ml={2}>
                                                                <Typography variant="h6"> 
                                                                    <b> Patient Profile </b>
                                                                </Typography>
                                                            </Box>
                                                            <Box p={1}>
                                                                <IconButton 
                                                                    color="secondary"
                                                                    onClick={ () => setDrawer('') }
                                                                >
                                                                    <HighlightOff />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                        { hasPermit && ( <PatientHeader connection = "online" patient_id = {refDetails && refDetails.patientId} /> )} 
                                                        { ! hasPermit && (  <PermissionRequest connection = "online" patient_id = {refDetails && refDetails.patient_id} closeTab = { () => setDrawer('drawer-chat')} /> )} 
                                                    </Box>
                                                </Drawer> 
                                            </Box> 
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Box 
                                                display="flex"
                                                justifyContent="center" 
                                                alignItems="center"
                                                height={'calc(100vh - 50px)'}  
                                            > 
                                                    <>  
                                                        <video   // myvideo tag
                                                            playsInline
                                                            style={
                                                                window.innerWidth < 600 || drawer !== '' ? { 
                                                                    position: 'absolute',
                                                                    maxWidth: '100px',
                                                                    top: '320px',
                                                                    right:'24px', 
                                                                    border: `2px solid ${blue[500]}`,
                                                                    borderRadius: '10px',
                                                                    zIndex: 1
                                                                }: {    
                                                                    position: 'absolute',
                                                                    maxWidth: '200px',
                                                                    bottom: '30px',
                                                                    right:'30px', 
                                                                    border: `2px solid ${blue[500]}`,
                                                                    borderRadius: '10px',
                                                                    zIndex: 1
                                                                }
                                                            } 
                                                            ref={ myvideoRef } 
                                                            muted 
                                                        />  

                                                        {/* client video */} 
                                                        <Box >    
                                                            <Box>
                                                                <CardMedia
                                                                    component="div"  
                                                                >
                                                                    <video  
                                                                        playsInline 
                                                                        style={
                                                                            window.innerWidth < 600 || drawer !== '' ? {    
                                                                                position: 'absolute',
                                                                                maxWidth: '290px',
                                                                                top: '170px',
                                                                                right:'30px', 
                                                                                border: `2px solid ${blue[500]}`,
                                                                                borderRadius: '10px',
                                                                            }:{             
                                                                                maxWidth: window.innerWidth > 600 ? '500px' : window.innerWidth , 
                                                                                objectFit: 'cover',
                                                                                border: `2px solid ${blue[500]}`,
                                                                                borderRadius: '3px',
                                                                                bottom: '200px',
                                                                                right:'30px',   
                                                                            }
                                                                        }
                                                                        className={'img-fluid'}
                                                                        ref={ clientvideoRef } 
                                                                    /> 
                                                                </CardMedia>
                                                            </Box>     

                                                            <Box 
                                                                display="flex" 
                                                                my={2}   
                                                                style={ 
                                                                    window.innerWidth < 600 || drawer !== '' ? { 
                                                                        position: "absolute",
                                                                        top: 400,
                                                                        right: 10, 
                                                                        justifyContent:"center"
                                                                    } : { 
                                                                        justifyContent:"center" ,
                                                                    }
                                                                }
                                                            >
                                                                <Box m={1} mt={2}>
                                                                    <Tooltip title="Timer" arrow>
                                                                        <Typography color="primary" variant="h5"> 
                                                                            { calculateTime() } : {callTimeSeconds > 10 ? callTimeSeconds : `0${callTimeSeconds}`} 
                                                                        </Typography>
                                                                    </Tooltip>
                                                                </Box> 

                                                                <Box m={1}>
                                                                    <Tooltip title="Chat" arrow>
                                                                        <IconButton 
                                                                            style={{ 
                                                                                backgroundColor: deepOrange[500], 
                                                                                color: '#fff' 
                                                                            }}
                                                                            onClick={ () => setDrawer('drawer-chat') }
                                                                        >
                                                                            <Badge badgeContent={unreadMsg} color="secondary">
                                                                                <ChatIcon />
                                                                            </Badge>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>

                                                                <Box m={1}>
                                                                    <Tooltip title="Profile" arrow>
                                                                        <IconButton 
                                                                            onClick={ checkProfilePermission } 
                                                                            style={{ backgroundColor: green[500], color: '#fff' }}
                                                                        >
                                                                            <ProfileIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>

                                                                <Box m={1}>
                                                                    <Tooltip title="Leave room" arrow>
                                                                        <Box>
                                                                            <IconButton 
                                                                                style={{ backgroundColor: red[buttonExitDisabled ? 200 : 500], color: '#fff' }}
                                                                                onClick={ () => setExitDialog(true) }
                                                                                disabled={ buttonExitDisabled }
                                                                            >
                                                                                <ExitToAppIcon />
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Tooltip>
                                                                </Box> 

                                                                { ishost && (
                                                                    <Box m={1}>
                                                                        <Tooltip title="Room Creator" arrow>
                                                                            <Box>
                                                                                <IconButton   
                                                                                    style={{ backgroundColor: purple[200], color: '#fff' }}  
                                                                                    onClick={ () =>{
                                                                                        endCall()
                                                                                        setTimeout(() => {
                                                                                            history.push('/app/doctor')
                                                                                        }, 2000);
                                                                                    }}
                                                                                >
                                                                                    <HomeOutlined />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Tooltip>
                                                                    </Box> 
                                                                )} 
                                                            </Box> 
                                                        </Box> 
                                                    </> 
                                            </Box>
                                        </Grid>
                                    </Grid> 
                                )}   
                                
                                {  ! validreq && ( 
                                    //  invalid requirements 
                                    <Box
                                        display="flex"
                                        justifyContent="center" 
                                        alignItems="center"
                                        height={'calc(100vh - 50px)'}
                                        border={1}
                                    >    
                                        <Box>
                                            <Alert severity="info"> 
                                                <AlertTitle>
                                                    Camera Or Microphone Error !
                                                </AlertTitle>
                                                Enable your camera and microphone to continue.
                                            </Alert>
                                        </Box>     
                                    </Box>
                                )}
                            </>
                        ) : (
                            //  preparing requirements 
                            <Box
                                display="flex"
                                justifyContent="center" 
                                alignItems="center"
                                height={'calc(100vh - 50px)'}
                                border={1}
                            >    
                                <Box alignItems="center" align="center">
                                    <CircularProgress size={30} color="primary" />
                                    <Typography color="primary" align="center"> preparing... </Typography>
                                </Box>     
                            </Box>
                        )
                    }
                </DialogContent>  
            </Dialog>

            {/* delete and exit room dialog */}
            <Dialog
                open = { exitDialog } 
                onClose = { () => setExitDialog(false) }
                disableBackdropClick 
                disableEscapeKeyDown
                maxWidth="xs"
                fullWidth
            >
                <DialogContent>
                    <Box>
                        <Typography>
                            Are you sure to set this appointment done and delete this room?
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="default" 
                        startIcon={ <ClearIcon /> } 
                        onClick={ () => setExitDialog(false)  }
                    >
                        No
                    </Button>  

                    <Button 
                        variant="contained"
                        color="primary"
                        startIcon={ <CheckIcon /> } 
                        onClick={() => { 
                            endCall();
                            setDoneDialog(true)
                            setExitDialog(false)
                        }}
                    >
                        {`Yes`}
                    </Button>  
                </DialogActions>
            </Dialog>

            {/* dialog for setappointment done */}
            <Dialog
                open = { doneDialog } 
                onClose = { () => setDoneDialog(false) }
                disableBackdropClick 
                disableEscapeKeyDown
            >
                <DialogTitle>
                    Set Appointment Done
                </DialogTitle>

                <Formik 
                    initialValues={{
                        username: username,
                        user_id: user_id, 
                        token: token, 
                        app_doneon: new Date().toLocaleString(), 
                        app_consumetime: `${calculateTime()} : ${callTimeSeconds > 10 ? callTimeSeconds : `0${callTimeSeconds}`} `, 
                        message: 'Thank you for having an appointment with me.', 
                        app_id: refDetails && refDetails.appointment_id, 
                        app_ref_number: refDetails && refDetails.reference_no, 
                        patient_id: refDetails && refDetails.patientId, 
                        connection: 'online', 
                    }}
                    validationSchema={Yup.object().shape({  
                        app_doneon: Yup.string().trim().required('Appointment done on is required.'), 
                        message: Yup.string().trim().required('Message is required.'), 
                        app_id: Yup.string().trim().required('Appointment id is required.'), 
                        app_ref_number: Yup.string().trim().required('Appointment reference is required.'), 
                    })}
                    onSubmit={async (values, {
                        setErrors, 
                        setSubmitting,
                        resetForm
                    }) => {  
                        try { 
                            const request = await axios.post('doctor/online/appointment/setas-done', getFormData(values))  
                            if(request.data === 'success'){
                                Notify.successRequest('Appointment Done')
                                setDoneDialog(false) 
                                history.push('/app/doctor')
                                resetForm();  
                            }
                        } catch (error) {
                            const message = error.message || 'Something went wrong';  
                            setErrors({ submit: message });
                            setSubmitting(false);
                        }
                    }}
                    >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values
                    }) => (
                        <form
                            noValidate 
                            onSubmit={handleSubmit}  
                        >    
                            <DialogContent dividers>

                                <Box mb={2}>
                                    <TextField 
                                        fullWidth
                                        required
                                        error={Boolean(touched.app_doneon && errors.app_doneon)}
                                        helperText={touched.app_doneon && errors.app_doneon}  
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        value={values.app_doneon} 
                                        name="app_doneon"
                                        label="Appointment Done On"
                                        variant="outlined"  
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box> 

                                <Box mb={2}>
                                    <TextField 
                                        fullWidth
                                        required
                                        error={Boolean(touched.app_consumetime && errors.app_consumetime)}
                                        helperText={touched.app_consumetime && errors.app_consumetime}  
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        value={values.app_consumetime} 
                                        name="app_consumetime"
                                        label="Call Consumed"
                                        variant="outlined"  
                                        InputProps={{ readOnly: true }}
                                    />
                                </Box>  

                                <Box mb={2}>
                                    <TextField 
                                        fullWidth
                                        required
                                        error={Boolean(touched.message && errors.message)}
                                        helperText={touched.message && errors.message}  
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        value={values.message} 
                                        name="message"
                                        label="Message"
                                        variant="outlined"   
                                        multiline
                                    />
                                </Box>  

                                {errors.submit && (
                                    <Box mt={3}>
                                        <FormHelperText error>
                                        {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}
                            </DialogContent>

                            <DialogActions>   
                                <Button
                                    variant="contained"
                                    color="default" 
                                    startIcon={ <ClearIcon /> } 
                                    onClick={ () => setDoneDialog(false)  }
                                >
                                    No
                                </Button>  

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={ <CheckIcon /> }
                                    disabled={isSubmitting}  
                                >
                                    {`Save & Exit`}
                                </Button>  
                            </DialogActions> 
                        </form>
                    )}
                </Formik>  
            </Dialog>
        </>
    )
}

export default CreateRoomOLd; 
 