import React, { Component, Fragment } from "react"
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Notify from "../../notification/Notify";

export default class CreditsLoadout extends Component{
    _mounted = false;
    constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),
            _patient_id : this.props.patient_id,
            _purchase_on: new Date(),
            _is_process: false,

        }
    }

    componentDidUpdate(){
        if(this.state._patient_id !== this.props.patient_id){
            this.setState({
                _patient_id: this.props.patient_id,
                _patient_name: this.props.patient_name
            })

            this.componentDidMount()
        }
    }

    componentDidMount(){
        this._mounted = true;  
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    handleLoadout = (e) =>{
        e.preventDefault();
        e.persist();
        var formdata = new FormData(e.target);
        formdata.set('token', this.state._token);
        formdata.set('user_id', this.state._user_id);
        formdata.set('username', this.state._username);
        formdata.set('patient_id', this.props.patient_id);
        var error = [];

        if(formdata.get('account_number').length === 0 || formdata.get('account_number').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('account number'); 
        }

        if(formdata.get('trace_number').length === 0 || formdata.get('trace_number').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('trace number'); 
        }

        if(formdata.get('credit').length === 0 || formdata.get('credit').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('credit'); 
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }
        if(error.length > 0){
            Notify.consoleLog('loadout save')
        }else{
            this.setState({
                _is_process: true
            })

            Axios.post('gtcadmin/credits/loadout-save', formdata )
            .then((response)=>{ 
                const data = response.data;   
                if(this._mounted){ 
                    this.setState({
                        _is_process: false
                    })
                    if(data === 'pass-inv'){
                        Notify.fieldInvalid('password')
                    }
                    if(data==='db-error'){
                        Notify.warnRequest('loadout save')
                    }
                    if(data === 'success'){
                        this.props.displayLoadoutLogs();
                        e.target.reset();
                        Notify.successRequest('loadout save')
                    }
                }
            }).catch(error=>{ 
                this.setState({
                    _is_process: false
                })
                Notify.requestError(error);
            });
        }
    
    }

    render(){
        return(
            <Fragment>
                <Form onSubmit={this.handleLoadout}>
                    <FormGroup>
                        <span className="text-muted text-uppercase gtc-small"> Patient Name </span>
                        <FormControl value={this.props.patient_name}  readOnly/>
                    </FormGroup>

                    <FormGroup>
                        <span className="text-muted text-uppercase gtc-small"> Patient User ID </span>
                        <FormControl value={this.props.patient_id} readOnly/>
                    </FormGroup>
                    
                    <FormGroup>
                        <span className="text-muted text-uppercase gtc-small"> Patient Account number </span>
                        <FormControl name="account_number"/>
                    </FormGroup>

                    <FormGroup>
                        <span className="text-muted text-uppercase gtc-small"> gtc Trace Number </span>
                        <FormControl name="trace_number"/>
                    </FormGroup>

                    <FormGroup>
                        <span className="text-muted text-uppercase gtc-small"> Credit </span>
                        <FormControl name="credit" type="number" min="1" step="0.01"/>
                    </FormGroup>

                    <FormGroup>
                        <span className="text-muted text-uppercase gtc-small"> Enter your password </span>
                        <FormControl type="password" name="password"/>
                    </FormGroup>

                    <p className="m-0 mb-2 text-muted"> Are you sure to continue this loadout? </p>
                    <Button
                        disabled={this.state._is_process ? true : false }
                        variant="success" 
                        type="submit"
                    ><FontAwesomeIcon 
                        icon={this.state._is_process ? faCircleNotch : faCheckCircle }
                        spin={this.state._is_process ? true : false }
                    /> Save </Button>
                    <Button variant="secondary ml-2"> <FontAwesomeIcon icon={faTimesCircle} /> No </Button>
                </Form>
            </Fragment>
        )
    }
}