import { Box, Tabs, Tab, Paper } from '@material-ui/core';
import React, {useState, useEffect} from 'react';  
import PatientDetailsHeader from './PatientDetailsHeader';
import TabPanel from 'src/utils/TabPanel';
import TodayIcon from '@material-ui/icons/Today';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'; 
import PatientDetailsAppointment from './PatientDetailsAppointment';
import PatientDetailsProfile from './PatientDetailsProfile';

const PatientDetails = ({ patient_id }) => { 

    const [category, setCategory] = useState('appointment'); 

    const handleChange = (event, newCategory) => {
        event.persist()
        setCategory(newCategory);
    }; 

    useEffect(( ) => {} , [patient_id ])

    return(
        <> 
            <PatientDetailsHeader patient_id = { patient_id } />
            <Box 
                mt={2}
                component={Paper}
                variant="outlined" 
            >
                <Tabs 
                    value={category}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"  
                >
                    <Tab wrapped value="appointment" label="Appointment" icon={ <TodayIcon /> } />
                    <Tab wrapped value="profile" label="Profile" icon={ <AssignmentIndIcon /> } /> 
                </Tabs> 
            </Box>

            <TabPanel value={category} index="appointment">
                <PatientDetailsAppointment patient_id = { patient_id } />
            </TabPanel>

            <TabPanel value={category} index="profile">
                <PatientDetailsProfile patient_id = { patient_id } />
            </TabPanel> 
        </>
    )
}

export default PatientDetails;