import React, { Component, Fragment } from 'react'; 
import axios from 'axios';  
import { Modal } from 'react-bootstrap';
import LoginChecker from './LoginChecker';
import HashLoader from "react-spinners/HashLoader";

class Logout extends Component {  
	constructor(props) {
		super(props);
		this.state = {
			token: localStorage.getItem('token'), 
			user_id: localStorage.getItem('user_id'),
		};  
	} 

	logout(){
		var formData = new FormData();
			formData.set('user_id', this.state.user_id);
			formData.set('token', this.state.token); 
		axios.post('welcome/logout', formData) 
		.then(function (response){     
			if(response.data.message ==='logout-success'){ 
				LoginChecker(''); 
			}
		}) 
		.catch(error => { 
			console.log(error)
		}).finally(() =>{
			localStorage.removeItem('token')
			localStorage.removeItem('is_login')
		})
	}
	
	componentDidMount(){
		this.logout();
	}
	
	render(){
		return (
			<Fragment> 
				 <Modal id="logoutDialog" size="sm" centered show={true} backdrop="static" keyboard={false}> 
					{/* <div className="text-center text-primary mt-3"> 
						<Spinner variant="primary" animation="grow" />
						<br />
						Logging out, please wait...
					</div> */}
					<div className="text-white text-center">
						<div className="d-flex mx-auto mb-2" style={{width: '55px'}}>
							<HashLoader
								loading={true}
								size={50}
								color={"#fff"}
							/>
						</div>
						<b>Signing out</b>
					</div>
				 </Modal>
			</Fragment>
		);
	}
}   
  

export default Logout;