import { Component } from 'react';
   
export default class DonMarcelino extends Component{ 
    static dvoOccidentalDonMarcelino(){
        var  donMarcelinoData = {
            "type":"FeatureCollection",
            "features":[
            {
                "type":"Feature",
                "id":"1",
                "properties":{
                    "barangay":"Baluntaya",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental"
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.642732,6.242228], [125.653289,6.218934], [125.641813,6.214499], [125.623017,6.203126], [125.587656,6.172992], [125.600766,6.199735], [125.627938,6.223618], [125.640998,6.236139]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"2",
                "properties":{
                    "barangay":"Calian",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental"
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.674430,6.141185], [125.705331,6.139137], [125.704948,6.137920], [125.704678,6.136770], [125.704511,6.135850], [125.704168,6.134065], [125.704183,6.132798],
                        [125.704010,6.131456], [125.704037,6.131577], [125.703693,6.129624], [125.703527,6.128609], [125.702953,6.126909], [125.702902,6.126617], [125.703080,6.126351],
                        [125.703336,6.126135], [125.703310,6.125894], [125.703310,6.125526], [125.703387,6.125234], [125.703642,6.124917], [125.703604,6.124574], [125.703586,6.124260],
                        [125.703432,6.124206], [125.702250,6.124403], [125.702088,6.124323], [125.702007,6.124125], [125.702025,6.123847], [125.701907,6.123668], [125.701592,6.123560],
                        [125.701023,6.123533], [125.701056,6.123074], [125.701305,6.123010], [125.701432,6.123004], [125.701547,6.122953], [125.701616,6.122708], [125.701673,6.122594],
                        [125.701801,6.122638], [125.701884,6.122752], [125.701935,6.123222], [125.701967,6.123292], [125.702043,6.123273], [125.702484,6.123019], [125.702509,6.123082],
                        [125.702541,6.123812], [125.702599,6.123907], [125.702988,6.124028], [125.703086,6.124052], [125.703249,6.123872], [125.703348,6.123450], [125.703357,6.123441],
                        [125.703474,6.123639], [125.703511,6.122580], [125.703489,6.120970], [125.703607,6.120373], [125.704487,6.118089], [125.706065,6.116915], [125.706216,6.116520],
                        [125.706248,6.116200], [125.706248,6.116050], [125.706570,6.115474], [125.706763,6.115260], [125.707096,6.114545], [125.708083,6.113414], [125.708695,6.113019],
                        [125.708695,6.113019], [125.709522,6.112635], [125.709522,6.112635], [125.709876,6.112347], [125.710166,6.112229], [125.712900,6.112551], [125.713212,6.112541],
                        [125.713770,6.112156], [125.713952,6.111932], [125.713899,6.111633], [125.712772,6.109648], [125.712686,6.109200], [125.712739,6.107930], [125.701290,6.108484],
                        [125.691624,6.108667], [125.684217,6.110313], [125.671268,6.109248], [125.672851,6.114782], [125.673387,6.124987], [125.675710,6.138212]
                    ]]
                } 
            },
            {
                "type":"Feature",
                "id":"3",
                "properties":{
                    "barangay":"Dalupan",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.623017,6.203126], [125.587656,6.172992],[125.629280,6.176658],[125.627392,6.181352]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"4",
                "properties":{
                    "barangay":"Kinanga",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.653289,6.218934], [125.662084,6.203303], [125.669122,6.202791], [125.680881,6.202961], [125.701652,6.204582], [125.701695,6.205307], [125.701909,6.205905],
                        [125.702725,6.209574], [125.703498,6.211621], [125.703626,6.212432], [125.702768,6.214437], [125.702468,6.215077], [125.700761,6.216296], [125.700434,6.216568],
                        [125.699286,6.218615], [125.698996,6.219138], [125.698503,6.219725], [125.698235,6.220429], [125.698117,6.221111], [125.698106,6.221719],
                        [125.697988,6.222210], [125.663553,6.220227]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"5",
                "properties":{
                    "barangay":"Kiobog",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.529040,6.050037], [125.526636,6.049012], [125.525092,6.045598], [125.524920,6.038599], [125.559939,6.037915], [125.615559,6.033133], [125.644224,6.035868],
                        [125.686111,6.038596], [125.701735,6.040472], [125.700916,6.041715], [125.700358,6.041800], [125.700188,6.042346], [125.699763,6.043719], [125.699823,6.044292],
                        [125.700021,6.044579], [125.700005,6.044730], [125.699732,6.044926], [125.699307,6.045197], [125.699277,6.045831], [125.699414,6.046178], [125.699596,6.046405],
                        [125.699649,6.046780], [125.699766,6.047227], [125.700226,6.047352], [125.700316,6.047505], [125.700280,6.047648], [125.699550,6.048231], [125.699477,6.048465],
                        [125.699622,6.048859], [125.699770,6.049098], [125.700157,6.050267], [125.700286,6.050494], [125.700377,6.050509], [125.700476,6.050501], [125.700787,6.050358],
                        [125.701105,6.050313], [125.701545,6.050818], [125.701553,6.051029], [125.701378,6.051331], [125.701401,6.051859], [125.701500,6.052629], [125.701318,6.052961],
                        [125.700809,6.053096], [125.700157,6.053458], [125.699899,6.053511], [125.699687,6.053579], [125.699239,6.054462], [125.699239,6.054462], [125.699224,6.055118],
                        [125.699626,6.056287], [125.700036,6.056416], [125.700506,6.056778], [125.700529,6.056906], [125.700286,6.057585], [125.693504,6.056352], [125.674989,6.057668],
                        [125.639025,6.057098], [125.618268,6.055649], [125.588772,6.055529], [125.585738,6.054322], [125.565978,6.051326]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"6",
                "properties":{
                    "barangay":"Lanao",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.660769,6.183563], [125.660769,6.176201], [125.661166,6.172043], [125.661380,6.172188], [125.692565,6.171095], [125.698587,6.171504], [125.712336,6.171898],
                        [125.712304,6.172368], [125.712274,6.172745], [125.712122,6.173409], [125.712077,6.173877], [125.712107,6.174118], [125.712107,6.174299], [125.711227,6.174918],
                        [125.709907,6.175038], [125.709588,6.175234], [125.709361,6.175808], [125.709224,6.176773], [125.708815,6.177693], [125.708375,6.178131], [125.708344,6.178704],
                        [125.707935,6.179519], [125.707540,6.180197], [125.707100,6.180605], [125.706463,6.183094], [125.706296,6.183169], [125.705689,6.183154], [125.704758,6.183594],
                        [125.703980,6.184748], [125.703814,6.185591], [125.703763,6.186955], [125.684488,6.185725], [125.667831,6.183849]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"7",
                "properties":{
                    "barangay":"Lapuan",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.712344,6.171970], [125.697747,6.171564], [125.691951,6.171013], [125.661395,6.172233], [125.661161,6.172007], [125.629440,6.176550], [125.587656,6.172992], 
                        [125.586267,6.163152], [125.591074,6.139343], [125.625577,6.142245], [125.670937,6.141727], [125.705438,6.139084], [125.707276,6.142534], [125.707051,6.145329],
                        [125.707824,6.148657], [125.709198,6.149425], [125.709328,6.150141], [125.709510,6.151469], [125.710057,6.153400], [125.709875,6.155270], [125.710117,6.155874],
                        [125.710824,6.157159], [125.710776,6.157809], [125.711444,6.158895], [125.711504,6.159740], [125.711383,6.162335], [125.711901,6.163348], [125.710824,6.165240],
                        [125.711066,6.166265], [125.711188,6.167834], [125.712584,6.170429], [125.712523,6.171515] 
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"8",
                "properties":{
                    "barangay":"Lawa (Poblacion)",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" /**/
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.701652,6.204582], [125.680881,6.202961], [125.669122,6.202791], [125.662084,6.203303], [125.662682,6.198653], [125.661708,6.198402], [125.660264,6.185774],
                        [125.660769,6.183621], [125.667770,6.183800], [125.703675,6.187011], [125.704134,6.191171], [125.704106,6.194801], [125.703759,6.194438], [125.703567,6.194442],
                        [125.703576,6.194640], [125.703783,6.194963], [125.703747,6.195088], [125.703558,6.195160], [125.703260,6.195608], [125.703183,6.195835], [125.703171,6.195994],
                        [125.703413,6.195899], [125.703840,6.195474], [125.704102,6.195195], [125.703891,6.197352], [125.703521,6.198312], [125.702835,6.198743], [125.702222,6.199415],
                        [125.701906,6.200375]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"9",
                "properties":{
                    "barangay":"Linadasan",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.703964,6.083179], [125.684131,6.083833], [125.641902,6.084089], [125.684131,6.083833], [125.637181,6.084515], [125.595345,6.081996], [125.534611,6.073854],
                        [125.534868,6.070355], [125.533667,6.066343], [125.529890,6.058832], [125.531242,6.055562], [125.531360,6.053366], [125.528808,6.050060], [125.565758,6.051581],
                        [125.585764,6.054322], [125.588622,6.055438], [125.621553,6.056020], [125.639763,6.056984], [125.673614,6.057733], [125.693015,6.056536], [125.700399,6.057730],
                        [125.700035,6.061128], [125.699815,6.065985], [125.700682,6.071655], [125.702487,6.079622], [125.703642,6.081416]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"10",
                "properties":{
                    "barangay":"Mabuhay",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.674287,6.141349], [125.675488,6.138276], [125.673083,6.123256], [125.672910,6.115062], [125.671021,6.109601], [125.646644,6.113700], [125.616088,6.120699],
                        [125.592055,6.131964], [125.591025,6.139132], [125.625358,6.142376]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"11",
                "properties":{
                    "barangay":"North Lamidan",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.660424,6.111466], [125.652566,6.098440], [125.655828,6.097415], [125.668187,6.097074], [125.706706,6.093380], [125.707930,6.095516], [125.710076,6.096796],
                        [125.711514,6.096334], [125.713942,6.098326], [125.714063,6.099774], [125.714063,6.099774], [125.714366,6.101404], [125.715155,6.102912], [125.713395,6.105507],
                        [125.713386,6.105964], [125.713053,6.106870], [125.712810,6.107594], [125.712689,6.107805] ,[125.712725,6.107929], [125.702235,6.108511], [125.691506,6.108724],
                        [125.684210,6.110346], [125.671293,6.109194]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"12",
                "properties":{
                    "barangay":"Nueva Villa",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                    [125.653289,6.218934], [125.641813,6.214499], [125.623017,6.203126], [125.627583,6.181461], [125.629299,6.176683], [125.661142,6.172158], [125.660713,6.176595],
                        [125.660714,6.183849], [125.660285,6.185811], [125.661573,6.198441], [125.662603,6.198696], [125.662088,6.203304]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"13",
                "properties":{
                    "barangay":"South Lamidan",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.652087,6.098557], [125.649921,6.095829], [125.637219,6.084562], [125.642220,6.084087], [125.683966,6.083782], [125.703360,6.083374], [125.704483,6.087840],
                        [125.706015,6.091291], [125.706627,6.093321], [125.690499,6.094438], [125.667023,6.096774], [125.655897,6.097484], [125.652018,6.098600]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"14",
                "properties":{
                    "barangay":"Talagutong (Poblacion)",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.697724,6.224264], [125.697603,6.225411], [125.695479,6.227522], [125.695115,6.229091], [125.694265,6.231384], [125.693476,6.232651],[125.692687,6.233858],
                        [125.691413,6.238262], [125.690988,6.240072], [125.688803,6.244778], [125.687528,6.246709], [125.686739,6.247493], [125.685768,6.250872],
                        [125.685283,6.251596], [125.684980,6.252501], [125.681884,6.254492], [125.680792,6.255517], [125.676422,6.256724], [125.674237,6.256543], [125.671930,6.259137],
                        [125.669988,6.265351], [125.669685,6.269815], [125.670535,6.273797], [125.670717,6.275124], [125.669624,6.276210], [125.667621,6.277718], [125.666832,6.280614],
                        [125.666225,6.282243], [125.664951,6.283208], [125.664283,6.284294], [125.663373,6.286043], [125.662887,6.287431], [125.662098,6.288094], [125.660763,6.290025],
                        [125.660460,6.292377], [125.660096,6.294006], [125.659185,6.295032], [125.658853,6.295080], [125.658088,6.288333], [125.642732,6.242228], [125.653289,6.218934],
                        [125.663553,6.220227],[125.697988,6.222210]
                    ]]
                }
            },
            {
                "type":"Feature",
                "id":"15",
                "properties":{
                    "barangay":"West Lamidan",
                    "municipality":"Don Marcelino",
                    "city":"Davao Occidental" 
                },
                "geometry":{
                    "type":"Polygon",
                    "coordinates":[[
                        [125.592141,6.132061], [125.616104,6.120864], [125.646272,6.113831], [125.660997,6.111533], [125.652189,6.098473], [125.650168,6.095889], [125.637320,6.084695],
                        [125.618411,6.083116], [125.595315,6.082256], [125.534688,6.073787], [125.533534,6.075510], [125.532234,6.079242], [125.532234,6.081682], [125.532090,6.080533],
                        [125.532090,6.083117], [125.531946,6.084552], [125.530069,6.088014], [125.531368,6.092160], [125.534833,6.096897], [125.535699,6.100198], [125.535699,6.102638]
                    ]]
                }
            }
        ]};
        return donMarcelinoData;
    }
}
 