import React, { Fragment, useState, useEffect, useContext } from 'react'; 
import Badge from '@material-ui/core/Badge'; 
import Tabs from '@material-ui/core/Tabs';  
import Tab from '@material-ui/core/Tab'; 
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box'; 
import PatientAppointmentLocal from './PatientAppointmentLocal';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import PatientAppointmentVirtual from './PatientAppointmentVirtual'; 
import TabPanel from '../../utils/TabPanel';  
import { PatientsUnRead } from 'src/ContextAPI';

function PatientAppointment({ patient_id, connection }) { 

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))

    const [category, setCategory] = useState(0); 
    const [locallength, setlocallength] = useState(0); 
    const [virtuallenght, setvirtuallenght] = useState(0); 
    
    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( notifFrom, category, department ) =>{
        
        let xx = unread.unviewNotif;
        let yy = unread.unviewNotifVirtual;
        let count = 0;

        if(notifFrom === 'local'){
            for (let i = 0; i < xx.length; i++) {
                if( patient_id === xx[i].patient_id &&  category === xx[i].category && department === xx[i].department){
                    count += 1;
                }
            }
        }else{
            for (let i = 0; i < yy.length; i++) {
                if(patient_id === yy[i].patient_id && category === yy[i].category && department === yy[i].department){
                    count += 1;
                }
            }
        }
        
        return count;
    }

    const getappointmentLocalRecordCount = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('patient_id', patient_id);    
        formdata.set('connection', connection);    
        
        Axios.post('doctors/appointment/patients/appointmentrecord-local', formdata)
        .then( (response) => { 
            const data = response.data;    
            setlocallength(data.length)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }  

    const getappointmentVirtualRecordCount = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('patient_id', patient_id);     
        formdata.set('connection', connection);    
        
        Axios.post('doctors/appointment/patients/appointmentrecord-virtual', formdata)
        .then( (response) => { 
            const data = response.data;     
            setvirtuallenght(data.length)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const handleChange = (event, newCategory) => {
        event.persist()
        setCategory(newCategory);
    };

    useEffect(()=>{
        getappointmentLocalRecordCount()
        getappointmentVirtualRecordCount()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[patient_id])

    return (
        <Fragment>  
            <Box  display="flex" mb={2}> 
                <Box flexGrow={1} />  
                <Box component={Paper} variant="outlined" px = {3}>
                    <Tabs  
                        value={category}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"  
                    > 
                        <Tab 
                            wrapped
                            label={
                                <Badge 
                                    color="secondary"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal:'left'
                                    }} 
                                    badgeContent={
                                        Boolean(parseInt(checkPatientUnreadNotif('local','appointment', 'local-appointment'))) ?
                                            'new' : locallength 
                                    }
                                > Local </Badge>
                            } 
                        />  
                        <Tab 
                            wrapped
                            label={
                                <Badge 
                                    color="secondary"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal:'left'
                                    }} 
                                    badgeContent={
                                        Boolean(parseInt(checkPatientUnreadNotif('virtual','appointment', 'virtual-appointment'))) ?
                                            'new' : virtuallenght 
                                    }
                                > Virtual </Badge> 
                            } 
                        /> 
                    </Tabs> 
                </Box> 
            </Box> 

            <Box>
                <TabPanel 
                    value={ category } 
                    index={0}
                >
                    <PatientAppointmentLocal connection={ connection } patient_id = {patient_id} />
                </TabPanel>  

                <TabPanel 
                    value={ category } 
                    index={1}
                >
                    <PatientAppointmentVirtual connection={ connection } patient_id = {patient_id} /> 
                </TabPanel>  
            </Box>
        </Fragment>
    )
}

export default PatientAppointment;
