import React, { Fragment, useState, useEffect } from 'react';
import Notify from '../../notification/Notify';
import Axios from 'axios';
import InboxIcon from '@material-ui/icons/Inbox';
import SendIcon from '@material-ui/icons/Send';
import { List, ListItem, ListItemIcon, ListItemText, Avatar, Typography } from '@material-ui/core';
import NotificationsDetails from './NotificationsDetails';

function Notifications(props) {
    
    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id')) 

    const [category, setcategory] = useState('patient-list')

    const [selectednotifid, setselectednotifid] = useState(null)
    const [selectednotifstatus, setselectednotifstatus] = useState(null) 
    const [selectednotifpatient, setselectednotifpatient] = useState(null)

    const [notification, setnotification] = useState([])
    const [notificationrd, setnotificationrd] = useState(false)

    const getAppointmentDetails = () =>{
        var formdata = new FormData();
        formdata.set('token', token); 
        formdata.set('user_id', user_id);        
        formdata.set('connection', props.connection);        
        
        Axios.post('doctor/appointment/virtual/notification-list', formdata )
        .then( (response) =>{ 
            const data = response.data;     
            setnotification(data)
            setnotificationrd(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const handleSelectednotifId = (e) =>{
        setselectednotifid(e.currentTarget.getAttribute('notifid'))
        setselectednotifpatient(e.currentTarget.getAttribute('notifpatientname'))
        setselectednotifstatus(e.currentTarget.getAttribute('notifisread'))
        setcategory('patient-details')
    }

    const handleResetDisplay = () =>{
        setselectednotifid(null)
        setselectednotifpatient(null)
        setselectednotifstatus(null)
        setcategory('patient-list')
        getAppointmentDetails()
    }

    useEffect(() => {
        
        getAppointmentDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {
                category === 'patient-list' ? 
                <List>   
                    {
                        notificationrd ? 
                            notification.length > 0 ?
                                notification.map((data, index) =>{
                                    return ( 
                                        <ListItem 
                                            button key={index}
                                            className={ data.notification_type === 'inbox' ? data.is_read ? "text-muted" : "text-primary" : "text-muted"}
                                            notifid={data.notif_id}
                                            notifpatientname={data.patients_name}
                                            notifisread={data.is_read}
                                            onClick={ handleSelectednotifId }
                                        >
                                            <ListItemIcon>  
                                                <Avatar 
                                                    className={ data.notification_type === 'inbox' ? data.is_read ? "bg-secondary" : "bg-primary" :"bg-secondary"  }
                                                >
                                                    {data.notification_type === "sent" ? <SendIcon style={{ fontSize: '17px'}} /> : <InboxIcon style={{ fontSize: '17px'}} /> }
                                                </Avatar> 
                                            </ListItemIcon>
                                            <ListItemText 
                                                primary={
                                                    <>
                                                        <Typography variant="body2">
                                                            { data.patients_name }    
                                                        </Typography> 
                                                        <Typography variant="caption"> { Notify.dateTimeConvert(data.created_at) }  </Typography> 
                                                    </>
                                                }
                                                secondary={ 
                                                    <Typography variant="caption"> { data.notification_msg.substring(0, 20) }... </Typography>
                                                } 
                                            />
                                        </ListItem>
                                    )
                                })
                            : Notify.noRecord()
                        : Notify.loading()
                    }
                </List>
                :  <NotificationsDetails 
                    notif_id = {selectednotifid}    
                    notif_status = {selectednotifstatus}     
                    notif_patient = { selectednotifpatient }
                    resetNotificationDisplay = { handleResetDisplay }
                    connection = { props.connection }
                />
            }
        </Fragment>
    )
}

export default Notifications;
