import React from 'react'
import { Box, Button, FormHelperText, TextField } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'
import ClearIcon from '@material-ui/icons/HighlightOff';
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import * as Yup from 'yup';
import { Formik } from 'formik'; 

const NewSpecializationEdit = ({ selectedSpecialName, selectedSpecialID, close, getSpecializationList }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const getFormData = (object) => {
        const formData = new FormData();
        formData.set('oldspecialization', selectedSpecialName);
        formData.set('spec_id', selectedSpecialID);
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    return (
        <>
            <Formik
                initialValues={{
                    user_id: user_id,
                    token: token,
                    username: username,
                    newspecialization: selectedSpecialName,
                    password: ''
                }}
                validationSchema={Yup.object().shape({
                    newspecialization: Yup.string().required(),
                    password: Yup.string().required()
                })}
                onSubmit={async (values, {
                    setErrors,
                    setSubmitting,
                    resetForm
                }) => {
                    try {
                        const request = await Axios.post('gtcadmin/doctors/new-specialization-edit', getFormData(values))
                        if (request.data === 'pass-invalid') {
                            setErrors({ submit: 'Password is invalid.' });
                            Notify.fieldInvalid('password')
                        }
                        if (request.data === 'success') {
                            Notify.successRequest('new specialization')
                            resetForm();
                            setSubmitting(true);
                            close();
                            getSpecializationList();
                        }
                    } catch (error) {
                        const message = error.message || 'Something went wrong';
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <Box mb={2}>
                            <TextField
                                error={Boolean(touched.newspecialization && errors.newspecialization)}
                                helperText={touched.newspecialization && errors.newspecialization}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                defaultValue={values.newspecialization}
                                fullWidth
                                required
                                name="newspecialization"
                                label="Edited Specialization"
                                variant="outlined"
                            />
                        </Box>

                        <Box mb={2}>
                            <TextField
                                fullWidth
                                required
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                name="password"
                                label="Enter your password"
                                variant="outlined"
                                type="password"
                            />
                        </Box>

                        {errors.submit && (
                            <Box mt={3} >
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}

                        <Box mb={2} display="flex">
                            <Box flexGrow={1} />

                            <Button
                                variant="contained"
                                color="default"
                                type="reset"
                                startIcon={<ClearIcon />}
                                onClick={close}
                            >
                                Close
                                </Button>
                            <Box ml={2}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CheckIcon />}
                                    disabled={isSubmitting}
                                >
                                    Update
                                    </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default NewSpecializationEdit; 