import React, { Fragment, useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'; 
import Axios from 'axios';
import Notify from './Notify';

function GTCPackageCountdown({ expiredOn }) {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))

    var interval = null; 

    const [currentDate, setcurrentDate] = useState({
        days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0
    }) 

    const dateTimeRemaining = (endDate) =>{ 
        var dateEnd = endDate.replace(/-/g, '/'); 
        interval = setInterval(() => {   
            if(parseFloat(new Date().getTime()) > parseFloat(new Date(dateEnd).getTime())){ 
                subscriptionExpired()
                setcurrentDate({ days: 0, hours: 0, minutes: 0, seconds: 0}) 
            }else{ 
                var delta = Math.abs(new Date(dateEnd) - new Date()) / 1000;

                var days = Math.floor(delta / 86400);
                delta -= days * 86400;

                var hours = Math.floor(delta / 3600) % 24;
                delta -= hours * 3600;

                var minutes = Math.floor(delta / 60) % 60;
                delta -= minutes * 60;

                var seconds = Math.floor( delta % 60); 

                setcurrentDate({ days, hours, minutes, seconds}) 
            }

        }, 1000);
         
    }  
    
    const subscriptionExpired = () =>{ 
        var formdata = new FormData();  
        formdata.set('token', token);  
        formdata.set('user_id', user_id);       
        
        Axios.post('patient/subscription/set-subscriptionexpired', formdata )
        .then((response)=>{  
            clearInterval(interval)
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    }

    
    useEffect(() =>{
        console.log('subscriptiondate: ', expiredOn)
        dateTimeRemaining(expiredOn)  

        return (()=> clearInterval(interval) )

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <Box display="flex" justifyContent="center" align="center"> 
                <Box borderRadius="50%" align="center" width={100} p={1}>
                    <Typography variant="h3">
                        { currentDate.days } 
                    </Typography>
                    <Typography style={style.datelable}>
                        Day{ currentDate.days > 1 ? 's' : ''} 
                    </Typography>
                </Box> 


                <Box mx={2} borderRadius="50%" align="center" width={100} p={1}>
                    <Typography variant="h3">
                        { currentDate.hours } 
                    </Typography>
                    <Typography style={style.datelable}>
                        Hour{ currentDate.hours > 1 ? 's' : ''} 
                    </Typography>
                </Box> 


                <Box mx={2} borderRadius="50%" align="center" width={100} p={1}>
                    <Typography variant="h3">
                        { currentDate.minutes } 
                    </Typography>
                    <Typography style={style.datelable}>
                        Minute{ currentDate.minutes > 1 ? 's' : ''} 
                    </Typography>
                </Box> 

                <Box borderRadius="50%" align="center" width={100} p={1}>
                    <Typography variant="h3">
                        { currentDate.seconds } 
                    </Typography>
                    <Typography style={style.datelable}>
                        Second{ currentDate.seconds > 1 ? 's' : ''} 
                    </Typography>
                </Box>  
            </Box>
        </Fragment>
    )
}

export default GTCPackageCountdown;

const style = {
    capitalize: {
        textTransform: 'capitalize'
    },
    datelable:{
        textTransform: 'uppercase',
        color: '#888',
        fontSize: '.9em',
    }
}