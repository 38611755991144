
import React from 'react'
import { Dialog, DialogContent, DialogTitle, Typography, Button, Box, TextField, FormHelperText, DialogActions } from '@material-ui/core';
import { Formik } from 'formik';
import CheckIcon from '@material-ui/icons/CheckCircleOutline' 
import ClearIcon from '@material-ui/icons/HighlightOff'; 
import Axios from 'axios';
import * as Yup from 'yup'; 
import Notify from 'src/notification/Notify';

const DoctorsServiceNew = ({ open, close, doctorUserId, getOnlineService }) =>{
    const token  = localStorage.getItem('token')
    const username  = localStorage.getItem('username')

    const convertToFormdata = (object) => {
        const formData = new FormData();
            formData.append('token', token) 
            formData.append('username', username) 
            formData.append('doctorUserId', doctorUserId)

            Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    return(
        <Dialog
            disableBackdropClick 
            open={ open }
            onClose={ close }
        >
            <DialogTitle
                disableTypography
            >
                <Typography
                    className={`gtc-uppercase`}
                >
                    NEW SERVICE
                </Typography>
            </DialogTitle> 

            <Formik 
                initialValues={{
                    service: '',
                    amount: '',
                    password: '',
                }}
                validationSchema={Yup.object().shape({ 
                    service: Yup.string().required(),
                    amount: Yup.number().min(1).max(1000).required(),
                    password: Yup.string().required(),
                })}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting, 
                    resetForm
                }) => {   
                    try { 
                        const request = await Axios.post('gtcadmin/doctors/newservice-save', convertToFormdata(values)) 
                        if(request.data === 'pass-invalid'){ 
                            setErrors({ submit: 'Password is invalid.' });
                            Notify.fieldInvalid('password')
                        }
                        if(request.data === 'success'){
                            getOnlineService()
                            resetForm()
                            close() 
                            setSubmitting(true);
                            Notify.successRequest('new service')
                        }
                    } catch (error) {
                        const message = error.message || 'Something went wrong'; 
                        setStatus({ success: false });
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }}
                >
                {({ 
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                        noValidate 
                        onSubmit={handleSubmit}  
                    >   
                        <DialogContent dividers>  
                            <Box mb={2}>
                                <TextField 
                                    fullWidth
                                    required 
                                    error={Boolean(touched.service && errors.service)}
                                    helperText={touched.service && errors.service}  
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values.service}
                                    name="service"
                                    label="Service"
                                    variant="outlined" 
                                />
                            </Box>

                            <Box mb={2}>
                                <TextField 
                                    fullWidth
                                    required 
                                    error={Boolean(touched.amount && errors.amount)}
                                    helperText={touched.amount && errors.amount}  
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values.amount}
                                    name="amount"
                                    label="Amount"
                                    variant="outlined" 
                                    type="number"
                                />
                            </Box>  

                            <Box mb={2}>
                                <TextField 
                                    fullWidth
                                    required
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}  
                                    onBlur={handleBlur}
                                    onChange={handleChange} 
                                    value={values.password} 
                                    name="password"
                                    label="Password"
                                    variant="outlined" 
                                    type="password"
                                />
                            </Box>  

                            {errors.submit && (
                                <Box mt={3}>
                                    <FormHelperText error>
                                    {errors.submit}
                                    </FormHelperText>
                                </Box>
                            )}
                        
                        </DialogContent> 
                        <DialogActions>   
                            <Button
                                variant="contained"
                                color="default"
                                onClick = { close }
                                startIcon={ <ClearIcon /> }
                                size="large"
                            >
                                Close
                            </Button> 
                            <Box ml={2}>   
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={ <CheckIcon /> }
                                    size="large"
                                    disabled={isSubmitting}  
                                >
                                    Save
                                </Button> 
                            </Box> 
                        </DialogActions>
                    </form>
                )}
            </Formik> 
        </Dialog>
    )
}

export default DoctorsServiceNew;