
import { Box, Dialog, Grid, TextField, Typography, Button, DialogTitle, DialogContent, DialogActions, Tooltip, CircularProgress, InputAdornment } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import Notify from 'src/notification/Notify'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import DraggableDialog from 'src/utils/DraggableDialog'
import { useHistory } from 'react-router-dom'
import FormvalidationClinicMicroscopy from './validation/FormvalidationClinicMicroscopy';


const ClinicalMicroscopyOrderDetails = ({ order, getLabCliniclMicroscopyOrder, resetDisplay }) => {

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const history = useHistory()
    const [orderDetails, setOrderDetails] = useState({
        data: null,
        ready: false
    })

    const [pendingDialog, setPendingDialog] = useState(false)
    const [processDialog, setProcessDialog] = useState(false)

    const [pendingSubmitting, setPendingSubmitting] = useState(false)
    const [processSubmitting, setProcessSubmitting] = useState(false)
    const [resultSubmitting, setResultSubmitting] = useState(false)

    const getLabCMicroscopyOrderDetails = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', order.order_id);
        Axios.post('laboratory/order/ordernew-clinicalmicroscopy/details', formdata)
            .then((response) => {
                const data = response.data;
                setOrderDetails({
                    data: data,
                    ready: true
                })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {

        getLabCMicroscopyOrderDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order])

    const handleSaveResult = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', order.order_id);
        formdata.set('patient_id', orderDetails.data.patient_id)
        formdata.set('doctor_id', orderDetails.data.doctor_id)

        var error = [];

        if (orderDetails.data) {
            error = FormvalidationClinicMicroscopy(orderDetails.data, formdata);
        }

        if (error.length > 0) {
            console.log("Form has an error.")
        } else {
            setResultSubmitting(true)
            Axios.post('laboratory/order/ordernew-clinicalmicroscopy/save-process-result', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        Notify.successRequest('order result added.')
                        setTimeout(() => {
                            history.push(`/app/laboratory/record/print/order/${order.order_id}`)
                        }, 5000);
                    }
                }).catch(error => {
                    Notify.requestError(error);
                });
        }
    }

    const handlePendingOrder = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.set('order_id', order.order_id);

        var error = [];

        if (error.length > 0) {
            console.log("Form has an error.")
        } else {
            setPendingSubmitting(true)
            Axios.post('laboratory/order/ordernew-clinicmicroscopy/save-setpending', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'success') {
                        getLabCliniclMicroscopyOrder()
                        resetDisplay()
                        setPendingDialog(false)
                        Notify.successRequest('order pending')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => setPendingSubmitting(false));
        }
    }

    const handleProcessOrder = (e) => {
        e.preventDefault()
        e.persist()

        var formdata = new FormData(e.target)
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('username', username);
        formdata.set('order_id', order.order_id);

        var error = [];

        if (error.length > 0) {
            console.log("Form has an error.")
        } else {

            setProcessSubmitting(true)
            Axios.post('laboratory/order/ordernew-clinicalmicroscopy/save-setprocessing', formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === 'pass-invalid') {
                        Notify.fieldInvalid('password')
                    }
                    if (data === 'success') {
                        getLabCliniclMicroscopyOrder()
                        setProcessDialog(false)
                        resetDisplay()
                        Notify.successRequest('order pending')
                    }
                }).catch(error => {
                    Notify.requestError(error);
                }).finally(() => setProcessSubmitting(false));
        }
    }

    return (
        <>
            <form onSubmit={handleSaveResult}>
                <Card elevation={0}>
                    <CardHeader
                        component={Box}
                        align="center"
                        title="DONASCO DIAGNOSTIC LABORATORY"
                        subheader="2f. Planlaque Bldg., Papaya St. General Santos City"
                    />
                    <CardContent>
                        {/* paitent information */}
                        <Box display="flex">
                            <Box flexGrow={1} mb={2}>
                                <Box mb={2}>
                                    <Typography>
                                        <Typography variant="caption" className="font-weight-bold">
                                            NAME:
                                        </Typography>  {`${order.firstname} ${order.lastname}`}
                                    </Typography>
                                </Box>

                                <Box display="flex" mb={2}>
                                    <Box>
                                        <Typography>
                                            <Typography variant="caption" className="font-weight-bold">
                                                AGE:
                                            </Typography>  {order.birthday === null ? 'none' : Notify.calculateAge(order.birthday)}
                                        </Typography>
                                    </Box>
                                    <Box ml={5}>
                                        <Typography>
                                            <Typography variant="caption" className="font-weight-bold">
                                                SEX:
                                            </Typography>  {order.gender === null ? 'none' : order.gender}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography>
                                        <Typography variant="caption" className="font-weight-bold">
                                            ADDRESS:
                                        </Typography>  {`${order.street} ${order.barangay} ${order.city} ${order.zip} `}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box>
                                <Box mb={2}>
                                    <Typography>
                                        <Typography variant="caption" className="font-weight-bold">
                                            DATE:
                                        </Typography>  {Notify.dateTimeConvert(new Date().toLocaleString())}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </CardContent>

                    <CardContent>
                        {/* order details */}
                        <Box>
                            <Typography variant="h6" align="center">
                                <b> CLINICAL MICROSCOPY </b>
                            </Typography>
                        </Box>

                        <Box>
                            {
                                orderDetails.ready ?
                                    Object.keys(orderDetails.data).length > 0 ?
                                        <>
                                            <Box mb={1}>
                                                <Typography variant="caption"> <b> SPECIMEN: </b> </Typography> <span className="gtc-uppercase"> {orderDetails.data.spicemen} </span>
                                            </Box>

                                            <Box mb={2}>
                                                {/* clinical microscopy */}
                                                {Boolean(parseInt(orderDetails.data.chemical_test)) &&
                                                    <>
                                                        <Box>
                                                            <Typography variant="subtitle1"> <b> CHEMICAL TEST </b> </Typography>
                                                        </Box>
                                                        <Box ml={2}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={4} sm={4}>
                                                                    <Box display="flex">
                                                                        <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                            <Typography variant="caption"> <b> COLOR: </b> </Typography>
                                                                        </Box>
                                                                        <Box>
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Result"
                                                                                name="color"
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                    <Box display="flex">
                                                                        <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                            <Typography variant="caption"> <b> TRANPARENCY: </b> </Typography>
                                                                        </Box>
                                                                        <Box>
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Result"
                                                                                name="transparency"
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={4} sm={4}>
                                                                    <Box display="flex">
                                                                        <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                            <Typography variant="caption"> <b> PH: </b> </Typography>
                                                                        </Box>
                                                                        <Box>
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Result"
                                                                                name="ph"
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                    <Box display="flex">
                                                                        <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                            <Typography variant="caption"> <b> SPECIFIC GRAVITY: </b> </Typography>
                                                                        </Box>
                                                                        <Box>
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Result"
                                                                                name="specific_gravity"
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={4} sm={4}>
                                                                    <Box display="flex">
                                                                        <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                            <Typography variant="caption"> <b> GLUCOSE: </b> </Typography>
                                                                        </Box>
                                                                        <Box>
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Result"
                                                                                name="glucose"
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                    <Box display="flex">
                                                                        <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                            <Typography variant="caption"> <b> ALBUMIN: </b> </Typography>
                                                                        </Box>
                                                                        <Box>
                                                                            <TextField
                                                                                fullWidth
                                                                                label="Result"
                                                                                name="albumin"
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </>
                                                }
                                            </Box>

                                            <Box mb={2}>
                                                {/* clinical microscopy */}
                                                {Boolean(parseInt(orderDetails.data.microscopic_test)) &&
                                                    <>
                                                        <Box>
                                                            <Typography variant="subtitle1"> <b> MICROSCOPIC TEST </b> </Typography>
                                                        </Box>
                                                        <Box ml={2}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6} sm={6}>
                                                                    <>
                                                                        <Box mt={2}>
                                                                            <Typography variant="subtitle2" color="textSecondary"> CELLS </Typography>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mr={1} flexGrow={1} align="right" mt={2}>
                                                                                <Typography variant="caption" > <b> SQUAMOUS CELLS: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="squamous"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b> PUS CELLS: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="pus"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                /HPF
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b> RED BLOOD CELLS: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="redblood"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                /HPF
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </>

                                                                    <>
                                                                        <Box mt={2}>
                                                                            <Typography variant="subtitle2" color="textSecondary"> CASTS </Typography>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mr={1} flexGrow={1} align="right" mt={2}>
                                                                                <Typography variant="caption" className={`gtc-uppercase`} > <b> Hyaline Cast: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="hyaline"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" className={`gtc-uppercase`} > <b> WBC Cast: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="wbc_cast"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" className={`gtc-uppercase`} > <b> RBC Cast: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="rbc_cast"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" className={`gtc-uppercase`} > <b> Fine Granualar Cast: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="fine_granualar"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" className={`gtc-uppercase`} > <b> Coarse Granualar Cast: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="coarse_granualar"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </>
                                                                </Grid>

                                                                <Grid item xs={6} sm={6}>
                                                                    <>
                                                                        <Box mt={2}>
                                                                            <Typography variant="subtitle2" color="textSecondary"> CRYSTALS </Typography>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mr={1} flexGrow={1} align="right" mt={2}>
                                                                                <Typography variant="caption" > <b> CALCIUM OXALATE: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="crystal_oxalate"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b> TRIPLE PHOSPHATE: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="triple_phosphate"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b> LEUCINE/TYROCINE: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="leucine_tyrocine"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>

                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b>  AMMONIUME BIURATE: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="ammoniume"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>

                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b>  AMORPHOUS URATES: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="amorphous_urates"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>

                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b>  AMORPHOUS PHOSPHATES: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="amorphous_phosphate"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>

                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b>  URIC ACID: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="uric_acid"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </>
                                                                    <>
                                                                        <Box mt={2}>
                                                                            <Typography variant="subtitle2" color="textSecondary"> OTHERS </Typography>
                                                                        </Box>

                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b>  MUCUS THREAD: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="mucus_thread"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b>  BACTERIA: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="bacteria"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>

                                                                        <Box display="flex">
                                                                            <Box mt={2} mr={1} flexGrow={1} align="right">
                                                                                <Typography variant="caption" > <b>  YEAST: </b> </Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    label="Result"
                                                                                    name="yeast"
                                                                                    margin="dense"
                                                                                    variant="outlined"
                                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    </>
                                                                </Grid>

                                                            </Grid>
                                                        </Box>
                                                    </>
                                                }
                                            </Box>

                                            <Box mb={2}>
                                                {/* clinical microscopy */}
                                                {Boolean(parseInt(orderDetails.data.pregnancy_test_hcg)) &&
                                                    <>
                                                        <Box>
                                                            <Typography variant="subtitle1"> <b> PREGNANCY TEST (HCG) </b> </Typography>
                                                            <Box>
                                                                <TextField
                                                                    fullWidth
                                                                    label="Result"
                                                                    name="pregnancy_test"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    multiline
                                                                    rows={3}
                                                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </>
                                                }
                                            </Box>

                                            <Box mb={2}>
                                                {/* clinical microscopy */}
                                                <>
                                                    <Box>
                                                        <Typography variant="subtitle1"> <b> REMARKS </b> </Typography>
                                                        <Box>
                                                            <TextField
                                                                fullWidth
                                                                label="Result"
                                                                name="result_remarks"
                                                                margin="dense"
                                                                variant="outlined"
                                                                multiline
                                                                rows={3}
                                                                disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </>
                                            </Box>
                                        </>
                                        : 'Order not found.'
                                    : 'please wait...'
                            }
                        </Box>
                    </CardContent>
                    <Box display="flex" m={1}>
                        <Box flexGrow={1} />
                        <Box>
                            <CardActions>
                                <Tooltip title={`${order.is_pending_reason}`} open={Boolean(parseInt(order.is_pending))} arrow>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="secondary"
                                        onClick={() => setPendingDialog(true)}
                                        disabled={Boolean(parseInt(order.is_pending))}
                                    >
                                        Set as Pending
                                    </Button>
                                </Tooltip>

                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    disabled={Boolean(parseInt(order.is_processed))}
                                    onClick={() => setProcessDialog(true)}
                                >
                                    Set As PRocess
                                </Button>

                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    type="submit"
                                    disabled={Boolean(parseInt(order.is_pending)) || !Boolean(parseInt(order.is_processed)) || resultSubmitting}
                                    startIcon={resultSubmitting && <CircularProgress size={20} color="inherit" />}
                                >
                                    Save Result
                                </Button>
                            </CardActions>
                        </Box>
                    </Box>
                </Card>
            </form>

            {/* set as pending dialog */}
            <Dialog
                open={pendingDialog}
                onClose={() => setPendingDialog(false)}
                disableBackdropClick
                PaperComponent={DraggableDialog}
            >
                <DialogTitle id="draggable-handle">
                    Set as pending
                </DialogTitle>
                <form
                    onSubmit={handlePendingOrder}
                >
                    <DialogContent dividers>
                        <Box mb={2}>
                            <TextField
                                rows={5}
                                fullWidth
                                name="reason"
                                label={`Pending Reason`}
                                variant="outlined"
                                multiline
                            />
                        </Box>

                        <Box>
                            <TextField
                                fullWidth
                                name="password"
                                label={`Password`}
                                variant="outlined"
                                type="password"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="default" onClick={() => setPendingDialog(false)} startIcon={<HighlightOffIcon />}>
                            cancel
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" disabled={pendingSubmitting} startIcon={pendingSubmitting ? <CircularProgress size={20} color="inherit" /> : <ErrorOutlineIcon />}>
                            pending
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            {/* set as process dialog */}
            <Dialog
                open={processDialog}
                onClose={() => setProcessDialog(false)}
                disableBackdropClick
                PaperComponent={DraggableDialog}
            >
                <DialogTitle id="draggable-handle">
                    Set as processing
                </DialogTitle>
                <form
                    onSubmit={handleProcessOrder}
                >
                    <DialogContent dividers>
                        <Box>
                            <TextField
                                fullWidth
                                name="password"
                                label={`Password`}
                                variant="outlined"
                                type="password"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="default" onClick={() => setProcessDialog(false)} startIcon={<HighlightOffIcon />}>
                            cancel
                        </Button>
                        <Button variant="contained" color="primary" type="submit" disabled={processSubmitting} startIcon={processSubmitting ? <CircularProgress size={20} color="inherit" /> : <CheckCircleIcon />}>
                            process
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default ClinicalMicroscopyOrderDetails;
