import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, InputAdornment, Button, FormHelperText, IconButton } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print';
import { Formik } from 'formik';
import * as Yup from 'yup';

const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

function SalesReportPrint() {
    const [token] = useState(localStorage.getItem('token'));
    const [user_id] = useState(localStorage.getItem('user_id'));
    const [management_id] = useState(localStorage.getItem('management_id'));
    const [sales, setSales] = useState([]);
    const [salesReady, setSalesReady] = useState(false);
    const [search, setSearch] = useState('');
    var totaltotal = 0;

    const fetchSales = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);

        Axios.post('malita/pharmacy/get-sales-list', formdata)
            .then((response) => {
                const data = response.data;
                setSales(data);
                setSalesReady(true);
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id, management_id])

    useEffect(() => {

        fetchSales()

    }, [fetchSales])

    const searchable = sales.filter((data) => {
        return data.product.toLowerCase().indexOf(search.trim()) !== -1
    })

    return (
        <Fragment>
            <Grid item sm={12} xs={12}>
                <Grid container>
                    <Grid item xs={6} sm={6}>
                        <Formik
                            initialValues={{
                                user_id: user_id,
                                token: token,
                                management_id: management_id,
                                date_from: '',
                                date_to: '',
                            }}
                            validationSchema={Yup.object().shape({
                                date_from: Yup.string().required(),
                                date_to: Yup.string().required(),
                            })}
                            onSubmit={async (values, {
                                setErrors,
                                setSubmitting,
                                resetForm
                            }) => {
                                try {
                                    const request = await Axios.post('malita/pharmacy/get-filter-by-date', getFormData(values))
                                    const data = request.data;
                                    setSales(data);
                                    setSalesReady(true);
                                    Notify.successRequest('filter')
                                    resetForm();
                                } catch (error) {
                                    const message = error.message || 'Something went wrong';
                                    setErrors({ submit: message });
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values
                            }) => (
                                <form
                                    noValidate
                                    onSubmit={handleSubmit}
                                >
                                    <Box display="flex" className={'d-print-none'}>
                                        <Box mb={1}>
                                            <TextField
                                                required
                                                label="Date From"
                                                error={Boolean(touched.date_from && errors.date_from)}
                                                helperText={touched.date_from && errors.date_from}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.date_from}
                                                name="date_from"
                                                variant="outlined"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                        <Box ml={1}>
                                            <TextField
                                                required
                                                label="Date To"
                                                error={Boolean(touched.date_to && errors.date_to)}
                                                helperText={touched.date_to && errors.date_to}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.date_to}
                                                name="date_to"
                                                variant="outlined"
                                                type="date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Box>
                                        {errors.submit && (
                                            <Box mt={3}>
                                                <FormHelperText error>
                                                    {errors.submit}
                                                </FormHelperText>
                                            </Box>
                                        )}
                                        <Box ml={1} border={1} height={55} width={55} borderRadius={6}>
                                            <IconButton
                                                style={{ top: 3, left: 3 }}
                                                className={'d-print-none'}
                                                color="primary"
                                                type="submit"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                    {/* <Grid item xs={6} sm={6}>
                        <Box display="flex" spacing={2}>
                            <Box flexGrow={1} />
                            <IconButton
                                className={'d-print-none'}
                                color="primary"
                                onClick={() => window.print()}
                            >
                                <PrintIcon />
                            </IconButton>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                        <Box>
                            <TableContainer component={Box} borderColor="grey.200" bgcolor="white">
                                <Box display="flex">
                                    <Box flexGrow={1} />
                                    <Box mb={1}>
                                        <TextField
                                            className={'d-print-none'}
                                            label="Search brand"
                                            variant="outlined"
                                            margin="dense"
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Box>
                                </Box>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"> Brand </TableCell>
                                            <TableCell align="center"> Generic </TableCell>
                                            <TableCell align="center"> Date </TableCell>
                                            <TableCell align="center"> Unit </TableCell>
                                            <TableCell align="center"> Qty </TableCell>
                                            <TableCell align="center"> Amount </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            salesReady ?
                                                sales.length > 0 ?
                                                    searchable.map((data, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell bgcolor="white"> {data.product} </TableCell>
                                                            <TableCell bgcolor="white">{data.description}</TableCell>
                                                            <TableCell bgcolor="white" align="right">
                                                                {Notify.dateTimeConvert(data.created_at)}
                                                            </TableCell>
                                                            <TableCell bgcolor="white">{data.unit}</TableCell>
                                                            <TableCell bgcolor="white" align="right"> {data.sum_all_total_quantity} </TableCell>
                                                            <TableCell bgcolor="white" align="right">
                                                                {Notify.numberFormat(data.sum_spec_total_quantity)}
                                                                <span className="d-none">
                                                                    {totaltotal += parseFloat(data.productSrp) * parseFloat(data.sum_all_total_quantity)}
                                                                </span>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                    : <TableRow>
                                                        <TableCell colSpan={6}>
                                                            <Typography variant="subtitle2" color="secondary"> No sales found </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                : <TableRow>
                                                    <TableCell colSpan={6}>
                                                        <Typography variant="subtitle2" color="primary"> loading... </Typography>
                                                    </TableCell>
                                                </TableRow>
                                        }
                                        {
                                            sales.length > 0 ?
                                                <TableRow>
                                                    <TableCell colSpan={5} align="right"> Total: </TableCell>
                                                    <TableCell align="right"> {Notify.numberFormat(totaltotal)} </TableCell>
                                                </TableRow>
                                                : null
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display="flex" mt={2}>
                                <Box flexGrow={1} />
                                <Button
                                    className={'d-print-none'}
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    onClick={() => window.print()}
                                >
                                    Print
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment >
    )

}

export default SalesReportPrint;