import React, { useState, Fragment } from 'react';
import Axios from 'axios';
import Notify from '../../../notification/Notify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, TextField, FormHelperText, Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const AccountAddUser = ({ closeModal }) => {
    const [token] = useState(localStorage.getItem('token'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [user_id] = useState(localStorage.getItem('user_id'))
    const [username] = useState(localStorage.getItem('username'))

    const getFormData = (object) => {
        const formData = new FormData();
        formData.set('accountType', 'user')
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    xl={12}
                >
                    <Formik
                        initialValues={{
                            user_id: user_id,
                            token: token,
                            username: username,
                            management_id: management_id,

                            name: '',
                            uusername: '',
                            upassword: '',
                            password: '',
                        }}

                        validationSchema={Yup.object().shape({
                            name: Yup.string().required(),
                            uusername: Yup.string().trim().required(),
                            upassword: Yup.string().trim().required(),
                            password: Yup.string().required()
                        })}

                        onSubmit={async (values, {
                            setErrors,
                            setSubmitting,
                            resetForm
                        }) => {
                            try {
                                const request = await Axios.post('malita/pharmacy/add-user', getFormData(values))
                                if (request.data === 'pass-invalid') {
                                    setErrors({ password: "Password doesn't matched" });
                                    Notify.customToast("Invalid Password", "Password doesn't matched")
                                }
                                if (request.data === 'success') {
                                    Notify.successRequest('add user')
                                    resetForm();
                                    setSubmitting(true);
                                    closeModal();
                                }
                            } catch (error) {
                                const message = error.message || 'Something went wrong';
                                setErrors({ submit: message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            resetForm
                        }) => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <Box flexGrow={1}>
                                    <Grid container spacing={2}>
                                        <Grid xs={12} item>
                                            <Box mb={1}>
                                                <TextField
                                                    error={Boolean(touched.name && errors.name)}
                                                    helperText={touched.name && errors.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    fullWidth
                                                    required
                                                    multiline
                                                    name="name"
                                                    label="Fullname"
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Box mb={1}>
                                                <TextField
                                                    error={Boolean(touched.uusername && errors.uusername)}
                                                    helperText={touched.uusername && errors.uusername}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.uusername}
                                                    fullWidth
                                                    required
                                                    multiline
                                                    name="uusername"
                                                    label="User's Username"
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid xs={12} item>
                                            <Box mb={1}>
                                                <TextField
                                                    error={Boolean(touched.upassword && errors.upassword)}
                                                    helperText={touched.upassword && errors.upassword}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.upassword}
                                                    fullWidth
                                                    required
                                                    multiline
                                                    name="upassword"
                                                    label="User's Password"
                                                    variant="outlined"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Box mb={1}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    error={Boolean(touched.password && errors.password)}
                                                    helperText={touched.password && errors.password}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    name="password"
                                                    label="Enter your password"
                                                    variant="outlined"
                                                    type="password"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {errors.submit && (
                                    <Box mt={2}>
                                        <FormHelperText error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}

                                <Box mt={2} mb={2} display="flex">
                                    <Box flexGrow={1} />

                                    <Button
                                        variant="contained"
                                        color="default"
                                        onClick={() => resetForm()}
                                        startIcon={<HighlightOffIcon />}
                                    >
                                        Reset
                                    </Button>

                                    <Box ml={2}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            startIcon={<CheckCircleIcon />}
                                            disabled={isSubmitting}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default AccountAddUser;