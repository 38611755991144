import React  from 'react'
import { Box, Dialog, DialogContent, Grid, Tabs, Tab, Zoom, makeStyles, Button } from '@material-ui/core' 
import TabPanel from '../../utils/TabPanel';
import Glucose from '../vitalsgraph/Glucose';
import Cholesterol from '../vitalsgraph/Cholesterol';
import UricAcid from '../vitalsgraph/Uricacid';
import Calcium from '../vitalsgraph/Calcium'; 
import Chloride from '../vitalsgraph/Chloride';
import Creatinine from '../vitalsgraph/Creatinine';
import HDL from '../vitalsgraph/HDL';
import LDL from '../vitalsgraph/LDL';
import Lithium from '../vitalsgraph/Lithium';
import Magnesium from '../vitalsgraph/Magnesium';
import Potasium from '../vitalsgraph/Potasium';
import Protein from '../vitalsgraph/Protein';
import Sodium from '../vitalsgraph/Sodium';

const useStyle = makeStyles((theme) =>({
    indicatorLeft: {
      left: "0px"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

const LaboratoryGraph = ({ open, close }) =>{
 
    const classes = useStyle() 
    const [category, setCategory] = React.useState(0); 

    const handleChange = (event, newValue) => {
        event.persist()
        setCategory(newValue);
    };
    
    return(
        <Dialog
            TransitionComponent={ Zoom }
            transitionDuration={600}
            open={ open } 
            fullScreen 
        > 
            <DialogContent >
                <Grid container spacing={1}>
                    <Grid  
                        item
                        xs={9}
                        md={10}
                        xl={11}  
                    >
                        <Box 
                            p={2}
                        >
                            <TabPanel value={ category } index={0}> 
                                <Glucose />
                            </TabPanel>

                            <TabPanel value={ category } index={1}>
                                <Cholesterol /> 
                            </TabPanel>

                            <TabPanel value={ category } index={2}> 
                                <UricAcid />
                            </TabPanel>

                            <TabPanel value={ category } index={3}> 
                                <Calcium />
                            </TabPanel>

                            <TabPanel value={ category } index={4}> 
                                <Chloride />
                            </TabPanel>

                            <TabPanel value={ category } index={5}> 
                                <Creatinine />
                            </TabPanel>

                            <TabPanel value={ category } index={6}> 
                                <HDL />
                            </TabPanel>

                            <TabPanel value={ category } index={7}> 
                                <LDL />
                            </TabPanel>

                            <TabPanel value={ category } index={8}> 
                                <Lithium />
                            </TabPanel>

                            <TabPanel value={ category } index={9}> 
                                <Magnesium />
                            </TabPanel>

                            <TabPanel value={ category } index={10}> 
                                <Potasium />
                            </TabPanel>

                            <TabPanel value={ category } index={11}> 
                                <Protein />
                            </TabPanel>

                            <TabPanel value={ category } index={12}> 
                                <Sodium />
                            </TabPanel>
                        </Box>
                    </Grid>

                    <Grid 
                        item
                        xs={3}
                        md={2}
                        xl={1}  
                    >
                        <Box > 
                            <Tabs  
                                orientation="vertical"
                                variant="scrollable"
                                value={category}
                                onChange={handleChange}
                                aria-label="Vertical tabs example" 
                                indicatorColor="primary"
                                textColor="primary"   
                                scrollButtons="on" 
                                classes = {{ indicator: classes.indicatorLeft }}
                                style={{ height: 'calc(100vh - 120px)' }}
                            >  
                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Glucose" 
                                />

                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Cholesterol" 
                                />

                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Uric Acid" 
                                />

                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Calcium" 
                                /> 
                                
                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Chloride" 
                                /> 
                                
                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Creatinine" 
                                /> 
                                
                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="HDL" 
                                /> 
                                
                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="LDL" 
                                />
 
                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Lithium" 
                                />
 
                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Magnesium" 
                                />

                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Potasium" 
                                />

                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Protein" 
                                />
                                
                                
                                <Tab 
                                    wrapped
                                    fullWidth
                                    label="Sodium" 
                                />

                            </Tabs>
                            <Button variant="contained" color="primary" fullWidth onClick={ close }> Close </Button>
                        </Box>
                    </Grid>
                </Grid> 
            </DialogContent>
        </Dialog>
    )
}

export default LaboratoryGraph;