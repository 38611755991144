import React, { useEffect, useState } from 'react'
import { Box, Dialog, Zoom, IconButton, CardHeader, DialogContent, CardMedia, Typography } from '@material-ui/core'
import Axios from 'axios'
import Notify from '../../notification/Notify'
import PatientHemaOrder from './details/PatientHemaOrder'
import PatientSeroOrder from './details/PatientSeroOrder'
import PatientChemOrder from './details/PatientChemOrder'
import PatientMicroOrder from './details/PatientMicroOrder'
import PatientFecalOrder from './details/PatientFecalOrder'
import CancelIcon from '@material-ui/icons/Cancel'

const imageLocation = process.env.REACT_APP_API_IMAGE;

const LaboratoryOrderDetails = ({ orderDetails, close }) => { 

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [hemaOrderDetails, setHemaOrderDetails] = useState({
        data: [],
        ready: false,
    })

    const [serologyOrderDetails, setSerologyOrderDetails] = useState({
        data: [],
        ready: false,
    })

    const [chemistryOrderDetails, setChemistryOrderDetails] = useState({
        data: [],
        ready: false,
    })

    const [microscopyOrderDetails, setMicroscopyOrderDetails] = useState({
        data: [],
        ready: false,
    })

    const [fecalOrderDetails, setFecalOrderDetails] = useState({
        data: [],
        ready: false,
    })

    const getHemaOrderDetails = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', orderDetails.order_id)

        Axios.post('patient/order/ordernew-hemathology/complete/details-print', formdata)
            .then((response) => {
                const data = response.data;
                setHemaOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    } 

    const getSerologyOrderDetails = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', orderDetails.order_id)

        Axios.post('patient/order/ordernew-sorology/complete/details-print', formdata)
            .then((response) => {
                const data = response.data;
                setSerologyOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getChemistryOrderDetails = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', orderDetails.order_id)

        Axios.post('patient/order/ordernew-chemistry/complete/details-print', formdata)
            .then((response) => {
                const data = response.data;
                setChemistryOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getMicroscopyOrderDetails = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', orderDetails.order_id)

        Axios.post('patient/order/ordernew-microscopy/complete/details-print', formdata)
            .then((response) => {
                const data = response.data;
                setMicroscopyOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getFecalAnalysisOrderDetails = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', orderDetails.order_id)

        Axios.post('patient/order/ordernew-fecalanalysis/complete/details-print', formdata)
            .then((response) => {
                const data = response.data;
                setFecalOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {
        getHemaOrderDetails()
        getSerologyOrderDetails()
        getChemistryOrderDetails()
        getMicroscopyOrderDetails()
        getFecalAnalysisOrderDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderDetails.order_id]) 

    return (
        <Dialog
            TransitionComponent={Zoom}
            transitionDuration={600}
            open={orderDetails.openOrderDetails}
            fullScreen
        >
            <DialogContent>
                <Box display="flex" mt={2} mr={2} className={'d-print-none'}>
                    <Box flexGrow={1} />
                    <IconButton
                        variant="outlined"
                        color={'secondary'}
                        onClick={close}
                    >
                        <CancelIcon />
                    </IconButton>
                </Box> 
                <Box>
                    <Box display="flex" justifyContent="center">
                        <Box mt={2}>
                            {
                                orderDetails.formheader && 
                                <CardMedia 
                                    style={{ width : 70 }}
                                    component={'img'}
                                    src={`${imageLocation}laboratory/logo/${orderDetails.formheader.logo}`}
                                /> 
                            } 
                        </Box>
                        <Box >
                            <CardHeader
                                component={Box}
                                align="center"
                                title={orderDetails.formheader && (orderDetails.formheader.name)}
                                subheader={orderDetails.formheader && (orderDetails.formheader.address)}
                            />
                        </Box>
                    </Box>
                    <Box mb={2}>
                        {hemaOrderDetails.data && Object.keys(hemaOrderDetails.data).length > 0 && hemaOrderDetails.ready && <PatientHemaOrder hemaOrderDetails={hemaOrderDetails} />}
                    </Box>

                    <Box>
                        {serologyOrderDetails.data && Object.keys(serologyOrderDetails.data).length > 0 && serologyOrderDetails.ready && <PatientSeroOrder serologyOrderDetails={serologyOrderDetails} />}
                    </Box>

                    <Box>
                        {chemistryOrderDetails.data && Object.keys(chemistryOrderDetails.data).length > 0 && chemistryOrderDetails.ready && <PatientChemOrder chemistryOrderDetails={chemistryOrderDetails} />}
                    </Box>

                    <Box>
                        {microscopyOrderDetails.data && Object.keys(microscopyOrderDetails.data).length > 0 && microscopyOrderDetails.ready && <PatientMicroOrder microscopyOrderDetails={microscopyOrderDetails} />}
                    </Box>

                    <Box>
                        {fecalOrderDetails.data && Object.keys(fecalOrderDetails.data).length > 0 && fecalOrderDetails.ready && <PatientFecalOrder fecalOrderDetails={fecalOrderDetails} />}
                    </Box> 
                </Box> 
                    <Box display="flex" my={3}>
                        <Box flexGrow={1}>
                            <Box align="center" width={250}>
                                <Box>
                                    { orderDetails.formheader && (
                                        <CardMedia 
                                            component="img"
                                            src={`${imageLocation}laboratory/pathologist/${orderDetails.formheader.pathologist_signature}`}
                                            style={{
                                                width: 100,
                                                top: 20,
                                                position: 'relative'
                                            }}
                                        />
                                    )} 
                                </Box>
                                <Typography className={`gtc-uppercase`} variant="subtitle2">  { orderDetails.formheader && (orderDetails.formheader.pathologist)} </Typography>
                                <Box borderTop={1} > 
                                    <b> Pathologist (LIC No. { orderDetails.formheader && (orderDetails.formheader.pathologist_lcn) }) </b>
                                </Box> 
                            </Box> 
                        </Box>

                        <Box>
                            <Box align="center"  width={250}>
                                <Box mt={5}>
                                    <Typography className={`gtc-uppercase`} variant="subtitle2"> { orderDetails.formheader && (orderDetails.formheader.medtech) } </Typography>
                                    <Box borderTop={1}>
                                        <b> Medical Technologist  </b>
                                    </Box> 
                                </Box>
                            </Box>
                        </Box>
                    </Box>
            </DialogContent>
        </Dialog>
    )
}

export default LaboratoryOrderDetails;