import React, { Fragment, useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; 
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';

import TablePagination from '@material-ui/core/TablePagination'; 
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Paper, Typography } from '@material-ui/core';  
import CheckInternet from 'src/utils/CheckInternet';
import NoInternet from 'src/NoInternet'
import CheckingConnection from 'src/CheckInternet';
import { PatientsUnRead } from 'src/ContextAPI';


function PatientAppointmentVirtual({ patient_id }) {

    const [token] = useState(localStorage.getItem('token'))
    const [user_id] = useState(localStorage.getItem('user_id'))

    const [virtualapp, setvirtualapp] = useState([])   
    const [virtualappready, setvirtualappready] = useState(false)   
    const [rowsPerPage, setRowsPerPage] = useState(5);  
    
    const [online, setOnline] = useState('checking')


    const unread = useContext(PatientsUnRead);

    const checkPatientUnreadNotif = ( category, department, notifId ) =>{
         
        let yy = unread.unviewNotifVirtual;
        let count = 0;
 
        for (let i = 0; i < yy.length; i++) {
            if( patient_id === yy[i].patient_id &&  category === yy[i].category && department === yy[i].department && notifId === yy[i].order_id){
                count += 1;
            }
        } 
        
        return count;
    }


    const getappointmentVirtualRecord = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('patient_id', patient_id);    
        formdata.set('connection', 'online'); 
         
        
        Axios.post('doctors/appointment/patients/appointmentrecord-virtual', formdata)
        .then( (response) => { 
            const data = response.data;    
            setvirtualapp(data)
            setvirtualappready(true)
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }  

    const [page, setPage] = useState(0); 

    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };    

    const checkinternet = () => {
        CheckInternet.online().then(() => setOnline('connected')).catch(() => setOnline('disconnected'))
    }

    useEffect(() => {   
        
        checkinternet() 

        if(online === 'connected'){
            getappointmentVirtualRecord() 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient_id, online]);  

    return (
        <Fragment> 
            { online === 'checking' && <CheckingConnection /> }
            { online === 'disconnected' && <NoInternet /> }
            { online === 'connected' && (
                <Grid item xs={12} sm={12}> 
                    <TableContainer 
                        component={Paper} variant={'outlined'} p={2}
                    >
                        <Box p={2}>
                            <Typography variant="subtitle2" color="primary"> APPOINTMENTS </Typography>
                        </Box>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> <b> Date </b> </TableCell> 
                                    <TableCell> <b> Service </b> </TableCell> 
                                    <TableCell> <b> Reason </b> </TableCell> 
                                    <TableCell> <b> Status </b> </TableCell>  
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    virtualappready ? 
                                        virtualapp.length > 0 ?
                                            (rowsPerPage > 0
                                                ? virtualapp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                : virtualapp
                                            ).map((data, index) => (
                                                <TableRow hover key={index}>
                                                    <TableCell> 
                                                        <Badge 
                                                            color="secondary"
                                                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                            invisible={ ! Boolean(parseInt(checkPatientUnreadNotif('appointment','virtual-appointment', data.app_id))) }
                                                            variant="dot"
                                                        >
                                                            { Notify.dateTimeConvert(data.appointment_date) }
                                                        </Badge> 
                                                    </TableCell> 
                                                    <TableCell> { data.doctors_service_id} </TableCell> 
                                                    <TableCell> { data.appointment_reason} </TableCell> 
                                                    <TableCell> { data.appointment_status } </TableCell> 
                                                </TableRow> 
                                            )) 
                                        :
                                        <TableRow>
                                            <TableCell colSpan={4}> 
                                                <Typography color="error">
                                                    No virtual appointment recorded.
                                                </Typography>
                                            </TableCell> 
                                        </TableRow>
                                    : <TableRow>
                                        <TableCell colSpan={4}> 
                                            <Typography color="primary">
                                                please wait...
                                            </Typography>
                                        </TableCell> 
                                    </TableRow>
                                }
                            </TableBody> 
                        </Table> 

                        <TablePagination
                            labelRowsPerPage = "List"
                            rowsPerPageOptions={[5, 20, 50, 100]}
                            component="div"
                            count={virtualapp.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /> 
                    </TableContainer> 
                </Grid>  
            )}
        </Fragment>
    )
}

export default PatientAppointmentVirtual;
