import React, { Fragment, Component } from 'react';
import { Col, Form, FormGroup, FormControl, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import Notify from '../notification/Notify';
import axios from 'axios';

export default class Contact extends Component{
    _mounted = false;
    constructor(props){
        super(props); 
        this.state={
            isbuttondisabled: false,
        }
    }

    handleSaveMessage = e =>{
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target); 
        var error = [];  
        
        if(formdata.get('fullname').length === 0 || formdata.get('fullname').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('fullname')
        } 
        if(formdata.get('email_add').length === 0 || formdata.get('email_add').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('email address')
        }else{
            if(!Notify.isvalidEmail(formdata.get('email_add'))){
                error = 'eerr';
                Notify.fieldInvalid("email")
            }
        }
        if(formdata.get('message').length === 0 || formdata.get('message').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('message')
        }
        if(error.length > 0){
            console.log('Unable to process login, Form has an error');
            console.log(axios.defaults.baseURL)
        }else{
            axios({
                url: axios.defaults.baseURL+'welcome/message', 
                method: 'post',
                data: formdata, 
            })
            .then((response) => {  
                if(response.data ==='success'){
                    e.target.reset()
                    this.setState({
                        isbuttondisabled: true
                    })
                    Notify.successRequest('message');
                }
                if(response.data ==='db-error'){
                    Notify.warnRequest('message');
                }
            })
        }
    }

    componentDidMount(){
        this._mounted = true;  
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    render (){
        return(
            <Fragment>
                <Col sm={12} className="contact-box">
                    <h2 className="text-center about-text mb-4" style={{fontWeight: 'bolder'}}> GOT QUESTIONS? </h2>
                    <Col sm={8} lg={7} className="mx-auto mb-5 mb-sm-2">
                        <Row className="contact_animation"> 
                            
                            <Col md={12} className="my-3 "> 
                                    <h6 style={{fontWeight: 'bolder'}}>WE ARE HERE TO LISTEN TO YOU</h6>
                                    
                                    <p  className="text-justify pt-3" style={{textIndent: '0.7cm'}}>
                                        For comments, suggestion, inquires, and account recovery, you may
                                        contact us directly through any of the following email or contact number below.
                                    </p> 
                            </Col>

                            <Col md={12} className="login-box">
                                <Form onSubmit={this.handleSaveMessage}>  
                                    <FormGroup>
                                        <strong> Fullname </strong>
                                        <FormControl name="fullname" autoComplete="off" />
                                    </FormGroup>
                                    <FormGroup>
                                        <strong> Email Address </strong>
                                        <FormControl name="email_add" autoComplete="off" />
                                    </FormGroup>
                                    <FormGroup>
                                        <strong> Message </strong>
                                        <FormControl as="textarea" name="message" autoComplete="off" rows="4" />
                                    </FormGroup>
                                    <FormGroup> 
                                        <button
                                            id="button_animated"
                                            className="btn btn-outline-success mr-2"
                                            disabled={this.state.isbuttondisabled ? true : false}
                                            type="submit"> 
                                            <FontAwesomeIcon 
                                                icon={this.state.isbuttondisabled ? faTimesCircle : faPaperPlane} 
                                            /> Send
                                        </button>

                                        <button
                                            id="button_animated2"
                                            className="btn btn-outline-danger"
                                            type="reset"> 
                                            <FontAwesomeIcon 
                                                icon={faEraser} 
                                            /> Clear
                                        </button>

                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Fragment>
        )
    }
}