import React, { useState } from 'react'
import { Dialog, Box, DialogActions, DialogContent, TextField, Button, CircularProgress, Typography, IconButton } from '@material-ui/core';
import Axios from 'axios';
import Notify from '../../notification/Notify';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {DropzoneArea} from 'material-ui-dropzone'

const AddResult = ({ imaging_center_id, open, close, reload, getCounts, radiologist }) =>{

    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 
    const username = localStorage.getItem('username') 
    const [submitting, setSubmitting] = useState(false)

    const [files, setFiles] = useState([])

    const handleAddResult = (e) =>{
        e.preventDefault();
        e.persist();
    
        var formdata = new FormData(e.target);  
        var error = [];
        formdata.set('token', token); 
        formdata.set('user_id', user_id); 
        formdata.set('username', username);   
        formdata.set('type', 'In-House')  
        formdata.set('radiologist', radiologist[0].radiologist_id)

        for (let i = 0; i < files.length; i++) {  
            formdata.append('attachments[]', files[i]) 
        }  

        if(files.length === 0){ 
            error = 'error';
            Notify.fieldRequired('attachments'); 
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0){
            Notify.consoleLog('set process')
        }else{
            setSubmitting(true)
            Axios.post('imaging/order/send-toradiologist', formdata )
            .then((response) => { 

                const data = response.data;    

                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'db-error'){
                    Notify.warnRequest('set process') 
                }
                if(data === 'success'){
                    setFiles()
                    reload()
                    close()
                    getCounts()
                    Notify.successRequest('set process') 
                } 
            }).catch(error=>{  
                Notify.requestError(error);
            }).finally(() =>{
                setSubmitting(false)
            });
        }
    } 

    const handleChange = (files) => {
        //Saving files to state for further use and closing Modal.  
        setFiles(files)
    } 
 

    return( 
        <Dialog 
            disableBackdropClick
            open={ open }
            onClose={ close }  
        > 

            <Box display="flex" p={1}>
                <Box flexGrow={1} m={1}>
                    <Typography variant="subtitle2" className="gtc-uppercase"> Sent To RadTech </Typography> 
                </Box>
                <Box>
                    <IconButton onClick={ close } color="secondary">
                        <Cancel />
                    </IconButton>
                </Box>
            </Box>

            <Box>
                { radiologist.length === 0 && (
                    <Typography color="secondary" align="center">
                        No radiologist found. Add radiologist in your management to continue.
                    </Typography>
                )}
                { radiologist.length > 0 && (
                    <form onSubmit = { handleAddResult }>
                        <DialogContent dividers>  
                            <Box mb={1}>
                                <TextField 
                                    fullWidth 
                                    name="orderId"
                                    variant="outlined"
                                    label="Selected Order"
                                    defaultValue={ imaging_center_id }
                                    InputProps={{
                                        inputProps:{
                                            readOnly: true
                                        }
                                    }}
                                /> 
                            </Box> 
                            <Box my={2}>
                                <DropzoneArea 
                                    filesLimit={5}
                                    onChange={ handleChange }
                                    acceptedFiles={['image/jpeg', 'image/png']}
                                    dropzoneText= "Result Attachment (Required) "
                                />
                            </Box> 

                            <Box>
                                <TextField 
                                    fullWidth 
                                    name="password"
                                    variant="outlined"
                                    label="Enter Password"
                                    type="password" 
                                /> 
                            </Box>
                        </DialogContent> 
                        <DialogActions> 
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<Cancel />}
                                onClick={ close }
                            >
                                No
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                startIcon={ submitting ? <CircularProgress color="inherit" size={15} /> : <CheckCircleIcon />}
                                disabled={ submitting }
                            >
                                Add Result
                            </Button> 
                        </DialogActions>
                    </form>
                )}
            </Box>
        </Dialog> 
    )
}

export default AddResult;