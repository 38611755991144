import React, { Fragment, useState } from 'react';
import { Box, Grid, Paper, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../../utils/TabPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt, faBoxes } from '@fortawesome/free-solid-svg-icons';
import StockMonitoring from './StockMonitoring';
import ActivityLogs from './ActivityLogs';

function Stock() {
    const [value, setValue] = useState('stock');

    return (
        <Fragment>
            <Box m={2}>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <Paper>
                            <Box
                                component={Paper}
                                variant="outlined"
                                mb={2}
                            >
                                <Tabs
                                    variant="scrollable"
                                    value={value}
                                    onChange={(e, data) => setValue(data)}
                                    aria-label="Vertical tabs example"
                                    indicatorColor={'primary'}
                                    textColor={"primary"}
                                    scrollButtons="on"
                                >
                                    <Tab
                                        wrapped
                                        fullWidth
                                        label="Stock"
                                        value="stock"
                                        icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faBoxes} />}
                                    />

                                    <Tab
                                        wrapped
                                        fullWidth
                                        label="Logs"
                                        value="logs"
                                        icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faListAlt} />}
                                    />
                                </Tabs>
                            </Box>
                            <Box>
                                <TabPanel value={value} index={'stock'}>
                                    <Box m={1}>
                                        <StockMonitoring />
                                    </Box>
                                </TabPanel>

                                <TabPanel value={value} index={'logs'}>
                                    <Box m={1} >
                                        <ActivityLogs />
                                    </Box>
                                </TabPanel>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    )
}

export default Stock;