import React, { Component, Fragment } from 'react';  
import Axios from 'axios';
import Notify from '../../notification/Notify';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export default class Transaction extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _transaction: [],
            _transaction_ready: false,

            _total_cost: 0 ,
        }

    } 

    componentDidMount(){
        this._mounted = true;
        this.getTransaction();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    getTransaction = () =>{
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('management_id', this.state._management_id);   
        formdata.set('user_id', this.state._user_id);    
        
        Axios.post('patient/credits/credits-transaction', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _transaction: data,
                    _transaction_ready: true,
                })
                this.totalCost(data)
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    totalCost = (data) =>{
        var total = 0; 
        for (let index = 0; index < data.length; index++) {
            total += parseFloat(data[index].transaction_cost);
        }
        this.setState({
            _total_cost: total
        })
    }

	render(){ 
		return(
            <Fragment>
                <Table striped responsive>
                    <thead className="text-uppercase header-label gtc-bg-purple text-white text-center">
                        <tr>
                            <th> Date </th>
                            <th> Trans # </th>
                            <th> Ref # </th>
                            <th> Transaction </th>
                            <th> Status </th> 
                            <th> Credit </th>
                        </tr>
                    </thead>
                    <tbody>                        
                        {
                            this.state._transaction_ready ? 
                                this.state._transaction.length > 0 ?
                                    this.state._transaction.map((data, index)=>{
                                        return(
                                            <tr key={index}>
                                                <td> { Notify.createdAt(data.created_at) } </td>
                                                <td> { data.transaction_id.replace('transaction-', '') } </td>
                                                <td> { data.reference_no} </td>
                                                <td> { data.services } </td>
                                                <td> { data.transaction_status } </td> 
                                                <td className="text-right"> { data.transaction_cost } </td>
                                            </tr>
                                        )
                                    })
                                : <tr>
                                    <td colSpan="6" className="text-danger"> No transaction yet. </td>
                                  </tr>
                            : <tr>
                                <td colSpan="6" className="gtc-text-purple"> <FontAwesomeIcon icon={faCircleNotch   } spin={true} />  loading </td>
                              </tr>
                        }
                    </tbody>
                    <tfoot className="bg-light">
                        <tr>
                            <td>  </td>
                            <td>  </td>
                            <td>  </td>
                            <td>  </td>
                            <td>  </td>
                            <td className="text-left header-label text-uppercase">
                                <strong> Total Cost: </strong> <span className="h5">{ this.state._total_cost }</span> 
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </Fragment>
        )
	}
}
