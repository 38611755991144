import React, { Component, Fragment } from "react"
import { Col, Row, Card, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Notify from "../../notification/Notify";
import CreditsTransactionLogs from "./CreditsTransactionLogs";
import CreditsLoadoutLogs from "./CreditsLoadoutLogs";
import CreditsLoadout from "./CreditsLoadout";
import CreditRequest from "./CreditRequest";

export default class Credits extends Component {
    _mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _show_dropdown: false,

            _patient: [],
            _patient_ready: false,

            _selected_logs: 'transaction',
            _selected_logs_title: 'Transaction Logs',

            _selected_patientid: null,
            _selected_patientname: null,

            _patient_search: '',
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.getPatients();
    }


    getPatients() {
        var formdata = new FormData();

        formdata.set('token', this.state._token);
        formdata.set('user_id', this.state._user_id);

        Axios.post('gtcadmin/patient-list', formdata)
            .then((response) => {
                const data = response.data;
                if (this._mounted) {
                    this.setState({
                        _patient: data,
                        _patient_ready: true
                    })
                }
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    handleCategory = (e) => {
        this.setState({
            _selected_logs_title: e.currentTarget.title,
            _selected_logs: e.currentTarget.id,
            _selected_patientid: e.currentTarget.getAttribute('patientid'),
            _selected_patientname: e.currentTarget.getAttribute('patientname'),

        })
    }

    displayLoadoutLogs = () => {
        this.setState({
            _selected_logs_title: 'Loadout Logs',
            _selected_logs: 'loadout',
            _selected_patientid: null,
            _selected_patientname: null
        })
    }

    handlePatientSearch = (e) => {
        this.setState({
            _patient_search: e.currentTarget.value
        })
    }

    render() {

        // search patient
        let patientfilter = this.state._patient.filter(
            (data) => {
                return data.lastname.toLowerCase().indexOf(this.state._patient_search.toLowerCase()) !== -1 ||
                    data.firstname.toLowerCase().indexOf(this.state._patient_search.toLowerCase()) !== -1 ||
                    data.user_id.toLowerCase().indexOf(this.state._patient_search.toLowerCase()) !== -1;
            }
        );
        return (
            <Fragment>
                <Col sm={12} className="mt-3">
                    <Row>
                        <Col sm={8}>

                            <CreditRequest />

                            <Card>
                                <Card.Header className="gtc-text-purple">
                                    Credit {this.state._selected_logs_title}
                                    <div className="dropleft float-right">
                                        <span
                                            className="pointer px-2"
                                            onClick={() => this.setState({ _show_dropdown: this.state._show_dropdown ? false : true })}
                                        >
                                            <FontAwesomeIcon icon={faEllipsisV} />
                                        </span>
                                        <div className={this.state._show_dropdown ? "dropdown-menu show px-2" : "dropdown-menu"}>
                                            <span
                                                title="Transaction Logs"
                                                id="transaction"
                                                onClick={this.handleCategory}
                                                className={this.state._selected_logs === 'transaction' ? 'd-none' : 'd-block pointer'}
                                            > Transaction Logs </span>
                                            <span
                                                title="LoadIn Logs"
                                                id="loadout"
                                                onClick={this.handleCategory}
                                                className={this.state._selected_logs === 'loadout' ? 'd-none' : 'd-block pointer'}
                                            > LoadIn Logs </span>
                                        </div>
                                    </div>

                                </Card.Header>
                                <Card.Body>
                                    {
                                        this.state._selected_logs === 'transaction' ?
                                            <CreditsTransactionLogs />
                                            : this.state._selected_logs === 'loadout' ?
                                                <CreditsLoadoutLogs />
                                                : <CreditsLoadout
                                                    patient_id={this.state._selected_patientid}
                                                    patient_name={this.state._selected_patientname}
                                                    displayLoadoutLogs={this.displayLoadoutLogs}
                                                />
                                    }
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col sm={4}>
                            <Card className="border-teal">
                                <Card.Header className="bg-teal text-white">
                                    Credit LoadIn
                                </Card.Header>
                                <Card.Body>
                                    <FormControl placeholder="Search name/id" onChange={this.handlePatientSearch} defaultValue={this.state._patient_search} className="mb-2 rounded-0" />
                                    <div className="gtc-scroll-limit" >
                                        {
                                            this.state._patient_ready ?
                                                patientfilter.length > 0 ?
                                                    patientfilter.map((data, index) => {
                                                        return (
                                                            <div
                                                                id="patient-loadout"
                                                                title="Loadout Form"
                                                                patientname={data.firstname + ' ' + data.lastname}
                                                                patientid={data.user_id}
                                                                key={index}
                                                                className="mb-2 border rounded py-1 px-2 text-capitalize"
                                                                onClick={this.handleCategory}
                                                            >
                                                                <p className={data.user_id === this.state._selected_patientid ? "m-0 text-danger" : "m-0 text-primary pointer"}> {data.lastname}, {data.firstname} {data.middle} </p>
                                                                <p className="text-muted gtc-small m-0"> {data.user_id} </p>
                                                            </div>
                                                        )
                                                    })
                                                    : Notify.noRecord()
                                                : Notify.loading()
                                        }
                                    </div>
                                    <p className="text-muted gtc-small text-center m-0 mt-2"> &copy; GTC 2018 </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Fragment>
        )
    }
}