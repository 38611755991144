import React, { useEffect, useRef, useState } from 'react'
import {  Box, CardMedia, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CallEndIcon from '@material-ui/icons/CallEnd';
import CallIcon from '@material-ui/icons/Call';
import { blue, grey, red } from '@material-ui/core/colors';  
import SimplePeer from 'simple-peer'; 
import Notify from 'src/notification/Notify';
import Axios from 'axios';

const CallPatient = ({ open, close, appdetails }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 
    const management_id = localStorage.getItem('management_id') 
    const username = localStorage.getItem('username')   

    const room_number = Date.now(); 

    const [mystream, setMyStream] = useState(null) 

    const [isanswer, setIsAnswer] = useState(false)

    let callingAudio = new Audio('./tones/calling_beep.mp3') 

    let  _peer = null; 
    let  _webidPatient = null;

    const myvideoRef = useRef(null)
    const clientvideoRef = useRef(null)
    
    // const playAudioCall = () =>{
    //     callingAudio.play()
    //     callingAudio.loop= true 
    // }  

    const stopAudioCall = () =>{
        callingAudio.pause()
        callingAudio.loop = false; 
        callingAudio.currentTime = 0;
    }
 
    const validatingCallRequirement = () =>{
        navigator.mediaDevices.getUserMedia({video:true, audio: true})
        .then((stream) => initializeCall(stream) )
        .catch(() =>{
            close()
            Notify.customToast('Camera/Mic Not Found.', 'Enable your camera to continue.')
        }) 
    }

    const sendingRTC = (webrtcid) =>{ 
        var formdata = new FormData();  
            formdata.set('token', token); 
            formdata.set('management_id', management_id);   
            formdata.set('user_id', user_id);   
            formdata.set('username', username);   
            formdata.set('room_number', room_number);    
            formdata.set('patient_id', appdetails.patient_id);   
            formdata.set('appointment_id', appdetails.appointment_id);   
            formdata.set('_your_web_rtc_id', webrtcid);   
            formdata.set('connection', this.state.connection); 
            
        Axios.post('doctor/online/checkup/create-room', formdata )
        .then((response) => { 
            const data = response.data;    
            if(data === 'success'){  
                Notify.successRequest('rtc sent to patient');  
            } 
        }).catch(error=>{ 
            Notify.requestError(error);
        }); 
    }

    const getClientWebRtcId = () => { 
         _webidPatient = setInterval(() => {
            var formdata = new FormData();  
                formdata.set('token', token); 
                formdata.set('management_id', management_id);   
                formdata.set('user_id', user_id);   
                formdata.set('username', username);   
                formdata.set('room_number', room_number);    
                formdata.set('patient_id', appdetails.patient_id);  
                formdata.set('connection', this.state.connection); 
                
                Axios.post('doctor/online/checkup/patient-web-rtc-id', formdata )
                .then( (response) => { 
                    const data = response.data;    
                    if(data.length > 0){  
                        setIsAnswer(true)
                        _peer.signal(JSON.parse(data[0].patient_webrtc_id));  
                        return clearInterval(_webidPatient);
                    } 
                }).catch(error=>{  
                    Notify.requestError(error);
                });
        }, 5000); 
    }  

    const initializeCall = (stream) =>{     
        _peer = new SimplePeer({ 
            initiator: true,
            trickle: false,
            stream: stream, 
        });   

        _peer.on('error', error => console.log('error', error))

        _peer.on('signal',(data) => {  
            let myrtc = JSON.stringify(data); 
            sendingRTC(myrtc) 
        }) 

        _peer.on('stream',(stream) => {  
            if(clientvideoRef.current){  
                clientvideoRef.current.srcObject = stream;
                clientvideoRef.current.play();     
            }   
        }) 

        if(myvideoRef.current){ 
            setMyStream(stream)
            getClientWebRtcId()
            myvideoRef.current.srcObject = stream;
            myvideoRef.current.play();     
        }     
    }

    const stopMyCamAndMic = () =>{
        mystream.getTracks().forEach(function(track) { track.stop(); });
    }

    const cancelCall = () =>{    
        stopMyCamAndMic()
        stopAudioCall()
        close()
    }    

    useEffect(() => {    

        validatingCallRequirement()

         // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])

    return(
        <Dialog
            open = { open }
            onClose = { close }
            disableBackdropClick 
            fullScreen
            
        >
            <DialogContent style={{
                backgroundColor: '#000'
            }}>   
                    <Box
                        display="flex"
                        justifyContent="center" 
                        alignItems="center"
                        height={'calc(100vh - 50px)'}
                        border={1}
                    > 
                        { ! isanswer && (
                            <Box border={1} p={2}>
                                <Box>
                                    <AccountCircleIcon style={{ fontSize: '10em' }} color="primary" />
                                </Box>

                                <Box my={4}>
                                    <Typography  align="center">
                                        calling...
                                    </Typography>
                                </Box>

                                <Box display={`flex`}>
                                    <Box flexGrow={1}>
                                        <IconButton
                                            style={{
                                                backgroundColor: red[500],
                                                color: 'white'
                                            }}
                                            onClick={ cancelCall }
                                            
                                        >
                                            <CallEndIcon />
                                        </IconButton> 
                                    </Box>

                                    <Box>
                                        <IconButton
                                            style={{
                                                backgroundColor: grey[500],
                                                // backgroundColor: green[500],
                                                color: 'white'
                                            }}
                                            disabled
                                        >
                                            <CallIcon />
                                        </IconButton> 
                                    </Box>
                                </Box>
                            </Box> 
                        )}  
                         { isanswer && ( 
                            <Box>
                                <CardMedia
                                    component="div"
                                >
                                    <video  
                                        playsInline
                                        style={{            
                                            objectFit: 'cover',
                                            border: `2px solid ${blue[500]}`,
                                            borderRadius: '3px',
                                        }}
                                        className={'img-fluid'}
                                        ref={ clientvideoRef } 
                                    /> 
                                </CardMedia>
                            </Box>    
                        )}
                    </Box> 


                {/* video tags */} 
                <video   // myvideo tag
                    playsInline
                    style={{    
                        position: 'absolute',
                        maxHeight: '150px',
                        top: '10px',
                        left:'10px', 
                        border: `2px solid ${blue[500]}`,
                        borderRadius: '3px',
                    }} 
                    ref={ myvideoRef } 
                    muted 
                /> 

                {/* client video */} 
            </DialogContent>
        </Dialog>
    )
}

export default CallPatient;