import React, { Component, Fragment } from 'react';  
import { Alert, Col, Row, FormControl, Button, Modal } from 'react-bootstrap'; 
import Notify from '../../notification/Notify';
import axios from 'axios';
import SimplePeer from 'simple-peer';
import MediaHandler from '../../MediaHandler';
import { toast } from 'react-toastify';
import OnlinechecupChat from '../../oncheckupchat/OnlinechecupChat'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faTrashAlt, faPhoneSquareAlt, faUserCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import AppointmentSetDone from './AppointmentSetDone'; 
import PatientHeader from '../patients/PatientHeader';
import PermissionRequest from './PermissionRequest';
       
export default class CreateRoom extends Component{
    _mounted = false;
    _peer = null;
    _timer = null;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'), 

            _room_number:  'room-'+ (Math.floor(Math.random()*90000) + 10000),  

            _accept_patient_btn: true,
            _vlabel_container: false,
            _show_vcontainer: true,

            _peer_status: null,
            _appointment_id : this.props.match.params.appointment_id,
            _appointment: [],
            _appointment_ready: false,

            _room_created_successful: false,

            _call_time_consumed_seconds: 0,
            _call_time_consumed_minutes: 0,

            _vcontroller_open: true,

            _btn_endcall: true,
            _btn_removeroom: true,
            _btn_successapp: true,
            _appointment_done: false,

            _openpatient_profile: false,
            _showalert: true,

            _ispermitted: false,
        }    

        this.windowHeight = window.innerHeigh;

        this.webidPatient = null;
        this.checkConnectionInterval = null;
    } 
    
    getAppointmentDetails(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token);
        formdata.set('user_id', this.state._user_id);   
        formdata.set('appointment_id', this.state._appointment_id);   
        formdata.set('connection', 'online');   
        
        axios.post('doctor/online/checkup/appointment-details', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _appointment: data,
                    _appointment_ready: true,
                }) 
                this.initializeRTC();
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        }); 
    }

    componentDidMount(){
        this._mounted = true;  

        this.mediaHandler = new MediaHandler();

        this.mywebrtcid = React.createRef();
        this.clientwebrtcid = React.createRef();
        this.myvideocontainer = React.createRef();
        this.clientvideocontainer = React.createRef();
        this.connectbtn = React.createRef(); 
        this.getAppointmentDetails(); 
    } 

    initializeRTC(){
        this.getClientWebRtcId();
        this.mediaHandler.getPermissions()
            .then((stream) => { 
                this._peer = new SimplePeer({ 
                    initiator: window.location.hash === '#initialization',
                    trickle: false,
                    stream: stream, 
                }); 

                this._peer.on('error', error => console.log('error', error))

                this._peer.on('signal',(data) => { 
                    this.mywebrtcid.value = JSON.stringify(data)
                    this.createRoom();  
                    this.getProfilePermission()
                }) 

                this._peer.on('stream',(stream) => { 

                    if ('srcObject' in this.clientvideocontainer) {
                        this.clientvideocontainer.srcObject = stream
                    } else {
                        this.clientvideocontainer.src = window.URL.createObjectURL(stream) // for older browsers
                    } 
                    
                    this.clientvideocontainer.play(); 

                    this.setState({
                        _show_vcontainer: false,
                    })
                })
 
                if(this.myvideocontainer){
                    if ('srcObject' in this.myvideocontainer) {
                        this.myvideocontainer.srcObject = stream
                    } else {
                        this.myvideocontainer.src = window.URL.createObjectURL(stream) // for older browsers
                    } 

                    this.myvideocontainer.play();    
                } else{
                    Notify.customToast('Video Error', 'Unable to load video container.')
                }
            })    
        setTimeout(() => {
            this.checkConnectionRTC();
        }, 3000); 
    } 

    checkConnectionRTC(){
        this.checkConnectionInterval = setInterval(()=>{ 
            if(this._peer !== null ){
                if(this._peer._pc !== null ){
                    this.setState({
                        _peer_status: this._peer._pc.connectionState
                    })
                    if(this._peer._pc.connectionState === 'disconnected'){
                        this._peer = null;
                        toast.error('Call ended, Connection has been terminated.');
                    } 
                }else{
                    this._peer = null; 
                    this.setState({
                        _peer_status: 'client-disconnected',
                    })
                    toast.error('Your patient is destroying the room..'); 
                } 
            }  
        }, 5000) 
    }

    removeRoom = () =>{ 
        var formdata = new FormData();  
            formdata.set('token', this.state._token); 
            formdata.set('management_id', this.state._management_id);   
            formdata.set('user_id', this.state._user_id);   
            formdata.set('username', this.state._username);   
            formdata.set('room_number', this.state._room_number);    
            formdata.set('patient_id', this.state._appointment.length > 0 ? this.state._appointment[0].patient_id : null);    
            formdata.set('connection', 'online');    

            
        axios.post('doctor/online/checkup/remove-room', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                if(data === 'success'){ 
                    setTimeout(() => {
                        window.location.href="/doctor"
                    }, 2000);
                    Notify.successRequest('remove room');  
                }
                if(data === 'db-error'){
                    Notify.warnRequest('remove room')
                }
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        }); 
    }

    createRoom(){
        var formdata = new FormData();  
            formdata.set('token', this.state._token); 
            formdata.set('management_id', this.state._management_id);   
            formdata.set('user_id', this.state._user_id);   
            formdata.set('username', this.state._username);   
            formdata.set('room_number', this.state._room_number);    
            formdata.set('patient_id', this.state._appointment.length > 0 ? this.state._appointment[0].patient_id : null);   
            formdata.set('appointment_id', this.state._appointment_id);   
            formdata.set('_your_web_rtc_id', this.mywebrtcid.value);   
            formdata.set('connection', 'online');    
            
        axios.post('doctor/online/checkup/create-room', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                if(data === 'success'){ 
                    this.setState({
                        _room_created_successful: true,
                        _btn_removeroom: false // enable remove room when room is successfully created
                    }) 
                    Notify.successRequest('create room');  
                }
                if(data === 'db-error'){
                    Notify.warnRequest('create room')
                }
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    handleConnect(){ 
        try{
            this._peer.signal(JSON.parse(this.clientwebrtcid.value));  
            this.startCallTimer();
            this.setState({
                _vlabel_container: true,
                _vcontroller_open: true,
                _btn_removeroom: true,
                _btn_endcall: false,
            })
        }catch{
            toast.error('Unable to connect to your client, Try to reload the page.');
        }
    }

    getClientWebRtcId(){
        // this.state._patient_webid_ready ? 
         this.webidPatient = setInterval(() => {
            var formdata = new FormData();  
                formdata.set('token', this.state._token); 
                formdata.set('management_id', this.state._management_id);   
                formdata.set('user_id', this.state._user_id);   
                formdata.set('username', this.state._username);   
                formdata.set('room_number', this.state._room_number);    
                formdata.set('patient_id', this.state._appointment.length > 0 ? this.state._appointment[0].patient_id : null);   
                formdata.set('connection', 'online');   
                
                axios.post('doctor/online/checkup/patient-web-rtc-id', formdata )
                .then(function(response){ 
                    const data = response.data;   
                    if(this._mounted){ 
                        if(data.length > 0){ 
                            this.clientwebrtcid.value = data[0].patient_webrtc_id;
                            // this.handleConnect();
                            this.setState({
                                _accept_patient_btn: false
                            })
                            return clearInterval(this.webidPatient);
                        }
                    } 
                }.bind(this)).catch(error=>{ 

                    Notify.requestError(error);
                });
        }, 5000); 
    }  

    startCallTimer(){
        this._timer = setInterval(() => {
            this.setState({
                _call_time_consumed_seconds: this.state._call_time_consumed_seconds >=60 ? 0 : this.state._call_time_consumed_seconds + 1,
                _call_time_consumed_minutes: this.state._call_time_consumed_seconds >= 60 ? this.state._call_time_consumed_minutes + 1 : this.state._call_time_consumed_minutes,
            })
        }, 5000);
    }

    videoCallEnded = () =>{
        clearInterval(this._timer);
        if(this._peer !== null){
            this._peer._pc.close();  
            this._peer = null;

            this.setState({
                _peer_status: 'call-ended',
                _btn_successapp: false,
                _btn_endcall: true,
                _btn_removeroom: true,
            })
        }
    } 

    handleOpenPatientProfile = () =>{
        this.setState({
            _openpatient_profile: true
        }) 
        this.getProfilePermission()
    }

    handleClosePatientProfile = () =>{
        this.setState({
            _openpatient_profile: false
        })
        this.getProfilePermission()
    }

    getProfilePermission = () =>{
        var formdata = new FormData();   
            formdata.set('token', this.state._token);  
            formdata.set('doctors_id', this.state._user_id);    
            formdata.set('patient_id', this.state._appointment.length > 0 ? this.state._appointment[0].patient_id : null);    
            formdata.set('connection', 'online');    
            
        axios.post('doctor/online/checkup/check-profilepermission', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(data.length > 0){
                this.setState({
                    _ispermitted: true
                })
            }
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        }); 
    }

    componentWillUnmount(){  
        this._mounted = false; 
        this.removeRoom();
        this.videoCallEnded();
        clearInterval(this.webidPatient)
        clearInterval(this.checkConnectionInterval)
    }

	render(){  

		return(
            <Fragment>
                {
                    this.state._appointment_ready ? 
                        this.state._appointment.length > 0 ?
                            <Fragment>
                                <Alert variant="danger text-center p-1 rounded-0 m-0 p-0" show={ this.state._showalert } onClose={() => this.setState({_showalert: false})} dismissible>
                                    <strong> Please do not reload the page while waiting for your patient to join.</strong><br/>
                                    <small> If page is reload, the room number will be change and your patient cannot access or join. </small>
                                </Alert>  

                                {
                                    this.state._peer_status === 'connecting' ? 
                                        <Alert className="bg-cyan text-center p-1 rounded-0 pb-0 mb-0 small text-white"> 
                                            <b className="text-uppercase">Room Number :  { this.state._room_number } </b> <br />
                                            Patient Joining, Your patient is entering your room please click the button to allow the patient to enter.
                                        </Alert>
                                    :
                                    this.state._peer_status === 'connected' ? 
                                        <Alert className="bg-green text-center p-1 rounded-0 pb-0 mb-0 small text-white">
                                            <b className="text-uppercase">Room Number :  { this.state._room_number } </b> <br />
                                            Patient Entered, You are now connected to each other. 
                                        </Alert>
                                    :
                                    this.state._peer_status === 'disconnected' ? 
                                        <Alert className="bg-red text-center p-1 rounded-0 pb-0 mb-0 small text-white"> 
                                        Patient is Disconnected, Your patient connection lost it's either he/she is reloading the page.
                                        </Alert>
                                    :
                                    this.state._peer_status === 'call-ended' ? 
                                        <Alert className="bg-red text-center p-1 rounded-0 pb-0 mb-0 small text-white"> 
                                            Room disconnected, You are destroying the room and ending the call.
                                        </Alert>
                                    :
                                    this.state._peer_status === 'client-disconnected' ? 
                                        <Alert className="bg-red text-center p-1 rounded-0 pb-0 mb-0 small text-white"> 
                                            Patient is disconnected, Your client is disconnecting while waiting for your confirmation, <Button size="sm" onClick={()=>window.location.reload()}>Reload</Button> the page to create new room because the current room is destroyed.
                                        </Alert>
                                    :null
                                }

                                <Row  
                                    className="m-0 p-0 patientvcall-container" 
                                    style={{
                                        background: '#000', 
                                        height: this.windowHeight,
                                    }}
                                >
                                    <Col sm={this.state._openpatient_profile ? 5 : 9} className="m-0 p-0 "
                                        style={{
                                            borderWidth: '.7rem',   
                                            borderStyle: 'double',
                                            borderColor: ' #007bff',
                                        }}
                                    >
                                        {/* webid container formcontrol */}
                                        {/* my id */} 
                                        <FormControl as="textarea" hidden ref={ el => this.mywebrtcid = el} /> 
                                        {/* client id */} 
 
                                        <FormControl as="textarea" hidden ref={ el => this.clientwebrtcid = el} />  
                                        <div  
                                            className="text-center m-0 p-0"
                                            hidden={this.state._vlabel_container ? true : false }
                                            style={{  
                                            position:'relative',
                                            zIndex: 1,
                                            top:'30vh',
                                        }}>
                                            {
                                                this.state._room_created_successful ? 
                                                    <Fragment>
                                                        <p className="mt-2 text-white" >
                                                            <span className="text-capitalize" > { this.state._room_number } </span> created, waiting for the patient to join. Please do not reload the page
                                                        </p>
                                                        <Button
                                                            ref={ el => this.connectbtn = el} 
                                                            type="button"
                                                            disabled = { this.state._accept_patient_btn ? true : false }
                                                            hidden = { this.state._accept_patient_btn ? true : false }
                                                            onClick={this.handleConnect.bind(this)}
                                                        >
                                                            Allow patient to join
                                                        </Button>
                                                    </Fragment>
                                                : <p className="mt-2 text-white" >
                                                        <span className="text-muted" > Please wait and  do not reload the page </span>
                                                    </p>
                                            }
                                        </div>   

                                        {/* myvideo  */}
                                        <div className="m-0 p-0">
                                            {/* my video */} 
                                            <video   
                                                playsInline
                                                style={{    
                                                    position: 'absolute',
                                                    maxHeight: '10vw',
                                                    top: '10px',
                                                    left:'10px', 
                                                    border: '3px solid #007bff',
                                                }} 
                                                ref={ el => this.myvideocontainer = el} 
                                                muted
                                                className={'img-fluid '}
                                            /> 

                                            {/* client video */}
                                            <video 
                                                playsInline
                                                style={{      
                                                    maxHeight: '80vh',  
                                                    bottom: 0,  
                                                    backgroundColor: '#000',
                                                    objectFit: 'cover',
                                                }} className = "img-fluid d-flex w-100 mx-auto mt-0 mt-sm-5" ref={ el => this.clientvideocontainer = el} 
                                            /> 
                                        </div>    
                                        {/* video controller */}
                                        {
                                            this.state._vcontroller_open ?
                                                <Col xs={10} sm={9} className="mx-auto rounded clearfix p-2">
                                                    <Row>
                                                        <Col xs={4} className="text-center">
                                                            <span title="call time consumed minutes : seconds " className="h1 text-white"> 
                                                                {this.state._call_time_consumed_minutes}:{this.state._call_time_consumed_seconds} 
                                                            </span>
                                                            <br /> <small className="text-uppercase text-muted gtc-small d-none d-lg-block"> timer</small>
                                                        </Col>
                                                        <Col xs={8} className="pt-2 text-sm-center">
                                                            <Button 
                                                                disabled={this.state._btn_successapp}
                                                                variant={this.state._btn_successapp ? "btn-link text-success m-0 p-0 mb-2 cursor-not-allowed" :"btn-link text-success m-0 p-0 mb-2" } 
                                                                onClick={()=>this.setState({ _appointment_done:true })}
                                                                title="Appointment Successful"
                                                            > 
                                                                <FontAwesomeIcon icon={faCheckSquare} size="2x" /> 
                                                                { this.state._openpatient_profile ? null : <Fragment> <br /> <small className="text-uppercase text-muted gtc-small d-none d-xl-block"> successful </small> </Fragment>}
                                                            </Button>
                                                            <Button 
                                                                disabled={this.state._btn_removeroom}
                                                                variant={this.state._btn_removeroom ? "btn-link text-danger m-0 p-0 mb-2 ml-3 ml-md-5 cursor-not-allowed": "btn-link text-danger m-0 p-0 mb-2 ml-3 ml-md-5"}
                                                                onClick={this.removeRoom} 
                                                                title="Remove Room"
                                                            > 
                                                                <FontAwesomeIcon icon={faTrashAlt} size="2x" /> 
                                                                { this.state._openpatient_profile ? null : <Fragment> <br /> <small className="text-uppercase text-muted gtc-small d-none d-xl-block"> RemoveRoom </small> </Fragment>}
                                                            </Button>
                                                            <Button 
                                                                disabled={this.state._btn_endcall}
                                                                variant={this.state._btn_endcall ? "btn-link text-warning m-0 p-0 mb-2 ml-3 ml-md-5 cursor-not-allowed" : "btn-link text-warning m-0 p-0 mb-2 ml-3 ml-md-5"} 
                                                                onClick={this.videoCallEnded} title="End session"
                                                            > 
                                                                <FontAwesomeIcon icon={faPhoneSquareAlt} size="2x" /> 
                                                                { this.state._openpatient_profile ? null : <Fragment> <br /> <small className="text-uppercase text-muted gtc-small d-none d-xl-block"> EndSession </small> </Fragment>}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            :null
                                        }
                                    </Col> 

                                    <Col sm={this.state._openpatient_profile ? 7 : 3} className="m-0 p-0">  
                                        {
                                            this.state._openpatient_profile ?
                                                <Fragment>
                                                    <div className="bg-white px-3 py-2" style={{
                                                            background: '#000',    
                                                            height: 'calc(100vh)',
                                                        }}
                                                    > 
                                                        <p className="m-0 clearfix mb-2">
                                                            <span className="header-label text-muted text-capitalize h5"> Patient Profile </span>
                                                            <span className="pointer text-danger float-right" onClick={ this.handleClosePatientProfile }> 
                                                                <FontAwesomeIcon style={{ fontSize: '1.5rem'}} icon={faTimesCircle} /> 
                                                            </span>
                                                        </p>
                                                        
                                                        <div className="bg-white px-1" style={{
                                                                background: '#000',  
                                                            }}
                                                        >  
                                                            {/* check permission if allowed */}
                                                            {
                                                                this.state._ispermitted ?
                                                                    <PatientHeader 
                                                                        patient_id = {this.state._appointment.length > 0 ? this.state._appointment[0].patientold_id : null}
                                                                        connection = 'online'
                                                                    />
                                                                :
                                                                    <PermissionRequest 
                                                                        patient_id = {this.state._appointment.length > 0 ? this.state._appointment[0].patient_id : null} 
                                                                        closeTab  = {() => this.setState({ _openpatient_profile: false })}
                                                                    />
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </Fragment> 
                                         : 
                                            <Fragment>
                                                <Button variant="success rounded-0" block onClick={ this.handleOpenPatientProfile }> <FontAwesomeIcon icon={faUserCircle} /> Patient Profile </Button>
                                                <OnlinechecupChat client_id = {this.state._appointment.length > 0 ? this.state._appointment[0].patient_id : null}/>   
                                            </Fragment> 
                                        }
                                    </Col>
                                </Row> 
                            </Fragment>
                        : Notify.noRecord()
                    : Notify.loading()
                }

                {/* appointment set as sucesss */}
                <Modal centered show={this.state._appointment_done} onHide={()=>this.setState({ _appointment_done: false})}>
                    <Modal.Header className="bg-success text-white" closeButton>
                        Appointment set success
                    </Modal.Header>
                    <Modal.Body>
                        <AppointmentSetDone 
                            closeDialog = {()=>this.setState({_appointment_done: false})}
                            appid= {this.state._appointment_id}
                            reference_no = {this.state._appointment.length > 0 ? this.state._appointment[0].reference_no : null}
                            patient_id = {this.state._appointment.length > 0 ? this.state._appointment[0].patient_id : null}
                            consumed_time = {this.state._call_time_consumed_minutes +':'+this.state._call_time_consumed_seconds}
                        />
                    </Modal.Body>
                </Modal>
            </Fragment>
		)
	}
}
