import React, { useState, Fragment, useEffect } from 'react';
import Axios from 'axios';
import Notify from '../../../notification/Notify';
import { Button, DialogContent, Typography, DialogActions, } from '@material-ui/core';

const AccountActivate = ({ selectedUserId, closeModal, reRenderModal }) => {
    const [token] = useState(localStorage.getItem('token'))
    const [management_id] = useState(localStorage.getItem('management_id'))
    const [user_id] = useState(localStorage.getItem('user_id'))

    const handleDeActivateAcct = (e) => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('management_id', management_id);
        formdata.set('uuser_id', selectedUserId);

        Axios.post('malita/pharmacy/update-selected-user-active-status', formdata)
            .then((response) => {
                const data = response.data;
                if (data === 'success') {
                    reRenderModal();
                    Notify.successRequest('activate user')
                }
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {

    }, [selectedUserId])

    return (
        <Fragment>
            <DialogContent>
                <Typography>
                    Are you sure to activate this account?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="default" onClick={() => closeModal()}> No </Button>
                <Button variant="contained" color="primary" onClick={() => {
                    handleDeActivateAcct();
                    closeModal()
                }}> Yes </Button>
            </DialogActions>
        </Fragment >
    )
}

export default AccountActivate;