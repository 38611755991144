import React, { Component, Fragment } from "react"   
import { Col } from "react-bootstrap";

export default class GTCLogo extends Component{
    _isMounted = false;
    constructor(props){
        super(props); 
        this.state={}
    }

    componentDidMount(){
        this._isMounted = true;  
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){
        return(
            <Fragment>
                <Col sm={12} className="mx-auto mb-5 mb-sm-0">
                    <img src="/gtc-logo.png" alt="" className="d-flex col-xs-12 col-lg-8 mx-auto"/> 
                </Col>
            </Fragment> 
        )
    }
}