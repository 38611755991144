import React, { Fragment, useEffect, useState } from 'react';   
import Axios from 'axios'; 
import Notify from '../../../notification/Notify';
import { Form, Col, FormGroup, FormControl, Row, Button, Badge } from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, Dialog, DialogTitle, Typography, DialogContent, IconButton } from '@material-ui/core';
import CheckInternet from 'src/utils/CheckInternet';
import NoInternet from 'src/NoInternet';
import CheckingConnection from 'src/CheckInternet';
import UnsavePrescription from './UnsavePrescription';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const PrescriptionVirtual = ({ patient_id, displayUnsave, getUnsavePrescription, unsaveCount }) => {

    const user_id = localStorage.getItem('user_id')
    const token = localStorage.getItem('token')
    const management_id = localStorage.getItem('management_id') 

    const [virtual, setVirtual] = useState({
        data: [],
        ready: false
    }) 

    const [vproduct, setVProduct] = useState([]) 
    const [selectedVirtualPharmacy, setSelectedVirtualPharmacy] = useState(0)

    const [isProcess, setIsProcess] = useState(false)
    const [online, setOnline] = useState('checking')

    // const [unsave_product_count, setunsave_product_count] = useState(0)
    const [selected_prod_type, setselected_prod_type] = useState('')
    const [selected_prod_name, setselected_prod_name] = useState('')
    const [selected_prod_amount, setselected_prod_amount] = useState(0) 

    const [ dialog, setDialog] = useState(false)

    const getVirtualPrescription = () => {
        var formdata = new FormData();  
        formdata.set('token', token);
        formdata.set('connection', 'online')     
        Axios.post('prescription/doctor/virtual/virtuallist', formdata )
        .then( (response) => { 
            const data = response.data;    
            setVirtual({ data: data, ready: true }) 
        }).catch(error=>{  
            Notify.requestError(error);
        });
    }

    const handleSelectedProdByPharmacy = (e) => {
        // e.value
        var virtual_parmacy = e.value
        var formdata = new FormData();  
        formdata.set('token', token);
        formdata.set('connection', 'online') 
        formdata.set('user_id', user_id);    
        formdata.set('pharmacy_id', virtual_parmacy);    
        
        Axios.post('prescription/virtual/pharmacyproducts-list', formdata )
        .then( (response) => { 
            const data = response.data;    
            setVProduct(data)
            setSelectedVirtualPharmacy(virtual_parmacy) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }


    const handleSelectedProdByPharmacyDetails = (e) => {
        // e.value 
        var formdata = new FormData();  
        formdata.set('token', token);
        formdata.set('connection', 'online') 
        formdata.set('user_id', user_id);    
        formdata.set('pharmacy_id', selectedVirtualPharmacy);    
        formdata.set('product_id', e.value);
        
        Axios.post('prescription/virtual/pharmacyproductsdetails-list', formdata )
        .then( (response) => { 
            const data = response.data;    
            if(data.length > 0){ 
                setselected_prod_name(data[0].product_name)
                setselected_prod_amount(data[0].product_amount)
                setselected_prod_type(data[0].unit)
            }   
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    const addToPrecription = e => {
        e.preventDefault();
        e.persist();
        var formdata = new FormData(e.target);
        var error = [];
        formdata.set('token', token);
        formdata.set('connection', 'online') 
        formdata.set('user_id', user_id);    
        formdata.set('management_id', management_id);    
        formdata.set('patient_id', patient_id);     
        formdata.set('prescription_type', 'virtual'); 

        if(formdata.get('virtual_parmacy').length === 0 || formdata.get('virtual_parmacy').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('virtual_parmacy'); 
        }

        if(formdata.get('prescription').length === 0 || formdata.get('prescription').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('prescription'); 
        }

        if(formdata.get('order_qty').length === 0 || formdata.get('order_qty').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('order qty'); 
        }

        if(formdata.get('dosage').length === 0 || formdata.get('dosage').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('dosage'); 
        }

        // if(formdata.get('take_every').length === 0 || formdata.get('take_every').trim()===''){ 
        //     error = 'error';
        //     Notify.fieldRequired('every take'); 
        // }

        // if(formdata.get('take_times').length === 0 || formdata.get('take_times').trim()===''){ 
        //     error = 'error';
        //     Notify.fieldRequired('take times'); 
        // }

        if(error.length > 0){
            Notify.consoleLog('virtual prescription')
        }else{ 
            setIsProcess(true)
            Axios.post('prescription/virtual/prescription-viruatladd', formdata )
            .then( (response) => { 
                const data = response.data;   
                if(data === 'success'){
                    e.target.reset();
                    getUnsavePrescription()
                    Notify.successRequest('virtual prescription');
                }else{
                    Notify.warnRequest('virtual prescription');
                }
            }).catch(error=>{  
                Notify.requestError(error);
            }).finally(() =>{
                setIsProcess(false)
            });
        }
    }  

    const checkinternet = () => {
        CheckInternet.online().then(() => setOnline('connected')).catch(() => setOnline('disconnected'))
    }

    useEffect(() =>{

        checkinternet() 

        if(online === 'connected'){   
            getVirtualPrescription()  
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[patient_id, online]) 

    return(
        <Fragment>
            { online === 'checking' && <CheckingConnection /> }
            { online === 'disconnected' && <NoInternet /> }
            { online === 'connected' && (
                <>  
                    <Box mb={1}>
                        <Typography variant="subtitle2" color="primary"> CREATE VIRTUAL PRESCRIPTION </Typography>
                    </Box>
                    {
                        virtual.ready ?
                            virtual.data.length > 0 ?
                                <> 
                                    <Form onSubmit={ addToPrecription }>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <span className="gtc-small text-uppercase text-muted"> Virtual Pharmacy </span>
                                                    <Select
                                                        name="virtual_parmacy"
                                                        options={virtual.data} 
                                                        onChange ={ handleSelectedProdByPharmacy } 
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <span className="gtc-small text-uppercase text-muted"> Prescription </span>
                                                    <Select
                                                        name="prescription"
                                                        options={ vproduct } 
                                                        onChange ={ handleSelectedProdByPharmacyDetails } 
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col sm={6}>
                                                <FormGroup>
                                                    <span className="gtc-small text-uppercase text-muted"> order qty </span>
                                                    <FormControl type="number" min="1" name="order_qty"/>
                                                    {/* hidden files */}
                                                    <FormControl name="product_name" value={selected_prod_name} readOnly hidden />
                                                    <FormControl name="product_amount" value={selected_prod_amount} readOnly hidden />
                                                </FormGroup>
                                            </Col> 
 
                                            <Col sm={6}>
                                                <FormGroup>
                                                    <span className="gtc-small text-uppercase text-muted"> Type </span>
                                                    <FormControl name="type" defaultValue={ selected_prod_type } readOnly/>
                                                </FormGroup> 
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <span className="gtc-small text-uppercase text-muted"> dosage </span>
                                                    <FormControl name="dosage"/>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4} hidden>
                                                <FormGroup>
                                                    <span className="gtc-small text-uppercase text-muted"> Take Every </span>
                                                    <FormControl name="take_every"/>
                                                </FormGroup>
                                            </Col>
                                            <Col sm={4} hidden>
                                                <FormGroup>
                                                    <span className="gtc-small text-uppercase text-muted"> Times </span>
                                                    <FormControl name="take_times"/>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <FormGroup>
                                            <span className="gtc-small text-uppercase text-muted"> Sig. </span>
                                            <FormControl name="remarks"/>
                                        </FormGroup>

                                        {/* <FormGroup>
                                            <span className="gtc-small text-uppercase text-muted"> brand </span>
                                            <FormControl name="brand" as="select">
                                                <option value=""> --Select-- </option>
                                                <option value="FK">FK</option>
                                                <option value="BBRAUN">BRAUN</option>
                                            </FormControl>
                                        </FormGroup> */}

                                        <p className="m-0 mb-2 text-muted"> Are you sure to continue adding this item? </p>
                                        <Button 
                                            type="submit" 
                                            variant="success"
                                            disabled = { isProcess }
                                        > <FontAwesomeIcon 
                                            icon={ isProcess ? faSpinner : faCheckCircle} 
                                            spin={ isProcess } 
                                            /> Add </Button>
                                        <Button type="reset" variant="secondary ml-2"> <FontAwesomeIcon icon={faTimesCircle} /> No </Button>
                                        <Button 
                                            type="button" 
                                            variant="primary ml-2"
                                            onClick = { () =>  displayUnsave() }
                                        > <Badge variant="danger"> { unsaveCount } </Badge> Unsave Prescription </Button>
                                    </Form>
                                </>
                            :  Notify.noRecord() 
                        :   Notify.loading() 
                    }
                </>
            )}

            <Dialog 
                open={ dialog } 
                onClose={() => setDialog(false)} 
                maxWidth="sm" 
                fullWidth
            >
                <Box display="flex">
                    <Box flexGrow={1}>
                        <DialogTitle>
                            Virtual Unsave Prescription
                        </DialogTitle>
                    </Box>
                    <Box>
                        <IconButton onClick={() => setDialog(false)}>
                            <HighlightOffIcon color="secondary" />
                        </IconButton>
                    </Box>
                </Box>

                <DialogContent dividers>
                    <UnsavePrescription 
                        connection="online" 
                        patient_id = {patient_id} 
                    />
                </DialogContent>
            </Dialog>
        </Fragment>
    ) 
}

export default PrescriptionVirtual