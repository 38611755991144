import React, { useState, useEffect, Fragment, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faChartPie, faVials, faXRay, faCalendarAlt, faMoneyCheckAlt, faIdCardAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Personal from './information/Personal';
import Vitals from './vitalsgraph/Vitals';
import Appointment from './appointment/Appointment';
import Axios from 'axios';
import Notify from '../notification/Notify';
import Credits from './Credits/Credits';
import Laboratory from './laboratory/Laboratory';
import Imaging from './imaging/Imaging';
import Subscription from './subscription/Subscription';
import { Box, Tabs, Tab, Badge, Paper } from '@material-ui/core';
import TabPanel from '../utils/TabPanel';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import CropFree from '@material-ui/icons/CropFree';
import Permissions from './permissions/Permissions';
import Cart from './cart/Cart';
import MyQRCode from './qrcode/MyQRCode';
import {
    OrderContext,
    PatientCategoryContext, 
    // ThemeContext 
} from 'src/ContextAPI';

// const useStyle = makeStyles({
//     indicator: {
//         backgroundColor: 'white',
//     },
// });

const PatientsCategory = ({ checkAppointment }) => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const categoryContext = useContext(PatientCategoryContext) 

    const category = checkAppointment ? 'appointment' : categoryContext._category
    const [appCount, setAppCount] = useState(0)
    const orderContext = useContext(OrderContext)
    // const themContext = useContext(ThemeContext)

    const handleChange = (event, newcategory) => {
        event.persist()
        // setCategory(newcategory);
        categoryContext._updateCategory(newcategory)
    };

    const getAppCount = useCallback(() => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);

        Axios.post('appointment/approve/approve-count', formdata)
            .then((response) => {
                const count = response.data;
                setAppCount(count)
            }).catch(error => {
                Notify.requestError(error);
            });
    }, [token, user_id]);

    useEffect(() => {
        getAppCount()
    }, [getAppCount])

    return (
        <Fragment>
            <Box
                component={Paper}
                variant="outlined"
                mb={2}
            >
                <Tabs
                    variant="scrollable"
                    value={category}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    indicatorColor={'primary'}
                    textColor={"primary"}
                    scrollButtons="on"
                >
                    <Tab
                        wrapped
                        fullWidth
                        label="Profile"
                        value="profile"
                        icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faUserAlt} />}
                    />

                    <Tab
                        wrapped
                        fullWidth
                        label="Graph"
                        value="graph"
                        icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faChartPie} />}
                    />

                    <Tab
                        wrapped
                        fullWidth
                        label="Laboratory"
                        value="laboratory"
                        icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faVials} />}
                    />

                    <Tab
                        wrapped
                        fullWidth
                        label="Imaging"
                        value="imaging"
                        icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faXRay} />}
                    />

                    <Tab
                        wrapped
                        fullWidth
                        label="Appointment"
                        value="appointment"
                        icon={
                            <Badge badgeContent={appCount} color="error">
                                <FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faCalendarAlt} />
                            </Badge>
                        }
                    />

                    <Tab
                        wrapped
                        fullWidth
                        label="Credits"
                        value="credits"
                        icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faMoneyCheckAlt} />}
                    />

                    <Tab
                        wrapped
                        fullWidth
                        label="Subscription"
                        value="subscription"
                        icon={<FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faIdCardAlt} />}
                    />

                    <Tab
                        wrapped
                        fullWidth
                        label="Permission"
                        value="permission"
                        icon={<EnhancedEncryptionIcon />}
                    />

                    <Tab
                        wrapped
                        fullWidth
                        label="Cart"
                        value="cart"
                        icon={
                            <Badge
                                color="error"
                                badgeContent={orderContext.__cart.length}
                            >
                                <FontAwesomeIcon style={{ fontSize: '1.5em' }} icon={faShoppingCart} />
                            </Badge>
                        }
                    />

                    <Tab
                        wrapped
                        fullWidth
                        label="My Qr"
                        value="qrcode"
                        icon={<CropFree />}
                    />
                </Tabs>
            </Box>

            <Box>
                <TabPanel value={category} index={'profile'}>
                    <Personal />
                </TabPanel>

                <TabPanel value={category} index={'graph'}>
                    <Vitals />
                </TabPanel>

                <TabPanel value={category} index={'laboratory'}>
                    <Laboratory />
                </TabPanel>

                <TabPanel value={category} index={'imaging'}>
                    <Imaging />
                </TabPanel>

                <TabPanel value={category} index={'appointment'}>
                    <Appointment />
                </TabPanel>

                <TabPanel value={category} index={'credits'}>
                    <Credits />
                </TabPanel>

                <TabPanel value={category} index={'subscription'}>
                    <Subscription />
                </TabPanel>

                <TabPanel value={category} index={'permission'}>
                    <Permissions />
                </TabPanel>

                <TabPanel value={category} index={'cart'}>
                    <Cart />
                </TabPanel>

                <TabPanel value={category} index={'qrcode'}>
                    <MyQRCode />
                </TabPanel>
            </Box>
        </Fragment>
    )
}

export default PatientsCategory;