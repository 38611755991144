import React, { useCallback, useEffect, useState } from 'react'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Slide, Dialog, DialogContent, DialogActions, Badge } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import Label from 'src/utils/Label';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// import ProcessOrder from './ProcessOrder';
// import ConfirmOrderFromVPharm from './ConfirmOrderFromVPharm';
// import CancelledOrderDetails from './CancelledOrderDetails';
// import PickupDetails from './PickupDetails';
import ChangeMethod from './ChangeMethod';
import CompleteOrder from './CompleteOrder';
import TrashIcon from '@material-ui/icons/DeleteOutline'
import CancelIcon from '@material-ui/icons/CancelOutlined'

const Cart = () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [orders, setOrders] = useState([])
    const [ordersRd, setOrdersRd] = useState(false)

    const [open, setOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({
        data: null,
        category: '',
    })

    const [dialogDialog, setDeleteDialog] = useState(false)
    const [isdelete, setIsDelete] = useState(false)

    const getOrderByGroup = useCallback(async () => {
        try {
            var formdata = new FormData()
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.append('connection', 'online')
            const request = await Axios.post('patient/cart/order/get-byordernumber', formdata)
            setOrders(request.data);
            setOrdersRd(true)
        } catch (error) {
            Notify.requestError(error)
        }
    }, [token, user_id])

    const handleClose = () => {
        setOpen(!open);
        setSelectedOrder({
            data: null,
            category: ''
        })
        getOrderByGroup();
    }

    const handleRemoveOrderFromCart = async () => {
        try {
            setIsDelete(true)
            var formdata = new FormData()
            formdata.append('token', token)
            formdata.append('user_id', user_id)
            formdata.append('order_no', selectedOrder.data.order_no)
            const request = await Axios.post('patient/cart/order/order-delete', formdata)
            const data = request.data;
            if (data === 'success') {
                getOrderByGroup()
                setDeleteDialog(false)
                setIsDelete(false)
                Notify.successRequest('order delete')
            }
        } catch (error) {
            Notify.requestError(error)
        }
    }

    useEffect(() => {

        getOrderByGroup()

    }, [getOrderByGroup])

    return (
        <>
            <Slide in={!open} direction="right">
                <Paper hidden={open} variant="outlined">
                    <Box m={2}>
                        <Typography
                            className={`gtc-uppercase`}
                            variant="subtitle2"
                            color="textPrimary"
                        >
                            Order Information
                        </Typography>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Order Number
                                    </TableCell>
                                    <TableCell>
                                        Date/Time
                                    </TableCell>
                                    <TableCell>
                                        Status
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                    />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    ordersRd ?
                                        orders.length > 0 ?
                                            orders.map((data, index) => (
                                                <TableRow
                                                    key={index}
                                                >
                                                    <TableCell>
                                                        {parseFloat(data.unreadCount) > 0 && (
                                                            <Badge variant="dot" color="secondary">
                                                                {data.order_no}
                                                            </Badge>
                                                        )}
                                                        {parseFloat(data.unreadCount) === 0 && (data.order_no)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Notify.dateTimeConvert(data.created_at)}
                                                    </TableCell>
                                                    <TableCell className={`pointer`}>
                                                        {data.order_status === 'order-new' && (<Label color="primary">{data.order_status} </Label>)}
                                                        {data.order_status === 'order-finalized' && (<Label color="warning"> waiting-for-pharmacy-response </Label>)}
                                                        {data.order_status === 'order-forconfirmation' && (<Label color="secondary"> order-for-confirmation </Label>)}
                                                        {data.order_status === 'order-approved' && (<Label color="primary"> order-processing </Label>)}
                                                        {data.order_status === 'order-ready' && (
                                                            <Label color="success">
                                                                { parseInt(data.delivery) === 1 ? 'waiting-for-rider' : 'order-ready-for-pickup'}
                                                            </Label>
                                                        )}
                                                        {data.order_status === 'order-complete' && (<Label color="success"> transaction complete </Label>)}
                                                        {data.order_status === 'order-cancelled' && (<Label color="error"> order cancelled </Label>)}
                                                        {data.order_status === 'order-broadcast' && (<Label color="primary"> Order waiting for rider </Label>)}
                                                        {data.order_status === 'order-pickedup' && (<Label color="primary"> Order Picked up by rider</Label>)}
                                                        {data.order_status === 'order-deliver' && (<Label color="primary"> Rider start delivery </Label>)}
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                    >
                                                        {/* {data.order_status === 'order-new' && (
                                                            <>
                                                                <Box display="flex">
                                                                    <Box mr={2}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            onClick={() => {
                                                                                setDeleteDialog(true)
                                                                                setSelectedOrder({
                                                                                    data: data,
                                                                                    category: 'order-delete'
                                                                                })
                                                                            }}
                                                                        >
                                                                            <TrashIcon />
                                                                        </Button>
                                                                    </Box>
                                                                    <Box>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                setOpen(true);
                                                                                setSelectedOrder({
                                                                                    data: data,
                                                                                    category: 'order-new'
                                                                                })
                                                                            }}
                                                                            endIcon={<ArrowRightAltIcon />}
                                                                        >
                                                                            Process
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </>
                                                        )} */}

                                                        {/* {data.order_status === 'order-forconfirmation' && (
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setSelectedOrder({
                                                                            data: data,
                                                                            category: 'order-forconfirmation'
                                                                        })
                                                                    }}
                                                                    endIcon={<ArrowRightAltIcon />}
                                                                > Details </Button>
                                                            </>
                                                        )} */}

                                                        {data.order_status === 'order-complete' && (
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setSelectedOrder({
                                                                            data: data,
                                                                            category: 'order-complete'
                                                                        })
                                                                    }}
                                                                    endIcon={<ArrowRightAltIcon />}
                                                                > Details </Button>
                                                            </>
                                                        )}

                                                        {/* {data.order_status === 'order-cancelled' && (
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setSelectedOrder({
                                                                            data: data,
                                                                            category: 'order-cancelled'
                                                                        })
                                                                    }}
                                                                    endIcon={<ArrowRightAltIcon />}
                                                                > Details </Button>
                                                            </>
                                                        )}

                                                        {data.order_status === 'order-ready' && (
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setSelectedOrder({
                                                                            data: data,
                                                                            category: 'order-ready'
                                                                        })
                                                                    }}
                                                                    endIcon={<ArrowRightAltIcon />}
                                                                > Details </Button>
                                                            </>
                                                        )} */}

                                                        {data.order_status === 'order-finalized' && (
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    color="default"
                                                                    onClick={() => {
                                                                        setOpen(true);
                                                                        setSelectedOrder({
                                                                            data: data,
                                                                            category: 'order-finalized'
                                                                        })
                                                                    }}
                                                                    endIcon={<ArrowRightAltIcon />}
                                                                >
                                                                    Change | Details
                                                                </Button>
                                                            </>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Typography color="secondary">
                                                        No order found.
                                                </Typography>
                                                </TableCell>
                                            </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Typography color="primary">
                                                    Loading...
                                            </Typography>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Slide>
            <Slide in={open} direction="left">
                <Box>
                    {/* {selectedOrder.category === 'order-new' && ( 
                        <ProcessOrder
                            close={() => handleClose()}
                            selectedOrder={selectedOrder.data}
                        />
                    )} */}

                    {/* {selectedOrder.category === 'order-forconfirmation' && (
                        <ConfirmOrderFromVPharm
                            close={() => handleClose()}
                            selectedOrder={selectedOrder.data}
                        />
                    )} */}

                    {selectedOrder.category === 'order-complete' && ( /* order complete details */
                        <CompleteOrder
                            close={() => handleClose()}
                            selectedOrder={selectedOrder.data}
                        />
                    )}

                    {/* {selectedOrder.category === 'order-cancelled' && ( 
                        <CancelledOrderDetails
                            close={() => handleClose()}
                            selectedOrder={selectedOrder.data}
                        />
                    )} */}

                    {/* {selectedOrder.category === 'order-ready' && ( 
                        <PickupDetails
                            close={() => handleClose()}
                            selectedOrder={selectedOrder.data}
                        />
                    )} */}

                    {selectedOrder.category === 'order-finalized' && ( /* order cancelled details */
                        <ChangeMethod
                            close={() => handleClose()}
                            selectedOrder={selectedOrder.data}
                            getOrderByGroup={() => getOrderByGroup()}
                        />
                    )}
                </Box>
            </Slide>


            {/* delete dialog */}
            <Dialog
                open={dialogDialog}
                onClose={() => setDeleteDialog(false)}
                disableBackdropClick
            >
                <DialogContent>
                    <Typography>
                        Are you sure to remove this order from cart?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteDialog(false)}
                        variant="contained"
                        color="default"
                        startIcon={<CancelIcon />}
                    >
                        Close
                    </Button>
                    <Button
                        onClick={handleRemoveOrderFromCart}
                        variant="contained"
                        color="secondary"
                        startIcon={<TrashIcon />}
                        disabled={isdelete}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Cart;