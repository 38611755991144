import React, { useEffect, useRef, useState } from 'react'
import { Box, Paper, TextField, FormHelperText, Button, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleOutline' 
import ClearIcon from '@material-ui/icons/HighlightOff'; 
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import * as Yup from 'yup'; 
import { Formik } from 'formik';
import {DropzoneArea} from 'material-ui-dropzone' 
import L from 'leaflet';  
import { Map, TileLayer, Marker } from "react-leaflet";    
import FullscreenControl from 'react-leaflet-fullscreen';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
}); 

const NewClinic = ({ getClinics }) =>{
    const token = localStorage.getItem('token') 
    const username = localStorage.getItem('username')
 
    const [marker, setmarker] = useState({lat: 7.318881730366756,  lng: 125.48583984375001}); 
    var refmarker = useRef(null);

    const [image, setImage] = useState([])

    const handleDragMarker = (e) =>{    
        setmarker(refmarker.leafletElement.getLatLng()) 
    }

    const convertToFormdata = (object) => {
        const formData = new FormData();
            formData.append('token', token) 
            formData.append('username', username)  
            formData.append('lat', marker.lat)  
            formData.append('lng', marker.lng)  
            formData.set('image', image.length > 0 ? image[0] : '')

            Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    const handleImageChange = (files) =>{
        setImage(files)
    }

    useEffect(() => { },[marker])

    return(
        <Box 
            component={Paper}
            p={2}  
        >
            <Formik 
                initialValues={{ 
                    clinic: '',
                    address: '',
                    days_open: '',
                    time_open: '', 
                    contact_no: '', 
                    remarks: '', 
                    password: '',
                }}
                validationSchema={Yup.object().shape({  
                    clinic: Yup.string().required(),
                    address: Yup.string().required(),
                    days_open: Yup.string().required('days open is a required field'),
                    time_open: Yup.string().required('time open is a required field'), 
                    contact_no: Yup.string().required('contact number is a required field'), 
                    remarks: Yup.string().required(), 
                    password: Yup.string().required(),
                })}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting, 
                    resetForm
                }) => {   
                    try { 
                        const request = await Axios.post('gtcadmin/doctors/newclinic-save', convertToFormdata(values)) 
                        if(request.data === 'pass-invalid'){ 
                            setErrors({ submit: 'Password is invalid.' });
                            Notify.fieldInvalid('password')
                        }
                        if(request.data === 'success'){ 
                            getClinics()
                            resetForm() 
                            setSubmitting(true);
                            Notify.successRequest('new service')
                        }
                    } catch (error) {
                        const message = error.message || 'Something went wrong'; 
                        setStatus({ success: false });
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }}
                >
                {({ 
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                        noValidate 
                        onSubmit={handleSubmit}  
                    >    

                        <Box mb={2}>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                NEW CLINIC
                            </Typography>
                        </Box> 

                        <Box mb={2}>
                            <TextField 
                                fullWidth
                                required 
                                error={Boolean(touched.clinic && errors.clinic)}
                                helperText={touched.clinic && errors.clinic}  
                                onBlur={handleBlur}
                                onChange={handleChange} 
                                value={values.clinic}
                                name="clinic"
                                label="Clinic"
                                variant="outlined" 
                            />
                        </Box>

                        <Box mb={2}>
                            <TextField 
                                multiline
                                fullWidth
                                required 
                                error={Boolean(touched.address && errors.address)}
                                helperText={touched.address && errors.address}  
                                onBlur={handleBlur}
                                onChange={handleChange} 
                                value={values.address}
                                name="address"
                                label="Address"
                                variant="outlined"  
                            />
                        </Box>  

                        <Box mb={2}>
                            <TextField 
                                fullWidth
                                required 
                                error={Boolean(touched.days_open && errors.days_open)}
                                helperText={touched.days_open && errors.days_open}  
                                onBlur={handleBlur}
                                onChange={handleChange} 
                                value={values.days_open}
                                name="days_open"
                                label="Days Open"
                                variant="outlined"  
                            />
                        </Box> 

                        <Box mb={2}>
                            <TextField 
                                multiline
                                fullWidth
                                required 
                                error={Boolean(touched.time_open && errors.time_open)}
                                helperText={touched.time_open && errors.time_open}  
                                onBlur={handleBlur}
                                onChange={handleChange} 
                                value={values.time_open}
                                name="time_open"
                                label="Time Open"
                                variant="outlined"  
                            />
                        </Box> 

                        <Box mb={2}>
                            <TextField 
                                multiline
                                fullWidth
                                required 
                                error={Boolean(touched.contact_no && errors.contact_no)}
                                helperText={touched.contact_no && errors.contact_no}  
                                onBlur={handleBlur}
                                onChange={handleChange} 
                                value={values.contact_no}
                                name="contact_no"
                                label="Contact Number"
                                variant="outlined"  
                            />
                        </Box> 

                        <Box mb={2}>
                            <TextField 
                                multiline
                                fullWidth
                                required 
                                error={Boolean(touched.remarks && errors.remarks)}
                                helperText={touched.remarks && errors.remarks}  
                                onBlur={handleBlur}
                                onChange={handleChange} 
                                value={values.remarks}
                                name="remarks"
                                label="Remarks"
                                variant="outlined"  
                            />
                        </Box> 
 

                        <Box  mb={2}>
                            <Box
                                mb={1}
                            >
                                <Typography variant="caption" color="textPrimary">
                                    CLINIC MAP LOCATION
                                </Typography>
                            </Box>
                            <Map 
                                center={[7.1907, 125.4553]} 
                                zoom={5} 
                                style={{height: '350px'}}  
                                locate={{
                                    watch: true,
                                    enableHighAccuracy: true
                                }}
                            >  
                                <TileLayer
                                    url={process.env.REACT_APP_MAP_URL} 
                                    attribution="© <a href='https://www.mapbox.com/about/maps/'>Mapbox</a> © <a href='http://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                                    maxZoom={19}
                                    minZoom={6}
                                    crossOrigin={true}
                                />
                                <Marker 
                                    position={marker} 
                                    onDrag={ handleDragMarker }
                                    draggable={true}
                                    onDragend={ handleDragMarker }
                                    ref={ (ref) => refmarker = ref }
                                /> 
                                <FullscreenControl position="topright" />
                            </Map>
                        </Box>

                        <Box  mb={2}>
                            <Box
                                mb={1}
                            >
                                <Typography variant="caption" color="textPrimary">
                                    CLINIC IMAGE
                                </Typography>
                            </Box>

                            <DropzoneArea 
                                filesLimit={1}
                                onChange={ handleImageChange }
                                acceptedFiles={['image/jpeg', 'image/png']}
                                dropzoneText= "Image (Optional)"
                                maxFileSize={300000}
                            />
                        </Box>

                        <Box mb={2}>
                            <TextField 
                                fullWidth
                                required
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}  
                                onBlur={handleBlur}
                                onChange={handleChange} 
                                value={values.password} 
                                name="password"
                                label="Password"
                                variant="outlined" 
                                type="password"
                            />
                        </Box>  

                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>
                                {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}
                        
                        <Box
                            display="flex"
                        >
                            <Button
                                variant="contained"
                                color="default" 
                                startIcon={ <ClearIcon /> }
                                size="large"
                                type="reset"
                            >
                                Clear
                            </Button> 
                            <Box ml={2}>   
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={ <CheckIcon /> }
                                    size="large"
                                    disabled={isSubmitting}  
                                >
                                    Save
                                </Button> 
                            </Box>  
                       </Box>
                    </form>
                )}
            </Formik> 
        </Box>
    )
}

export default NewClinic;