import React, { useEffect, useState } from 'react';
import { Badge, Box, Dialog, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TablePagination ,TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import CancelIcon from '@material-ui/icons/Cancel';

const imageLocationOnline = process.env.REACT_APP_API_IMAGE_VIRTUAL; 

const ImagingReadByImaging = () =>{


    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const [list, setList] = useState([])
    const [listRd, setListRd] = useState(false)
    const [selectedOder, setSelectedOrder] = useState({ 
        data: null,
        dialog: false
    })

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const handleChangePage = (event, newPage) => {
        event.persist()
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
 
    const getImagingReadByTelerad = async () => {
        try {
            var formdata = new FormData();
                formdata.append('token' , token)
                formdata.append('user_id' , user_id)
                formdata.set('connection', 'online')
            const request = await Axios.post('imaging/telerad/order-readbytelerad', formdata)
                setList(request.data)
                setListRd(true)
        } catch (error) {
            Notify.requestError(error)
        }
    }

    useEffect(() =>{

        getImagingReadByTelerad()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])  

    return(
        <Paper
            component={Box}
            p={2} 
            variant="outlined"
        > 
            <Typography
                color="textPrimary"
                variant="subtitle2"
                className={`gtc-uppercase`}
            >
                <b> Imaging Order Sent To Telerad  </b>
            </Typography>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow> 
                            <TableCell> <b> Date </b> </TableCell>
                            <TableCell> <b> Patient </b> </TableCell>
                            <TableCell> <b> Order </b> </TableCell>
                            <TableCell> <b> Telerad </b> </TableCell>
                            <TableCell> <b> Action </b> </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            listRd ?
                                list.length > 0 ?
                                (rowsPerPage > 0 ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : list)
                                    .map((data, index) =>{
                                        return(
                                            <TableRow
                                                key={ index }
                                            > 
                                                <TableCell>
                                                    <Badge color="secondary" badgeContent={'new'} invisible={ data.is_viewed !== 3 } /> 
                                                    { Notify.dateTimeConvert(data.created_at) }
                                                </TableCell>
                                                <TableCell>
                                                    { data.patient_name }
                                                </TableCell>
                                                <TableCell>
                                                    { data.imaging_order }
                                                </TableCell>
                                                <TableCell>
                                                    { data.telerad }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={ () =>{
                                                            setSelectedOrder({ data: data, dialog: true })
                                                        }}
                                                    >
                                                        <MenuOpenIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                :   <TableRow>
                                        <TableCell colSpan={5}> <Typography color="secondary"> No record found. </Typography> </TableCell> 
                                    </TableRow>
                            :   <TableRow>
                                    <TableCell colSpan={5}> <Typography color="primary"> loading... </Typography> </TableCell> 
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                labelRowsPerPage="list"
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

            {/* imaging details dialog */}
            { selectedOder.data && selectedOder.dialog && ( 
                <ImagingReadByImagingDetails 
                    details = { selectedOder.data } 
                    open ={ selectedOder.dialog} 
                    close = { () => { 
                        setSelectedOrder({ data: null, dialog: false })  
                    }} 
                /> 
            )}
        </Paper>
    )
}

export default ImagingReadByImaging; 

const ImagingReadByImagingDetails = ({ details, open, close }) =>{
    
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const setTeleradFindingsRead = async () => {
        try {
            var formdata = new FormData();
                formdata.append('token' , token)
                formdata.append('user_id' , user_id)
                formdata.set('connection', 'online')
                formdata.set('imaging_center_id', details.imaging_center_id)

            const request = await Axios.post('imaging/order/set-findings-asread', formdata)
                console.log(request.data)
        } catch (error) {
            Notify.requestError(error)
        }
    }

    useEffect(() =>{

        setTeleradFindingsRead()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
           <Dialog
                open={ open }
                onClose = { close }
                fullWidth
                maxWidth="sm"
           >
               <Box display="flex">
                   <Box flexGrow={1}>
                        <DialogTitle> Order Details </DialogTitle>
                    </Box>
                    <Box mt={1} mr={1}>
                        <IconButton
                            color="secondary"
                            onClick = { close }
                        >
                            <CancelIcon />
                        </IconButton>
                    </Box>
               </Box>

               <DialogContent dividers> 

                    <Box mb={2}>
                        <TextField 
                            label="Order"
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ details.imaging_order } 
                            multiline
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField 
                            label="Order Remarks "
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ details.imaging_remarks } 
                            multiline
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField 
                            label="Order On "
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ Notify.createdAt(details.created_at) } 
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField
                            label="Result"
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ details.imaging_result === null ? 'None' : details.imaging_result }
                            multiline
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField 
                            label="Result Remarks"
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ details.imaging_results_remarks === null ? 'None' : details.imaging_results_remarks }
                            multiline
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField 
                            label="Result Added On"
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true
                            }}
                            value={ details.end_time === null ? 'None' : Notify.createdAt(details.end_time) }
                        />
                    </Box>

                    <Box> 
                        <Box>
                            <Typography color="primary" variant="subtitle2" >
                                RESULT ATTACHMENT
                            </Typography>
                        </Box>

                        { 
                            details.imaging_result_attachment === null ? 
                                <p className="m-0 text-danger"> No attachment </p> 
                            : 
                            <div className="row">
                                {
                                    details.imaging_result_attachment.split(',').map( (data, index) => {
                                        return( 
                                            <img  
                                                key={index}  
                                                src={`${imageLocationOnline}imaging/${data}`} 
                                                alt="" 
                                                className="col-sm-4 col-xs-6 pointer" 
                                                title="Enlarge" 
                                            />  
                                        )
                                    })
                                }
                            </div> 
                        } 
                    </Box>

               </DialogContent>
           </Dialog>
        </>
    )
}