import { Box, Button, CircularProgress, Divider, IconButton, TextField, Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import Axios from 'axios'
import React, { useEffect, useCallback, useState } from 'react'
import Notify from '../notification/Notify'
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ClinicInformation = () =>{
    
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id') 
    const username = localStorage.getItem('username') 

    const [clinicInfo, setClinicInfo] = useState(null)
    const [clinicInfoReady, setClinicInfoReady] = useState(false)

    const [enableEdit, setEnableEdit] = useState(false)
    const [isprocess, setIsProcess] = useState(false)


    const getClinicInformation = useCallback( async () => {
        var formdata = new FormData()
            formdata.set('token', token)
            formdata.set('user_id', user_id)  
        Axios.post('clinic/secretary/clinic-details', formdata)
        .then((response) =>{
            const data = response.data    
            setClinicInfo(data)
            setClinicInfoReady(true)
        }).catch(error => { Notify.requestError(error) }) 
    },[token, user_id])

    const handleEditClinic = (e) =>{
        e.preventDefault();
        e.persist()

        var formdata = new FormData(e.target)
            formdata.set('token', token)
            formdata.set('user_id', user_id)  
            formdata.set('username', username)   
        var error = [];

        if(formdata.get('contact_no').length === 0 || formdata.get('contact_no').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('contact no'); 
        }

        if(formdata.get('days_open').length === 0 || formdata.get('days_open').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('days open'); 
        }

        if(formdata.get('time_open').length === 0 || formdata.get('time_open').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('time open'); 
        }

        if(formdata.get('remarks').length === 0 || formdata.get('remarks').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('remarks'); 
        }

        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0){
            console.log("Form has an error.")
        }else{
            setIsProcess(true)
            Axios.post('clinic/secretary/clinic-editInformation', formdata)
            .then((response) =>{
                const data = response.data
                if(data === 'success'){
                    setEnableEdit(false)
                    Notify.successRequest('edit clinic')
                }
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
            }).catch(error => Notify.requestError(error) )
            .finally(() => setIsProcess(false))
        }
    } 

    useEffect(() =>{

        getClinicInformation()
        
    },[getClinicInformation])

    return(
        <>  
            <Box
                border={1} 
                bgcolor="white" 
                borderColor={grey[300]} 
                pt={1}
                pl={1}
            >
                <Box display="flex" p={1}>
                    <Box flexGrow={1}>
                        <Typography
                            noWrap 
                            color="primary" 
                            variant="subtitle2"
                        > CLINIC INFORMATION </Typography> 
                    </Box>
                    <Box hidden={ enableEdit }>
                        <IconButton
                            color="primary"
                            onClick={  () => setEnableEdit(true) }
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box p={1} className={ !enableEdit ? "gtc-textfield-noborder" : ''}>
                    {
                        clinicInfoReady ?
                            clinicInfo && (
                                <form onSubmit={ handleEditClinic }>
                                    <Box mb={3}>  
                                        <TextField
                                            required
                                            multiline
                                            fullWidth
                                            label="Clinic"
                                            variant="outlined"
                                            name="name"
                                            value={clinicInfo.clinic} 
                                            disabled={ enableEdit }
                                            inputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Box> 

                                    <Box mb={3}>  
                                        <TextField
                                            required
                                            multiline
                                            fullWidth
                                            label="Address"
                                            variant="outlined"
                                            name="address"
                                            value={clinicInfo.address} 
                                            disabled={ enableEdit }
                                            inputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    </Box> 

                                    <Box mb={3}>  
                                        <TextField
                                            required
                                            multiline
                                            fullWidth
                                            label="Contact No."
                                            variant="outlined"
                                            name="contact_no"
                                            defaultValue={clinicInfo.contact_no} 
                                            inputProps={{
                                                readOnly: !enableEdit
                                            }}
                                        />
                                    </Box> 

                                    <Box mb={3}>  
                                        <TextField
                                            required
                                            multiline
                                            fullWidth
                                            label="Days Open"
                                            variant="outlined"
                                            name="days_open"
                                            defaultValue={clinicInfo.days_open} 
                                            inputProps={{
                                                readOnly: !enableEdit
                                            }}
                                        />
                                    </Box> 

                                    <Box mb={3}>  
                                        <TextField
                                            required
                                            multiline
                                            fullWidth
                                            label="Time Open"
                                            variant="outlined"
                                            name="time_open"
                                            defaultValue={clinicInfo.time_open} 
                                            inputProps={{
                                                readOnly: !enableEdit
                                            }}
                                        />
                                    </Box> 

                                    <Box mb={3}>  
                                        <TextField
                                            required
                                            multiline
                                            fullWidth
                                            label="Remarks"
                                            variant="outlined"
                                            name="remarks"
                                            defaultValue={clinicInfo.remarks} 
                                            inputProps={{
                                                readOnly: !enableEdit
                                            }}
                                        />
                                    </Box>  

                                    <Box 
                                        hidden={ !enableEdit }
                                    >
                                        <Box mb={3}>  
                                            <TextField
                                                required 
                                                fullWidth
                                                label="Password"
                                                variant="outlined"
                                                name="password" 
                                                type="password"
                                            />
                                        </Box>  

                                        <Divider />

                                        <Box 
                                            display="flex"
                                            my={1}
                                            justifyContent="flex-end"
                                        >  
                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                onClick={ () => setEnableEdit(false) }
                                                startIcon={
                                                    <CancelIcon />
                                                }
                                            >
                                                Cancel
                                            </Button> 
                                            <Box ml={2}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={ isprocess }
                                                    startIcon={
                                                        isprocess ?  <CircularProgress size={15} /> : <CheckCircleIcon />
                                                    }
                                                >
                                                    Save
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </form>
                            ) 
                        : Notify.loading()
                    }
                </Box>
            </Box>
        </>
    )
}

export default ClinicInformation;