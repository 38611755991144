
import React, { useEffect, useState } from 'react';
import { Box, Table, TableHead, TableBody, Paper, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';
import Notify from 'src/notification/Notify';

const Report =  () => {
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')

    const [completed, setCompleted] = useState({
        data: [],
        ready: false
    })

    const getCompleteAppointment = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);         
        formdata.set('connection', 'online');  
        
        axios.post('encoder/appointment/online/completed-list', formdata )
        .then( (response) =>{ 
            const data = response.data;     
            setCompleted({
                data: data, ready: true
            }) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    useEffect(() =>{
        
        getCompleteAppointment()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return( 
        <Box
            component={Paper}
            variant="outlined" 
        >  
            <Box ml={2} mt={2}>
                <Typography variant="subtitle2" color="primary" className="gtc-uppercase">
                    Online Complete Appointment
                </Typography>
            </Box>

            <TableContainer>
                <PerfectScrollbar>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> Appointment Date </TableCell>
                                <TableCell> Complete On </TableCell>
                                <TableCell> Service </TableCell>
                                <TableCell> Fee </TableCell> 
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                completed.ready ? 
                                    completed.data.length > 0 ?
                                        completed.data.map((data, index) =>(
                                            <TableRow
                                                key={index}
                                                hover
                                            >
                                                <TableCell> { Notify.dateTimeConvert(data.appointment_dateonline) } </TableCell>
                                                <TableCell> { Notify.dateTimeConvert(data.appointment_done_on) } </TableCell>
                                                <TableCell> { data.doctors_service } </TableCell>
                                                <TableCell> { data.doctors_service_amount } </TableCell> 
                                            </TableRow>
                                        ))
                                    :
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <Typography color="secondary">
                                                No complete appointment found.
                                            </Typography>
                                        </TableCell> 
                                    </TableRow>
                                : 
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography color="primary">
                                            please wait...
                                        </Typography>
                                    </TableCell> 
                                </TableRow>
                            }
                        </TableBody>

                    </Table>
                </PerfectScrollbar>
            </TableContainer>
        </Box>
    )
}

export default Report;