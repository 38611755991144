import React from 'react'
import { Box, Paper, TextField, Typography, Tabs, Tab } from '@material-ui/core';
import Notify from 'src/notification/Notify';
import TabPanel from 'src/utils/TabPanel';
// import ImagingTeleradMessages from './ImagingTeleradMessages';
import ImagingTeleradSentToTelerad from './ImagingTeleradSentToTelerad';

const ImagingTeleradDetails = ({ telerad , orderId}) =>{ 


    const [category, setCategory] = React.useState(0);

    const handleChange = (event, newValue) => {
        event.persist()
        setCategory(newValue);
    };
    
    return(
        <>
            <Paper
                component={Box}
                p={2}
                variant="outlined"
                className={`gtc-textfield-noborder`}
                mb={2}
            >
                <Box mb={3}> 
                    <Typography 
                        variant="subtitle2"
                        color="textPrimary"
                        className={`gtc-uppercase`}
                    >
                        <b> Teleradiologist Information </b>
                    </Typography>
                </Box>

                <Box mb={2}>
                    <TextField 
                        fullWidth 
                        variant="outlined"
                        value={ telerad.data.name }
                        InputProps={{
                            readOnly: true
                        }}
                        label="Name" 
                    />
                </Box>

                <Box mb={2}>
                    <TextField 
                        fullWidth 
                        variant="outlined"
                        value={ telerad.data.gender === null ? 'None' : telerad.data.gender}
                        InputProps={{
                            readOnly: true
                        }}
                        label="Gender" 
                    />
                </Box>

                <Box mb={2}>
                    <TextField 
                        fullWidth 
                        variant="outlined"
                        value={ telerad.data.birthday === null ? 'None' : Notify.birthday(telerad.data.birthday)}
                        InputProps={{
                            readOnly: true
                        }}
                        label="Birthday" 
                    />
                </Box>

                <Box mb={2}>
                    <TextField 
                        fullWidth 
                        variant="outlined"
                        value={ telerad.data.address === null ? 'None' : telerad.data.address}
                        InputProps={{
                            readOnly: true
                        }}
                        label="Address" 
                    />
                </Box> 
            </Paper>

            <Paper
                component={Box}
                p={2}
                variant="outlined"
                className={`gtc-textfield-noborder`}
            >
                <Tabs 
                    value={category} 
                    onChange={handleChange} 
                    indicatorColor="primary"
                >
                    {/* <Tab label="Message" /> */}
                    <Tab label="Send Order" /> 
                </Tabs> 
                <TabPanel value={category} index={0}>
                    <ImagingTeleradSentToTelerad telerad = { telerad } orderId = { orderId } />
                </TabPanel>
                <TabPanel value={category} index={1}> 
                    {/* <ImagingTeleradMessages telerad = { telerad } /> */}
                </TabPanel>
            </Paper>
        </>
    )
}

export default ImagingTeleradDetails;