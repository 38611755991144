import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { Fragment, useEffect, useState } from 'react'
import Axios from 'axios';
import Notify from 'src/notification/Notify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';

const SearchAppointmentDocList = () => {
    const token = localStorage.getItem('token')
    const [doctorList, setDoctorList] = useState([]);
    const history = useHistory()

    const fetchAllDoctor = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        Axios.post('appointment/doctors/doctors-listbysearch', formdata)
            .then((response) => {
                const data = response.data;
                setDoctorList(data);
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    useEffect(() => {

        fetchAllDoctor();
        
         // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    return (
        <Fragment>
            <SearchIcon style={{ marginTop: 20, marginLeft: 10, marginRight: 5 }} />
            <Autocomplete
                id="combo-box-demo"
                onChange={(e, data) => {
                    history.push('/app/patient/appointment/doctor/' + data.user_id)
                }}
                options={doctorList}
                getOptionLabel={(option) => option.name}
                style={{ width: 300 }}
                renderInput={(params) =>
                    <TextField {...params} label="Search Doctors" />}
            />
        </Fragment >
    )
}

export default SearchAppointmentDocList;