import { Box, Grid, Button, Backdrop, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Notify from 'src/notification/Notify';
import HemaOrder from './print/HemaOrder';
import SorologyOrder from './print/SorologyOrder';
import ClinicalMicroscopyOrder from './print/ClinicalMicroscopyOrder';
import FecalAnalysisOrder from './print/FecalAnalysisOrder';
import PrintIcon from '@material-ui/icons/Print';
import ChemistryOrder from './print/ChemistryOrder';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
}));

const PrintOrder = () => {
    const classes = useStyles();
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const management_id = localStorage.getItem('management_id')

    const { order_id } = useParams() 

    const [print, setPrint] = useState('')
    const [printProcess, setPrintProcess] = useState(false)
    
    const [hemaOrderDetails, setHemaOrderDetails] = useState({
        data: null,
        ready: false,
    })

    const [serologyOrderDetails, setSerologyOrderDetails] = useState({
        data: null,
        ready: false,
    })

    const [clinicalMicroscopyOrderDetails, setClinicalMicroscopyOrderDetails] = useState({
        data: null,
        ready: false,
    })

    const [fecalAnalysisOrderDetails, setFecalAnalysisOrderDetails] = useState({
        data: null,
        ready: false,
    })

    const [chemistryOrderDetails, setChemistryOrderDetails] = useState({
        data: null,
        ready: false,
    })

    const [formHeader, setFormHeader] = useState({
        data: null,
        ready: false,
    })

    const getHemaOrderDetails = () =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('order_id' ,order_id)
        
        Axios.post('laboratory/order/ordernew-hemathology/complete/details-print', formdata )
        .then((response) => {  
            const data = response.data;      
            setHemaOrderDetails({ data, ready: true })
        }).catch(error=>{ 
            Notify.requestError(error);
        });  
    }

    const getSerologyOrderDetails = () =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('order_id', order_id)
        
        Axios.post('laboratory/order/ordernew-sorology/complete/details-print', formdata )
        .then((response) => {  
            const data = response.data;      
            setSerologyOrderDetails({ data, ready: true })
        }).catch(error=>{ 
            Notify.requestError(error);
        });  
    }

    const getClinicalMicroscopyOrderDetails = () =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('order_id', order_id)
        
        Axios.post('laboratory/order/ordernew-clinicalmicroscopy/complete/details-print', formdata )
        .then((response) => {  
            const data = response.data;      
            setClinicalMicroscopyOrderDetails({ data, ready: true })
        }).catch(error=>{ 
            Notify.requestError(error);
        });  
    }

    const getFecalAnalysisOrderDetails = () =>{ 
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('order_id', order_id)
        
        Axios.post('laboratory/order/ordernew-fecalanalysis/complete/details-print', formdata )
        .then((response) => {  
            const data = response.data;      
            setFecalAnalysisOrderDetails({ data, ready: true })
        }).catch(error=>{ 
            Notify.requestError(error);
        });  
    }

    const getChemistryOrderDetails = () => {
        var formdata = new FormData();
        formdata.set('token', token);
        formdata.set('user_id', user_id);
        formdata.set('order_id', order_id)

        Axios.post('laboratory/order/ordernew-chemistry/complete/details-print', formdata)
            .then((response) => {
                const data = response.data;
                setChemistryOrderDetails({ data: data, ready: true })
            }).catch(error => {
                Notify.requestError(error);
            });
    }

    const getLabFormHeader = () => {
        var formdata = new FormData();
        formdata.set('token', token); 
        formdata.set('management_id', management_id)

        Axios.post('laboratory/order/formheader-details', formdata)
        .then((response) => {
            const data = response.data;
            setFormHeader(data)
        }).catch(error => {
            Notify.requestError(error);
        });
    }
 
    useEffect(() =>{
        
        getLabFormHeader()
        getHemaOrderDetails()
        getSerologyOrderDetails()
        getClinicalMicroscopyOrderDetails()
        getFecalAnalysisOrderDetails()
        getChemistryOrderDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handlePrint = (e) =>{
        setPrint(e.currentTarget.id)
        setPrintProcess(true)
        setTimeout(() => {
            setPrintProcess(false) 
            window.print()
        }, 4000);
    }


    return(
        <Box m={2}>   

            <Backdrop open={printProcess}  className={classes.backdrop}>
                <Box alignItems="center" align="center">
                    <CircularProgress color="inherit" />
                    <Typography>
                        please wait...
                    </Typography>
                </Box>
            </Backdrop>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3} lg={2} >
                    <Box displayPrint="none">
                        <Button 
                            hidden={ hemaOrderDetails.data && Object.keys(hemaOrderDetails.data).length === 0 }
                            onClick={ handlePrint } 
                            id ="hema"
                            size={'large'} 
                            color="primary" 
                            startIcon={<PrintIcon />} 
                            fullWidth 
                            component={Box} 
                            my={1}
                        > Print Hemathology Result </Button>

                        <Button  
                            hidden={ serologyOrderDetails.data && Object.keys(serologyOrderDetails.data).length === 0 }
                            onClick={ handlePrint } 
                            id ="sero"
                            size={'large'} 
                            color="primary" 
                            startIcon={<PrintIcon />} 
                            fullWidth 
                            component={Box}
                            my={1}
                        > Print Serology Result </Button>

                        <Button
                            hidden={ clinicalMicroscopyOrderDetails.data && Object.keys(clinicalMicroscopyOrderDetails.data).length === 0 }
                            onClick={ handlePrint } 
                            id ="c-micro"
                            size={'large'} 
                            color="primary" 
                            startIcon={<PrintIcon />} 
                            fullWidth 
                            component={Box} 
                            my={1}
                        > Print Clinical Microscopy Result </Button> 

                        <Button
                            hidden={ fecalAnalysisOrderDetails.data && Object.keys(fecalAnalysisOrderDetails.data).length === 0 }
                            onClick={ handlePrint } 
                            id ="fecal"
                            size={'large'} 
                            color="primary" 
                            startIcon={<PrintIcon />} 
                            fullWidth 
                            component={Box} 
                            my={1}
                        > Print Fecal Analysis  Result </Button> 

                        <Button
                            hidden={ chemistryOrderDetails.data && Object.keys(chemistryOrderDetails.data).length === 0 }
                            onClick={ handlePrint } 
                            id ="c-chem"
                            size={'large'} 
                            color="primary" 
                            startIcon={<PrintIcon />} 
                            fullWidth 
                            component={Box} 
                            my={1}
                        > Print Clinical Chemistry Result </Button> 
                    </Box>
                </Grid> 

                <Grid item xs={12} sm={9} lg={10}>
                    { Object.keys(formHeader).length > 0 ? (
                        <>
                            <Box mb={2} className={print === 'hema' ? 'd-print-block' : 'd-print-none'}> 
                                { hemaOrderDetails.data && Object.keys(hemaOrderDetails.data).length > 0 && hemaOrderDetails.ready && <HemaOrder formheader= { formHeader} hemaOrderDetails = { hemaOrderDetails } /> }   
                            </Box>
                            
                            <Box mb={2} className={print === 'sero' ? 'd-print-block' : 'd-print-none'}>
                                { serologyOrderDetails.data &&  Object.keys(serologyOrderDetails.data).length > 0 && serologyOrderDetails.ready && <SorologyOrder formheader= { formHeader} serologyOrderDetails = { serologyOrderDetails } /> }    
                            </Box> 

                            <Box mb={2} className={print === 'c-micro' ? 'd-print-block' : 'd-print-none'}>
                                { clinicalMicroscopyOrderDetails.data &&  Object.keys(clinicalMicroscopyOrderDetails.data).length > 0 && clinicalMicroscopyOrderDetails.ready && <ClinicalMicroscopyOrder formheader= { formHeader} orderDetails = { clinicalMicroscopyOrderDetails } /> }    
                            </Box> 

                            <Box mb={2} className={print === 'fecal' ? 'd-print-block' : 'd-print-none'}>
                                { fecalAnalysisOrderDetails.data &&  Object.keys(fecalAnalysisOrderDetails.data).length > 0 && fecalAnalysisOrderDetails.ready && <FecalAnalysisOrder formheader= { formHeader} orderDetails = { fecalAnalysisOrderDetails } /> }    
                            </Box>  

                            <Box mb={2} className={print === 'c-chem' ? 'd-print-block' : 'd-print-none'}>
                                {chemistryOrderDetails.data && Object.keys(chemistryOrderDetails.data).length > 0 && chemistryOrderDetails.ready && <ChemistryOrder formheader= { formHeader} chemistryOrderDetails={chemistryOrderDetails} />}
                            </Box>
                        </>
                    ): 'Laboratory information not set in database.'} 
                </Grid>
            </Grid>
        </Box>
    )
}

export default PrintOrder;