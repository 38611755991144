import React, { useState, Fragment } from 'react';    
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
 
import { Grid, Box, Card, CardContent, Typography, Button, CircularProgress } from '@material-ui/core'; 
import HighlightOff from '@material-ui/icons/HighlightOff';
import ControlPoint from '@material-ui/icons/ControlPoint';
import Notify from '../../notification/Notify'; 
import Axios from 'axios';
import { grey } from '@material-ui/core/colors';

function NewPatient(props) {

    const [user_id] = useState(localStorage.getItem('user_id'));
    const [token] = useState(localStorage.getItem('token'));
    const [username] = useState(localStorage.getItem('username'));
    
    const [gender, setgender] = useState('');
    const [civil, setcivil] = useState('');
    // const [selectedimage, setselectedimage] = useState(null);
    const [birthday, setbirthday] = useState(new Date());  

    const [isprocessave, setisprocessave] = useState(false) 
    const saveNewPatient = (e) =>{ 
        e.preventDefault()
        e.persist()
        
        var formdata = new FormData(e.target);
            formdata.set('token', token)
            formdata.set('user_id', user_id) 
            formdata.set('username', username)
            
        var error = [];
        if(formdata.get('lastname').length === 0 || formdata.get('lastname').trim()===''){  
            Notify.fieldRequired('lastname')
            error = 'error';
        }
        if(formdata.get('firstname').length === 0 || formdata.get('firstname').trim()===''){  
            Notify.fieldRequired('firstname')
            error = 'error';
        }
        if(formdata.get('middle').length === 0 || formdata.get('middle').trim()===''){  
            Notify.fieldRequired('middle')
            error = 'error';
        }
        if(formdata.get('gender').length === 0 || formdata.get('gender').trim()===''){  
            Notify.fieldRequired('gender')
            error = 'error';
        }
        if(formdata.get('gender').length === 0 || formdata.get('gender').trim()===''){  
            Notify.fieldRequired('gender')
            error = 'error';
        }
        if(formdata.get('birthday').length === 0 || formdata.get('birthday').trim()===''){  
            Notify.fieldRequired('birthday')
            error = 'error';
        }
        if(formdata.get('birthplace').length === 0 || formdata.get('birthplace').trim()===''){  
            Notify.fieldRequired('birthplace')
            error = 'error';
        }
        if(formdata.get('street').length === 0 || formdata.get('street').trim()===''){  
            Notify.fieldRequired('street')
            error = 'error';
        }
        if(formdata.get('barangay').length === 0 || formdata.get('barangay').trim()===''){  
            Notify.fieldRequired('barangay')
            error = 'error';
        }
        if(formdata.get('city').length === 0 || formdata.get('city').trim()===''){  
            Notify.fieldRequired('city')
            error = 'error';
        }
        if(formdata.get('religion').length === 0 || formdata.get('religion').trim()===''){  
            Notify.fieldRequired('religion')
            error = 'error';
        }
        // if(formdata.get('mobile').length === 0 || formdata.get('mobile').trim()===''){  
        //     Notify.fieldRequired('mobile')
        //     error = 'error';
        // }
        // if(formdata.get('telephone').length === 0 || formdata.get('telephone').trim()===''){  
        //     Notify.fieldRequired('telephone')
        //     error = 'error';
        // }
        if(formdata.get('email').length === 0 || formdata.get('email').trim()===''){  
            Notify.fieldRequired('email')
            error = 'error';
        }else{
            if(!Notify.isvalidEmail(formdata.get('email'))){
                Notify.fieldInvalid('email')
                error = 'error';
            }
        } 
        // if(formdata.get('patient_image').name.length === 0 || formdata.get('patient_image').name.trim()===''){  
        //     Notify.fieldRequired('patient image')
        //     error = 'error';
        // }else{
        //     if (!formdata.get('patient_image').name.match(/.(jpg|jpeg|png)$/i)){
        //         Notify.fieldInvalid('patient image')
        //         error = 'error';
        //     }
        // }
        if(error.length > 0){ 
            console.warn("Form is invalid. check your entry.")
        }else{
            setisprocessave(true)
            Axios.post('doctor/patient/save-patientlocal', formdata)
            .then((response) =>{
                const data = response.data
                if(data === 'pass-invalid'){
                    Notify.fieldInvalid('password')
                }
                if(data === 'email-exist'){
                    Notify.fieldInvalid('email alredy taken')
                }
                if(data === 'success'){
                    props.getPatientsList()
                    e.target.reset()
                    Notify.successRequest("patient already added.")
                }
            })
            .catch((error) =>{
                Notify.requestError(error)
            }).finally(() =>{
                setisprocessave(false) 
            })
        }
    }

    // const handleSelectedImage = (e) =>{
    //     if(e.currentTarget.value.length > 0){
    //         setselectedimage(e.currentTarget.files[0].name)
    //     }else{
    //         setselectedimage(null)
    //     }
    // }

    return (
        <Fragment>     
            <Card
                elevation={0} 
                component={Box}
                border={1}
                borderColor={grey[200]}
            >  
                <CardContent>
                    <Typography variant="h6" color="textSecondary"> New Patient </Typography>
                    <form onSubmit={ saveNewPatient } encType = "multipart/form-data">
                        <Box mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <TextField fullWidth margin="normal" variant="outlined" label="Last Name" name="lastname" autoComplete="off"/>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField fullWidth margin="normal" variant="outlined" label="First Name" name="firstname" autoComplete="off"/>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField fullWidth margin="normal" variant="outlined"  label="Middle Name" name="middle" autoComplete="off"/>
                                </Grid>  
                            </Grid>
                        </Box>

                        <Box mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}> 
                                    <FormControl variant="outlined" fullWidth margin="normal">
                                        <InputLabel>Gender</InputLabel>
                                        <Select  
                                            label="Gender"  
                                            name="gender"
                                            value={ gender }
                                            autoComplete="off"
                                            onChange={ (e) => setgender(e.target.value) }
                                        > 
                                            <MenuItem value={'Male'}>Male</MenuItem>
                                            <MenuItem value={'Female'}>Female</MenuItem> 
                                        </Select>
                                    </FormControl> 
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>  
                                        <DatePicker 
                                            fullWidth
                                            disableFuture
                                            name="birthday"
                                            // openTo="year"
                                            format="dd/MM/yyyy"
                                            label="Date of birth"
                                            views={["year", "month", "date"]}
                                            value={ birthday }
                                            onChange={(date) => setbirthday(date) }
                                            autoComplete="off"
                                            inputVariant="outlined"
                                            margin="normal"
                                        /> 
                                    </MuiPickersUtilsProvider> 
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField fullWidth margin="normal" variant="outlined"  label="Birth Place" name="birthplace" autoComplete="off"/>
                                </Grid>  

                            </Grid>
                        </Box>

                        <Box mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>  
                                    <TextField fullWidth margin="normal" variant="outlined"  label="Street" name="street" autoComplete="off"/>
                                </Grid>

                                <Grid item xs={12} sm={4}> 
                                    <TextField fullWidth margin="normal" variant="outlined"  label="Barangay" name="barangay" autoComplete="off"/>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField fullWidth margin="normal" variant="outlined"  label="City" name="city" autoComplete="off"/>
                                </Grid>   
                            </Grid>
                        </Box>

                        <Box mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>  
                                    <TextField fullWidth margin="normal" variant="outlined"  label="Occupation" name="occupation" autoComplete="off"/>
                                </Grid>

                                <Grid item xs={12} sm={4}>  
                                    <FormControl variant="outlined" fullWidth margin="normal">
                                        <InputLabel>Civil Status</InputLabel>
                                        <Select  
                                            label="Civil Status"  
                                            name="civil"
                                            value={ civil }
                                            autoComplete="off"
                                            onChange={ (e) => setcivil(e.target.value) }
                                        >  
                                            <MenuItem value={'Single'}> Single </MenuItem>
                                            <MenuItem value={'Married'}> Married </MenuItem>  
                                            <MenuItem value={'Divorced '}> Divorced  </MenuItem>   
                                        </Select>
                                    </FormControl> 
                                </Grid> 

                                <Grid item xs={12} sm={4}>
                                    <TextField fullWidth margin="normal" variant="outlined"  label="Religion" name="religion" autoComplete="off"/>
                                </Grid>   
                            </Grid>
                        </Box>

                        <Box mb={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4}>  
                                    <TextField fullWidth margin="normal" variant="outlined"  label="Mobile" name="mobile" autoComplete="off"/>
                                </Grid>

                                <Grid item xs={12} sm={4}> 
                                    <TextField fullWidth margin="normal" variant="outlined"  label="Telephone" name="telephone" autoComplete="off"/>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField fullWidth margin="normal" variant="outlined"  label="Email" name="email" autoComplete="off"/>
                                </Grid>   
                            </Grid>
                        </Box>
                        
                        {/* <Box mb={2}>
                            <Grid item xs={12} sm={12}> 
                                <input
                                    accept="image/*" 
                                    style={{ display: 'none' }}
                                    id="raised-button-file" 
                                    type="file"
                                    name="patient_image"
                                    onChange={ handleSelectedImage }
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="contained" component="span" fullWidth color="default" startIcon={<Backup />}>
                                        { selectedimage === null ? " Upload Patient Image " : selectedimage } 
                                    </Button>
                                </label>

                            </Grid> 
                        </Box> */}

                        <Grid item xs={12} sm={12}>
                            <TextField fullWidth margin="normal" type="password" variant="outlined"  label="Password" name="password" autoComplete="off"/>
                        </Grid> 
                        
                        <Box my={3}>
                            <Typography color="textSecondary" style={{marginBottom: '6px'}}> Are you sure to continue adding this patient? </Typography>
                             
                            <Button 
                                startIcon={isprocessave ? <CircularProgress color="inherit" size={15} /> : <ControlPoint />}
                                disabled={ isprocessave } color="primary" variant="contained" type="submit">
                                Add
                            </Button>
                            <Button variant="contained" color="default" style={{ marginLeft: '10px'}} startIcon={ <HighlightOff /> } type="reset"> No </Button>
                        </Box> 
                    </form>
                </CardContent>
            </Card>  
        </Fragment>
    )
}

export default NewPatient;

