import React, { useEffect, useState } from 'react';  
import Notify from '../../../notification/Notify';
import Axios from 'axios';  
import Select from 'react-select';
import ImagingExam from '../../../utils/ImagingExam'; 
import { Button, Box, Typography, Paper, TextField, CircularProgress } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const ImagingOrderLocal = ({ patient_id, getCountImaging }) =>{
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');
    const management_id = localStorage.getItem('management_id'); 
    const username = localStorage.getItem('username');
    const exams = ImagingExam();

    const [imaging, setImaging] = useState({ data: [] , ready: false })
    const [selectedImagingId, setSelectedImagingId] = useState(null)
    const [isProcess, setIsProcess] = useState(false)
 
    const getImagingDetails = () =>{
        var formdata = new FormData();  
        formdata.set('token', token); 
        formdata.set('user_id', user_id);    
        formdata.set('management_id', management_id);    
        
        Axios.post('imaging/imaging-details', formdata )
        .then( (response) => {  
            const data = response.data;    
                setImaging({ data: data, ready: true }) 
        }).catch(error=>{ 
            Notify.requestError(error);
        });
    } 

    const handleCreateOrder = (e) =>{
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);  
        var error = [];
        formdata.set('token', token); 
        formdata.set('doctors_id', user_id);    
        formdata.set('username', username);    
        formdata.set('patient_id', patient_id);    
        formdata.set('imaging_center', selectedImagingId);    

        if(formdata.get('order[]').length === 0 || formdata.get('order[]').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('order'); 
        }

        if(formdata.get('remarks').length === 0 || formdata.get('remarks').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('remarks'); 
        }

        if(formdata.get('imaging_center').length === 0 || formdata.get('imaging_center').trim()==='null'){ 
            error = 'error';
            Notify.fieldRequired('send to'); 
        }
        
        if(formdata.get('password').length === 0 || formdata.get('password').trim()===''){ 
            error = 'error';
            Notify.fieldRequired('password'); 
        }

        if(error.length > 0){
            Notify.consoleLog('imaging order')
        }else{
            setIsProcess(true)
            Axios.post('imaging/create-order', formdata )
            .then((response) =>{ 
                const data = response.data;    
                if(data==='pass-inv'){
                    Notify.fieldInvalid('password')
                }
                if(data==='success'){
                    e.target.reset(); 
                    getCountImaging()
                    Notify.successRequest('imaging order')
                }
                if(data==='db-error'){
                    Notify.warnRequest('imaging order')
                } 
            }).catch(error=>{ 
                Notify.requestError(error);
            }).finally(() => setIsProcess(false))
        }
    } 

    useEffect(() =>{
        
        getImagingDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[patient_id])

    return(
        <>
            <Paper variant="outlined" component={Box} p={2}>
                <Box>
                    <Typography variant="subtitle2" color="primary"> 
                        CREATE LOCAL ORDER
                    </Typography>
                </Box>

                <Box>
                    <form onSubmit={ handleCreateOrder }> 
                        <Box my={3}>
                            <Typography variant="caption" color="textSecondary"> SELECT ORDER </Typography>
                            <Select
                                options ={exams}
                                placeholder="Order"
                                name="order[]"
                                isMulti={true} 
                                menuColor="black"
                                styles={{ 
                                    menu: provided => ({ 
                                        ...provided, zIndex: 9999, 
                                        color: 'black'
                                    }),
                                    control: (base) =>({
                                        ...base,
                                        minHeight: 55
                                    })
                                }}
                            />
                        </Box>
                        
                        <Box mb={3}>
                            <TextField 
                                name="remarks"
                                label="Remarks"
                                variant="outlined"
                                fullWidth
                                rows={4}
                            />
                        </Box> 

                        <Box mb={3}>  
                            <TextField 
                                select
                                label="Imaging Center" 
                                onChange={ (e) => setSelectedImagingId(selectedImagingId === null ? e.currentTarget.value : null) }
                                SelectProps={{ native: true }} 
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                fullWidth
                            >
                                <option value=""> Select </option>
                                { 
                                    imaging.ready ?
                                        imaging.data.length > 0 ?
                                            imaging.data.map((data, index) => { 
                                                return (
                                                    <option key={index} value={ data.imaging_id } > { data.name } </option> 
                                                )
                                            })
                                        : <option value="" disabled> No imaging found </option>
                                    : <option value="" disabled> please wait... </option>
                                }
                            </TextField>
                        </Box>

                        <Box mb={3}>
                            <TextField 
                                name="password"
                                label="Password"
                                variant="outlined"
                                fullWidth
                                type="password"
                            />
                        </Box> 
                        
                        <Box display="flex">
                            <Box mr={3}>
                                <Button 
                                    variant="contained"
                                    startIcon={ <HighlightOffIcon /> }
                                    color="default"
                                > 
                                    No 
                                </Button>
                            </Box>

                            <Button 
                                type="submit"
                                disabled={imaging.data.length > 0 ? isProcess ? true :false : true}
                                variant="contained"
                                startIcon={ isProcess ? <CircularProgress size={20} color="inherit" /> : <CheckCircleOutlineIcon /> }
                                color="primary"
                            >
                                Save 
                            </Button> 
                        </Box>
                    </form>
                </Box>
            </Paper>   
        </>
    )
}

export default ImagingOrderLocal; 