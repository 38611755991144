import React from 'react'
import { Badge, IconButton, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import MessageIcon from '@material-ui/icons/Message';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Notify from 'src/notification/Notify';

const SnackbarUnreadInquiries = ({ unreadInqReply, history }) =>{

    return(
        <>
            { unreadInqReply.length > 0 && (
                unreadInqReply.map((data, index) =>(
                    <Snackbar
                        key={index} 
                        open={ true } 
                        anchorOrigin={{  
                            vertical: 'bottom', 
                            horizontal: 'right'  
                        }}  
                    > 
                        <Alert
                            severity="info" 
                            variant="filled"
                            icon={ 
                                <Badge badgeContent={index + 1} color="error"> 
                                    <MessageIcon /> 
                                </Badge> 
                            } 
                            action={
                                <IconButton
                                    color = "inherit" 
                                    onClick = { () => history.push(`/app/patient/appointment/clinic/${data.clinic_id}`) }
                                >
                                    <ExitToAppIcon />  
                                </IconButton>
                            }
                        >   
                            <Typography
                                variant="subtitle2"
                            >
                                { data.message }
                            </Typography>
                            <Typography 
                                variant="caption"
                                className={`gtc-uppercase`}
                                style={{ fontSize: '.7em' }}
                            >
                                { data.clinic_name }  message on {Notify.dateTimeConvert(data.created_at)}
                            </Typography> 
                        </Alert> 
                    </Snackbar>
                ))
            )}
        </>
    )
}

export default SnackbarUnreadInquiries;