import React, { Component, Fragment } from 'react';  
import Notify from '../../notification/Notify'
import Axios from 'axios';
import { Card, Button } from 'react-bootstrap';
import { faBell, faExternalLinkAlt, faReplyAll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppointmentNotificationMsgDetails from './AppointmentNotificationMsgDetails';
import { Dialog, DialogTitle, TablePagination, Typography } from '@material-ui/core';

export default class AppointmentNotificationMsg extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _notification: [], 

            _selected_notifid: null,
            _dialog_notifopen: false,

            _notifhoverId: '',
            _notifDetailstType: 'view',
            rowsPerPage: 5,
            page: 0, 
		}
    } 
    
    handleChangeRowsPerPage = (event) => {
        this.setState({ 
            rowsPerPage: parseInt(event.target.value, 10),
            page:0,
        }); 
    };

    handleChangePage = (event, newPage) => {
        event.persist() 
        this.setState({
            page: newPage
        })
    };
     

    getNotificationMsg = () => {
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);       
        
        Axios.post('appointment/patient/notification-msg', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _notification: data, 
                })
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    componentDidMount(){
        this._mounted = true;
        this.getNotificationMsg();
    }

    componentWillUnmount(){
        this._mounted = false;
    }
  

    selectedNotif = (e) =>{
        this.setState({
            _selected_notifid: e.currentTarget.getAttribute('notifid'),
            _dialog_notifopen: true
        })
    }


    closeNotifDialog = () => { 
        this.setState({
            _selected_notifid: null,
            _dialog_notifopen: false,
            _notifDetailstType: 'view',
        })
    }

    handleNotifReply  = e => {
        this.setState({
            _selected_notifid: e.currentTarget.getAttribute('notifid'),
            _notifDetailstType: 'reply',
            _dialog_notifopen: true
        })
    }

	render(){  

		return(
            <Fragment> 
                <p className="h6 mb-2 text-uppercase header-label text-muted"> <FontAwesomeIcon icon={faBell} /> Notification Messages </p> 
                {
                    this.state._notification.length > 0 ?  
                            (this.state.rowsPerPage > 0
                                ? this.state._notification.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                : this.state._notification
                            ).map((data, index)=>{
                                return(
                                    <Card 
                                        key={index}  
                                        onMouseEnter={ (e) => this.setState({ _notifhoverId: e.currentTarget.id}) } 
                                        id={ data.id } 
                                        className="pointer bg-white mb-2 p-app-hover"  
                                    >
                                        <Card.Body> 
                                            <Button 
                                                hidden ={ this.state._notifhoverId === data.id.toString() ? false : true }
                                                variant="outline-primary rounded-0 float-right"
                                                onClick={this.selectedNotif} 
                                                notifid={data.notif_id} 
                                                size="sm"
                                            >
                                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                                            </Button> 

                                            <div className="m-0 mb-1 gtc-darkblue text-capitalize">
                                                {data.doctors_name} on {Notify.dateTimeConvert(data.created_at)}
                                            </div>

                                            <Typography noWrap style={style.px300}> {data.notification_msg} </Typography>
                                            { 
                                                data.notification_type === 'inbox' ? 
                                                    <span className="small text-muted"> This is your reply on {Notify.dateTimeConvert(data.created_at)} </span> 
                                                :
                                                    <div className="m-0"> 
                                                        {/* notify back */} 
                                                        <span 
                                                            className="small text-primary mr-2"
                                                            onClick={ this.handleNotifReply }
                                                            notifid={data.notif_id} 
                                                        > 
                                                            <FontAwesomeIcon icon={faReplyAll} /> Reply 
                                                        </span> 

                                                        { 
                                                            data.is_read ? 
                                                                <span className="small text-muted"> seen on {Notify.dateTimeConvert(data.updated_at)} </span> 
                                                            : 
                                                                <span className="small text-muted"> unseen (click to open)</span> 
                                                        }
                                                    </div>  
                                            }
                                        </Card.Body>
                                    </Card>
                                )
                            }) 
                    : Notify.noRecord()
                }

                {/* pagination */}

                <TablePagination
                    labelRowsPerPage = "List"
                    rowsPerPageOptions={[5, 20, 50, 100, { label: 'All', value: -1 }]}
                    component="div"
                    count={this.state._notification.length }
                    rowsPerPage={ this.state.rowsPerPage }
                    page={ this.state.page }
                    onChangePage={ this.handleChangePage}
                    onChangeRowsPerPage={ this.handleChangeRowsPerPage}
                />
                 
                {/* notification dialog */} 
                <Dialog
                    disableBackdropClick
                    open={this.state._dialog_notifopen} 
                    onClose={this.closeNotifDialog}
                >
                    <DialogTitle>
                        Notification
                    </DialogTitle> 
                    <AppointmentNotificationMsgDetails
                        notifid = { this.state._selected_notifid } 
                        detailstype = { this.state._notifDetailstType }
                        getNotificationMsg = { this.getNotificationMsg }
                        closeNotifDialog = {this.closeNotifDialog}
                    />
                </Dialog>

            </Fragment>
		)
	}
}


const style = {
    px300:{
        maxWidth: '300px',
    }
}