
import React, { Fragment, useContext, useState } from "react" 
import { BrowserRouter, NavLink, Redirect, Route, Switch } from 'react-router-dom';  
import Logout from "../welcome/Logout";
import PageNotFound from "../PageNotFound";
import Laboratory from "../laboratory/newlaboratory"; 
import LabTest from "src/laboratory/labtest";
import PrintOrder from "src/laboratory/newlaboratory/PrintOrder";
import { ThemeContext } from "../ContextAPI";
import DarkIcon from '@material-ui/icons/Brightness4';
import LightIcon from '@material-ui/icons/Brightness7';
import { AppBar, Box, Drawer, Hidden, IconButton, List, Toolbar, Typography, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';

import HomeIcon from '@material-ui/icons/Home';
import CreditCardIcon from '@material-ui/icons/CreditCard';  
import ExitToAppIcon from '@material-ui/icons/ExitToApp'; 

const RouteAdministrator = () =>{

    const themeContext = useContext(ThemeContext)  
    const [openDrawer, setOpenDrawer] = useState(false)

    return(
        <Fragment>  
            <BrowserRouter>
                <AppBar 
                    position="static" 
                    color={ themeContext.gtcThemeDark ? 'default' : 'primary' } 
                    className={`gtc-appbar d-print-none`} 
                >  
                    <Toolbar> 
                        {/* desktop navigation bar */}
                        <Hidden smDown>
                            {/* mdup large appbar */} 
                            <Box 
                                display="flex" 
                                width={'100%'}
                                className={`appbarLink`}
                            >  
                                <Box flexGrow={1}> 
                                    <Box display="flex">
                                        <Box m={2}>
                                            <Typography className={`text-white`}>
                                                GTC LABORATORY
                                            </Typography>
                                        </Box> 
                                        <Box m={2}> 
                                            <NavLink to="/app/laboratory" exact activeClassName="text-white"> 
                                                Home 
                                            </NavLink> 
                                        </Box> 

                                        <Box m={2}>
                                            <NavLink to="/app/laboratory/test" exact activeClassName="text-white">
                                                Test 
                                            </NavLink>
                                        </Box>  
                                    </Box> 
                                </Box> 
                            </Box>      
                        </Hidden>
                        
                        {/* mobile navigation bar */}
                        <Hidden mdUp>
                            {/* smdown appbar drawer */}
                            <IconButton edge="start" color="inherit" aria-label="menu"
                                onClick={() => setOpenDrawer(true)}
                            >
                                <MenuIcon />
                            </IconButton> 
                            <Box 
                                display="flex" 
                                width={'100%'} 
                            >  
                                <Box flexGrow={1}> 
                                    {/* drawer for mobile view */}
                                    <Drawer anchor={`left`} open={ openDrawer } onClose={() => setOpenDrawer(false)}> 
                                        <Box p={2}>
                                            <Typography>
                                                GTC LABORATORY
                                            </Typography>
                                            <List
                                                className={`appbarLink`}
                                            >
                                                <ListItem component={NavLink} exact to="/app/laboratory" activeClassName={themeContext.gtcThemeDark ? 'text-white' : 'gtc-text-purple'}> 
                                                    <ListItemIcon> <HomeIcon /> </ListItemIcon>
                                                    <ListItemText primary={'Home'} />
                                                </ListItem>

                                                <ListItem component={NavLink} exact to="/app/laboratory/test" activeClassName={themeContext.gtcThemeDark ? 'text-white' : 'gtc-text-purple'}> 
                                                    <ListItemIcon> <CreditCardIcon /> </ListItemIcon> 
                                                    <ListItemText primary={'Test'} />
                                                </ListItem> 
                                            </List>
                                        </Box> 
                                    </Drawer> 
                                </Box> 
                            </Box>      
                        </Hidden>

                        <Box>
                            <Box display="flex" width={180}>
                                <Box m={2} className={`pointer`}> 
                                    <Typography onClick={()=>{ themeContext.updategtcTheme(themeContext.gtcThemeDark ? 'light' : 'dark') } }> { themeContext.gtcThemeDark ? <DarkIcon /> : <LightIcon /> } </Typography>
                                </Box>

                                <Box m={2}>  
                                    <NavLink to="/app/logout" exact style={{ color: '#c5c5c5', textDecoration: 'none' }}> 
                                        Logout  <ExitToAppIcon />
                                    </NavLink>    
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Switch>
                    <Route exact path="/" component={() => <Redirect to="/app/laboratory" /> } />   
                    <Route exact path="/app" component={() => <Redirect to="/app/laboratory" /> } />   
                    <Route exact path="/app/laboratory" component={Laboratory}/>  
                    <Route exact path="/app/laboratory/test" component={LabTest}/>  
                    <Route exact path="/app/laboratory/record/print/order/:order_id" component={PrintOrder}/>  
                    <Route exact path="/app/logout" component={Logout} />    
                    
                    <Route render={
                        () => <PageNotFound />
                    }/> 
                </Switch>

            </BrowserRouter>
        </Fragment>
    )
}

export default RouteAdministrator;
 