import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Box, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, TablePagination, TextField, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NewDoctor from './NewDoctor';
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DoctorsDetails from './DoctorsDetails';

function searchDoctor(doctors, query) {
    return doctors.filter((doctor) => {
        let matches = true;

        if (query) {
            let containsQuery = false;

            if (doctor.name.toLowerCase().includes(query.toLowerCase())) {
                containsQuery = true;
            }

            if (!containsQuery) {
                matches = false;
            }
        }
        return matches;
    });
}

function applyPagination(doctors, page, limit) {
    return doctors.slice(page * limit, page * limit + limit);
}

const Doctor = () => {
    const token = localStorage.getItem('token')
    const [doctors, setDoctors] = useState([])
    const [doctorsReady, setDoctorsReady] = useState(false)
    const [selectedDoctorUserId, setSelectedDoctorUserId] = useState(null)

    const [query, setQuery] = useState('');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const getDoctorsList = useCallback(async () => {
        try {
            var formdata = new FormData()
            formdata.append('token', token)
            const request = await Axios.post('gtcadmin/doctors/getdoctors-list', formdata)
            setDoctors(request.data)
            setDoctorsReady(true)
        } catch (error) {
            Notify.requestError(error)
        }

    }, [token])

    useEffect(() => {
        getDoctorsList()
    }, [getDoctorsList])

    const handleSearch = (event) => {
        event.persist();
        setQuery(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const filterDoctors = searchDoctor(doctors, query);
    const paginatedDoctor = applyPagination(filterDoctors, page, limit);

    return (
        <Box m={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <Box
                        component={Paper}
                        p={2}
                        height={'calc(100vh - 120px)'}
                        overflow="hidden"
                    >
                        <Box>
                            <Typography
                                variant="subtitle2"
                                color="textPrimary"
                            >
                                DOCTORS LIST
                            </Typography>
                        </Box>
                        <Box my={1}>
                            <TextField
                                fullWidth
                                label="Search Doctor"
                                variant="outlined"
                                margin="dense"
                                onChange={handleSearch}
                                value={query}
                            />
                        </Box>
                        <PerfectScrollbar>
                            <List component={`div`}>
                                <ListItem
                                    button
                                    selected={selectedDoctorUserId === null}
                                    onClick={() => setSelectedDoctorUserId(null)}
                                >
                                    <ListItemIcon>
                                        <Avatar>
                                            <AddCircleOutlineIcon />
                                        </Avatar>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="New Doctor"
                                        secondary="Add Doctor Form"
                                    />
                                </ListItem>
                                {
                                    doctorsReady ?
                                        doctors.length > 0 ?
                                            paginatedDoctor.map((data, index) => (
                                                <ListItem
                                                    button
                                                    key={index}
                                                    selected={data.user_id === selectedDoctorUserId}
                                                    onClick={() => setSelectedDoctorUserId(data.user_id)}
                                                >
                                                    <ListItemIcon>
                                                        <Avatar>
                                                            {data.name.charAt().toUpperCase()}
                                                        </Avatar>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={data.name}
                                                        secondary={
                                                            <Typography className={`gtc-lowercase`} variant="caption" color="textSecondary"> {data.specialization} </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            ))
                                            : <Box mt={2}> {Notify.noRecord()} </Box>
                                        : <Box mt={2}> {Notify.loading()} </Box>
                                }
                            </List>
                        </PerfectScrollbar>
                    </Box>
                    {filterDoctors.length > 0 && (
                        <TablePagination
                            component={Box}
                            count={filterDoctors.length}
                            labelRowsPerPage="List"
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleLimitChange}
                            page={page}
                            rowsPerPage={limit}
                            rowsPerPageOptions={[5, 10, 25]}
                        />
                    )}
                </Grid>

                <Grid item xs={12} sm={8} md={9}>
                    {selectedDoctorUserId === null && (<NewDoctor getDoctorsList={getDoctorsList} />)}
                    {selectedDoctorUserId && (<DoctorsDetails getDoctorsList={getDoctorsList} doctorUserId={selectedDoctorUserId} />)}
                </Grid>
            </Grid>
        </Box>
    )
}

export default Doctor;