import React, { useEffect, useState } from 'react';   
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import ZoomableImage from 'src/utils/ZoomableImage'; 
import Axios from 'axios';

const imageLocation = process.env.REACT_APP_API_IMAGE; 
const imageLocationOnline = process.env.REACT_APP_API_IMAGE_VIRTUAL; 


const ImagingProcessedAttachment = ({ details, imagingType }) =>{

    const [openLightbox, setOpenLightBox] = useState(false)
    const [imageToEnlarge, setImageToEnlarge] = useState(null)

    const token = localStorage.getItem('token') 

    const setNotifAsView = async() => {
        var fd = new FormData()
            fd.append('token', token) 
            fd.append('order_id', details.imaging_center_id)
            fd.append('connection', imagingType === 'local-imaging' ? 'local' : 'online')

        await Axios.post('/doctor/setnotification/as-read', fd)
    }
    

    useEffect( () =>{

        setNotifAsView()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const imageUrl = imagingType === 'virtual-imaging' ? imageLocationOnline : imageLocation
    return(
        <> 
            <Row>
                <Col sm={12} className="mb-2">
                    <p className="text-muted gtc-small text-uppercase"> Result Attachment </p>
                    { 
                        details === null ? 
                            <p className="m-0 text-danger"> No attachment </p> 
                        : 
                        <div className="row">
                            {
                                details.split(',').map( (data, index) => {
                                    return( 
                                        <img  
                                            key={index} 
                                            onClick={() =>{
                                                setOpenLightBox(true)
                                                setImageToEnlarge(data) 
                                            }} 
                                            src={imageUrl+'imaging/'+data} 
                                            alt="" 
                                            className="col-sm-4 col-xs-6 pointer" 
                                            title="Enlarge" 
                                        />  
                                    )
                                })
                            }
                        </div> 
                    }
                </Col>
            </Row>  
        
            <ZoomableImage
                open = { openLightbox }
                close = { () => setOpenLightBox(false) }
                title = {`Imaging Attachment`}
                image = {`${imageUrl}imaging/${imageToEnlarge}`}
            />
        </>
    )
}

export default ImagingProcessedAttachment; 