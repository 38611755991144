import React, { Fragment } from 'react';
import { Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';

function RegistrationSuccess(props) {
    return (
        <Fragment>
            <Col sm={8} className="mx-auto" style={{marginTop: '10%'}}>
                <Alert variant="success" className="border border-success rounded-0 text-center">
                    <p className="text-center text-success mb-2">
                        <FontAwesomeIcon icon={faUserCheck} size="4x" /> <br /> <br />
                        <strong> Your registration is successful! </strong>
                    </p>
                    <p className="mt-3">  
                        Check your <b>{props.email}  </b> email address for the link given, please be patient because sometimes message takes time to arrive in your email.
                    </p>
                </Alert>
            </Col>  
        </Fragment>
    )
}

export default RegistrationSuccess;
