import React, { Component } from 'react';  
import { Col, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarCheck, } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import AppointmentDetails from './AppointmentDetails';
import Notify from '../../notification/Notify';
import AppointmentNotificationMsg from './AppointmentNotificationMsg';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Badge } from '@material-ui/core';
import Label from 'src/utils/Label';

export default class Appointment extends Component{
    _mounted = false;
	constructor(props){
        super(props); 
        this.state={
            _user_id: localStorage.getItem('user_id'),
            _token: localStorage.getItem('token'),
            _management_id: localStorage.getItem('management_id'),
            _type: localStorage.getItem('type'),
            _username: localStorage.getItem('username'),

            _appointment: [],
            _appointment_ready: false,

            _open_appointment: false,
            _selected_app_id: null,

            _selected_category: 'appointment-list',
            _notification_count: 0,
        }

    } 

    componentDidMount () {
        this._mounted = true;
         this.getAppointment();
         this.getNotificationMsg()
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    getAppointment(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token);  
        formdata.set('user_id', this.state._user_id);    
        
        Axios.post('appointment/patient/appointment-list', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _appointment: data,
                    _appointment_ready: true,
                })
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    getNotificationMsg(){
        var formdata = new FormData();  
        formdata.set('token', this.state._token); 
        formdata.set('user_id', this.state._user_id);       
        
        Axios.post('appointment/patient/notification-msg', formdata )
        .then(function(response){ 
            const data = response.data;   
            if(this._mounted){ 
                this.setState({
                    _notification_count: data.length, 
                })
            } 
        }.bind(this)).catch(error=>{ 
            Notify.requestError(error);
        });
    }

    handleSelectedAppointment = (e) =>{
        this.setState({
            _selected_app_id: e.currentTarget.id,
            _open_appointment: true
        })
    }

    handleCategory = (e) => {
        this.setState({
            _selected_category: e.currentTarget.id
        })
    }

	render(){ 
		return(
            <Row>
                <Col sm={12} className="mt-3">
                    <p className="header-label text-uppercase font-weight-bold gtc-darkblue"> 
                        <span className="mr-2 pointer" id="appointment-list" onClick={this.handleCategory}> Appointment </span> 
                        | 
                        <span className="ml-2 pointer" id="notification-list" onClick={this.handleCategory}> <Badge color="error" badgeContent={ this.state._notification_count }>  Notification</Badge> </span>
                    </p> 
                    {
                        this.state._selected_category === 'appointment-list' ?
                            <Row>
                                <Col sm={12}>
                                    <p className="h6 mb-2 text-uppercase header-label text-muted"> <FontAwesomeIcon icon={faCalendarCheck} /> Appointment List </p> 
                                </Col>
                                {
                                    this.state._appointment_ready ? 
                                        this.state._appointment.length > 0 ?
                                            this.state._appointment.map((data, index)=>{
                                                return(
                                                    <Col key={index} sm={6} lg={4} className="mb-2 pointer">
                                                        <Card className="bg-white border p-app-hover" id={data.appointment_id} onClick={this.handleSelectedAppointment}>
                                                            <Card.Body className="p-2">
                                                                <Row>
                                                                    <Col xs={4} className="gtc-darkblue">
                                                                        <div className="p-app-date-container mx-auto">
                                                                            <FontAwesomeIcon icon={faCalendar} size="6x" /> 
                                                                            <p className="app-date-day m-0 mt-2">
                                                                                { new Date(Boolean(data.is_reschedule) ? data.is_reschedule_date : data.appointment_date.replace(/-/g, '/')).toLocaleString('en-US',{ day:'2-digit'}) }
                                                                            </p> 
                                                                            <p className="app-date-time m-0">
                                                                                { new Date(Boolean(data.is_reschedule) ? data.is_reschedule_date : data.appointment_date.replace(/-/g, '/')).toLocaleString('en-US',{ hour:'numeric', minute: 'numeric'}) }
                                                                            </p> 
                                                                            <p className="app-date-month m-0">
                                                                                { new Date( Boolean(data.is_reschedule) ? data.is_reschedule_date : data.appointment_date.replace(/-/g, '/')).toLocaleString('en-US',{ year:'numeric',month:'short'}) }
                                                                            </p>  
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={8}> 
                                                                        <div>
                                                                            <Label
                                                                                color={ 
                                                                                    data.appointment_status === "successful" ? "primary" 
                                                                                    : data.appointment_status === 'approved' ? "success" 
                                                                                    : "secondary" 
                                                                                }
                                                                            >
                                                                                {data.appointment_status}     
                                                                            </Label> 
                                                                        </div>
                                                                        <div>
                                                                            <span className="gtc-text gtc-small text-uppercase"> ID: </span>
                                                                            <span  className="gtc-darkblue text-capitalize"> {data.appointment_id} </span> 
                                                                        </div>
                                                                        <div>
                                                                            <span className="gtc-text gtc-small text-uppercase"> Type: </span>
                                                                            <span  className="gtc-darkblue text-capitalize"> {data.services} </span> 
                                                                        </div>
                                                                        <div>
                                                                            <span className="gtc-text gtc-small text-uppercase"> Credit: </span>
                                                                            <span  className="gtc-darkblue text-capitalize"> {data.credit_fee} </span> 
                                                                        </div> 
                                                                        <div>
                                                                            <span className="gtc-text gtc-small text-uppercase"> Doctor </span>
                                                                            <Typography noWrap className="gtc-darkblue text-capitalize"> {data.doctors_name} </Typography> 
                                                                        </div>
                                                                    </Col>
                                                                </Row> 
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                        : <div className="col-sm-12 "> <div className="py-3"> { Notify.noRecord() } </div> </div>
                                    : <div className="col-sm-12 "> <div className="py-3"> { Notify.loading() }  </div> </div>
                                }
                            </Row>
                        : <AppointmentNotificationMsg />
                    }
                    
                </Col>
                {/* view appointment details */} 
                <Dialog
                    open={ this.state._open_appointment}
                    onClose={()=>this.setState({ _open_appointment: false })}
                >
                    <DialogTitle>
                        Appointment Details
                    </DialogTitle>
                    <DialogContent dividers>
                        <AppointmentDetails 
                            appointment_id = { this.state._selected_app_id }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={()=>this.setState({ _open_appointment: false })}
                            color="default"
                            variant="contained"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Row>
        )
	}
}
