import React, { useState } from 'react'
import { Box, Button, FormHelperText, TextField } from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'; 
import Notify from 'src/notification/Notify';
import Axios from 'axios';
import * as Yup from 'yup'; 
import { Formik } from 'formik';
import CheckIcon from '@material-ui/icons/CheckCircleOutline' 
import ClearIcon from '@material-ui/icons/HighlightOff'; 
import { useHistory } from 'react-router-dom';

const ImagingTeleradSentToTelerad = ({ telerad, orderId }) =>{
    const token = localStorage.getItem('token')
    const user_id = localStorage.getItem('user_id')
    const username = localStorage.getItem('username')

    const [files, setFiles] = useState([])

    const history = useHistory()

    const handleFileChange = (files) => { 
        setFiles(files)
    }  
    
    const convertFormdata = (object) =>{ 
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {  
            formData.append('attachments[]', files[i]) 
        } 
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    } 

    return(
        <>
            <Formik
                initialValues={{
                    username: username,
                    user_id: user_id, 
                    token: token, 
                    orderId: orderId, 
                    radiologist: telerad.data.telerad_id,
                    type: 'Telerad',
                    password: ''
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string().required(),   
                })}
                onSubmit={async (values, {
                    setErrors,
                    setStatus,
                    setSubmitting,
                    resetForm
                }) => {  
                    try {   
                        if(files.length === 0 ){
                            Notify.fieldRequired('attachment')
                            return false;
                        }  
                        const request = await Axios.post('imaging/order/send-toradiologist', convertFormdata(values)) 
                        if(request.data === 'pass-invalid'){ 
                            setErrors({ password: 'Password is invalid.' });
                            Notify.fieldInvalid('password')
                        } 
                        if(request.data === 'success'){
                            Notify.successRequest('order sent')
                            setStatus(false)
                            setFiles([])
                            resetForm(); 
                            setSubmitting(true); 
                            setTimeout(() => {
                                history.push('/app/imaging')
                            }, 2000);
                        }
                    } catch (error) {
                        const message = error.message || 'Something went wrong';  
                        setErrors({ submit: message });
                        setSubmitting(false);
                    }
                }}
                >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                }) => (
                    <form
                        noValidate 
                        onSubmit={handleSubmit}  
                    >     
                        <Box my={1}>
                            <DropzoneArea
                                filesLimit={5}
                                onChange={ handleFileChange }
                                acceptedFiles={['image/jpeg', 'image/png']}
                                dropzoneText= "Result Attachments (Required)"
                            />
                        </Box>  
                                 
                        <Box mb={2}>
                            <TextField 
                                fullWidth
                                required
                                error={Boolean(touched.password && errors.password)}
                                helperText={touched.password && errors.password}  
                                onBlur={handleBlur}
                                onChange={handleChange} 
                                value={values.password} 
                                name="password"
                                label="Enter your password"
                                variant="outlined" 
                                type="password"
                            />
                        </Box>  

                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}

                        <Box mb={2} display="flex"> 
                            <Box flexGrow={1} />

                            <Button
                                variant="contained"
                                color="default"
                                type="reset"  
                                startIcon={ <ClearIcon /> }
                            >
                                Clear
                            </Button> 
                            <Box ml={2}>   
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={ <CheckIcon /> }
                                    disabled={isSubmitting}  
                                >
                                    Save
                                </Button> 
                            </Box>
                        </Box> 
                    </form>
                )}
            </Formik> 
        </>
    )
}

export default ImagingTeleradSentToTelerad;